import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Image,
  Tabs,
  Flex,
} from "antd";
import _ from "lodash";

import { SanPhamService } from "services/SanPhamService";
import { HomeService } from "services/HomeService";
import { FileUpload } from "primereact/fileupload";
import TabPane from "antd/es/tabs/TabPane";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CiTrash } from "react-icons/ci";
import { API_UPLOAD_URL } from "services/UploadService";
import { UploadService } from "services/UploadService";

const FormAdd = (props, ref) => {
  const [files, setFiles] = useState([]);

  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaultData = {
    DinhLuong: [],
  };
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaiSP, setLoaiSP] = useState([]);
  const [khuvuichoi, setKhuvuichoi] = useState([]);
  const [goidichvu, setGoidichvu] = useState([]);
  const [nhomsp, setNhomsp] = useState([]);
  const [dvt, setDVT] = useState([]);
  const [sanpham, setSanpham] = useState([]);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const tabsDefault = [
    {
      title: "Thông tin chung",
      key: "0",
    },
  ];
  const [tabs, setTabs] = useState(tabsDefault);
  const [activeTab, setActiveTab] = useState("0");
  const loadCategories = async () => {
    let _lsp = await SanPhamService.getLSP({ApDung:true});
    let _dvt = await SanPhamService.getDVT();
    let _nhom = await SanPhamService.getNhom();
    let _kvc = await SanPhamService.filterKhuVuiChoi();
    let _sp = await SanPhamService.getListByMaNhom({ MaNhom: 4, InputSearch :""});
    setNhomsp(_nhom?.data ?? []);
    setLoaiSP(_lsp?.data ?? []);
    setDVT(_dvt?.data ?? []);
    setKhuvuichoi(_kvc?.data ?? []);
    setSanpham(_sp?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setIsUpdate(true);
      let _res = await SanPhamService.getGoiDVByKVC({
        MaKVC: data.MaKVC,
      });
      setGoidichvu(_res?.data ?? []);
      if (data.MaNhom === 3) {
        let _res = await SanPhamService.getDinhLuong({ MaSP: data.ID });
        data.DinhLuong = _res.data ?? [];
        let _tabs = _.cloneDeep(tabs);
        let f = _tabs.some((v) => v.key === "1");
        if (!f) {
          _tabs.push({
            title: "Định lượng",
            key: "1",
          });
        }
        setTabs(_tabs);
      }else{
        let _tabs = _.cloneDeep(tabs);
        _tabs = _tabs.filter(v=>v.key !== "1");
        setTabs(_tabs);
        data.DinhLuong = []
      }
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaNhom":
        let _tabs = _.cloneDeep(tabs);
        if (v === 3) {
          let f = _tabs?.find((v) => v.key === "1");
          if (!f) {
            _tabs.push({
              title: "Định lượng",
              key: "1",
            });
            setTabs(_tabs);
          }
        } else {
          _tabs = _tabs?.filter((v) => v.key !== "1");
          setTabs(_tabs);
        }
        _data[p] = v;

        break;
      case "MaKVC":
        let _res = await SanPhamService.getGoiDVByKVC({
          MaKVC: v,
        });
        setGoidichvu(_res?.data ?? []);
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    if (files?.length > 0) {
      var formData = new FormData();
      formData.append("Image", files[0]);
      formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
      let _resupload = await UploadService.upload(formData);
      if (_resupload.status === 2000) {
        _data.LinkImage = API_UPLOAD_URL  + _resupload.data[0];
        setData((pre) => {
          return { ...pre, LinkImage: _data.LinkImage };
        });
      }
    }
    let _res = null;
    if (isUpdate) {
      _res = await SanPhamService.updateSanPham(_data);
    } else {
      _res = await SanPhamService.addSanPham(_data);
      _data.ID = _res.data;
      // setData((pre) => {
      //   return { ...pre, ID: _res.data };
      // });
    }
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isUpdate ? "Sửa" : "Thêm"} sản phẩm thành công!`
      );
      if (close) {
        onClose();
        onAfterSubmit();
      } else {
        setIsUpdate(true);
        let _res = await SanPhamService.getDinhLuong({ MaSP: _data.ID });
        _data.DinhLuong = _res.data ?? [];
      setData(_data);

      }
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
  };
  const renderThongTinChung = () => {
    return (
      <Row gutter={[10, 20]}>
        <Col span={6}>
          <p>Mã vạch</p>
          <Input
            value={data?.MaVach}
            placeholder="Nhập mã vạch"
            onChange={(e) => applyChange("MaVach", e.target.value)}
          />
        </Col>
        <Col span={6}>
          <p>Mã sản phẩm</p>
          <Input
            value={data?.MaSP}
            placeholder="Nhập mã sản phẩm"
            onChange={(e) => applyChange("MaSP", e.target.value)}
          />
        </Col>
        <Col span={9}>
          <p>Tên sản phẩm</p>
          <Input
            value={data?.TenSP}
            placeholder="Nhập tên sản phẩm"
            onChange={(e) => applyChange("TenSP", e.target.value)}
          />
        </Col>
        <Col span={3}>
          <p>Số ngày</p>
          <InputNumber
            value={data?.SoNgay}
            onChange={(v) => applyChange("SoNgay", v)}
          />
        </Col>
        <Col span={12}>
          <p>Loại sản phẩm</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaLSP}
            placeholder="Chọn loại sản phẩm"
            onChange={(v) => applyChange("MaLSP", v)}
            options={loaiSP}
            fieldNames={{ label: "TenLSP", value: "MaLSP" }}
          />
        </Col>
        <Col span={12}>
          <p>Nhóm</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaNhom}
            placeholder="Chọn nhóm sản phẩm"
            onChange={(v) => applyChange("MaNhom", v)}
            options={nhomsp}
            fieldNames={{ label: "TenNhom", value: "ID" }}
          />
        </Col>
        <Col span={8}>
          <p>Đơn vị tính</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaDVT}
            placeholder="Chọn đơn vị tính"
            onChange={(v) => applyChange("MaDVT", v)}
            options={dvt}
            fieldNames={{ label: "TenDVT", value: "MaDVT" }}
          />
        </Col>
        <Col span={8}>
          <p style={{ color: "red" }}>Giá bán</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Nhập giá bán"
            addonAfter="vnđ"
            value={data?.GiaBan}
            onChange={(v) => applyChange("GiaBan", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          <p style={{ color: "red" }}>{errors?.GiaBan}</p>
        </Col>
        <Col span={8}>
          <p style={{ color: "red" }}>Giá mua</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Nhập giá mua"
            addonAfter="vnđ"
            value={data?.GiaMua}
            onChange={(v) => applyChange("GiaMua", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={8}>
          <p>Đơn vị tính vừa</p>
          <Flex gap={10}>
            <Select
              allowClear
              style={{
                width: "100%",
              }}
              value={data?.MaDVT2}
              placeholder="Chọn đơn vị tính"
              onChange={(v) => applyChange("MaDVT2", v)}
              options={dvt}
              fieldNames={{ label: "TenDVT", value: "MaDVT" }}
            />
            <InputNumber
              style={{ width: "100%" }}
              // placeholder="Nhập giá bán"
              addonAfter={
                (dvt.find((v) => v.MaDVT === data?.MaDVT)?.TenDVT ?? "") +
                "/" +
                (dvt.find((v) => v.MaDVT === data?.MaDVT2)?.TenDVT ?? "")
              }
              value={data?.QuyDoiDVT2}
              onChange={(v) => applyChange("QuyDoiDVT2", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Flex>
        </Col>
        <Col span={8}>
          <p style={{ color: "red" }}>Giá bán</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Nhập giá bán"
            addonAfter="vnđ"
            value={data?.GiaBan2}
            onChange={(v) => applyChange("GiaBan2", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          <p style={{ color: "red" }}>{errors?.GiaBan}</p>
        </Col>
        <Col span={8}>
          <p style={{ color: "red" }}>Giá mua</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Nhập giá mua"
            addonAfter="vnđ"
            value={data?.GiaMua2}
            onChange={(v) => applyChange("GiaMua2", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={8}>
          <p>Đơn vị tính lớn</p>
          <Flex gap={10}>
            <Select
              allowClear
              style={{
                width: "100%",
              }}
              value={data?.MaDVT3}
              placeholder="Chọn đơn vị tính"
              onChange={(v) => applyChange("MaDVT3", v)}
              options={dvt}
              fieldNames={{ label: "TenDVT", value: "MaDVT" }}
            />
            <InputNumber
              style={{ width: "100%" }}
              addonAfter={
                (dvt.find((v) => v.MaDVT === data?.MaDVT2)?.TenDVT ?? "") +
                "/" +
                (dvt.find((v) => v.MaDVT === data?.MaDVT3)?.TenDVT ?? "")
              }
              value={data?.QuyDoiDVT3}
              onChange={(v) => applyChange("QuyDoiDVT3", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Flex>
        </Col>
        <Col span={8}>
          <p style={{ color: "red" }}>Giá bán</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Nhập giá bán"
            addonAfter="vnđ"
            value={data?.GiaBan3}
            onChange={(v) => applyChange("GiaBan3", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          <p style={{ color: "red" }}>{errors?.GiaBan}</p>
        </Col>
        <Col span={8}>
          <p style={{ color: "red" }}>Giá mua</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Nhập giá mua"
            addonAfter="vnđ"
            value={data?.GiaMua3}
            onChange={(v) => applyChange("GiaMua3", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={12}>
          <p>Thuế GTGT</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Nhập thuế gtgt"
            addonAfter="%"
            max={100}
            value={data?.ThueGTGT}
            onChange={(v) => applyChange("ThueGTGT", v)}
          />
        </Col>
        <Col span={12}>
          <p>Số lần quẹt</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Nhập số lần quẹt"
            max={100}
            value={data?.SoLanQuet}
            onChange={(v) => applyChange("SoLanQuet", v)}
          />
        </Col>
        <Col span={12}>
          <p>Điểm tích luỹ</p>
          <InputNumber
            placeholder="Nhập điểm tích luỹ"
            style={{ width: "100%" }}
            addonAfter="điểm"
            value={data?.DiemTichLuy}
            onChange={(v) => applyChange("DiemTichLuy", v)}
          />
        </Col>
        <Col span={12}>
          <p>Điểm đổi quà</p>
          <InputNumber
            style={{ width: "100%" }}
            addonAfter="điểm"
            placeholder="Nhập điểm đổi quà"
            value={data?.DiemKM}
            onChange={(v) => applyChange("DiemKM", v)}
          />
        </Col>
        <Col span={12}>
          <p>Khu vui chơi</p>
          <Select
            allowClear
            style={{
              width: "100%",
            }}
            value={data?.MaKVC}
            placeholder="Khu vui chơi"
            onChange={(v) => applyChange("MaKVC", v)}
            options={khuvuichoi}
            fieldNames={{ label: "TenKhuVuiChoi", value: "ID" }}
          />
        </Col>
        <Col span={12}>
          <p>Gói dịch vụ</p>
          <Select
            allowClear
            style={{
              width: "100%",
            }}
            value={data?.MaGDV}
            placeholder="Chọn gói dịch vụ"
            onChange={(v) => applyChange("MaGDV", v)}
            options={goidichvu}
            fieldNames={{ label: "TenGoi", value: "ID" }}
          />
        </Col>

        <Col span={6}>
          {data.LinkImage && <Image src={data.LinkImage} />}
          <FileUpload
            mode="basic"
            accept="image/*"
            maxFileSize={1000000}
            onSelect={(e) => setFiles(e.files)}
            chooseLabel="Tải ảnh"
          />
        </Col>
      </Row>
    );
  };
  const renderDinhLuong = () => {
    const applyChangeSP = async (p, v, index) => {
      let _data = _.cloneDeep(data);
      const SUM = (value) => {
        value.ThanhTien = value.DonGia * value.SoLuong;
      };
      switch (p) {
        default:
          _data.DinhLuong[index][p] = v;
          SUM(_data.DinhLuong[index]);
          break;
      }
      setData(_data);
    };
    const addCoCau = () => {
      let _data = _.cloneDeep(data);
      _data.DinhLuong.push({
        SoLuong: 0,
        DonGia: 0,
        ThanhTien: 0,
      });
      setData(_data);
    };
    const deleteCoCau = (v, index) => {
      let _data = _.cloneDeep(data);
      _data.DinhLuong.splice(index, 1);
      setData(_data);
      if (v.ID) {
         SanPhamService.deleteDinhLuong({ ID: v.ID });
      }
    };
    return (
      <div>
        <Button
          loading={loading}
          onClick={() => addCoCau()}
          type="primary"
          ghost
        >
          Thêm
        </Button>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={data.DinhLuong}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            header="Sản phẩm"
            body={(item, row) => {
              return (
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  value={item?.MaSPNVL}
                  placeholder="Chọn sản phẩm"
                  onChange={(v) => applyChangeSP("MaSPNVL", v, row.rowIndex)}
                  options={sanpham}
                  fieldNames={{ label: "TenSP", value: "ID" }}
                />
              );
            }}
          />
          <Column
            header="ĐVT"
            style={{ width: "100px" }}
            body={(item, row) => {
              return (
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={item?.MaDVT}
                  placeholder="Chọn ĐVT"
                  onChange={(v) => applyChangeSP("MaDVT", v, row.rowIndex)}
                  options={dvt}
                  fieldNames={{ label: "TenDVT", value: "MaDVT" }}
                />
              );
            }}
          />
          <Column
            style={{ width: "100px" }}
            header="Số lượng"
            body={(item, row) => {
              return (
                <InputNumber
                  placeholder="Số lượng"
                  style={{ width: "100%" }}
                  value={item?.SoLuong}
                  onChange={(v) => applyChangeSP("SoLuong", v, row.rowIndex)}
                />
              );
            }}
          />
          <Column
            header="Đơn giá"
            style={{ width: "150px" }}
            body={(item, row) => {
              return (
                <InputNumber
                  placeholder="Đơn giá"
                  style={{ width: "100%" }}
                  value={item?.DonGia}
                  onChange={(v) => applyChangeSP("DonGia", v, row.rowIndex)}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              );
            }}
          />
          <Column
            style={{ width: "150px" }}
            header="Thành tiền"
            body={(item, row) => {
              return (
                <InputNumber
                  placeholder="Thành tiền"
                  style={{ width: "100%" }}
                  value={item?.ThanhTien}
                  disabled
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              );
            }}
          />

          <Column
            style={{ width: "50px" }}
            body={(item, row) => {
              return (
                <Button
                  onClick={() => deleteCoCau(item, row.rowIndex)}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              );
            }}
          />
        </DataTable>
      </div>
    );
  };
  return (
    <Drawer
      title="Sản phẩm"
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          // paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={() => onSubmit()}>Lưu</Button>
          <Button onClick={() => onSubmit("print")} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              {item.key === "0" ? renderThongTinChung() : renderDinhLuong()}
            </TabPane>
          );
        })}
      </Tabs>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
