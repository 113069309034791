import { Box, useEditable } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  Select,
  Checkbox,
  DatePicker,
} from "antd";
import _ from "lodash";
import locale from "antd/es/date-picker/locale/vi_VN";
import dayjs from "dayjs";
import { SanPhamService } from "services/SanPhamService";
import { BanHangService } from "services/BanHangService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    ApDung: true,
  });
  const [quay, setQuay] = useState([]);

  const [open, setOpen] = useState(false);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const loadCategories = () => {
    BanHangService.getQuayBan().then((res) => setQuay(res.data ?? []));
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      setData(data);
      loadCategories();
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onDelete = async () => {
    setLoading(true);
    let _res = await SanPhamService.Categories.deleteBangGia({ ID: data.ID });
    if (_res.status === 2000) {
      onAfterSubmit(true);
      openNotification("success", "topRight", `Xoá thành công!`);
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = await SanPhamService.Categories.addBangGia(_data);
    setLoading(false);
    if (_res.status === 2000) {
      if (!_data.ID) {
        setData((pre) => {
          return { ...pre, ID: _res.data };
        });
      }
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} danh mục thành công!`
      );
      onAfterSubmit();
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderTab1 = () => {
    return (
      <Row gutter={[10, 15]} style={{ marginTop: 20 }}>
        <Col span={24}>
          <p>Tên bảng giá</p>
          <Input
            value={data?.TenBangGia}
            placeholder="Nhập tên bảng giá"
            onChange={(e) => applyChange("TenBangGia", e.target.value)}
          />
        </Col>
        {!data?.isCuoiTuan && (
          <>
            <Col span={12}>
              <p>Từ ngày</p>
              <DatePicker
                defaultValue={
                  data?.TuNgay ? dayjs(data?.TuNgay) : dayjs(new Date())
                }
                style={{ width: "100%" }}
                allowClear={false}
                format="DD-MM-YYYY"
                locale={locale}
                onChange={(v) => applyChange("TuNgay", v)}
              />
            </Col>
            <Col span={12}>
              <p>Đến ngày</p>
              <DatePicker
                defaultValue={
                  data?.DenNgay ? dayjs(data?.DenNgay) : dayjs(new Date())
                }
                style={{ width: "100%" }}
                allowClear={false}
                format="DD-MM-YYYY"
                locale={locale}
                onChange={(v) => applyChange("DenNgay", v)}
              />
            </Col>
          </>
        )}
        <Col span={24}>
          <Select
            placeholder="Chọn quầy bán"
            value={data?.MaQuay}
            onChange={(v) => applyChange("MaQuay", v)}
            style={{
              width: "100%",
            }}
            options={quay}
            fieldNames={{ label: "TenQuay", value: "ID" }}
          />
        </Col>
        <Col span={24}>
          <Checkbox
            checked={data?.MacDinh}
            onChange={(v) => applyChange("MacDinh", v.target.checked)}
          >
            Mặc định
          </Checkbox>
          <Checkbox
            checked={data?.ApDung}
            onChange={(v) => applyChange("ApDung", v.target.checked)}
          >
            Áp dụng
          </Checkbox>
          <Checkbox
            checked={data?.isCuoiTuan}
            onChange={(v) => applyChange("isCuoiTuan", v.target.checked)}
          >
            Cuối tuần
          </Checkbox>
        </Col>
      </Row>
    );
  };

  return (
    <Drawer
      title="Bảng giá"
      width={450}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button loading={loading} onClick={() => onSubmit()}>
            Lưu
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button>
          {data?.ID && (
            <Button ghost danger loading={loading} onClick={() => onDelete()}>
              Xoá
            </Button>
          )}
        </Space>
      }
    >
      {contextHolder}
      {renderTab1()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
