import axiosApi from "./api_helper";

export const KhoHangService = {
  NhapKho: {
    filter: async (payload) => {
      return await axiosApi()
        .post("api/nhapkho/get-list", payload)
        .then((res) => res.data);
    },
    getLoai: async () => {
      return await axiosApi()
        .post("api/nhapkho/get-loai")
        .then((res) => res.data);
    },
    getSanPhamByMaPN: async (payload) => {
      return await axiosApi()
        .post("api/nhapkho/get-sanpham-bymank", payload)
        .then((res) => res.data);
    },
    deleteSanPham: async (payload) => {
      return await axiosApi()
        .post("api/nhapkho/delete-sanpham", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/nhapkho/add", payload)
        .then((res) => res.data);
    },
    update: async (payload) => {
      return await axiosApi()
        .post("api/nhapkho/update", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/nhapkho/delete", payload)
        .then((res) => res.data);
    },
    getTenKho: async () => {
      return await axiosApi()
        .post("api/nhapkho/get-name")
        .then((res) => res.data);
    },
  },
  XuatKho: {
    filter: async (payload) => {
      return await axiosApi()
        .post("api/xuatkho/get-list", payload)
        .then((res) => res.data);
    },
    getLoai: async () => {
      return await axiosApi()
        .post("api/xuatkho/get-loai")
        .then((res) => res.data);
    },
    getSanPhamByMaXK: async (payload) => {
      return await axiosApi()
        .post("api/xuatkho/get-sanpham-bymaxk", payload)
        .then((res) => res.data);
    },
    deleteSanPham: async (payload) => {
      return await axiosApi()
        .post("api/xuatkho/delete-sanpham", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/xuatkho/add", payload)
        .then((res) => res.data);
    },
    update: async (payload) => {
      return await axiosApi()
        .post("api/xuatkho/update", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/xuatkho/delete", payload)
        .then((res) => res.data);
    },
  },
  KiemKe: {
    filter: async (payload) => {
      return await axiosApi()
        .post("api/kiemke/get-list", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/kiemke/add", payload)
        .then((res) => res.data);
    },
    update: async (payload) => {
      return await axiosApi()
        .post("api/kiemke/update", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/kiemke/delete", payload)
        .then((res) => res.data);
    },
    changeState: async (payload) => {
      return await axiosApi()
        .post("api/kiemke/change-state", payload)
        .then((res) => res.data);
    },
    getChiTietTheoMaKK: async (payload) => {
      return await axiosApi()
        .post("api/kiemke/get-chitiet", payload)
        .then((res) => res.data);
    },
    getTonKho: async (payload) => {
      return await axiosApi()
        .post("api/kiemke/get-tonkho", payload)
        .then((res) => res.data);
    },
  },
  BaoCao: {
    getTonKho: async (payload) => {
      return await axiosApi()
        .post("api/report/get-tonkho", payload)
        .then((res) => res.data);
    },
  },
};