import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Row,
  Col,
} from "antd";
import FilterModal from "./FilterModal";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { OtherService } from "services/OtherService";
import { Format_Datetime } from "layouts/ultis/common";

export default function Index() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    NhanVien: ",,",
  });
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await OtherService.getLichSuThaoTac(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const state = {
    NEW: "Mới",
    PAYMENT: "Đã thanh toán",
    CANCEL: "Đã huỷ",
    REJECT: "Từ chối",
    ACCEPT: "Đã duyệt",
    RETURN: "Hoàn trả",
  };
  const columns = [
    {
      title: "Modules",
      dataIndex: "Modules",
      // render: (item) => Format_Date(item),
    },
    {
      title: "Số giao dịch",
      dataIndex: "SoGiaoDich",
    },
    {
      title: "Nội dung",
      dataIndex: "NoiDung",
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => Format_Datetime(item),
    },
  ];
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          // left={() => (
          //   <Search
          //     style={{width:"300px"}}
          //     placeholder="Tìm kiếm"
          //     // loading
          //     enterButton
          //   />
          // )}
        />
        <Table
          style={{
            height: 100,
            // boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            // borderRadius:0
          }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            // x: 2500
          }}
          size="small"
        />
      </Col>
    </Row>
  );
}
