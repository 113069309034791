import { Flex, Image } from "antd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Time } from "layouts/ultis/common";
import { Format_Datetime } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import * as React from "react";
import { NhanVienService } from "services/NhanVienService";

const HoaDonToPrint = (props, ref) => {
  const { data, qrurl } = props;
  const [nhanvien, setNhanVien] = React.useState({});

  React.useEffect(() => {
    NhanVienService.getDetail().then((res) => {
      setNhanVien(res.data ?? []);
    });
  }, []);
  return (
    <div ref={ref} id="in-banhang" style={{ margin: "0px 5px 5px" }}>
      <div style={{ textAlign: "center", padding: "15px 0px" }}>
        <p style={{ fontSize: 15, fontWeight: "bold" }}>{nhanvien?.TenCT}</p>
        <p style={{ fontSize: 13 }}>{nhanvien?.DiaChi}</p>
        <p>{nhanvien?.DienThoai}</p>
      </div>
      <div style={{ padding: "10px 0px" }}>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          HOÁ ĐƠN BÁN HÀNG
        </p>
        <Flex justify="space-between">
          <p style={{ fontSize: 11 }}>Ngày: {Format_Date(new Date())}</p>
          <p style={{ fontSize: 11 }}>{data?.SoBH}</p>
        </Flex>
        <Flex justify="space-between">
          <p style={{ fontSize: 11 }}>Thu ngân: {nhanvien?.HoTen}</p>
          <p style={{ fontSize: 11 }}>Khách hàng: {data?.TenCongTy}</p>
        </Flex>
        <Flex justify="space-between">
          <p style={{ fontSize: 15 }}>
            In lúc:{" "}
            {new Date().getHours() < 10
              ? "0" + new Date().getHours()
              : new Date().getHours()}
            :
            {new Date().getMinutes() < 10
              ? "0" + new Date().getMinutes()
              : new Date().getMinutes()}
          </p>
          <p style={{ fontSize: 11 }}>Bàn: {data?.TenBan}</p>
        </Flex>
      </div>

      <table style={{ width: "100%" }}>
        <tr style={{ textAlign: "left", borderBottom: "2px solid black" }}>
          <th style={{ fontSize: 11 }}>Mặt hàng</th>
          <th style={{ fontSize: 11 }}>ĐVT</th>
          <th style={{ fontSize: 11 }}>SL</th>
          <th style={{ fontSize: 11 }}>Đ.Giá</th>
          <th style={{ fontSize: 11 }}>T.Tiền</th>
        </tr>
        {data?.SanPham?.map((item) => {
          return (
            <tr>
              <td style={{ width: "40%", fontSize: 11 }}>
                {item.TenSP}{" "}
                {item.MaNhom === 7 &&
                  `(Từ : ${Format_Datetime(item?.GioVao)} đến 
                    ${
                      new Date(item?.GioVao).getDate() ===
                      new Date(item.GioRa).getDate()
                        ? Format_Time(item.GioRa)
                        : Format_Datetime(item.GioRa)
                    })`}
              </td>
              <td style={{ width: "10%", fontSize: 11 }}>{item.MaDVT}</td>
              <td style={{ width: "10%", fontSize: 11 }}>{item.SoLuong}</td>
              <td style={{ width: "20%", fontSize: 11 }}>
                {Format_Currency(item.DonGia)}
              </td>
              <td style={{ width: "20%", fontSize: 11 }}>
                {Format_Currency(item.DonGia * item.SoLuong - item.TienCK)}
              </td>
            </tr>
          );
        })}
        <tfoot
          style={{
            margin: "10px 0px",
            borderTop: "1px solid gray",
          }}
        >
          <tr>
            <td style={{ fontSize: 11 }}>Tổng cộng</td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ fontSize: 11 }}>{Format_Currency(data?.ThanhTien)}</td>
          </tr>
          <tr>
            <td style={{ fontSize: 11 }}>Chiết khấu</td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ fontSize: 11 }}>{Format_Currency(data?.TienCK)}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold", fontSize: 12 }}>Khách phải trả</td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ fontWeight: "bold", fontSize: 12 }}>
              {Format_Currency(data?.ThanhTien - (data?.TienCK ?? 0))}
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: 12 }}>Hình thức thanh toán</td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ fontSize: 12 }}>{data?.HinhThuc}</td>
          </tr>
        </tfoot>
      </table>
      {(data?.HinhThucTT === 2 || data?.HinhThucTT === 4) && (
        <Flex align="center" justify="center">
          <Image style={{ textAlign: "center" }} width={200} src={qrurl} />
        </Flex>
      )}

      <p
        style={{
          fontStyle: "italic",
          fontSize: 12,
          textAlign: "center",
        }}
      >
        {nhanvien?.TenCT} cảm ơn quý khách, hẹn gặp lại.
      </p>
      <p
        style={{
          fontStyle: "italic",
          fontSize: 12,
          textAlign: "center",
        }}
      >
        Powered by beesky.vn
      </p>
    </div>
  );
};
export default React.forwardRef(HoaDonToPrint);
