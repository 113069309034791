import React, { useState } from "react";

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import Links from "components/sidebar/components/Links";
import { IoMenuOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  notification,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import _ from "lodash";
import { NhanVienService } from "services/NhanVienService";
import Notification from "./Notification";
function Sidebar(props) {
  const { routes } = props;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [api, contextHolder] = notification.useNotification();

  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarMargins = "0px";

  // SIDEBAR
  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("url");
    sessionStorage.removeItem("token");
    history.push("/auth/sign-in");
  };

  const items = [
    {
      key: "1",
      label: "Đổi mật khẩu",
      onClick: () => setOpen(true),
    },
    {
      key: "2",
      label: "Đăng xuất",
      onClick: () => logout(),
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const renderModal = () => {
    const hide = () => {
      setOpen(false);
      //  setLydo("");
    };
    const applyChange = (p, v) => {
      let _data = _.cloneDeep(data);
      _data[p] = v;
      setData(_data);
      //  setLydo(v);
    };
    const onSubmit = async () => {
      setLoading(true);
      let _res = await NhanVienService.changePass(data);
      if (_res.status === 2000) {
        openNotification("success", "topRight", `Đổi mật khẩu thành công!`);
        hide();
      } else {
        openNotification("error", "topRight", _res?.message);
      }
      setLoading(false);
    };
    return (
      <Modal
        title="Đổi mật khẩu"
        open={open}
        width={400}
        confirmLoading={loading}
        onOk={() => onSubmit()}
        onCancel={hide}
        okText="Lưu"
        cancelText="Huỷ"
      >
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <p>Mật khẩu cũ</p>
            <Input
              style={{ width: "100%" }}
              value={data.PasswordCurrent}
              onChange={(v) => applyChange("PasswordCurrent", v.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Mật khẩu mới</p>
            <Input
              style={{ width: "100%" }}
              value={data.PasswordNew}
              onChange={(v) => applyChange("PasswordNew", v.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Nhập lại mật khẩu mới</p>
            <Input
              style={{ width: "100%" }}
              value={data.PasswordRe}
              onChange={(v) => applyChange("PasswordRe", v.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  return (
    <Flex
      align="center"
      className="box-header"
      gap={0}
      style={{ width: "100%", background: "#bae0ff"}}
    >
      <Links routes={routes} />
      <Flex align={"center"} style={{ padding: "0px 20px" }}>
        <Notification />
        <Dropdown menu={{ items }} placement="bottomRight">
          <Avatar size={35} icon={<UserOutlined />} />
        </Dropdown>
      </Flex>
      {/* <p
        onClick={() => logout()}
        style={{
          fontWeight: "bold",
          fontSize: 14,
          color: "white",
          width: 100,
          cursor: "pointer",
        }}
      >
        Đăng xuất
      </p> */}
      {renderModal()}
      {contextHolder}
    </Flex>
    // <Box display={{ sm: "none", xl: "block" }} w="100%" position='fixed' minH='100%'>
    //     <Box
    //       bg={sidebarBg}
    //       transition={variantChange}
    //       w='100%'
    //       h='100px'
    //       // m={sidebarMargins}
    //       // minH='100%'
    //       overflowX='hidden'
    //       boxShadow={shadow}>
    //       <Scrollbars
    //         autoHide
    //         renderTrackVertical={renderTrack}
    //         renderThumbVertical={renderThumb}
    //         renderView={renderView}>
    //         <Content routes={routes} />
    //       </Scrollbars>
    //     </Box>
    //   </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive(props) {
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  let menuColor = useColorModeValue("gray.400", "white");
  // // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { routes } = props;
  // let isWindows = navigator.platform.startsWith("Win");
  //  BRAND

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my="auto"
          w="20px"
          h="20px"
          me="10px"
          _hover={{ cursor: "pointer" }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent w="285px" maxW="285px" bg={sidebarBackgroundColor}>
          <DrawerCloseButton
            zIndex="3"
            onClose={onClose}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0">
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
            >
              <Content routes={routes} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
// PROPS

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
