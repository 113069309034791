import { Box, useEditable } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  Select,
} from "antd";
import _ from "lodash";
import { NhanVienService } from "services/NhanVienService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { SanPhamService } from "services/SanPhamService";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Bạn chỉ được phép upload file có định dạng JPG/PNG!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Ảnh tải lên phải nhỏ hơn 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const [kvc,setKvc] = useState([]);
  const loadKVC =async () =>{
      let _res = await SanPhamService.filterKhuVuiChoi();
      if (_res.data) setKvc(_res.data);
  }
  useImperativeHandle(ref, () => ({
    show: async (tab) => {
      setOpen(true);
      setActiveTab(tab);
      loadKVC();
    },
    update: async (data, tab) => {
      setOpen(true);
      setData(data);
      setActiveTab(tab);
      loadKVC();
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await SanPhamService.addKhuVuiChoi(_data);
        break;
      case "1":
        _res = await SanPhamService.addGoiDV(_data);
        break;
    }

    setLoading(false);
    if (_res.status === 2000) {
      if (!_data.ID) {
        setData((pre) => {
          return { ...pre, ID: _res.data };
        });
      }
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} danh mục thành công!`
      );
      onAfterSubmit();
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Drawer
      title="Danh mục"
      width={activeTab === "0" ? 920 : 450}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button loading={loading} onClick={() => onSubmit()}>
            Lưu
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {activeTab === "0" ? (
        <Row gutter={[24, 20]}>
          <Col span={24}>
            <p>Tên khu vui chơi</p>
            <Input
              value={data?.TenKhuVuiChoi}
              placeholder="Nhập tên khu vui chơi"
              onChange={(e) => applyChange("TenKhuVuiChoi", e.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Địa chỉ</p>
            <Input
              value={data?.DiaChi}
              placeholder="Nhập địa chỉ"
              onChange={(e) => applyChange("DiaChi", e.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Giởi thiệu chung</p>
            <CKEditor
              // editor={ClassicEditor}
              data={data.GioiThieuChung ?? ""}
              config={{
                plugins: ["Paragraph", "Bold", "Italic", "Essentials"],
                toolbar: ["bold", "italic"],
              }}
              // onReady={(editor) => {
              //   // You can store the "editor" and use when it is needed.
              //   console.log("Editor is ready to use!", editor);
              // }}
              onChange={(event, editor) => {
                applyChange("GioiThieuChung", editor.getData());
                // console.log(editor.getData());
              }}
              // onBlur={(event, editor) => {
              //   console.log("Blur.", editor);
              // }}
              // onFocus={(event, editor) => {
              //   console.log("Focus.", editor);
              // }}
            />
          </Col>
          <Col span={24}>
            <p>Chính sách chung</p>
            <CKEditor
              editor={ClassicEditor}
              config={{
                plugins: ["Paragraph", "Bold", "Italic", "Essentials"],
                toolbar: ["bold", "italic"],
              }}
              data={data.ChinhSachChung ?? ""}
              onChange={(event, editor) => {
                applyChange("ChinhSachChung", editor.getData());
              }}
            />
          </Col>
          <Col span={24}>
            <p>Chính sách huỷ</p>
            <CKEditor
              editor={ClassicEditor}
              config={{
                plugins: ["Paragraph", "Bold", "Italic", "Essentials"],
                toolbar: ["bold", "italic"],
              }}
              data={data.ChinhSachHuy ?? ""}
              onChange={(event, editor) => {
                applyChange("ChinhSachHuy", editor.getData());
              }}
            />
          </Col>
          <Col span={24}>
            <p>Chính sách thanh toán</p>
            <CKEditor
              editor={ClassicEditor}
              config={{
                plugins: ["Paragraph", "Bold", "Italic", "Essentials"],
                toolbar: ["bold", "italic"],
              }}
              data={data.ChinhSachThanhToan ?? ""}
              onChange={(event, editor) => {
                applyChange("ChinhSachThanhToan", editor.getData());
              }}
            />
          </Col>
        </Row>
      ) : (
        <Row gutter={[24, 20]}>
          <Col span={24}>
            <p>Tên gói</p>
            <Input
              value={data?.TenGoi}
              placeholder="Nhập tên gói"
              onChange={(e) => applyChange("TenGoi", e.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Khu vui chơi</p>
            <Select
              style={{
                width: "100%",
              }}
              value={data?.MaKVC}
              placeholder="Chọn khu vui chơi"
              onChange={(v) => applyChange("MaKVC", v)}
              options={kvc}
              fieldNames={{ label: "TenKhuVuiChoi", value: "ID" }}
            />
          </Col>
        </Row>
      )}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
