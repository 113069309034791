import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Popconfirm, Row, Table, Tooltip, notification } from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { SoQuyService } from "services/SoQuyService";
import { CongTyService } from "services/CongTyService";
import { OtherService } from "services/OtherService";
export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaiPT, setLoaiPT] = useState([]);
  const [hinhThucTT, setHinhThucTT] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    ListMaCT: ",,",
    MaCT: 0,
    LoaiThu: ",,",
    HinhThuc: ",,",
  });
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    // let _resCT = await CongTyService.getCongTy();
    // setCompanies(_resCT.data);
     OtherService.getHinhThucTT().then((res) => setHinhThucTT(res.data ?? []));
     SoQuyService.PhieuThu.getLoaiPhieuThu().then((res) =>
       setLoaiPT(res.data ?? [])
     );
  };
  useEffect(() => {
    filter(filterCondition);
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await SoQuyService.PhieuThu.getPhieuThu(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const columns = [
    {
      title: "Ngày thu",
      dataIndex: "NgayThu",
      render: (item) => Format_Date(item),
    },
    {
      title: "Số tiền",
      dataIndex: "ThanhTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Lý do",
      dataIndex: "LyDo",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
    {
      title: "Hình thức",
      dataIndex: "MaHinhThucTT",
      render: (item) => hinhThucTT.find((v) => v.ID === item)?.TenHinhThuc,
    },
    {
      title: "Loại thu",
      dataIndex: "MaLPT",
      render: (item) => loaiPT.find((v) => v.ID === item)?.TenLPT,
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
    },
    {
      title: "",
      fixed: "right",
      width: "100px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá phiếu thu"
              description="Bạn có chắc chắn muốn xoá phiếu thu?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await SoQuyService.PhieuThu.deletePhieuThu({ ID: Number(value) });
    if(_res.status === 2000){
      let _data = _.cloneDeep(data);
      let fil = _data.filter(v=>v.ID !==Number(value) );
      setData(fil);
      openNotification(
        "success",
        "topRight",
        "Xoá phiếu thu thành công"
      );
    }else{
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          hinhThuc={hinhThucTT}
          loai={loaiPT}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          right={() => (
            <Button type="primary" onClick={() => refDetail.current.show()}>
              Thêm
            </Button>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            // x: 2500
          }}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                   <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "ThanhTien")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}}
        />
      </Col>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        hinhThuc={hinhThucTT}
        loai={loaiPT}
      />
      {contextHolder}
    </Row>
  );
}
