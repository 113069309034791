import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  InputNumber,
  Row,
  Col,
  Drawer,
  notification,
  Space,
  Checkbox,
  Flex,
  DatePicker,
  Select,
  Upload,
} from "antd";

import _, { trimEnd } from "lodash";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import DebounceSelect from "components/DebounceSelect";
import { SanPhamService } from "services/SanPhamService";
import { CustomerService } from "services/CustomerService";
import { ContractService } from "services/ContractService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CiTrash } from "react-icons/ci";
import locale from "antd/es/date-picker/locale/vi_VN";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { KhoHangService } from "services/KhoHangService";
import { UploadOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const { Search } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    SanPhamChoose: {},
    SanPham: [],
    NgayKiemKe: new Date(),
    SoLuong: 1,
    GhiChu: "Kiểm kê định kỳ",
  };
  const defaultError = {
    MaKho: null,
  };
  const [errors, setErrors] = useState(defaultError);
  const [khohang, setKhohang] = useState([]);
  const [data, setData] = useState(defaulData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isbarcode, setIsbarcode] = useState(false);
  const loadcategories = async () => {
    // let _lhd = await ContractService.getLoaiHD();
    // let _tt = await ContractService.getTrangThaiHD();
    // let _lt = await ContractService.getLoaiTien();
    // let _dvt = await SanPhamService.getDVT();
    // setLoaihd(_lhd.data ?? []);
    // setTrangthai(_tt.data ?? []);
    // setLoaiTien(_lt.data ?? []);
    // setDvt(_dvt.data ?? []);
    let _kho = await KhoHangService.NhapKho.getTenKho();
    setKhohang(_kho.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadcategories();
    },
    update: async (data) => {
      setOpen(true);
      let _sp = await KhoHangService.KiemKe.getChiTietTheoMaKK({
        MaKK: data.ID,
      });
      data.SanPham = _sp?.data ?? [];
      setData(data);
      loadcategories();
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "SanPhamChoose":
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const applyChangeSP = async (p, v, index) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "KiemKe":
        _data.SanPham[index][p] = v;
        _data.SanPham[index].ChenhLech = v - _data.SanPham[index].TonDau;
        if (_data.SanPham[index].ChenhLech < 0) {
          _data.SanPham[index].XuLy = "XUAT";
        } else if (_data.SanPham[index].ChenhLech > 0) {
          _data.SanPham[index].XuLy = "NHAP";
        }else{
          _data.SanPham[index].XuLy = "";
        }
        break;
      default:
        _data.SanPham[index][p] = v;
        // SUM(_data.SanPham[index]);
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _error = _.cloneDeep(errors);
    Object.keys(_error)?.map((item) => {
      _error[item] = data[item] ? null : "Vui lòng chọn kho hàng";
    });
    setErrors(_error);
    let valid = _.uniq(Object.values(_error)?.filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _data = _.cloneDeep(data);
    let _res = null;
    const sanpham = [];
    _data?.SanPham?.map((item) => {
      sanpham.push({
        ...item,
        MaKho: _data.MaKho,
        MaSP: item.IDSP,
        SoLuongTon: item.TonDau,
      });
    });
    let _payload = {
      ..._data,
      ChiTiet: sanpham,
    };
    if (_data.ID) {
      _res = await KhoHangService.KiemKe.update(_payload);
    } else {
      _res = await KhoHangService.KiemKe.add(_payload);
      _data.ID = _res.data;
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Kiểm kê thành công!`);
      if (close) {
        onClose();
      } else {
        let _sp = await KhoHangService.KiemKe.getChiTietTheoMaKK({
          MaKK: _data.ID,
        });
        _data.SanPham = _sp.data ?? [];
        setData(_data);
      }
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onSubmitXuLy = async () => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    const sanphamnhap = [];
    const sanphamxuat = [];
    _data?.SanPham?.map((item) => {
      if (item.XuLy === "NHAP") {
        sanphamnhap.push({
          ...item,
          SoLuong: item.ChenhLech,
          DonGia: item.GiaMua,
          ThanhTien : item.ChenhLech * item.GiaMua
        });
      } else if (item.XuLy === "XUAT") {
        sanphamxuat.push({
          ...item,
          SoLuong: Math.abs(item.ChenhLech),
          DonGia: item.GiaMua,
          ThanhTien: Math.abs(item.ChenhLech) * item.GiaMua,
        });
      }
    });
    if(sanphamnhap.length > 0){
      let _payload = {
        NgayNK: _data.NgayKiemKe,
        MaKho: _data.MaKho,
        DienGiai: _data.GhiChu,
        MaLoai: 2,
        SanPham: sanphamnhap,
      };
      let _res = await KhoHangService.NhapKho.add(_payload);
    }
    if (sanphamxuat.length > 0) {
      let _payload = {
        NgayXK: _data.NgayKiemKe,
        MaKho: _data.MaKho,
        DienGiai: _data.GhiChu,
        MaLoai: 2,
        SanPham: sanphamxuat,
      };
      let _res = await KhoHangService.XuatKho.add(_payload);
    }
    let _res = await KhoHangService.KiemKe.changeState({ID: _data.ID});
    setLoading(false);
    onClose();
    openNotification("success", "topRight", `Xử lý thành công thành công!`);

  };
  const renderCoCauGiai = () => {
    const getTonKho = async () => {
      setLoading(true);
      let _res = await KhoHangService.KiemKe.getTonKho({
        DenNgay: data?.NgayKiemKe,
        MaKho: data?.MaKho,
      });
      let _data = _.cloneDeep(data);
      _data.SanPham = _res?.data ?? [];
      setData(_data);
      setLoading(false);
    };
    return (
      <Row style={{ height: "100%" }}>
        <Col span={20}>
          {/* <Row align="middle" style={{ padding: "10px 0px" }}>
            <Col span={3}>
              <Checkbox
                checked={isbarcode}
                onChange={(v) => setIsbarcode(v.target.checked)}
              >
                Barcode
              </Checkbox>
            </Col>
            {isbarcode ? (
              <Col span={8}>
                <Search
                  onKeyDown={(e) => handleKeyDown(e)}
                  value={data?.MaVach}
                  placeholder="Nhập mã vạch"
                  onChange={(e) => applyChange("MaVach", e.target.value)}
                  loading ={loading}
                />
              </Col>
            ) : (
              <>
                <Col span={8}>
                  <DebounceSelect
                    showSearch
                    placeholder="Nhập mã hàng cần tìm kiếm"
                    fetchOptions={fetchList}
                    value={data?.SanPhamChoose}
                    onChange={(newValue) =>
                      applyChange("SanPhamChoose", newValue)
                    }
                    style={{
                      width: "100%",
                    }}
                  />
                </Col>
                <Col span={5}>
                  <Flex align="center" justify="flex-end">
                    <p>Số lượng:</p>
                    <InputNumber
                      style={{ width: "50%" }}
                      value={data?.SoLuong}
                      onChange={(v) => applyChange("SoLuong", v)}
                    />
                  </Flex>
                </Col>
                <Col span={5}>
                  <Flex align="center" justify="center">
                    <p>Giá:</p>
                    <InputNumber
                      value={data?.DonGia}
                      style={{ width: "60%" }}
                      onChange={(v) => applyChange("DonGia", v)}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                    />
                  </Flex>
                </Col>
                <Col span={1}>
                  <Button
                    loading={loading}
                    onClick={() => addCoCau()}
                    type="primary"
                    ghost
                  >
                    Thêm
                  </Button>
                </Col>
              </>
            )}
          </Row> */}
          <DataTable
            size="small"
            showGridlines
            stripedRows
            value={data.SanPham}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column
              header="Mã vạch"
              style={{ width: "100px" }}
              body={(item, row) => <p>{item.MaVach}</p>}
            />
            <Column
              header="Sản phẩm"
              style={{ width: "300px" }}
              body={(item, row) => <p>{item.TenSP}</p>}
            />
            <Column
              header="SL tồn"
              style={{ width: "70px" }}
              body={(item, row) => <p>{item.TonDau ?? item.SoLuongTon}</p>}
            />
            <Column
              header="Kiểm kê"
              style={{ width: "100px" }}
              body={(item, row) => {
                return (
                  <InputNumber
                    style={{ width: "100%" }}
                    value={item?.KiemKe}
                    onChange={(v) => applyChangeSP("KiemKe", v, row.rowIndex)}
                  />
                );
              }}
            />
            <Column
              header="Chênh lệch"
              style={{ width: "100px" }}
              body={(item, row) => <p>{item.ChenhLech}</p>}
            />
            <Column
              header="Lý do"
              body={(item, row) => {
                return (
                  <Input
                    style={{ width: "100%" }}
                    value={item?.GhiChu}
                    onChange={(e) =>
                      applyChangeSP("GhiChu", e.target.value, row.rowIndex)
                    }
                  />
                );
              }}
            />
            <Column
              header="Xử lý"
              body={(item, row) => (
                <p>
                  {item.XuLy === "NHAP"
                    ? "Nhập kho"
                    : item.XuLy === "XUAT"
                    ? "Xuất kho"
                    : ""}
                </p>
              )}
            />
          </DataTable>
        </Col>
        <Col span={4} style={{ borderLeft: "1px solid #ced4da", padding: 10 }}>
          <p>Kho hàng</p>
          <Select
            style={{
              width: "100%",
            }}
            // status={data?.MaLT ? "" : "error"}
            disabled={data?.ID}
            value={data?.MaKho}
            placeholder="Chọn kho hàng"
            onChange={(v) => applyChange("MaKho", v)}
            options={khohang}
            fieldNames={{ label: "TenKho", value: "ID" }}
          />
          <p style={{ color: "red" }}>{errors?.MaKho}</p>
          <p style={{ marginTop: 10 }}>Đến ngày</p>
          <DatePicker
            disabled={data?.ID}
            defaultValue={
              data?.NgayKiemKe ? dayjs(data?.NgayKiemKe) : dayjs(new Date())
            }
            style={{ width: "100%" }}
            allowClear={false}
            format="DD-MM-YYYY"
            locale={locale}
            placeholder="Chọn ngày sử dụng dịch vụ"
            onChange={(v) => applyChange("NgayKiemKe", v)}
          />
          <Button
            loading={loading}
            disabled={data?.ID}
            onClick={() => getTonKho()}
            type="primary"
            style={{ width: "100%", marginTop: 10 }}
            ghost
          >
            Lấy tồn kho
          </Button>

          <p style={{ marginTop: 20 }}>Mục đích</p>
          <Input
            disabled
            value={data?.GhiChu}
            // onChange={(e) => applyChange("DienGiai", e.target.value)}
            // placeholder="Nhập diễn giải"
          />
        </Col>
      </Row>
    );
  };
  const loadDataImport = async (value) => {
    let _res = await SanPhamService.getList({ InputSearch: "" });
    console.log(_res);
    let res = [];
    value?.map((item, i) => {
      if (i > 0) {
        let _sp = _res.data?.find((v) => v.MaVach === item[0]);
        if (_sp) {
          res.push({
            SoLuong: item[1],
            DonGia: item[2],
            MaSP: _sp.ID,
            ThanhTien: item[1] * item[2],
            MaDVT: _sp?.MaDVT,
            TenSP: _sp?.TenSP,
          });
        }
      }
    });
    let _data = _.cloneDeep(data);
    _data.SanPham = res;
    console.log(_data);
    setData(_data);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    let _data = [
      {
        MaVach: "123321",
        SoLuong: 25,
        DonGia: 100000,
        ThanhTien: 100,
      },
      {
        MaVach: "123321",
        SoLuong: 12,
        DonGia: 200000,
        ThanhTien: 100,
      },
    ];
    const ws = XLSX.utils.json_to_sheet(_data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "template_nhapkho" + fileExtension);
  };
  return (
    <Drawer
      title="Kiểm kê"
      width="1220px"
      onClose={onClose}
      open={open}
      className="kiemke-detail"
      extra={
        <Space>
          {/* <Upload
            accept=".txt, .csv, .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                loadDataImport(data);
                // setNewFile(true);
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Import</Button>
          </Upload>
          <Button icon={<UploadOutlined />} onClick={() => exportToCSV()}>
            Export
          </Button> */}
          <Button
            disabled={
              !data.ID ||
              data.State === "DONE" ||
              !data.SanPham?.some((v) => v.XuLy === "NHAP" || v.XuLy === "XUAT")
            }
            loading={loading}
            onClick={() => onSubmitXuLy(false)}
            type="primary"
            ghost
          >
            Xử lý
          </Button>
          <Button
            disabled={data.State === "DONE"}
            loading={loading}
            onClick={() => onSubmit(false)}
            type="primary"
            ghost
          >
            Lưu
          </Button>
          <Button
            disabled={data.State === "DONE"}
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderCoCauGiai()}
      {/* <div style={{ display: "none" }}>
        <HoaDonToPrint ref={componentRef} data={data} />
      </div> */}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
