import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Popconfirm,
  Table,
  Tabs,
  Tooltip,
  notification,
  Flex,
} from "antd";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import TabPane from "antd/es/tabs/TabPane";
import { SanPhamService } from "services/SanPhamService";
import { RiImageAddLine } from "react-icons/ri";
import FormAddImage from "./FormAddImage";
import { Format_Date } from "layouts/ultis/common";
import { CRMService } from "services/CRMService";
import { Editor } from "primereact/editor";
import { NhanVienService } from "services/NhanVienService";

const NhaCungCap = [
  {
    ID: 1,
    Name: "FPT",
  },
  {
    ID: 2,
    Name: "ESMS",
  },
  {
    ID: 3,
    Name: "VNPT",
  },
];
export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const refDetail = useRef(null);
  const refDetailImage = useRef(null);
  const [nhanvien, setNhanvien] = useState([]);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const tabs = [
    {
      title: "Mẫu SMS",
      key: "1",
    },
    {
      title: "Cấu hình SMS",
      key: "2",
    },
    {
      title: "Mẫu Email",
      key: "3",
    },
    {
      title: "Cấu hình Email",
      key: "4",
    },
    {
      title: "Mẫu Zalo",
      key: "5",
    },
    {
      title: "Cấu hình Zalo",
      key: "6",
    },
  ];
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    filter(activeTab);
    NhanVienService.getNhanVien().then((res) => setNhanvien(res.data ?? []));
    setColumns([
      {
        title: "Server",
        dataIndex: "Server",
      },
    ]);
  }, []);
  const filter = async (tab) => {
    try {
      setLoading(true);
      if (tab === "1") {
        let _res = await CRMService.Categories.getTempSMS();
        if (_res.data) setData(_res.data);
      } else if (tab === "2") {
        let _res = await CRMService.Categories.getConfigSMS();
        if (_res.data) setData(_res.data);
      } else if (tab === "3") {
        let _res = await CRMService.Categories.getTempMail();
        setData(_res.data ?? []);
      } else if (tab === "4") {
        let _res = await CRMService.Categories.getConfigMail();
        setData(_res.data ?? []);
      } else if (tab === "5") {
        let _res = await CRMService.Categories.getTempZalo();
        setData(_res.data ?? []);
      } else if (tab === "6") {
        let _res = await CRMService.Categories.getConfigZalo();
        setData(_res.data ?? []);
      }
      setLoading(false);
    } catch {}
  };
  const onAfterSubmit = () => {
    filter(activeTab);
  };
  const columns = [
    {
      title: "Tên mẫu",
      dataIndex: "TempName",
    },
    // {
    //   title: "Loại mẫu",
    //   dataIndex: "LoaiMau",
    // },
    {
      title: "Người tạo",
      dataIndex: "StaffID",
      render: (item) => nhanvien?.find((v) => v.MaNV === item)?.HoTen,
    },
    {
      title: "Ngày tạo",
      dataIndex: "DateCreate",
      render: (item) => Format_Date(item),
    },
    {
      title: "Người sửa",
      dataIndex: "StaffModify",
      render: (item) => nhanvien?.find((v) => v.MaNV === item)?.HoTen,
    },
    {
      title: "Ngày sửa",
      dataIndex: "DateModify",
      render: (item) => Format_Date(item),
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex justify="center">
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() =>
                  refDetail.current.update(
                    { ...item, ID: item.TempID },
                    activeTab
                  )
                }
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.TempID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];

  const [columns22,setColumns] = useState([])
  const columns2 = [
    {
      title: "Server",
      dataIndex: "Server",
    },
    {
      title: "BrandName",
      dataIndex: "BrandName",
    },
    {
      title: "Nhà cung cấp",
      dataIndex: "MaLoai",
      render: (item) => NhaCungCap.find((v) => v.ID === item)?.Name,
    },
    {
      title: "Public Key",
      dataIndex: "PublicKey",
    },
    {
      title: "Private Key",
      dataIndex: "PrivateKey",
    },
    {
      title: "Token",
      dataIndex: "Token",
    },
    {
      title: "Mặc định",
      dataIndex: "isDefault",
      render: (item) => (item ? "Mặc định" : null),
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex justify="center">
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const columns3 = [
    {
      title: "Tên mẫu",
      dataIndex: "TempName",
    },
    {
      title: "Người tạo",
      dataIndex: "StaffID",
      render: (item) => nhanvien?.find((v) => v.MaNV === item)?.HoTen,
    },
    {
      title: "Ngày tạo",
      dataIndex: "DateCreate",
      render: (item) => Format_Date(item),
    },
    {
      title: "Người sửa",
      dataIndex: "StaffModify",
      render: (item) => nhanvien?.find((v) => v.MaNV === item)?.HoTen,
    },
    {
      title: "Ngày sửa",
      dataIndex: "DateModify",
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "160px",
      render: (item) => {
        return (
          <div>
            {/* <Tooltip placement="topRight" title="Thêm hình ảnh">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<RiImageAddLine />}
                onClick={() => refDetailImage.current.show(item)}
              />
            </Tooltip> */}
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() =>
                  refDetail.current.update(
                    { ...item, ID: item.TempID },
                    activeTab
                  )
                }
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.TempID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns4 = [
    {
      title: "Tên hiển thị",
      dataIndex: "Display",
    },
    {
      title: "Email gửi",
      dataIndex: "Email",
    },
    {
      title: "Máy chủ gửi",
      dataIndex: "Server",
    },
    {
      title: "Port",
      dataIndex: "Port",
    },
    {
      title: "Gửi tối đa",
      dataIndex: "SendMax",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex justify="center">
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const columns5 = [
    {
      title: "Tên mẫu",
      dataIndex: "TempName",
    },
    {
      title: "Người tạo",
      dataIndex: "StaffID",
      render: (item) => nhanvien?.find((v) => v.MaNV === item)?.HoTen,
    },
    {
      title: "Ngày tạo",
      dataIndex: "DateCreate",
      render: (item) => Format_Date(item),
    },
    {
      title: "Người sửa",
      dataIndex: "StaffModify",
      render: (item) => nhanvien?.find((v) => v.MaNV === item)?.HoTen,
    },
    {
      title: "Ngày sửa",
      dataIndex: "DateModify",
      render: (item) => (item ? Format_Date(item) : null),
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex justify="center">
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const columns6 = [
    {
      title: "URL",
      dataIndex: "URL",
    },
    {
      title: "APPID",
      dataIndex: "AppID",
    },
    {
      title: "SecretKey",
      dataIndex: "SecretKey",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex justify="center">
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = null;
    switch (activeTab) {
      case "1":
        _res = await CRMService.Categories.deleteTempSMS({
          ID: Number(value),
        });
        break;
      case "2":
        _res = await CRMService.Categories.deleteConfigSMS({
          ID: Number(value),
        });
        break;
      case "3":
        _res = await CRMService.Categories.deleteTempMail({
          ID: Number(value),
        });
        break;
      case "4":
        _res = await CRMService.Categories.deleteConfigMail({
          ID: Number(value),
        });
        break;
      case "5":
        _res = await CRMService.Categories.deleteTempZalo({
          ID: Number(value),
        });
        break;
      case "6":
        _res = await CRMService.Categories.deleteConfigZalo({
          ID: Number(value),
        });
        break;
    }
    if (_res.status === 2000) {
      filter(activeTab);
      openNotification("success", "topRight", "Xoá danh mục thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
    filter(newActiveKey);
  };
  const GetColumn = (index) => {
    switch (index) {
      case "1":
        return columns;
        break;
      case "2":
        return columns2;
        break;
      case "3":
        return columns3;
        break;
      case "4":
        return columns4;
        break;
      case "5":
        return columns5;
        break;
      case "6":
        return columns6;
        break;
    }
  };
  return (
    <Box>
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              <Table
                loading={loading}
                dataSource={data}
                columns={GetColumn(item.key)}
                scroll={{
                  y: window.innerHeight - 350,
                  // x: 2500
                }}
                size="small"
              />
            </TabPane>
          );
        })}
      </Tabs>

      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormAddImage ref={refDetailImage} />
      {contextHolder}
    </Box>
  );
}
