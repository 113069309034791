import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Select,
  InputNumber,
  Checkbox,
} from "antd";
import _ from "lodash";
import { SanPhamService } from "services/SanPhamService";
import { Editor } from "primereact/editor";
import { CRMService } from "services/CRMService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [text, setText] = useState(null);

  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);


  useImperativeHandle(ref, () => ({
    show: async (tab) => {
      setOpen(true);
      setActiveTab(tab);
    },
    update: async (data, tab) => {
      setOpen(true);
      setData(data);
      setActiveTab(tab);
      setText(data.Contents);
      console.log(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
    setText(null);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = null;
    switch (activeTab) {
      case "1":
        _res = await CRMService.Categories.addTempSMS(_data);
        break;
      case "2":
        _res = await CRMService.Categories.addConfigSMS(_data);
        break;
      case "3":
        _res = await CRMService.Categories.addTempMail({
          ..._data,
          Contents: text,
        });
        break;
      case "4":
        _res = await CRMService.Categories.addConfigMail(_data);
        break;
      case "5":
        _res = await CRMService.Categories.addTempZalo({
          ..._data,
          Contents: text,
        });
        break;
      case "6":
        _res = await CRMService.Categories.addConfigZalo(_data);
        break;
    }

    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Thêm/sửa danh mục thành công!`);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderTab1 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên mẫu</p>
          <Input
            value={data?.TempName}
            placeholder="Nhập tên mẫu"
            onChange={(e) => applyChange("TempName", e.target.value)}
          />
        </Col>
        {/* <Col span={12}>
          <p>Loại mẫu</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.HinhThucTT}
            placeholder="Chọn hình thức"
            onChange={(v) => applyChange("HinhThucTT", v)}
            options={[]}
            fieldNames={{ label: "TenHinhThuc", value: "ID" }}
          />
        </Col> */}
        <Col span={24}>
          <p>Nội dung</p>
          <TextArea
            value={data?.Contents}
            onChange={(e) => applyChange("Contents", e.target.value)}
            style={{ minHeight: "160px" }}
          />
        </Col>
      </Row>
    );
  };
  const renderTab2 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={12}>
          <p>Server</p>
          <Input
            value={data?.Server}
            placeholder="Nhập server"
            onChange={(e) => applyChange("Server", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Nhà cung cấp</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaLoai}
            placeholder="Chọn nhà cung cấp"
            onChange={(v) => applyChange("MaLoai", v)}
            options={[
              {
                ID: 1,
                Name: "FPT",
              },
              {
                ID: 2,
                Name: "ESMS",
              },
              {
                ID: 3,
                Name: "VNPT",
              },
            ]}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={24}>
          <p>PublicKey</p>
          <Input
            value={data?.PublicKey}
            placeholder="Nhập PublicKey"
            onChange={(e) => applyChange("PublicKey", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Private Key</p>
          <Input
            value={data?.PrivateKey}
            placeholder="Nhập Private Key"
            onChange={(e) => applyChange("PrivateKey", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Token</p>
          <Input
            value={data?.Token}
            placeholder="Nhập token"
            onChange={(e) => applyChange("Token", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>BrandName</p>
          <Input
            value={data?.BrandName}
            placeholder="Nhập BrandName"
            onChange={(e) => applyChange("BrandName", e.target.value)}
          />
        </Col>

        <Col span={24}>
          <Checkbox
            checked={data?.isDefault}
            onChange={(v) => applyChange("isDefault", v.target.checked)}
          >
            Mặc định
          </Checkbox>
        </Col>
      </Row>
    );
  };
  const renderTab3 = () => {
    
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên mẫu</p>
          <Input
            value={data?.TempName}
            placeholder="Nhập tên mẫu"
            onChange={(e) => applyChange("TempName", e.target.value)}
          />
        </Col>
        {/* <Col span={12}>
          <p>Loại mẫu</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.HinhThucTT}
            placeholder="Chọn hình thức"
            onChange={(v) => applyChange("HinhThucTT", v)}
            options={[]}
            fieldNames={{ label: "TenHinhThuc", value: "ID" }}
          />
        </Col> */}
        <Col span={24}>
          <p>Nội dung</p>
          <Editor
            value={text}
            onTextChange={(e) => setText(e.htmlValue)}
            style={{ minHeight: "420px" }}
          />
        </Col>
      </Row>
    );
  };
  const renderTab4 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên hiển thị</p>
          <Input
            value={data?.Display}
            placeholder="Nhập tên hiển thị"
            onChange={(e) => applyChange("Display", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Email</p>
          <Input
            value={data?.Email}
            placeholder="Nhập email"
            onChange={(e) => applyChange("Email", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Mật khẩu</p>
          <Input
            value={data?.Password}
            placeholder="Nhập mật khẩu"
            onChange={(e) => applyChange("Password", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Máy chủ gửi mail</p>
          <Input
            value={data?.Server}
            placeholder="Nhập máy chủ gửi"
            onChange={(e) => applyChange("Server", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Port</p>
          <InputNumber
            placeholder="Nhập port"
            value={data?.Port}
            style={{ width: "100%" }}
            onChange={(v) => applyChange("Port", v)}
          />
        </Col>
        <Col span={12}>
          <p>Gửi tối đa</p>
          <InputNumber
            value={data?.SendMax}
            placeholder="Nhập tiền đầu ca"
            style={{ width: "100%" }}
            onChange={(v) => applyChange("SendMax", v)}
          />
        </Col>
        <Col span={12}>
          <Checkbox
            checked={data?.EnableSsl}
            onChange={(v) => applyChange("EnableSsl", v.target.checked)}
          >
            Enable SSL
          </Checkbox>
        </Col>
      </Row>
    );
  };
  const renderTab5 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên mẫu</p>
          <Input
            value={data?.TempName}
            placeholder="Nhập tên mẫu"
            onChange={(e) => applyChange("TempName", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Nội dung</p>
          <Editor
            value={text}
            onTextChange={(e) => setText(e.htmlValue)}
            style={{ minHeight: "420px" }}
          />
        </Col>
      </Row>
    );
  };
  const renderTab6 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>URL</p>
          <Input
            value={data?.URL}
            placeholder="Nhập url"
            onChange={(e) => applyChange("URL", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>AppID</p>
          <Input
            value={data?.AppID}
            placeholder="Nhập AppID"
            onChange={(e) => applyChange("AppID", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>SecretKey</p>
          <Input
            value={data?.SecretKey}
            placeholder="Nhập SecretKey"
            onChange={(e) => applyChange("SecretKey", e.target.value)}
          />
        </Col>
      </Row>
    );
  };
  return (
    <Drawer
      title="Danh mục"
      width={700}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
          marginTop: 20,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {activeTab === "1"
        ? renderTab1()
        : activeTab === "2"
        ? renderTab2()
        : activeTab === "3"
        ? renderTab3()
        : activeTab === "4"
        ? renderTab4()
        : activeTab === "5"
        ? renderTab5()
        : renderTab6()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
