import * as React from "react";

const Toolbar = (props, ref) => {
  const { left, right } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 5,
      }}
    >
     {typeof left === "function" && left()}
      {typeof right === "function" && right()}
    </div>
  );
};
export default Toolbar;
