import { Col, Flex, Row } from "antd";
import { Format_Datetime } from "layouts/ultis/common";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import * as React from "react";
import { NhanVienService } from "services/NhanVienService";

const KetCaPrint = (props, ref) => {
  const { data, calamviec } = props;
  const [nhanvien,setNhanVien] = React.useState({})
  React.useEffect(()=>{
    NhanVienService.getDetail().then((res)=>{
      setNhanVien(res.data ?? []);
    });
  },[])
  return (
    <div className="ketcaprint" ref={ref} style={{ margin: "0px 5px 5px" }}>
      <div style={{ textAlign: "center", padding: "15px 0px" }}>
        <p style={{ fontSize: 15, fontWeight: "bold" }}>{nhanvien?.TenCT}</p>
        <p>{nhanvien?.DiaChi}</p>
      </div>
      <hr />
      <div style={{ padding: "10px 0px" }}>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          BÁO CÁO KẾT CA
        </p>
        <p>Nhân viên: {nhanvien?.HoTen}</p>
        <p>Ngày: {Format_Date(new Date())}</p>
      </div>
      <Row style={{ border: "1px solid #e2e8f0", marginTop: 10 }}>
        <Col span={12} className="text">
          Giờ vào ca
        </Col>
        <Col span={12} className="value">
          {/* {data?.length} */}
          {Format_Datetime(calamviec?.GioVaoCa)}
        </Col>
        <Col span={12} className="text">
          Tiền đầu ca
        </Col>
        <Col span={12} className="value">
          {Format_Currency(calamviec?.TienDauCa)}
        </Col>
        <Col span={12} className="text">
          Tổng số hoá đơn
        </Col>
        <Col span={12} className="value">
          {data?.length}
        </Col>
        <Col span={12} className="text">
          Tiền hàng
        </Col>
        <Col span={12} className="value">
          {Format_Currency(
            data?.reduce((pre, cur) => (pre += cur?.ThanhTien), 0)
          )}
        </Col>
        <Col span={12} className="text">
          Chiết khấu
        </Col>
        <Col span={12} className="value">
          {Format_Currency(data?.reduce((pre, cur) => (pre += cur?.TienCK), 0))}
        </Col>
        <Col span={12} className="text">
          Doanh thu
        </Col>
        <Col span={12} className="value">
          {Format_Currency(
            data?.reduce((pre, cur) => (pre += cur?.KhachTra), 0)
          )}
        </Col>
        <Col span={12} className="text">
          Tiền mặt
        </Col>
        <Col span={12} className="value">
          {Format_Currency(
            data
              ?.filter((v) => v.HinhThucTT === 3)
              ?.reduce((pre, cur) => (pre += cur?.KhachTra), 0)
          )}
        </Col>
        <Col span={12} className="text">
          Tiền mặt cuối ca
        </Col>
        <Col span={12} className="value">
          {Format_Currency(
            (data
              ?.filter((v) => v.HinhThucTT === 3)
              ?.reduce((pre, cur) => (pre += cur?.KhachTra), 0) ?? 0) +
              calamviec?.TienDauCa
          )}
        </Col>
        <Col span={12} className="text">
          Giờ kết thúc ca
        </Col>
        <Col span={12} className="value">
          {Format_Datetime(new Date())}
        </Col>
      </Row>
      <p style={{ textAlign: "right", padding: "10px 20px", fontSize: 12 }}>
        Giờ in: {Format_Datetime(new Date())}
      </p>
      <Flex justify="space-around">
        <div style={{textAlign:'center'}}>
          <p style={{ fontWeight: "bold", fontSize: 16 }}>NV giao ca</p>
          <p style={{  fontSize: 14,marginTop:50 }}>{nhanvien?.HoTen}</p>
        </div>
        <div>
          <p style={{ fontWeight: "bold", fontSize: 16 }}>NV nhận ca</p>
          <p style={{ fontWeight: "bold", fontSize: 16 }}></p>
        </div>
      </Flex>
    </div>
  );
};
export default React.forwardRef(KetCaPrint);
