import axios from "axios";

export const API_UPLOAD_URL = "https://upload.beesky.vn/";
const axiosApi = (headers) => {
  return axios.create({
    baseURL: API_UPLOAD_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...headers,
    },
  });
};
export default axiosApi;
export const UploadService = {
  upload: async (payload) => {
    return await axiosApi({
      "Content-Type": "multipart/form-data",
    })
      .post("api/Upload", payload)
      .then((res) => res.data);
  },
};
