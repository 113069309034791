import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Drawer, Table } from "antd";
import _ from "lodash";
import { Format_Date } from "layouts/ultis/common";
import { BanHangService } from "services/BanHangService";
import { Format_Datetime } from "layouts/ultis/common";
const FormHistory = (props, ref) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      let _res = await BanHangService.BanVeThang.getListHisQuetVeDetail({
        MaThe: item.MaThe,
      });
      setData(_res.data ?? []);
    },
  }));
  const onClose = () => {
    setOpen(false);
  };
   const columns = [
     {
       title: "Mã vé",
       dataIndex: "MaVach",
     },
     {
       title: "Ngày quẹt",
       dataIndex: "NgayQuet",
       render: (item) => Format_Datetime(item),
     },
     {
       title: "Cổng",
       dataIndex: "MaCong",
       render: (item) => item ?? 0,
     },
   ];
  return (
    <Drawer title="Lịch sử quẹt vé" width="500px" onClose={onClose} open={open}>
      <Table
        // loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 150,
          // x: 2000,
        }}
        size="small"
      />
    </Drawer>
  );
};
export default forwardRef(FormHistory);
