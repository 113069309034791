import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  DatePicker,
  Flex,
  Modal,
  Table,
  Badge,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import _ from "lodash";
import { TiDelete } from "react-icons/ti";
import { NhanVienService } from "services/NhanVienService";
import { CustomerService } from "services/CustomerService";
import { Editor } from "primereact/editor";
import { CRMService } from "services/CRMService";
import { IoChevronDown } from "react-icons/io5";
const { TextArea } = Input;
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [smsConfig, setSmsconfig] = useState([]);
  const [brands, setBrands] = useState([]);
  const [khachhang, setKhachhang] = useState([]);
  const [khachhangAll, setKhachhangAll] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [openMau, setOpenMau] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadmore, setLoadmore] = useState(false);
  const [stateActive, setStateActive] = useState({
    ID: 0,
    Name: "Tất cả",
    Color: "gray",
  });
  const loadCategories = async () => {
    let _res = await CRMService.Categories.getConfigSMS();
    setSmsconfig(_res.data);
    let _resBrand = await CRMService.SMS.GetSMSBrand();
    setBrands(_resBrand.data);
    filterKhachHang("");
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      let _res = await CRMService.SMS.GetSMSByID({ ID: data.ID });
      let v = {
        ..._res.data,
        KhachHang: _res.mailnhan,
      };
      loadCategories();
      setData(v);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaKH":
        break;
      case "RemoveDiDong":
        var kh = _data.KhachHang?.filter((a) => a.MaKH !== v);
        _data.KhachHang = kh;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = await CRMService.SMS.AddSMS(_data);
    setLoading(false);
    if (_res.status === 2000) {
      setData(_data);
      openNotification("success", "topRight", `Thêm thành công!`);
      // if (close)
      onClose();
      onAfterSubmit(_data);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const [temp, setTemp] = useState([]);
  const openChooseTemp = async () => {
    let _res = await CRMService.Categories.getTempSMS();
    setTemp(_res.data);
    setOpenMau(true);
  };
  const renderChooseTemp = () => {
    return (
      <Drawer
        title="Chọn mẫu"
        width={500}
        onClose={() => setOpenMau(false)}
        open={openMau}
        styles={{
          body: {
            marginTop: 20,
          },
        }}
      >
        <Row gutter={[20]}>
          {temp?.map((item) => {
            return (
              <Col span={24}>
                <Flex
                  justify="space-between"
                  align="center"
                  style={{
                    backgroundColor: "#d9f7be",
                    paddingLeft: "10px",
                    borderRadius: 10,
                    marginBottom: 5,
                  }}
                >
                  <p>{item.TempName}</p>
                  <Button
                    onClick={() => {
                      applyChange("ContentSend",item.Contents);
                      setOpenMau(false);
                    }}
                    type="primary"
                  >
                    Chọn
                  </Button>
                </Flex>
              </Col>
            );
          })}
        </Row>
      </Drawer>
    );
  };
  const renderChooseDiDong = () => {
    const columns = [
      {
        title: "Tên khách hàng",
        dataIndex: "TenCongTy",
      },
      {
        title: "Di động",
        dataIndex: "DiDong",
      },
      {
        title: "Email",
        dataIndex: "Email",
      },
    ];
    const onSelectChange = async (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };
    const changeState = (state) => {
      setSelectedRowKeys([]);
      if (state === 0) {
        setKhachhang(khachhangAll);
      } else {
        let _data = khachhangAll.filter((v) => v.MaTT === state);
        setKhachhang(_data);
      }
    };
    const handleOk = () => {
      let _data = _.cloneDeep(data);
      let kh = khachhangAll.filter(
        (v) => selectedRowKeys.includes(v.MaKH) && v.DiDong
      );
      let _lst = [];
      kh?.map((item)=>{
        _lst.push({Number: item.DiDong,CusID: item.MaKH});
      });
      _data.KhachHang = _lst;
      setData(_data);
      setIsModalOpen(false);
      setSelectedRowKeys([]);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    return (
      <Modal
        width={600}
        title={
          <Input
            placeholder="Tìm kiếm"
            onChange={(e) => filterKhachHang(e.target.value)}
          />
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Đồng ý"
        cancelText="Huỷ"
      >
        {status?.map((item) => {
          return (
            <Badge
              key={item.ID}
              count={item.Total}
              onClick={() => {
                setStateActive(item);
                changeState(item.ID);
              }}
            >
              <div
                style={{
                  border: `1px solid ${"#" + item.Color}`,
                  padding: "6px 15px",
                  cursor: "pointer",
                  marginLeft: 15,
                  borderRadius: 10,
                  background:
                    stateActive.ID === item.ID ? "#" + item.Color : null,
                  color: stateActive.ID === item.ID ? "white" : null,
                }}
              >
                <p>{item.Name}</p>
              </div>
            </Badge>
          );
        })}
        <Table
          loading={loading}
          dataSource={khachhang}
          columns={columns}
          scroll={{
            y: window.innerHeight - 350,
          }}
          rowSelection={rowSelection}
          size="small"
          pagination={false}
        />
      </Modal>
    );
  };
  const filterKhachHang = async (input) => {
    let _res = await CustomerService.getList({ InputSearch: input });
    let _tt = await CustomerService.getStatus();
    let status =
      [{ ID: 0, Name: "Tất cả", Color: "cccccc" }, ..._tt.data] ?? [];
    status.map((item) => {
      if (item.ID === 0) {
        item.Total = _res.data?.length;
      } else {
        item.Total = _res?.data?.filter((v) => v.MaTT === item.ID)?.length;
      }
    });
    setStatus(status);
    setKhachhangAll(_res.data);
    setKhachhang(_res?.data ?? []);
  };
  const handleMailNhan = async () => {
    setIsModalOpen(true);
    filterKhachHang("");
  };
  return (
    <Drawer
      title="Chiến dịch SMS"
      width={600}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          marginTop: 20,
        },
      }}
      extra={
        <Space>
          <Button onClick={() => openChooseTemp()}>Chọn mẫu</Button>
          <Button onClick={() => onSubmit()} loading={loading} type="primary">
            Lưu
          </Button>
        </Space>
      }
    >
      {contextHolderMS}
      {contextHolder}
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <p>Tiêu chiến dịch</p>
          <Input
            value={data?.CampaignName}
            placeholder="Nhập tên chiến dịch"
            onChange={(e) => applyChange("CampaignName", e.target.value)}
          />
        </Col>
        <Col span={24} className="gutter-row">
          <p>Thương hiệu</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.BrandID}
            onChange={(v) => applyChange("BrandID", v)}
            options={smsConfig}
            fieldNames={{ label: "BrandName", value: "ID" }}
          />
        </Col>
        <Col span={24}>
          <p>Nội dung</p>
          <TextArea
            style={{ height: 120 }}
            value={data?.ContentSend}
            onChange={(v) => applyChange("ContentSend", v.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Ghi chú</p>
          <TextArea
            value={data?.Note}
            onChange={(v) => applyChange("Note", v.target.value)}
          />
        </Col>
        <Col span={24} className="gutter-row">
          <p>Di động nhận</p>
          <div
            wrap="wrap"
            // gap={[10,0]}
            style={{ borderBottom: "1px solid #d9d9d9", minHeight: "30px" }}
          >
            <Flex justify="space-between" align="center">
              <Flex wrap="wrap" style={{ width: "95%" }}>
                {(!data?.KhachHang || data?.KhachHang?.length === 0) && (
                  <p
                    style={{ color: "#bfbfbf" }}
                    onClick={() => handleMailNhan()}
                  >
                    Chọn mail nhận
                  </p>
                )}
                {data?.KhachHang?.map((item, index) => {
                  if (index > 10 && loadmore === false) return;
                  return (
                    <div style={{ position: "relative" }}>
                      <p
                        style={{
                          backgroundColor: "#f0f0f0",
                          padding: "2px 5px",
                          borderRadius: 5,
                          marginRight: 5,
                          marginBottom: 5,
                        }}
                      >
                        {item.Number}
                      </p>
                      <p
                        style={{
                          position: "absolute",
                          top: -5,
                          right: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => applyChange("RemoveDiDong", item.MaKH)}
                      >
                        <TiDelete color="red" size={18} />
                      </p>
                    </div>
                  );
                })}
                {data.KhachHang?.length > 10 && (
                  <p
                    style={{
                      backgroundColor: "#f0f0f0",
                      padding: "2px 5px",
                      borderRadius: 5,
                      marginRight: 5,
                      marginBottom: 5,
                      cursor: "pointer",
                      color: "red",
                    }}
                    onClick={() => setLoadmore(!loadmore)}
                  >
                    {loadmore ? "Thu gọn" : "Xem thêm"}
                  </p>
                )}
              </Flex>
              <IoChevronDown
                size={16}
                color="#d9d9d9"
                style={{ cursor: "pointer", width: "5%" }}
                onClick={() => handleMailNhan()}
              />
            </Flex>
          </div>
        </Col>
      </Row>
      {renderChooseTemp()}
      {renderChooseDiDong()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
