import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Drawer,
  notification,
  DatePicker,
  Table,
  Divider,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import _ from "lodash";
import { CardService } from "services/CardService";
import { NhanVienService } from "services/NhanVienService";
import { CustomerService } from "services/CustomerService";
import { Format_Date } from "layouts/ultis/common";
import { VisaService } from "services/VisaService";
import { Format_Datetime } from "layouts/ultis/common";

const { TextArea } = Input;
const FormDetail = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [nhatky, setNhatky] = useState([]);
  const [open, setOpen] = useState(false);

  const loadChamSoc  = async (_maVisa) =>{
    let _res = await VisaService.NhatKy.getList({ MaVisa: _maVisa });
    setNhatky(_res.data ?? []);
  }
  useImperativeHandle(ref, () => ({
    update: async (data,status) => {
      setOpen(true);
      setData(data);
      setStatus(status);
      loadChamSoc(data?.ID);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _payload ={
      ..._data,
      MaVisa:_data?.ID,
      MaTT:_data?.MaTT,
      DienGiai:_data?.DienGiai
    }
    let _res = await VisaService.NhatKy.add(_payload);
    setLoading(false);
    if (_res.status === 2000) {
      loadChamSoc(_data?.ID);
      onAfterSubmit();
      openNotification("success", "topRight", `Thêm lịch sử thành công!`);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  // chăm sóc
  const columns = [
    {
      title: "Ngày xử lý",
      dataIndex: "NgayNhap",
      render: (item) => Format_Datetime(item),
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
    },
    {
      title: "Trạng thái",
      dataIndex: "MaTT",
      render: (item) => status?.find((v) => v.ID === item)?.TenTT,
    },
    {
      title: "Ghi chú",
      dataIndex: "DienGiai",
      render: (item) => (
        <p
          style={{
            margin: 0,
            padding: "8px",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {item}
        </p>
      ),
    },
  ];
  return (
    <Drawer
      title="Nhật ký xử lý"
      width={1020}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={24}>
          <TextArea
            value={data?.DienGiai}
            onChange={(e) => applyChange("DienGiai", e.target.value)}
            placeholder="Nhập ghi chú"
            rows={4}
          />
        </Col>
        <Col span={7} className="gutter-row">
          <p>Trạng thái</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaTT}
            placeholder="Chọn trạng thái"
            onChange={(v) => applyChange("MaTT", v)}
            options={status}
            fieldNames={{ label: "TenTT", value: "ID" }}
          />
        </Col>
        <Col span={7}>
          <p>Ngày nộp ĐSQ</p>
          <DatePicker
            allowClear={false}
            placeholder="Nhập nộp ĐSQ"
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.NgayNopHS ? dayjs(data?.NgayNopHS) : null}
            locale={vi}
            onChange={(v) => applyChange("NgayNopHS", v)}
          />
        </Col>
        <Col span={7}>
          <p>Ngày trả kết quả</p>
          <DatePicker
            allowClear={false}
            placeholder="Ngày trả kết quả"
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.NgayTraKQ ? dayjs(data?.NgayTraKQ) : null}
            locale={vi}
            onChange={(v) => applyChange("NgayTraKQ", v)}
          />
        </Col>
        <Col span={3} style={{ textAlign: "right" }}>
          <Button onClick={() => onSubmit(false)} type="primary">
            Lưu
          </Button>
        </Col>

        <Col span={24}>
          <Divider orientation="left">Lịch sử</Divider>
          <Table
            style={{ height: 100 }}
            loading={loading}
            dataSource={nhatky}
            columns={columns}
            size="small"
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormDetail);
