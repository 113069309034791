import React, { useEffect, useState, useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import "./scss/index.scss";
import { Tabs } from "antd";
import { OrderService } from "services/OrdersService";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import { AddIcon, MinusIcon, ChevronRightIcon } from "@chakra-ui/icons";
import CheckOut from "./CheckOut";
import History from "./History";
import { Input, Select, Button, notification, Space } from "antd";

export default function Portal() {
  let params = useLocation();
  const [layout, setLayout] = useState("home");
  const [data, setData] = useState([]);
  const [carts, setCarts] = useState([]);
  const [table, setTable] = useState(null);
  const filter = async () => {
    let _res = await OrderService.getProducts({ TenCTDK: "centerpoker" });
    if (_res.status === 2000) setData(_res.data);
  };
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api.success({
      message: "Đặt đồ ăn thành công!",
    });
  };
  useEffect(() => {
    let indexmact = params?.search?.indexOf("&mact");
    let indextable = params?.search?.indexOf("&table");
    let _mact = params?.search.substring(indexmact + 7, indextable);
    let _table = params?.search.substring(indextable + 7);
    let _domain = "";
    if (_mact?.toLocaleLowerCase() === "crownpoker") {
      _domain = "https://api.crownpokerclub.com/";
    } else if (_mact?.toLocaleLowerCase() === "galaxy") {
      _domain = "https://apigalaxy.appbeesky.com/";
    } else if (_mact?.toLocaleLowerCase() === "royalpoker") {
      _domain = "https://apiroyalpoker.appbeesky.com/";
    } else if (
      _mact?.toLocaleLowerCase() === "centerpoker" ||
      _mact?.toLocaleLowerCase() === "hongvan"
    ) {
      _domain = "https://apicenterpoker.appbeesky.com/";
    } else {
      // _domain = "Https://api-poker.beesky.vn/";
        _domain = "https://apicenterpoker.appbeesky.com/";
    }
    localStorage.setItem("tenCTDK", _mact);
    localStorage.setItem("url", _domain);
    (async () => {
      let _resTable = await OrderService.getTableDetail({ ID: Number(_table) });
      setTable(_resTable?.data);
    })();
    filter();
  }, []);
  const renderTabBar = (props, DefaultTabBar) => (
    <div
      style={{ position: "sticky", background: "white", zIndex: 99, top: 0 }}
    >
      <DefaultTabBar {...props} />
    </div>
  );
  const renderChildren = (value) => {
    return value?.map((item) => {
      return (
        <div
          className="box-product"
          key={item.id}
          style={{
            boxShadow: "1px 3px 12px 1px rgba(208, 216, 243, 0.6)",
            marginBottom: 18,
            padding: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 15,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
              <img
                src="https://openclipart.org/image/2000px/289282"
                style={{ width: 40, height: 40 }}
              />
              <div>
                <p>{item.tenSP}</p>
                <p style={{ fontWeight: "700" }}>
                  {Format_Currency(item.giaBan)}
                </p>
              </div>
            </div>
            {carts?.some((v) => v.id === item.id) ? (
              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <MinusIcon
                  boxSize={4}
                  style={{ border: "1px solid", padding: 2 }}
                  onClick={() => removeProduct(item)}
                />
                <p>{carts?.find((v) => v.id === item.id)?.quantity}</p>
                <AddIcon
                  boxSize={4}
                  style={{ border: "1px solid", padding: 2 }}
                  onClick={() => addProduct(item)}
                />
              </div>
            ) : (
              <div
                className="secondary-bg"
                style={{
                  padding: 6,
                  borderRadius: 10,
                  color: "white",
                  fontWeight: "600",
                }}
                onClick={() => addProduct(item)}
              >
                Thêm
              </div>
            )}
          </div>
        </div>
      );
    });
  };
  const addProduct = (value) => {
    const _carts = _.cloneDeep(carts);
    let cart = _carts.find((v) => v.id === value.id);
    if (cart) {
      cart.quantity += 1;
    } else {
      _carts.push({
        ...value,
        quantity: 1,
      });
    }
    setCarts(_carts);
  };
  const removeProduct = (value) => {
    const _carts = _.cloneDeep(carts);
    let cart = _carts.find((v) => v.id === value.id);
    if (cart?.quantity > 1) {
      cart.quantity -= 1;
    } else {
      let index = _carts?.findIndex((v) => v.id === value.id);
      _carts.splice(index, 1);
    }
    setCarts(_carts);
  };
  const reunderFooter = () => {
    return (
      carts?.length > 0 && (
        <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
          <div
            className="primary-bg"
            style={{
              // padding: "10px 20px",
              paddingLeft: "10px",
              boxShadow: "1px 3px 12px 1px rgba(208, 216, 243, 0.6)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>
              Món:{" "}
              {carts?.reduce((pre, currentValue) => {
                return (pre += currentValue.quantity);
              }, 0)}
            </p>
            <p>
              Tổng tiền:{" "}
              {Format_Currency(
                carts?.reduce((pre, currentValue) => {
                  return (pre += currentValue.quantity * currentValue.giaBan);
                }, 0)
              )}
            </p>
            <div
              className="secondary-bg"
              style={{
                padding: "10px 30px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              onClick={() => setLayout("checkout")}
            >
              <p>Đặt món</p>
              <ChevronRightIcon boxSize={6} />
            </div>
          </div>
        </div>
      )
    );
  };
  const onGoback = (v) => {
    setLayout("home");
    if (v?.status === 2000) {
      setCarts([]);
      openNotificationWithIcon();
    }
  };
  const onChangeNote = (value, item) => {
    let _carts = _.cloneDeep(carts);
    let _cart = _carts.find((v) => v.id === item.id);
    _cart.note = value;
    setCarts(_carts);
  };
  return layout === "home" ? (
    <div style={{ background: "white" }}>
      <div style={{ padding: "0px 10px", minHeight: "900px" }}>
        <div
          style={{
            alignItems: "flex-end",
            display: "flex",
            justifyContent: "flex-end",
            padding: 8,
          }}
          onClick={() => setLayout("history")}
        >
          <p style={{ fontWeight: "bold" }}>Lịch sử</p>
        </div>
        <Tabs
          defaultActiveKey="1"
          items={data?.map((item, i) => {
            return {
              label: item.title,
              key: item.key,
              children: renderChildren(item?.data),
            };
          })}
          renderTabBar={renderTabBar}
          // onChange={onChange}
        />
      </div>
      {reunderFooter()}
      {contextHolder}
    </div>
  ) : layout === "checkout" ? (
    <CheckOut
      carts={carts}
      onBack={(v) => onGoback(v)}
      table={table}
      onChangeNote={(v, item) => onChangeNote(v, item)}
      addProduct={addProduct}
      removeProduct={removeProduct}
    />
  ) : (
    <History onBack={(v) => onGoback(v)} />
  );
}
