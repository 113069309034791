import axiosApi from "./api_helper";
export const CongTyService = {
  getCongTy: async () => {
    return await axiosApi()
      .post("api/chi-nhanh/get")
      .then((res) => res.data);
  },
  addChiNhanh: async (payload) => {
    return await axiosApi()
      .post("api/chi-nhanh/add", payload)
      .then((res) => res.data);
  },
  updateCompany: async (payload) => {
    return await axiosApi()
      .post("api/chi-nhanh/update", payload)
      .then((res) => res.data);
  },
  deleteCompany: async (payload) => {
    return await axiosApi()
      .post("api/chi-nhanh/delete", payload)
      .then((res) => res.data);
  },

  addCongTy: async (payload) => {
    return await axiosApi()
      .post("api/cong-ty/add", payload)
      .then((res) => res.data);
  },
  NhomModules: {
    Get: async () => {
      return await axiosApi()
        .post("api/nhom-modules/get")
        .then((res) => res.data);
    },
    Add: async (payload) => {
      return await axiosApi()
        .post("api/nhom-modules/add", payload)
        .then((res) => res.data);
    },
  },
};
