import axiosApi from "./api_helper";
export const VisaService = {
  getList: async (payload) => {
    return await axiosApi()
      .post("api/crm/visa/get", payload)
      .then((res) => res.data);
  },
  add: async (payload) => {
    return await axiosApi()
      .post("api/crm/visa/add", payload)
      .then((res) => res.data);
  },
  delete: async (payload) => {
    return await axiosApi()
      .post("api/crm/visa/delete", payload)
      .then((res) => res.data);
  },
  NhatKy: {
    getList: async (payload) => {
      return await axiosApi()
        .post("api/crm/visa/get-nhatky", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/crm/visa/add-nhatky", payload)
        .then((res) => res.data);
    },
  },
  HoSo: {
    getList: async (payload) => {
      return await axiosApi()
        .post("api/crm/visa/get-hoso", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/crm/visa/add-hoso", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/crm/visa/delete-hoso", payload)
        .then((res) => res.data);
    },
    getListTaiLieu: async (payload) => {
      return await axiosApi()
        .post("api/crm/visa/get-tailieu-hoso", payload)
        .then((res) => res.data);
    },
    addTaiLieu: async (payload) => {
      return await axiosApi()
        .post("api/crm/visa/add-tailieu-hoso", payload)
        .then((res) => res.data);
    },
    deleteTaiLieu: async (payload) => {
      return await axiosApi()
        .post("api/crm/visa/delete-tailieu-hoso", payload)
        .then((res) => res.data);
    },
  },
  Categories: {
    getTenLoaiHS: async () => {
      return await axiosApi()
        .post("api/category/visa/get-loaihs")
        .then((res) => res.data);
    },
    addTenLoaiHS: async (payload) => {
      return await axiosApi()
        .post("api/category/visa/coru-loaihs", payload)
        .then((res) => res.data);
    },
    deleteTenLoaiHS: async (payload) => {
      return await axiosApi()
        .post("api/category/visa/delete-loaihs", payload)
        .then((res) => res.data);
    },
    getTinhTrang: async (payload) => {
      return await axiosApi()
        .post("api/category/visa/get-tinhtrang", payload)
        .then((res) => res.data);
    },
    addTinhTrang: async (payload) => {
      return await axiosApi()
        .post("api/category/visa/coru-tinhtrang", payload)
        .then((res) => res.data);
    },
    deleteTinhTrang: async (payload) => {
      return await axiosApi()
        .post("api/category/visa/delete-tinhtrang", payload)
        .then((res) => res.data);
    },
  },
  SanPham: {
    getHoSoByMSP: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/get-tenloaihs-bymasp", payload)
        .then((res) => res.data);
    },
    addHoSoByMSP: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/add-tenloaihs-bymasp", payload)
        .then((res) => res.data);
    },
    deleteHoSoByMSP: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/delete-tenloaihs-bymasp", payload)
        .then((res) => res.data);
    },
  },
};
