import React, {
  useState,
  useEffect,
} from "react";
import { DatePicker,  Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { KhoHangService } from "services/KhoHangService";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import ChiNhanhSelect from "components/ChiNhanhSelect";

const FilterModal = ({ ...props }) => {
  const { companies, filterCondition, onChangeFilter } = props;
  const [loaiNK, setLoaiNK] = useState([]);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "ListMaCT":
      case "LoaiNhapKho":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      case "MaCT":
        _filter[p] = v ? v : 0;
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(()=>{
    KhoHangService.NhapKho.getLoai().then((res) => setLoaiNK(res.data ?? []));
  },[])
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      <ChiNhanhSelect
        FormID={118}
        onChange={(v) => applyChangeFilter("MaCT", v)}
      />

      <div className="box-search">
        <p className="label">Loại nhập kho</p>
        <Select
          mode="multiple"
          placeholder="Chọn loại nhập kho"
          onChange={(v) => applyChangeFilter("LoaiNhapKho", v)}
          style={{
            width: "100%",
          }}
          options={loaiNK}
          fieldNames={{ label: "TenLoai", value: "ID" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
