import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { HomeService } from "services/HomeService";
import {
  Button,
  Popconfirm,
  Table,
  Tooltip,
  notification,
  message,
  Col,
  Row,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { SoQuyService } from "services/SoQuyService";
import InPhieuChi from "./InPhieuChi";
import { useReactToPrint } from "react-to-print";
import { IoMdPrint } from "react-icons/io";
import { OtherService } from "services/OtherService";
export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const refPrint = useRef(null);
  const [data, setData] = useState([]);
  const [dataprint, setDataprint] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hinhthuc, setHinhThuc] = useState([]);
  const [loai, setLoai] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    LoaiChi: ",,",
    HinhThuc: ",,",
    MaCT: 0,
  });
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadcategories = async () => {
    let _ht = await OtherService.getHinhThucTT();
    setHinhThuc(_ht.data ?? []);
    let _loai = await SoQuyService.PhieuChi.getLoaiPhieuChi();
    setLoai(_loai.data ?? []);
  };
  useEffect(() => {
    loadcategories();
    filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await SoQuyService.PhieuChi.getPhieuChi(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const columns = [
    {
      title: "Ngày chi",
      dataIndex: "NgayChi",
      render: (item) => Format_Date(item),
    },
    {
      title: "Người nhận",
      dataIndex: "NguoiNhan",
    },
    {
      title: "Di động",
      dataIndex: "DiDong",
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChiNN",
    },
    {
      title: "Số tiền",
      dataIndex: "TongTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Hình thức",
      dataIndex: "MaPTTT",
      render: (item) => hinhthuc.find((v) => v.ID === item)?.TenHinhThuc,
    },
    {
      title: "Loại chi",
      dataIndex: "MaLPC",
      render: (item) => loai.find((v) => v.ID === item)?.TenLPC,
    },
    {
      title: "Lý do",
      dataIndex: "LyDo",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
    },
    {
      title: "",
      fixed: "right",
      width: "140px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="In phiếu chi">
              <Button
                ghost
                type="primary"
                icon={<IoMdPrint />}
                onClick={() => print(item)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá phiếu chi"
              description="Bạn có chắc chắn muốn xoá phiếu chi?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await SoQuyService.PhieuChi.deletePhieuChi({
      ID: Number(value),
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá phiếu chi thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => refPrint.current,
  });
  const print = async (value) => {
    let _db = _.cloneDeep(value);
    setDataprint(_db);
  };
  useEffect(() => {
    if (dataprint) {
      handlePrint();
    }
  }, [dataprint]);
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          hinhThuc={hinhthuc}
          loai={loai}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          right={() => (
            <Button type="primary" onClick={() => refDetail.current.show()}>
              Thêm
            </Button>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 1500,
          }}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TongTien")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <div style={{ display: "none" }}>
        <InPhieuChi ref={refPrint} data={dataprint} />
      </div>
      {contextHolder}
    </Row>
  );
}
