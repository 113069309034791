import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  DatePicker,
  Flex,
  Spin,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import _ from "lodash";
import { CustomerService } from "services/CustomerService";
import FormAddKH from "../../CRM/KhachHang/FormAdd";
import { SanPhamService } from "services/SanPhamService";
import FormHoSo from "./FormHoSo.jsx";
import { VisaService } from "services/VisaService";
import { MdAdd, MdEdit } from "react-icons/md";
import TextArea from "antd/es/input/TextArea";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [sanPham, setSanPham] = useState([]);
  const refAddKH = useRef(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [khachHang, setKhachhang] = useState([]);
  const [hoSo, setHoSo] = useState([]);
  const [action,setAction] = useState([]);

  const defaultData = {
    NgayNhanHS: new Date(),
  };
  const [data, setData] = useState(defaultData);
  const defaultErrors = {
    MaGD: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const loadCategories = async () => {
    let _kh = await CustomerService.getListAll();
    _kh.data?.map((item) => (item.Name = item.TenCongTy + " - " + item.DiDong));
    setKhachhang(_kh?.data);
    let _ressp = await SanPhamService.getListByMaNhom({
      MaNhom: 5,
      InputSearch: "",
      MaLoaiSP: ",,",
    });
    setSanPham(_ressp?.data ?? []);
  };
  const loadHoSoVisa = async (mavisa) => {
    let _res = await VisaService.HoSo.getList({ MaVisa: mavisa });
    setHoSo(_res?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadCategories();
    },
    update: async (data,action) => {
      setOpen(true);
      setLoading(true);
      await loadCategories();
      await loadHoSoVisa(data.ID);
      setData(data);
      setLoading(false);
      setAction(action);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
    setHoSo([]);
  };
  const loadHoSo = async (MaSP) => {
    let _hs = await VisaService.Categories.getTenLoaiHS();
    let _hstheosp = await VisaService.SanPham.getHoSoByMSP({
      MaSP: MaSP,
    });
    let _data = _hs.data.filter((item) =>
      _.map(_hstheosp.data, "MaLHS").includes(item.ID)
    );
    _data.map((item) => {
      item.TenHoSo =
        item.TenLoaiHS + (item.GhiChu ? "(" + item.GhiChu + ")" : "");
      item.ID = Date.now() + Math.random();
    });
    setHoSo(_data);
  };
  const SUM = (_data) => {
    _data.TongTien = _data.SoTien - _data.TienCK + _data.PhiDichThuat;
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaKH":
        _data[p] = v;
        break;
      case "MaSP":
        _data[p] = v;
        let _sp = sanPham.find((item) => item.ID === v);
        _data.SoTien = _sp?.GiaBan;
        _data.TongTien = _sp?.GiaBan;
        _data.TyLeCK = 0;
        _data.TienCK = 0;
        _data.PhiDichThuat = 0;
        if (!_data?.ID) {
          loadHoSo(v);
        }
        break;
      case "TyLeCK":
        _data[p] = v;
        _data.TienCK = Number(((_data.SoTien * v) / 100).toFixed(0));
        SUM(_data);
        break;
      case "TienCK":
        _data[p] = v;
        _data.TyLeCK = Number(((v / _data.SoTien) * 100).toFixed(2));
        SUM(_data);
        break;
      case "PhiDichThuat":
        _data[p] = v;
        SUM(_data);
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    let _data = _.cloneDeep(data);
    setLoading(true);
    let _payload = {
      ..._data,
      HoSo: hoSo,
    };
    let _res = await VisaService.add(_payload);
    setLoading(false);
    if (_res.status === 2000) {
      setData(_data);
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} thành công!`
      );
      onClose();
      onAfterSubmit(_data);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const filterOption = (input, option) => {
    return (option?.Name ?? "").toLowerCase().includes(input.toLowerCase());
  };
  const filterOptionSP = (input, option) => {
    const inputLower = input.toLowerCase();
    return (
      (option?.TenSP ?? "").toLowerCase().includes(inputLower) ||
      (option?.MaSP ?? "").toLowerCase().includes(inputLower)
    );
  };
  const onAfterSubmitKH = async (value) => {
    let _data = _.cloneDeep(data);
    _data.KhachHang = value?.MaKH;
    _data.MaKH = value.MaKH;
    setData(_data);
    let _kh = await CustomerService.getListAll();
    _kh.data?.map((item) => (item.Name = item.TenCongTy + " - " + item.DiDong));
    setKhachhang(_kh?.data);
  };
  return (
    <Drawer
      title="Thêm/Sửa"
      width={1120}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          {/* <Button onClick={() => onSubmit()}>Lưu</Button> */}
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolderMS}
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={12}>
          <p>Khách hàng</p>
          <Flex
            gap={10}
            align="center"
            justify="space-between"
            style={{
              width: "100%",
            }}
          >
            <Select
              showSearch
              allowClear
              placeholder="Tìm kiểm khách hàng"
              value={data?.MaKH}
              optionFilterProp="children"
              filterOption={filterOption}
              style={{
                width: "95%",
              }}
              fieldNames={{ label: "Name", value: "MaKH" }}
              onChange={(newValue) => applyChange("MaKH", newValue)}
              options={khachHang}
              // disabled={data?.ID}
            />
            <Button
              loading={loading}
              // disabled={data?.ID}
              icon={data?.MaKH ? <MdEdit /> : <MdAdd />}
              style={{
                borderRadius: 10,
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
              }}
              onClick={() =>
                data?.MaKH
                  ? refAddKH.current.update(
                      khachHang.find((v) => v.MaKH === data.MaKH)
                    )
                  : refAddKH.current.show()
              }
            />
          </Flex>
        </Col>
        <Col span={12}>
          <p>Sản phẩm</p>
          <Select
            // disabled={data?.ID}
            style={{
              width: "100%",
            }}
            value={data?.MaSP}
            showSearch
            optionFilterProp="children"
            filterOption={filterOptionSP}
            placeholder="Chọn sản phẩm"
            onChange={(v) => applyChange("MaSP", v)}
            options={sanPham}
            fieldNames={{ label: "TenSP", value: "ID" }}
          />
        </Col>
        <Col span={4}>
          <p>Số tiền</p>
          <InputNumber
            value={data?.SoTien}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={4}>
          <p>Tỷ lệ CK</p>
          <InputNumber
            value={data?.TyLeCK}
            onChange={(v) => applyChange("TyLeCK", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={4}>
          <p>Tiền CK</p>
          <InputNumber
            value={data?.TienCK}
            onChange={(v) => applyChange("TienCK", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={4}>
          <p>Phí dịch thuật</p>
          <InputNumber
            value={data?.PhiDichThuat}
            onChange={(v) => applyChange("PhiDichThuat", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={8}>
          <p>Tổng tiền</p>
          <InputNumber
            disabled
            value={data?.TongTien}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={8}>
          <p>Ngày nhận hồ sơ</p>
          <DatePicker
            allowClear={false}
            placeholder="Nhập nhận hồ sơ"
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.NgayNhanHS ? dayjs(data?.NgayNhanHS) : null}
            locale={vi}
            onChange={(v) => applyChange("NgayNhanHS", v)}
          />
        </Col>
        <Col span={8}>
          <p>Ngày nộp ĐSQ</p>
          <DatePicker
            allowClear={false}
            placeholder="Nhập nộp ĐSQ"
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.NgayNopHS ? dayjs(data?.NgayNopHS) : null}
            locale={vi}
            onChange={(v) => applyChange("NgayNopHS", v)}
          />
        </Col>
        <Col span={8}>
          <p>Ngày trả kết quả</p>
          <DatePicker
            allowClear={false}
            placeholder="Ngày trả kết quả"
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.NgayTraKQ ? dayjs(data?.NgayTraKQ) : null}
            locale={vi}
            onChange={(v) => applyChange("NgayTraKQ", v)}
          />
        </Col>
        <Col span={24}>
          <p>Ghi chú</p>
          <TextArea
            style={{ width: "100%" }}
            value={data?.GhiChu}
            onChange={(v) => applyChange("GhiChu", v.target.value)}
          />
        </Col>
      </Row>
      {loading ? (
        <Spin size="large" />
      ) : (
        <FormHoSo hoSo={hoSo} setHoSo={setHoSo} maVisa={data?.ID} action={action} />
      )}

      <FormAddKH ref={refAddKH} onAfterSubmit={onAfterSubmitKH} />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
