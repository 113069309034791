import { Box, useEditable } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  Select,
  Checkbox,
  InputNumber,
} from "antd";
import _ from "lodash";
import { SanPhamService } from "services/SanPhamService";
import { OtherService } from "services/OtherService";
import { BanHangService } from "services/BanHangService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [nganhang, setNganhang] = useState([]);
  const [quayBan, setQuayBan] = useState([]);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  useImperativeHandle(ref, () => ({
    show: async (tab) => {
      setOpen(true);
      setActiveTab(tab);
      if (tab === "0") {
        let _res = await OtherService.getNganHang();
        setNganhang(_res.data ?? []);
      }
    },
    update: async (data, tab) => {
      setOpen(true);
      setData(data);
      setActiveTab(tab);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await SanPhamService.addLSP(_data);
        break;
      case "1":
        _res = await SanPhamService.addDVT(_data);
        break;
      case "2":
        _res = await SanPhamService.Categories.addCaiDatMayIn(_data);
        break;
      case "3":
        _res = await SanPhamService.Categories.addKhoHang(_data);
        break;
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} danh mục thành công!`
      );
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderTab1 = () => {
    return (
      <Row gutter={[10, 10]}>
        <Col span={6}>
          <p>Số thứ tự</p>
          <InputNumber
            min={1}
            value={data?.STT}
            placeholder="Nhập STT"
            onChange={(e) => applyChange("STT", e)}
          />
        </Col>
        <Col span={18}>
          <p>Tên loại sản phẩm</p>
          <Input
            value={data?.TenLSP}
            placeholder="Nhập tên loại sản phẩm"
            onChange={(e) => applyChange("TenLSP", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Checkbox
            checked={data?.ApDung}
            onChange={(v) => applyChange("ApDung", !data?.ApDung)}
          >
            Áp dụng
          </Checkbox>
        </Col>
      </Row>
    );
  };
  const renderTab2 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên đơn vị tính</p>
          <Input
            value={data?.TenDVT}
            placeholder="Nhập tên đơn vị tính"
            onChange={(e) => applyChange("TenDVT", e.target.value)}
          />
        </Col>
      </Row>
    );
  };
  const renderTab3 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên server</p>
          <Input
            value={data?.TenServer}
            placeholder="Nhập tên server"
            onChange={(e) => applyChange("TenServer", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Tên máy in</p>
          <Input
            value={data?.TenMayIn}
            placeholder="Nhập tên máy in"
            onChange={(e) => applyChange("TenMayIn", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Điểm bán</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaKV}
            placeholder="Chọn điểm bán"
            onChange={(v) => applyChange("MaKV", v)}
            options={options?.KhuVuc}
            fieldNames={{ label: "TenQuay", value: "ID" }}
          />
        </Col>
        <Col span={24}>
          <p>Loại in</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaLoaiMayIn}
            placeholder="Chọn loại in"
            onChange={(v) => applyChange("MaLoaiMayIn", v)}
            options={[
              { TenLI: "In tem", ID: 1 },
              { TenLI: "In bếp", ID: 2 },
              { TenLI: "Hóa đơn", ID: 3 },
            ]}
            fieldNames={{ label: "TenLI", value: "ID" }}
          />
        </Col>
        {/* <Col span={24}>
          <p>Loại sản phẩm</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaLoaiSP}
            placeholder="Chọn loại sản phẩm"
            onChange={(v) => applyChange("MaLoaiSP", v)}
            options={options?.LoaiSP}
            fieldNames={{ label: "TenLSP", value: "MaLSP" }}
          />
        </Col> */}
        <Col span={24}>
          <p>Mẫu in</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaMauIn}
            placeholder="Chọn mẫu in"
            onChange={(v) => applyChange("MaMauIn", v)}
            options={[{ ID: 1, Name: "Mẫu 1" }]}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
      </Row>
    );
  };
  const renderTab4 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Mã kho</p>
          <Input
            value={data?.MaKho}
            placeholder="Nhập mã kho"
            onChange={(e) => applyChange("MaKho", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Tên kho</p>
          <Input
            value={data?.TenKho}
            placeholder="Nhập tên kho"
            onChange={(e) => applyChange("TenKho", e.target.value)}
          />
        </Col>
      </Row>
    );
  };
  return (
    <Drawer
      title="Danh mục"
      width={activeTab === "0" ? 550 : 450}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          marginTop: 20,
        },
      }}
      extra={
        <Space>
          <Button loading={loading} onClick={() => onSubmit()} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {activeTab === "0"
        ? renderTab1()
        : activeTab === "1"
        ? renderTab2()
        : activeTab === "2"
        ? renderTab3()
        : renderTab4()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
