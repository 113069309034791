import { Box} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Flex, Popconfirm, Table, Tabs, Tooltip, notification } from "antd";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import { MdAdd, MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import TabPane from "antd/es/tabs/TabPane";
import { CustomerService } from "services/CustomerService";
import { ColorPicker } from "primereact/colorpicker";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  
 
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const tabs = [
    {
      title: "Trạng thái",
      key: "0",
    },
    {
      title: "Nhóm khách hàng",
      key: "1",
    },
    {
      title: "Nguồn khách hàng",
      key: "2",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");

  useEffect(() => {
    filter(activeTab);
  }, []);
  const filter = async (tab) => {
    setLoading(true);
    if (tab === "0") {
      let _res = await  CustomerService.getStatus();
      if (_res.data) setData(_res.data);
    } else if (tab === "1") {
      let _res = await CustomerService.getGroup();
      if (_res.data) setData(_res.data);
    } else {
      let _res = await CustomerService.getNguon();
      if (_res.data) setData(_res.data);
    }
    setLoading(false);
  };
  const onAfterSubmit = () => {
    filter(activeTab);
  };
  const columns = [
    {
      title: "Tên",
      dataIndex: "Name",
    },
    {
      title: activeTab !== "2" ? "Màu sắc" :" ",
      dataIndex: "Color",
      render: (item) =>
        activeTab !== "2" && <ColorPicker format="hex" value={item} />,
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              ghost
              icon={<MdAdd />}
              onClick={() => refDetail.current.show(activeTab)}
            />
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await CustomerService.deleteStatus({ ID: Number(value) });
        break;
      case "1":
        _res = await CustomerService.deleteGroup({ ID: Number(value) });
        break;
      case "2":
        _res = await CustomerService.deleteNguon({ ID: Number(value) });
        break;
    }
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá danh mục thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
    filter(newActiveKey);
  };

  return (
    <Box >
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              <Table
                loading={loading}
                dataSource={data}
                columns={columns}
                scroll={{
                  y: window.innerHeight - 350,
                  // x: 2500
                }}
                size="small"
              />
            </TabPane>
          );
        })}
      </Tabs>

      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Box>
  );
}
