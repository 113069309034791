import React, { lazy, Suspense } from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdHome,
  MdLock,
  MdOutlineChecklist,
  MdPlaylistAddCheck,
} from "react-icons/md";
import { CiHome } from "react-icons/ci";
import { PiTicketFill } from "react-icons/pi";
import { CiCreditCard2 } from "react-icons/ci";
import { SiAmazonsimpleemailservice, SiCivicrm } from "react-icons/si";
import { IoSettingsOutline } from "react-icons/io5";
import { FaChartPie } from "react-icons/fa6";
import { BiCategoryAlt } from "react-icons/bi";
import { PiWarehouse } from "react-icons/pi";
import { BsCash, BsFillPassportFill } from "react-icons/bs";
//icon
import { FaIdCard, FaSms, FaUserLock } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { CgPlayListAdd } from "react-icons/cg";
import { LuGitBranchPlus } from "react-icons/lu";
import { MdTour } from "react-icons/md";
import { BiListMinus } from "react-icons/bi";
import { FaUsersBetweenLines } from "react-icons/fa6";
import { LiaFileContractSolid } from "react-icons/lia";
// import KhachHang from "views/admin/CRM/KhachHang/index";
import KhachHang from "views/admin/CRM/KhachHang/index";
import BaoCao from "views/admin/BaoCao/index";
import DanhSachBHSP from "views/admin/BanHang/BanSanPham/index";
import DanhSachBHSPTheoBan from "views/admin/BanHang/BanTheoBan/index";
import DanhSachBHVe from "views/admin/BanHang/BanVe/index";
import DanhSachHoaDonViettel from "views/admin/BanHang/HoaDonViettel/index";
import DanhSachBHVeThang from "views/admin/BanHang/BanVeThang/index";
import DanhSachBanVeKD from "views/admin/BanHang/BanVeKinhDoanh/index";
import TicketOnline from "views/admin/BanHang/TicketOnline/index";
import LichSuThe from "views/admin/The/LichSuThe/index";
import DSEmail from "views/admin/CRM/Email/index";
import DSSMS from "views/admin/CRM/SMS/index";
import DanhSachVisa from "views/admin/CRM/Visa/index";
import DanhSachHopDong from "views/admin/CRM/DanhSachHopDong/index";
import NhapKho from "views/admin/KhoHang/NhapKho/index";
import XuatKho from "views/admin/KhoHang/XuatKho/index";
import KiemKe from "views/admin/KhoHang/KiemKe/index";
import BaoCaoTonKho from "views/admin/KhoHang/BaoCao/index";
import DanhSachThe from "views/admin/The/DanhSachThe/index";
import LichSuRaVao from "views/admin/The/LichSuRaVao/index";
import HistoryNotification from "views/admin/HeThong/LichSuNotification";


import DanhSachLSCaLamViec from "views/admin/HeThong/LichSuCaLamViec/index";
import LichSuChamCong from "views/admin/HeThong/LichSuChamCong/index";
import DanhSachNhanVien from "views/admin/HeThong/NhanVien/index";
import PhanQuyen from "views/admin/HeThong/PhanQuyen/index";
import PhanQuyenCongTy from "views/admin/HeThong/PhanQuyen/TheoCongTy/index";
import LSThaoTac from "views/admin/HeThong/LSThaoTac/index";
import DanhSachPhieuThu from "views/admin/SoQuy/PhieuThu/index";
import DanhSachPhieuChi from "views/admin/SoQuy/PhieuChi/index";
import DanhSachCongTy from "views/admin/HeThong/CongTy/index";
import DanhSachSanPham from "views/admin/HeThong/SanPham/index";
import TaiLieu from "views/admin/HeThong/TaiLieu/index";

import BangGia from "views/admin/DanhMuc/BangGia/index";
import KhuVuc from "views/admin/DanhMuc/KhuVuc/index";
import DanhMucNhanVien from "views/admin/DanhMuc/NhanVien/index";
import DanhMucKhachHang from "views/admin/DanhMuc/KhachHang/index";
import DanhMucThe from "views/admin/DanhMuc/The/index";
import KhuVuiChoi from "views/admin/DanhMuc/KhuVuiChoi/index";
import DanhMucSanPham from "views/admin/DanhMuc/SanPham/index";
import DanhMucCRM from "views/admin/DanhMuc/CRM/index";
import DanhMucVisa from "views/admin/DanhMuc/Visa/index";
import HoaDonViettel from "views/admin/DanhMuc/HoaDonViettel/index";
import DanhMucKhac from "views/admin/DanhMuc/Khac/index";

import BaoCaoLichSuQuetThe from "views/admin/BaoCao/LichSuQuetVe/index";
import LichSuGuiTin from "views/admin/BaoCao/LichSuGuiTin/index";
import BaoCaoLichSuQuetTheLoi from "views/admin/BaoCao/LichSuQuetVeLoi/index";
import BaoCaoBanHangTheoNgay from "views/admin/BaoCao/BanHangTheoNgay/index";
import BaoCaoBanHangTheoSP from "views/admin/BaoCao/BanHangTheoSanPham/index";
import BaoCaoChiTietSanPham from "views/admin/BaoCao/ChiTietSanPham/index";
import BaoCaoKhac from "layouts/reports/index";
import SignInCentered from "views/auth/signIn";
import DangKy from "views/auth/register";
import InfoUser from "views/admin/HeThong/ThietLapThongBao";
import Automation from "views/admin/HeThong/Automantion";

// Admin Imports
// giải đấu
//end

//

// Auth Imports

const routes = [
  {
    key: "trang-chu",
    // label: "Trang chủ",
    layout: "/admin",
    icon: <Icon as={CiHome} width="20px" height="20px" color="inherit" />,
    path: "/trang-chu",
    component: BaoCao,
    id: 0,
  },
  {
    label: "Bán hàng",
    layout: "/admin",
    icon: (
      <Icon
        as={MdPlaylistAddCheck}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    children: [
      {
        key: "ban-san-pham",
        label: "Bán sản phẩm",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/ban-san-pham",
        component: DanhSachBHSP,
        id: 272,
      },
      {
        key: "ban-theo-ban",
        label: "Bán theo bàn",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/ban-theo-ban",
        component: DanhSachBHSPTheoBan,
        id: 403,
      },
    ],
    id: 56,
  },
  {
    label: "Bán vé",
    layout: "/admin",
    icon: <Icon as={PiTicketFill} width="20px" height="20px" color="inherit" />,
    children: [
      {
        key: "ban-ve",
        label: "Bán vé",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/ban-ve",
        component: DanhSachBHVe,
        id: 393,
      },
      {
        key: "ve-thang",
        label: "Bán vé tháng",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/ve-thang",
        component: DanhSachBHVeThang,
        id: 413,
      },
      {
        key: "ve-kinh-doanh",
        label: "Vé kinh doanh",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/ve-kinh-doanh",
        component: DanhSachBanVeKD,
        id: 409,
      },
      {
        key: "ticket-online",
        label: "Ticket Online",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/ticket-online",
        component: TicketOnline,
        id: 394,
      },
      {
        key: "hoadon-viettel",
        label: "Hoá đơn Viettel",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/hoadon-viettel",
        component: DanhSachHoaDonViettel,
        id: 427,
      },
    ],
    id: 95,
  },
  {
    label: "Thẻ thành viên",
    layout: "/admin",
    icon: (
      <Icon as={CiCreditCard2} width="20px" height="20px" color="inherit" />
    ),
    children: [
      {
        key: "danh-sach-the",
        label: "Danh sách thẻ",
        layout: "/admin",
        icon: <Icon as={FaIdCard} width="20px" height="20px" color="inherit" />,
        path: "/danh-sach-the",
        component: DanhSachThe,
        id: 282,
      },
      {
        key: "lich-su-the",
        label: "Lịch sử thẻ",
        layout: "/admin",
        icon: (
          <Icon
            as={LuGitBranchPlus}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/lich-su-the",
        component: LichSuThe,
        id: 285,
      },
      {
        key: "lich-su-ra-vao",
        label: "Lịch sử ra vào",
        layout: "/admin",
        icon: (
          <Icon
            as={MdOutlineChecklist}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/lich-su-ra-vao",
        component: LichSuRaVao,
        id: 291,
      },
    ],
    id: 58,
  },

  {
    label: "CRM",
    layout: "/admin",
    icon: <Icon as={SiCivicrm} width="20px" height="20px" color="inherit" />,
    children: [
      {
        key: "danh-sach-khach-hang",
        label: "Danh sách khách hàng",
        layout: "/admin",
        icon: (
          <Icon
            as={FaUsersBetweenLines}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/danh-sach-khach-hang",
        component: KhachHang,
        id: 9,
      },
      {
        key: "danh-sach-hop-dong",
        label: "Danh sách hợp đồng",
        layout: "/admin",
        icon: (
          <Icon
            as={LiaFileContractSolid}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/danh-sach-hop-dong",
        component: DanhSachHopDong,
        id: 88,
      },
      {
        key: "danh-sach-email",
        label: "Danh sách Email",
        layout: "/admin",
        icon: (
          <Icon
            as={SiAmazonsimpleemailservice}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/danh-sach-email",
        component: DSEmail,
        id: 415,
      },
      {
        key: "danh-sach-sms",
        label: "Danh sách SMS",
        layout: "/admin",
        icon: <Icon as={FaSms} width="20px" height="20px" color="inherit" />,
        path: "/danh-sach-sms",
        component: DSSMS,
        id: 416,
      },
      {
        key: "danh-sach-visa",
        label: "Danh sách visa",
        layout: "/admin",
        icon: <BsFillPassportFill />, // <Icon as={FaSms} width="20px" height="20px" color="inherit" />,
        path: "/danh-sach-visa",
        component: DanhSachVisa,
        id: 425,
      },
    ],
    id: 53,
  },

  {
    label: "Kho hàng",
    layout: "/admin",
    icon: <Icon as={PiWarehouse} width="20px" height="20px" color="inherit" />,
    children: [
      {
        key: "nhap-kho",
        label: "Nhập kho",
        layout: "/admin",
        icon: (
          <Icon
            as={LiaFileContractSolid}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/nhap-kho",
        component: NhapKho,
        id: 118,
      },
      {
        key: "xuat-kho",
        label: "Xuất kho",
        layout: "/admin",
        icon: (
          <Icon
            as={LiaFileContractSolid}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/xuat-kho",
        component: XuatKho,
        id: 119,
      },
      {
        key: "bao-cao-ton-kho",
        label: "Báo cáo tồn kho",
        layout: "/admin",
        icon: (
          <Icon
            as={LiaFileContractSolid}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/bao-cao-ton-kho",
        component: BaoCaoTonKho,
        id: 262,
      },
      {
        key: "kiem-ke",
        label: "Kiểm kê",
        layout: "/admin",
        icon: (
          <Icon
            as={LiaFileContractSolid}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/kiem-ke",
        component: KiemKe,
        id: 392,
      },
    ],
    id: 55,
  },
  {
    label: "Sổ quỹ",
    layout: "/admin",
    icon: <Icon as={BsCash} width="20px" height="20px" color="inherit" />,
    children: [
      {
        key: "danh-sach-phieu-thu",
        label: "Phiếu thu",
        layout: "/admin",
        icon: (
          <Icon as={CgPlayListAdd} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-phieu-thu",
        component: DanhSachPhieuThu,
        id: 120,
      },
      {
        key: "danh-sach-phieu-chi",
        label: "Phiếu chi",
        layout: "/admin",
        icon: (
          <Icon as={BiListMinus} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-phieu-chi",
        component: DanhSachPhieuChi,
        id: 121,
      },
    ],
    id: 57,
  },
  {
    label: "Hệ thống",
    layout: "/admin",
    icon: (
      <Icon as={IoSettingsOutline} width="20px" height="20px" color="inherit" />
    ),
    children: [
      {
        key: "danh-sach-tailieu",
        label: "Tài liệu",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-tailieu",
        component: TaiLieu,
        id: 15,
      },
      {
        key: "danh-sach-nhan-vien",
        label: "Nhân viên",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-nhan-vien",
        component: DanhSachNhanVien,
        id: 15,
      },
      {
        key: "danh-sach-cong-ty",
        label: "Công ty",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-cong-ty",
        component: DanhSachCongTy,
        id: 148,
      },
      {
        key: "danh-sach-san-pham",
        label: "Sản phẩm",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-san-pham",
        component: DanhSachSanPham,
        id: 75,
      },
      {
        key: "automation",
        label: "Automation",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/automation",
        component: Automation,
        id: 62,
      },
      {
        key: "phan-quyen",
        label: "Phân quyền",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/phan-quyen",
        component: PhanQuyen,
        id: 62,
      },
      {
        key: "phan-quyen-cong-ty",
        label: "Phân quyền công ty",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/phan-quyen-cong-ty",
        component: PhanQuyenCongTy,
        id: 410,
      },
      {
        key: "lich-su-cham-cong",
        label: "Lịch sử checkin",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/lich-su-cham-cong",
        component: LichSuChamCong,
        id: 420,
      },
      {
        key: "ca-lam-viec",
        label: "Lịch sử ca làm việc",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/ca-lam-viec",
        component: DanhSachLSCaLamViec,
        id: 401,
      },
      {
        key: "ls-thaotac",
        label: "Lịch sử thao tác",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/ls-thaotac",
        component: LSThaoTac,
        id: 414,
      },
      {
        key: "lich-su-notification",
        label: "Lịch sử notification",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/lich-su-notification",
        component: HistoryNotification,
        id: 75,
      },
    ],
    id: 51,
  },
  {
    label: "Danh mục",
    layout: "/admin",
    icon: (
      <Icon as={BiCategoryAlt} width="20px" height="20px" color="inherit" />
    ),
    children: [
      {
        key: "bang-gia",
        label: "Bảng giá",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/bang-gia",
        component: BangGia,
        id: 412,
      },
      {
        key: "khu-vuc",
        label: "Khu vực/ bàn",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/khu-vuc",
        component: KhuVuc,
        id: 402,
      },
      {
        key: "khu-vui-choi",
        label: "Khu vui chơi",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/khu-vui-choi",
        component: KhuVuiChoi,
        id: 395,
      },
      {
        key: "danh-muc-nhan-vien",
        label: "Danh mục nhân viên",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-muc-nhan-vien",
        component: DanhMucNhanVien,
        id: 396,
      },
      {
        key: "danh-muc-khach-hang",
        label: "Danh mục khách hàng",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-muc-khach-hang",
        component: DanhMucKhachHang,
        id: 397,
      },
      {
        key: "danh-muc-nhan-the",
        label: "Danh mục thẻ",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-muc-nhan-the",
        component: DanhMucThe,
        id: 398,
      },
      {
        key: "san-pham",
        label: "Sản phẩm",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/san-pham",
        component: DanhMucSanPham,
        id: 399,
      },
      {
        key: "danh-muc-crm",
        label: "CRM",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-muc-crm",
        component: DanhMucCRM,
        id: 411,
      },
      {
        key: "danhmuc-visa",
        label: "Visa",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danhmuc-visa",
        component: DanhMucVisa,
        id: 426,
      },
      {
        key: "danhmuc-viettel",
        label: "Hoá đơn viettel",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danhmuc-viettel",
        component: HoaDonViettel,
        id: 428,
      },
      {
        key: "khac",
        label: "Khác",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/khac",
        component: DanhMucKhac,
        id: 400,
      },
    ],
    id: 94,
  },
  {
    label: "Báo cáo",
    layout: "/admin",
    icon: <Icon as={FaChartPie} width="20px" height="20px" color="inherit" />,
    children: [
      {
        key: "lich-su-gui-tin",
        label: "Lịch sử gửi tin",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/lich-su-gui-tin",
        component: LichSuGuiTin,
        id: 406,
      },
      {
        key: "lich-su-quet-the",
        label: "Lịch sử quẹt vé",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/lich-su-quet-the",
        component: BaoCaoLichSuQuetThe,
        id: 406,
      },
      {
        key: "lich-su-quet-ve-loi",
        label: "Lịch sử quẹt vé lỗi",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/lich-su-quet-ve-loi",
        component: BaoCaoLichSuQuetTheLoi,
        id: 408,
      },
      {
        key: "bao-cao-ban-hang-hang-ngay",
        label: "Bán hàng theo ngày",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/bao-cao-ban-hang-hang-ngay",
        component: BaoCaoBanHangTheoNgay,
        id: 405,
      },
      {
        key: "bao-cao-ban-hang-theo-san-pham",
        label: "Bán hàng theo sản phẩm",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/bao-cao-ban-hang-theo-san-pham",
        component: BaoCaoBanHangTheoSP,
        id: 404,
      },
      {
        key: "bao-cao-chi-tiet-san-pham",
        label: "Báo cáo chi tiết theo sản phẩm",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/bao-cao-chi-tiet-san-pham",
        component: BaoCaoChiTietSanPham,
        id: 418,
      },
      {
        key: "report-other",
        label: "Báo cáo khác",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/report-other",
        component: BaoCaoKhac,
        id: 417,
      },
    ],
    id: 61,
  },
  {
    // label: "Thông tin người dùng",
    layout: "/admin",
    // icon: (
    //   <Icon as={BiCategoryAlt} width="20px" height="20px" color="inherit" />
    // ),
    children: [
      {
        key: "info-user",
        label: "Info user",
        layout: "/admin",
        icon: <GoDotFill />,
        path: "/info-user",
        component: InfoUser,
        // id: 395,
      },
    ],
    // id: 94,
  },
  {
    label: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    label: "Đăng ký",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: DangKy,
  },
];

// const routes = [
//   {
//     key: "admin",
//     routes: adminRoutes,
//   },
// ];

// // Render routes with Suspense
// const RenderRoutes = () => {
//   return (
//     <Suspense fallback={<Loading />}>
//       {routes.map((routeGroup) =>
//         routeGroup.routes.map((route) => (
//           <Route
//             key={route.id}
//             path={route.layout + route.path}
//             element={<route.component />}
//           />
//         ))
//       )}
//     </Suspense>
//   );
// };
export default routes;
