import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  InputNumber,
  Row,
  Col,
  Drawer,
  notification,
  Space,
  Checkbox,
  Flex,
  DatePicker,
  Select,
  Upload,
} from "antd";

import _ from "lodash";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import DebounceSelect from "components/DebounceSelect";
import { SanPhamService } from "services/SanPhamService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CiTrash } from "react-icons/ci";
import locale from "antd/es/date-picker/locale/vi_VN";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { KhoHangService } from "services/KhoHangService";
import { UploadOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const { Search } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    SanPhamChoose: {},
    SanPham: [],
    NgayXK: new Date(),
    SoLuong: 1,
    MaLoai:1
  };
  const defaultError = {
    MaKho: null
  }
  const [errors,setErrors] = useState(defaultError);
  const [khohang,setKhohang] = useState([]);
    const [loai, setLoai] = useState([]);

  const [data, setData] = useState(defaulData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isbarcode,setIsbarcode] = useState(false);
  const loadcategories = async () => {
    // let _lhd = await ContractService.getLoaiHD();
    // let _tt = await ContractService.getTrangThaiHD();
    // let _lt = await ContractService.getLoaiTien();
    // let _dvt = await SanPhamService.getDVT();
    // setLoaihd(_lhd.data ?? []);
    // setTrangthai(_tt.data ?? []);
    // setLoaiTien(_lt.data ?? []);
    // setDvt(_dvt.data ?? []);
    let _kho = await KhoHangService.NhapKho.getTenKho();
    setKhohang(_kho.data ?? []);
     let _loai = await KhoHangService.XuatKho.getLoai();
     setLoai(_loai.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadcategories();
    },
    update: async (data) => {
      setOpen(true);
      let _sp = await KhoHangService.XuatKho.getSanPhamByMaXK({
        MaPX: data.ID,
      });
      data.SanPham = _sp?.data ?? [];
      data.SoLuong = 0
      setData(data);
      loadcategories();
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "SanPhamChoose":
        _data[p] = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const applyChangeSP = async (p, v, index) => {
    let _data = _.cloneDeep(data);
    const SUM = (value)=>{
      value.ThanhTien = value.DonGia * value.SoLuong
    }
    switch (p) {
      default:
        _data.SanPham[index][p] = v;
        SUM(_data.SanPham[index]);
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () =>{
    let _error = _.cloneDeep(errors);
    Object.keys(_error)?.map((item)=>{
        _error[item] = data[item] ? null :"Vui lòng chọn kho hàng";
    });
    setErrors(_error);
    let valid = _.uniq(Object.values(_error)?.filter(v=>v))
    return valid;
  }
  const onSubmit = async (close) => {
    setLoading(true);
    let valid = validate();
    if(valid?.length > 0){
      setLoading(false);
      return;
    }
    let _data = _.cloneDeep(data);
    let _res = null;
    const sanpham = []
    _data?.SanPham?.map((item)=>{
      sanpham.push({
        ...item,
        MaKho : _data.MaKho
      })
    })
    let _payload = {
      ..._data,
      SanPham: sanpham,
    };
    if (_data.ID) {
      _res = await KhoHangService.XuatKho.update(_data);
    } else {
      _res = await KhoHangService.XuatKho.add(_payload);
      setData((pre) => {
        return { ...pre, ID: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} xuất kho thành công!`
      );
      if (close) {
        onClose();
      }
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderCoCauGiai = () => {
    const addCoCau = () => {
      let _data = _.cloneDeep(data);
      var item = _data.SanPham?.find(
        (v) => v.MaSP === _data.SanPhamChoose?.value
      );
      if (item) {
        item.SoLuong += _data.SoLuong;
        item.DonGia += _data.DonGia;
        item.ThanhTien += _data.SoLuong * _data.DonGia;
      } else {
        _data.SanPham.push({
          SoLuong: _data.SoLuong,
          DonGia: _data.DonGia,
          MaSP: _data.SanPhamChoose?.value,
          ThanhTien: _data.SoLuong * _data.DonGia,
          MaDVT: JSON.parse(_data?.SanPhamChoose?.title)?.MaDVT,
          TenSP: JSON.parse(_data?.SanPhamChoose?.title)?.TenSP,
        });
      }
      _data.SanPhamChoose = {};
      _data.SoLuong = 1;
      _data.DonGia = 0;
      setData(_data);
    };
    const deleteCoCau = (v, index) => {
      let _data = _.cloneDeep(data);
      _data.SanPham.splice(index, 1);
      setData(_data);
      if (v.ID) {
        KhoHangService.XuatKho.deleteSanPham({ ID: v.ID });
      }
    };
    async function fetchList(username) {
      return SanPhamService.getList({ InputSearch: username }).then((res) => {
        return res?.data?.map((item) => ({
          label: `${item.MaVach} - ${item.TenSP}`,
          value: item.ID,
          title: JSON.stringify(item),
        }));
      });
    }
    const handleKeyDown =async (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      var _res = await SanPhamService.byMaVach({InputSearch: e.target.value});
      if(_res?.data){
        let _data = _.cloneDeep(data);
        _data.SanPham.push({
          SoLuong: 1,
          DonGia: 0,
          MaSP: _res?.data?.ID,
          ThanhTien: 0,
          MaDVT: _res?.data?.MaDVT,
          TenSP: _res?.data?.TenSP,
        });
        _data.MaVach="";
        setData(_data)
      }else{
        openNotification(
          "error",
          "topRight",
          `Không tìm thấy sản phẩm này`
        );
      }
      setLoading(false);
    }
  }
    return (
      <Row style={{ height: "100%" }}>
        <Col span={19}>
          <Row align="middle" style={{ padding: "10px 0px" }}>
            <Col span={3}>
              <Checkbox
                checked={isbarcode}
                onChange={(v) => setIsbarcode(v.target.checked)}
              >
                Barcode
              </Checkbox>
            </Col>
            {isbarcode ? (
              <Col span={8}>
                <Search
                  onKeyDown={(e) => handleKeyDown(e)}
                  value={data?.MaVach}
                  placeholder="Nhập mã vạch"
                  onChange={(e) => applyChange("MaVach", e.target.value)}
                  loading={loading}
                />

                {/* <Input
                  onKeyDown={(e) => handleKeyDown(e)}
                  value={data?.MaVach}
                  placeholder="Nhập mã vạch"
                  onChange={(e) => applyChange("MaVach", e.target.value)}
                /> */}
              </Col>
            ) : (
              <>
                <Col span={8}>
                  <DebounceSelect
                    // mode="multiple"
                    showSearch
                    placeholder="Nhập mã hàng cần tìm kiếm"
                    fetchOptions={fetchList}
                    value={data?.SanPhamChoose}
                    onChange={(newValue) =>
                      applyChange("SanPhamChoose", newValue)
                    }
                    style={{
                      width: "100%",
                    }}
                  />
                </Col>
                <Col span={5}>
                  <Flex align="center" justify="flex-end">
                    <p>Số lượng:</p>
                    <InputNumber
                      style={{ width: "50%" }}
                      value={data?.SoLuong}
                      onChange={(v) => applyChange("SoLuong", v)}
                    />
                  </Flex>
                </Col>
                <Col span={5}>
                  <Flex align="center" justify="center">
                    <p>Giá:</p>
                    <InputNumber
                      value={data?.DonGia}
                      style={{ width: "60%" }}
                      onChange={(v) => applyChange("DonGia", v)}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                    />
                  </Flex>
                </Col>
                <Col span={1}>
                  <Button
                    loading={loading}
                    onClick={() => addCoCau()}
                    type="primary"
                    ghost
                  >
                    Thêm
                  </Button>
                </Col>
              </>
            )}
          </Row>
          <DataTable
            size="small"
            showGridlines
            stripedRows
            value={data.SanPham}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column
              header="Sản phẩm"
              style={{ width: "200px" }}
              body={(item, row) => <p>{item.TenSP}</p>}
            />
            {/* <Column
            header="ĐVT"
            body={(item, row) => {
              return (
                <p>dvt</p>

                // <Select
                //   style={{
                //     width: "100%",
                //   }}
                //   value={item?.MaDVT}
                //   placeholder="Chọn ĐVT"
                //   onChange={(v) => applyChangeSP("MaDVT", v, row.rowIndex)}
                //   options={dvt}
                //   fieldNames={{ label: "TenDVT", value: "MaDVT" }}
                // />
              );
            }}
          /> */}
            <Column
              header="Số lượng"
              body={(item, row) => {
                return (
                  <InputNumber
                    placeholder="Số lượng"
                    style={{ width: "100%" }}
                    value={item?.SoLuong}
                    onChange={(v) => applyChangeSP("SoLuong", v, row.rowIndex)}
                  />
                );
              }}
            />
            <Column
              header="Đơn giá"
              body={(item, row) => {
                return (
                  <InputNumber
                    placeholder="Đơn giá"
                    style={{ width: "100%" }}
                    value={item?.DonGia}
                    onChange={(v) => applyChangeSP("DonGia", v, row.rowIndex)}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                );
              }}
            />
            <Column
              header="Thành tiền"
              body={(item, row) => {
                return (
                  <InputNumber
                    placeholder="Thành tiền"
                    style={{ width: "100%" }}
                    value={item?.ThanhTien}
                    disabled
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                );
              }}
            />

            <Column
              body={(item, row) => {
                return (
                  <Button
                    onClick={() => deleteCoCau(item, row.rowIndex)}
                    danger
                    ghost
                    icon={<CiTrash />}
                  />
                );
              }}
            />
          </DataTable>
        </Col>
        <Col span={5} style={{ borderLeft: "1px solid #ced4da", padding: 10 }}>
          <p>Ngày nhập kho</p>
          <DatePicker
            defaultValue={
              data?.NgayXK ? dayjs(data?.NgayXK) : dayjs(new Date())
            }
            style={{ width: "100%" }}
            allowClear={false}
            format="DD-MM-YYYY"
            locale={locale}
            placeholder="Chọn ngày sử dụng dịch vụ"
            onChange={(v) => applyChange("NgayXK", v)}
          />
          <p style={{ marginTop: 20 }}>Kho hàng</p>
          <Select
            style={{
              width: "100%",
            }}
            // status={data?.MaLT ? "" : "error"}
            value={data?.MaKho}
            placeholder="Chọn kho hàng"
            onChange={(v) => applyChange("MaKho", v)}
            options={khohang}
            fieldNames={{ label: "TenKho", value: "ID" }}
          />
          <p style={{ color: "red" }}>{errors?.MaKho}</p>
          <p style={{ marginTop: 20 }}>Loại</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaLoai}
            placeholder="Chọn loại nhập kho"
            onChange={(v) => applyChange("MaLoai", v)}
            options={loai}
            fieldNames={{ label: "TenLoai", value: "ID" }}
          />
          <p style={{ marginTop: 20 }}>Diễn giải</p>
          <TextArea
            value={data?.DienGiai}
            onChange={(e) => applyChange("DienGiai", e.target.value)}
            placeholder="Nhập diễn giải"
          />
          {/* <p style={{ marginTop: 20 }}>Nhà cung cấp</p>
          <Select
            style={{
              width: "100%",
            }}
            // status={data?.MaLT ? "" : "error"}
            value={data?.MaLT}
            placeholder="Chọn loại thẻ"
            // onChange={(v) => applyChange("MaLT", v)}
            // options={cardType}
            fieldNames={{ label: "TenLT", value: "ID" }}
          /> */}
        </Col>
      </Row>
    );
  };
  const loadDataImport =async (value) => {
    let _res = await SanPhamService.getList({InputSearch:""});
    console.log(_res);
    let res = [];
    value?.map((item, i) => {
      if (i > 0) {
        let _sp = _res.data?.find((v) => v.MaVach === item[0]);
        if (_sp) {
          res.push({
            SoLuong: item[1],
            DonGia: item[2],
            MaSP: _sp.ID,
            ThanhTien: item[1] * item[2],
            MaDVT: _sp?.MaDVT,
            TenSP: _sp?.TenSP,
          });
        }
      }
    });
    let _data = _.cloneDeep(data);
    _data.SanPham = res;
    console.log(_data);
    setData(_data);
  };
   const fileType =
     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
   const fileExtension = ".xlsx";
   const exportToCSV = () => {
       let _data  = [
         {
           MaVach: "123321",
           SoLuong: 25,
           DonGia: 100000,
           ThanhTien: 100,
         },
         {
           MaVach: "123321",
           SoLuong: 12,
           DonGia: 200000,
           ThanhTien: 100,
         },
       ];
     const ws = XLSX.utils.json_to_sheet(_data);
     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
     const dataEX = new Blob([excelBuffer], { type: fileType });
     FileSaver.saveAs(dataEX, "template_xuatkho" + fileExtension);
   };
  return (
    <Drawer
      title="Xuất kho"
      width="1120px"
      onClose={onClose}
      open={open}
      className="xuatkho-detail"
      extra={
        <Space>
          <Upload
            accept=".txt, .csv, .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                loadDataImport(data);
                // setNewFile(true);
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Import</Button>
          </Upload>
          <Button icon={<UploadOutlined />} onClick={() => exportToCSV()}>
            Export
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(false)}
            type="primary"
            ghost
          >
            Lưu
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderCoCauGiai()}
      {/* <div style={{ display: "none" }}>
        <HoaDonToPrint ref={componentRef} data={data} />
      </div> */}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
