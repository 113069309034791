import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Flex,
  Row,
  Col,
  Input,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { Format_Date } from "layouts/ultis/common";
import { CustomerService } from "services/CustomerService";
import { MdEdit } from "react-icons/md";
import FormDetail from "./FormDetail";
import { CongTyService } from "services/CongTyService";
import { CRMService } from "services/CRMService";
import { NhanVienService } from "services/NhanVienService";
import { IoIosSend } from "react-icons/io";
import { SendSMS } from "services/api_helper";

export default function Settings() {
  const [dataAll, setDataAll] = useState([]);
  const [data, setData] = useState([]);
  const [stateActive, setStateActive] = useState({
    ID: 0,
    Name: "Tất cả",
    Color: "gray",
  });
  // const [status, setStatus] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const refDetail = useRef(null);
  const refAdd = useRef(null);
  const [loading, setLoading] = useState(false);
  // const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [nhanvien, setNhanvien] = useState([]);

  const [filterCondition, setFilterCondition] = useState({
    InputSearch: "",
    MaCT: 0,
  });
  useEffect(() => {
    loadCategories();
    // loadBuyin(filterCondition);
  }, []);

  const loadBuyin = async (_filter) => {
    setLoading(true);
    let _res = await CRMService.SMS.GetSMS(_filter);
    setData(_res.data);
    let _resNV = await NhanVienService.getNhanVien();
    setNhanvien(_resNV.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    // let _resCT = await CongTyService.getCongTy();
    // setCompanies(_resCT.data);
    let _resBrand = await CRMService.Categories.getConfigSMS();
    setBrands(_resBrand.data);
  };
  const columns = [
    {
      title: "Tên chiến dịch",
      dataIndex: "CampaignName",
    },
    {
      title: "Ngày tạo",
      dataIndex: "DateCreate",
      render: (item) => Format_Date(item),
    },
    {
      title: "Thương hiệu",
      dataIndex: "BrandID",
      render: (item) => brands?.find((v) => v.ID === item)?.BrandName,
    },
    {
      title: "Nhân viên",
      dataIndex: "StaffCreate",
      render: (item) => nhanvien?.find((v) => v.MaNV === item)?.HoTen,
    },
    {
      title: "",
      fixed: "right",
      width: "140px",
      render: (item) => {
        return (
          <Flex justify="center" gap={5}>
            <Tooltip placement="topRight" title="Gửi sms">
              <Popconfirm
                title="Gửi"
                description="Bạn có chắc chắn muốn gửi tin hay không?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => handelSend(item)}
              >
                <Button icon={<IoIosSend />} />
              </Popconfirm>
            </Tooltip>

            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refAdd.current.update(item)}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá"
              description="Bạn có chắc chắn muốn xoá hay không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item)}
            >
              <Button danger ghost icon={<CiTrash />} />
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await CRMService.SMS.DeleteSMS({
      ID: item.ID,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(item.ID));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const handelSend = async (item) => {
    let _res = await CRMService.SMS.SendSMS({ ID: item.ID });
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        "Tiến trình gửi SMS đang thực hiện!"
      );
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onAfterSubmit = () => {
    loadBuyin(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    loadBuyin(_filter);
    setFilterCondition(_filter);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách buyin" + fileExtension);
  };

  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        loadBuyin(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  return (
    <Row gutter={[15]}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              placeholder="Tìm kiếm"
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          right={() => (
            <Button type="primary" onClick={() => refAdd.current.show()}>
              Thêm
            </Button>
          )}
        />

        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            // x: 1500,
          }}
          size="small"
        />
      </Col>

      <FormAdd ref={refAdd} onAfterSubmit={onAfterSubmit} />
      <FormDetail ref={refDetail} onAfterSubmit={onAfterSubmit} />

      {contextHolder}
    </Row>
  );
}
