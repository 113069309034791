import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Popconfirm, notification, Tooltip, Badge, Row, Col, Input } from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { Format_Date } from "layouts/ultis/common";
import { CustomerService } from "services/CustomerService";
import { MdEdit } from "react-icons/md";
import FormDetail from "./FormDetail";
import { CongTyService } from "services/CongTyService";

export default function Settings() {
  const [dataAll, setDataAll] = useState([]);
  const [data, setData] = useState([]);
  const [stateActive, setStateActive] = useState({
    ID: 0,
   
    Name: "Tất cả",
    Color: "gray",
  });
  const [status, setStatus] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const refDetail = useRef(null);
  const refAdd = useRef(null);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    InputSearch: "",
    MaCT: 0,
  });
  useEffect(() => {
    loadCategories();
    // loadBuyin(filterCondition);
  }, []);

  const loadBuyin = async (_filter) => {
    setLoading(true);

    let _res = await CustomerService.getList(_filter);
    let _tt = await CustomerService.getStatus();
    let status = [{ ID: 0, Name: "Tất cả", Color: "cccccc" }, ..._tt.data] ?? [];
    status.map((item) => {
      if (item.ID === 0) {
        item.Total = _res.data?.length;
      } else {
        item.Total = _res?.data?.filter((v) => v.MaTT === item.ID)?.length;
      }
    });
    setStatus(status);

    setDataAll(_res.data);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    let _resCT = await CongTyService.getCongTy();
    setCompanies(_resCT.data);
  };
  const columns = [
    {
      title: "Tên khách hàng",
      render: (item) => (
        <p
          style={{ fontWeight: "bold", color: "cadetblue", cursor: "pointer" }}
          onClick={() => refDetail.current.update(item)}
        >
          {item.TenCongTy}
        </p>
      ),
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      render: (item) => Format_Date(item),
    },
    {
      title: "Di động",
      dataIndex: "DiDong",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Số CMND",
      dataIndex: "SoCMND",
    },
    {
      title: "Nhóm khách hàng",
      dataIndex: "TenNKH",
    },
    {
      title: "",
      fixed: "right",
      width: "100px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refAdd.current.update(item)}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá khách hàng"
              description="Bạn có chắc chắn muốn xoá khách hàng hay không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item)}
            >
              <Button
                style={{ marginLeft: 10 }}
                danger
                ghost
                icon={<CiTrash />}
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await CustomerService.delete({
      ID: item.MaKH,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(item.ID));
      setData(fil);
      openNotification("success", "topRight", "Xoá khách hàng thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onAfterSubmit = () => {
    loadBuyin(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    loadBuyin(_filter);
    setFilterCondition(_filter);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách buyin" + fileExtension);
  };
  const changeState = (state) => {
    if (state === 0) {
      setData(dataAll);
    } else {
      let _data = dataAll.filter((v) => v.MaTT === state);
      setData(_data);
    }
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        loadBuyin(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  return (
    <Row gutter={[15]}>
      <Col span={4}>
        <FilterModal
          companies={companies}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              placeholder="Tìm kiếm"
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          right={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
              }}
            >
              {status?.map((item) => {
                return (
                  <Badge
                    key={item.ID}
                    count={item.Total}
                    onClick={() => {
                      setStateActive(item);
                      changeState(item.ID);
                    }}
                  >
                    <div
                      style={{
                        border: `1px solid ${"#" + item.Color}`,
                        padding: "6px 15px",
                        cursor: "pointer",
                        borderRadius: 10,
                        background:
                          stateActive.ID === item.ID ? "#" + item.Color : null,
                        color: stateActive.ID === item.ID ? "white" : null,
                      }}
                    >
                      <p>{item.Name}</p>
                    </div>
                  </Badge>
                );
              })}
              <Button type="primary" onClick={() => refAdd.current.show()}>
                Thêm
              </Button>
            </div>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            // x: 1500,
          }}
          size="small"
        />
      </Col>

      <FormAdd ref={refAdd} onAfterSubmit={onAfterSubmit} />
      <FormDetail ref={refDetail} onAfterSubmit={onAfterSubmit} />

      {contextHolder}
    </Row>
  );
}
