import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import * as React from "react";
import { NhanVienService } from "services/NhanVienService";
import "./scss/index.scss";
import { Flex } from "antd";
const HoaDonToPrint = (props, ref) => {
  const { data,  dataPrint } = props;
  const [nhanvien, setNhanVien] = React.useState({});
  React.useEffect(() => {
    NhanVienService.getDetail().then((res) => {
      setNhanVien(res.data ?? []);
    });
  }, []);

  return (
    <div
      ref={ref}
      className="print-container"
      style={{ margin: "0", padding: "0" }}
    >
      <div className="page-break" />
      <div style={{ height: "98vh", margin: "0 10px", padding: "0" }}>
        <div style={{ textAlign: "center", padding: "15px 0px" }}>
          <p style={{ fontSize: 15, fontWeight: "bold" }}>{nhanvien?.TenCT}</p>
          <p>{nhanvien?.DiaChi}</p>
          <p>{nhanvien?.DienThoai}</p>
        </div>
        <div style={{ padding: "10px 0px" }}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            HOÁ ĐƠN BÁN HÀNG
          </p>
          <Flex justify="space-between">
            <p>Ngày: {Format_Date(new Date())}</p>
            <p>{dataPrint?.SoHD}</p>
          </Flex>
          <Flex justify="space-between">
            <p>Thu ngân: {nhanvien?.HoTen}</p>
            <p>Khách hàng: {data?.TenKH}</p>
          </Flex>
          <Flex justify="space-between">
            <p>
              In lúc:{" "}
              {new Date().getHours() < 10
                ? "0" + new Date().getHours()
                : new Date().getHours()}
              :
              {new Date().getMinutes() < 10
                ? "0" + new Date().getMinutes()
                : new Date().getMinutes()}
            </p>
            <p>Quầy: {dataPrint?.TenQuay}</p>
          </Flex>
        </div>
        <table style={{ width: "100%" }}>
          <tr
            style={{
              textAlign: "left",
              borderBottom: "2px solid black",
            }}
          >
            <th style={{ fontSize: 12 }}>Mặt hàng</th>
            <th style={{ fontSize: 12 }}>SL</th>
            <th style={{ fontSize: 12 }}>Đ.Giá</th>
            <th style={{ fontSize: 12 }}>T.Tiền</th>
          </tr>
          {data?.SanPham?.map((item) => {
            return (
              <tr>
                <td style={{ width: "40%", fontSize: 12 }}>{item.TenSP}</td>
                <td style={{ width: "10%", fontSize: 12 }}>{item.SoLuong}</td>
                <td style={{ width: "20%", fontSize: 12 }}>
                  {Format_Currency(item.DonGia)}
                </td>
                <td style={{ width: "20%", fontSize: 12 }}>
                  {Format_Currency(item.DonGia * item.SoLuong - item.TienCK)}
                </td>
              </tr>
            );
          })}
          <tfoot
            style={{
              padding: "10px 0px",
              borderTop: "1px solid gray",
            }}
          >
            <tr>
              <td style={{ fontSize: 12 }}>Tổng cộng</td>
              <td></td>
              <td></td>
              <td style={{ fontSize: 12 }}>
                {Format_Currency(data?.ThanhTien)}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: 12 }}>Chiết khấu</td>
              <td></td>
              <td></td>
              <td style={{ fontSize: 12 }}>{Format_Currency(data?.TienCK)}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold", fontSize: 13 }}>Khách trả</td>
              <td></td>
              <td></td>
              <td style={{ fontWeight: "bold", fontSize: 13 }}>
                {Format_Currency(data?.ThanhTien - data?.TienCK)}
              </td>
            </tr>
          </tfoot>
        </table>
        <p
          style={{
            marginTop: 20,
            fontStyle: "italic",
            fontSize: 12,
            textAlign: "center",
          }}
        >
          Phiếu này có giá trị xuất hoá đơn đỏ trong ngày
        </p>
        <p
          style={{
            fontStyle: "italic",
            fontSize: 12,
            textAlign: "center",
          }}
        >
          Cảm ơn quý khách. Hẹn gặp lại!
        </p>
      </div>
    </div>
  );
};
export default React.forwardRef(HoaDonToPrint);
