import React, { useEffect, useRef, useState } from "react";
import { Button, Row, Col, DatePicker, Select, Flex, Input } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import { BanHangService } from "services/BanHangService";
import Print from "./Print";
import { ReportService } from "services/ReportService";
import { NhanVienService } from "services/NhanVienService";
import { useReactToPrint } from "react-to-print";
import DebounceSelect from "components/DebounceSelect";
import { CustomerService } from "services/CustomerService";
import { SanPhamService } from "services/SanPhamService";
import LichSuTieuDungThe from "./LichSuTieuDungThe";
import { CardService } from "services/CardService";
import Search from "antd/es/input/Search";
import LichSuTieuDungDiemThe from "./LichSuTieuDungDiemThe";
import ChiNhanhSelect from "components/ChiNhanhSelect";
import { PermissionService } from "services/PermissionService";
import { CongTyService } from "services/CongTyService";
import LichSuCheckIn from "./LichSuCheckIn";
export default function Settings() {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(false);
  const componentRef = useRef();
  const componentRef2 = useRef();
  const [quay, setQuay] = useState([]);
  const [nhanvien, setNhanvien] = useState([]);
  const [loaiHD, setLoaiHD] = useState([]);
  const [sanpham, setSanpham] = useState([]);
  const [congty, setCongty] = useState([]);
  const refPer = useRef(null);
  let now = new Date(); 
  const [filterCondition, setFilterCondition] = useState({
    FormID: 417,
    Report: 1,
    Horizontal: false,
    TuNgay: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0),
    DenNgay: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 0, 0),
    MaNhom: 2,
    MaCT: 0,
    QuayBan: ",,",
    NhanVien: ",,",
    LoaiHD: ",,",
    KhachHang: ",,",
    InputSearch: "",
    MaLyDo: ",,",
  });
  useEffect(() => {
    filter(filterCondition);
    BanHangService.getQuayBan().then((res) => setQuay(res.data ?? []));
    BanHangService.Categories.getLoaiHD().then((res) =>
      setLoaiHD(res.data ?? [])
    );
    NhanVienService.getNhanVien().then((res) => setNhanvien(res.data ?? []));
    PermissionService.checkQuyen({ FormID: 417 }).then((res) => {
      refPer.current = res.data;
      if (res.data == 1) {
        CongTyService.getCongTy().then((_ct) => {
          setCongty(_ct.data);
        });
      }
    });
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    if (_filter.Report === 1) {
      let _res = await ReportService.getBanHangTheoNgay(_filter);
      if (_res.data) setData(_res.data);
      let _res2 = await ReportService.getBanHangTheoSP(_filter);
      if (_res2.data) setData2(_res2.data);
      let _sp = await SanPhamService.getList({ InputSearch: "" });
      setSanpham(_sp.data ?? []);
    } else if (_filter.Report === 2) {
      let _res = await CardService.getCardHistory(_filter);
      if (_res.data) setData(_res.data);
      let _res2 = await CardService.getBanHangLSTruThe(_filter);
      if (_res2.data) setData2(_res2.data);
    } else if (_filter.Report === 4) {
      let _res = await NhanVienService.getLSChamCongTongHop(_filter);
      if (_res.data) setData(_res.data);
    }
    setLoading(false);
  };

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    console.log(v);
    switch (p) {
      case "KhachHang":
        let arrkey = _.map(v, "key");
        _filter[p] = arrkey?.length > 0 ? "," + arrkey.join(",") + "," : ",,";
        break;
      case "QuayBan":
      case "LoaiHD":
      case "NhanVien":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      default:
        _filter[p] = v;
        break;
    }
    setFilterCondition(_filter);
    filter(_filter);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  async function fetchUserList(username) {
    return CustomerService.filter({ InputSearch: username }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenCongTy} - ${item.DiDong}`,
        value: item.MaKH,
        title: JSON.stringify(item),
      }));
    });
  }
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        filter(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const reports = [
    { ID: 1, Name: "Tổng hợp bán hàng" },
    { ID: 2, Name: "Lịch sử tiêu dùng thẻ" },
    { ID: 3, Name: "Lịch sử tiêu dùng điểm thẻ" },
    { ID: 4, Name: "Tổng hợp checkin, checkout" },
  ];
  return (
    <Row>
      <Col span={24} style={{ position: "fixed", zIndex: 2, width: "100%" }}>
        <div className="box-search" style={{ padding: "8px 30px" }}>
          <Row justify="space-between" align="middle">
            <Col span={21}>
              <Flex gap={20}>
                <div style={{ width: 300 }}>
                  <p className="label">Báo cáo</p>
                  <Select
                    maxTagCount="responsive"
                    defaultValue={1}
                    onChange={(v) => applyChangeFilter("Report", v)}
                    style={{
                      width: "100%",
                    }}
                    options={reports}
                    value={filterCondition?.Report}
                    fieldNames={{ label: "Name", value: "ID" }}
                  />
                </div>
                <div style={{ width: 100 }}>
                  <p className="label">Mẫu A4</p>
                  <Select
                    placeholder="Chọn quầy bán"
                    onChange={(v) => applyChangeFilter("Horizontal", v)}
                    style={{
                      width: "100%",
                    }}
                    options={[
                      { ID: false, Name: "Dọc" },
                      { ID: true, Name: "Ngang" },
                    ]}
                    value={filterCondition?.Horizontal}
                    fieldNames={{ label: "Name", value: "ID" }}
                  />
                </div>
                {refPer.current === 1 && (
                  <div style={{ width: 250 }}>
                    <p className="label">Chi nhánh</p>
                    <Select
                      // mode="multiple"
                      allowClear
                      placeholder="Chọn chi nhánh"
                      onChange={(v) => applyChangeFilter("MaCT", v)}
                      style={{
                        width: "100%",
                      }}
                      options={congty}
                      fieldNames={{ label: "TenCTVT", value: "ID" }}
                    />
                  </div>
                )}

                <div style={{ width: 250 }}>
                  <p className="label">Từ ngày</p>
                  <DatePicker
                    // format="DD-MM-YYYY"
                    format="DD-MM-YYYY HH:mm"
                    style={{ width: "100%" }}
                    defaultValue={dayjs(filterCondition.TuNgay)}
                    placeholder="Từ ngày"
                    onChange={(v) => applyChangeFilter("TuNgay", v)}
                    showTime={{ use12Hours: false }}

                    // locale={locale}
                  />
                </div>
                <div style={{ width: 250 }}>
                  <p className="label">Đến ngày</p>
                  <DatePicker
                    // format="DD-MM-YYYY"
                    format="DD-MM-YYYY HH:mm"
                    style={{ width: "100%" }}
                    defaultValue={dayjs(filterCondition.DenNgay)}
                    placeholder="Đến ngày "
                    showTime={{ use12Hours: false }}
                    onChange={(v) => applyChangeFilter("DenNgay", v)}
                  />
                </div>
                {filterCondition?.Report === 1 && (
                  <div style={{ width: 250 }}>
                    <p className="label">Nhân viên</p>
                    <Select
                      maxTagCount="responsive"
                      mode="multiple"
                      allowClear
                      placeholder="Chọn nhân viên"
                      onChange={(v) => applyChangeFilter("NhanVien", v)}
                      style={{
                        width: "100%",
                      }}
                      options={nhanvien}
                      fieldNames={{ label: "HoTen", value: "MaNV" }}
                    />
                  </div>
                )}

                {filterCondition?.Report === 1 && (
                  <div style={{ width: 250 }}>
                    <p className="label">Quầy bán</p>
                    <Select
                      maxTagCount="responsive"
                      mode="multiple"
                      allowClear
                      placeholder="Chọn quầy bán"
                      onChange={(v) => applyChangeFilter("QuayBan", v)}
                      style={{
                        width: "100%",
                      }}
                      options={quay}
                      fieldNames={{ label: "TenQuay", value: "ID" }}
                    />
                  </div>
                )}

                {filterCondition?.Report === 1 && (
                  <div style={{ width: 250 }}>
                    <p className="label">Loại HD</p>
                    <Select
                      maxTagCount="responsive"
                      mode="multiple"
                      allowClear
                      placeholder="Chọn loại HD"
                      onChange={(v) => applyChangeFilter("LoaiHD", v)}
                      style={{
                        width: "100%",
                      }}
                      options={loaiHD}
                      fieldNames={{ label: "TenLHD", value: "MaLHD" }}
                    />
                  </div>
                )}
                {filterCondition?.Report === 1 && (
                  <div style={{ width: 250 }}>
                    <p className="label">Khách hàng</p>
                    <DebounceSelect
                      mode="multiple"
                      placeholder="Tìm kiểm khách hàng"
                      fetchOptions={fetchUserList}
                      onChange={(newValue) =>
                        applyChangeFilter("KhachHang", newValue)
                      }
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                )}
                {filterCondition?.Report === 2 && (
                  <div style={{ width: 180 }}>
                    <p className="label">Tìm kiếm</p>
                    <Input
                      style={{ width: "180px" }}
                      placeholder="Tìm di động, tên khách hàng"
                      // loading={loading}
                      onChange={(e) => onSearch(e.target.value)}
                      // enterButton
                    />
                  </div>
                )}
              </Flex>
            </Col>
            <Col
              span={3}
              style={{ justifyContent: "flex-end", textAlign: "right" }}
            >
              <Button type="primary" onClick={() => handlePrint()}>
                In
              </Button>
              {/* <Button
                danger
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={() => handleGeneratePdf()}
              >
                Xuất PDF
              </Button> */}
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={24} style={{ marginTop: 80 }}>
        {filterCondition?.Report === 1 ? (
          <Print
            filter={filterCondition}
            ref={componentRef}
            data={data}
            data2={data2}
            sanpham={sanpham}
          />
        ) : filterCondition?.Report === 2 ? (
          <LichSuTieuDungThe
            filter={filterCondition}
            ref={componentRef2}
            data={data}
            data2={data2}
            sanpham={sanpham}
          />
        ) : filterCondition?.Report === 3 ? (
          <LichSuTieuDungThe
            filter={filterCondition}
            ref={componentRef2}
            data={data}
            data2={data2}
            sanpham={sanpham}
          />
        ) : (
          <LichSuCheckIn
            filter={filterCondition}
            ref={componentRef2}
            data={data}
          />
        )}
      </Col>
    </Row>
  );
}
