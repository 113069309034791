import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  DatePicker,
  Flex,
  Modal,
  Table,
  Badge,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import _ from "lodash";
import { TiDelete } from "react-icons/ti";
import { NhanVienService } from "services/NhanVienService";
import { CustomerService } from "services/CustomerService";
import { Editor } from "primereact/editor";
import { CRMService } from "services/CRMService";
import { IoChevronDown } from "react-icons/io5";

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [mailSend, setMailSend] = useState([]);
  const [nhanvien, setNhanvien] = useState([]);
  const [khachhang, setKhachhang] = useState([]);
  const [khachhangAll, setKhachhangAll] = useState([]);
  const [text, setText] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [openMau, setOpenMau] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadmore, setLoadmore] = useState(false);

  const [stateActive, setStateActive] = useState({
    ID: 0,
    Name: "Tất cả",
    Color: "gray",
  });
  const loadCategories = async () => {
    let _res = await CRMService.Categories.getConfigMail();
    setMailSend(_res.data);
    let _resNV = await NhanVienService.getNhanVien();
    setNhanvien(_resNV.data);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      let _res = await CRMService.Email.GetMailByID({ ID: data.ID });
      let v = {
        ..._res.data,
        MailNhan: _res.mailnhan
      }
      loadCategories();
      setText(_res?.data?.NoiDung)
      setData(v);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaKH":
        break;
      case "RemoveMailNhan":
        var kh = _data.MailNhan?.filter((a) => a.MaKH !== v);
        _data.MailNhan = kh;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = await CRMService.Email.AddMail({ ..._data ,NoiDung: text});
    setLoading(false);
    if (_res.status === 2000) {
      setData(_data);
      openNotification(
        "success",
        "topRight",
        `Thêm thành công!`
      );
      // if (close) 
      onClose();
      onAfterSubmit(_data);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const [temp, setTemp] = useState([]);
  const openChooseTemp = async () => {
    let _res = await CRMService.Categories.getTempMail();
    setTemp(_res.data);
    setOpenMau(true);
  };
  const renderChooseTemp = () => {
    return (
      <Drawer
        title="Chọn mẫu"
        width={500}
        onClose={() => setOpenMau(false)}
        open={openMau}
        styles={{
          body: {
            marginTop: 20,
          },
        }}
      >
        <Row gutter={[20]}>
          {temp?.map((item) => {
            return (
              <Col span={24}>
                <Flex
                  justify="space-between"
                  align="center"
                  style={{
                    backgroundColor: "#d9f7be",
                    paddingLeft: "10px",
                    borderRadius: 10,
                    marginBottom: 5,
                  }}
                >
                  <p>{item.TempName}</p>
                  <Button
                    onClick={() => {
                      setText(item.Contents);
                      setOpenMau(false);
                    }}
                    type="primary"
                  >
                    Chọn
                  </Button>
                </Flex>
              </Col>
            );
          })}
        </Row>
      </Drawer>
    );
  };
  const renderChooseMailNhan = () => {
    const columns = [
      {
        title: "Tên khách hàng",
        dataIndex: "TenCongTy",
      },
      {
        title: "Di động",
        dataIndex: "DiDong",
      },
      {
        title: "Email",
        dataIndex: "Email",
      },
    ];
    const onSelectChange = async (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };
    const changeState = (state) => {
      setSelectedRowKeys([]);
      if (state === 0) {
        setKhachhang(khachhangAll);
      } else {
        let _data = khachhangAll.filter((v) => v.MaTT === state);
        setKhachhang(_data);
      }
    };
    const handleOk = () => {
      let _data = _.cloneDeep(data);
      let kh = khachhangAll.filter((v) => selectedRowKeys.includes(v.MaKH) && v.Email);
      _data.MailNhan = kh;
      setData(_data);
      setIsModalOpen(false);
      setSelectedRowKeys([]);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    return (
      <Modal
        width={600}
        title={
          <Input
            placeholder="Tìm kiếm"
            onChange={(e) => filterKhachHang(e.target.value)}
          />
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Đồng ý"
        cancelText="Huỷ"
      >
        {status?.map((item) => {
          return (
            <Badge
              key={item.ID}
              count={item.Total}
              onClick={() => {
                setStateActive(item);
                changeState(item.ID);
              }}
            >
              <div
                style={{
                  border: `1px solid ${"#" + item.Color}`,
                  padding: "6px 15px",
                  cursor: "pointer",
                  marginLeft: 15,
                  borderRadius: 10,
                  background:
                    stateActive.ID === item.ID ? "#" + item.Color : null,
                  color: stateActive.ID === item.ID ? "white" : null,
                }}
              >
                <p>{item.Name}</p>
              </div>
            </Badge>
          );
        })}
        <Table
          loading={loading}
          dataSource={khachhang}
          columns={columns}
          scroll={{
            y: window.innerHeight - 350,
          }}
          rowSelection={rowSelection}
          size="small"
          pagination={false}
          // pagination={{
          //   defaultPageSize: 100,
          //   showSizeChanger: true,
          //   pageSizeOptions: ["100", "500", "5000"],
          // }}
        />
      </Modal>
    );
  };
  const filterKhachHang = async (input) => {
    let _res = await CustomerService.getList({ InputSearch: input });
    let _tt = await CustomerService.getStatus();
    let status =
      [{ ID: 0, Name: "Tất cả", Color: "cccccc" }, ..._tt.data] ?? [];
    status.map((item) => {
      if (item.ID === 0) {
        item.Total = _res.data?.length;
      } else {
        item.Total = _res?.data?.filter((v) => v.MaTT === item.ID)?.length;
      }
    });
    setStatus(status);
    setKhachhangAll(_res.data);
    setKhachhang(_res?.data ?? []);
  };
  const handleMailNhan = async () => {
    setIsModalOpen(true);
    filterKhachHang("");
  };
  return (
    <Drawer
      title="Gửi mail"
      width="100%"
      onClose={onClose}
      open={open}
      styles={{
        body: {
          marginTop: 20,
        },
      }}
      extra={
        <Space>
          <Button onClick={() => openChooseTemp()} type="primary">
            Chọn mẫu
          </Button>
        </Space>
      }
    >
      {contextHolderMS}
      {contextHolder}
      <Row gutter={[20]}>
        <Col span={8}>
          <Row gutter={[24, 8]}>
            <Col span={24} className="gutter-row">
              <p>Mail gửi</p>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaMailGui}
                onChange={(v) => applyChange("MaMailGui", v)}
                options={mailSend}
                fieldNames={{ label: "Email", value: "ID" }}
              />
            </Col>
            <Col span={24} className="gutter-row">
              <p>Nhân viên</p>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaNV}
                placeholder="Chọn nhân viên lập"
                onChange={(v) => applyChange("MaNV", v)}
                options={nhanvien}
                fieldNames={{ label: "HoTen", value: "MaNV" }}
              />
            </Col>
            <Col span={24} className="gutter-row">
              <p>Mail nhận</p>
              <div
                wrap="wrap"
                // gap={[10,0]}
                style={{ borderBottom: "1px solid #d9d9d9", minHeight: "30px" }}
              >
                <Flex justify="space-between" align="center">
                  <Flex wrap="wrap" style={{ width: "95%" }}>
                    {(!data?.MailNhan || data?.MailNhan?.length === 0) && (
                      <p
                        style={{ color: "#bfbfbf" }}
                        onClick={() => handleMailNhan()}
                      >
                        Chọn mail nhận
                      </p>
                    )}
                    {data?.MailNhan?.map((item, index) => {
                      if (index > 10 && loadmore === false) return;
                      return (
                        <div style={{ position: "relative" }}>
                          <p
                            style={{
                              backgroundColor: "#f0f0f0",
                              padding: "2px 5px",
                              borderRadius: 5,
                              marginRight: 5,
                              marginBottom: 5,
                            }}
                          >
                            {item.Email}
                          </p>
                          <p
                            style={{
                              position: "absolute",
                              top: -5,
                              right: 0,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              applyChange("RemoveMailNhan", item.MaKH)
                            }
                          >
                            <TiDelete color="red" size={18} />
                          </p>
                        </div>
                      );
                    })}
                    {data.MailNhan?.length > 10 && (
                      <p
                        style={{
                          backgroundColor: "#f0f0f0",
                          padding: "2px 5px",
                          borderRadius: 5,
                          marginRight: 5,
                          marginBottom: 5,
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => setLoadmore(!loadmore)}
                      >
                        {loadmore ? "Thu gọn" : "Xem thêm"}
                      </p>
                    )}
                  </Flex>
                  <IoChevronDown
                    size={16}
                    color="#d9d9d9"
                    style={{ cursor: "pointer", width: "5%" }}
                    onClick={() => handleMailNhan()}
                  />
                </Flex>
              </div>
            </Col>
            <Col span={12}>
              <p>Ngày lập</p>
              <DatePicker
                placeholder="Nhập ngày cấp"
                allowClear={false}
                format="DD-MM-YYYY"
                style={{ width: "100%" }}
                // defaultValue={}
                value={data?.NgayLap ? dayjs(data?.NgayLap) : dayjs(new Date())}
                locale={vi}
                onChange={(v) => applyChange("NgayLap", v)}
              />
            </Col>
            <Col span={24}>
              <Button
                onClick={() => onSubmit()}
                loading={loading}
                type="primary"
              >
                Lưu
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={16}>
          <Row gutter={[24, 20]}>
            <Col span={24}>
              <p>Tiêu đề</p>
              <Input
                value={data?.TieuDe}
                placeholder="Nhập tiêu đề"
                onChange={(e) => applyChange("TieuDe", e.target.value)}
              />
            </Col>
            <Col span={24}>
              <p>Nội dung</p>
              <Editor
                value={text}
                onTextChange={(e) => setText(e.htmlValue)}
                style={{ minHeight: "320px" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {renderChooseTemp()}
      {renderChooseMailNhan()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
