import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  InputNumber,
  Table,
} from "antd";
import _ from "lodash";
import { BanHangService } from "services/BanHangService";
import { Format_Date } from "layouts/ultis/common";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const [maThe, setMaThe] = useState(0);
  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      setMaThe(data?.MaThe);
      let _res = await BanHangService.BanVeThang.getLichSuThemLuotVe({
        MaThe: data?.MaThe,
      });
      setData(_res?.data ?? []);
    },
    update: async (data) => {
      setOpen(true);
      // setData(data ?? []);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _res = await BanHangService.BanVeThang.addLuotVe({
      ID: maThe,
      ...data,
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Thêm lượt thành công!`);
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderTab1 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Số lượt thêm</p>
          <InputNumber
            placeholder="Số lượt thêm"
            style={{ width: "100%" }}
            value={data?.SoLuotThem}
            onChange={(v) => applyChange("SoLuotThem", v)}
          />
        </Col>
      </Row>
    );
  };
  const columns = [
    {
      title: "Số lượt thêm",
      dataIndex: "SoLuotThem",
    },
    {
      title: "Ngày thêm",
      dataIndex: "NgayThem",
      render: (item) => Format_Date(item),
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
    },
  ];
  return (
    <Drawer
      title="Thêm lượt"
      width={520}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={() => onSubmit(true)} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderTab1()}
      <p style={{ fontWeight: "bold", marginTop: 20 }}>Lịch sử đã thêm</p>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
        }}
        size="small"
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
