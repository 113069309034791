import axiosApi from "./api_helper";
export const OtherService = {
  getDevice: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-device", payload)
      .then((res) => res.data);
  },
  addDevice: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-new-device", payload)
      .then((res) => res.data);
  },
  updateDevice: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-device", payload)
      .then((res) => res.data);
  },
  deleteDevice: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-device", payload)
      .then((res) => res.data);
  },
  getHinhThucTT: async () => {
    return await axiosApi()
      .post("api/get-hinhthuctt")
      .then((res) => res.data);
  },
  getTaiKhoan: async (payload) => {
    return await axiosApi()
      .post("api/nganhang/list", payload)
      .then((res) => res.data);
  },
  getNganHang: async () => {
    return await axiosApi()
      .post("api/nganhang/get")
      .then((res) => res.data);
  },
  addNganHang: async (payload) => {
    return await axiosApi()
      .post("api/nganhang/add", payload)
      .then((res) => res.data);
  },
  deleteNganHang: async (payload) => {
    return await axiosApi()
      .post("api/nganhang/delete", payload)
      .then((res) => res.data);
  },
  print: async (payload) => {
    return await axiosApi()
      .post("api/print", payload)
      .then((res) => res.data);
  },
  getLichSuThaoTac: async (payload) => {
    return await axiosApi()
      .post("api/get-lichsu-thaotac", payload)
      .then((res) => res.data);
  },
  addLichSuThaoTac: async (payload) => {
    return await axiosApi()
      .post("api/add-lichsu-thaotac", payload)
      .then((res) => res.data);
  },
};
