import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Flex,
  Input,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  notification,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { SanPhamService } from "services/SanPhamService";
import "./index.scss";
import FormAddHoSo from "./FormAddHoSo";
import { TiDocumentAdd } from "react-icons/ti";
export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  // const [loaiSP, setLoaiSP] = useState([]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    InputSearch: "",
    MaLoaiSP:",,",
  });
  const refDetail = useRef(null);
  const refDetailHoSo = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  // const loadCategories = async () => {
  //   let _lsp = await SanPhamService.getLSP({ ApDung: true });
  //   setLoaiSP(_lsp?.data ?? []);
  // };
  useEffect(() => {
    // loadCategories();
    // filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await SanPhamService.getListByMaNhom(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const columns = [
    {
      title: "Mã sản phẩm",
      dataIndex: "MaSP",
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "TenSP",
    },
    {
      title: "Loại sản phẩm",
      dataIndex: "TenLSP",
    },
    {
      title: "Nhóm sản phẩm",
      dataIndex: "TenNhom",
    },
    {
      title: "Mã vạch",
      dataIndex: "MaVach",
    },
    {
      title: "Giá mua",
      dataIndex: "GiaMua",
    },
    {
      title: "Giá bán",
      dataIndex: "GiaBan",
    },
    {
      title: "Người tạo",
      dataIndex: "NguoiTao",
    },
    {
      title: "Ngày tạo",
      dataIndex: "NgayTao",
      render: (item) => Format_Date(item),
    },
    {
      title: "",
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex gap={5} justify="center" align="center">
            <Tooltip placement="topRight" title="Thêm hồ sơ">
              <Button
                type="primary"
                ghost
                icon={<TiDocumentAdd />}
                onClick={() => refDetailHoSo.current.add(item.ID)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá sản phẩm"
              description="Bạn có chắc chắn muốn xoá sản phẩm?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button danger ghost icon={<CiTrash />} />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await SanPhamService.deleteSanPham({ ID: value });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== value);
      setData(fil);
      openNotification("success", "topRight", "Xoá sản phẩm thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        filter(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              placeholder="Tìm kiếm tên, mã sản phẩm"
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          right={() => (
            <Button type="primary" onClick={() => refDetail.current.show()}>
              Thêm
            </Button>
            // <FilterModal
            //   filterCondition={filterCondition}
            //   onChangeFilter={onChangeFilter}
            // />
          )}
        />
        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 1500,
          }}
          size="small"
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100", "500", "1000"],
          }}
        />
      </Col>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormAddHoSo ref={refDetailHoSo}  />
      {contextHolder}
    </Row>
  );
}
