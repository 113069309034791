import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  QRCode,
} from "antd";
import _ from "lodash";
import { CongTyService } from "services/CongTyService";
import { FileUpload } from "primereact/fileupload";
import { HomeService } from "services/HomeService";
import { API_UPLOAD_URL } from "services/UploadService";
import { UploadService } from "services/UploadService";

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [isUpdate,setIsUpdate] = useState(false);
   const [logo, setLogo] = useState([]);
   const [logoGD, setLogoGD] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setData(data);
      setIsUpdate(true);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    let _res = null;
    if (logo?.length > 0) {
      var formData = new FormData();
      formData.append("Image", logo[0]);
      formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
      let _resupload = await UploadService.upload(formData);
      if (_resupload.status === 2000) {
        _data.Logo2 = API_UPLOAD_URL + _resupload.data[0];
        setData((pre) => {
          return { ...pre, Logo2: _data.Logo2 };
        });
      }
    }
    if (logoGD?.length > 0) {
      var formData = new FormData();
      formData.append("Image", logoGD[0]);
      formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
      let _resupload = await UploadService.upload(formData);
      if (_resupload.status === 2000) {
        _data.LogoGiaiDau = API_UPLOAD_URL + _resupload.data[0];
        setData((pre) => {
          return { ...pre, LogoGiaiDau: _data.LogoGiaiDau };
        });
      }
    }
    if (isUpdate) {
      _res = await CongTyService.updateCompany(_data);
    } else {
      _res = await CongTyService.addChiNhanh(_data);
      setData((pre) => {
        return { ...pre, ID: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isUpdate ? "Sửa" : "Thêm"} công ty thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };    

  const onBeforeUpload = (v) => {
    setLogo(v.files);
  };
  const onBeforeUploadGD = (v) => {
    setLogoGD(v.files);
  };
  const downloadQRCode = () => {
    const canvas =
      document.getElementById("myqrcode")?.querySelector <
      HTMLCanvasElement >
      "canvas";
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  return (
    <Drawer
      title="Công ty"
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={() => onSubmit()}>Lưu</Button>
          <Button onClick={() => onSubmit(true)} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 20]}>
        <Col span={12}>
          <p>Tên viết tắt</p>
          <Input
            value={data?.TenCTVT}
            placeholder="Nhập tên viết tắt"
            onChange={(e) => applyChange("TenCTVT", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Tên đầy đủ</p>
          <Input
            value={data?.TenCT}
            placeholder="Nhập tên đầy đủ"
            onChange={(e) => applyChange("TenCT", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Địa chỉ</p>
          <Input
            value={data?.DiaChi}
            placeholder="Nhập địa chỉ"
            onChange={(e) => applyChange("DiaChi", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Email</p>
          <Input
            value={data?.Email}
            placeholder="Nhập email"
            onChange={(e) => applyChange("Email", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Diện thoại</p>
          <Input
            value={data?.DienThoai}
            placeholder="Nhập điện thoại"
            onChange={(e) => applyChange("DienThoai", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Website</p>
          <Input
            value={data?.Website}
            placeholder="Nhập website"
            onChange={(e) => applyChange("Website", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>URL API</p>
          <Input
            value={data?.urlAPI}
            placeholder="Nhập url api"
            onChange={(e) => applyChange("urlAPI", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>IP Mạng</p>
          <Input
            value={data?.IP}
            placeholder="Nhập ip mạng"
            onChange={(e) => applyChange("IP", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Nội dung chuyển khoản</p>
          <Input
            value={data?.NoiDungCK}
            placeholder="Nhập nội dung chuyển khoản"
            onChange={(e) => applyChange("NoiDungCK", e.target.value)}
          />
        </Col>

        <Col span={12}>
          <QRCode errorLevel="H" value={data?.TenCTVT} icon={data.Logo2} />
          <Button type="primary" onClick={downloadQRCode}>
            Tải xuống
          </Button>
        </Col>

        <Col span={8}>
          {data.Logo2 && (
            <img
              alt="example"
              style={{ width: "100%", height: 150 }}
              src={data.Logo2}
            />
          )}
          <FileUpload
            mode="basic"
            // name="demo[]"
            // url="/api/upload"
            accept="image/*"
            maxFileSize={1000000}
            onSelect={(e) => onBeforeUpload(e)}
            chooseLabel="Tải logo"
          />
        </Col>
        <Col span={8}>
          {data.LogoGiaiDau && (
            <img
              alt="example gd"
              style={{ width: "100%", height: 150 }}
              src={data.LogoGiaiDau}
            />
          )}
          <FileUpload
            // style={{ maxWidth: "100px", width: 100 }}
            mode="basic"
            // name="demo[]"
            // url="/api/upload"
            accept="image/*"
            maxFileSize={1000000}
            onSelect={(e) => onBeforeUploadGD(e)}
            chooseLabel="Tải bg giải đấu"
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
