import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Button, Col, DatePicker, Popover, Row, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { BanHangService } from "services/BanHangService";
import { NhanVienService } from "services/NhanVienService";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import { PermissionService } from "services/PermissionService";
import { CongTyService } from "services/CongTyService";
import ChiNhanhSelect from "components/ChiNhanhSelect";

const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter } = props;
  const [quay, setQuay] = useState([]);
  const [nhanvien, setNhanvien] = useState([]);
  // const [congty, setCongty] = useState([]);
// const [per, setPer] = useState(0);
  // const refPer = useRef(null);

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "QuayBan":
      case "NhanVien":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TrangThai":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : null;
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      case "MaCT":
        _filter[p] = v ? v : 0;
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(() => {
    BanHangService.getQuayBan().then((res) => setQuay(res.data ?? []));
    NhanVienService.getNhanVien().then((res) => setNhanvien(res.data ?? []));
    // PermissionService.checkQuyen({ FormID: 272 }).then((res) => {
    //   refPer.current = res.data;
    //   if (res.data == 1) {
    //     CongTyService.getCongTy().then((_ct) => {
    //       setCongty(_ct.data);
    //     });
    //   }
    // });
  }, []);
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      <ChiNhanhSelect FormID={272} onChange={(v) => applyChangeFilter("MaCT", v)} />
      {/* {refPer.current === 1 && (
        <div className="box-search">
          <p className="label">Chi nhánh</p>
          <Select
            // mode="multiple"
            allowClear
            placeholder="Chọn chi nhánh"
            onChange={(v) => applyChangeFilter("MaCT", v)}
            style={{
              width: "100%",
            }}
            options={congty}
            fieldNames={{ label: "TenCTVT", value: "ID" }}
          />
        </div>
      )} */}

      <div className="box-search">
        <p className="label">Quầy bán</p>
        <Select
          mode="multiple"
          placeholder="Chọn quầy bán"
          onChange={(v) => applyChangeFilter("QuayBan", v)}
          style={{
            width: "100%",
          }}
          options={quay}
          fieldNames={{ label: "TenQuay", value: "ID" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Nhân viên</p>
        <Select
          mode="multiple"
          placeholder="Chọn nhân viên"
          onChange={(v) => applyChangeFilter("NhanVien", v)}
          style={{
            width: "100%",
          }}
          options={nhanvien}
          fieldNames={{ label: "HoTen", value: "MaNV" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Trạng thái</p>
        <Select
          mode="multiple"
          placeholder="Chọn trạng thái"
          onChange={(v) => applyChangeFilter("TrangThai", v)}
          style={{
            width: "100%",
          }}
          options={[
            { ID: "NEW", Name: "Mới" },
            { ID: "ACCEPT", Name: "Đã thanh toán" },
            { ID: "CANCEL", Name: "Đã huỷ" },
          ]}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
