import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  message,
  Select,
  DatePicker,
  ConfigProvider,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DateTime } from "luxon";
import vi from "dayjs/locale/vi";
import viVN from "antd/es/locale/vi_VN";
import { BanHangService } from "services/BanHangService";
import { CardService } from "services/CardService";
import { ConvertExcelDateToJSDate } from "layouts/ultis/common";

const FormAdd = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = { TenKH: null };
  const [errors, setErrors] = useState(defaultErrors);
  const [sheetExcel, setSheetExcel] = useState();
  const [workbook, setWorkbook] = useState(null);
  const [isDate, setIsDate] = useState(false);
  const [dataNN, setDataNN] = useState({
    // NgayNhap: DateTime.now().toFormat("yyyy-MM-dd"),
    NgayNhap: null,
  });
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const columns = [
    {
      title: "Mã khách hàng",
      dataIndex: "MaKhachHang",
      key: "MaKhachHang",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenKhachHang",
      key: "TenKhachHang",
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      key: "NgaySinh",
    },
    {
      title: "Số CCCD",
      dataIndex: "SoCCCD",
      key: "SoCCCD",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Di Động",
      dataIndex: "DiDong",
      key: "DiDong",
    },
    {
      title: "Địa Chỉ",
      dataIndex: "DiaChi",
      key: "DiaChi",
      width: 200,
    },
    {
      title: "Loại thẻ",
      dataIndex: "LoaiThe",
      key: "LoaiThe",
    },
    {
      title: "Số thẻ",
      dataIndex: "SoThe",
      key: "SoThe",
    },
    {
      title: "Mã vạch (Card Number)",
      dataIndex: "MaVach",
      key: "MaVach",
    },

    {
      title: "Ngày cấp",
      dataIndex: "NgayCap",
      key: "NgayCap",
    },
    {
      title: "Ngày Hiệu lực",
      dataIndex: "NgayHL",
      key: "NgayHL",
    },
    {
      title: " Ngày hết hạn",
      dataIndex: "NgayHH",
      key: "NgayHH",
    },
    {
      title: "Ghi chú	",
      dataIndex: "GhiChu",
      key: "GhiChu",
    },
  ];
  const onClose = () => {
    setOpen(false);
    setData([]);
    setSheetExcel();
    setCol(columns);
  };

  const [col, setCol] = useState(columns);
  const sampleData = [
    {
      MaKhachHang: "AAA123",
      TenKhachHang: "Nguyen Van AAA1",
      NgaySinh: "1990-01-01",
      SoCCCD: "123456789012",
      Email: "nguyenvana@example.com",
      DiDong: "0987654321",
      DiaChi: "123 Đường ABC, Phường XYZ, Thành phố HCM",
      LoaiThe: "Thẻ VIP",
      SoThe: "9876543210",
      MaVach: "ABC123456AAA",
      NgayCap: "2024-01-01",
      NgayHL: "2024-02-01",
      NgayHH: "2025-01-01",
      GhiChu: "Khách hàng VIP",
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // const exportToCSV = () => {
  //   const ws = XLSX.utils.json_to_sheet(sampleData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const dataEX = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(dataEX, "File mẫu " + fileExtension);
  // };
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);

    // Define the styles for the header cells
    const headerCellStyle = {
      font: { bold: true, color: { rgb: "FF0000" } }, // Red text
      fill: {
        fgColor: { rgb: "FFFF00" }, // Yellow background
      },
    };
    ws["A1"].s = headerCellStyle;
    ws["B1"].s = headerCellStyle;
    ws["C1"].s = headerCellStyle;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "File mẫu bán vé tháng" + fileExtension);
  };
  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
          MaKhachHang: item[0]?.toString() ?? null,
          TenKhachHang: item[1]?.toString() ?? null,
          NgaySinh:
            typeof item[2] === "number"
              ? ConvertExcelDateToJSDate(item[2])
              : item[2],
          SoCCCD: item[3]?.toString() ?? null,
          Email: item[4]?.toString() ?? null,
          DiDong: item[5]?.toString() ?? null,
          DiaChi: item[6]?.toString() ?? null,
          LoaiThe: item[7]?.toString() ?? null,
          SoThe: item[8]?.toString() ?? null,
          MaVach: item[9]?.toString() ?? null,
          NgayCap:
            typeof item[10] === "number"
              ? ConvertExcelDateToJSDate(item[10])
              : item[10],
          NgayHL:
            typeof item[11] === "number"
              ? ConvertExcelDateToJSDate(item[11])
              : item[11],
          NgayHH:
            typeof item[12] === "number"
              ? ConvertExcelDateToJSDate(item[12])
              : item[12],
          GhiChu: item[13]?.toString() ?? null,
        });
      }
    });
    setData(res);
  };
  const onSubmit = async (close) => {
    setLoading(true);

    let _data = _.cloneDeep(data);
    dayjs.extend(customParseFormat);

    const formatDate = (dateString) => {
      if (dayjs(dateString, "DD/MM/YYYY", true).isValid()) {
        return dayjs(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      } else if (dayjs(dateString, "YYYY/MM/DD", true).isValid()) {
        return dayjs(dateString, "YYYY/MM/DD").format("YYYY-MM-DD");
      } else if (dayjs(dateString, "DD-MM-YYYY", true).isValid()) {
        return dayjs(dateString, "DD-MM-YYYY").format("YYYY-MM-DD");
      } else {
        return dateString;
      }
    };
    const filterData = _data.map((item) => {
      return {
        ...item,
        NgaySinh: formatDate(item.NgaySinh),
        NgayHH: formatDate(item.NgayHH),
        NgayCap: formatDate(item.NgayCap),
        NgayHL: formatDate(item.NgayHL),
      };
    });

    let _res = await CardService.import({
      List: filterData,
    });
    setLoading(false);

    if (_res?.status !== 2000) {
      openNotification("error", "topRight", _res?.message);
    } else if (_res?.status === 2000 && _res?.data.length === 0) {
      openNotification("success", "topRight", "Thao tác thành công");
      setIsUpdate(true);
      onClose();
    } else {
      //  openNotification("error", "topRight", "Thao tác thành công");
      const pushErrorData = _data.map((item) => {
        let updatedItem = { ...item };
        _res?.data?.forEach((i) => {
          if (item.MaKhachHang === i.maKhachHang) {
            updatedItem = { ...item, error: i.error };
          }
        });
        return updatedItem;
      });

      setData(pushErrorData.filter((item) => item.error));
      setCol([
        {
          title: "Error",
          dataIndex: "error",
          key: "error",
          width: 180,
          render: (item) => <p style={{ color: "red" }}>{item}</p>,
        },
        ...columns,
      ]);
    }
    onAfterSubmit();
  };
  const handleSheet = (value) => {
    const wsname = workbook.SheetNames[value];
    const ws = workbook.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    loadDataImport(data);
    setCol(columns);
  };
  const onChangeDate = (date, dateString) => {
    setDataNN({ NgayNhap: dateString });
    setIsDate(true);
  };
  return (
    <Drawer
      title="Excel"
      width={1160}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {sheetExcel && (
            <Select
              placeholder="Chọn Sheet"
              style={{
                width: 115,
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "0 2px 0 10px",
              }}
              onChange={handleSheet}
              options={sheetExcel}
            />
          )}
          {/* <ConfigProvider locale={viVN}>
            <DatePicker
              style={{
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "5px",
              }}
              format="DD-MM-YYYY"
              placeholder="Chọn ngày TT"
              // value={dataTT?.NgayTT}
              onChange={onChangeDate}
              locale={vi}
            />
          </ConfigProvider> */}
          <Upload
            accept=" .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              setWorkbook(null);
              setSheetExcel(null);
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const sheets = wb.SheetNames.map((item, index) => ({
                  value: index,
                  label: item,
                }));
                if (sheets.length === 1) {
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                  loadDataImport(data);
                  setCol(columns);
                } else {
                  setWorkbook(wb);
                  setSheetExcel(sheets);
                  setData();
                }
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button onClick={() => exportToCSV()}>Tải file mẫu</Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        style={{ margin: 0, marginTop: "20px" }}
        dataSource={data}
        columns={col}
        scroll={{
          y: window.innerHeight - 250,
          x: 2000,
        }}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
