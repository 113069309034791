import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Col, DatePicker, Input, Popover, Row, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { FaFilter } from "react-icons/fa6";
import ChiNhanhSelect from "components/ChiNhanhSelect";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
const FilterModal = ({ ...props }) => {
  const { companies, filterCondition, onChangeFilter } = props;
  const [visible, setVisible] = useState(false);
  const handleOpenChange = (newOpen) => {
    setVisible(newOpen);
  };
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "ListMaCT":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      case "MaCT":
        _filter[p] = v ? v : 0;
        break;
    }
    onChangeFilter(_filter);
  };
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      <ChiNhanhSelect
        FormID={119}
        onChange={(v) => applyChangeFilter("MaCT", v)}
      />
    </div>
  );
};
export default FilterModal;
