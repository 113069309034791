import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  DatePicker,
  Tooltip,
  Flex,
  Popconfirm,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import _ from "lodash";
import DebounceSelect from "components/DebounceSelect";
import BuyinToPrint from "components/BuyinToPrint";
import { useReactToPrint } from "react-to-print";
import { CardService } from "services/CardService";
import { NhanVienService } from "services/NhanVienService";
import { CustomerService } from "services/CustomerService";
import { TaiLieuService } from "services/TaiLieuService";
import FormAddLoaiTL from "./FormAddLoaiTL";
import { IoIosAdd } from "react-icons/io";
import { MdDelete, MdOutlineEdit } from "react-icons/md";

const { TextArea } = Input;
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [quyDanh, setQuyDanh] = useState([]);
  const [status, setStatus] = useState([]);
  const [nguon, setNguon] = useState([]);
  const [customerGroup, setCustomerGroup] = useState([]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [lydo, setLydo] = useState([]);
  const refLoaiTL = useRef(null);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const [loai, setLoai] = useState([]);

  const loadCategories = async () => {
    let _ld = await TaiLieuService.Loai.getList();
    setLoai(_ld?.data ?? []);
    // setLydo(_ld?.data ?? []);
    // let _qd = await NhanVienService.getQuyDanh();
    // setQuyDanh(_qd?.data ?? []);
    // let _resgr = await CardService.getCustomerGroup();
    // setCustomerGroup(_resgr?.data ?? []);

    // let _tt = await CustomerService.getStatus();
    // setStatus(_tt?.data ?? []);
    // let _n = await CustomerService.getNguon();
    // setNguon(_n?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    let _data = _.cloneDeep(data);
    if (!_data.NgayCap) {
      delete _data.NgayCap;
    }
    let _res = await TaiLieuService.add(_data);
    setLoading(false);
    if (_res.status === 2000) {
      setData(_data);
      openNotification(
        "success",
        "topRight",
        `${_data.MaKH ? "Sửa" : "Thêm"} nhân viên thành công!`
      );
      if (close) onClose();
      onAfterSubmit(_data);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const removeLoaiTaiLieu = async () => {
    let _data = _.cloneDeep(data);
    let _ltl = loai.find((d) => d.ID === _data.MaLTL);
    let _res = await TaiLieuService.Loai.add({ ..._ltl,Status: false });
    if (_res.status === 2000) {
      let _loai = _.cloneDeep(loai);
      let fil = _loai.filter((v) => v.ID !== _data.MaLTL);
      setLoai(fil);
      _data.MaLTL = null;
      setData(_data);
      openNotification("success", "topRight", "Xoá thành công!");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onAfterSubmitAddLTL = (v) => {
    let _ltl = _.cloneDeep(loai);
    if (v.isEdit) {
      let index = _ltl.findIndex((d) => d.ID === v.ID);
      _ltl[index] = v;
    } else {
      _ltl.push(v);
    }
    setLoai(_ltl);
    let _data = _.cloneDeep(data);
    _data.MaLTL = v.ID;
    setData(_data);
  };

  return (
    <Drawer
      title="Tài liệu"
      width={520}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          {/* <Button onClick={() => onSubmit()}>Lưu</Button> */}
          <Button onClick={() => onSubmit(true)} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolderMS}
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={24} className="gutter-row">
          <p>Tên tài liệu</p>
          <Input
            value={data?.Name}
            placeholder="Nhập tên tài liệu"
            onChange={(e) => applyChange("Name", e.target.value)}
          />
        </Col>
        <Col span={24} className="gutter-row">
          <p>Ghi chú</p>
          <TextArea
            value={data?.Note}
            placeholder="Nhập ghi chú"
            onChange={(e) => applyChange("Note", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Loại tài liệu</p>
          <Flex align="center">
            <Select
              style={{
                width: "100%",
              }}
              value={data?.MaLTL}
              placeholder="Chọn loại tài liệu"
              onChange={(v) => applyChange("MaLTL", v)}
              options={loai}
              fieldNames={{ label: "Name", value: "ID" }}
            />
            <Tooltip title="Thêm">
              <Button
                onClick={() => refLoaiTL.current.show()}
                type="text"
                icon={<IoIosAdd size={25} />}
              />
            </Tooltip>

            {data?.MaLTL && (
              <>
                <Tooltip title="Sửa">
                  <Button
                    onClick={() =>
                      refLoaiTL.current.update(
                        loai?.find((d) => d.ID === data?.MaLTL)
                      )
                    }
                    type="text"
                    icon={<MdOutlineEdit size={18} />}
                  />
                </Tooltip>
                <Tooltip title="Xoá">
                  <Popconfirm
                    title="Xoá"
                    description="Bạn có chắc chắn muốn xoá hay không?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => removeLoaiTaiLieu()}
                  >
                    <Button type="text" icon={<MdDelete size={18} />} />
                  </Popconfirm>
                </Tooltip>
              </>
            )}
          </Flex>
        </Col>
      </Row>

      <FormAddLoaiTL ref={refLoaiTL} onAfterSubmit={onAfterSubmitAddLTL} />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
