import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Popconfirm,
  Table,
  Tabs,
  Tooltip,
  notification,
  Flex,
  Tag,
} from "antd";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import TabPane from "antd/es/tabs/TabPane";
import { CardService } from "services/CardService";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { SanPhamService } from "services/SanPhamService";
import { Format_Datetime } from "layouts/ultis/common";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const refDetail = useRef(null);
  const [activeTab, setActiveTab] = useState("0");
  const [loaithe, setLoaithe] = useState([]);
  const [loaisp, setLoaisp] = useState([]);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const tabs = [
    {
      title: "Loại thẻ",
      key: "0",
    },
    {
      title: "Chính sách nạp tiền",
      key: "1",
    },
    {
      title: "Cài đặt ưu đãi thẻ",
      key: "2",
    },
    {
      title: "Kho thẻ",
      key: "3",
    },
  ];

  useEffect(() => {
    filter(activeTab);
  }, []);
  const loadCateTab3 = async () => {
    let _res = await CardService.getType();
    if (_res.data) setLoaithe(_res.data);
    let _lsp = await SanPhamService.getLSP();
    setLoaisp(_lsp?.data ?? []);
  };
  const filter = async (tab) => {
    setLoading(true);
    if (tab === "0") {
      let _res = await CardService.getType();
      if (_res.data) setData(_res.data);
    } else if (tab === "1") {
      let _res = await CardService.getChinhSach();
      if (_res.data) setData(_res.data);
    } else if (tab === "2") {
      let _res = await CardService.getUuDai();
      if (_res.data) setData(_res.data);
    } else if (tab === "3") {
      let _res = await CardService.getKhoThe({
        TuNgay: new Date(new Date().setDate(new Date().getDate() - 5)),
        DenNgay: new Date(),
        InputSearch: "",
      });
      if (_res.data) setData(_res.data);
    }
    setLoading(false);
  };
  const onAfterSubmit = () => {
    filter(activeTab);
  };
  const columns = [
    {
      title: "Tên loại thẻ",
      dataIndex: "TenLT",
    },
    {
      title: "Mức điểm đạt",
      dataIndex: "MucDat",
    },

    {
      title: "Điểm duy trì",
      dataIndex: "DuyTri",
    },

    {
      title: "Số tiền / điềm",
      dataIndex: "DiemTien",
    },
    {
      title: "Tỷ giá điểm",
      dataIndex: "TyGiaDiem",
    },
    {
      title: "Tích điểm sản phẩm",
      dataIndex: "isCheckSP",
      render: (item) => <Checkbox checked={item} />,
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex justify="center" gap={10}>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá thẻ"
              description="Bạn có chắc chắn muốn xoá thẻ?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button danger ghost icon={<CiTrash />} />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "Từ ngày",
      dataIndex: "TuNgay",
      render: (item) => Format_Date(item),
    },
    {
      title: "Đến ngày",
      dataIndex: "DenNgay",
      render: (item) => Format_Date(item),
    },
    {
      title: "Nạp tiền từ",
      dataIndex: "NapTienTu",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Nạp tiền đến",
      dataIndex: "NapTienDen",
      render: (item) => Format_Currency(item),
    },

    {
      title: "Tỷ lệ CK",
      dataIndex: "TyLeCK",
    },

    {
      title: "Tỷ lệ cộng thêm",
      dataIndex: "TyLeCongThem",
    },
    {
      title: "Áp dụng",
      dataIndex: "ApDung",
      render: (item) => <Checkbox checked={item} />,
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá thẻ"
              description="Bạn có chắc chắn muốn xoá thẻ?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns3 = [
    {
      title: "Tên chương trình",
      dataIndex: "TenCT",
    },
    {
      title: "Từ ngày",
      dataIndex: "TuNgay",
      render: (item) => Format_Date(item),
    },
    {
      title: "Đến ngày",
      dataIndex: "DenNgay",
      render: (item) => Format_Date(item),
    },
    {
      title: "Loại ưu đãi",
      dataIndex: "LoaiUuDai",
      render: (item) => (item === "CK" ? "Chiết khấu" : "Voucher"),
    },
    {
      title: "Loại thẻ",
      dataIndex: "MaLoaiThe",
      render: (item) => loaithe?.find((v) => v.ID === item)?.TenLT,
    },
    {
      title: "Loại sản phẩm",
      dataIndex: "MaLoaiSP",
      render: (item) => loaisp?.find((v) => v.MaLSP === item)?.TenLSP,
    },
    {
      title: "Tỷ lệ CK",
      dataIndex: "TyLeCK",
    },

    {
      title: "Tiền voucher",
      dataIndex: "TienVoucher",
    },
    {
      title: "Áp dụng",
      dataIndex: "ApDung",
      render: (item) => <Checkbox checked={item} />,
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá thẻ"
              description="Bạn có chắc chắn muốn xoá thẻ?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
   const columns4 = [
     {
       title: "CardNo",
       dataIndex: "CardNo",
     },
     {
       title: "CardNumber",
       dataIndex: "CardNumber",
     },
     {
      title: "Trạng thái",
      dataIndex: "MaTheSD",
      render: (item) =>
        item ? (
          <Tag color="red">Đã sử dụng</Tag>
        ) : (
          <Tag color="green">Chưa sử dụng</Tag>
        ),
    },
     {
       title: "Ngày tạo",
       dataIndex: "NgayTao",
       render: (item) => Format_Datetime(item),
     },
   ];
  const remove = async (value) => {
    let _res = null;
    if (activeTab === "0") {
      _res = await CardService.deleteCardType({ ID: Number(value) });
    } else if (activeTab === "1") {
      _res = await CardService.deleteChinhSach({ ID: Number(value) });
    } else if (activeTab === "2") {
      _res = await CardService.deleteUuDai({ ID: Number(value) });
    }
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
    filter(newActiveKey);
    if (newActiveKey === "2") loadCateTab3();
  };
  return (
    <Box>
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              <Table
                loading={loading}
                dataSource={data}
                columns={
                  activeTab === "0"
                    ? columns
                    : activeTab === "1"
                    ? columns2
                    : activeTab === "2" ? columns3 : columns4
                }
                scroll={{
                  y: window.innerHeight - 350,
                  // x: 2500
                }}
                size="small"
              />
            </TabPane>
          );
        })}
      </Tabs>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Box>
  );
}
