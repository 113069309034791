import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Input,
  Popover,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import routes from "routes.js";
import { SanPhamService } from "services/SanPhamService";
import ActiveRoute from "components/ActiveRoute";

const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter } = props;
  const [nhom, setNhom] = useState([]);
  const [loaiSP, setLoaiSP] = useState([]);
  useEffect(() => {
    SanPhamService.getNhom().then((res) => setNhom(res.data ?? []));
    SanPhamService.getLSP({ ApDung: true }).then((res) =>
      setLoaiSP(res.data ?? [])
    );
  }, []);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaLoaiSP":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };

  return (
    <div>
      <ActiveRoute />

      <div className="box-search">
        <p className="label">Nhóm sản phẩm</p>
        <Radio.Group
          onChange={(e) => applyChangeFilter("MaNhom", e.target.value)}
        >
          <Flex vertical gap={5}>
            <Radio>Tất cả</Radio>
            {nhom.map((item) => {
              return <Radio value={item.ID}>{item.TenNhom}</Radio>;
            })}
          </Flex>
        </Radio.Group>
      </div>
      <div className="box-search">
        <p className="label">Loại sản phẩm</p>
        <Select
          mode="multiple"
          placeholder="Chọn loại sản phẩm"
          onChange={(v) => applyChangeFilter("MaLoaiSP", v)}
          style={{
            width: "100%",
          }}
          options={loaiSP}
          fieldNames={{ label: "TenLSP", value: "MaLSP" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
