import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Table,
  notification,
  Row,
  Col,
  Tooltip,
  Modal,
  Input,
  Tag,
  InputNumber,
  Select,
  Flex,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import "./scss/index.scss";
import Toolbar from "components/Toolbar";
import { BanHangService } from "services/BanHangService";
import FormDetail from "./FormDetail";
import { IoEyeSharp } from "react-icons/io5";
import { GiPayMoney } from "react-icons/gi";
import { CiEdit } from "react-icons/ci";
import { OtherService } from "services/OtherService";
import { SoQuyService } from "services/SoQuyService";
import FormDetailEdit from "./FormDetailEdit";
import ExcelJS from "exceljs";
import { NhanVienService } from "services/NhanVienService";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    FormID: 409,
    MaCT: 0,
    TuNgay: new Date(),
    DenNgay: new Date(),
    QuayBan: ",,",
    NhanVien: ",,",
    MaNhom: 1,
    MaLHD: 2,
    InputSearch: "",
  });
  const [hinhthuc, setHinhThuc] = useState([]);

  const refAdd = useRef(null);
  const [lydo, setLydo] = useState("");
  const [open, setOpen] = useState(false);
  const refDetail = useRef(null);
  const refDetailEdit = useRef(null);
  const refID = useRef(null);
  const [dataPay, setDataPay] = useState({});
  const [action, setAction] = useState([]);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadcategories = async () => {
    let _ht = await OtherService.getHinhThucTT();
    setHinhThuc(_ht.data ?? []);
    NhanVienService.getAction({ FormID: 409 }).then((res) =>
      setAction(res?.data)
    );
  };
  useEffect(() => {
    loadcategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await BanHangService.getList(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const state = {
    NEW: "Mới",
    PAYMENT: "Đã thanh toán",
    CANCEL: "Đã huỷ",
    REJECT: "Từ chối",
    ACCEPT: "Đã duyệt",
    RETURN: "Hoàn trả",
  };
  const stateColor = {
    NEW: "blue",
    PAYMENT: "cyan",
    CANCEL: "orange",
    REJECT: "red",
    ACCEPT: "green",
    RETURN: "#87d068",
  };
  const columns = [
    {
      title: "Ngày bán hàng",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
    },
    {
      title: "Ngày sử dụng",
      dataIndex: "NgayBH",
      render: (item) => Format_Date(item),
    },
    {
      title: "Số hoá đơn",
      dataIndex: "SoBH",
    },

    {
      title: "Lý do",
      dataIndex: "GhiChuTraLai",
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
    },
    {
      title: "Quầy vé",
      dataIndex: "TenQuay",
    },
    {
      title: "Khách hàng",
      dataIndex: "TenCongTy",
    },
    {
      title: "Hình thức TT",
      dataIndex: "HinhThucTT",
      render: (item) => hinhthuc?.find((v) => v.ID === item)?.TenHinhThuc,
    },
    {
      title: "Tổng tiền",
      dataIndex: "ThanhTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền CK",
      dataIndex: "TienCK",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tổng sau CK",
      dataIndex: "KhachTra",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Đã thanh toán",
      dataIndex: "ThanhToan",
      render: (item) => Format_Currency(item ?? 0),
    },

    {
      title: "Số lượng",
      dataIndex: "SoLuongThe",
      render: (item) => item ?? 0,
    },
    {
      title: "Vé đã SD",
      dataIndex: "DaSuDung",
      render: (item) => item ?? 0,
    },
    {
      title: "Vé còn lại",
      // dataIndex: "ConLai",
      render: (item) => item.SoLuongThe - (item.DaSuDung ?? 0),
    },
    {
      title: "Còn nợ",
      fixed: "right",
      render: (item) => Format_Currency(item.KhachTra - (item.ThanhToan ?? 0)),
    },
    {
      title: "Trạng thái",
      fixed: "right",
      dataIndex: "State",
      width: "100px",
      render: (item) => <Tag color={stateColor[item]}>{state[item]}</Tag>,
    },
    {
      title: "",
      fixed: "right",
      width: "130px",
      render: (item) => {
        return (
          <div>
            {item.KhachTra - item.ThanhToan > 0 && (
              <Tooltip placement="topRight" title="Thanh toán">
                <Button
                  style={{ marginLeft: 5 }}
                  type="primary"
                  danger
                  icon={<GiPayMoney />}
                  onClick={() => {
                    setOpen(true);
                    setDataPay(item);
                    // OtherService.getHinhThucTT().then((res) => {
                    //   setHinhThuc(res.data ?? []);
                    // });
                  }}
                />
              </Tooltip>
            )}
            {item.State === "NEW" && (
              <Tooltip placement="topRight" title="Bỏ / thêm vé">
                <Button
                  style={{ marginLeft: 5 }}
                  // type="primary"
                  danger
                  ghost
                  icon={<CiEdit />}
                  onClick={() => refDetailEdit.current.show(item)}
                />
              </Tooltip>
            )}
            {item.KhachTra - (item.ThanhToan ?? 0) <= 0 && (
              <Tooltip placement="topRight" title="Chi tiết">
                <Button
                  style={{ marginLeft: 5 }}
                  type="primary"
                  ghost
                  icon={<IoEyeSharp />}
                  onClick={() => refDetail.current.show(item)}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];
  const renderModal = () => {
    const hide = () => {
      setOpen(false);
      setDataPay({});
    };
    const applyChangeKH = (p, v) => {
      let _data = _.cloneDeep(dataPay);
      _data[p] = v;
      setDataPay(_data);
    };
    const onSubmit = async () => {
      setLoading(true);
      let _res = await SoQuyService.PhieuThu.addPhieuThu({
        NgayThu: new Date(),
        LyDo: "Thu tiền bán vé kinh doanh",
        MaBH: dataPay.ID,
        MaKH: dataPay.MaKH,
        MaHinhThucTT: dataPay.MaHinhThucTT,
        ThanhTien: dataPay.ConLai,
      });
      OtherService.addLichSuThaoTac({
        Modules: "BanVe",
        SoGiaoDich: dataPay?.SoBH,
        NoiDung: "Thanh toán số còn nợ vé kinh doanh",
        LinkID: dataPay?.ID,
      });
      if (_res.status === 2000) {
        filter(filterCondition);
        openNotification("success", "topRight", `Thanh toán thành công!`);
        hide();
      } else {
        openNotification("error", "topRight", _res?.message);
      }
      setLoading(false);
    };
    return (
      <Modal
        title={
          "Thanh toán: " +
          Format_Currency(dataPay?.KhachTra - (dataPay?.ThanhToan ?? 0))
        }
        open={open}
        width={300}
        onOk={() => onSubmit()}
        onCancel={hide}
        okText="Lưu"
        cancelText="Huỷ"
      >
        <Row gutter={[0, 10]} style={{ margin: "20px 0px" }}>
          <Col span={24}>
            {/* <p style={{ fontWeight: "bold" }}>
              Còn nợ:{" "}
              {Format_Currency(dataPay?.KhachTra - (dataPay?.ThanhToan ?? 0))}
            </p> */}
            <p>Nhập số tiền thanh toán</p>
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              max={dataPay?.KhachTra - (dataPay?.ThanhToan ?? 0)}
              value={dataPay?.ConLai}
              placeholder="Nhập tiền khách trả"
              style={{ width: "100%" }}
              onChange={(v) => applyChangeKH("ConLai", v)}
            />
          </Col>
          <Col span={24}>
            <p>Hình thức thanh toán</p>
            <Select
              style={{
                width: "100%",
              }}
              value={dataPay.MaHinhThucTT}
              placeholder="Chọn hình thức"
              onChange={(v) => applyChangeKH("MaHinhThucTT", v)}
              options={hinhthuc}
              fieldNames={{ label: "TenHinhThuc", value: "ID" }}
            />
          </Col>
        </Row>
      </Modal>
    );
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        filter(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const exportExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("BaoCao");
    sheet.properties.defaultRowHeight = 20;
    sheet.columns = [
      {
        header: "Ngày bán hàng",
        key: "ngaybh",
        width: 15,
        font: {
          bold: true,
          size: 24,
        },
      },
      {
        header: "Ngày sử dụng",
        key: "ngaysd",
        width: 15,
        font: {
          bold: true,
          size: 24,
        },
      },
      {
        header: "Số hoá đơn",
        key: "sohd",
        width: 20,
      },
      {
        header: "Nhân viên",
        key: "nhanvien",
        width: 10,
      },
      {
        header: "Quầy vé",
        key: "quayve",
        width: 10,
      },
      {
        header: "Khách hàng",
        key: "khachhang",
        width: 15,
      },
      {
        header: "Hình thức TT",
        key: "hinhthuctt",
        width: 8,
      },
      {
        header: "Tổng tiền",
        key: "tongtien",
        width: 20,
        numFmt: "#.##0",
      },
      {
        header: "Tiền CK",
        key: "tienck",
        width: 20,
      },
      {
        header: "Tổng sau CK",
        key: "tongsauck",
        width: 20,
        numFmt: "#.##0",
      },

      {
        header: "Đã thanh toán",
        key: "thanhtoan",
        width: 20,
      },
      {
        header: "Còn nợ",
        key: "conno",
        width: 20,
      },

      {
        header: "Số lượng",
        key: "soluong",
        width: 10,
        numFmt: "#.##0",
      },
      {
        header: "Đã sử dụng",
        key: "dasudung",
        width: 10,
      },
      {
        header: "Còn lại",
        key: "conlai",
        width: 10,
      },
      {
        header: "Trạng thái",
        key: "trangthai",
        width: 10,
      },
    ];
    data?.map((item) => {
      sheet.addRow({
        ngaybh: Format_Date(item.NgayNhap),
        ngaysd: Format_Date(item.NgayBH),
        sohd: item.SoBH,
        nhanvien: item.HoTen,
        quayve: item.TenQuay,
        khachhang: item.TenCongTy,
        hinhthuctt: hinhthuc?.find((v) => v.ID === item.HinhThucTT)
          ?.TenHinhThuc,
        tongtien: item.ThanhTien,
        tienck: item.TienCK,
        tongsauck: item.KhachTra,
        thanhtoan: item.ThanhToan,
        conno: item.KhachTra - (item.ThanhToan ?? 0),
        soluong: item.SoLuongThe,
        dasudung: item.DaSuDung,
        conlai: item.SoLuongThe - item.DaSuDung,
        trangthai: state[item.State],
      });
    });
    sheet.getCell("A1").numFmt = "# ?/?";

    sheet.addRow({
      ngaybh: "Tổng: " + data.length,
      tongtien: _.sumBy(data, (o) => o.ThanhTien),
      tienck: _.sumBy(data, (o) => o.TienCK),
      tongsauck: _.sumBy(data, (o) => o.KhachTra),
      thanhtoan: _.sumBy(data, (o) => o.ThanhToan),
      conno: _.sumBy(data, (o) => o.KhachTra - (o.ThanhToan ?? 0)),
      soluong: _.sumBy(data, (o) => o.SoLuongThe),
      dasudung: _.sumBy(data, (o) => o.DaSuDung),
      conlai: _.sumBy(data, (o) => o.SoLuongThe - (o.DaSuDung ?? 0)),
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "banve_kinhdoanh.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const checkAction = (id) => {
    return action?.some((v) => v.FeatureID === id);
  };
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              placeholder="Tìm kiếm di động, tên khách, số hoá đơn"
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          right={() => (
            <Flex gap={10}>
              { (
                <Button onClick={() => exportExcel()}>Export</Button>
              )}
              {checkAction(1) && (
                <Button type="primary" onClick={() => refAdd.current.show()}>
                  Thêm
                </Button>
              )}
            </Flex>
          )}
        />
        <Table
          style={{
            height: 100,
            // boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            // borderRadius:0
          }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            x: 2000,
          }}
          size="small"
        />
      </Col>
      <FormAdd ref={refAdd} onAfterSubmit={onAfterSubmit} />
      <FormDetail ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormDetailEdit ref={refDetailEdit} onAfterSubmit={onAfterSubmit} />
      {renderModal()}
      {contextHolder}
    </Row>
  );
}
