import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import * as React from "react";
import { NhanVienService } from "services/NhanVienService";
import "./scss/index.scss";
import { Flex } from "antd";
const HoaDonToPrint = (props, ref) => {
  const { data,  dataPrint } = props;
  const [nhanvien, setNhanVien] = React.useState({});
  React.useEffect(() => {
    NhanVienService.getDetail().then((res) => {
      setNhanVien(res.data ?? []);
    });
  }, []);

  return (
    <div
      ref={ref}
      className="print-container"
      style={{ margin: "0", padding: "0" }}
    >
      <div className="page-break" />
      <div style={{ height: "100vh", margin: "0 10px", padding: "0" }}>
        <div style={{ textAlign: "center", padding: "15px 0px" }}>
          <p style={{ fontSize: 13, fontWeight: "bold" }}>{nhanvien?.TenCT}</p>
          <p style={{ fontSize: 12 }}>{nhanvien?.DiaChi}</p>
          <p style={{ fontSize: 12 }}>{nhanvien?.DienThoai}</p>
        </div>
        <div style={{ padding: "10px 0px" }}>
          <p
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            HOÁ ĐƠN BÁN HÀNG
          </p>
          <Flex justify="space-between">
            <p>Ngày: {Format_Date(new Date())}</p>
            <p>{dataPrint?.SoHD}</p>
          </Flex>
          <Flex justify="space-between">
            <p>Thu ngân: {nhanvien?.HoTen}</p>
            <p>Khách hàng: {data?.TenKH}</p>
          </Flex>
          <Flex justify="space-between">
            <p>
              In lúc:{" "}
              {new Date().getHours() < 10
                ? "0" + new Date().getHours()
                : new Date().getHours()}
              :
              {new Date().getMinutes() < 10
                ? "0" + new Date().getMinutes()
                : new Date().getMinutes()}
            </p>
            <p>Quầy: {dataPrint?.TenQuay}</p>
          </Flex>
        </div>
        <table style={{ width: "100%" }}>
          <tr
            style={{
              textAlign: "left",
              borderBottom: "2px solid black",
            }}
          >
            <th style={{ fontSize: 12 }}>Mặt hàng</th>
            <th style={{ fontSize: 12 }}>SL</th>
            <th style={{ fontSize: 12 }}>Đ.Giá</th>
            <th style={{ fontSize: 12 }}>T.Tiền</th>
          </tr>
          {data?.SanPham?.map((item) => {
            return (
              <tr>
                <td style={{ width: "40%", fontSize: 12 }}>{item.TenSP}</td>
                <td style={{ width: "10%", fontSize: 12 }}>{item.SoLuong}</td>
                <td style={{ width: "20%", fontSize: 12 }}>
                  {Format_Currency(item.DonGia)}
                </td>
                <td style={{ width: "20%", fontSize: 12 }}>
                  {Format_Currency(item.DonGia * item.SoLuong - item.TienCK)}
                </td>
              </tr>
            );
          })}
          <tfoot
            style={{
              padding: "10px 0px",
              borderTop: "1px solid gray",
            }}
          >
            <tr>
              <td style={{ fontSize: 12 }}>Hình thức thanh toán</td>
              <td></td>
              <td></td>
              <td style={{ fontSize: 12 }}>{dataPrint?.HinhThucTT}</td>
            </tr>
            <tr>
              <td style={{ fontSize: 12 }}>Tổng cộng</td>
              <td></td>
              <td></td>
              <td style={{ fontSize: 12 }}>
                {Format_Currency(data?.ThanhTien)}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: 12 }}>Chiết khấu</td>
              <td></td>
              <td></td>
              <td style={{ fontSize: 12 }}>{Format_Currency(data?.TienCK)}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold", fontSize: 12 }}>Tổng sau CK</td>
              <td></td>
              <td></td>
              <td style={{ fontWeight: "bold", fontSize: 12 }}>
                {Format_Currency(data?.ThanhTien - data?.TienCK)}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold", fontSize: 12 }}>Khách trả</td>
              <td></td>
              <td></td>
              <td style={{ fontWeight: "bold", fontSize: 12 }}>
                {Format_Currency(data?.ThanhToan)}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold", fontSize: 12 }}>Tiền thừa</td>
              <td></td>
              <td></td>
              <td style={{ fontWeight: "bold", fontSize: 13 }}>
                {Format_Currency(data?.TienThua)}
              </td>
            </tr>
          </tfoot>
        </table>
        <p
          style={{
            marginTop: 20,
            fontStyle: "italic",
            fontSize: 12,
            textAlign: "center",
          }}
        >
          Phiếu này có giá trị xuất hoá đơn đỏ trong ngày
        </p>
        <p
          style={{
            fontStyle: "italic",
            fontSize: 12,
            textAlign: "center",
          }}
        >
          Cảm ơn quý khách. Hẹn gặp lại!
        </p>
      </div>

      {data?.LoaiVeXuat === "QR" &&
        data?.SanPham?.map((item) => {
          let a = [];
          for (let index = 0; index < item.SoLuong; index++) {
            a.push(index);
          }
          return a?.map((item2, index) => {
            return (
              <>
                <div className="page-break" />
                <div style={{ height: "100vh" }}>
                  <div style={{ textAlign: "center", padding: "0px 0px" }}>
                    <p style={{ fontSize: 15, fontWeight: "bold" }}>
                      {nhanvien?.TenCT}
                    </p>
                    <p style={{ fontSize: 10 }}>{nhanvien?.DiaChi}</p>
                    <p style={{ fontSize: 10 }}>{nhanvien?.DienThoai}</p>
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      VÉ / TICKET
                    </p>
                  </div>
                  <div style={{ textAlign: "center", padding: "0px 5px" }}>
                    <p style={{ fontWeight: "bold" }}>{item.TenSP}</p>
                    <Flex justify="space-between">
                      <p>Giá: {Format_Currency(item.DonGia)}</p>
                      <p>Ngày SD: {Format_Date(data?.NgayBH)}</p>
                    </Flex>
                    <Flex justify="space-between">
                      <p>
                        Mã: {item.QRCodes?.length > 0 && item.QRCodes[index]}
                      </p>
                      <p>Ngày HH: {Format_Date(data?.NgayBH)}</p>
                    </Flex>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                    }}
                  >
                    <img
                      src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${
                        item.QRCodes?.length > 0 && item.QRCodes[index]
                      }`}
                    />
                  </div>
                  <p
                    style={{
                      fontStyle: "italic",
                      fontSize: 12,
                      textAlign: "center",
                      paddingTop: 10,
                    }}
                  >
                    Cảm ơn quý khách. Hẹn gặp lại!
                  </p>
                </div>
              </>
            );
          });
        })}
    </div>
  );
};
export default React.forwardRef(HoaDonToPrint);
