import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Table,
  notification,
  Row,
  Col,
  Input,
  Tag,
  Tooltip,
  Flex,
  Popconfirm,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import "./scss/index.scss";
import Toolbar from "components/Toolbar";
import { BanHangService } from "services/BanHangService";
import { OtherService } from "services/OtherService";
import InHoaDon from "./InHoaDon";
import { IoMdPrint } from "react-icons/io";
import { useReactToPrint } from "react-to-print";
import { MdCancel } from "react-icons/md";
import ExcelJS from "exceljs";
import { Format_Datetime } from "layouts/ultis/common";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import FormAddNew from "./FormAddNew";
import { NhanVienService } from "services/NhanVienService";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    FormID: 272,
    TuNgay: new Date(),
    DenNgay: new Date(),
    MaNhom: 2,
    MaCT: 0,
    QuayBan: ",,",
    NhanVien: ",,",
    TrangThai: null,
    InputSearch: "",
  });
  const state = {
    NEW: "Mới",
    ACCEPT: "Đã thanh toán",
    CANCEL: "Đã huỷ",
    REJECT: "Từ chối",
    RETURN: "Đã huỷ",
  };
  const stateColor = {
    NEW: "blue",
    PAYMENT: "cyan",
    CANCEL: "orange",
    REJECT: "red",
    ACCEPT: "green",
    RETURN: "orange",
  };
  const refDetail = useRef(null);
  const refPrintHD = useRef(null);
  const [hinhthuc, setHinhThuc] = useState([]);
  const [action, setAction] = useState([]);

  const loadcategories = async () => {
    let _ht = await OtherService.getHinhThucTT();
    setHinhThuc(_ht.data ?? []);
    NhanVienService.getAction({ FormID: 272 }).then((res) =>
      setAction(res?.data)
    );
  };
  const checkAction = (id) => {
    return action?.some((v) => v.FeatureID === id);
  };
  useEffect(() => {
    loadcategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await BanHangService.getList(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const columns = [
    {
      title: "Ngày bán hàng",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
    },
    {
      title: "Số hoá đơn",
      dataIndex: "SoBH",
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
    },
    {
      title: "Trạng thái",
      dataIndex: "State",
      render: (item) => <Tag color={stateColor[item]}>{state[item]}</Tag>,
    },
    {
      title: "Khách hàng",
      dataIndex: "TenCongTy",
    },
    {
      title: "Tên bàn",
      dataIndex: "TenBan",
    },
    {
      title: "Hình thức TT",
      dataIndex: "HinhThucTT",
      render: (item) => hinhthuc?.find((v) => v.ID === item)?.TenHinhThuc,
    },
    {
      title: "Tổng tiền",
      dataIndex: "ThanhTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền CK",
      dataIndex: "TienCK",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tổng sau CK",
      dataIndex: "KhachTra",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Giờ thanh toán",
      dataIndex: "NgayBH",
      render: (item) => item && Format_Datetime(item),
    },
    // {
    //   title: "Ngày nhập",
    //   dataIndex: "NgayNhap",
    //   render: (item) => Format_Date(item),
    // },
    {
      title: "",
      fixed: "right",
      width: "80px",
      render: (item) => {
        return (
          <Flex justify="center" gap={10}>
            <Tooltip placement="topRight" title="In hoá đơn">
              <Button
                ghost
                type="primary"
                icon={<IoMdPrint />}
                onClick={() => print(item)}
              />
            </Tooltip>
            {checkAction(3) && item.State !== "RETURN" && item.State !== "CANCEL" && (
              <Popconfirm
                title="Huỷ hoá đơn"
                description="Bạn có chắc chắn muốn huỷ hoá đơn?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => handleCancel(item)}
              >
                <Tooltip placement="topRight" title="Huỷ hoá đơn">
                  <Button loading={loading} danger ghost icon={<MdCancel />} />
                </Tooltip>
              </Popconfirm>
            )}
          </Flex>
        );
      },
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const handleCancel = async (value) => {
    setLoading(true);
    let _res = await BanHangService.changeState({
      ID: value.ID,
      State: "RETURN",
      TongTien: value?.KhachTra,
      MaKH: value.MaKH,
      LyDo: "Huỷ hoá đơn bán hàng",
      MaLPC: 4,
      The: [],
    });
    if (_res.status === 2000) {
      filter(filterCondition);
      openNotification("success", "topRight", `Huỷ hoá đơn thành công!`);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };
  const [dataprint, setDataprint] = useState({ SanPham: [] });

  const print = async (value) => {
    let _res = await BanHangService.getBHSanPHam({ MaBH: value.ID });
    // let _ht = await OtherService.getHinhThucTT();
    if (_res.status === 2000) {
      let _db = _.cloneDeep(dataprint);
      _db = value;
      _db.SanPham = _res.data;
      _db.HinhThuc = hinhthuc?.find(
        (v) => v.ID === value.HinhThucTT
      )?.TenHinhThuc;
      //  _ht?.data?.find(
      //   (v) => v.ID === value.HinhThucTT
      // )?.TenHinhThuc;
      setDataprint(_db);
      handlePrintLai();
    }
  };
  const handlePrintLai = useReactToPrint({
    content: () => refPrintHD.current,
  });
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        filter(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const exportExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("BaoCao");
    sheet.properties.defaultRowHeight = 20;
    sheet.columns = [
      {
        header: "Ngày bán hàng",
        key: "ngaybh",
        width: 15,
        font: {
          bold: true,
          size: 24,
        },
      },
      {
        header: "Số hoá đơn",
        key: "sohd",
        width: 20,
      },
      {
        header: "Nhân viên",
        key: "nhanvien",
        width: 10,
      },
      {
        header: "Trạng thái",
        key: "trangthai",
        width: 10,
      },
      {
        header: "Khách hàng",
        key: "khachhang",
        width: 15,
      },

      {
        header: "Bàn bán",
        key: "tenban",
        width: 10,
      },
      {
        header: "Hình thức TT",
        key: "hinhthuctt",
        width: 8,
      },
      {
        header: "Tổng tiền",
        key: "tongtien",
        width: 20,
        numFmt: "#.##0",
      },
      {
        header: "Tiền CK",
        key: "tienck",
        width: 20,
      },
      {
        header: "Tổng sau CK",
        key: "tongsauck",
        width: 20,
      },
    ];
    data?.map((item) => {
      sheet.addRow({
        ngaybh: Format_Date(item.NgayBH),
        sohd: item.SoBH,
        nhanvien: item.HoTen,
        trangthai: state[item.State],
        khachhang: item.TenCongTy,
        tenban: item.TenBan,
        hinhthuctt: hinhthuc?.find((v) => v.ID === item.HinhThucTT)
          ?.TenHinhThuc,
        tongtien: item.ThanhTien,
        tienck: item.TienCK,
        tongsauck: item.KhachTra,
      });
    });
    sheet.getCell("A1").numFmt = "# ?/?";

    sheet.addRow({
      ngaybh: "Tổng: " + data.length,

      tongtien: _.sumBy(data, (o) => o.ThanhTien),
      tienck: _.sumBy(data, (o) => o.TienCK),
      tongsauck: _.sumBy(data, (o) => o.KhachTra),
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "banhang.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const expandedRowRender = (row) => {
    const columns = [
      {
        title: "Sản phẩm",
        dataIndex: "TenSP",
      },
      {
        title: "Số lượng",
        dataIndex: "SoLuong",
      },
      {
        title: "Đơn giá",
        dataIndex: "DonGia",
        render: (item) => Format_Currency(item),
      },
      {
        title: "Thành tiền",
        render: (item) => Format_Currency(item.SoLuong * item.DonGia),
      },
      {
        title: "Tiền CK",
        dataIndex: "TienCK",
        render: (item) => Format_Currency(item),
      },
      {
        title: "Tiền sau CK",
        dataIndex: "ThanhTien",
        render: (item) => Format_Currency(item),
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={dataDetail[row.ID]}
        pagination={false}
      />
    );
  };
  const [dataDetail, setDataDetail] = useState({});
  const loadDataExpand = async (value) => {
    let _res = await BanHangService.getBHSanPHam({
      MaBH: value.ID,
    });
    let _dt = _.cloneDeep(dataDetail);
    _dt[value.ID] = _res.data;
    setDataDetail(_dt);
  };
  const handleBanHang = () => {
    window.location.href = "#/admin/ban-theo-ban";
  };

  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              placeholder="Tìm kiếm tên, điện thoại, số hoá đơn"
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          right={() => (
            <Flex gap={10}>
              <Button onClick={() => exportExcel()}>Export</Button>
              <Button type="primary" onClick={() => refDetail.current.show()}>
                Thêm
              </Button>
              {JSON.parse(localStorage.getItem("user"))?.MaNTK === 3 && (
                <>
                  <Button danger onClick={() => handleBanHang()}>
                    Bán hàng
                  </Button>
                </>
              )}
            </Flex>
          )}
        />
        <Table
          style={{
            height: 100,
            // boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            // borderRadius:0
          }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            x: 2000,
          }}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={8}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData.length}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "ThanhTien")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TienCK")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "KhachTra")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
          rowKey="ID"
          expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: ["0"],
          }}
          expandIcon={({ expanded, onExpand, record }) => {
            return expanded ? (
              <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
            ) : (
              // <Button
              //   onClick={(e) => {
              //     onExpand(record, e);
              //   }}
              // >
              //   Rút gọn
              // </Button>
              <PlusCircleTwoTone
                onClick={(e) => {
                  onExpand(record, e);
                  loadDataExpand(record);
                }}
              />

              // <Button
              //   onClick={(e) => {
              //     onExpand(record, e);
              //     loadDataExpand(record);
              //   }}
              // >
              //   Chi tiết
              // </Button>
            );
          }}
        />
      </Col>
      <div style={{ display: "none" }}>
        <InHoaDon ref={refPrintHD} data={dataprint} />
      </div>
      <FormAddNew ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Row>
  );
}
