import {
  Badge,
  Drawer,
  Flex,
  Tooltip,
  Tabs,
  Row,
  Col,
  Avatar,
  Button,
  Tag,
} from "antd";
import React, { useState, useRef, useEffect, useContext } from "react";
import { IoIosNotifications } from "react-icons/io";
import "./index.scss";
import { CheckOutlined, CloseOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { CiSettings } from "react-icons/ci";
import { GoDotFill } from "react-icons/go";
import { InfoService } from "services/InfoService";
import { HeThongService } from "services/HeThongService";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { DateTime } from "luxon";
import SignalrContext from "contexts/SignalrContext";
const audioNotification = new Audio(
  require("../../../assets/audio/thong-bao.mp3")
);
const { TabPane } = Tabs;

const Notification = () => {
  const { hubConnection } = useContext(SignalrContext);
  const [open, setOpen] = useState(false);
  const [danhMuc, setDanhMuc] = useState([]);
  const [thongBao, setThongBao] = useState([]);
  const [countBage, setCountBage] = useState();
  const notificationRef = useRef(null);
  const history = useHistory();
  const refDetail = useRef(null);
  const refPhieuThu = useRef(null);
  const showDrawer = () => {
    setOpen(true);
    filter();
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (hubConnection) {
      hubConnection.on("GuiThongBaoToNV", (res) => {
        audioNotification.play();
        setCountBage((prevCount) => prevCount + 1);
      });
    }
  }, [hubConnection]);
  const filter = async () => {
    let _res = await HeThongService.ThongBao.get();
    if (_res.data) {
      setThongBao(_res.data);
      const countBage = _res.data.filter((item) => item.IsRead === false);
      setCountBage(countBage.length);
    }
  };
  const filterCountBage = async () => {
    let _resCN = await InfoService.getChucNang();
    if (_resCN.data) setDanhMuc(_resCN.data);
    let _res = await HeThongService.ThongBao.get();
    if (_res.data) {
      setThongBao(_res.data);
      const countBage = _res.data.filter((item) => item.IsRead === false);
      setCountBage(countBage.length);
    }
  };
  const handleConfirmRead = async () => {
    InfoService.confirmRead({
      MaTB: 0,
    }).then((res) => {
      if (res.status === 2000) {
        setThongBao(
          thongBao.map((tb) => ({
            ...tb,
            IsRead: true,
          }))
        );
        setCountBage(0);
      }
    });
  };
  const handleNexPage = () => {
    const path = "/admin/lich-su-notification";

    if (history.location.pathname !== path) {
      history.push(path);
    } else {
      history.replace(path);
    }
    setOpen(false);
  };

  dayjs.extend(relativeTime);

  const formatTimeAgo = (dateString) => {
    const dateTime = DateTime.fromISO(dateString);

    const time = dateTime.toFormat("HH:mm");
    const now = dayjs();
    const targetDate = dayjs(dateString);
    if (now.diff(targetDate, "hour") >= 24) {
      return time;
    } else if (now.diff(targetDate, "hour") >= 1) {
      return `${now.diff(targetDate, "hour")} giờ trước`;
    } else if (now.diff(targetDate, "minute") >= 1) {
      return `${now.diff(targetDate, "minute")} phút trước`;
    } else {
      return "Vừa xong";
    }
  };
  useEffect(() => {
    filterCountBage();
  }, []);
  const nextPage = async (item) => {
    if (item.IsRead === false) {
      InfoService.confirmRead({
        MaTB: item?.MaTB,
      }).then((res) => {
        if (res.status === 2000) {
          setThongBao(
            thongBao.map((tb) => ({
              ...tb,
              IsRead: tb.MaTB === item?.MaTB ? true : tb?.IsRead,
            }))
          );
          setCountBage(countBage - 1);
        }
      });
    }
    if (item?.LinkID !== 0) {
      if (item?.FormID === 46) {
        refDetail.current.show(item?.LinkID);
        setOpen(false);
      } else if (item.FormID === 102) {
        refPhieuThu.current.show(item.LinkID);
      } else if (item.FormID === 1178) {
         let url = `${window.location.origin}/#chats/${item.MaTN}?id=${item.LinkID}`;
         window.open(url);
      }
    }
  };
  return (
    <div className="container-notification">
      <div
        ref={notificationRef}
        onClick={showDrawer}
        style={{ padding: "10px 10px 0", cursor: "pointer" }}
      >
        <Tooltip placement="left" title="Thông báo">
          <Badge
            count={countBage}
            //  overflowCount={9}
            size="small"
          >
            <IoIosNotifications style={{ color: "#fff", fontSize: "25px" }} />
          </Badge>
        </Tooltip>
      </div>

      <Drawer
        headerStyle={{ display: "none" }}
        onClose={onClose}
        open={open}
        placement="top"
        closable={true}
        bodyStyle={{ padding: 0 }}
        className="custom-drawer"
        style={{ borderRadius: "10px", boxShadow: "0 2px 8px rgba(0,0,0,0.1)" }}
        contentWrapperStyle={{
          width: "500px",
          height: "550px",
          top: notificationRef.current
            ? `${notificationRef.current.getBoundingClientRect().bottom + 2}px`
            : "0px",
          left: notificationRef.current
            ? `${notificationRef.current.getBoundingClientRect().left - 465}px`
            : "100px",
          position: "absolute",
          // Thêm bóng cho Drawer
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0 15px",
          }}
        >
          <div style={{ flex: 1, padding: "10px" }}>
            <Flex style={{ paddingTop: "10px" }} justify="space-between">
              <h2
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "#050505",
                }}
              >
                Thông báo
              </h2>
              {/* <Link onClick={() => setOpen(false)} to="/admin/info-user"> */}
              <Button
                onClick={onClose}
                size="small"
                icon={<CloseOutlined />}
              ></Button>
              {/* </Link> */}
            </Flex>
            <Tabs
              className="custom-tabs"
              defaultActiveKey="4"
              style={{ paddingTop: "0px" }}
            >
              {danhMuc?.map((item) => {
                const notificationCount = thongBao.filter(
                  (tb) => tb.MaChucNang === item.ID && !tb.IsRead
                ).length;
                return (
                  <TabPane
                    tab={
                      <Badge
                        count={notificationCount}
                        offset={[5, -5]}
                        overflowCount={99}
                        size="small"
                      >
                        {item?.Name}
                      </Badge>
                    }
                    key={item.ID}
                  >
                    <div
                      className="custom-scroll"
                      style={{ overflowY: "scroll", maxHeight: "375px" }}
                    >
                      {thongBao
                        ?.filter((v) => v.MaChucNang === item?.ID)
                        .map((thongBao) => {
                          return (
                            <Row
                              onClick={() => nextPage(thongBao)}
                              style={{
                                marginBottom: "15px",
                                cursor: "pointer",
                              }}
                            >
                              <Col span={23}>
                                <h5
                                  style={{
                                    fontWeight: thongBao?.IsRead
                                      ? "500"
                                      : "700",
                                    color: thongBao?.IsRead
                                      ? "#65676B"
                                      : "#3b3a3a",
                                  }}
                                >
                                  <Tag color="orange">{item?.Name}</Tag>{" "}
                                  {thongBao?.Title}
                                </h5>
                                <p
                                  style={{
                                    color: thongBao?.IsRead
                                      ? "#6c757d"
                                      : "#333",
                                    fontSize: "13.2px",
                                    paddingTop: "5px",
                                  }}
                                >
                                  {thongBao.Contents}
                                </p>
                                <Flex justify="space-between">
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "#6c757d",
                                    }}
                                  >
                                    {formatTimeAgo(thongBao.DateCreate)}
                                  </p>
                                  <p>
                                    {dayjs(thongBao.DateCreate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </Flex>
                              </Col>
                              <Col
                                style={{
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                                span={1}
                              >
                                {!thongBao?.IsRead ? (
                                  <GoDotFill
                                    style={{
                                      color: "#1890ff",
                                      fontSize: "18px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          );
                        })}
                    </div>
                   
                  </TabPane>
                );
              })}
            </Tabs>
          </div>
          <div
            style={{
              marginBottom: "-545px",
              borderTop: "1px solid #e2e8f0",
              padding: "10px",
              backgroundColor: "#fff",
            }}
          >
            <Flex justify="space-between">
              <Flex align="center">
                <Link
                  to={{
                    pathname: "/admin/info-user",
                    state: {
                      activeKey: "3",
                    },
                  }}
                  key={"da8sahd98yx9"}
                >
                  <CiSettings
                    style={{
                      marginRight: "10px ",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  />{" "}
                </Link>
                <CheckOutlined style={{ color: "#1890ff" }} />
                <CheckOutlined
                  style={{
                    color: "#1890ff",
                    marginLeft: "-4px",
                    marginRight: "5px",
                  }}
                />
                <p
                  onClick={() => handleConfirmRead()}
                  style={{
                    fontWeight: "500",
                    color: "#1890ff",
                    cursor: "pointer",
                  }}
                >
                  Đánh dấu đã xem
                </p>
              </Flex>

              <Button onClick={() => handleNexPage()} type="primary">
                Xem thêm
              </Button>
            </Flex>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Notification;
