import React, { useState, useEffect } from "react";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { BanHangService } from "services/BanHangService";
import { NhanVienService } from "services/NhanVienService";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";

const FilterModal = ({ ...props }) => {
  const { companies, filterCondition, onChangeFilter, setActiveTT } = props;
  const [quay, setQuay] = useState([]);
  const [nhanvien, setNhanvien] = useState([]);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "ListMaCT":
      case "QuayBan":
      case "NhanVien":
        _filter[p] =
          Array.isArray(v) && v.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
      case "DenNgay":
        _filter[p] = v ? new Date(v) : null;
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };

  useEffect(() => {
    BanHangService.getQuayBan().then((res) => setQuay(res.data ?? []));
    NhanVienService.getNhanVien().then((res) => setNhanvien(res.data ?? []));
  }, []);
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  const defaultDate = {
    Name: "Năm nay",
    TuNgay: filterCondition.TuNgay,
    DenNgay: filterCondition.DenNgay,
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} dateDefault={defaultDate} />

      <div className="box-search">
        <p className="label">Quầy vé</p>
        <Select
          mode="multiple"
          placeholder="Chọn quầy vé"
          onChange={(v) => applyChangeFilter("QuayBan", v)}
          style={{
            width: "100%",
          }}
          options={quay}
          fieldNames={{ label: "TenQuay", value: "ID" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Nhân viên</p>
        <Select
          mode="multiple"
          placeholder="Chọn nhân viên"
          onChange={(v) => applyChangeFilter("NhanVien", v)}
          style={{
            width: "100%",
          }}
          options={nhanvien}
          fieldNames={{ label: "HoTen", value: "MaNV" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Trạng thái</p>
        <Select
          // mode="multiple"
          allowClear
          placeholder="Chọn trạng thái"
          onChange={(v) => applyChangeFilter("IsGiaHan", v)}
          style={{
            width: "100%",
          }}
          options={[
            { Name: "Đăng kí mới", ID: false },
            { Name: "Gia hạn", ID: true },
          ]}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
