import React, { useEffect, useRef, useState } from "react";
import {
  DatePicker,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import Chart from "react-apexcharts";
import { BanHangService } from "services/BanHangService";
import "../scss/index.scss";
import { ReportService } from "services/ReportService";
export default function VeOnOff() {
  const [filterCondition, setFilterCondition] = useState(new Date());
  const optionsDefault = {
    plotOptions: {
      pie: {
        horizontal: true,
      },
    },
    labels: ["Onlive", "Quầy vé"],
    legend: {
      position: "bottom",
    },
  };
  const seriesDefault = [44, 55];
  const [options, setOptions] = useState(optionsDefault);
  const [series, setSeries] = useState(seriesDefault);
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    let _res = await ReportService.getVeBanTheoThang({
      Date: _filter,
    });
    let _loaihd = await BanHangService.Categories.getLoaiHD();
    let labels = [];
    let series = []
    _loaihd?.data?.map((item)=>{
      labels.push(item.TenLHD);
      let sl = _res.data?.filter(v=>v.MaLHD === item.MaLHD);
      series.push(sl?.length ?? 0)
    })
    let _option = _.cloneDeep(options);
    _option.labels = labels;
    setOptions(_option);
    setSeries(series);
  };

  const applyChange = (v) => {
    filter(v);
    setFilterCondition(v);
  };
  return (
    <div className="box">
      <Flex gap="middle" justify="space-between" >
        <p style={{ fontWeight: "bold" }}>Hoá đơn On/Off</p>
        <DatePicker
          allowClear={false}
          format="MM-YYYY"
          style={{ width: "120px" }}
          defaultValue={dayjs(filterCondition)}
          picker="month"
         // value={data?.NgaySinh ? dayjs(data?.NgaySinh) : null}
          // placeholder="Ngày sinh"
          // locale={vi} 
          onChange={(v) => applyChange(v)}
        />
      </Flex>
      <Chart
        options={options}
        series={series}
        type="pie"
        width="100%"
        height={300}
      />
    </div>
  );
}
