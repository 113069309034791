import React, { useEffect } from "react";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import ChiNhanhSelect from "components/ChiNhanhSelect";

const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter, setData, data, dataAll } = props;
  // const [quay, setQuay] = useState([]);
  // const [nhanvien, setNhanvien] = useState([]);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaLyDo":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      case "MaCT":
        _filter[p] = v ? v : 0;
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };

  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  const handleTrangThai = (v) => {
    const hienTai = new Date();
    const sevenDaysLater = new Date(
      hienTai.getTime() + 7 * 24 * 60 * 60 * 1000
    );
    switch (v) {
      case 1:
        const dangSuDung = dataAll?.filter(
          (item) => new Date(item?.NgayHH) >= hienTai
        );
        setData(dangSuDung);
        break;

      case 2:
        const sapHetHan = dataAll?.filter(
          (item) =>
            new Date(item?.NgayHH) >= hienTai &&
            new Date(item?.NgayHH) <= sevenDaysLater
        );
        setData(sapHetHan);
        break;

      case 3:
        const theHetHan = dataAll?.filter(
          (item) => new Date(item?.NgayHH) < hienTai
        );
        setData(theHetHan);
        break;

      default:
        setData(dataAll);
        break;
    }
  };

  const dateDefault = {
    Name: "Năm này",
    TuNgay: filterCondition.TuNgay,
    DenNgay: filterCondition.DenNgay,
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} dateDefault={dateDefault} />
      <ChiNhanhSelect
        FormID={282}
        onChange={(v) => applyChangeFilter("MaCT", v)}
      />
      <div className="box-search">
        <p className="label">Trạng thái</p>
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          allowClear
          value={filterCondition.MaTN}
          placeholder="Chọn trạng thái"
          onChange={(v) => handleTrangThai(v)}
          style={{
            width: "100%",
          }}
          options={[
            { TrangThai: "Đang sử dụng", ID: 1 },
            { TrangThai: "Sắp hết hạn", ID: 2 },
            { TrangThai: "Thẻ hết hạn", ID: 3 },
          ]}
          fieldNames={{ label: "TrangThai", value: "ID" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
