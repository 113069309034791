import axios from "axios";

const axiosApi = (headers) => {
  return axios.create({
    baseURL: "https://api-vinvoice.viettel.vn/",
    headers: {
      "Content-Type": "application/json",
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...headers,
    },
  });
};
export const ViettelService = {
  Login: async (payload) => {
    return await axiosApi()
      .post("auth/login", {
        // username: payload.Username, //"0100109106-509",
        // password: payload.Password, //"2wsxCDE#",
        username: payload.Username, //"0100109106-509",
        password: payload.Password, //"2wsxCDE#",
      })
      .then((res) => res.data);
  },
  addHoaDon: async (payload, account) => {
    let _header = {
      Authorization: `Bearer ${payload.token}`,
    };
    let urlDraft =
      "services/einvoiceapplication/api/InvoiceAPI/InvoiceWS/createOrUpdateInvoiceDraft/";
    let url =
      "services/einvoiceapplication/api/InvoiceAPI/InvoiceWS/createInvoice/";
    return await axiosApi(_header)
      .post(
        `${account?.isKyHoaDon ? url : urlDraft}${account?.Username}`,
        payload
      )
      .then((res) => res.data);
    // return await axiosApi(_header)
    //   .post(
    //     // "services/einvoiceapplication/api/InvoiceAPI/InvoiceWS/createInvoice/" +
    //     "services/einvoiceapplication/api/InvoiceAPI/InvoiceWS/createOrUpdateInvoiceDraft/" +
    //       account?.Username,
    //     payload
    //   )
    //   .then((res) => res.data);
  },
};
