import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  Select,
  Checkbox,
  Table,
} from "antd";
import _ from "lodash";
import { SanPhamService } from "services/SanPhamService";
import { OtherService } from "services/OtherService";
import DebounceSelect from "components/DebounceSelect";
const FormAddSanPham = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const refMaBG = useRef(null);
  useImperativeHandle(ref, () => ({
    add: async (list, mabg) => {
      setOpen(true);
      refMaBG.current = mabg;
      let ids = _.map(list, "ID");
      // let _res = await SanPhamService.getList({ InputSearch: "" });
       let _res = await SanPhamService.getListByMaNhom({
         InputSearch: "",
       });
      // let _sptheoquay = await SanPhamService.getSanPhamTheoQuay({
      //   MaQuay: quay,
      // });
      // let selects=[]
      // list?.map((item) => {
      //   selects.push(item.MaSP);
      // });
      // setSelectedRowKeys(selects);
      setData(_res.data?.filter((v) => !ids.includes(v.ID)));
    },
  }));
  const onClose = () => {
    setOpen(false);
    setSelectedRowKeys([]);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let lst = [];
    selectedRowKeys?.map((id) => {
      let sp = data?.find((v) => v.ID === id);
      lst.push({
        GiaMua: sp?.GiaMua,
        GiaBan: sp?.GiaBan,
        MaSP: id,
      });
    });
    let _payload = {
      SanPham: lst,
      MaBG: refMaBG.current,
    };
    let _res = await SanPhamService.Categories.addNhieuSPBangGia(_payload);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        "Thêm sản phẩm vào bảng giá thành công!"
      );
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const columns = [
    {
      title: "Tên sản phẩm",
      dataIndex: "TenSP",
    },
    {
      title: "Loại sản phẩm",
      dataIndex: "TenLSP",
    },
    {
      title: "Nhóm sản phẩm",
      dataIndex: "TenNhom",
    },
  ];
  const onSelectChange = async (newSelectedRowKeys) => {
    setLoading(true);
    console.log(newSelectedRowKeys);
    
    // if (selectedRowKeys.length > newSelectedRowKeys.length) {
    //   let id = _.difference(selectedRowKeys, newSelectedRowKeys);
    //   if(id?.length > 0){
    //     let _res = await SanPhamService.removeSanPhamTheoQuay({
    //       MaQuay: maquay,
    //       Ids: id,
    //     });
    //   }
    // } else {
    //    let id = _.difference(newSelectedRowKeys,selectedRowKeys);
    //   if (id?.length > 0) {
    //     let _res = await SanPhamService.addSanPhamTheoQuay({
    //       MaQuay: maquay,
    //       Ids: id,
    //     });
    //   }
    // }
    setSelectedRowKeys(newSelectedRowKeys);
    setLoading(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <Drawer
      title="Thêm sản phẩm"
      width={950}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Button loading={loading} type="primary" onClick={() => onSubmit()}>
          Lưu
        </Button>
      }
    >
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 190,
          // x: 2500
        }}
        rowSelection={rowSelection}
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
        size="small"
        pagination={{
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: ["100", "500", "5000"],
        }}
      />
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAddSanPham);
