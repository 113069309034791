import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Popover,
  Row,
  Select,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";

const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter, options } = props;

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaTN":
        _filter[p] = v;
        break;

      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div className="">
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      <div className="box-search">
        <p className="label">Loại thông báo</p>
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("MaTN")}
          value={filterCondition.MaChucNang}
          placeholder="Chọn loại thông báo"
          onChange={(v) => applyChangeFilter("MaChucNang", v)}
          style={{
            width: "100%",
          }}
          options={[{ Name: "Tất cả", ID: 0 }, ...options?.LoaiTB]}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
