import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  DatePicker,
  InputNumber,
  Row,
  Col,
  Drawer,
  notification,
  Select,
  Flex,
  Modal,
  Popconfirm,
  Badge,
  message,
  Tooltip,
  Input,
  Popover,
  Spin,
  Table,
} from "antd";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import _ from "lodash";
import { FaTrashAlt } from "react-icons/fa";
import { SanPhamService } from "services/SanPhamService";
import { Format_Currency } from "layouts/ultis/common";
import { CustomerService } from "services/CustomerService";
import { BanHangService } from "services/BanHangService";
import { CardService } from "services/CardService";
import FormAddKH from "../../CRM/KhachHang/FormAdd";
import { MdOutlineCancel, MdOutlineFastfood } from "react-icons/md";
import { KhoHangService } from "services/KhoHangService";
import { CiStickyNote, CiTimer } from "react-icons/ci";
import { Format_Datetime, Format_Time } from "layouts/ultis/common";
import FormChangeTable from "./FormChangeTable";
import { FcOvertime } from "react-icons/fc";
import FormPay from "./FormPay";
import { RiMoneyDollarCircleLine, RiTableAltLine } from "react-icons/ri";
import { LuPercent } from "react-icons/lu";
import "./scss/index.scss";
import { SlNote } from "react-icons/sl";
import { NhanVienService } from "services/NhanVienService";

function roundToThree(num) {
  return parseFloat((num + Number.EPSILON).toFixed(3));
}
const columns = [
  {
    title: "Ngày tính",
    dataIndex: "NgayTinh",
    render: (item) => Format_Datetime(item),
  },
  {
    title: "Giờ vào",
    dataIndex: "GioVao",
    render: (item) => Format_Datetime(item),
  },
  {
    title: "Giờ ra",
    dataIndex: "GioRa",
    render: (item) => item && Format_Datetime(item),
  },
  {
    title: "Nhân viên",
    dataIndex: "TenNV",
  },
  {
    title: "Hành động",
    dataIndex: "IsDungTinh",
    render: (item) => (item ? "Dừng tính" : "Tiếp tục"),
  },
];
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [diemTien, setDiemTien] = useState({});
  const [khachhang, setKhachhang] = useState([]);
  const refPay = useRef(null);
  const refAddKH = useRef(null);
  const [cardType, setCardType] = useState({});
  const ketcaRef = useRef();
  const refChangeTable = useRef(null);
  const [tinhGio, setTinhGio] = useState({});
  const [lsTinhGio, setLsTinhGio] = useState([]);
  const defaulData = {
    IsKhachHang: 1,
    SanPham: [],
    MaKH: null,
  };
  const [data, setData] = useState(defaulData);
  const [open, setOpen] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [openNoteBH, setOpenNoteBH] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [sanpham, setSanpham] = useState([]);
  const [sanphamall, setSanphamall] = useState([]);
  const [khohang, setKhohang] = useState([]);
  const [quayve, setQuayve] = useState([]);
  const [dataquay, setDataquay] = useState({});
  const [calamviec, setCalamviec] = useState({});
  const [openCaLV, setOpenCaLV] = useState(false);
  const [openTinhGio, setOpenTinhGio] = useState(false);
  const [loaisp, setLoaisp] = useState([]);
  const [banggia, setBanggia] = useState([]);
  const [bgActive, setBgActive] = useState(0);
  const [uudai, setUudai] = useState({});
  const [lspActive, setLspActive] = useState({ MaLSP: 0, TenLSP: "Tất cả" });
  const refActive = useRef(null);
  const [action, setAction] = useState([]);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen?.() ||
        document.documentElement.mozRequestFullScreen?.() ||
        document.documentElement.webkitRequestFullscreen?.(
          Element.ALLOW_KEYBOARD_INPUT
        );
    } else {
      // document.cancelFullScreen?.() ||
      //   document.mozCancelFullScreen?.() ||
      //   document.webkitCancelFullScreen?.();
    }
  };
  const goFullScreen = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user?.MaNTK == 3) {
      toggleFullscreen();
      let navBar = document.querySelector(".box-header");
      if (navBar) {
        navBar.style.display = "none";
      }
    }
  };
  const checkAction = (id) => {
    return action?.some((v) => v.FeatureID === id);
  };
  const loadcategories = async () => {
    let _qv = await BanHangService.getQuayBan();
    setQuayve(_qv.data ?? []);
    let _dataquay = _.cloneDeep(dataquay);
    _dataquay.MaQuay = _qv.isDefault;
    setDataquay(_dataquay);
    let _kh = await CustomerService.getListAll();
    _kh.data?.map((item) => (item.Name = item.TenCongTy + " - " + item.DiDong));
    setKhachhang(_kh?.data);
    NhanVienService.getAction({ FormID: 403 }).then((res) =>
      setAction(res?.data)
    );
  };

  const loadSanPham = async (maquay) => {
    let _sp = await SanPhamService.getList({ InputSearch: "" });
    //get bang gia
    let _bgtheoquay = await SanPhamService.Categories.getBangGiaTheoQuay({
      MaQuay: maquay,
    });
    setBanggia(_bgtheoquay.data);
    let mabgDefault = _bgtheoquay.data?.find((v) => v.MacDinh)?.ID;
    setBgActive(mabgDefault);
    let _sptheobg = await SanPhamService.Categories.getSanPhamByMaBG({
      InputSearch: "",
      MaBG: mabgDefault,
    });
    //end
    let _sptheoquay = await SanPhamService.getSanPhamTheoQuay({
      MaQuay: maquay,
    });
    let spquay = _sp.data.filter((v) =>
      _.map(_sptheoquay.data, "MaSP").includes(v.ID)
    );
    let _lsp = await SanPhamService.getLSP({ ApDung: true });
    let lsp = [{ MaLSP: 0, TenLSP: "Tất cả" }];
    let _sptheoloai = spquay.filter((v) =>
      _.map(_lsp.data, "MaLSP").includes(v.MaLSP)
    );
    let updateGia = _sptheoloai?.map((v) => {
      let sp = _sptheobg?.data?.find((a) => a.ID === v.ID);
      if (sp) {
        return { ...v, GiaBan: sp.GiaBan };
      } else return v;
    });
    setSanpham(updateGia);
    setSanphamall(updateGia);
    _lsp.data?.map((item) => {
      if (_sptheoloai?.some((v) => v.MaLSP === item.MaLSP)) lsp.push(item);
    });
    setLoaisp(lsp);
  };
  const applyChangeBangGia = async (v) => {
    setBgActive(v);
    let _sptheobg = await SanPhamService.Categories.getSanPhamByMaBG({
      InputSearch: "",
      MaBG: v,
    });
    let sanpham = _.cloneDeep(sanphamall);
    let updateGia = sanpham?.map((v) => {
      let sp = _sptheobg?.data?.find((a) => a.ID === v.ID);
      if (sp) {
        return { ...v, GiaBan: sp.GiaBan };
      } else return v;
    });
    setSanpham(updateGia);
    setSanphamall(updateGia);
  };
  useImperativeHandle(ref, () => ({
    show: async (ban, isTinhGio) => {
      setOpen(true);
      goFullScreen();
      setLoadingScreen(true);
      loadcategories();
      let _data = _.cloneDeep(data);
      _data.MaBan = ban.ID;
      _data.TenBan = ban.TenBan;
      _data.MaKV = ban.MaKV;
      _data.TinhGio = isTinhGio;
      _data.GioVao = ban?.GioVao ?? new Date();
      let _checkBanHang = await BanHangService.BanTheoBan.check({
        MaBan: ban.ID,
      });
      if (_checkBanHang?.data) {
        _data.MaBH = _checkBanHang.data?.id;
        _data.DienGiai = _checkBanHang.data?.dienGiai;
        _data.SoBH = _checkBanHang.data?.soBH;
        _data.ThanhTien = _checkBanHang.data?.thanhTien;
        _data.SanPham = _checkBanHang.sanpham;
      }
      let _kho = await KhoHangService.NhapKho.getTenKho();
      if (_kho.data?.length > 0) {
        _data.MaKho = _kho.data[0].ID;
        setKhohang(_kho.data ?? []);
      }
      setData(_data);
      let _check = await BanHangService.checkCaLamViec();
      if (!_check.data) {
        setLoadingScreen(false);
        setOpenCaLV(true);
      } else {
        loadSanPham(_check.data.MaQuay);
        setCalamviec(_check.data);
      }
    },
  }));
  useEffect(() => {
    (async () => {
      setLoadingScreen(true);
      let _data = _.cloneDeep(data);
      if (_data?.TinhGio) {
        if (!_data.MaBH) {
          console.log("tinhtien");
          let _sp = await SanPhamService.getListByMaNhom({
            MaNhom: 7,
            InputSearch: "",
          });
          let _sptheoban = await SanPhamService.TheoBan.Get({
            MaBan: _data.MaBan,
          });
          let lstsp = [];
          if (_sp.data?.length > 0) {
            let _findsp = _sp?.data?.find(
              (v) => v.ID === _sptheoban?.data?.MaSP
            );
            lstsp.push({
              MaSP: _findsp ? _findsp.ID : _sp.data[0].ID,
              TenSP: _findsp ? _findsp.TenSP : _sp.data[0].TenSP,
              DonGia: _findsp ? _sptheoban?.data?.DonGia : _sp.data[0].GiaBan,
              SoLuong: 0,
              TyLeCK: 0,
              TienCK: 0,
              ThanhTien: 0,
              MaNhom: _findsp ? _findsp.MaNhom : _sp.data[0].MaNhom,
            });
          }
          let _payload = {
            MaCaLamViec: calamviec?.ID,
            MaQuayBan: calamviec?.MaQuay,
            TenQuayVT: quayve?.find((v) => v.ID === calamviec?.MaQuay)?.MaQuay,
            MaNhom: 2,
            ThanhTien: 0,
            SanPham: lstsp,
            MaBan: _data.MaBan,
          };
          let _res = await BanHangService.BanTheoBan.add(_payload);
          if (_res.status === 2000) {
            let _checkBanHang = await BanHangService.BanTheoBan.check({
              MaBan: _data.MaBan,
            });
            if (_checkBanHang?.data) {
              _data.MaBH = _res.data;
              _data.ThanhTien = _checkBanHang.data?.thanhTien;
              _data.SanPham = _checkBanHang.sanpham;
            }
          }
          setData(_data);
          onAfterSubmit(_data.MaBan, "ACTIVE");
        }
      }
      setLoadingScreen(false);
    })();
  }, [calamviec]);

  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    setDiemTien({});
  };
  const SumTotal = (_data) => {
    _data.ThanhTien = _data?.SanPham?.reduce((pre, current) => {
      return (pre += current.ThanhTien);
    }, 0);
    _data.TienCK = (_data.ThanhTien * (_data?.TyLeCK ?? 0)) / 100;
  };
  const applyChange = async (p, v, MaSP) => {
    if (loading === true) {
      return;
    }
    setLoading(true);
    let _data = _.cloneDeep(data);
    switch (p) {
      case "ChooseSP":
        _data[p] = [];
        let sp = JSON.parse(v[0].title);
        let spds = _data.SanPham?.find((v) => v.MaSP === sp.ID);
        if (spds) {
          spds.SoLuong++;
        } else {
          _data.SanPham.push({
            MaSP: sp.ID,
            TenSP: sp.TenSP,
            DonGia: sp.GiaBan,
            SoLuong: 1,
            TyLeCK: 0,
            TienCK: 0,
            ThanhTien: sp.GiaBan,
            MaNhom: sp.MaNhom,
          });
        }
        SumTotal(_data);
        break;
      case "ChooseClickSP":
        let spdsnew = _data.SanPham?.find((f) => f.MaSP === v.ID);
        if (spdsnew) {
          spdsnew.SoLuong++;
          spdsnew.TienCK =
            (spdsnew?.SoLuong * spdsnew?.DonGia * spdsnew?.TyLeCK) / 100;
          spdsnew.ThanhTien =
            spdsnew.SoLuong * spdsnew.DonGia -
            (spdsnew?.SoLuong * spdsnew?.DonGia * spdsnew.TyLeCK) / 100;
        } else {
          let tyleck = uudai?.TyLeCK ? uudai?.TyLeCK : 0;
          let TienCK = (v?.GiaBan * tyleck) / 100;
          let ThanhTien = v.GiaBan - (v?.GiaBan * tyleck) / 100;
          _data.SanPham.push({
            MaSP: v.ID,
            TenSP: v.TenSP,
            DonGia: v.GiaBan,
            SoLuong: 1,
            TyLeCK: tyleck,
            TienCK: TienCK,
            ThanhTien: ThanhTien,
            MaNhom: v.MaNhom,
          });
        }
        SumTotal(_data);
        if (_data.MaBH) {
          let _payload = {
            ThanhTien: _data.ThanhTien,
            ID: _data.MaBH,
            SanPham: _data.SanPham,
          };
          await BanHangService.BanTheoBan.update(_payload);
          let _sp = await BanHangService.BanTheoBan.check({
            MaBan: _data.MaBan,
          });
          _data.SanPham = _sp.sanpham;
        } else {
          let _payload = {
            MaCaLamViec: calamviec?.ID,
            MaQuayBan: calamviec?.MaQuay,
            MaNhom: 2,
            ThanhTien: _data.ThanhTien,
            SanPham: _data.SanPham,
            MaBan: _data.MaBan,
          };
          let _res = await BanHangService.BanTheoBan.add(_payload);
          _data.MaBH = _res?.data;
          onAfterSubmit(_data.MaBan, "ACTIVE");
        }
        break;
      case "MaKH":
        if (v) {
          _data[p] = v;
          // _data.MaKH = v;
          let _the = await CardService.getByMaKH({ MaKH: v });
          // _data.MaVach = _the?.data?.MaVach;
          let _res = await CardService.getDiem({
            MaThe: _the?.data?.ID,
          });
          let _resTypeCardDetail = await CardService.getLoaiTheDetail({
            ID: _the?.data?.MaLT,
          });
          setDiemTien(_res.data ?? {});
          setCardType(_resTypeCardDetail.data ?? {});
          _data.MaThe = _the?.data?.ID;
          let _uu = await CardService.findUuDai({
            LoaiUuDai: "CK",
            MaLoaiThe: _the?.data?.MaLT,
          });
          if (_uu?.data) {
            setUudai(_uu.data);
            _data.SanPham?.map((item) => {
              item.TyLeCK = _uu?.data?.TyLeCK;
              item.TienCK = (item?.SoLuong * item?.DonGia * item.TyLeCK) / 100;
              item.ThanhTien =
                item.SoLuong * item.DonGia -
                (item?.SoLuong * item?.DonGia * item.TyLeCK) / 100;
            });
            SumTotal(_data);
          } else {
            _data.SanPham?.map((item) => {
              item.TyLeCK = 0;
              item.TienCK = 0;
              item.ThanhTien = item.SoLuong * item.DonGia - 0;
            });
            SumTotal(_data);
          }
        } else {
          _data[p] = [];
          setUudai({});
        }
        break;
      case "SoLuong":
        let sl = _data.SanPham?.find((v) => v.MaSP === MaSP);
        sl[p] = v;
        sl.TienCK = (sl?.SoLuong * sl?.DonGia * sl?.TyLeCK) / 100;
        sl.ThanhTien =
          sl.SoLuong * sl.DonGia - (sl?.SoLuong * sl?.DonGia * sl.TyLeCK) / 100;
        SumTotal(_data);
        let _payloadSL = {
          ThanhTien: _data.ThanhTien,
          ID: _data.MaBH,
          SanPham: [sl],
        };
        await BanHangService.BanTheoBan.update(_payloadSL);
        break;
      case "TyLeCK":
        let item = _data.SanPham?.find((v) => v.MaSP === MaSP);
        item[p] = v;
        item.TienCK = (item?.SoLuong * item?.DonGia * v) / 100;
        item.ThanhTien =
          item.SoLuong * item.DonGia -
          (item?.SoLuong * item?.DonGia * item.TyLeCK) / 100;
        SumTotal(_data);
        break;
      case "Remove":
        _data.SanPham?.splice(v, 1);
        SumTotal(_data);
        if (MaSP) {
          BanHangService.BanVeKinhDoanh.deleteSPBanHang({ ID: MaSP });
        }
        break;
      case "AddDvGio":
        let _sp = await SanPhamService.getListByMaNhom({
          MaNhom: 7,
          InputSearch: "",
        });
        let _sptheoban = await SanPhamService.TheoBan.Get({
          MaBan: _data.MaBan,
        });
        let lstsp = [];
        if (_sp.data?.length > 0) {
          let _findsp = _sp?.data?.find((v) => v.ID === _sptheoban?.data?.MaSP);
          lstsp.push({
            MaSP: _findsp ? _findsp.ID : _sp.data[0].ID,
            TenSP: _findsp ? _findsp.TenSP : _sp.data[0].TenSP,
            DonGia: _findsp ? _sptheoban?.data?.DonGia : _sp.data[0].GiaBan,
            SoLuong: 0,
            TyLeCK: 0,
            TienCK: 0,
            ThanhTien: 0,
            MaNhom: _findsp ? _findsp.MaNhom : _sp.data[0].MaNhom,
            GioRa: null,
            GioVao: DateTime.now().setZone("Asia/Ho_Chi_Minh"),
            ID: null,
          });
        }
        let _payloadAdd = {
          ThanhTien: _data.ThanhTien,
          ID: _data.MaBH,
          SanPham: lstsp,
        };
        await BanHangService.BanTheoBan.update(_payloadAdd);
        let _check = await BanHangService.BanTheoBan.check({
          MaBan: _data.MaBan,
        });
        _data.SanPham = _check.sanpham;
        break;
      case "TinhGio":
        let _sptg = _data.SanPham?.find((v) => v.ID === tinhGio.ID);
        if (!_sptg) return;
        _sptg.GioRa = tinhGio.IsDungTinh ? null : new Date(tinhGio.GioRa);
        _sptg.GioVao = new Date(tinhGio.GioVao);
        const ngay1 = tinhGio.IsDungTinh
          ? null
          : DateTime.fromISO(_sptg.GioRa?.toISOString(), {
              zone: "Asia/Ho_Chi_Minh",
            }).toString();
        const ngay2 = DateTime.fromISO(_sptg.GioVao?.toISOString(), {
          zone: "Asia/Ho_Chi_Minh",
        }).toString();
        var chenhLechGio =
          (new Date(ngay1) - new Date(ngay2)) / (1000 * 60 * 60);
        _sptg.SoLuong = tinhGio.IsDungTinh
          ? 0
          : roundToThree((chenhLechGio ?? 0) + 0.01) ?? 0;
        _sptg.TienCK = (_sptg?.SoLuong * _sptg?.DonGia * _sptg?.TyLeCK) / 100;
        _sptg.ThanhTien =
          (_sptg.SoLuong ?? 0) * _sptg.DonGia -
          (_sptg?.SoLuong * _sptg?.DonGia * _sptg.TyLeCK) / 100;
        SumTotal(_data);
        _sptg.GioRa = ngay1;
        _sptg.GioVao = ngay2;
        let _payload2 = {
          ThanhTien: _data.ThanhTien,
          ID: _data.MaBH,
          SanPham: [
            {
              ..._sptg,
              // GioRa: ngay1,
              // GioVao: ngay2,
            },
          ],
        };
        await BanHangService.BanTheoBan.update(_payload2);
        if (tinhGio?.IsPay) pay(_data);
        BanHangService.BanTheoBan.addLichSuTinhGio({
          ..._sptg,
          MaSPBH: _sptg.ID,
          IsDungTinh: !tinhGio.IsDungTinh,
          // GioRa: ngay1,
          // GioVao: ngay2,
        });
        // let _checktg = await BanHangService.BanTheoBan.check({
        //   MaBan: _data.MaBan,
        // });
        // _data.SanPham = _checktg.sanpham;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
    setLoading(false);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const handleCancel = async () => {
    let _data = _.cloneDeep(data);
    let _res = await BanHangService.BanTheoBan.cancel({
      ID: _data.MaBH,
      MaBan: _data.MaBan,
    });
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Huỷ bàn thành công!`);
      onClose();
      onAfterSubmit(_data.MaBan, "EMPTY");
    }
  };
  const changeTable = () => {
    refChangeTable.current.show({
      MaKV: data?.MaKV,
      MaBan: data?.MaBan,
      GioVao: data?.GioVao,
      MaBH: data?.MaBH,
    });
  };
  useEffect(() => {
    let time = null;
    if (data.MaVach?.length > 0) {
      time = setTimeout(async () => {
        let _res = await CardService.getCardByMaVach({
          MaVach: data.MaVach,
        });
        if (_res.status === 2000) {
          messageApi.open({
            type: "success",
            content: "Thẻ đang hoạt động",
          });
          let _kh = await CardService.getKhachHangByID({ ID: _res.data.MaKH });
          let _data = _.cloneDeep(data);
          _data.MaKH = _kh.data.MaKH;
          // _data.KhachHang = _kh.data.MaKH;
          let _resDiem = await CardService.getDiem({
            MaThe: _res?.data?.ID,
          });
          let _resTypeCardDetail = await CardService.getLoaiTheDetail({
            ID: _res?.data?.MaLT,
          });
          setDiemTien(_resDiem.data ?? {});
          setCardType(_resTypeCardDetail.data ?? {});
          _data.MaThe = _res?.data?.ID;
          let _uu = await CardService.findUuDai({
            LoaiUuDai: "CK",
            MaLoaiThe: _res?.data?.MaLT,
          });
          if (_uu?.data) {
            setUudai(_uu.data);
            _data.SanPham?.map((item) => {
              item.TyLeCK = _uu?.data?.TyLeCK;
              item.TienCK = (item?.SoLuong * item?.DonGia * item.TyLeCK) / 100;
              item.ThanhTien =
                item.SoLuong * item.DonGia -
                (item?.SoLuong * item?.DonGia * item.TyLeCK) / 100;
            });
            SumTotal(_data);
          } else {
            _data.SanPham?.map((item) => {
              item.TyLeCK = 0;
              item.TienCK = 0;
              item.ThanhTien = item.SoLuong * item.DonGia - 0;
            });
            SumTotal(_data);
          }
          setData(_data);
        } else {
          let _data = _.cloneDeep(data);
          _data.MaKH = null;
          _data.MaThe = null;
          setData(_data);
          setDiemTien({});
          setCardType({});
          setUudai({});
          messageApi.open({
            type: "error",
            content: _res.message,
          });
        }
      }, 1000);
    }
    return () => clearTimeout(time);
  }, [data.MaVach]);
  const [note, setNote] = useState(null);
  const [ck, setCk] = useState({ Type: "VND", Value: 0 });
  const pay = (_data) => {
    let dt = _data || data;
    let thanhtien = _.sum(_.map(dt.SanPham, "ThanhTien"));
    let _payload = {
      ...dt,
      KhachHang: khachhang?.find((v) => v.MaKH === dt.MaKH),
      cardType: cardType,
      diemTien: diemTien,
      HinhThucTT: 3,
      ThanhTien: thanhtien,
      KhachTra: thanhtien,
      ThanhToan: thanhtien,
      TienThua: 0,
      MaCaLamViec: calamviec?.ID,
      MaQuayBan: calamviec?.MaQuay,
      TenQuayVT: quayve?.find((v) => v.ID === calamviec?.MaQuay)?.MaQuay,
      MaNhom: 2,
    };
    refPay.current.show(_payload);
  };
  const renderRight = () => {
    const appllyCK = (p, v) => {
      let _res = _.cloneDeep(ck);
      switch (p) {
        case "Value":
          _res[p] = v;
          if (_res.Type === "VND") {
            _res.TyLeCK = Number(
              (
                (refActive.current?.SoLuong * refActive.current?.DonGia) /
                v
              ).toFixed(2)
            );
            _res.TienCK = v;
          } else {
            _res.TienCK = Number(
              (
                (refActive.current?.SoLuong * refActive.current?.DonGia * v) /
                100
              ).toFixed(2)
            );
            _res.TyLeCK = v;
          }
          break;
        default:
          _res[p] = v;
          break;
      }
      console.log(_res);
      setCk(_res);
    };
    const handleOpenChange = async (item, newOpen) => {
      if (newOpen === true) {
        refActive.current = item;
      }
      if (newOpen === false) {
        setLoading(true);
        let _data = _.cloneDeep(data);
        let sp = _data.SanPham?.find((v) => v.ID === refActive.current?.ID);
        sp.TyLeCK = ck?.TyLeCK;
        sp.TienCK = ck?.TienCK;
        sp.ThanhTien =
          refActive.current?.SoLuong * refActive.current?.DonGia -
          (ck?.TienCK ?? 0);
        SumTotal(_data);
        let _res = await BanHangService.BanTheoBan.updateSanPham({
          ID: refActive.current?.ID,
          GhiChu: refActive.current?.GhiChu,
          SoLuong: refActive.current?.SoLuong,
          ThanhTien:
            refActive.current?.SoLuong * refActive.current?.DonGia -
            (ck?.TienCK ?? 0),
          TyLeCK: ck?.TyLeCK,
          TienCK: ck?.TienCK,
          ThanhTienBH: _data.ThanhTien,
          MaBH: data.MaBH,
        });
        setLoading(false);
        if (_res.status === 2000) {
          setData(_data);
          setCk({ Type: "PT", Value: 0 });
        }
      }
    };
    const content = () => {
      return (
        <Flex
          align="center"
          style={{ width: 300 }}
          gap={5}
          justify="space-between"
        >
          <p>Giảm giá</p>
          <InputNumber
            style={{ width: "40%" }}
            value={ck.Value}
            onChange={(v) => appllyCK("Value", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          <Button
            type={ck.Type === "VND" ? "primary" : "dashed"}
            onClick={() => appllyCK("Type", "VND")}
          >
            VND
          </Button>
          <Button
            type={ck.Type === "PT" ? "primary" : "dashed"}
            onClick={() => appllyCK("Type", "PT")}
          >
            %
          </Button>
        </Flex>
      );
    };
    const renderTop = () => {
      return (
        <Flex
          justify="flex-end"
          align="center"
          style={{ padding: "13px 0px" }}
          gap={10}
        >
          <p style={{ fontWeight: "900", color: "white", fontSize: 16 }}>
            {data?.TenBan}
          </p>
          {data?.TinhGio && (
            <>
              <p style={{ fontWeight: "bold", color: "white", fontSize: 14 }}>
                {Format_Datetime(data?.GioVao)}
              </p>
              <Button
                type="primary"
                icon={<CiTimer />}
                onClick={() => applyChange("AddDvGio")}
              >
                Thêm dịch vụ giờ
              </Button>
            </>
          )}

          <Button onClick={() => onClose()}>Thoát</Button>
        </Flex>
      );
    };
    const renderContent = () => {
      return (
        <div
          style={{
            overflow: "scroll",
          }}
          className="left-box-table"
        >
          <Flex style={{ padding: "10px" }} gap={10}>
            <Select
              value={data?.IsKhachHang}
              style={{
                width: "25%",
              }}
              fieldNames={{ label: "Name", value: "ID" }}
              onChange={(newValue) => applyChange("IsKhachHang", newValue)}
              options={[
                { ID: 1, Name: "Theo khách hàng" },
                { ID: 2, Name: "Theo mã thẻ" },
              ]}
            />
            {data?.IsKhachHang === 1 ? (
              <Flex
                align="center"
                style={{
                  width: "50%",
                }}
              >
                <Select
                  showSearch
                  placeholder="Tìm kiểm khách hàng"
                  value={data?.MaKH}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  style={{
                    width: "90%",
                  }}
                  fieldNames={{ label: "Name", value: "MaKH" }}
                  onChange={(newValue) => applyChange("MaKH", newValue)}
                  options={khachhang}
                />
                <Button
                  loading={loading}
                  style={{
                    borderRadius: 10,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  // type="primary"
                  // disabled={data[tab]?.SanPham?.length > 0 ? false : true}
                  onClick={() => refAddKH.current.show()}
                >
                  +
                </Button>
              </Flex>
            ) : (
              <Input
                style={{
                  width: "50%",
                }}
                value={data?.MaVach}
                placeholder="Nhập mã thẻ"
                onChange={(v) => applyChange("MaVach", v.target.value)}
              />
            )}

            <Select
              style={{
                width: "120px",
              }}
              value={data?.MaKho}
              onChange={(v) => applyChange("MaKho", v)}
              options={khohang}
              fieldNames={{ label: "TenKho", value: "ID" }}
            />
          </Flex>
          {data?.SanPham?.map((item, i) => {
            return (
              <Row
                gutter={[3]}
                style={{
                  borderBottom: "1px solid #F5F6F8",
                  padding: 5,
                }}
                align="middle"
                key={item.ID}
              >
                <Col span={13}>
                  <Flex align="center" style={{ paddingRight: 20 }}>
                    <Button
                      onClick={() => applyChange("Remove", i, item.ID)}
                      icon={<FaTrashAlt color="#4D5258" />}
                      type="text"
                    />
                    <p
                      className="text-overflow-ellipsis"
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        textOverflow: "ellipsis",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        color: "#000810",
                      }}
                    >
                      {i + 1}. {item.TenSP} ({Format_Currency(item.TienCK)})
                    </p>
                  </Flex>
                  {item.MaNhom === 7 && (
                    <Flex align="center" gap={10}>
                      <Tooltip placement="topRight" title="Tính tiền giờ">
                        <FcOvertime
                          color="green"
                          size={25}
                          style={{ cursor: "pointer", color: "green" }}
                          onClick={() => handleTinhGio(item)}
                        />
                      </Tooltip>
                      <p>
                        Giờ: {Format_Datetime(item?.GioVao)} -{" "}
                        {item.GioRa
                          ? new Date(item?.GioVao).getDate() ===
                            new Date(item.GioRa).getDate()
                            ? Format_Time(item.GioRa)
                            : Format_Datetime(item.GioRa)
                          : "HT"}
                      </p>
                    </Flex>
                  )}
                  <Flex
                    gap={20}
                    style={{ paddingLeft: 10, cursor: "pointer" }}
                    align="center"
                  >
                    <Popover
                      content={content()}
                      trigger="click"
                      onOpenChange={(v) => handleOpenChange(item, v)}
                    >
                      <Flex align="center">
                        <p style={{ fontSize: 12, color: "#85888C" }}>
                          {item.TyLeCK > 0 ? item.TyLeCK : "CK"}
                        </p>
                        <LuPercent />
                      </Flex>
                    </Popover>

                    <Flex
                      align="center"
                      gap={5}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        refActive.current = item;
                        setOpenNote(true);
                      }}
                    >
                      <CiStickyNote color="#85888C" size={15} />
                      <p style={{ fontSize: 12, color: "#85888C" }}>
                        {item?.GhiChu ? item.GhiChu : "Nhập ghi chú"}
                      </p>
                    </Flex>
                  </Flex>
                </Col>
                <Col span={3}>
                  <InputNumber
                    style={{ width: "100%" }}
                    value={item.SoLuong}
                    onChange={(v) => applyChange("SoLuong", v, item.MaSP)}
                  />
                </Col>
                <Col span={4}>{Format_Currency(item.DonGia)}</Col>
                <Col span={4}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(item.ThanhTien)}
                  </p>
                </Col>
              </Row>
            );
          })}
        </div>
      );
    };
    const renderBottom = () => {
      const renderNoteBanHang = () => {
        const save = async () => {
          setLoading(true);
          let _res = await BanHangService.BanTheoBan.updateDienGiai({
            ID: data.MaBH,
            DienGiai: data?.DienGiai,
            MaBan: data.MaBan,
          });
          if (_res.status === 2000) {
            setOpenNoteBH(false);
          }
          setLoading(false);
        };
        return (
          <Modal
            closeIcon={false}
            width={500}
            title="Ghi chú"
            open={openNoteBH}
            onOk={() => save()}
            onCancel={() => {
              setOpenNoteBH(false);
            }}
            cancelText="Bỏ qua"
            okText="Lưu lại"
          >
            <Input
              style={{ width: "100%" }}
              value={data?.DienGiai}
              onChange={(e) => applyChange("DienGiai", e.target.value)}
            />
          </Modal>
        );
      };
      const handleThanhToan = () => {
        let sanpham = data.SanPham.find((v) => v.MaNhom === 7 && !v.GioRa);
        if (sanpham) {
          handleTinhGio(sanpham, true);
          return;
        }
        pay();
      };
      return (
        <>
          <Flex
            align="center"
            justify="space-between"
            style={{
              padding: "10px 20px",
              background: "#e6f0fa",
            }}
          >
            <Flex gap={10}>
              <Flex
                align="center"
                gap={5}
                style={{
                  background: data?.DienGiai
                    ? "#1777ff"
                    : "rgba(0, 8, 16, 0.04)",
                  borderRadius: 20,
                  padding: "8px",
                  cursor: "pointer",
                  color: data?.DienGiai ? "white" : "black",
                }}
                onClick={() => setOpenNoteBH(true)}
              >
                <SlNote size={16} />
              </Flex>
              {checkAction(9) && (
                <Flex
                  align="center"
                  gap={5}
                  style={{
                    background: "rgba(0, 8, 16, 0.04)",
                    borderRadius: 20,
                    padding: "5px 10px",
                    cursor: "pointer",
                  }}
                  onClick={() => changeTable()}
                >
                  <RiTableAltLine size={20} />
                  <p>Ghép/Chuyền bàn</p>
                </Flex>
              )}
            </Flex>
            <p>
              Tổng tiền:{" "}
              <span style={{ fontWeight: "bold", fontSize: 18 }}>
                {/* {Format_Currency(data?.ThanhTien)} */}
                {Format_Currency(_.sum(_.map(data.SanPham, "ThanhTien")))}
              </span>
            </p>
          </Flex>
          <Flex
            align="center"
            gap={10}
            justify="center"
            style={{
              background: "#e6f0fa",
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              paddingBottom: 10,
            }}
          >
            {checkAction(8) && (
              <Button
                loading={loading}
                type="primary"
                // disabled={data[tab]?.SanPham?.length > 0 ? false : true}
                icon={<RiMoneyDollarCircleLine size={25} />}
                onClick={() => handleThanhToan()}
                className="btn-thanhtoan"
                style={{
                  width: "45%",
                }}
              >
                Thanh toán
              </Button>
            )}

            {checkAction(20) && (
              <Popconfirm
                title="Huỷ hoá đơn"
                description="Bạn có chắc chắn muốn huỷ hoá đơn?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => handleCancel()}
              >
                <Button
                  loading={loading}
                  icon={<MdOutlineCancel size={25} />}
                  className="btn-cancel"
                  style={{
                    width: "45%",
                  }}
                >
                  Huỷ hoá đơn
                </Button>
              </Popconfirm>
            )}
          </Flex>
          {renderNoteBanHang()}
        </>
      );
    };
    const renderNote = () => {
      const save = async () => {
        setLoading(true);
        let _res = await BanHangService.BanTheoBan.updateSanPham({
          ID: refActive.current?.ID,
          GhiChu: note,
          SoLuong: refActive.current?.SoLuong,
          ThanhTien: refActive.current?.ThanhTien,
          TyLeCK: refActive.current?.TyLeCK,
          TienCK: refActive.current?.TienCK,
        });
        if (_res.status === 2000) {
          let _data = _.cloneDeep(data);
          let sp = _data.SanPham?.find((v) => v.ID === refActive.current?.ID);
          sp.GhiChu = note;
          setData(_data);
          setOpenNote(false);
          setNote(null);
        }
        setLoading(false);
      };
      return (
        <Modal
          closeIcon={false}
          width={500}
          title="Ghi chú / Món thêm"
          open={openNote}
          onOk={() => save()}
          onCancel={() => {
            setOpenNote(false);
          }}
          cancelText="Bỏ qua"
          okText="Lưu lại"
        >
          <Input
            style={{ width: "100%" }}
            value={note || note === "" ? note : refActive.current?.GhiChu}
            onChange={(e) => setNote(e.target.value)}
          />
        </Modal>
      );
    };
    return (
      <div>
        {renderTop()}
        {renderContent()}
        {renderBottom()}
        {renderNote()}
      </div>
    );
  };
  const filterOption = (input, option) => {
    return (option?.Name ?? "").toLowerCase().includes(input.toLowerCase());
  };
  const renderHoaDon = () => {
    const onChangeLSP = (value) => {
      if (value.MaLSP === 0) {
        setSanpham(sanphamall);
      } else {
        let _sp = sanphamall?.filter((v) => v.MaLSP === value.MaLSP);
        setSanpham(_sp);
      }
      setLspActive(value);
    };
    const handleChooseSP = (v) => {
      let item = sanphamall.find((a) => a.ID === v);
      applyChange("ChooseClickSP", item);
    };
    const filterOptionSP = (input, option) => {
      return (option?.TenSP ?? "").toLowerCase().includes(input.toLowerCase());
    };
    return (
      <div className="boxhoadon">
        <div
          style={{
            marginBottom: 5,
          }}
        >
          <Row gutter={[30, 0]}>
            <Col md={16} xs={24}>
              <Flex style={{ padding: "13px 0px" }} gap={10}>
                <Select
                  value={bgActive}
                  style={{
                    width: "120px",
                  }}
                  fieldNames={{ label: "TenBangGia", value: "ID" }}
                  onChange={(v) => applyChangeBangGia(v)}
                  options={banggia}
                />
                <Select
                  showSearch
                  placeholder="Tìm kiếm sản phẩm"
                  optionFilterProp="children"
                  filterOption={filterOptionSP}
                  style={{
                    width: "30%",
                  }}
                  fieldNames={{ label: "TenSP", value: "ID" }}
                  onChange={(v) => handleChooseSP(v)}
                  options={sanphamall}
                />
              </Flex>
              <div className="right-box">
                <Flex
                  align="center"
                  gap={5}
                  style={{
                    marginBottom: 10,
                    overflowX: "scroll",
                    width: "100%",
                  }}
                >
                  {loaisp?.map((item) => {
                    return (
                      <Badge
                        key={item.MaLSP}
                        count={item.Total}
                        onClick={() => onChangeLSP(item)}
                      >
                        <div
                          style={{
                            // border: `1px solid #adc6ff`,
                            padding: "6px 15px",
                            cursor: "pointer",
                            borderRadius: 10,
                            background:
                              lspActive.MaLSP === item.MaLSP ? "#004b96" : null,
                            color:
                              lspActive.MaLSP === item.MaLSP ? "white" : null,
                          }}
                        >
                          <p
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: 14,
                              fontWeight: "600",
                            }}
                          >
                            {item.TenLSP}
                          </p>
                        </div>
                      </Badge>
                    );
                  })}
                </Flex>
                <Row gutter={[10, 10]} align="top" className="right-box-detail">
                  {sanpham.map((item) => {
                    return (
                      <Col xs={12} md={6} lg={4} xl={4}>
                        <Row
                          style={{
                            border: "1px solid rgb(217, 217, 217)",
                            cursor: "pointer",
                            // padding: 5,
                            height: "200px",
                            borderRadius: 10,
                          }}
                          // gutter={[0, 10]}
                          onClick={() =>
                            loading == false &&
                            applyChange("ChooseClickSP", item)
                          }
                        >
                          <Flex
                            style={{
                              height: 140,
                              width: "100%",
                              background: !item?.LinkImage && "#e6f0fa",
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10,
                            }}
                            justify="center"
                            align="center"
                          >
                            {item?.LinkImage ? (
                              <img
                                style={{ height: "140px", padding: "15px" }}
                                src={item.LinkImage}
                              />
                            ) : (
                              <MdOutlineFastfood
                                style={{
                                  height: "50px",
                                  width: "22%",
                                  color: "rgba(0, 102, 204, 0.5)",
                                }}
                              />
                            )}
                          </Flex>
                          {/* </Col> */}
                          <Flex
                            justify="center"
                            style={{
                              background: "rgba(0, 8, 16, 0.04)",
                              height: 60,
                              width: "100%",
                              borderBottomLeftRadius: 10,
                              borderBottomRightRadius: 10,
                            }}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: "600",
                                fontSize: "14px",
                                paddingTop: 5,
                              }}
                            >
                              {item.TenSP}
                            </p>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "13px",
                                color: "#0066CC",
                                position: "absolute",
                                top: 120,
                                right: "33%",
                                background: "rgba(255, 255, 255, 0.7)",
                                padding: 4,
                                borderRadius: 5,
                              }}
                            >
                              {Format_Currency(item.GiaBan)}
                            </p>
                          </Flex>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
            <Col md={8} xs={24} className="left-box">
              {renderRight()}
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  const renderBatDauCa = () => {
    const saveCaLamViec = async () => {
      setLoading(true);
      let _res = await BanHangService.addCaLamViec(dataquay);
      if (_res.status === 2000) {
        setOpenCaLV(false);
        setCalamviec({
          ID: _res.data,
          TienDauCa: dataquay.TienDauCa,
          GioVaoCa: new Date(),
          MaQuay: dataquay.MaQuay,
        });
        loadSanPham(dataquay.MaQuay);
      }
      setLoading(false);
    };
    const appllyChange = (p, v) => {
      let _data = _.cloneDeep(dataquay);
      _data[p] = v;
      setDataquay(_data);
    };
    return (
      <Modal
        closeIcon={false}
        title="KHAI BÁO TIỀN ĐẦU CA"
        open={openCaLV}
        onOk={() => saveCaLamViec()}
        onCancel={() => {
          setOpenCaLV(false);
          setOpen(false);
        }}
        cancelText="Huỷ"
        okText="Lưu"
      >
        <div>
          <p style={{ marginTop: 10 }}>Quầy bán</p>
          <Select
            style={{
              width: "100%",
            }}
            value={dataquay?.MaQuay}
            placeholder="Chọn quầy bán"
            onChange={(v) => appllyChange("MaQuay", v)}
            options={quayve}
            fieldNames={{ label: "TenQuay", value: "ID" }}
          />
          <p style={{ marginTop: 10 }}>Tiền đầu ca</p>
          <InputNumber
            suffix="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            placeholder="Nhập tiền đầu ca"
            style={{ width: "100%" }}
            onChange={(v) => appllyChange("TienDauCa", v)}
          />
        </div>
      </Modal>
    );
  };
  const handleTinhGio = async (item, ispay) => {
    setOpenTinhGio(true);
    let _tinhgio = {
      ...item,
      GioRa: item.GioRa
        ? item.GioRa
        : DateTime.now().setZone("Asia/Ho_Chi_Minh"),
      IsDungTinh: item.GioRa ? true : false,
      IsPay: ispay,
    };
    setTinhGio(_tinhgio);
    BanHangService.BanTheoBan.getLichSuTinhGio({ ID: item.ID }).then((res) => {
      setLsTinhGio(res.data);
    });
  };
  const renderTinhGio = () => {
    const save = async () => {
      applyChange("TinhGio");
      setTinhGio({});
      setOpenTinhGio(false);
    };
    const appllyChangeTime = (p, v) => {
      let _dataTab = _.cloneDeep(tinhGio);
      switch (p) {
        case "NgayVao":
          let time = new Date(v);
          _dataTab.GioVao = time;
          break;
        case "GioVao":
          let year = new Date(_dataTab.GioVao).getFullYear();
          let month = new Date(_dataTab.GioVao).getMonth();
          let day = new Date(_dataTab.GioVao).getDate();
          let min = new Date(_dataTab.GioVao).getMinutes();
          let time1 = new Date(year, month, day, v, min, 0);
          _dataTab.GioVao = time1;
          break;
        case "PhutVao":
          let year1 = new Date(_dataTab.GioVao).getFullYear();
          let month1 = new Date(_dataTab.GioVao).getMonth();
          let day1 = new Date(_dataTab.GioVao).getDate();
          let hour = new Date(_dataTab.GioVao).getHours();
          let time2 = new Date(year1, month1, day1, hour, v, 0);
          _dataTab.GioVao = time2;
          break;
        case "NgayRa":
          let time3 = new Date(v);
          _dataTab.GioRa = time3;
          break;
        case "GioRa":
          let year2 = new Date(_dataTab.GioRa).getFullYear();
          let month2 = new Date(_dataTab.GioRa).getMonth();
          let day2 = new Date(_dataTab.GioRa).getDate();
          let min2 = new Date(_dataTab.GioRa).getMinutes();
          let time4 = new Date(year2, month2, day2, v, min2, 0);
          _dataTab.GioRa = time4;
          break;
        case "PhutRa":
          let year3 = new Date(_dataTab.GioRa).getFullYear();
          let month3 = new Date(_dataTab.GioRa).getMonth();
          let day3 = new Date(_dataTab.GioRa).getDate();
          let hour3 = new Date(_dataTab.GioRa).getHours();
          let time5 = new Date(year3, month3, day3, hour3, v, 0);
          _dataTab.GioRa = time5;
          break;
      }
      setTinhGio(_dataTab);
    };
    return (
      <Modal
        closeIcon={false}
        title="TÍNH GIỜ"
        open={openTinhGio}
        width={700}
        onOk={() => save()}
        onCancel={() => {
          setOpenTinhGio(false);
        }}
        cancelText="Huỷ"
        okText={
          tinhGio.IsDungTinh
            ? "Tiếp tục"
            : tinhGio?.IsPay
            ? "Dừng tính và thanh toán"
            : "Dừng tính"
        }
      >
        <Flex gap={20} justify="space-between">
          <div style={{ width: "45%" }}>
            <Row align="middle" gutter={[15, 5]}>
              <Col span={8}>Ngày vào</Col>
              <Col span={16}>
                <DatePicker
                  allowClear={false}
                  format="DD-MM-YYYY"
                  style={{ width: "100%" }}
                  value={dayjs(tinhGio?.GioVao)}
                  onChange={(v) => appllyChangeTime("NgayVao", v)}
                />
              </Col>
              <Col span={8}>Giờ</Col>
              <Col span={16}>
                <InputNumber
                  style={{ width: "100%" }}
                  value={new Date(tinhGio?.GioVao).getHours()}
                  onChange={(v) => appllyChangeTime("GioVao", v)}
                />
              </Col>
              <Col span={8}>Phút</Col>
              <Col span={16}>
                <InputNumber
                  style={{ width: "100%" }}
                  value={new Date(tinhGio?.GioVao).getMinutes()}
                  onChange={(v) => appllyChangeTime("PhutVao", v)}
                />
              </Col>
            </Row>
          </div>
          <div style={{ width: "45%" }}>
            <Row align="middle" gutter={[15, 5]}>
              <Col span={8}>Ngày ra</Col>
              <Col span={16}>
                <DatePicker
                  disabled={tinhGio.IsDungTinh}
                  allowClear={false}
                  format="DD-MM-YYYY"
                  style={{ width: "100%" }}
                  value={
                    tinhGio?.GioRa ? dayjs(tinhGio?.GioRa) : dayjs(new Date())
                  }
                  onChange={(v) => appllyChangeTime("NgayRa", v)}
                />
              </Col>
              <Col span={8}>Giờ</Col>
              <Col span={16}>
                <InputNumber
                  disabled={tinhGio.IsDungTinh}
                  style={{ width: "100%" }}
                  value={
                    tinhGio?.GioRa
                      ? new Date(tinhGio?.GioRa).getHours()
                      : new Date().getHours()
                  }
                  onChange={(v) => appllyChangeTime("GioRa", v)}
                />
              </Col>
              <Col span={8}>Phút</Col>
              <Col span={16}>
                <InputNumber
                  disabled={tinhGio.IsDungTinh}
                  style={{ width: "100%" }}
                  value={
                    tinhGio?.GioRa
                      ? new Date(tinhGio?.GioRa).getMinutes()
                      : new Date().getMinutes()
                  }
                  onChange={(v) => appllyChangeTime("PhutRa", v)}
                />
              </Col>
            </Row>
          </div>
        </Flex>
        {lsTinhGio.length > 0 && (
          <>
            <p style={{ fontWeight: "500", fontSize: 16, paddingTop: 30 }}>
              Lịch sử
            </p>
            <Table
              dataSource={lsTinhGio}
              columns={columns}
              scroll={{
                y: window.innerHeight - 500,
                // x: 2000,
              }}
              pagination={false}
              size="small"
            />
          </>
        )}
      </Modal>
    );
  };
  const onAfterSubmitChangeTable = async (v) => {
    let _data = _.cloneDeep(data);
    let _checkBanHang = await BanHangService.BanTheoBan.check({
      MaBan: v.ID,
    });
    if (_checkBanHang?.data) {
      _data.MaBH = _checkBanHang.data?.id;
      _data.ThanhTien = _checkBanHang.data?.thanhTien;
      _data.SanPham = _checkBanHang.sanpham;
    }
    _data.MaBan = v.ID;
    setData(_data);
    onAfterSubmit();
  };
  const onSubmitPay = (maban, state) => {
    onClose();
    onAfterSubmit(maban, state);
  };
  const onAfterSubmitKH = async (value) => {
    let _data = _.cloneDeep(data);
    _data.KhachHang = value?.MaKH;
    _data.MaKH = value.MaKH;
    setData(_data);
    let _kh = await CustomerService.getListAll();
    _kh.data?.map((item) => (item.Name = item.TenCongTy + " - " + item.DiDong));
    setKhachhang(_kh?.data);
  };

  return (
    <Drawer
      width="100%"
      onClose={() => onClose()}
      open={open}
      className="bantheoban-detail"
    >
      {loadingScreen ? (
        <Flex align="center" justify="center" style={{ height: "100vh" }}>
          /
          <Spin size="large" style={{ color: "white" }} />
        </Flex>
      ) : (
        <>
          {renderHoaDon()}
          {renderBatDauCa()}
          {renderTinhGio()}
        </>
      )}

      <FormChangeTable
        ref={refChangeTable}
        onAfterSubmit={onAfterSubmitChangeTable}
      />
      <FormAddKH ref={refAddKH} onAfterSubmit={onAfterSubmitKH} />
      <FormPay ref={refPay} onAfterSubmit={onSubmitPay} />
      {contextHolder}
      {contextHolderMS}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
