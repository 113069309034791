import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Row,
  Col,
  Badge,
  Flex,
  Input,
  Tooltip,
  Modal,
  Tag,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import { Format_Currency } from "layouts/ultis/common";
import { CardService } from "services/CardService";
import { Format_Date } from "layouts/ultis/common";
import { MdEdit } from "react-icons/md";
import Toolbar from "components/Toolbar";
import { NhanVienService } from "services/NhanVienService";
import { DateTime } from "luxon";
import FormEdit from "./FormEdit";

export default function Automation() {
  const [data, setData] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const refAdd = useRef(null);
  const refEdit = useRef(null);
  const refAddExcel = useRef(null);
  const [api, contextHolder] = notification.useNotification();
  const [action, setAction] = useState([]);
  const [loading, setLoading] = useState(false);
  const now = DateTime.now().setZone("Asia/Ho_Chi_Minh");
  const startOfYear = now.startOf("year");
  const endOfYear = now.endOf("year");
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: startOfYear.toISO(),
    DenNgay: endOfYear.toISO(),
    InputSearch: "",
    MaCT: 0,
  });
  const loadcategories = async () => {
    NhanVienService.getAction({ FormID: 282 }).then((res) =>
      setAction(res?.data)
    );
  };
  const checkAction = (id) => {
    return action?.some((v) => v.FeatureID === id);
  };
  useEffect(() => {
    loadcategories();
  }, []);
  const loadBuyin = async (_filter) => {
    setLoading(true);
    let _res = await CardService.getCard(_filter);
    setDataAll(_res.data);
    setData(_res.data);
    setLoading(false);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const columns = [
    {
      title: "Tên automation",
      dataIndex: "TenAutomation",
    },

    {
      title: "Người tạo",
      dataIndex: "NguoiTao",
    },
    {
      title: "Nhóm automation",
      dataIndex: "NhomAutomation",
    },
    {
      title: "Loại",
      dataIndex: "Loai",
    },
    {
      title: "Trạng thái",
      dataIndex: "TrangThai",
      render: (item) =>
        item ? (
          <Tag color={item === "Đã kích hoạt" ? "green" : "red"}>{item}</Tag>
        ) : (
          ""
        ),
    },
    {
      title: "",
      fixed: "right",
      width: "60px",
      render: (item) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Tooltip placement="topRight" title={"Chỉnh sửa"}>
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refEdit.current.show(item)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];


  const fakeData = [
    {
      key: 1,
      TenAutomation: "Tự động hóa A",
      NguoiTao: "Nguyễn Văn A",
      NhomAutomation: "Nhóm 1",
      Loai: "Loại 1",
      TrangThai: "Đã kích hoạt",
    },
    {
      key: 2,
      TenAutomation: "Tự động hóa B",
      NguoiTao: "Trần Thị B",
      NhomAutomation: "Nhóm 2",
      Loai: "Loại 2",
      TrangThai: "Chưa kích hoạt",
    },
    {
      key: 3,
      TenAutomation: "Tự động hóa C",
      NguoiTao: "Phạm Văn C",
      NhomAutomation: "Nhóm 3",
      Loai: "Loại 3",
      TrangThai: "Đã kích hoạt",
    },
  ];
  const remove = async (value) => {
    let _res = await CardService.deleteCard({
      ID: value,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thẻ thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onAfterSubmit = () => {
    loadBuyin(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    loadBuyin(_filter);
    setFilterCondition(_filter);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        loadBuyin(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition]);
  const changeState = (malt) => {
    if (malt === 0) {
      setData(dataAll);
    } else {
      let _data = dataAll.filter((v) => v.MaLT === malt);
      setData(_data);
    }
  };
  return (
    <Row gutter={15}>
      {/* <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col> */}
      <Col span={24}>
        <Toolbar
          left={() => (
            <Input
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              placeholder="Tên automation"
              loading={loading}
              onChange={(e) => onSearch(e.target.value)}
              // enterButton
            />
          )}
          right={() => (
            <Flex align="center" gap={10}>
              {checkAction(16) && (
                <Button onClick={() => refAddExcel.current.show()}>
                  Import
                </Button>
              )}
              {checkAction(1) && (
                <Button type="primary" onClick={() => refAdd.current.show()}>
                  Thêm
                </Button>
              )}
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={fakeData}
          columns={columns}
          scroll={{
            y: window.innerHeight - 260,
            x: 1000,
          }}
          size="small"
        />
        {contextHolder}
        <FormEdit ref={refEdit} />
      </Col>
    </Row>
  );


  
}
