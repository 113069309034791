import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import _ from "lodash";
import DebounceSelect from "components/DebounceSelect";
import BuyinToPrint from "components/BuyinToPrint";
import { useReactToPrint } from "react-to-print";
import { CardService } from "services/CardService";
import { NhanVienService } from "services/NhanVienService";
import { CustomerService } from "services/CustomerService";

const { TextArea } = Input;
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [quyDanh, setQuyDanh] = useState([]);
  const [status, setStatus] = useState([]);
  const [nguon, setNguon] = useState([]);
  const [customerGroup, setCustomerGroup] = useState([]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [lydo, setLydo] = useState([]);
  const refPrint = useRef(null);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const loadCategories = async () => {
    let _ld = await CardService.getLyDoCongDiem();
    setLydo(_ld?.data ?? []);
    let _qd = await NhanVienService.getQuyDanh();
    setQuyDanh(_qd?.data ?? []);
    let _resgr = await CardService.getCustomerGroup();
    setCustomerGroup(_resgr?.data ?? []);

    let _tt = await CustomerService.getStatus();
    setStatus(_tt?.data ?? []);
    let _n = await CustomerService.getNguon();
    setNguon(_n?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaKH":
        if (v?.length > 0) {
          _data[p] = v;
          if (v?.length > 1) {
            _data[p] = v.splice(1);
          }
          _data.MaVach = JSON.parse(_data?.MaKH[0].title)?.MaVach;
          _data.SoThe = JSON.parse(_data?.MaKH[0].title)?.SoThe;
          _data.MaThe = JSON.parse(_data?.MaKH[0].title)?.MaThe;
        } else {
          _data[p] = [];
        }
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  async function fetchUserList(username) {
    return CardService.filterCustomer({ InputSearch: username }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenCongTy} - ${item.DiDong} - ${new Date(
          item.NgaySinh
        ).getDate()}/${new Date(item.NgaySinh).getMonth() + 1}/${new Date(
          item.NgaySinh
        ).getFullYear()}`,
        value: item.MaKH,
        title: JSON.stringify({
          MaVach: item.MaVach,
          MaThe: item.MaThe,
          SoThe: item.SoThe,
        }),
      }));
    });
  }
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    if (!_data.NgayCap) {
      delete _data.NgayCap;
    }
    let _res = null;
    if (_data.MaKH) {
      _res = await CustomerService.update(_data);
    } else {
      _res = await CustomerService.add(_data);
      _data.MaKH = _res.data;
      // setData((pre) => {
      //   return { ...pre, MaKH: _res.data };
      // });
    }
    setLoading(false);
    if (_res.status === 2000) {
      setData(_data);
      openNotification(
        "success",
        "topRight",
        `${_data.MaKH ? "Sửa" : "Thêm"} nhân viên thành công!`
      );
      if (close) onClose();
      onAfterSubmit(_data);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  
  return (
    <Drawer
      title="Thông tin hoá đơn"
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          {/* <Button onClick={() => onSubmit()}>Lưu</Button> */}
          <Button onClick={() => onSubmit(true)} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolderMS}
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={24} className="gutter-row">
          <p style={{ color: "red" }}>Họ và tên *</p>
          <Row gutter={[10]}>
            <Col span={6}>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaQD}
                placeholder="Chọn danh xưng"
                onChange={(v) => applyChange("MaQD", v)}
                options={quyDanh}
                fieldNames={{ label: "TenQD", value: "MaQD" }}
              />
            </Col>
            <Col span={18}>
              <Input
                value={data?.TenCongTy}
                placeholder="Nhập họ và tên"
                onChange={(v) => applyChange("TenCongTy", v.target.value)}
              />
            </Col>
          </Row>
          {/* <p style={{ color: "red" }}>{errors?.TenCongTy}</p> */}
        </Col>
        <Col span={12} className="gutter-row">
          <p>Di động</p>
          <Input
            value={data?.DiDong}
            placeholder="Nhập số điện thoại"
            onChange={(e) => applyChange("DiDong", e.target.value)}
          />
        </Col>
        <Col span={12} className="gutter-row">
          <p>Email</p>
          <Input
            value={data?.Email}
            placeholder="Nhập email"
            onChange={(e) => applyChange("Email", e.target.value)}
          />
        </Col>
        <Col span={24} className="gutter-row">
          <p>Địa chỉ</p>
          <Input
            value={data?.DiaChiCT}
            placeholder="Nhập địa chỉ"
            onChange={(e) => applyChange("DiaChiCT", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Ngày sinh</p>
          <DatePicker
            allowClear={false}
            placeholder="Nhập ngày sinh"
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.NgaySinh ? dayjs(data?.NgaySinh) : null}
            locale={vi}
            onChange={(v) => applyChange("NgaySinh", v)}
          />
        </Col>

        <Col span={12} className="gutter-row">
          <p>Nhóm khách hàng</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaNKH}
            placeholder="Chọn nhóm khách hàng"
            onChange={(v) => applyChange("MaNKH", v)}
            options={customerGroup}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={12} className="gutter-row">
          <p>Trạng thái</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaTT}
            placeholder="Chọn trạng thái khách hàng"
            onChange={(v) => applyChange("MaTT", v)}
            options={status}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={12} className="gutter-row">
          <p>Nguồn</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaNguon}
            placeholder="Chọn nguồn khách hàng"
            onChange={(v) => applyChange("MaNguon", v)}
            options={nguon}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={12} className="gutter-row">
          <p>Số CMND</p>
          <Input
            value={data?.SoCMND}
            placeholder="Nhập số cmnd"
            onChange={(e) => applyChange("SoCMND", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Ngày cấp</p>
          <DatePicker
            placeholder="Nhập ngày cấp"
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.NgayCap ? dayjs(data?.NgayCap) : null}
            locale={vi}
            onChange={(v) => applyChange("NgayCap", v)}
          />
        </Col>
        <Col span={12} className="gutter-row">
          <p>Nơi cấp</p>
          <Input
            value={data?.NoiCap}
            placeholder="Nhập nơi cấp"
            onChange={(e) => applyChange("NoiCap", e.target.value)}
          />
        </Col>
        <Col span={12} className="gutter-row">
          <p>Hộ chiếu</p>
          <Input
            value={data?.SoHoChieu}
            placeholder="Nhập số hộ chiếu"
            onChange={(e) => applyChange("SoHoChieu", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Ghi chú</p>
          <TextArea
            value={data?.GhiChu}
            onChange={(e) => applyChange("GhiChu", e.target.value)}
            placeholder="Nhập ghi chú"
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
