import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Row, Drawer, notification, Col, Flex } from "antd";
import _ from "lodash";
import { CRMService } from "services/CRMService";
import { BanHangService } from "services/BanHangService";
import { IoTabletLandscape } from "react-icons/io5";
import { Format_Datetime } from "layouts/ultis/common";
const FormChangeTable = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [ban, setBan] = useState([]);
  const [banhang, setBanHang] = useState({});

  const loadCategories = async (data) => {
    let _res = await BanHangService.BanTheoBan.getList({
      MaKV: data?.MaKV,
    });
    setBan(_res.data?.filter((v) => v.ID !== data?.MaBan));
  };
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      loadCategories(item);
      setBanHang(item);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const changeTable = async (item) => {
    if (loading) return;
    setLoading(true);
    if (item.MaBH) {
      let _res = await BanHangService.BanTheoBan.ghepTable({
        ID: banhang.MaBH,
        TuBan: banhang.MaBan,
        DenBan: item.ID,
      });
      if (_res.status === 2000) {
        openNotification("success", "topRight", `Ghép bàn thành công!`);
        onClose();
        onAfterSubmit(item);
      } else {
        openNotification("error", "topRight", _res?.message);
      }
    } else {
      let _res = await BanHangService.BanTheoBan.changeTable({
        ID: banhang.MaBH,
        MaBan: item.ID,
        GioVao: banhang.GioVao,
      });
      if (_res.status === 2000) {
        openNotification("success", "topRight", `Đổi bàn thành công!`);
        onClose();
        onAfterSubmit(item);
      } else {
        openNotification("error", "topRight", _res?.message);
      }
    }

    setLoading(false);
  };
  return (
    <Drawer
      title="Ghép/Chuyển bàn"
      width={900}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          marginTop: 20,
        },
      }}
    >
      {contextHolder}
      <Row gutter={[10]} style={{ marginTop: 20 }}>
        {ban?.map((item) => {
          return (
            <Col span={4} key={item.ID} style={{ marginBottom: 10 }}>
              <Flex
                vertical
                justify="center"
                align="center"
                onClick={() => changeTable(item)}
                style={{
                  height: "100px",
                  background: item.MaBH ? "#bae0ff" : "#f0f0f0",
                  borderRadius: 15,
                  // padding: 10,
                  cursor: "pointer",
                }}
              >
                <IoTabletLandscape
                  size={50}
                  color={item.MaBH ? "#4096ff" : "#bfbfbf"}
                />
                <p style={{ fontWeight: "bold" }}>{item.TenBan}</p>
              </Flex>
            </Col>
          );
        })}
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormChangeTable);
