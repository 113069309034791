import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Table, notification, Flex, Row, Col, Input } from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { SanPhamService } from "services/SanPhamService";
import { CardService } from "services/CardService";
import { Format_Datetime } from "layouts/ultis/common";
export default function Settings() {
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    InputSearch: "",
    MaCT: 0,
  });
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    // filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await CardService.getLichSuRaVao(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const columns = [
    {
      title: "Mã vạch",
      dataIndex: "MaVach",
    },
    {
      title: "Thời gian vào",
      dataIndex: "ThoiGianVao",
      render: (item) => Format_Datetime(item),
    },
    // {
    //   title: "Tên dịch vụ",
    //   dataIndex: "TenSP",
    // },
    // {
    //   title: "Loại dịch vụ",
    //   dataIndex: "TenLSP",
    // },
    {
      title: "Khách hàng",
      dataIndex: "TenKH",
    },
    {
      title: "Điện thoại",
      dataIndex: "DiDong",
    },
    // {
    //   title: "",
    //   fixed: "right",
    //   width: "50px",
    //   render: (item) => {
    //     return (
    //       <div>
    //         <Popconfirm
    //           title="Xoá sản phẩm"
    //           description="Bạn có chắc chắn muốn xoá sản phẩm?"
    //           okText="Đồng ý"
    //           cancelText="Không"
    //           onConfirm={() => remove(item.ID)}
    //         >
    //           <Tooltip placement="topRight" title="Xoá">
    //             <Button
    //               danger
    //               ghost
    //               icon={<CiTrash />}
    //             />
    //           </Tooltip>
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
  ];
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        filter(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              placeholder="Tìm kiếm"
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          right={() => (
            <Flex align="center" gap={10}>
              <p style={{ fontWeight: "bold" }}>Tổng: {data?.length}</p>
              <Button type="primary" onClick={() => refDetail.current.show()}>
                Thêm
              </Button>
            </Flex>
          )}
        />
        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            // x: 1500,
          }}
          size="small"
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100", "500", "1000"],
          }}
        />
      </Col>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Row>
  );
}
