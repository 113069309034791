import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  ColorPicker,
} from "antd";
import _ from "lodash";
import { TaiLieuService } from "services/TaiLieuService";
const { TextArea } = Input;
const FormAddLoaiTL = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
    },
    update: async (data) => {
      setOpen(true);
      console.log(data);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const onSubmit = async () => {
    let _data = _.cloneDeep(data);
    let _res = await TaiLieuService.Loai.add(_data);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Lưu thành công!");
      onClose();
      onAfterSubmit({
        ..._data,
        ID: _res.data ? _res.data : _data.ID,
        isEdit: _data.ID ? true : false,
      });
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Drawer
      title="Loại tài liệu"
      width={300}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button onClick={() => onSubmit()}>Lưu</Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={24}>
          <p>Tên loại tài liệu</p>
          <Input
            value={data?.Name}
            onChange={(e) => applyChange("Name", e.target.value)}
            style={{ marginTop: 15 }}
            placeholder="Nhập tên loại"
          />
        </Col>
        <Col span={24}>
          <ColorPicker
            value={data?.Color}
            showText
            onChange={(color) =>
              applyChange("Color", color.toHexString())
            }
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAddLoaiTL);
