import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Row,
  Col,
  Badge,
  Flex,
  Input,
  Tooltip,
  Modal,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { CardService } from "services/CardService";
import { Format_Date } from "layouts/ultis/common";
import { MdEdit, MdMoreTime } from "react-icons/md";
import Toolbar from "components/Toolbar";
import FormDetail from "./FormDetail";
import { NhanVienService } from "services/NhanVienService";
import FormAddExcel from "./FormAddExcel";
import { DateTime } from "luxon";
import FormGiaHanThe from "./FormGiaHanThe";

export default function Settings() {
  const [data, setData] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const refAdd = useRef(null);
  const refDetail = useRef(null);
  const refAddExcel = useRef(null);
  const refGiaHan = useRef(null);
  const [api, contextHolder] = notification.useNotification();
  const [type, setType] = useState({
    ID: 0,
    TenLT: "Tất cả",
    Color: "gray",
  });
  const [action, setAction] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const now = DateTime.now().setZone("Asia/Ho_Chi_Minh");
  const startOfYear = now.startOf("year");
  const endOfYear = now.endOf("year");
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: startOfYear.toISO(),
    DenNgay: endOfYear.toISO(),
    InputSearch: "",
    MaCT: 0,
  });
  const loadcategories = async () => {
    NhanVienService.getAction({ FormID: 282 }).then((res) =>
      setAction(res?.data)
    );
  };
  const checkAction = (id) => {
    return action?.some((v) => v.FeatureID === id);
  };
  useEffect(() => {
    loadcategories();
  }, []);
  const loadBuyin = async (_filter) => {
    setLoading(true);
    let _res = await CardService.getCard(_filter);
    let _type = await CardService.getType();
    let types =
      [{ ID: 0, TenLT: "Tất cả", Color: "cccccc" }, ..._type.data] ?? [];
    types.map((item) => {
      if (item.ID === 0) {
        item.Total = _res.data?.length;
      } else {
        item.Total = _res?.data?.filter((v) => v.MaLT === item.ID)?.length;
      }
    });
    setTypes(types);
    setDataAll(_res.data);
    setData(_res.data);
    setLoading(false);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const handleGiaHanThe = async (item) => {
    refGiaHan.current.update(item);
  };

  const columns = [
    {
      title: "Số thẻ",
      dataIndex: "SoThe",
    },
    {
      title: "Mã vạch",
      // dataIndex: "MaVach",
      render: (item) => {
        return (
          <p
            onClick={() => refDetail.current.show(item)}
            style={{ fontWeight: "bold", cursor: "pointer", color: "#597ef7" }}
          >
            {item.MaVach}
          </p>
        );
      },
    },
    {
      title: "Loại thẻ",
      dataIndex: "TenLT",
    },
    {
      title: "Trạng thái",
      dataIndex: "TenTT",
    },
    {
      title: "Ngày phát hàng",
      dataIndex: "NgayPH",
      render: (item) => Format_Date(item),
    },
    {
      title: "Ngày hiệu lực",
      dataIndex: "NgayHL",
      render: (item) => Format_Date(item),
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "NgayHH",
      render: (item) => Format_Date(item),
    },
    {
      title: "Số ngày còn lại",
      dataIndex: "SoLuotConLai",
    },
    {
      title: "Điểm tích luỹ",
      dataIndex: "DiemThe",
    },
    {
      title: "Tổng tiền",
      dataIndex: "TienThe",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Số dư thẻ",
      dataIndex: "SoDuThe",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenCongTy",
      width: "200px",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      render: (item) => Format_Date(item),
    },
    {
      title: "CMND",
      dataIndex: "SoCMND",
    },
    {
      title: "Di động",
      dataIndex: "DiDong",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
    },
    {
      title: "",
      fixed: "right",
      width: "130px",
      render: (item) => {
        const isExpired =
          new Date(item?.NgayHH).getTime() < new Date().getTime();
        console.log(isExpired);

        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {checkAction(2) &&
              (isExpired ? (
                <Tooltip placement="topRight" title="Gia hạn thẻ">
                  <Button
                    style={{ color: "green", border: "1px solid green" }}
                    ghost
                    icon={<MdMoreTime />}
                    onClick={() => handleGiaHanThe(item)}
                  />
                </Tooltip>
              ) : (
                <Popconfirm
                  title="Gia hạn thẻ"
                  description="Thẻ chưa hết hạn bạn có chắc chắn gia hạn không?"
                  okText="Đồng ý"
                  cancelText="Không"
                  onConfirm={() => handleGiaHanThe(item)}
                >
                  <Button
                    style={{ color: "green", border: "1px solid green" }}
                    ghost
                    icon={<MdMoreTime />}
                  />
                </Popconfirm>
              ))}
            {checkAction(2) && (
              <Tooltip placement="topRight" title={"Sửa thẻ"}>
                <Button
                  type="primary"
                  ghost
                  icon={<MdEdit />}
                  onClick={() => refAdd.current.update(item)}
                />
              </Tooltip>
            )}

            {checkAction(3) && (
              <Popconfirm
                title="Xoá"
                description="Bạn có chắc chắn muốn xoá thẻ hay không?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => remove(item.ID)}
              >
                <Button danger ghost icon={<CiTrash />} />
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await CardService.deleteCard({
      ID: value,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thẻ thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  

  const onAfterSubmit = () => {
    loadBuyin(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    loadBuyin(_filter);
    setFilterCondition(_filter);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        loadBuyin(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition]);
  const changeState = (malt) => {
    if (malt === 0) {
      setData(dataAll);
    } else {
      let _data = dataAll.filter((v) => v.MaLT === malt);
      setData(_data);
    }
  };
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          setData={setData}
          data={data}
          dataAll={dataAll}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              placeholder="Tìm kiếm mã, tên sản phẩm"
              loading={loading}
              onChange={(e) => onSearch(e.target.value)}
              // enterButton
            />
          )}
          right={() => (
            <Flex align="center" gap={10}>
              {types?.map((item) => {
                return (
                  <Badge
                    key={item.ID}
                    count={item.Total}
                    onClick={() => {
                      setType(item);
                      changeState(item.ID);
                    }}
                  >
                    <div
                      style={{
                        border: `1px solid #adc6ff`,
                        padding: "6px 15px",
                        cursor: "pointer",
                        borderRadius: 10,
                        background: type.ID === item.ID ? "#adc6ff" : null,
                        color: type.ID === item.ID ? "black" : null,
                      }}
                    >
                      <p>{item.TenLT}</p>
                    </div>
                  </Badge>
                );
              })}
              {checkAction(16) && (
                <Button onClick={() => refAddExcel.current.show()}>
                  Import
                </Button>
              )}
              {checkAction(1) && (
                <Button type="primary" onClick={() => refAdd.current.show()}>
                  Thêm
                </Button>
              )}
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 260,
            x: 2500,
          }}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={8}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData.length}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TienThe")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "SoDuThe")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />

        <FormAdd ref={refAdd} onAfterSubmit={onAfterSubmit} />
        <FormDetail ref={refDetail} />
        <FormAddExcel ref={refAddExcel} onAfterSubmit={onAfterSubmit} />
        <FormGiaHanThe ref={refGiaHan} onAfterSubmit={onAfterSubmit} />
        {contextHolder}
      </Col>
    </Row>
  );
}
