import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Col, DatePicker, Popover, Row, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { BanHangService } from "services/BanHangService";
import { NhanVienService } from "services/NhanVienService";
import ActiveRoute from "components/ActiveRoute";
import DebounceSelect from "components/DebounceSelect";
import { CustomerService } from "services/CustomerService";
import FilterDate from "components/FilterDate";
import ChiNhanhSelect from "components/ChiNhanhSelect";

const FilterModal = ({ ...props }) => {
  const { companies, filterCondition, onChangeFilter } = props;
  const [quay, setQuay] = useState([]);
  const [nhanvien, setNhanvien] = useState([]);
  const [loaiHD, setLoaiHD] = useState([]);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    console.log(v);

    switch (p) {
      case "KhachHang":
        let arrkey = _.map(v, "key");
        _filter[p] = arrkey?.length > 0 ? "," + arrkey.join(",") + "," : ",,";
        break;
      case "ListMaCT":
      case "QuayBan":
      case "LoaiHD":
      case "NhanVien":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      case "MaCT":
        _filter[p] = v ? v : 0;
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(() => {
    BanHangService.getQuayBan().then((res) => setQuay(res.data ?? []));
    BanHangService.Categories.getLoaiHD().then((res) =>
      setLoaiHD(res.data ?? [])
    );
    NhanVienService.getNhanVien().then((res) => setNhanvien(res.data ?? []));
  }, []);
  async function fetchUserList(username) {
    return CustomerService.filter({ InputSearch: username }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenCongTy} - ${item.DiDong}`,
        value: item.MaKH,
        title: JSON.stringify(item),
      }));
    });
  }
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      <ChiNhanhSelect
        FormID={405}
        onChange={(v) => applyChangeFilter("MaCT", v)}
      />
      <div className="box-search">
        <p className="label">Quầy bán</p>
        <Select
          mode="multiple"
          placeholder="Chọn quầy bán"
          onChange={(v) => applyChangeFilter("QuayBan", v)}
          style={{
            width: "100%",
          }}
          options={quay}
          fieldNames={{ label: "TenQuay", value: "ID" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Nhân viên</p>
        <Select
          mode="multiple"
          placeholder="Chọn nhân viên"
          onChange={(v) => applyChangeFilter("NhanVien", v)}
          style={{
            width: "100%",
          }}
          options={nhanvien}
          fieldNames={{ label: "HoTen", value: "MaNV" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Loại HD</p>
        <Select
          mode="multiple"
          allowClear
          placeholder="Chọn loại HD"
          onChange={(v) => applyChangeFilter("LoaiHD", v)}
          style={{
            width: "100%",
          }}
          options={loaiHD}
          fieldNames={{ label: "TenLHD", value: "MaLHD" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Khách hàng</p>
        <DebounceSelect
          mode="multiple"
          // value={data[activeTab]?.KhachHang}
          placeholder="Tìm kiểm khách hàng"
          fetchOptions={fetchUserList}
          onChange={(newValue) => applyChangeFilter("KhachHang", newValue)}
          style={{
            width: "100%",
          }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
