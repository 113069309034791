import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Table,
  Tooltip,
  notification,
  Row,
  Col,
  Tag,
  Input,
  Popconfirm,
  Flex,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CSVLink } from "react-csv";
import { IoEyeSharp } from "react-icons/io5";
import _ from "lodash";
import "./scss/index.scss";
import Toolbar from "components/Toolbar";
import { BanHangService } from "services/BanHangService";
import Search from "antd/es/input/Search";
import { GiConfirmed } from "react-icons/gi";
import { CRMService } from "services/CRMService";
export default function Settings() {
  const state = {
    NEW: "Mới",
    PAYMENT: "Đã thanh toán",
    CANCEL: "Đã huỷ",
    REJECT: "Từ chối",
    ACCEPT: "Đã duyệt",
    DONE: "Đã xuất vé",
  };
  const stateColor = {
    NEW: "blue",
    PAYMENT: "cyan",
    CANCEL: "orange",
    REJECT: "red",
    ACCEPT: "green",
    DONE: "#1677ff",
  };
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    InputSearch: "",
  });
  const refDetail = useRef(null);
  const refSettingBlind = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await BanHangService.getListVeOnline(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const columns = [
    {
      title: "Ngày đặt vé",
      dataIndex: "NgayBH",
      render: (item) =>
        new Date(item).getDate() +
        "-" +
        (new Date(item).getMonth() + 1) +
        "-" +
        new Date(item).getFullYear(),
    },
    {
      title: "Mã vé",
      dataIndex: "SoBH",
      width: 150,
    },
    {
      title: "Trạng thái",
      dataIndex: "State",
      render: (item) => <Tag color={stateColor[item]}>{state[item]}</Tag>,
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenCongTy",
    },
    {
      title: "Số điện thoại",
      dataIndex: "DiDong",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Tổng tiền",
      dataIndex: "ThanhTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Số lượng vé",
      dataIndex: "SoLuongThe",
    },
    {
      title: "Đã sử dụng",
      dataIndex: "DaSuDung",
    },
    {
      title: "Còn lại",
      render: (item) => item?.SoLuongThe - item?.DaSuDung,
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
    },
    {
      title: "",
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex gap={5} justify="center">
            {(item.State === "PAYMENT" || item.State === "NEW") && (
              <Tooltip placement="topRight" title="Duyệt vé">
                <Popconfirm
                  title="Duyệt"
                  description="Bạn có chắc chắn muốn duyệt hay không?"
                  okText="Đồng ý"
                  cancelText="Không"
                  onConfirm={() => onAccept(item)}
                >
                  <Button icon={<GiConfirmed color="green" />} />
                </Popconfirm>
              </Tooltip>
            )}

            <Tooltip placement="topRight" title="Chi tiết">
              <Button
                type="primary"
                ghost
                icon={<IoEyeSharp />}
                onClick={() => refDetail.current.show(item)}
              />
            </Tooltip>
          </Flex>
        );
      },
    },
  ];
  const onAccept = async (value) => {
    let _res = await BanHangService.duyetVeBan({
      ID: value.ID,
      TongTien: value.ThanhTien,
      MaKH: value.MaKH,
      LyDoPT: "Duyệt vé online",
    });
    if (_res.status === 2000) {
      filter(filterCondition);
      CRMService.Zalo.Send({
        Phone: value?.DiDong,
        TenKH: value?.TenCongTy,
        KyHieuKH: value?.DiDong,
        MaVe: value?.SoBH,
        TempID: "5920",
      });
      openNotification("success", "topRight", "Duyệt thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        filter(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              placeholder="Tìm kiếm tên, điện thoại, mã vé"
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          right={() => (
            <Button type="primary">
              <CSVLink filename={"ticket-online.csv"} data={data}>
                Export
              </CSVLink>
            </Button>
          )}
        />
        <Table
          style={{
            height: 100,
          }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            x: 2000,
          }}
          size="small"
        />
      </Col>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Row>
  );
}
