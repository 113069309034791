import React, { useEffect,  useState } from "react";
import { Table, notification, Row, Col,  Button } from "antd";
import FilterModal from "./FilterModal";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import "./scss/index.scss";
import Toolbar from "components/Toolbar";
import { ReportService } from "services/ReportService";
import ExcelJS from "exceljs";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    MaNhom: 2,
    MaCT: 0,
    QuayBan: ",,",
    NhanVien: ",,",
    LoaiHD: ",,",
    KhachHang: ",,",
  });
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await ReportService.getBanHangTheoChiTietSP(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const columns = [
    {
      title: "Ngày bán hàng",
      dataIndex: "NgayBH",
      render: (item) => Format_Date(item),
    },
    {
      title: "Số hoá đơn",
      dataIndex: "SoBH",
    },
    {
      title: "Quầy bán",
      dataIndex: "TenQuay",
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
    },

    {
      title: "Khách hàng",
      dataIndex: "TenCongTy",
    },
    {
      title: "Số điện thoại",
      dataIndex: "DiDong",
    },
    {
      title: "Hình thức TT",
      dataIndex: "TenHinhThuc",
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "TenSP",
    },
    {
      title: "Số lượng",
      dataIndex: "SoLuong",
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Thành tiền",
      dataIndex: "ThanhTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền CK",
      dataIndex: "TienCK",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tổng sau CK",
      dataIndex: "TongSauCK",
      render: (item) => Format_Currency(item),
    },
  ];

  const exportExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("BaoCao");
    sheet.properties.defaultRowHeight = 20;
    sheet.columns = [
      {
        header: "Ngày bán hàng",
        key: "ngaybh",
        width: 15,
        font: {
          bold: true,
          size: 24,
        },
      },
      {
        header: "Số hoá đơn",
        key: "sohd",
        width: 20,
      },
      {
        header: "Quầy bán",
        key: "quayban",
        width: 10,
      },
      {
        header: "Nhân viên",
        key: "nhanvien",
        width: 10,
      },
      {
        header: "Khách hàng",
        key: "khachhang",
        width: 15,
      },
      {
        header: "Di động",
        key: "didong",
        width: 15,
      },

      {
        header: "Hình thức TT",
        key: "tenhinhthuc",
        width: 8,
      },
      {
        header: "Tên sản phẩm",
        key: "tensp",
        width: 20,
      },
      {
        header: "Số lượng",
        key: "soluong",
        width: 8,
      },
      {
        header: "Đơn giá",
        key: "dongia",
        width: 15,
      },
      {
        header: "Thành tiền",
        key: "thanhtien",
        width: 15,
        numFmt: "#.##0",
      },
      {
        header: "Tiền CK",
        key: "tienck",
        width: 15,
      },
      {
        header: "Tổng sau CK",
        key: "tongsauck",
        width: 15,
      },
    ];
    data?.map((item) => {
      sheet.addRow({
        ngaybh: Format_Date(item.NgayBH),
        sohd: item.SoBH,
        quayban: item.TenQuay,
        nhanvien: item.HoTen,
        khachhang: item.TenCongTy,
        didong: item.DiDong,
        tenhinhthuc: item.TenHinhThuc,
        tensp: item.TenSP,
        soluong: item.SoLuong,
        dongia: item.DonGia,
        thanhtien: item.ThanhTien,
        tienck: item.TienCK,
        tongsauck: item.TongSauCK,
      });
    });
    sheet.addRow({
      ngaybh: "Tổng: " + data.length,
      soluong: _.sumBy(data, (o) => o.SoLuong),
      thanhtien: _.sumBy(data, (o) => o.ThanhTien),
      tienck: _.sumBy(data, (o) => o.TienCK),
      tongsauck: _.sumBy(data, (o) => o.TongSauCK),
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "baocaobanhang_chitietsanpham.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          right={() => (
            <Button type="primary" onClick={() => exportExcel()}>
              Export
            </Button>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            x: 2000
          }}
          size="small"
          pagination={{
            defaultPageSize: 100,
            showSizeChanger: true,
            pageSizeOptions: ["100", "500", "5000"],
          }}
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={8}>
                    <p style={{ fontWeight: "bold" }}>Tổng : {data?.length}</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "SoLuong")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "ThanhTien")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TienCK")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TongSauCK")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
      {contextHolder}
    </Row>
  );
}
