import React, { useState, useRef } from "react";
import {
  Button,
  InputNumber,
  Row,
  Col,
  Select,
  Flex,
  Modal,
  Input,
  Popover,
} from "antd";
import _ from "lodash";
import { FaTrashAlt } from "react-icons/fa";
import { Format_Currency } from "layouts/ultis/common";
import { BanHangService } from "services/BanHangService";
import FormAddKH from "../../../CRM/KhachHang/FormAdd";
import { CiStickyNote } from "react-icons/ci";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { LuPercent } from "react-icons/lu";
import "../scss/index.scss";
import { SlNote } from "react-icons/sl";
const FormRight = (props) => {
  const {
    data,
    setData,
    applyChange,
    handlePay,
    khoHang,
    khachHang,
    onAfterSubmitKH,
    onClose,
  } = props;
  const refAddKH = useRef(null);
  const [openNote, setOpenNote] = useState(false);
  const [openNoteBH, setOpenNoteBH] = useState(false);
  const [loading, setLoading] = useState(false);
  const refActive = useRef(null);
  const [note, setNote] = useState(null);
  const [ck, setCk] = useState({ Type: "PT", Value: 0 });
  const SumTotal = (_data) => {
    _data.ThanhTien = _data?.SanPham?.reduce((pre, current) => {
      return (pre += current.ThanhTien);
    }, 0);
    _data.TienCK = (_data.ThanhTien * (_data?.TyLeCK ?? 0)) / 100;
  };
  const appllyCK = (p, v) => {
    let _res = _.cloneDeep(ck);
    switch (p) {
      case "Value":
        _res[p] = v;
        if (_res.Type === "VND") {
          _res.TyLeCK = Number(
            (
              (refActive.current?.SoLuong * refActive.current?.DonGia) /
              v
            ).toFixed(2)
          );
          _res.TienCK = v;
        } else {
          _res.TienCK = Number(
            (
              (refActive.current?.SoLuong * refActive.current?.DonGia * v) /
              100
            ).toFixed(2)
          );
          _res.TyLeCK = v;
        }
        break;
      default:
        _res[p] = v;
        break;
    }
    setCk(_res);
  };
  const handleOpenChange = async (item, newOpen) => {
    if (newOpen === true) {
      refActive.current = item;
    }
    if (newOpen === false) {
      let _data = _.cloneDeep(data);
      let sp = _data.SanPham?.find((v) => v.MaSP === refActive.current?.MaSP);
      sp.TyLeCK = ck?.TyLeCK;
      sp.TienCK = ck?.TienCK;
      sp.ThanhTien =
        refActive.current?.SoLuong * refActive.current?.DonGia -
        (ck?.TienCK ?? 0);
      SumTotal(_data);
      setData(_data);
      setCk({ Type: "PT", Value: 0 });
    }
  };
      const [diengiai, setDiengiai] = useState(null);

  const renderRight = () => {
    const content = () => {
      return (
        <Flex
          align="center"
          style={{ width: 300 }}
          gap={5}
          justify="space-between"
        >
          <p>Giảm giá</p>
          <InputNumber
            style={{ width: "40%" }}
            value={ck.Value}
            onChange={(v) => appllyCK("Value", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          <Button
            type={ck.Type === "VND" ? "primary" : "dashed"}
            onClick={() => appllyCK("Type", "VND")}
          >
            VND
          </Button>
          <Button
            type={ck.Type === "PT" ? "primary" : "dashed"}
            onClick={() => appllyCK("Type", "PT")}
          >
            %
          </Button>
        </Flex>
      );
    };
    const renderTop = () => {
      return (
        <Flex
          justify="flex-end"
          align="center"
          style={{ padding: "13px 0px" }}
          gap={10}
        >
          <Button onClick={() => onClose()}>Thoát</Button>
        </Flex>
      );
    };
    const renderContent = () => {
      return (
        <div
          style={{
            overflow: "scroll",
          }}
          className="left-box-table"
        >
          <Flex style={{ padding: "10px" }} gap={10}>
            <Select
              value={data?.IsKhachHang}
              style={{
                width: "25%",
              }}
              fieldNames={{ label: "Name", value: "ID" }}
              onChange={(newValue) => applyChange("IsKhachHang", newValue)}
              options={[
                { ID: 1, Name: "Theo khách hàng" },
                { ID: 2, Name: "Theo mã thẻ" },
              ]}
            />
            {data?.IsKhachHang === 1 ? (
              <Flex
                align="center"
                style={{
                  width: "50%",
                }}
              >
                <Select
                  showSearch
                  placeholder="Tìm kiểm khách hàng"
                  value={data?.MaKH}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  style={{
                    width: "90%",
                  }}
                  fieldNames={{ label: "Name", value: "MaKH" }}
                  onChange={(newValue) => applyChange("MaKH", newValue)}
                  options={khachHang}
                />
                <Button
                  loading={loading}
                  style={{
                    borderRadius: 10,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  onClick={() => refAddKH.current.show()}
                >
                  +
                </Button>
              </Flex>
            ) : (
              <Input
                style={{
                  width: "50%",
                }}
                value={data?.MaVach}
                placeholder="Nhập mã thẻ"
                onChange={(v) => applyChange("MaVach", v.target.value)}
              />
            )}

            <Select
              style={{
                width: "120px",
              }}
              value={data?.MaKho}
              onChange={(v) => applyChange("MaKho", v)}
              options={khoHang}
              fieldNames={{ label: "TenKho", value: "ID" }}
            />
          </Flex>
          {data?.SanPham?.map((item, i) => {
            return (
              <Row
                gutter={[3]}
                style={{
                  borderBottom: "1px solid #F5F6F8",
                  padding: 5,
                }}
                align="middle"
                key={item.ID}
              >
                <Col span={13}>
                  <Flex align="center" style={{ paddingRight: 20 }}>
                    <Button
                      onClick={() => applyChange("Remove", i, item.ID)}
                      icon={<FaTrashAlt color="#4D5258" />}
                      type="text"
                    />
                    <p
                      className="text-overflow-ellipsis"
                      style={{
                        fontWeight: "bold",
                        textOverflow: "ellipsis",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        color: "#000810",
                      }}
                    >
                      {i + 1}. {item.TenSP} ({Format_Currency(item.TienCK)})
                    </p>
                  </Flex>
                  <Flex
                    gap={20}
                    style={{ paddingLeft: 10, cursor: "pointer" }}
                    align="center"
                  >
                    <Popover
                      content={content()}
                      trigger="click"
                      onOpenChange={(v) => handleOpenChange(item, v)}
                    >
                      <Flex align="center">
                        <p style={{ fontSize: 12, color: "#85888C" }}>
                          {item.TyLeCK > 0 ? item.TyLeCK : "CK"}
                        </p>
                        <LuPercent />
                      </Flex>
                    </Popover>

                    <Flex
                      align="center"
                      gap={5}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        refActive.current = item;
                        setOpenNote(true);
                      }}
                    >
                      <CiStickyNote color="#85888C" size={15} />
                      <p style={{ fontSize: 12, color: "#85888C" }}>
                        {item?.GhiChu ? item.GhiChu : "Nhập ghi chú"}
                      </p>
                    </Flex>
                  </Flex>
                </Col>
                <Col span={3}>
                  <InputNumber
                    style={{ width: "100%" }}
                    value={item.SoLuong}
                    onChange={(v) => applyChange("SoLuong", v, item.MaSP)}
                  />
                </Col>
                <Col span={4}>{Format_Currency(item.DonGia)}</Col>
                <Col span={4}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(item.ThanhTien)}
                  </p>
                </Col>
              </Row>
            );
          })}
        </div>
      );
    };
    const renderBottom = () => {
      const renderNoteBanHang = () => {
        const save = async () => {
            applyChange("DienGiai", diengiai);
            setOpenNoteBH(false);
        };
        return (
          <Modal
            closeIcon={false}
            width={500}
            title="Ghi chú"
            open={openNoteBH}
            onOk={() => save()}
            onCancel={() => {
              setOpenNoteBH(false);
            }}
            cancelText="Bỏ qua"
            okText="Lưu lại"
          >
            <Input
              style={{ width: "100%" }}
              value={diengiai !== null ? diengiai : data?.DienGiai}
              onChange={(e) => setDiengiai(e.target.value)}
            />
          </Modal>
        );
      };
      return (
        <div
          style={{
            padding: "0px 20px",
            background: "#e6f0fa",
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <Flex
            align="center"
            justify="space-between"
            style={{
              padding: "10px 0px",
            }}
          >
            <Flex gap={10}>
              <Flex
                align="center"
                gap={5}
                style={{
                  background: data?.DienGiai
                    ? "#1777ff"
                    : "rgba(0, 8, 16, 0.04)",
                  borderRadius: 20,
                  padding: "8px",
                  cursor: "pointer",
                  color: data?.DienGiai ? "white" : "black",
                }}
                onClick={() => setOpenNoteBH(true)}
              >
                <SlNote size={16} />
              </Flex>
            </Flex>
            <p>
              Tổng tiền:{" "}
              <span style={{ fontWeight: "bold", fontSize: 18 }}>
                {Format_Currency(_.sum(_.map(data.SanPham, "ThanhTien")))}
              </span>
            </p>
          </Flex>
          <Flex
            align="center"
            gap={10}
            justify="center"
            style={{
              paddingBottom: 10,
            }}
          >
            <Button
              loading={loading}
              type="primary"
              icon={<RiMoneyDollarCircleLine size={25} />}
              onClick={() => handlePay()}
              className="btn-thanhtoan"
              style={{
                width: "100%",
              }}
            >
              Thanh toán
            </Button>
            {/* <Popconfirm
              title="Huỷ hoá đơn"
              description="Bạn có chắc chắn muốn huỷ hoá đơn?"
              okText="Đồng ý"
              cancelText="Không"
              // onConfirm={() => handleCancel()}
            >
              <Button
                loading={loading}
                icon={<MdOutlineCancel size={25} />}
                className="btn-cancel"
                style={{
                  width: "45%",
                }}
              >
                Huỷ hoá đơn
              </Button>
            </Popconfirm> */}
          </Flex>
          {renderNoteBanHang()}
        </div>
      );
    };
    const renderNote = () => {
      const save = async () => {
        let _data = _.cloneDeep(data);
        let sp = _data.SanPham?.find((v) => v.MaSP === refActive.current?.MaSP);
        sp.GhiChu = note;
        setData(_data);
        setOpenNote(false);
        setNote(null);
      };
      return (
        <Modal
          closeIcon={false}
          width={500}
          title="Ghi chú / Món thêm"
          open={openNote}
          onOk={() => save()}
          onCancel={() => {
            setOpenNote(false);
          }}
          cancelText="Bỏ qua"
          okText="Lưu lại"
        >
          <Input
            style={{ width: "100%" }}
            value={note || note === "" ? note : refActive.current?.GhiChu}
            onChange={(e) => setNote(e.target.value)}
          />
        </Modal>
      );
    };
    return (
      <div>
        {renderTop()}
        {renderContent()}
        {renderBottom()}
        {renderNote()}
      </div>
    );
  };
  const filterOption = (input, option) => {
    return (option?.Name ?? "").toLowerCase().includes(input.toLowerCase());
  };
  return (
    <Col md={8} xs={24} className="left-box">
      {renderRight()}
      <FormAddKH ref={refAddKH} onAfterSubmit={onAfterSubmitKH} />
    </Col>
  );
};
export default React.memo(FormRight);
