import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  InputNumber,
  Row,
  Col,
  Drawer,
  notification,
  Tabs,
  Select,
  Flex,
  Modal,
  Popconfirm,
  Tag,
  DatePicker,
} from "antd";
import _ from "lodash";
import DebounceSelect from "components/DebounceSelect";
import { FaTrashAlt } from "react-icons/fa";
import { SanPhamService } from "services/SanPhamService";
import TabPane from "antd/es/tabs/TabPane";
import { Format_Currency } from "layouts/ultis/common";
import { CustomerService } from "services/CustomerService";
import { BanHangService } from "services/BanHangService";
import { CardService } from "services/CardService";
import { OtherService } from "services/OtherService";
import HoaDonToPrint from "./HoaDonToPrint";
import KetCaPrint from "./KetCaPrint";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import locale from "antd/es/date-picker/locale/vi_VN";
import dayjs from "dayjs";
import FormAddKH from "../../CRM/KhachHang/FormAdd";
const FormAdd = (props, ref) => {
  const history = useHistory();
  const refSoLuong = useRef();
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [diemTien, setDiemTien] = useState({});
  const [hinhthuc, setHinhThuc] = useState([]);
  const [banggia, setBanggia] = useState([]);
  const [nguonKH, setNguonKH] = useState([]);
  const [khachhang, setKhachhang] = useState([]);
  const refAddKH = useRef(null);
  const [cardType, setCardType] = useState({});
  const componentRef = useRef();
  const ketcaRef = useRef();
  const defaultTab = {
    SanPham: [],
    KhachHang: null,
    HinhThucTT: 3,
    The: [],
    LoaiVeXuat: "QR",
    NgayBH: new Date(),
    KhachDua: 0,
  };
  const defaulData = {
    ["0"]: defaultTab,
  };
  const [data, setData] = useState(defaulData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialItems = [
    {
      title: "Hoá đơn",
      key: "0",
      closable: false,
    },
  ];
  const [tabs, setTabs] = useState(initialItems);
  const [activeTab, setActiveTab] = useState(initialItems[0].key);
  const newTabIndex = useRef(0);
  const [sanpham, setSanpham] = useState([]);
  const [quayve, setQuayve] = useState([]);
  const [dataquay, setDataquay] = useState({});
  const [calamviec, setCalamviec] = useState({});
  const [openCaLV, setOpenCaLV] = useState(false);
  const [openKTCa, setOpenKTCa] = useState(false);
  const [dhtheoca, setDhtheoca] = useState([]);
  const [mabanggia, setMabanggia] = useState(0);
  const [openPay, setOpenPay] = useState(false);

  const loadcategories = async () => {
    let _ht = await OtherService.getHinhThucTT();
    setHinhThuc(_ht.data ?? []);
    let _qv = await BanHangService.getQuayBan();
    setQuayve(_qv.data ?? []);
    let _dataquay = _.cloneDeep(dataquay);
    _dataquay.MaQuay = _qv.isDefault;
    setDataquay(_dataquay);
    let _kh = await CustomerService.getListAll();
    _kh.data?.map((item) => (item.Name = item.TenCongTy + " - " + item.DiDong));
    setKhachhang(_kh?.data);
  };

  const [dataPrint, setDataPrint] = useState({});
  useImperativeHandle(ref, () => ({
    show: async () => {
      let _data = _.cloneDeep(data);
      setOpen(true);
      loadcategories();
      let _kh = await CustomerService.getNguon();
      setNguonKH(_kh?.data ?? []);
      if (_kh.data?.length > 0) _data[activeTab].MaNguonKH = _kh.data[0].ID;
      let _check = await BanHangService.checkCaLamViec();
      if (!_check.data) {
        setOpenCaLV(true);
      } else {
        setCalamviec(_check.data);
      }
      let _bg = await SanPhamService.Categories.getBangGia({ ApDung: true });
      if (_bg.data?.length > 0) {
        setBanggia(_bg.data ?? []);
        let _day = new Date().getDay();
        let id = 0;
        if (_day === 0 || _data === 6) {
          id = _bg.data?.find((v) => v.isCuoiTuan === true)?.ID;
        } else {
          id = _bg.data?.find((v) => v.MacDinh === true)?.ID;
        }
        _data["0"].MaBG = id;
        setMabanggia(id);
        let _res = await SanPhamService.Categories.getSanPhamByMaBG({
          MaBG: id,
          InputSearch: "",
        });
        setSanpham(_res.data ?? []);
      }
      setData(_data);
    },
  }));

  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    onAfterSubmit();
  };
  const SumTotal = (_data) => {
    _data[activeTab].ThanhTien = _data[activeTab]?.SanPham?.reduce(
      (pre, current) => {
        return (pre += current.ThanhTien);
      },
      0
    );
    _data[activeTab].TienCK =
      (_data[activeTab].ThanhTien * (_data[activeTab]?.TyLeCK ?? 0)) / 100;
    _data[activeTab].KhachTra =
      _data[activeTab].ThanhTien - _data[activeTab].TienCK;
    _data[activeTab].ThanhToan =
      _data[activeTab].ThanhToan > _data[activeTab].KhachTra
        ? _data[activeTab].KhachTra
        : _data[activeTab].ThanhToan;
  };
  const applyChange = async (p, v, MaSP) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "ChooseSP":
        _data[p] = [];
        if (!_data[activeTab]) _data[activeTab] = defaultTab;
        let sp = JSON.parse(v[0].title);
        let spds = _data[activeTab].SanPham?.find((v) => v.MaSP === sp.ID);
        if (spds) {
          spds.SoLuong++;
        } else {
          _data[activeTab].SanPham.push({
            MaSP: sp.ID,
            TenSP: sp.TenSP,
            DonGia: sp.GiaBan,
            SoLuong: 1,
            TyLeCK: 0,
            TienCK: 0,
            ThanhTien: sp.GiaBan,
            MaGDV: sp.MaGDV,
            SoLanQuet: sp.SoLanQuet,
          });
        }
        SumTotal(_data);
        break;
      case "ChooseClickSP":
        _data[p] = [];
        if (!_data[activeTab]) _data[activeTab] = defaultTab;
        let spdsnew = _data[activeTab].SanPham?.find((f) => f.MaSP === v.ID);
        if (spdsnew) {
          spdsnew.SoLuong++;
          spdsnew.TienCK =
            (spdsnew?.SoLuong * spdsnew?.DonGia * spdsnew?.TyLeCK) / 100;

          spdsnew.ThanhTien =
            spdsnew.SoLuong * spdsnew.DonGia -
            (spdsnew?.SoLuong * spdsnew?.DonGia * spdsnew.TyLeCK) / 100;
        } else {
          _data[activeTab].SanPham.push({
            MaSP: v.ID,
            TenSP: v.TenSP,
            DonGia: v.GiaBan,
            SoLuong: 1,
            TyLeCK: 0,
            TienCK: 0,
            ThanhTien: v.GiaBan,
            MaGDV: v.MaGDV,
            SoLanQuet: v.SoLanQuet,
          });
        }
        SumTotal(_data);
        break;
      case "KhachHang":
        if (v) {
          console.log(v);
          _data[activeTab][p] = v;
          _data[activeTab].MaKH = v;
          let kh = khachhang?.find((a) => a.MaKH === v);
          _data[activeTab].TenKH = kh?.TenCongTy;
          let _the = await CardService.getByMaKH({ MaKH: v });
          let _res = await CardService.getDiem({
            MaThe: _the?.data?.ID,
          });
          let _resTypeCardDetail = await CardService.getLoaiTheDetail({
            ID: _the?.data?.MaLT,
          });
          setDiemTien(_res.data ?? {});
          setCardType(_resTypeCardDetail.data ?? {});
          _data[activeTab].MaThe = _the?.data?.ID;
        } else {
          _data[activeTab][p] = null;
        }
        break;
      case "SoLuong":
        let sl = _data[activeTab].SanPham?.find((v) => v.MaSP === MaSP);
        sl[p] = v;
        sl.TienCK = (sl?.SoLuong * sl?.DonGia * sl?.TyLeCK) / 100;
        sl.ThanhTien =
          sl.SoLuong * sl.DonGia - (sl?.SoLuong * sl?.DonGia * sl.TyLeCK) / 100;
        SumTotal(_data);
        break;
      case "TyLeCK":
        let item = _data[activeTab].SanPham?.find((v) => v.MaSP === MaSP);
        item[p] = v;
        item.TienCK = (item?.SoLuong * item?.DonGia * v) / 100;
        item.ThanhTien =
          item.SoLuong * item.DonGia -
          (item?.SoLuong * item?.DonGia * item.TyLeCK) / 100;
        SumTotal(_data);
        break;
      case "Remove":
        _data[activeTab].SanPham?.splice(v, 1);
        SumTotal(_data);
        break;
      default:
        _data[activeTab][p] = v;
        break;
    }
    setData(_data);
  };
  const applyChangeTotal = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaBG":
        _data[activeTab][p] = v;
        let _res = await SanPhamService.Categories.getSanPhamByMaBG({
          MaBG: v,
          InputSearch: "",
        });
        setSanpham(_res.data ?? []);
        setMabanggia(v);
        break;
      default:
        _data[activeTab][p] = v;
        SumTotal(_data);
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async () => {
    setLoading(true);
    let _dataAll = _.cloneDeep(data);
    let _data = _dataAll[activeTab];
    let totalVe = _.sumBy(_data?.SanPham, "SoLuong");
    if (totalVe !== _data.The?.length && _data.LoaiVeXuat === "CARD") {
      openNotification(
        "error",
        "topRight",
        `Nhập đủ số lượng thẻ. Tổng: ${totalVe}`
      );
      setLoading(false);
      return;
    }
    if (cardType?.TyGiaDiem) {
      _data.DiemTich = _data.KhachTra / cardType?.TyGiaDiem;
    }
    _data.MaCaLamViec = calamviec?.ID;
    _data.MaQuayBan = calamviec?.MaQuay;
    _data.TenQuayVT = quayve.find((v) => v.ID === calamviec?.MaQuay)?.MaQuay;
    _data.MaNhom = 1;
    let _res = await BanHangService.BanVeKinhDoanh.addBanHang(_data);
    setLoading(false);
    if (_res.status === 2000) {
      setDataPrint({
        SoHD: _res.data,
        TenQuay: quayve?.find((v) => v.ID === calamviec?.MaQuay)?.TenQuay,
      });
      handlePrint();
      openNotification("success", "topRight", `Thanh toán thành công!`);
      _dataAll[activeTab] = {
        ...defaultTab,
        MaBG: mabanggia,
        LoaiVeXuat: _data.LoaiVeXuat,
        MaNguonKH: _data.MaNguonKH,
      };
      setData(_dataAll);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchSanPham(username) {
    return SanPhamService.Categories.getSanPhamByMaBG({
      InputSearch: username,
      MaBG: data[activeTab]?.MaBG,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenSP}`,
        value: item.ID,
        title: JSON.stringify(item),
      }));
    });
  }
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
  };
  const add = async () => {
    const newActiveKey = `${(newTabIndex.current += 1)}`;
    const newPanes = [...tabs];
    newPanes.push({
      title: "Hoá đơn",
      key: newActiveKey,
    });
    let _data = _.cloneDeep(data);
    _data[newActiveKey] = {
      ...defaultTab,
      MaNguonKH: nguonKH[0].ID,
      MaBG: mabanggia,
    };
    setData(_data);
    setTabs(newPanes);
    setActiveTab(newActiveKey);
  };
  const remove = (targetKey) => {
    let newActiveKey = activeTab;
    let lastIndex = -1;
    tabs.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = tabs.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    let _data = _.cloneDeep(data);
    delete _data[targetKey];
    setData(_data);
    setTabs(newPanes);
    setActiveTab(newActiveKey);
  };
  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePrintKetCa = useReactToPrint({
    content: () => ketcaRef.current,
  });
  const renderBatDauCa = () => {
    const saveCaLamViec = async () => {
      setLoading(true);
      let _res = await BanHangService.addCaLamViec(dataquay);
      if (_res.status === 2000) {
        setOpenCaLV(false);
        setCalamviec({
          ID: _res.data,
          TienDauCa: dataquay.TienDauCa,
          GioVaoCa: new Date(),
          MaQuay: dataquay.MaQuay,
        });
      }
      setLoading(false);
    };
    const appllyChange = (p, v) => {
      let _data = _.cloneDeep(dataquay);
      _data[p] = v;
      setDataquay(_data);
    };
    return (
      <Modal
        closeIcon={false}
        title="KHAI BÁO TIỀN ĐẦU CA"
        open={openCaLV}
        onOk={() => saveCaLamViec()}
        onCancel={() => {
          setOpenCaLV(false);
          setOpen(false);
        }}
        cancelText="Huỷ"
        okText="Lưu"
      >
        <div>
          <p style={{ marginTop: 10 }}>Quầy bán</p>
          <Select
            style={{
              width: "100%",
            }}
            value={dataquay?.MaQuay}
            placeholder="Chọn quầy bán"
            onChange={(v) => appllyChange("MaQuay", v)}
            options={quayve}
            fieldNames={{ label: "TenQuay", value: "ID" }}
          />
          <p style={{ marginTop: 10 }}>Tiền đầu ca</p>
          <InputNumber
            suffix="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            placeholder="Nhập tiền đầu ca"
            style={{ width: "100%" }}
            onChange={(v) => appllyChange("TienDauCa", v)}
          />
        </div>
      </Modal>
    );
  };

  const renderKetThucCa = () => {
    const logout = async () => {
      localStorage.removeItem("token");
      localStorage.removeItem("url");
      history.push("/auth/sign-in");
    };
    const saveKetThucCa = async () => {
      setLoading(true);
      let _res = await BanHangService.addKetThucCa({
        ID: calamviec?.ID,
      });
      if (_res.status === 2000) {
        handlePrintKetCa();
        setOpenKTCa(false);
        setOpen(false);
        logout();
      }
      setLoading(false);
    };
    return (
      <Modal
        closeIcon={false}
        width={900}
        title="KHAI BÁO TIỀN KẾT THÚC CA"
        open={openKTCa}
        onOk={() => saveKetThucCa()}
        onCancel={() => {
          setOpenKTCa(false);
        }}
        cancelText="Huỷ"
        okText="Kết ca"
      >
        <Row gutter={[20]}>
          <Col span={12}>
            <p style={{ marginTop: 10, color: "red" }}>Tiền đầu ca (1)</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={calamviec?.TienDauCa}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Tổng hoá đơn</p>
            <InputNumber
              disabled
              value={dhtheoca?.length}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Tiền hàng</p>
            <InputNumber
              suffix="vnđ"
              disabled
              value={dhtheoca?.reduce((pre, cur) => (pre += cur.ThanhTien), 0)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Chiết khấu</p>
            <InputNumber
              suffix="vnđ"
              disabled
              value={dhtheoca?.reduce((pre, cur) => (pre += cur.TienCK), 0)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Doanh thu</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={dhtheoca?.reduce((pre, cur) => (pre += cur.KhachTra), 0)}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10, color: "red" }}>Tiền mặt (2)</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={dhtheoca
                ?.filter((v) => v.HinhThucTT === 3)
                ?.reduce((pre, cur) => (pre += cur.KhachTra), 0)}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Ngân hàng</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={dhtheoca
                ?.filter((v) => v.HinhThucTT === 2)
                ?.reduce((pre, cur) => (pre += cur.KhachTra), 0)}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10, color: "red" }}>
              Tổng tiền mặt cuối ca = (1)+(2)
            </p>
            <InputNumber
              disabled
              suffix="vnđ"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={
                (dhtheoca
                  ?.filter((v) => v.HinhThucTT === 3)
                  ?.reduce((pre, cur) => (pre += cur.KhachTra), 0) ?? 0) +
                calamviec?.TienDauCa
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  };
  const handleKetThucCa = async () => {
    let _res = await BanHangService.getDonHangTheoCa({ MaCa: calamviec?.ID });
    setDhtheoca(_res.data ?? []);
    setOpenKTCa(true);
  };
  const renderModalConfirmPay = () => {
    const hideModal = () => {
      setOpenPay(false);
    };
    return (
      <Modal
        title="Xác nhận"
        open={openPay}
        onOk={() => {
          onSubmit();
          setOpenPay(false);
        }}
        onCancel={hideModal}
        okText="Đồng ý"
        cancelText="Huỷ"
      >
        <p>Bạn có chắc chắn thanh toán hoá đơn</p>
      </Modal>
    );
  };
  const onPayConfirm = () => {
    setOpenPay(true);
  };
  const onAfterSubmitKH = async (value) => {
    let _data = _.cloneDeep(data);
    _data[activeTab].KhachHang = value?.MaKH;
    _data[activeTab].MaKH = value.MaKH;
    setData(_data);
    let _kh = await CustomerService.getListAll();
    _kh.data?.map((item) => (item.Name = item.TenCongTy + " - " + item.DiDong));
    setKhachhang(_kh?.data);
  };
  const filterOption = (input, option) => {
    // console.log(option);
    return (option?.Name ?? "").toLowerCase().includes(input.toLowerCase());
  };
  const renderHoaDon = (tab) => {
    return (
      <Row gutter={[10, 20]} style={{ height: "100%" }}>
        <Col span={18} style={{ height: "100%" }}>
          <Flex
            gap="middle"
            vertical
            justify="space-between"
            style={{ height: "100%" }}
          >
            <div style={{ height: "400px", overflow: "scroll" }}>
              {(!data[tab] || data[tab]?.SanPham?.length === 0) && (
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "10%",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Vui lòng chọn sản phẩm!
                </p>
              )}
              {data[tab]?.SanPham?.map((item, i) => {
                return (
                  <Row
                    style={{
                      border: "1px solid #d9d9d9",
                      padding: 5,
                      borderRadius: 5,
                      marginBottom: 5,
                    }}
                    align="middle"
                    key={item.ID}
                  >
                    <Col span={1}>{i + 1}</Col>
                    <Col span={1}>
                      <div
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => applyChange("Remove", i)}
                      >
                        <FaTrashAlt />
                      </div>
                    </Col>
                    <Col span={10}>
                      {item.TenSP} - {Format_Currency(item.DonGia)}đ
                    </Col>
                    <Col span={3}>
                      <InputNumber
                        value={item.SoLuong}
                        suffix="SL"
                        onChange={(v) => applyChange("SoLuong", v, item.MaSP)}
                      />
                    </Col>
                    <Col span={3}>
                      <InputNumber
                        suffix="% CK"
                        value={item.TyLeCK}
                        onChange={(v) => applyChange("TyLeCK", v, item.MaSP)}
                      />
                    </Col>
                    <Col span={3}> {Format_Currency(item.TienCK)}</Col>
                    <Col span={3}>{Format_Currency(item.ThanhTien)}</Col>
                  </Row>
                );
              })}
            </div>
            <Flex
              gap="middle"
              justify="flex-start"
              style={{
                height: "400px",
                borderTop: "1px solid rgb(217, 217, 217)",
                paddingTop: "5px",
                overflow: "scroll",
              }}
              wrap="wrap"
            >
              {sanpham.map((item) => {
                return (
                  <div
                    style={{
                      width: "150px",
                      height: "140px",
                      border: "1px solid rgb(217, 217, 217)",
                      cursor: "pointer",
                    }}
                    onClick={() => applyChange("ChooseClickSP", item)}
                  >
                    {item?.LinkImage ? (
                      <img
                        style={{ height: "70px", width: "100%" }}
                        src={item.LinkImage}
                      />
                    ) : (
                      <img
                        style={{ height: "70px", width: "100%" }}
                        src={require("../../../../assets/img/ticket.jpeg")}
                      />
                    )}

                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        padding: "5px",
                      }}
                    >
                      {item.TenSP}
                    </p>
                    <Tag bordered={false} color="green">
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {Format_Currency(item.GiaBan)} đ
                      </p>
                    </Tag>
                  </div>
                );
              })}
            </Flex>
          </Flex>
        </Col>
        <Col
          span={6}
          style={{
            borderLeft: "1px solid rgb(217, 217, 217)",
            // height: "100%",
          }}
        >
          <Flex vertical justify="space-between" style={{ height: "100%" }}>
            <div style={{ width: "100%" }} className="top-right">
              <Flex gap={5}>
                <Select
                  showSearch
                  placeholder="Tìm kiếm khách hàng"
                  value={data[activeTab]?.KhachHang}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  style={{
                    width: "100%",
                  }}
                  fieldNames={{ label: "Name", value: "MaKH" }}
                  onChange={(newValue) => applyChange("KhachHang", newValue)}
                  options={khachhang}
                />
                {/* <DebounceSelect
                  mode="multiple"
                  value={data[activeTab]?.KhachHang}
                  placeholder="Tìm kiểm khách hàng"
                  fetchOptions={fetchUserList}
                  onChange={(newValue) => applyChange("KhachHang", newValue)}
                  style={{
                    width: "100%",
                  }}
                /> */}
                <Button
                  loading={loading}
                  // type="primary"
                  // disabled={data[tab]?.SanPham?.length > 0 ? false : true}
                  onClick={() => refAddKH.current.show()}
                >
                  +
                </Button>
              </Flex>
              <Flex gap={20} style={{ margin: "6px 0px" }}>
                <DatePicker
                  defaultValue={dayjs(new Date())}
                  style={{ width: "50%" }}
                  allowClear={false}
                  format="DD-MM-YYYY"
                  locale={locale}
                  value={data[tab]?.NgayBH ? dayjs(data[tab]?.NgayBH) : null}
                  placeholder="Chọn ngày SD"
                  onChange={(v) => applyChangeTotal("NgayBH", v)}
                />
                <Select
                  style={{
                    width: "50%",
                  }}
                  placeholder="Chọn nguồn khách"
                  value={data[tab]?.MaNguonKH}
                  onChange={(v) => applyChangeTotal("MaNguonKH", v)}
                  options={nguonKH}
                  fieldNames={{ label: "Name", value: "ID" }}
                />
              </Flex>
              <p
                style={{ fontWeight: "bold", fontSize: 16, padding: "5px 0px" }}
              >
                Điểm thẻ: {Format_Currency(diemTien?.diemThe)} - Tiền thẻ:{" "}
                {Format_Currency(diemTien?.tienThe)}
              </p>
              <Flex justify="space-between" style={{ marginBottom: 8 }}>
                <p style={{ fontSize: 16 }}>Tổng chưa CK</p>
                <p style={{ fontSize: 16 }}>
                  {Format_Currency(data[tab]?.ThanhTien)}
                </p>
              </Flex>
              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 8 }}
              >
                <p style={{ fontSize: 16 }}>Chiết khấu (%)</p>
                <Flex
                  style={{ width: "50%" }}
                  justify="space-between"
                  align="center"
                >
                  <InputNumber
                    ref={refSoLuong}
                    // suffix="%"
                    max={100}
                    // placeholder="Tỷ lệ CK"
                    style={{ width: "35%" }}
                    value={data[tab]?.TyLeCK}
                    onChange={(v) => applyChangeTotal("TyLeCK", v)}
                  />
                  <p style={{ fontSize: 16 }}>
                    {Format_Currency(data[tab]?.TienCK)}
                  </p>
                </Flex>
              </Flex>
              {cardType.DiemTien && (
                <>
                  <Col span={12}>
                    <InputNumber
                      suffix="điểm"
                      max={diemTien?.diemThe}
                      placeholder="Trừ điểm"
                      style={{ width: "80%" }}
                      value={data[tab]?.DiemTru}
                      onChange={(v) => applyChangeTotal("DiemTru", v)}
                    />
                  </Col>
                  <Col span={12}>
                    {Format_Currency(data[tab]?.DiemTru * cardType.DiemTien)}
                  </Col>
                </>
              )}
              {diemTien.tienThe > 0 && (
                <>
                  <Col span={12}>
                    <InputNumber
                      suffix="vnđ"
                      max={diemTien?.tienThe}
                      placeholder="Trừ tiền thẻ"
                      style={{ width: "80%" }}
                      value={data[tab]?.TienTru}
                      onChange={(v) => applyChangeTotal("TienTru", v)}
                    />
                  </Col>
                  <Col span={12}>{Format_Currency(data[tab]?.TienTru)}</Col>
                </>
              )}

              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 8 }}
              >
                <p style={{ fontWeight: "bold", fontSize: 16 }}>
                  Tổng phải trả
                </p>
                <p style={{ fontWeight: "bold", fontSize: 16 }}>
                  {Format_Currency(data[tab]?.KhachTra)}

                  {/* {Format_Currency(
                    data[tab]?.SanPham?.reduce((pre, current) => {
                      return (pre += current.ThanhTien);
                    }, 0) -
                      (data[tab]?.DiemTru ?? 0) * (cardType.DiemTien ?? 0) -
                      (data[tab]?.TienTru ?? 0) -
                      (data[tab]?.SanPham?.reduce((pre, current) => {
                        return (pre += current.ThanhTien);
                      }, 0) *
                        (data[tab]?.TyLeCK ?? 0)) /
                        100
                  )} */}
                </p>
              </Flex>
              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 8 }}
              >
                <p style={{ fontWeight: "bold", fontSize: 16 }}>Khách trả</p>
                <InputNumber
                  style={{ width: "50%" }}
                  value={data[tab]?.ThanhToan}
                  onChange={(v) => applyChangeTotal("ThanhToan", v)}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              </Flex>
              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 8 }}
              >
                <p style={{ fontSize: 16 }}>Hình thức thanh toán</p>
                <Select
                  style={{
                    width: "50%",
                  }}
                  value={data[tab]?.HinhThucTT}
                  placeholder="Chọn hình thức"
                  onChange={(v) => applyChangeTotal("HinhThucTT", v)}
                  options={hinhthuc}
                  fieldNames={{ label: "TenHinhThuc", value: "ID" }}
                />
              </Flex>
            </div>
            <Flex
              align="flex-end"
              gap={10}
              justify="center"
              style={{ height: "100px" }}
            >
              <Button
                loading={loading}
                type="primary"
                disabled={
                  data[tab]?.SanPham?.length > 0 && data[tab]?.KhachHang
                    ? false
                    : true
                }
                // style={{ width: "100%" }}
                onClick={() => onPayConfirm()}
              >
                Thanh toán
              </Button>
              <Button danger onClick={() => handleKetThucCa()}>
                Kết thúc ca
              </Button>
            </Flex>
          </Flex>
        </Col>
      </Row>
    );
  };
  return (
    <Drawer
      title="Bán vé"
      width="100%"
      onClose={onClose}
      open={open}
      className="banve-detail"
    >
      {contextHolder}
      <Tabs
        onEdit={onEdit}
        type="editable-card"
        onChange={onChange}
        activeKey={activeTab}
        tabBarExtraContent={{
          left: (
            <Flex gap={10}>
              <Select
                style={{
                  width: "200px",
                }}
                value={data[activeTab]?.MaBG}
                // placeholder="Chọn hình thức"
                onChange={(v) => applyChangeTotal("MaBG", v)}
                options={banggia}
                fieldNames={{ label: "TenBangGia", value: "ID" }}
              />
              <DebounceSelect
                mode="multiple"
                value={data?.ChooseSP}
                placeholder="Tìm kiểm sản phẩm"
                fetchOptions={fetchSanPham}
                onChange={(newValue) => applyChange("ChooseSP", newValue)}
                style={{
                  width: 300,
                }}
              />
            </Flex>
          ),
          right: (
            <Button danger onClick={() => onClose()}>
              Thoát
            </Button>
          ),
        }}
      >
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key} closable={item.closable}>
              {renderHoaDon(item.key)}
            </TabPane>
          );
        })}
      </Tabs>
      <div style={{ display: "none" }}>
        <HoaDonToPrint
          ref={componentRef}
          data={data[activeTab]}
          dataPrint={dataPrint}
        />
      </div>
      <div style={{ display: "none" }}>
        <KetCaPrint ref={ketcaRef} data={dhtheoca} calamviec={calamviec} />
      </div>
      {renderModalConfirmPay()}
      {renderBatDauCa()}
      {renderKetThucCa()}
      <FormAddKH ref={refAddKH} onAfterSubmit={onAfterSubmitKH} />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
