import axiosApi from "./api_helper";
export const ContractService = {
  getList: async (payload) => {
    return await axiosApi()
      .post("api/contract/get-list", payload)
      .then((res) => res.data);
  },
  update: async (payload) => {
    return await axiosApi()
      .post("api/contract/update", payload)
      .then((res) => res.data);
  },
  add: async (payload) => {
    return await axiosApi()
      .post("api/contract/add", payload)
      .then((res) => res.data);
  },
  delete: async (payload) => {
    return await axiosApi()
      .post("api/contract/delete", payload)
      .then((res) => res.data);
  },

  //sản phẩm hợp đồng
  getListSP: async (payload) => {
    return await axiosApi()
      .post("api/contract/get-sanpham", payload)
      .then((res) => res.data);
  },
  deleteSanPham: async (payload) => {
    return await axiosApi()
      .post("api/contract/delete-sanpham", payload)
      .then((res) => res.data);
  },

  //danh mục
  getLoaiHD: async () => {
    return await axiosApi()
      .post("api/contract/get-loaihd")
      .then((res) => res.data);
  },
  getTrangThaiHD: async () => {
    return await axiosApi()
      .post("api/contract/get-trangthaihd")
      .then((res) => res.data);
  },
  getLoaiTien: async () => {
    return await axiosApi()
      .post("api/contract/get-loaitien")
      .then((res) => res.data);
  },
};
