import axiosApi from "./api_helper";
export const CRMService = {
  GetLichSu: async (payload) => {
    return await axiosApi()
      .post("api/crm/get-lichsu-guitin", payload)
      .then((res) => res.data);
  },
  Email: {
    Send: async (payload) => {
      return await axiosApi()
        .post("api/crm/send-mail", payload)
        .then((res) => res.data);
    },
    GetMail: async (payload) => {
      return await axiosApi()
        .post("api/crm/get-mail", payload)
        .then((res) => res.data);
    },
    GetMailByID: async (payload) => {
      return await axiosApi()
        .post("api/crm/get-mail-byid", payload)
        .then((res) => res.data);
    },
    AddMail: async (payload) => {
      return await axiosApi()
        .post("api/crm/add-mail", payload)
        .then((res) => res.data);
    },
    DeleteMail: async (payload) => {
      return await axiosApi()
        .post("api/crm/delete-mail", payload)
        .then((res) => res.data);
    },
  },
  SMS: {
    GetSMSBrand: async (payload) => {
      return await axiosApi()
        .post("api/crm/get-sms-brand", payload)
        .then((res) => res.data);
    },
    GetSMS: async (payload) => {
      return await axiosApi()
        .post("api/crm/get-sms", payload)
        .then((res) => res.data);
    },
    SendSMS: async (payload) => {
      return await axiosApi()
        .post("api/crm/send-sms", payload)
        .then((res) => res.data);
    },
    GetSMSByID: async (payload) => {
      return await axiosApi()
        .post("api/crm/get-sms-byid", payload)
        .then((res) => res.data);
    },
    AddSMS: async (payload) => {
      return await axiosApi()
        .post("api/crm/add-sms", payload)
        .then((res) => res.data);
    },
    DeleteSMS: async (payload) => {
      return await axiosApi()
        .post("api/crm/delete-sms", payload)
        .then((res) => res.data);
    },
  },
  Zalo: {
    Send: async (payload) => {
      return await axiosApi()
        .post("api/crm/send-zalo", payload)
        .then((res) => res.data);
    },
    SendOTP: async (payload) => {
      return await axiosApi()
        .post("api/crm/send-zalo-otp", payload)
        .then((res) => res.data);
    },
  },
  Categories: {
    getTempMail: async () => {
      return await axiosApi()
        .post("api/crm/category/get-temp-mail")
        .then((res) => res.data);
    },
    addTempMail: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/add-temp-mail", payload)
        .then((res) => res.data);
    },
    deleteTempMail: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/delete-temp-mail", payload)
        .then((res) => res.data);
    },
    getConfigMail: async () => {
      return await axiosApi()
        .post("api/crm/category/get-config-mail")
        .then((res) => res.data);
    },
    addConfigMail: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/add-config-mail", payload)
        .then((res) => res.data);
    },
    deleteConfigMail: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/delete-config-mail", payload)
        .then((res) => res.data);
    },

    getTempSMS: async () => {
      return await axiosApi()
        .post("api/crm/category/get-temp-sms")
        .then((res) => res.data);
    },
    addTempSMS: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/add-temp-sms", payload)
        .then((res) => res.data);
    },
    deleteTempSMS: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/delete-temp-sms", payload)
        .then((res) => res.data);
    },
    getConfigSMS: async () => {
      return await axiosApi()
        .post("api/crm/category/get-config-sms")
        .then((res) => res.data);
    },
    getConfigSMSByID: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/get-config-sms-byid", payload)
        .then((res) => res.data);
    },
    addConfigSMS: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/add-config-sms", payload)
        .then((res) => res.data);
    },
    deleteConfigSMS: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/delete-config-sms", payload)
        .then((res) => res.data);
    },

    getTempZalo: async () => {
      return await axiosApi()
        .post("api/crm/category/get-temp-zalo")
        .then((res) => res.data);
    },
    addTempZalo: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/add-temp-zalo", payload)
        .then((res) => res.data);
    },
    deleteTempZalo: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/delete-temp-zalo", payload)
        .then((res) => res.data);
    },
    getConfigZalo: async () => {
      return await axiosApi()
        .post("api/crm/category/get-config-zalo")
        .then((res) => res.data);
    },
    addConfigZalo: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/add-config-zalo", payload)
        .then((res) => res.data);
    },
    deleteConfigZalo: async (payload) => {
      return await axiosApi()
        .post("api/crm/category/delete-config-zalo", payload)
        .then((res) => res.data);
    },
  },
};
