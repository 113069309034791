import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import * as React from "react";
import { NhanVienService } from "services/NhanVienService";
import "./index.scss";
import { Flex } from "antd";
import _ from "lodash";
import { Format_Datetime } from "layouts/ultis/common";
const LichSuTieuDungThe = (props, ref) => {
  const { data, data2, filter, sanpham } = props;
  const [nhanvien, setNhanVien] = React.useState({});
  React.useEffect(() => {
    NhanVienService.getDetail().then((res) => {
      setNhanVien(res.data ?? []);
    });
  }, []);

  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <div
        ref={ref}
        className="print-report"
        style={{
          width: filter?.Horizontal ? "1123px" : "794px",
          margin: "0",
          padding: "0 25px",
        }}
      >
        <div className="page-break" />
        <div style={{ margin: "0 10px", padding: "0" }}>
          <Flex justify="space-around" style={{ padding: "20px" }}>
            <img width={100} src={nhanvien.Logo} />
            <div style={{ textAlign: "center", padding: "15px 0px" }}>
              <p style={{ fontSize: 15, fontWeight: "bold" }}>
                {nhanvien?.TenCT}
              </p>
              <p>{nhanvien?.DiaChi}</p>
              <p>{nhanvien?.DienThoai}</p>
            </div>
          </Flex>
          <Flex justify="space-between">
            <div>
              <p>
                Từ ngày {Format_Date(filter.TuNgay)} Đến ngày{" "}
                {Format_Date(filter.DenNgay)}
              </p>
              <p>
                In lúc:{" "}
                {new Date().getHours() < 10
                  ? "0" + new Date().getHours()
                  : new Date().getHours()}
                :
                {new Date().getMinutes() < 10
                  ? "0" + new Date().getMinutes()
                  : new Date().getMinutes()}
              </p>
            </div>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              BÁO CÁO LỊCH SỬ TIÊU DÙNG THẺ THÀNH VIÊN
            </p>
          </Flex>
          <p style={{ fontWeight: "bold" }}>LỊCH SỬ NẠP TIỀN</p>
          <table style={{ width: "100%" }}>
            <tr
              style={{
                textAlign: "left",
                borderBottom: "2px solid black",
              }}
            >
              <th style={{ fontSize: 12, width: "30px", padding: "6px 0px" }}>
                STT
              </th>
              <th style={{ fontSize: 12 }}>Ngày (Giờ)</th>
              <th style={{ fontSize: 12 }}>Tên khách hàng</th>
              <th style={{ fontSize: 12 }}>Số di động</th>
              <th style={{ fontSize: 12 }}>Số tiền</th>
              <th style={{ fontSize: 12 }}>Tiền cộng thêm</th>
              <th style={{ fontSize: 12 }}>Tổng tiền nạp</th>
            </tr>
            {data?.map((item, index) => {
              return (
                <tr style={{ borderBottom: "1px dotted gray" }}>
                  <td
                    style={{ width: "20px", fontSize: 12, padding: "6px 0px" }}
                  >
                    {index + 1}
                  </td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Datetime(item.NgayNhap)}
                  </td>
                  <td style={{ fontSize: 12 }}>{item.TenKH}</td>
                  <td style={{ fontSize: 12 }}>{item.DiDong}</td>
                  <td style={{ fontSize: 12 }}>{item.TienNap}</td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Currency(
                      (item.TienNap * (item.PTCongTienNap ?? 0)) / 100
                    )}
                  </td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Currency(
                      item.TienNap +
                        (item.TienNap * (item.PTCongTienNap ?? 0)) / 100
                    )}
                  </td>
                </tr>
              );
            })}
            <tfoot
              style={{
                padding: "10px 0px",
                borderTop: "1px solid gray",
              }}
            >
              <tr>
                <td colSpan={4} style={{ fontSize: 12, fontWeight: "bold" }}>
                  Tổng cộng
                </td>

                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(_.sumBy(data, (v) => v.TienNap))}
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(
                    _.reduce(
                      data,
                      (sum, cur) =>
                        sum + (cur.TienNap * cur.PTCongTienNap) / 100,
                      0
                    )
                  )}
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(
                    _.reduce(
                      data,
                      (sum, cur) =>
                        sum +
                        (cur.TienNap + (cur.TienNap * cur.PTCongTienNap) / 100),
                      0
                    )
                  )}
                </td>
              </tr>
            </tfoot>
          </table>

          <p style={{ fontWeight: "bold", marginTop: 20 }}>LỊCH SỬ TRỪ TIỀN</p>
          <table style={{ width: "100%" }}>
            <tr
              style={{
                textAlign: "left",
                borderBottom: "2px solid black",
              }}
            >
              <th style={{ fontSize: 12, width: "30px", padding: "6px 0px" }}>
                STT
              </th>
              <th style={{ fontSize: 12 }}>Số hoá đơn</th>
              <th style={{ fontSize: 12 }}>Ngày hoá đơn</th>
              <th style={{ fontSize: 12 }}>Tên khách hàng</th>
              <th style={{ fontSize: 12 }}>Di động</th>
              <th style={{ fontSize: 12 }}>Số tiền hoá đơn</th>
              <th style={{ fontSize: 12 }}>Số tiền trừ</th>
            </tr>
            {data2?.map((item, index) => {
              return (
                <tr style={{ borderBottom: "1px dotted gray" }}>
                  <td
                    style={{ width: "20px", fontSize: 12, padding: "6px 0px" }}
                  >
                    {index + 1}
                  </td>
                  <td style={{ fontSize: 12 }}>{item.SoBH}</td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Datetime(item.NgayBH)}
                  </td>
                  <td style={{ fontSize: 12 }}>{item.TenKH}</td>
                  <td style={{ fontSize: 12 }}>{item.DiDong}</td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Currency(item.KhachTra)}
                  </td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Currency(item.TienTru)}
                  </td>
                </tr>
              );
            })}
            <tfoot
              style={{
                padding: "10px 0px",
                borderTop: "1px solid gray",
              }}
            >
              <tr>
                <td colSpan={4} style={{ fontSize: 12, fontWeight: "bold" }}>
                  Tổng cộng
                </td>

                <td></td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(_.sumBy(data2, (v) => v.KhachTra))}
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(_.sumBy(data2, (v) => v.TienTru))}
                </td>
              </tr>
            </tfoot>
          </table>

          <p style={{ fontWeight: "bold", marginTop: 20 }}>TỔNG HỢP</p>
          <table style={{ width: "40%" }}>
            <tfoot
              style={{
                padding: "10px 0px",
                borderTop: "2px solid black",
              }}
            >
              <tr style={{ borderBottom: "1px dotted gray" }}>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  Số tiền nạp
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(_.sumBy(data, (v) => v.TienNap))}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px dotted gray" }}>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  Số tiền cộng thêm
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(
                    _.reduce(
                      data,
                      (sum, cur) =>
                        sum + (cur.TienNap * cur.PTCongTienNap) / 100,
                      0
                    )
                  )}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px dotted gray" }}>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  Số tiền trừ
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(_.sumBy(data2, (v) => v.TienTru))}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px dotted gray" }}>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  Số dư hiện tại
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(
                    _.reduce(
                      data,
                      (sum, cur) =>
                        sum +
                        (cur.TienNap + (cur.TienNap * cur.PTCongTienNap) / 100),
                      0
                    ) - _.sumBy(data2, (v) => v.TienTru)
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
          <Flex justify="flex-end" style={{ padding: "30px 10%" }}>
            <div>
              <p style={{ textAlign: "center" }}>
                Ngày {new Date().getDate()} tháng {new Date().getMonth() + 1}{" "}
                năm {new Date().getFullYear()}
              </p>
              <p style={{ fontWeight: "bold", textAlign: "center" }}>
                Người lập
              </p>
              <p style={{ textAlign: "center", fontStyle: "italic" }}>
                (Ký , họ tên)
              </p>
            </div>
          </Flex>
        </div>
      </div>
    </div>
  );
};
export default React.forwardRef(LichSuTieuDungThe);
