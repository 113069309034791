import React, { useEffect, useRef, useState } from "react";
import {
  notification,
  Row,
  Col,
  Flex,
  Tabs,
  Checkbox,
  Select,
  Tooltip,
  Modal,
  InputNumber,
  Button,
} from "antd";
import FormAdd from "./FormAdd";
import _ from "lodash";
import "./scss/index.scss";
import Toolbar from "components/Toolbar";
import { BanHangService } from "services/BanHangService";
import { IoIosCafe } from "react-icons/io";
import { Format_Datetime } from "layouts/ultis/common";
import TabPane from "antd/es/tabs/TabPane";
import { IoTabletLandscape } from "react-icons/io5";
import FormAddMobile from "./FormAddMobile";
import { isMobile } from "react-device-detect";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { PermissionService } from "services/PermissionService";
import { CongTyService } from "services/CongTyService";
import { useReactToPrint } from "react-to-print";
import KetCaPrint from "./KetCaPrint";
import { useHistory } from "react-router-dom";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const history = useHistory();
  const [khuVuc, setKhuVuc] = useState([]);
  const [ban, setBan] = useState([]);
  const [banAll, setBanAll] = useState([]);
  const [selectKhu, setSelectKhu] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const refDetail = useRef(null);
  const refPer = useRef(null);
  const refDetailMobile = useRef(null);
  const [hubConnection, setHubConnection] = useState(null);
  const [congty, setCongty] = useState([]);
  const [calamviec, setCalamviec] = useState({});
  const [dhtheoca, setDhtheoca] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const ketcaRef = useRef();

  const loadCalamviec = async () => {
    let _check = await BanHangService.checkCaLamViec();
    if (_check.data) {
      setCalamviec(_check.data);
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen?.() ||
        document.documentElement.mozRequestFullScreen?.() ||
        document.documentElement.webkitRequestFullscreen?.(
          Element.ALLOW_KEYBOARD_INPUT
        );
    } else {
      // document.cancelFullScreen?.() ||
      //   document.mozCancelFullScreen?.() ||
      //   document.webkitCancelFullScreen?.();
    }
  };
  const goFullScreen = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user?.MaNTK == 3) {
      toggleFullscreen();
      let navBar = document.querySelector(".box-header");
      if (navBar) {
        navBar.style.display = "none";
      }
    }
  };
  useEffect(() => {
    loadCalamviec();
    goFullScreen();
    PermissionService.checkQuyen({ FormID: 403 }).then((res) => {
      if (res.data == 1) {
        refPer.current = res.data;
        CongTyService.getCongTy().then((_ct) => {
          setCongty(_ct.data);
        });
      }
    });
    try {
      (async () => {
        let connection = new HubConnectionBuilder()
          .withUrl("https://api-crm.beesky.vn/order")
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Information)
          .build();
        await connection.start();
        connection
          .invoke("JoinGroup", localStorage.getItem("MaCTDK"))
          .catch((err) => console.error(err.toString()));
        console.log(localStorage.getItem("MaCTDK"));
        setHubConnection(connection);
        return () => connection.off();
      })();
    } catch {}
  }, []);
  useEffect(() => {
    if (hubConnection) {
      const updateBanState = (response, updateFn) => {
        setBan((prev) => {
          const updatedBans = prev.map((ban) =>
            ban.ID === response.maBan
              ? updateFn(_.cloneDeep(ban), response)
              : ban
          );
          return updatedBans;
        });

        setBanAll((prev) => {
          const updatedBansAll = prev.map((ban) =>
            ban.ID === response.maBan
              ? updateFn(_.cloneDeep(ban), response)
              : ban
          );
          return updatedBansAll;
        });
      };

      const handleAddBan = (response) => {
        updateBanState(response, (ban, res) => {
          ban.GioVao = res.gioVao;
          ban.MaBH = res.maBH;
          return ban;
        });
      };

      const handleUpdateDienGiai = (response) => {
        updateBanState(response, (ban, res) => {
          ban.DienGiai = res.dienGiai;
          return ban;
        });
      };

      const handleEmptyTable = (response) => {
        updateBanState(response, (ban) => {
          ban.MaBH = null;
          ban.GioVao = null;
          ban.DienGiai = null;
          return ban;
        });
      };

      try {
        hubConnection.on("AddBan", handleAddBan);
        hubConnection.on("UpdateDienGiai", handleUpdateDienGiai);
        hubConnection.on("EmptyTable", handleEmptyTable);
      } catch (error) {
        console.error("Error handling hub connection:", error);
      }

      return () => {
        hubConnection.off("AddBan", handleAddBan);
        hubConnection.off("UpdateDienGiai", handleUpdateDienGiai);
        hubConnection.off("EmptyTable", handleEmptyTable);
      };
    }
  }, [hubConnection]);
  const loadBan = async (makhu) => {
    let _res = await BanHangService.BanTheoBan.getList({ MaKV: makhu });
    setBan(_res.data);
    setBanAll(_res.data);
  };
  const loadKhuVuc = async (mact) => {
    let _res = await BanHangService.Categories.getKhuVuc({
      FormID: 403,
      MaCT: mact,
    });
    setKhuVuc(_res.data);
    if (_res?.data.length > 0) {
      setSelectKhu(_res.data[0]);
      loadBan(_res.data[0].ID);
    } else {
      setBan([]);
      setBanAll([]);
    }
  };
  useEffect(() => {
    loadKhuVuc(0);
  }, []);
  const onAfterSubmit = () => {
    loadBan(selectKhu.ID);
    loadCalamviec();
  };
  const onChangeKhu = (v) => {
    setSelectKhu(v);
    loadBan(v.ID);
    setActiveTab(1);
  };
  const onChange = (e) => {
    var index = e.target.value;
    if (index === 1) {
      setBan(banAll);
    } else if (index === 2) {
      let _ban = banAll.filter((v) => v.MaBH);
      setBan(_ban);
    } else {
      let _ban = banAll.filter((v) => !v.MaBH);
      setBan(_ban);
    }
    setActiveTab(index);
  };
  const applyChangeFilter = (v) => {
    loadKhuVuc(v ? v : 0);
  };
  const handlePrintKetCa = useReactToPrint({
    content: () => ketcaRef.current,
  });
  const renderKetThucCa = () => {
    const logout = async () => {
      localStorage.removeItem("token");
      localStorage.removeItem("url");
      history.push("/auth/sign-in");
    };

    const saveKetThucCa = async () => {
      setLoading(true);
      let _res = await BanHangService.addKetThucCa({
        ID: calamviec?.ID,
      });
      if (_res.status === 2000) {
        handlePrintKetCa();
        logout();
        setOpen(false);
      }
      setLoading(false);
    };
    return (
      <Modal
        closeIcon={false}
        width={900}
        title="KHAI BÁO TIỀN KẾT THÚC CA"
        open={open}
        onOk={() => saveKetThucCa()}
        onCancel={() => {
          setOpen(false);
        }}
        cancelText="Huỷ"
        okText="Kết ca"
      >
        <Row gutter={[20]}>
          <Col span={12}>
            <p style={{ marginTop: 10, color: "red" }}>Tiền đầu ca (1)</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={calamviec?.TienDauCa}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Tổng hoá đơn</p>
            <InputNumber
              disabled
              value={dhtheoca?.length}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Tiền hàng</p>
            <InputNumber
              suffix="vnđ"
              disabled
              value={dhtheoca?.reduce((pre, cur) => (pre += cur.ThanhTien), 0)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Chiết khấu</p>
            <InputNumber
              suffix="vnđ"
              disabled
              value={dhtheoca?.reduce((pre, cur) => (pre += cur.TienCK), 0)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Doanh thu</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={dhtheoca?.reduce((pre, cur) => (pre += cur.KhachTra), 0)}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10, color: "red" }}>Tiền mặt (2)</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={dhtheoca
                ?.filter((v) => v.HinhThucTT === 3)
                ?.reduce((pre, cur) => (pre += cur.KhachTra), 0)}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Ngân hàng</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={dhtheoca
                ?.filter((v) => v.HinhThucTT === 2)
                ?.reduce((pre, cur) => (pre += cur.KhachTra), 0)}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10, color: "red" }}>
              Tổng tiền mặt cuối ca = (1)+(2)
            </p>
            <InputNumber
              disabled
              suffix="vnđ"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={
                (dhtheoca
                  ?.filter((v) => v.HinhThucTT === 3)
                  ?.reduce((pre, cur) => (pre += cur.KhachTra), 0) ?? 0) +
                calamviec?.TienDauCa
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  };
  const handleKetThucCa = async () => {
    let _res = await BanHangService.getDonHangTheoCa({ MaCa: calamviec?.ID });
    setDhtheoca(_res.data ?? []);
    setOpen(true);
  };
  const handleHoaDon = () =>{
      window.location.href = "#/admin/ban-san-pham";
  }
    const handleLogout = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("url");
      sessionStorage.removeItem("token");
      history.push("/auth/sign-in");
    };
  return (
    <div>
      <Toolbar
        left={() => {
          return (
            <Flex gap={5} style={{ width: "100%" }} justify="space-between">
              <Flex gap={5}>
                {refPer.current === 1 && (
                  <Select
                    // mode="multiple"
                    allowClear
                    placeholder="Chọn chi nhánh"
                    onChange={(v) => applyChangeFilter(v)}
                    style={{
                      width: 200,
                    }}
                    options={congty}
                    fieldNames={{ label: "TenCTVT", value: "ID" }}
                  />
                )}

                {khuVuc?.map((item) => {
                  return (
                    <div
                      style={{
                        background:
                          selectKhu?.ID === item?.ID ? "#adc6ff" : "white",
                        borderRadius: 10,
                        padding: "2px 20px",
                        cursor: "pointer",
                      }}
                      onClick={() => onChangeKhu(item)}
                    >
                      <p>{item.TenKhu}</p>
                    </div>
                  );
                })}
              </Flex>
              <Flex gap={10}>
                {calamviec?.ID && (
                  <Button danger onClick={() => handleKetThucCa()}>
                    Kết thúc ca
                  </Button>
                )}
                {JSON.parse(localStorage.getItem("user"))?.MaNTK === 3 && (
                  <>
                    <Button danger onClick={() => handleLogout()}>
                      Đăng xuất
                    </Button>
                    <Button onClick={() => handleHoaDon()}>Hoá đơn</Button>
                  </>
                )}
              </Flex>
            </Flex>
          );
        }}
      />
      <Checkbox value={1} checked={activeTab === 1} onChange={onChange}>
        Tất cả ({banAll.length})
      </Checkbox>
      <Checkbox value={2} checked={activeTab === 2} onChange={onChange}>
        Sử dụng ({banAll?.filter((v) => v.MaBH)?.length})
      </Checkbox>
      <Checkbox value={3} checked={activeTab === 3} onChange={onChange}>
        Còn trống ({banAll?.filter((v) => !v.MaBH)?.length})
      </Checkbox>
      <Row gutter={[10]} style={{ marginTop: 20 }}>
        {ban?.map((item) => {
          return (
            <Col xs={8} md={4} lg={3} xl={2} key={item?.ID}>
              <p
                style={{
                  fontSize: 12,
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                {item?.GioVao ? Format_Datetime(item?.GioVao) : "Bàn trống"}
              </p>
              <Flex
                vertical
                justify="center"
                align="center"
                onClick={() =>
                  isMobile
                    ? refDetailMobile.current.show(item, selectKhu?.IsTinhGio)
                    : refDetail.current.show(item, selectKhu?.IsTinhGio)
                }
                style={{
                  height: "100px",
                  background: item?.MaBH ? "#bae0ff" : "#fafafa",
                  borderRadius: 15,
                  cursor: "pointer",
                }}
              >
                <IoTabletLandscape
                  size={50}
                  color={item?.MaBH ? "#4096ff" : "#bfbfbf"}
                />
                <p style={{ fontWeight: "bold" }}>{item?.TenBan}</p>
                <Tooltip placement="topRight" title={item.DienGiai}>
                  <p
                    style={{
                      width: "80%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {item.DienGiai}
                  </p>
                </Tooltip>
              </Flex>
            </Col>
          );
        })}
      </Row>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormAddMobile ref={refDetailMobile} onAfterSubmit={onAfterSubmit} />
      <div style={{ display: "none" }}>
        <KetCaPrint ref={ketcaRef} data={dhtheoca} calamviec={calamviec} />
      </div>
      {renderKetThucCa()}
      {contextHolder}
    </div>
  );
}
