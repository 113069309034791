import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Flex,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  notification,
  Checkbox,
  Select,
} from "antd";
import FormAdd from "./FormAdd";
import { CiEdit, CiTrash } from "react-icons/ci";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { BanHangService } from "services/BanHangService";
import { PermissionService } from "services/PermissionService";
import { CongTyService } from "services/CongTyService";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [khuVuc, setKhuVuc] = useState([]);
  const [selectKV, setSelectKV] = useState(null);
  const [loading, setLoading] = useState(false);
  const refDetail = useRef(null);
  const refPer = useRef(null);

  const [congty, setCongty] = useState([]);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadKhuVuc = async (mact) => {
    let _res = await BanHangService.Categories.getKhuVuc({
      FormID: 402,
      MaCT: mact,
    });
    setKhuVuc(_res.data ?? []);
  };
  useEffect(() => {
    PermissionService.checkQuyen({ FormID: 402 }).then((res) => {
      refPer.current = res.data;
      if (res.data == 1) {
        CongTyService.getCongTy().then((_ct) => {
          setCongty(_ct.data);
        });
      }
    });
    loadKhuVuc(0);
  }, []);
  const filter = async (makv) => {
    setLoading(true);
    let _res = await BanHangService.Categories.getBan({
      MaKV: makv ? makv : selectKV,
    });
    setData(_res.data);
    setLoading(false);
  };
  const onAfterSubmit = (tab) => {
    if (tab === "0") {
      loadKhuVuc(0);
    } else {
      filter();
    }
  };
  const columns = [
    {
      title: "Tên bàn",
      dataIndex: "TenBan",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show("1", selectKV)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "140px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() =>
                  refDetail.current.update(
                    item,
                    "1",
                    khuVuc?.find((v) => v.ID === selectKV)
                  )
                }
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const remove = async (value) => {
    let _res = await BanHangService.Categories.deleteBan({
      ID: Number(value),
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);

      openNotification("success", "topRight", "Xoá danh mục thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const removeKhu = async (value) => {
    let _res = await BanHangService.Categories.deleteKhuVuc({
      ID: Number(value),
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(khuVuc);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setKhuVuc(fil);
      if (value === selectKV) setSelectKV(null);
      openNotification("success", "topRight", "Xoá danh mục thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const applyChangeFilter = (v) => {
    loadKhuVuc(v ? v : 0);
    setSelectKV({});
    setData([]);
  };
  return (
    <Box>
      <Row gutter={[10]}>
        <Col span={12}>
          <Toolbar
            left={() => <p style={{ fontWeight: "bold" }}>Danh sách khu vực</p>}
            right={() => (
              <Flex gap={10}>
                {refPer.current === 1 && (
                  <Select
                    // mode="multiple"
                    allowClear
                    placeholder="Chọn chi nhánh"
                    onChange={(v) => applyChangeFilter(v)}
                    style={{
                      width: 200,
                    }}
                    options={congty}
                    fieldNames={{ label: "TenCTVT", value: "ID" }}
                  />
                )}
                <Button
                  type="primary"
                  ghost
                  onClick={() => refDetail.current.show("0")}
                >
                  Thêm
                </Button>
              </Flex>
            )}
          />
          <div style={{ background: "white", borderRadius: 5, padding: 10 }}>
            <Flex
              align="center"
              justify="space-between"
              style={{ borderBottom: "1px solid #E6ECFA", marginBottom: 5 }}
            >
              <p style={{ fontWeight: "bold", width: "35%" }}>Tên khu vực</p>
              <p style={{ fontWeight: "bold", width: "20%" }}>Chi nhánh</p>
              <p style={{ fontWeight: "bold" }}>Tính giờ</p>
              <p></p>
            </Flex>
            {khuVuc?.map((item) => {
              return (
                <Flex
                  key={item.ID}
                  align="center"
                  justify="space-between"
                  style={{
                    padding: "0px 5px",
                    borderRadius: 5,
                    background: selectKV === item.ID ? "#adc6ff" : null,
                  }}
                >
                  <p
                    onClick={() => {
                      setSelectKV(item.ID);
                      filter(item.ID);
                    }}
                    style={{
                      // color: "#fff1b8",
                      cursor: "pointer",
                      width: "35%",
                    }}
                  >
                    {item.TenKhu}
                  </p>
                  <p
                    onClick={() => {
                      setSelectKV(item.ID);
                      filter(item.ID);
                    }}
                    style={{
                      // color: "#fff1b8",
                      cursor: "pointer",
                      width: "30%",
                    }}
                  >
                    {congty.find(v=>v.ID === item.MaCT)?.TenCTVT}
                  </p>
                  <Checkbox checked={item?.IsTinhGio} />
                  <div>
                    <Button
                      style={{ padding: "0px 3px" }}
                      type="text"
                      onClick={() => refDetail.current.update(item, "0")}
                    >
                      <CiEdit size={20} />
                    </Button>
                    <Button
                      style={{ padding: "0px 3px" }}
                      type="text"
                      onClick={() => removeKhu(item.ID)}
                    >
                      <MdDeleteOutline color="red" size={20} />
                    </Button>
                  </div>
                </Flex>
              );
            })}
          </div>
        </Col>
        {selectKV && (
          <Col span={12}>
            <Toolbar
              left={() => <p style={{ fontWeight: "bold" }}>Danh sách bàn</p>}
              // right={() => (
              //   <Button
              //     ghost
              //     type="primary"
              //     onClick={() => refDetail.current.show("1", selectKV)}
              //   >
              //     Thêm
              //   </Button>
              // )}
            />
            <Table
              loading={loading}
              dataSource={data}
              columns={columns}
              scroll={{
                y: window.innerHeight - 350,
                // x: 2500
              }}
              onSelect={(rec) => console.log(rec)}
              size="small"
            />
          </Col>
        )}
      </Row>

      <FormAdd ref={refDetail} onAfterSubmit={(tab) => onAfterSubmit(tab)} />
      {contextHolder}
    </Box>
  );
}
