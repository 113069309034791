import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Drawer,
  notification,
  DatePicker,
  Table,
  Divider,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import _ from "lodash";
import { CardService } from "services/CardService";
import { NhanVienService } from "services/NhanVienService";
import { CustomerService } from "services/CustomerService";
import { Format_Date } from "layouts/ultis/common";

const { TextArea } = Input;
const FormDetail = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [maKH,setMaKH] = useState(null);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [nhatky, setNhatky] = useState([]);
  const [open, setOpen] = useState(false);
  const loadCategories = async () => {
    let _tt = await CustomerService.getStatus();
    setStatus(_tt?.data ?? []);
  };
  const loadChamSoc  = async (_makh) =>{
    let _res = await CustomerService.getChamSoc({ ID: _makh });
    setNhatky(_res.data ?? []);
  }
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setMaKH(data.MaKH);
      setData({MaTT: data.MaTT});
      loadChamSoc(data?.MaKH);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    _data.MaKH = maKH;
    let _res = await CustomerService.addChamSoc(_data);
    setLoading(false);
    if (_res.status === 2000) {
      loadChamSoc(maKH);
      onAfterSubmit();
      openNotification("success", "topRight", `Thêm lịch sử thành công!`);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  // chăm sóc
  const columns = [
    {
      title: "Ngày xử lý",
      dataIndex: "NgayXL",
      render: (item) => Format_Date(item),
    },
    {
      title: "Trạng thái",
      dataIndex: "MaTT",
      render: (item) => status?.find(v=>v.ID===item)?.Name,
    },
    {
      title: "Ghi chú",
      dataIndex: "DienGiai",
    },
  ];
  return (
    <Drawer
      title="Thông tin khách hàng"
      width={1020}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={24}>
          <TextArea
            value={data?.DienGiai}
            onChange={(e) => applyChange("DienGiai", e.target.value)}
            placeholder="Nhập ghi chú"
            rows={4}
          />
        </Col>
        <Col span={22} className="gutter-row">
          <Select
            style={{
              width: "20%",
            }}
            value={data?.MaTT}
            placeholder="Chọn trạng thái khách hàng"
            onChange={(v) => applyChange("MaTT", v)}
            options={status}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Button onClick={() => onSubmit(false)} type="primary">
            Lưu
          </Button>
        </Col>

        <Col span={24}>
          <Divider orientation="left">Lịch sử</Divider>
          <Table
            style={{ height: 100 }}
            loading={loading}
            dataSource={nhatky}
            columns={columns}
            size="small"
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormDetail);
