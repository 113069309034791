import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import { Button, Drawer, Flex, Tag, notification, Popconfirm } from "antd";
import _ from "lodash";
import { useReactToPrint } from "react-to-print";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { BanHangService } from "services/BanHangService";
import InLaiVe from "./InLaiVe";

let exits = {};
function taoSoDuyNhatBangDate() {
  try {
    const timestamp = Date.now().toString();
    const value = timestamp.slice(-9);
    if (exits[value]) {
      return taoSoDuyNhatBangDate();
    } else {
      exits[value] = true;
      return value;
    }
  } catch {
    return taoSoDuyNhatBangDate();
  }
}
const FormDetail = (props, ref) => {
    const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const refInLaiVe = useRef();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sanpham, setSanpham] = useState([]);
  const [the, setThe] = useState([]);
  const state = {
    NEW: "Mới",
    PAYMENT: "Đã thanh toán",
    CANCEL: "Đã huỷ",
    REJECT: "Từ chối",
    ACCEPT: "Đã duyệt",
  };
  const stateColor = {
    NEW: "blue",
    PAYMENT: "cyan",
    CANCEL: "orange",
    REJECT: "red",
    ACCEPT: "green",
  };
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      let _the = await BanHangService.getBHThe({ MaBH: item.ID });
      let _res = await BanHangService.getBHSanPHam({ MaBH: item.ID });
      let sp = [];
      _res.data?.map((item) => {
        let qr = _the.data?.filter(
          (v) => v.MaSP === item.MaSP && v.MaGDV === item.MaGDV
        );
        sp.push({
          ...item,
          QRCodes: _.map(qr, "MaVach"),
        });
      });
      setSanpham(sp);
      setThe(_the?.data);
      //       let _res = await BanHangService.getBHSanPHam({ MaBH: item.ID });
      // setSanpham(_res.data);

      setOpen(true);
      setData(item);
    },
  }));
  const onClose = () => {
    setOpen(false);
    onAfterSubmit();
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const handlePrintLai = useReactToPrint({
    content: () => refInLaiVe.current,
  });
  const onAccept = async (state) => {
    setLoading(true);
    let _the = [];
    sanpham?.map((item) => {
      for (let index = 0; index < item.SoLuong; index++) {
        let qr = taoSoDuyNhatBangDate();
        _the.push({
          MaVach: qr,
          MaGoiDV: item.MaGDV,
          MaSP: item.MaSP,
          SoLanQuet: item.SoLanQuet ?? 1,
          NgayTao: item.NgayBH,
        });
      }
    });
    let _res = await BanHangService.changeState({
      ID: data.ID,
      State: state,
      TongTien: state === "ACCEPT" ? 0 : data?.ThanhToan,
      MaKH: data.MaKH,
      LyDoPT: "Duyệt vé kinh doanh",
      The: _the,
    });
    setLoading(false);
    if (_res.status === 2000) {
      onClose();
    }
  };
  const confirm = (e) => {
    onAccept("ACCEPT");
  };

  const confirm2 = (e) => {
    onAccept("RETURN");
  };
  const print = () => {
    handlePrintLai();
  };
  return (
    <Drawer
      title="Thông tin chi tiết vé"
      width="500px"
      onClose={onClose}
      open={open}
    >
      {contextHolder}
      <div style={{ margin: "10px 0px" }}>
        <p>
          Tên khách hàng:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {data.TenCongTy}
          </span>
        </p>
        <p>
          Di động:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {data.DiDong}
          </span>
        </p>
        <p>
          Email:
          <span style={{ fontWeight: "bold", fontSize: 14 }}>{data.Email}</span>
        </p>
      </div>
      <hr />
      <div style={{ margin: "10px 0px" }}>
        <p>
          Ngày bán vé:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {/* {Format_Datetime(data.NgayBH)} */}
            {new Date(data.NgayBH).getDate()}/{" "}
            {new Date(data.NgayBH).getMonth() + 1}/{" "}
            {new Date(data.NgayBH).getFullYear()}
          </span>
        </p>
        <p>
          Tổng tiền:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {Format_Currency(data.ThanhTien)}
          </span>
        </p>
        <p>
          Số lượng vé:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {data.SoLuongThe}
          </span>
        </p>
        <p>
          Vé đã sử dụng:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {data.DaSuDung ?? 0}
          </span>
        </p>
        <p>
          Vé còn lại:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {data.SoLuongThe - data.DaSuDung}
          </span>
        </p>
        {/* <Tag
          style={{ fontSize: 16, padding: 10 }}
          color={stateColor[data.State]}
        >
          {state[data.State]}
        </Tag> */}
      </div>
      <hr />
      <div style={{ margin: "10px 0px" }}>
        <Flex
          justify="space-between"
          style={{ borderBottom: "1px dotted #dee2e6" }}
        >
          <p style={{ fontWeight: "600" }}>Gói bán(SL)</p>
          <p style={{ fontWeight: "600" }}>Đơn giá(đ)</p>
        </Flex>
        {sanpham?.map((item) => {
          return (
            <Flex justify="space-between" style={{margin:"5px 0px"}}>
              <p style={{ marginTop: 0 }}>
                {item.TenSP} ({item.SoLuong})
                {/* {sanpham?.find((v) => v.MaSP === item.MaSP)?.TenSP} ({item.SoLuong}) */}
                {/* {sanpham?.find((v) => v.MaSP === item.MaSP)?.SoLuong} */}
                {/* <Tag
                  style={{ fontSize: 12,marginLeft:10 }}
                  color="green"
                >
                  {item.DaQuet ?? 0} / {item.SoLanQuet}
                </Tag> */}
              </p>
              <p style={{ marginTop: 0,fontWeight:'bold' }}>
                {/* {Format_Currency(
                  sanpham?.find((v) => v.MaSP === item.MaSP)?.DonGia *
                    sanpham?.find((v) => v.MaSP === item.MaSP)?.SoLuong
                )} */}
                {Format_Currency(item.DonGia)}
              </p>
            </Flex>
          );
        })}
      </div>
      <hr />
      {data?.State === "ACCEPT" && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Button
            type="primary"
            style={{ marginRight: 5 }}
            loading={loading}
            onClick={() => print()}
          >
            In vé
          </Button>
        </div>
      )}

      {(data.State === "PAYMENT" || data.State === "NEW") && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Popconfirm
            title="Duyệt vé"
            description="Bạn có chắc muốn duyệt vé này không?"
            onConfirm={confirm}
            okText="Đồng ý"
            cancelText="Huỷ"
          >
            <Button style={{ marginRight: 5 }} type="primary" loading={loading}>
              Duyệt
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Từ chối vé"
            description="Bạn có chắc muốn từ chối vé này không?"
            onConfirm={confirm2}
            okText="Đồng ý"
            cancelText="Huỷ"
          >
            <Button
              loading={loading}
              style={{ marginLeft: 5 }}
              type="primary"
              danger
              ghost
            >
              Hoàn vé
            </Button>
          </Popconfirm>
        </div>
      )}
      <div style={{ display: "none" }}>
        <InLaiVe ref={refInLaiVe} data={{ SanPham: sanpham, The: the }} />
      </div>
    </Drawer>
  );
};
export default forwardRef(FormDetail);
