import axiosApi from "./api_helper";
export const CustomerService = {
  filter: async (payload) => {
    return await axiosApi()
      .post("api/customer/filter", payload)
      .then((res) => res.data);
  },
  getList: async (payload) => {
    return await axiosApi()
      .post("api/customer/get-list", payload)
      .then((res) => res.data);
  },
  getListAll: async () => {
    return await axiosApi()
      .post("api/customer/get-list-all")
      .then((res) => res.data);
  },
  getByID: async (payload) => {
    return await axiosApi()
      .post("api/customer/get-byid", payload)
      .then((res) => res.data);
  },
  update: async (payload) => {
    return await axiosApi()
      .post("api/customer/update", payload)
      .then((res) => res.data);
  },
  add: async (payload) => {
    return await axiosApi()
      .post("api/customer/add", payload)
      .then((res) => res.data);
  },
  delete: async (payload) => {
    return await axiosApi()
      .post("api/customer/delete", payload)
      .then((res) => res.data);
  },

  // danh mục
  getStatus: async () => {
    return await axiosApi()
      .post("api/customer/get-status")
      .then((res) => res.data);
  },
  addStatus: async (payload) => {
    return await axiosApi()
      .post("api/customer/add-status", payload)
      .then((res) => res.data);
  },
  deleteStatus: async (payload) => {
    return await axiosApi()
      .post("api/customer/delete-status", payload)
      .then((res) => res.data);
  },

  getGroup: async () => {
    return await axiosApi()
      .post("api/customer/get-group")
      .then((res) => res.data);
  },
  addGroup: async (payload) => {
    return await axiosApi()
      .post("api/customer/add-group", payload)
      .then((res) => res.data);
  },
  deleteGroup: async (payload) => {
    return await axiosApi()
      .post("api/customer/delete-group", payload)
      .then((res) => res.data);
  },

  getNguon: async () => {
    return await axiosApi()
      .post("api/customer/get-nguon")
      .then((res) => res.data);
  },
  addNguon: async (payload) => {
    return await axiosApi()
      .post("api/customer/add-nguon", payload)
      .then((res) => res.data);
  },
  deleteNguon: async (payload) => {
    return await axiosApi()
      .post("api/customer/delete-nguon", payload)
      .then((res) => res.data);
  },

  // tab chi tiết
  getChamSoc: async (payload) => {
    return await axiosApi()
      .post("api/customer/get-chamsoc", payload)
      .then((res) => res.data);
  },
  addChamSoc: async (payload) => {
    return await axiosApi()
      .post("api/customer/add-chamsoc", payload)
      .then((res) => res.data);
  },
};
