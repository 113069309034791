import React, {
  useState,
  useEffect,
} from "react";
import {  Flex, Radio, Select } from "antd";
import _ from "lodash";
import ActiveRoute from "components/ActiveRoute";
import { SanPhamService } from "services/SanPhamService";
import FilterDate from "components/FilterDate";

const FilterModal = ({ ...props }) => {
  const {  filterCondition, onChangeFilter } = props;
  const [loaiDichVu, setLoaiDichVu] = useState([]);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "LoaiVe":
      case "LoaiDichVu":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(() => {
    SanPhamService.filterGoiDV({MaKVC:5}).then((res) => setLoaiDichVu(res.data ?? []));
  }, []);
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      {!filterCondition.IsVeThang && (
        <>
          <div className="box-search">
            <p className="label">Loại dịch vụ</p>
            <Select
              mode="multiple"
              placeholder="Chọn loại dịch vụ"
              onChange={(v) => applyChangeFilter("LoaiDichVu", v)}
              style={{
                width: "100%",
              }}
              options={loaiDichVu}
              fieldNames={{ label: "TenGoi", value: "ID" }}
            />
          </div>
          <div className="box-search">
            <p className="label">Loại vé</p>
            <Select
              mode="multiple"
              placeholder="Chọn loại vé"
              onChange={(v) => applyChangeFilter("LoaiVe", v)}
              style={{
                width: "100%",
              }}
              options={[
                { ID: "QR", name: "QR" },
                { ID: "CARD", name: "CARD" },
              ]}
              fieldNames={{ label: "name", value: "ID" }}
            />
          </div>
        </>
      )}

      <div className="box-search">
        <p className="label">Vé</p>
        <Radio.Group
          defaultValue={false}
          onChange={(e) => applyChangeFilter("IsVeThang", e.target.value)}
        >
          <Flex vertical gap={5}>
            <Radio value={false}>Vé ngày</Radio>
            <Radio value={true}>Vé tháng</Radio>
          </Flex>
        </Radio.Group>
      </div>
    </div>
  );
};
export default FilterModal;
