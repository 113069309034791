import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  Select,
  Checkbox,
  Table,
} from "antd";
import _ from "lodash";
import { SanPhamService } from "services/SanPhamService";
import { OtherService } from "services/OtherService";
import DebounceSelect from "components/DebounceSelect";
const FormAddSanPham = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [maquay, setMaquay] = useState(0);
  useImperativeHandle(ref, () => ({
    add: async (data) => {
      setOpen(true);
      let _res = await SanPhamService.getList({ InputSearch: "" });
      let _sptheoquay = await SanPhamService.getSanPhamTheoQuay({
        MaQuay: data?.MaKV,
      });
      let selects = [];
      _sptheoquay?.data?.map((item) => {
        selects.push(item.MaSP);
      });
      let _sptheoMayIn = await SanPhamService.Categories.getSanPhamMayIn({
        MaMayIn: data?.ID,
      });
      let selects2 = [];
      _sptheoMayIn?.data?.map((item) => {
        selects2.push(item.MaSP);
      });
      setSelectedRowKeys(selects2);
      setData(_res.data?.filter((item) => selects?.includes(item?.ID)));
      setMaquay(data?.ID);
    },
  }));
  const onClose = () => {
    setOpen(false);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = null;
    _res = await OtherService.addNganHang(_data);

    setLoading(false);
    if (_res.status === 2000) {
      if (!_data.ID) {
        setData((pre) => {
          return { ...pre, ID: _res.data };
        });
      }
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} danh mục thành công!`
      );
      onAfterSubmit();
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchSanPham(username) {
    return SanPhamService.getList({ InputSearch: username }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenSP}`,
        value: item.ID,
        title: JSON.stringify(item),
      }));
    });
  }
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "ChooseSP":
        break;
      default:
        break;
    }
    setData(_data);
  };
  const columns = [
    {
      title: "Tên sản phẩm",
      dataIndex: "TenSP",
    },
    {
      title: "Loại sản phẩm",
      dataIndex: "TenLSP",
    },
    {
      title: "Nhóm sản phẩm",
      dataIndex: "TenNhom",
    },
  ];
  const onSelectChange = async (newSelectedRowKeys) => {
    setLoading(true);
    if (selectedRowKeys.length > newSelectedRowKeys.length) {
      let id = _.difference(selectedRowKeys, newSelectedRowKeys);
      if (id?.length > 0) {
        let _res = await SanPhamService.Categories.deleteSanPhamMayIn({
          MaMayIn: maquay,
          Ids: id,
        });
      }
    } else {
      let id = _.difference(newSelectedRowKeys, selectedRowKeys);
      if (id?.length > 0) {
        let _res = await SanPhamService.Categories.addSanPhamMayIn({
          MaMayIn: maquay,
          Ids: id,
        });
      }
    }
    setSelectedRowKeys(newSelectedRowKeys);
    setLoading(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <Drawer
      title="Thêm sản phẩm"
      width={950}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      // extra={
      //   <DebounceSelect
      //     showSearch
      //     value={data?.ChooseSP}
      //     placeholder="Tìm kiểm sản phẩm"
      //     fetchOptions={fetchSanPham}
      //     onChange={(newValue) => applyChange("ChooseSP", newValue)}
      //     style={{
      //       width: 300,
      //     }}
      //   />
      // }
    >
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 190,
          // x: 2500
        }}
        rowSelection={rowSelection}
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
        size="small"
        pagination={{
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: ["100", "500", "5000"],
        }}
      />
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAddSanPham);
