import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Popconfirm,
  Table,
  Tabs,
  Tooltip,
  notification,
  Flex,
} from "antd";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import TabPane from "antd/es/tabs/TabPane";
import { SanPhamService } from "services/SanPhamService";
import { BanHangService } from "services/BanHangService";
import { KhoHangService } from "services/KhoHangService";
import { IoIosAddCircle } from "react-icons/io";
import FormAddSanPham from "./FormAdd.SanPham";
// import FormAddImage from "./FormAddImage";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const refDetail = useRef(null);
  const refAddSanPham = useRef(null);
  const [loaisp, setLoaisp] = useState([]);
  const [khuVuc, setKhuVuc] = useState([]);

  const loadCateTab3 = async () => {
    let _lsp = await SanPhamService.getLSP();
    setLoaisp(_lsp?.data ?? []);
    // let _res = await BanHangService.Categories.getKhuVuc({ FormID: 402 });
    // setKhuVuc(_res.data);

    let _resQB = await BanHangService.getQuayBan();
    setKhuVuc(_resQB.data ?? []);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const tabs = [
    {
      title: "Loại sản phẩm",
      key: "0",
    },
    {
      title: "Đơn vị tính",
      key: "1",
    },
    {
      title: "Cài đặt máy in",
      key: "2",
    },
    {
      title: "Kho hàng",
      key: "3",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");

  useEffect(() => {
    filter(activeTab);
  }, []);
  const filter = async (tab) => {
    setLoading(true);
    if (tab === "0") {
      let _res = await SanPhamService.getLSP();
      if (_res.data) setData(_res.data);
    } else if (tab === "1") {
      let _res = await SanPhamService.getDVT();
      if (_res.data) setData(_res.data);
    } else if (tab === "2") {
      let _res = await SanPhamService.Categories.getCatDatMayIn();
      if (_res.data) setData(_res.data);
    } else if (tab === "3") {
      let _res = await KhoHangService.NhapKho.getTenKho();
      if (_res.data) setData(_res.data);
    }
    setLoading(false);
  };
  const onAfterSubmit = () => {
    filter(activeTab);
  };
  const columns = [
    {
      title: "Tên loại sản phẩm",
      dataIndex: "TenLSP",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "100px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaLSP)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "Tên đơn vị tính",
      dataIndex: "TenDVT",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaDVT)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns3 = [
    {
      title: "Tên server",
      dataIndex: "TenServer",
    },
    {
      title: "Tên máy in",
      dataIndex: "TenMayIn",
    },

    {
      title: "Điểm bán",
      dataIndex: "MaKV",
      render: (item) => khuVuc?.find((v) => v.ID === item)?.TenQuay,
    },
    {
      title: "Loại In",
      dataIndex: "MaLoaiMayIn",
      render: (item) =>
        [
          { TenLI: "In tem", ID: 1 },
          { TenLI: "In bếp", ID: 2 },
          { TenLI: "Hóa đơn", ID: 3 },
        ]?.find((v) => v.ID === item)?.TenLI,
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "130px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Thêm sản phẩm vào quầy">
              <Button
                style={{ marginLeft: 5 }}
                type="primary"
                ghost
                icon={<IoIosAddCircle />}
                onClick={() => refAddSanPham.current.add(item)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 5 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 5 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns4 = [
    {
      title: "Mã kho",
      dataIndex: "MaKho",
    },
    {
      title: "Tên kho",
      dataIndex: "TenKho",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await SanPhamService.deleteLSP({ ID: Number(value) });
        break;
      case "1":
        _res = await SanPhamService.deleteDVT({ ID: Number(value) });
        break;
      case "2":
        _res = await SanPhamService.Categories.deleteCaiDatMayIn({
          ID: Number(value),
        });
        break;
      case "3":
        _res = await SanPhamService.Categories.deleteKhoHang({
          ID: Number(value),
        });
        break;
    }
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = [];
      switch (activeTab) {
        case "0":
          fil = _data.filter((v) => v.MaLSP !== Number(value));
          break;
        case "1":
          fil = _data.filter((v) => v.MaLSP !== Number(value));
          break;
        case "2":
          fil = _data.filter((v) => v.ID !== Number(value));
          break;
        case "3":
          fil = _data.filter((v) => v.ID !== Number(value));
          break;
      }
      setData(fil);
      openNotification("success", "topRight", "Xoá danh mục thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
    filter(newActiveKey);
    if (newActiveKey === "2") loadCateTab3();
  };
  return (
    <Box>
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              <Table
                loading={loading}
                dataSource={data}
                columns={
                  activeTab === "0"
                    ? columns
                    : activeTab === "1"
                    ? columns2
                    : activeTab === "2"
                    ? columns3
                    : columns4
                }
                scroll={{
                  y: window.innerHeight - 350,
                  // x: 2500
                }}
                size="small"
              />
            </TabPane>
          );
        })}
      </Tabs>

      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        options={{ LoaiSP: loaisp, KhuVuc: khuVuc }}
      />
      <FormAddSanPham ref={refAddSanPham} />
      {/* <FormAddImage ref={refDetailImage}  /> */}
      {contextHolder}
    </Box>
  );
}
