import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Image,
  DatePicker,
  Divider,
  Checkbox,
  message,
} from "antd";
import _ from "lodash";
import Webcam from "react-webcam";

import dayjs from "dayjs";
import vi from "dayjs/locale/vi";
import { SanPhamService } from "services/SanPhamService";
import { HomeService } from "services/HomeService";
import { FileUpload } from "primereact/fileupload";
import { CardService } from "services/CardService";
import DebounceSelect from "components/DebounceSelect";
import { API_URL } from "services/api_helper";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [files, setFiles] = useState([]);
  const [messageApi, contextHolderMS] = message.useMessage();
  const [api, contextHolder] = notification.useNotification();
  const refMaVach = useRef(null);
  const webcamRef = useRef(null);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaiSP, setLoaiSP] = useState([]);
  const [dvt, setDVT] = useState([]);
  const [customerGroup, setCustomerGroup] = useState([]);
  const [cardType, setCardType] = useState([]);

  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const loadCategories = async () => {
    let _restype = await CardService.getType();
    let _resgr = await CardService.getCustomerGroup();
    setCardType(_restype?.data ?? []);
    setCustomerGroup(_resgr?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setIsUpdate(true);
      setData(data);
    },
  }));
  useEffect(() => {
    if (refMaVach.current) {
      refMaVach.current.focus();
    }
  }, [refMaVach.current]);
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    console.log(v);
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    if (files?.length > 0) {
      var formData = new FormData();
      formData.append("Image", files[0]);
      formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
      let _resupload = await HomeService.upload(formData);
      if (_resupload.status === 2000) {
        _data.LinkImage = API_URL() + "/" + _resupload.data;
        setData((pre) => {
          return { ...pre, LinkImage: _data.LinkImage };
        });
      }
    }
    let _res = null;
    if (isUpdate) {
      _res = await SanPhamService.updateSanPham(_data);
    } else {
      _res = await SanPhamService.addSanPham(_data);
      setData((pre) => {
        return { ...pre, ID: _res.data };
      });
    }
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isUpdate ? "Sửa" : "Thêm"} sản phẩm thành công!`
      );
      if (close) onClose();
      setIsUpdate(true);
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchUserList(username) {
    return CardService.filterCustomer({ InputSearch: username }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenCongTy} - ${item.DiDong} - ${new Date(
          item.NgaySinh
        ).getDate()}/${new Date(item.NgaySinh).getMonth() + 1}/${new Date(
          item.NgaySinh
        ).getFullYear()}`,
        value: item.MaKH,
        title: item.TenCongTy,
        DiDong: item.DiDong,
      }));
    });
  }
  const applyChangeCard = async (v) => {
    let _data = _.cloneDeep(data);
    _data.MaVach = v;
    setData(_data);
  };
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }
  useEffect(() => {
    let time = null;
    if (data.MaVach) {
      time = setTimeout(async () => {
        let _res = await CardService.getCardByMaVach({ MaVach: data.MaVach });
        if (_res.status === 2000) {
          messageApi.open({
            type: "success",
            content: "Thẻ đang hoạt động",
          });
          let _kh = await CardService.getKhachHangByID({ ID: _res.data.MaKH });
          let _resupload; 
          try{
            const file = DataURIToBlob(webcamRef.current.getScreenshot());
            var formData = new FormData();
            formData.append("Image", file, "image.jpg");
            formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
            _resupload = await HomeService.upload(formData);
          }
          catch{}
          
          await CardService.addLichSuRaVao({
            MaThe: _res?.data?.ID,
            MaVach: data.MaVach,
            LinkImage: API_URL() + "/" + _resupload?.data,
            TenKH: _kh?.data?.TenCongTy,
            DiDong: _kh?.data?.DiDong,
          });
          setData((pre) => {
            return {
              ...pre,
              MaVach: "",
              SoThe: _res.data?.SoThe,
              MaLT: _res.data?.MaLT,
              NgayHH: _res.data?.NgayHH,
              NgayHL: _res.data?.NgayHL,
              NgayPH: _res.data?.NgayPH,
              MaKH: _res.data?.MaKH,

              TenKH: _kh?.data?.TenCongTy,
              NgaySinh: _kh?.data?.NgaySinh,
              Email: _kh?.data?.Email,
              DiDong: _kh?.data?.DiDong,
              MaNKH: _kh?.data?.MaNKH,
              GioiTinh: _kh?.data?.GioiTinh,
              DiaChiCT: _kh?.data?.DiaChiCT,
              HinhAnh2: _kh?.data?.HinhAnh2,
            };
          });
          onAfterSubmit();
        } else {
          messageApi.open({
            type: "error",
            content: _res.message,
          });
        }
      }, 1000);
    }
    return () => clearTimeout(time);
  }, [data.MaVach]);
  return (
    <Drawer
      title="Tra cứu thẻ"
      width={1220}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      // extra={
      //   <Space>
      //     <Button onClick={() => onSubmit()}>Lưu</Button>
      //     <Button onClick={() => onSubmit("print")} type="primary">
      //       Lưu & đóng
      //     </Button>
      //   </Space>
      // }
    >
      {contextHolderMS}
      {contextHolder}
      <Row gutter={[10, 20]}>
        <Col span={18}>
          <Row gutter={[10, 20]}>
            <Col span={8}>
              <p>Mã vạch</p>
              <Input
                ref={refMaVach}
                autoFocus
                value={data?.MaVach}
                placeholder="Nhập mã vạch"
                onChange={(e) => {
                  applyChangeCard(e.target.value);
                }}
              />
            </Col>
            <Col span={8}>
              <p>Số thẻ</p>
              <Input value={data?.SoThe} disabled />
            </Col>
            <Col span={8}>
              <p>Loại thẻ</p>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaLT}
                options={cardType}
                fieldNames={{ label: "TenLT", value: "ID" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <p>Ngày cấp</p>
              <DatePicker
                allowClear={false}
                format="DD-MM-YYYY"
                style={{ width: "100%" }}
                value={dayjs(data?.NgayPH)}
                locale={vi}
                disabled
              />
            </Col>
            <Col span={8}>
              <p>Ngày hiệu lực</p>
              <DatePicker
                allowClear={false}
                format="DD-MM-YYYY"
                style={{ width: "100%" }}
                value={dayjs(data?.NgayHL)}
                locale={vi}
                disabled
              />
            </Col>
            <Col span={8}>
              <p>Ngày hết hạn</p>
              <DatePicker
                allowClear={false}
                format="DD-MM-YYYY"
                style={{ width: "100%" }}
                value={dayjs(data?.NgayHH)}
                locale={vi}
                disabled
              />
            </Col>
            {/* <Col span={24}>
              <p>Diễn giải</p>
              <Input
                value={data?.TenSP}
                placeholder="Nhập tên sản phẩm"
                onChange={(e) => applyChange("TenSP", e.target.value)}
              />
            </Col> */}
          </Row>
        </Col>
        <Col span={6}>
          <Webcam
            audio={false}
            ref={webcamRef}
            height={720}
            screenshotFormat="image/jpeg"
            width={1280}
            // videoConstraints={{
            //   facingMode: "user",
            // }}
          >
            {/* {({ getScreenshot }) => (
              <button
                onClick={() => {
                  const imageSrc = getScreenshot();
                }}
              >
                Capture photo
              </button>
            )} */}
          </Webcam>
        </Col>

        <Divider
          orientation="left"
          style={{ margin: "15px 0px 0px", fontSize: 14 }}
        >
          Thông tin khách hàng
        </Divider>
        <Col span={18}>
          <Row gutter={[10, 20]}>
            <Col span={6}>
              <p>Họ tên</p>
              <Input value={data?.TenKH} disabled />
            </Col>
            <Col span={6}>
              <p>Ngày sinh</p>
              <DatePicker
                allowClear={false}
                format="DD-MM-YYYY"
                style={{ width: "100%" }}
                value={dayjs(data?.NgaySinh)}
                disabled
              />
            </Col>
            <Col span={6}>
              <p>Email</p>
              <Input value={data?.Email} disabled />
            </Col>
            <Col span={6}>
              <p>Di động</p>
              <Input value={data?.DiDong} disabled />
            </Col>
            <Col span={6}>
              <p>Nhóm khách hàng</p>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaNKH}
                options={customerGroup}
                fieldNames={{ label: "Name", value: "ID" }}
                disabled
              />
            </Col>
            <Col span={12}>
              <p>Địa chỉ</p>
              <Input value={data?.DiaChiCT} placeholder="Địa chỉ" disabled />
            </Col>
            <Col span={6}>
              <p>Giới tính</p>
              <Checkbox checked={data?.GioiTinh === true} disabled>
                Nam
              </Checkbox>
              <Checkbox checked={data?.GioiTinh === false} disabled>
                Nữ
              </Checkbox>
            </Col>
          </Row>
        </Col>

        <Col span={6}>
          <Image src={data?.HinhAnh2} />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
