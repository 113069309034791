import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Table,
  notification,
  Row,
  Col,
  Tooltip,
  Modal,
  Input,
  Tag,
  InputNumber,
  Select,
  Dropdown,
  Menu,
  Popconfirm,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import "./scss/index.scss";
import Toolbar from "components/Toolbar";
import { BanHangService } from "services/BanHangService";
import FormDetail from "./FormDetail";
import { IoEyeSharp } from "react-icons/io5";
import { GiPayMoney } from "react-icons/gi";
import { CiEdit } from "react-icons/ci";
import { OtherService } from "services/OtherService";
import { SoQuyService } from "services/SoQuyService";
import FormDetailEdit from "./FormDetailEdit";
import FormHistory from "./FormHistory";
import FormThemLuot from "./FormThemLuot";
import { IoMdAdd } from "react-icons/io";
import { DateTime } from "luxon";
import { BsMotherboard } from "react-icons/bs";
import {
  DownOutlined,
  EditTwoTone,
  ExportOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FormAddExcel from "./FormAddExcel";
import { NhanVienService } from "services/NhanVienService";
import { FaCheckCircle } from "react-icons/fa";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentDateTime = DateTime.utc().setZone("Asia/Ho_Chi_Minh");
  const startDate = currentDateTime.startOf("year");
  const endDate = currentDateTime.endOf("year");
  const [action, setAction] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: startDate.toISO(),
    DenNgay: endDate.toISO(),
    QuayBan: ",,",
    NhanVien: ",,",
    InputSearch: "",
    MaNhom: 1,
    MaCT: 0,
    FormID: 413,
    MaLHD: 4,
    IsGiaHan: null,
  });
  const [hinhthuc, setHinhThuc] = useState([]);
  const [activeTT, setActiveTT] = useState(1);

  const refAdd = useRef(null);
  const refHistory = useRef(null);
  const [lydo, setLydo] = useState("");
  const [open, setOpen] = useState(false);
  const refDetail = useRef(null);
  const refDetailEdit = useRef(null);
  const refThemLuot = useRef(null);
  const refID = useRef(null);
  const refAddExcel = useRef(null);
  const [dataPay, setDataPay] = useState({});
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const handleDuyet = async (data) => {
    let _res = await BanHangService.BanVeThang.duyet({
      MaThe: data?.MaThe,
      MaBH: data?.ID,
    });
    if (_res.status === 2000) {
      filter(filterCondition);
      openNotification("success", "topRight", `Duyệt thành công!`);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const columns = [
    {
      title: "Loại",
      render: (item) =>
        item ? (
          <Tag color={item?.IsGiaHan ? "red" : "blue"}>{item?.Loai}</Tag>
        ) : (
          ""
        ),
    },
    {
      title: "Ngày bán hàng",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
    },
    {
      title: "Ngày sử dụng",
      dataIndex: "NgayBH",
      render: (item) => Format_Date(item),
    },
    {
      title: "Số hoá đơn",
      dataIndex: "SoBH",
    },
    {
      title: "Số lượng",
      dataIndex: "SoLuongThe",
      render: (item) => item ?? 0,
    },
    {
      title: "Vé đã SD",
      // dataIndex: "DaSuDung",
      render: (item) => (
        <p
          onClick={() => refHistory.current.show(item)}
          style={{ fontWeight: "bold", fontSize: 16, cursor: "pointer" }}
        >
          {item?.DaSuDung ?? 0}
        </p>
      ),
    },
    {
      title: "Vé còn lại",
      // dataIndex: "ConLai",
      render: (item) => item.SoLuongThe - (item.DaSuDung ?? 0),
    },
    {
      title: "Lý do",
      dataIndex: "GhiChuTraLai",
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
    },
    {
      title: "Quầy vé",
      dataIndex: "TenQuay",
    },
    {
      title: "Khách hàng",
      dataIndex: "TenCongTy",
    },
    {
      title: "Hình thức TT",
      dataIndex: "HinhThucTT",
      render: (item) => hinhthuc?.find((v) => v.ID === item)?.TenHinhThuc,
    },
    {
      title: "Tổng tiền",
      dataIndex: "ThanhTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền CK",
      dataIndex: "TienCK",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tổng sau CK",
      dataIndex: "KhachTra",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Đã thanh toán",
      dataIndex: "ThanhToan",
      render: (item) => Format_Currency(item ?? 0),
    },

    {
      title: "Còn nợ",
      fixed: "right",
      width: "80px",
      render: (item) => Format_Currency(item.KhachTra - (item.ThanhToan ?? 0)),
    },
    {
      title: "Trạng thái",
      fixed: "right",
      dataIndex: "State",
      width: "100px",
      render: (item) => <Tag color={stateColor[item]}>{state[item]}</Tag>,
    },
    {
      title: "",
      fixed: "right",
      width: "140px",
      render: (item) => {
        return (
          <div>
            {checkAction(1) &&
              item?.IsGiaHan &&
              state[item?.State] === "Mới" && (
                <Popconfirm
                  title="Duyệt"
                  description="Bạn có chắc chắn duyệt không?"
                  okText="Đồng ý"
                  cancelText="Không"
                  onConfirm={() => handleDuyet(item)}
                >
                  <Button
                    style={{
                      marginLeft: 5,
                      marginBottom: 5,
                      color: "green",
                      border: "1px solid green",
                    }}
                    icon={<FaCheckCircle />}
                  />
                </Popconfirm>
              )}
            {checkAction(1) && (
              <Tooltip placement="topRight" title="Thêm lượt vé">
                <Button
                  style={{ marginLeft: 5 }}
                  type="primary"
                  icon={<IoMdAdd />}
                  onClick={() => refThemLuot.current.show(item)}
                />
              </Tooltip>
            )}

            {item.KhachTra - item.ThanhToan > 0 && checkAction(8) && (
              <Tooltip placement="topRight" title="Thanh toán">
                <Button
                  style={{ marginLeft: 5 }}
                  type="primary"
                  danger
                  icon={<GiPayMoney />}
                  onClick={() => {
                    setOpen(true);
                    setDataPay(item);
                    // OtherService.getHinhThucTT().then((res) => {
                    //   setHinhThuc(res.data ?? []);
                    // });
                  }}
                />
              </Tooltip>
            )}
            {item.State === "NEW" && (
              <Tooltip placement="topRight" title="Bỏ / thêm vé">
                <Button
                  style={{ marginLeft: 5 }}
                  // type="primary"
                  danger
                  ghost
                  icon={<CiEdit />}
                  onClick={() => refDetailEdit.current.show(item)}
                />
              </Tooltip>
            )}

            {item.KhachTra - (item.ThanhToan ?? 0) <= 0 && checkAction(38) && (
              <Tooltip placement="topRight" title="Chi tiết">
                <Button
                  style={{ marginLeft: 5 }}
                  type="primary"
                  ghost
                  icon={<IoEyeSharp />}
                  onClick={() => refDetail.current.show(item)}
                />
              </Tooltip>
            )}

            {checkAction(2) && (
              <Tooltip placement="topRight" title="Sửa">
                <Button
                  disabled={
                    item.SoLuongThe - (item.DaSuDung ?? 0) <= 0 ? true : false
                  }
                  style={{ marginLeft: 10 }}
                  type="primary"
                  ghost
                  icon={<EditTwoTone />}
                  onClick={() => refAdd.current.update(item)}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const loadcategories = async () => {
    let _ht = await OtherService.getHinhThucTT();
    setHinhThuc(_ht.data ?? []);
    NhanVienService.getAction({ FormID: 413 }).then((res) =>
      setAction(res?.data)
    );
  };
  const checkAction = (id) => {
    return action?.some((v) => v.FeatureID === id);
  };

  useEffect(() => {
    loadcategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);

    let _res = await BanHangService.BanVeThang.getList(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const state = {
    NEW: "Mới",
    PAYMENT: "Đã thanh toán",
    CANCEL: "Đã huỷ",
    REJECT: "Từ chối",
    ACCEPT: "Đã duyệt",
    RETURN: "Hoàn trả",
  };
  const stateColor = {
    NEW: "blue",
    PAYMENT: "cyan",
    CANCEL: "orange",
    REJECT: "red",
    ACCEPT: "green",
    RETURN: "#87d068",
  };

  const renderModal = () => {
    const hide = () => {
      setOpen(false);
      setDataPay({});
    };
    const applyChangeKH = (p, v) => {
      let _data = _.cloneDeep(dataPay);
      _data[p] = v;
      setDataPay(_data);
    };
    const onSubmit = async () => {
      setLoading(true);
      let _res = await SoQuyService.PhieuThu.addPhieuThu({
        NgayThu: new Date(),
        LyDo: "Thu tiền bán vé kinh doanh",
        MaBH: dataPay.ID,
        MaKH: dataPay.MaKH,
        MaHinhThucTT: dataPay.MaHinhThucTT,
        ThanhTien: dataPay.ConLai,
      });
      if (_res.status === 2000) {
        filter(filterCondition);
        openNotification("success", "topRight", `Thanh toán thành công!`);
        hide();
      } else {
        openNotification("error", "topRight", _res?.message);
      }
      setLoading(false);
    };
    return (
      <Modal
        title={
          "Thanh toán: " +
          Format_Currency(dataPay?.KhachTra - (dataPay?.ThanhToan ?? 0))
        }
        open={open}
        width={300}
        onOk={() => onSubmit()}
        onCancel={hide}
        okText="Lưu"
        cancelText="Huỷ"
      >
        <Row gutter={[0, 10]} style={{ margin: "20px 0px" }}>
          <Col span={24}>
            {/* <p style={{ fontWeight: "bold" }}>
              Còn nợ:{" "}
              {Format_Currency(dataPay?.KhachTra - (dataPay?.ThanhToan ?? 0))}
            </p> */}
            <p>Nhập số tiền thanh toán</p>
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              max={dataPay?.KhachTra - (dataPay?.ThanhToan ?? 0)}
              value={dataPay?.ConLai}
              placeholder="Nhập tiền khách trả"
              style={{ width: "100%" }}
              onChange={(v) => applyChangeKH("ConLai", v)}
            />
          </Col>
          <Col span={24}>
            <p>Hình thức thanh toán</p>
            <Select
              style={{
                width: "100%",
              }}
              value={dataPay.MaHinhThucTT}
              placeholder="Chọn hình thức"
              onChange={(v) => applyChangeKH("MaHinhThucTT", v)}
              options={hinhthuc}
              fieldNames={{ label: "TenHinhThuc", value: "ID" }}
            />
          </Col>
        </Row>
      </Modal>
    );
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        filter(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = async () => {
    let _ft = _.cloneDeep(filterCondition);
    // _ft.PageSize = 5000;
    // let _res = await DichVuService.Nuoc.get(_ft);
    let _res = await BanHangService.BanVeThang.getList(_ft);
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = _res?.data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách bán vé tháng" + fileExtension);
  };
  const handleMenuClick2 = (e) => {
    // if (e.key !== "1" && e.key !== "2" && selectedRowKeys.length < 1) {
    //   openNotification(
    //     "error",
    //     "topRight",
    //     "Vui lòng chọn mặt bằng, xin cảm ơn."
    //   );
    //   return;
    // }

    switch (e.key) {
      case "1":
        refAddExcel.current.show();
        break;
      case "2":
        exportToCSV();
        break;

      default:
        break;
    }
  };

  const thaoTacKhac = (
    <Menu onClick={handleMenuClick2}>
      {checkAction(16) && (
        <Menu.Item key="1">
          {" "}
          <ImportOutlined style={{ paddingRight: "8px" }} />
          Import
        </Menu.Item>
      )}

      {checkAction(44) && (
        <Menu.Item key="2">
          <ExportOutlined style={{ paddingRight: "8px" }} />
          Export
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          setActiveTT={setActiveTT}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              placeholder="Tìm kiếm tên, điện thoại, số hoá đơn"
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          right={() => (
            <div style={{ display: "flex" }}>
              {checkAction(1) && (
                <Button
                  style={{ marginRight: "10px" }}
                  type="primary"
                  onClick={() => refAdd.current.show()}
                >
                  Thêm
                </Button>
              )}
              <Dropdown overlay={thaoTacKhac} trigger={["click"]}>
                <Button style={{ display: "flex", alignItems: "center" }}>
                  <BsMotherboard style={{ marginRight: "5px" }} /> Thao tác khác
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          )}
        />
        <Table
          style={{
            height: 100,
            // boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            // borderRadius:0
          }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            x: 2100,
          }}
          size="small"
        />
      </Col>
      <FormAdd ref={refAdd} onAfterSubmit={onAfterSubmit} />
      <FormDetail ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormDetailEdit ref={refDetailEdit} onAfterSubmit={onAfterSubmit} />
      <FormThemLuot ref={refThemLuot} onAfterSubmit={onAfterSubmit} />
      <FormHistory ref={refHistory} />
      {renderModal()}
      {contextHolder}
      <FormAddExcel ref={refAddExcel} onAfterSubmit={onAfterSubmit} />
    </Row>
  );
}
