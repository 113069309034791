import React, { useEffect, useRef, useState } from "react";
import { Table, Row, Col, Tag, Flex } from "antd";
import FilterModal from "./FilterModal";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import ExcelJS from "exceljs";
import { Format_Datetime } from "layouts/ultis/common";
import { NhanVienService } from "services/NhanVienService";

export default function Settings() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    FormID: 420,
    TuNgay: new Date(),
    DenNgay: new Date(),
    MaCT: 0,
    NhanVien: ",,",
  });
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await NhanVienService.getLSChamCong(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };

  const columns = [
    {
      title: "Mã nhân viên",
      dataIndex: "MaSo",
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
    },
    {
      title: "Di động",
      dataIndex: "DienThoai",
    },
    {
      title: "Số CMND",
      dataIndex: "SoCMND",
    },
    {
      title: "Giờ CheckIn ",
      dataIndex: "DateCheckIn",
      render: (item) => Format_Datetime(item),
    },
    {
      title: "Giờ CheckOut ",
      dataIndex: "DateCheckOut",
      render: (item) => item ? Format_Datetime(item) : "Chưa checkout",
    },
    {
      title: "Số giờ",
      dataIndex: "SoPhut",
      render: (item) => (item / 60).toFixed(2),
    },
    {
      title: "Tiền dự kiến",
      render: (item) =>
        Format_Currency((item.SoPhut / 60).toFixed(2) * item.TienTrenGio),
    },
  ];
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            // x: 2000,
          }}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={8}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData.length}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "ThanhTien")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TienCK")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "KhachTra")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
}
