import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Modal,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import DebounceSelect from "components/DebounceSelect";
import { SoQuyService } from "services/SoQuyService";
import { CustomerService } from "services/CustomerService";
import { OtherService } from "services/OtherService";
import { BanHangService } from "services/BanHangService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    NgayChi: new Date(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hinhthuc, setHinhThuc] = useState([]);
  const [loai, setLoai] = useState([]);
  const [calamviec, setCalamviec] = useState({});
  const [openCaLV, setOpenCaLV] = useState(false);
  const [dataquay, setDataquay] = useState({});
  const [quayve, setQuayve] = useState([]);

  const defaultErrors = {
    TongTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const loadcategories = async () => {
    let _ht = await OtherService.getHinhThucTT();
    setHinhThuc(_ht.data ?? []);
    let _loai = await SoQuyService.PhieuChi.getLoaiPhieuChi();
    setLoai(_loai.data ?? []);

     let _qv = await BanHangService.getQuayBan();
     setQuayve(_qv.data ?? []);
    let _dataquay = _.cloneDeep(dataquay);
    _dataquay.MaQuay = _qv.isDefault;
    setDataquay(_dataquay);
    let _check = await BanHangService.checkCaLamViec();
    if (!_check.data) {
      setOpenCaLV(true);
    } else {
      setCalamviec(_check.data);
    }
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      loadcategories();
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      loadcategories();
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    setErrors(defaultErrors);
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    // if (_data.MaKH?.length === 0) {
    //   setLoading(false);
    //   return;
    // }
    if (Array.isArray(_data.MaKH)) {
      _data.MaKH = _data.MaKH[0].value;
    }
    _data.MaCaLamViec = calamviec.ID;
    let _res = null;
    if (isupdate) {
      _res = await SoQuyService.PhieuChi.updatePhieuChi(_data);
    } else {
      _res = await SoQuyService.PhieuChi.addPhieuChi(_data);
      setData((pre) => {
        return { ...pre, ID: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} phiếu chi thành công!`
      );
      // setIsUpdate(true);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchUserList(username) {
    return CustomerService.filter({ InputSearch: username }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenCongTy} - ${item.DiDong} - ${new Date(
          item.NgaySinh
        ).getDate()}/${new Date(item.NgaySinh).getMonth() + 1}/${new Date(
          item.NgaySinh
        ).getFullYear()}`,
        value: item.MaKH,
        TenKH: item.TenCongTy,
        DiDong: item.DiDong,
      }));
    });
  }
  const renderBatDauCa = () => {
    const saveCaLamViec = async () => {
      setLoading(true);
      let _res = await BanHangService.addCaLamViec(dataquay);
      if (_res.status === 2000) {
        setOpenCaLV(false);
        setCalamviec({
          ID: _res.data,
          TienDauCa: dataquay.TienDauCa,
          GioVaoCa: new Date(),
          MaQuay: dataquay.MaQuay,
        });
      }
      setLoading(false);
    };
    const appllyChange = (p, v) => {
      let _data = _.cloneDeep(dataquay);
      _data[p] = v;
      setDataquay(_data);
    };
    return (
      <Modal
        closeIcon={false}
        title="KHAI BÁO TIỀN ĐẦU CA"
        open={openCaLV}
        onOk={() => saveCaLamViec()}
        onCancel={() => {
          setOpenCaLV(false);
        }}
        cancelText="Huỷ"
        okText="Lưu"
      >
        <div>
          <p style={{ marginTop: 10 }}>Quầy bán</p>
          <Select
            style={{
              width: "100%",
            }}
            value={dataquay?.MaQuay}
            placeholder="Chọn quầy bán"
            onChange={(v) => appllyChange("MaQuay", v)}
            options={quayve}
            fieldNames={{ label: "TenQuay", value: "ID" }}
          />
          <p style={{ marginTop: 10 }}>Tiền đầu ca</p>
          <InputNumber
            suffix="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            placeholder="Nhập tiền đầu ca"
            style={{ width: "100%" }}
            onChange={(v) => appllyChange("TienDauCa", v)}
          />
        </div>
      </Modal>
    );
  };
  return (
    <Drawer
      title="PHIẾU CHI"
      width={620}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {/* <Button
            loading={loading}
            onClick={() => onSubmit(false)}
            type="primary"
            ghost
          >
            Lưu
          </Button> */}
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 12]}>
        {/* <Col span={24} className="gutter-row">
          <p>
            Khách hàng <span style={{ color: "red" }}>*</span>
          </p>
          <DebounceSelect
            mode="multiple"
            value={data?.MaKH}
            placeholder="Tìm kiểm khách hàng"
            status={data?.MaKH ? "" : "error"}
            fetchOptions={fetchUserList}
            onChange={(newValue) => applyChange("MaKH", newValue)}
            style={{
              width: "100%",
            }}
          />
          <p style={{ color: "red" }}>{errors?.MaKH}</p>
        </Col> */}
        <Col span={12}>
          <p>Người nhận</p>
          <Input
            value={data?.NguoiNhan}
            placeholder="Nhập người nhận"
            onChange={(v) => applyChange("NguoiNhan", v.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Di động</p>
          <Input
            value={data?.DiDong}
            placeholder="Nhập di động"
            onChange={(v) => applyChange("DiDong", v.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Địa chỉ</p>
          <Input
            value={data?.DiaChiNN}
            placeholder="Nhập địa chỉ người nhận"
            onChange={(v) => applyChange("DiaChiNN", v.target.value)}
          />
        </Col>
        <Col span={12}>
          <p style={{ color: "red" }}>Hình thức thanh toán</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaPTTT}
            placeholder="Chọn hình thức thanh toán"
            onChange={(v) => applyChange("MaPTTT", v)}
            options={hinhthuc}
            fieldNames={{ label: "TenHinhThuc", value: "ID" }}
          />
        </Col>
        <Col span={12}>
          <p style={{ color: "red" }}>Loại chi</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaLPC}
            placeholder="Chọn loại phiếu chi"
            onChange={(v) => applyChange("MaLPC", v)}
            options={loai}
            fieldNames={{ label: "TenLPC", value: "ID" }}
          />
        </Col>
        <Col span={24}>
          <p>Ngày chi</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.NgayChi)}
            locale={vi}
            onChange={(v) => applyChange("NgayChi", v)}
          />
        </Col>

        <Col span={24}>
          <p style={{ color: "red" }}>Số tiền</p>
          <InputNumber
            style={{ width: "100%" }}
            addonAfter="vnđ"
            value={data?.TongTien}
            status={data?.TongTien ? "" : "error"}
            onChange={(v) => applyChange("TongTien", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          <p style={{ color: "red" }}>{errors?.TongTien}</p>
        </Col>

        <Col span={24}>
          <p>Lý do</p>
          <TextArea
            value={data?.LyDo}
            onChange={(e) => applyChange("LyDo", e.target.value)}
            placeholder="Nhập lý do"
          />
        </Col>
      </Row>
      {renderBatDauCa()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
