import axiosApi from "./api_helper";
export const PermissionService = {
  checkQuyen: async (payload) => {
    return await axiosApi()
      .post("api/permission/check", payload)
      .then((res) => res.data);
  },
  getNhomQuyen: async () => {
    return await axiosApi()
      .post("api/nhanvien/get-nhom-quyen")
      .then((res) => res.data);
  },
  addNhomQuyen: async (payload) => {
    return await axiosApi()
      .post("api/permission/add-update", payload)
      .then((res) => res.data);
  },
  deleteNhomQuyen: async (payload) => {
    return await axiosApi()
      .post("api/permission/delete", payload)
      .then((res) => res.data);
  },
  getMenuLevel1: async (payload) => {
    return await axiosApi()
      .post("api/permission/get-modules", payload)
      .then((res) => res.data);
  },
  getForm: async (payload) => {
    return await axiosApi()
      .post("api/permission/get-form", payload)
      .then((res) => res.data);
  },
  addAccessData: async (payload) => {
    return await axiosApi()
      .post("api/permission/add-accessdata", payload)
      .then((res) => res.data);
  },
  updateAccessData: async (payload) => {
    return await axiosApi()
      .post("api/permission/update-accessdata", payload)
      .then((res) => res.data);
  },
  addAction: async (payload) => {
    return await axiosApi()
      .post("api/permission/add-action", payload)
      .then((res) => res.data);
  },
  deleteAction: async (payload) => {
    return await axiosApi()
      .post("api/permission/remove-action", payload)
      .then((res) => res.data);
  },
  TheoCongTy: {
    getCongTyDangKy: async () => {
      return await axiosApi()
        .post("api/cong-ty/get")
        .then((res) => res.data);
    },
    addForm: async (payload) => {
      return await axiosApi()
        .post("api/cong-ty/permission/add-form", payload)
        .then((res) => res.data);
    },
    getForm: async (payload) => {
      return await axiosApi()
        .post("api/cong-ty/permission/get-form", payload)
        .then((res) => res.data);
    },
    getMenu: async () => {
      return await axiosApi()
        .post("api/cong-ty/get-menu")
        .then((res) => res.data);
    },
  },
  TheoNhomModules: {
    addForm: async (payload) => {
      return await axiosApi()
        .post("api/nhom-modules/add-form", payload)
        .then((res) => res.data);
    },
    getForm: async (payload) => {
      return await axiosApi()
        .post("api/nhom-modules/get-form", payload)
        .then((res) => res.data);
    },
    getFormByMaNhom: async (payload) => {
      return await axiosApi()
        .post("api/nhom-modules/get-form-bymanhom", payload)
        .then((res) => res.data);
    },
  },
};
