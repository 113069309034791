import React, { useEffect, useRef, useState } from "react";
import { Table, notification, Row, Col, Button } from "antd";
import FilterModal from "./FilterModal";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import "./scss/index.scss";
import Toolbar from "components/Toolbar";
import { ReportService } from "services/ReportService";
import ExcelJS from "exceljs"

export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    QuayBan: ",,",
    MaCT: 0,
    NhanVien: ",,",
    LoaiHD: ",,",
    KhachHang: ",,",
  });
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await ReportService.getBanHangTheoSP(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const columns = [
    // {
    //   title: "Ngày bán hàng",
    //   dataIndex: "NgayBH",
    //   render: (item) => Format_Date(item),
    // },
    {
      title: "Tên sản phẩm",
      dataIndex: "TenSP",
    },
    {
      title: "Số lượng",
      dataIndex: "SoLuong",
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tổng tiền",
      dataIndex: "ThanhTien",
      render: (item) => Format_Currency(item),
    },

    // {
    //   title: "Ngày nhập",
    //   dataIndex: "NgayNhap",
    //   render: (item) => Format_Date(item),
    // },
  ];
  const exportExcel = () =>{
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("BaoCao");
    sheet.properties.defaultRowHeight = 80;
    sheet.columns = [
      {
        header: "Tên sản phẩm",
        key: "tensp",
        width: 30,
      },
      {
        header: "Số lượng",
        key: "soluong",
        width: 10,
      },
      {
        header: "Đơn giá",
        key: "dongia",
        width: 20,
      },
      {
        header: "Thành tiền",
        key: "thanhtien",
        width: 20,
      },
    ];
    data?.map((item)=>{
      sheet.addRow({
        tensp: item.TenSP,
        soluong: item.SoLuong,
        dongia: item.DonGia,
        thanhtien: item.ThanhTien
      })
    })
    sheet.addRow({
      tensp: "Tổng: " + data.length,
      soluong: _.sumBy(data, (o) => o.SoLuong),
      dongia: 0,
      thanhtien: _.sumBy(data, (o) => o.ThanhTien),
    });
    workbook.xlsx.writeBuffer().then(data=>{
      const blob = new Blob([data],{
        type:"application/vnd.openxmlformats-officedocument.spreadsheet.sheet"
      })
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "baocaotheosanpham.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    })
  }
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
        right={() => (
          <Button type="primary" onClick={() => exportExcel()}>
            Export
          </Button>
        )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            // x: 2500
          }}
          size="small"
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100", "500", "5000"],
          }}
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <p style={{ fontWeight: "bold" }}>Tổng</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "SoLuong")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    {/* <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TienCK")))}
                    </p> */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "ThanhTien")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
      {contextHolder}
    </Row>
  );
}
