import React, {
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Row, Col, Drawer, notification } from "antd";
import _ from "lodash";
import { CustomerService } from "services/CustomerService";
import { VisaService } from "services/VisaService";
import { API_UPLOAD_URL } from "services/UploadService";
import Upload from "./Upload";
import { TaiLieuService } from "services/TaiLieuService";
const FormTaiLieu = (props, ref) => {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [files,setFiles] = useState([]);
  const loadCategories = async (MaTL) => {
    let _res = await TaiLieuService.File.getList({ MaTL: MaTL });
    setFiles(_res.data);
  };
  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      setData(data);
      await loadCategories(data.ID);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = await CustomerService.addChamSoc(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Thêm lịch sử thành công!`);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const fileType = (file) => {
    let _file = file?.slice(file.lastIndexOf("."));
    return _file;
  };
  const getFileName = (url) => {
    let filename = url.split("/")?.pop();
    let fileNotType = filename?.split(".")?.shift();
    return fileNotType;
  };
  const affterUpload = async (files) => {
    let imgs = [];
    files?.map((item)=>{
      imgs.push({
        Link: API_UPLOAD_URL + item,
        Type: fileType(item),
        Name: getFileName(item),
      });
    });
    await TaiLieuService.File.add({
      MaTL: data?.ID,
      Files: imgs,
    });
    await loadCategories(data.ID);
  };
  const onRemove = async (file) => {
    console.log(files);
    let _file = files.find(v=>v.Link === file.url);
    await TaiLieuService.File.delete({
      ID: _file?.ID,
    });
  };
  return (
    <Drawer
      title="Tài liệu"
      width={500}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={24}>
          <p style={{ textAlign: "center", fontWeight: "bold", marginTop: 10 }}>
            {data?.TenHoSo}
          </p>
        </Col>
        <Col span={24}>
          <Upload
            files={_.map(files, "Link")}
            affterUpload={affterUpload}
            onRemove={onRemove}
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormTaiLieu);
