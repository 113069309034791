import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Calendar,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Input,
  notification,
  Row,
  Table,
  Tabs,
} from "antd";

import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useRef, useState } from "react";
import { FaUser } from "react-icons/fa6";
import "./index.scss";
import _, { includes } from "lodash";
import dayjs, { locale } from "dayjs";
// import { InfoService } from "services/infoService";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import { InfoService } from "services/InfoService";

const InfoUser = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [chucNang, setChucNang] = useState([]);
  const [caiDat, setCaiDat] = useState([]);
  const [checkedList, setCheckedList] = useState({
    newTask: false,
    mentionTask: false,
    dailySummary: false,
    newOrder: false,
    changeOrder: false,
    approveOrder: false,
    cancelOrder: false,
    thuEmail: false,
    chiEmail: false,
  });
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    // setActiveKey("3")
    if (location.state && location.state.activeKey) {
      setActiveKey(location.state.activeKey);
    }
  }, [location.state]);

  const filter = async () => {
    let _resCN = await InfoService.getChucNang();
    if (_resCN.data) setChucNang(_resCN.data);
    let _resCD = await InfoService.getCaiDat();
    if (_resCD.data) setCaiDat(_resCD.data);

    // setToanha(_tn.data);
    // let _filter = _.cloneDeep(filterCondition);
    // _filter.MaTN = _tn.data[0]?.MaTN;
    // setFilterCondition(_filter);
    // loadcategoriesByMaTN(_tn.data[0]?.MaTN);
  };

  useEffect(() => {
    filter();
  }, []);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onChange = (field) => (e) => {
    setCheckedList({
      ...checkedList,
      [field]: e.target.checked,
    });
  };

  const ThietLapThongBao = () => {
    const applyChange = async (key, value, record) => {
      setLoading(true);
      let newData = caiDat;
      if (caiDat.length !== chucNang.length * 2) {
        let _resCD = await InfoService.getCaiDat();
        if (_resCD.data) {
          newData = _resCD.data;
          setCaiDat(_resCD.data);
        }
      }
      const _caiDat = _.cloneDeep(newData);
      let _res;
      if (key === "ALL") {
        // Trường hợp `key` là "ALL"
        if (record.ALL === true) {
          _res = await InfoService.addCaiDat({
            MaChucNang: Number(record.key),
            LoaiThietBi: record.LoaiThietBi,
            HanhDong: "", // Mảng rỗng
          });
          setCaiDat(
            _caiDat.map((item) =>
              item.MaChucNang === Number(record.key) &&
              item.LoaiThietBi === record.LoaiThietBi
                ? { ...item, HanhDong: "" }
                : item
            )
          );
        } else {
          _res = await InfoService.addCaiDat({
            MaChucNang: Number(record.key),
            LoaiThietBi: record.LoaiThietBi,
            HanhDong: ["ALL", "ADD", "EDIT", "DELETE", "OTHER"].join(","), // Thêm tất cả hành động
          });
          setCaiDat(
            _caiDat.map((item) =>
              item.MaChucNang === Number(record.key) &&
              item.LoaiThietBi === record.LoaiThietBi
                ? {
                    ...item,
                    HanhDong: ["ALL", "ADD", "EDIT", "DELETE", "OTHER"].join(
                      ","
                    ),
                  }
                : item
            )
          );
        }
      } else {
        // Trường hợp `key` không phải "ALL"
        const foundItem = _caiDat.find(
          (item) =>
            item.MaChucNang === Number(record.key) &&
            item.LoaiThietBi === record.LoaiThietBi
        );

        let newArr = foundItem?.HanhDong
          ? foundItem.HanhDong.split(",").filter(Boolean)
          : [];

        if (record[key] === true) {
          newArr = newArr.filter(
            (action) => action !== key && action !== "ALL"
          );

          _res = await InfoService.addCaiDat({
            MaChucNang: Number(record.key),
            LoaiThietBi: record.LoaiThietBi,
            HanhDong: newArr.join(","),
          });

          setCaiDat(
            _caiDat.map((item) =>
              item.MaChucNang === Number(record.key) &&
              item.LoaiThietBi === record.LoaiThietBi
                ? {
                    ...item,
                    HanhDong: newArr.join(","),
                  }
                : item
            )
          );
        } else {
          if (!newArr.includes(key)) {
            newArr.push(key);
          }
          if (
            newArr.includes("ADD") &&
            newArr.includes("EDIT") &&
            newArr.includes("DELETE") &&
            newArr.includes("OTHER") &&
            !newArr.includes("ALL")
          ) {
            newArr.push("ALL");
          }

          _res = await InfoService.addCaiDat({
            MaChucNang: Number(record.key),
            LoaiThietBi: record.LoaiThietBi,
            HanhDong: newArr.join(","),
          });
          setCaiDat(
            _caiDat.map((item) =>
              item.MaChucNang === Number(record.key) &&
              item.LoaiThietBi === record.LoaiThietBi
                ? {
                    ...item,
                    HanhDong: newArr.join(","),
                  }
                : item
            )
          );
        }
      }

      setLoading(false);
      if (_res.status === 2000) {
        openNotification("success", "topRight", `Thao tác thành công!`);
      } else {
        openNotification("error", "topRight", _res?.message);
      }
    };

    const dataSource = chucNang.flatMap((item) => {
      const webSetting = caiDat.find(
        (setting) =>
          setting.MaChucNang === item.ID && setting.LoaiThietBi === "WEB"
      );
      const appSetting = caiDat.find(
        (setting) =>
          setting.MaChucNang === item.ID && setting.LoaiThietBi === "APP"
      );

      const webData = {
        key: `${item?.ID}`,
        ChucNang: item?.Name,
        LoaiThietBi: "WEB",
        ALL: webSetting?.HanhDong?.includes("ALL") || false,
        ADD: webSetting?.HanhDong?.includes("ADD") || false,
        EDIT: webSetting?.HanhDong?.includes("EDIT") || false,
        DELETE: webSetting?.HanhDong?.includes("DELETE") || false,
        OTHER: webSetting?.HanhDong?.includes("OTHER") || false,
      };

      // Tạo cấu hình cho APP
      const appData = {
        key: `${item?.ID}`,
        ChucNang: item?.Name,
        LoaiThietBi: "APP",
        ALL: appSetting?.HanhDong?.includes("ALL") || false,
        ADD: appSetting?.HanhDong?.includes("ADD") || false,
        EDIT: appSetting?.HanhDong?.includes("EDIT") || false,
        DELETE: appSetting?.HanhDong?.includes("DELETE") || false,
        OTHER: appSetting?.HanhDong?.includes("OTHER") || false,
      };

      // Trả về mảng gồm cả dữ liệu cho WEB và APP
      return [webData, appData];
    });

    const columns = [
      {
        title: "Chức năng",
        dataIndex: "ChucNang",
        render: (text, record, index) => {
          if (index % 2 === 0) {
            return {
              children: text,
              props: {
                rowSpan: 2,
              },
            };
          }
          if (index % 2 === 1) {
            return {
              children: null,
              props: {
                rowSpan: 0,
              },
            };
          }
          return text;
        },
      },
      {
        title: "Loại thiết bị",
        dataIndex: "LoaiThietBi",
        key: "LoaiThietBi",
      },
      {
        title: "Tất cả",
        dataIndex: "ALL",
        key: "ALL",
        render: (item, record) => (
          <Checkbox
            onChange={(e) => applyChange("ALL", e.target.checked, record)}
            value={"ALL"}
            checked={item}
          ></Checkbox>
        ),
      },
      {
        title: "Thêm",
        dataIndex: "ADD",
        key: "ADD",
        render: (item, record) => (
          <Checkbox
            onChange={(e) => applyChange("ADD", e.target.checked, record)}
            checked={item}
          ></Checkbox>
        ),
      },
      {
        title: "Cập nhật",
        dataIndex: "EDIT",
        key: "EDIT",
        render: (item, record) => (
          <Checkbox
            onChange={(e) => applyChange("EDIT", e.target.checked, record)}
            checked={item}
          ></Checkbox>
        ),
      },
      {
        title: "Xóa",
        dataIndex: "DELETE",
        key: "DELETE",
        render: (item, record) => (
          <Checkbox
            onChange={(e) => applyChange("DELETE", e.target.checked, record)}
            checked={item}
          ></Checkbox>
        ),
      },
      {
        title: "Khác",
        dataIndex: "OTHER",
        key: "OTHER",
        render: (item, record) => (
          <Checkbox
            onChange={(e) => applyChange("OTHER", e.target.checked, record)}
            checked={item}
          ></Checkbox>
        ),
      },
    ];

    return (
      <>
        <Flex
          style={{ marginBottom: "5px" }}
          justify="space-between"
          align="center"
        >
          <h3
            style={{
              fontSize: "20px",
              color: "black",
              fontWeight: "600",
              paddingBottom: "10px",
            }}
          >
            Thiết lập nhận thông báo
          </h3>
          <div>
            {/* <Button type="primary" style={{ marginRight: "5px" }}>
              Nhận tất cả
            </Button>
            <Button  type="primary" danger>
              Bỏ nhận tất cả
            </Button> */}
          </div>
        </Flex>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          bordered
          scroll={{
            y: window.innerHeight - 250,
            x: 1000,
          }}
        />
      </>
    );
  };

  const LichSuDangNhap = () => {
    const dataSource = [
      {
        key: "1",
        stt: 1,
        time: "14:46:38 06-10-2024",
        ip: "117.6.78.123",
        device: "PC",
      },
      {
        key: "2",
        stt: 2,
        time: "13:43:54 04-10-2024",
        ip: "117.6.78.123",
        device: "PC",
      },
      {
        key: "3",
        stt: 3,
        time: "13:36:29 04-10-2024",
        ip: "118.70.127.204",
        device: "PC",
      },
      {
        key: "4",
        stt: 4,
        time: "20:35:55 19-09-2024",
        ip: "123.24.161.122",
        device: "PC",
      },
      {
        key: "5",
        stt: 5,
        time: "09:12:43 18-06-2024",
        ip: "222.252.39.223",
        device: "PC",
      },
      {
        key: "6",
        stt: 6,
        time: "06:29:29 16-05-2024",
        ip: "113.178.33.36",
        device: "PC",
      },
      {
        key: "7",
        stt: 7,
        time: "11:22:20 07-05-2024",
        ip: "14.248.68.78",
        device: "PC",
      },
      {
        key: "8",
        stt: 8,
        time: "17:34:30 19-04-2024",
        ip: "222.254.11.25",
        device: "PC",
      },
      {
        key: "9",
        stt: 9,
        time: "14:46:02 17-04-2024",
        ip: "222.252.101.223",
        device: "PC",
      },
    ];

    const columns = [
      {
        title: "#",
        dataIndex: "stt",
        key: "stt",
      },
      {
        title: "Thời gian",
        dataIndex: "time",
        key: "time",
      },
      {
        title: "Địa chỉ IP",
        dataIndex: "ip",
        key: "ip",
      },
      {
        title: "Loại thiết bị",
        dataIndex: "device",
        key: "device",
      },
    ];

    return (
      <>
        <h3
          style={{
            fontSize: "20px",
            color: "black",
            fontWeight: "600",
            paddingBottom: "10px",
          }}
        >
          Lịch sử đăng nhập
        </h3>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          bordered
        />{" "}
      </>
    );
  };
  return (
    <div>
      {/* <div>
        <Flex style={{ marginTop: "10px" }} align="center">
          <FaUser style={{ fontSize: "25px", marginRight: "8px" }} />
          Quản lý thông tin cá nhân
        </Flex>
      </div>
      <Row justify="" style={{ padding: "20px 0" }}>
        <Col xs={24}>
          <div style={{ color: "#fff" }}>
            <Tabs
              activeKey={activeKey}
              onChange={handleTabChange}
              style={{ width: "100%" }}
              className="custom-tabs"
              defaultActiveKey="1"
            >
              <TabPane tab="Thông tin cá nhân" key="1">
                <div style={{ width: "100%" }}>{ThongTinCaNhan()}</div>
              </TabPane>
              <TabPane tab="Thông báo qua email" key="2">
                <div style={{ width: "100%" }}>{ThongBaoQuaEmail()}</div>
              </TabPane>
              <TabPane tab="Thông báo trên hệ thống" key="3">
                <div style={{ width: "100%" }}>{ThietLapThongBao()}</div>
              </TabPane>
              <TabPane tab="Lịch sử đăng nhập" key="4">
                {LichSuDangNhap()}
              </TabPane>
            </Tabs>
          </div>
        </Col>
      </Row> */}

      <div style={{ width: "100%", padding: "10px", marginTop: "0px" }}>
        {ThietLapThongBao()}
      </div>
      {contextHolder}
    </div>
  );
};

export default InfoUser;
