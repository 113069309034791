import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Flex,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  notification,
} from "antd";
import FilterModal from "./FilterModal";
import { Format_Currency } from "layouts/ultis/common";
import { CiEdit } from "react-icons/ci";
import { MdEdit, MdOutlineDeleteOutline } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { CSVLink } from "react-csv";
import { SanPhamService } from "services/SanPhamService";
import DebounceSelect from "components/DebounceSelect";
import FormAddSanPham from "./FormAddSanPham";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const refAddProduct = useRef(null);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    InputSearch: "",
    MaBG: null,
  });
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await SanPhamService.Categories.getSanPhamByMaBG(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () =>{
     filter(filterCondition);
  }
  const columns = [
    {
      title: "Mã sản phẩm",
      dataIndex: "MaSP",
      width: 200,
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "TenSP",
    },
    {
      title: "Giá bán",
      dataIndex: "GiaBan",
      width: 200,
      render: (item) => Format_Currency(item),
    },
    {
      title: "",
      fixed: "right",
      width: "90px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginRight: 5 }}
                type="text"
                ghost
                icon={<CiEdit size={20} />}
                onClick={() => showModal(item)}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá nhập kho"
              description="Bạn có chắc chắn muốn xoá phiếu nhập?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.IDBG)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  type="text"
                  danger
                  ghost
                  icon={<MdOutlineDeleteOutline size={20} />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const [dataSP, setDataSP] = useState({});
  const remove = async (value) => {
    let _res = await SanPhamService.Categories.deleteSPBangGia({
      ID: Number(value),
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.IDBG !== Number(value));
      setData(fil);
     
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchSanPham(username) {
    return SanPhamService.getListByMaNhom({ InputSearch: username }).then(
      (res) => {
        return res?.data?.map((item) => ({
          label: `${item.TenSP}`,
          value: item.ID,
          title: JSON.stringify(item),
        }));
      }
    );
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onAdd = async (v) => {
    let item = JSON.parse(v.title);
    if (data?.some((a) => a.ID === v.value)) {
      openNotification("error", "topRight", "Sản phẩm đã có trong bảng giá");
      return;
    }
    let _res = await SanPhamService.Categories.addSPBangGia({
      GiaMua: item.GiaMua,
      GiaBan: item.GiaBan,
      MaSP: item.ID,
      MaBG: filterCondition.MaBG,
    });
    if (_res.status === 2000) {
      filter(filterCondition);
      openNotification("success", "topRight", "Thêm sản phẩm thành công");
    }
    //  setIsModalOpen(true);
    //  setDataSP({
    //    GiaMua: item.GiaMua,
    //    GiaBan: item.GiaBan,
    //    MaSP: item.ID,
    //  });
  };
  const showModal = (item) => {
    setIsModalOpen(true);
    setDataSP({
      GiaMua: item.GiaMua,
      GiaBan: item.GiaBan,
      MaSP: item.ID,
      ID: item.IDBG,
    });
  };

  const handleOk = async () => {
    let _res = await SanPhamService.Categories.addSPBangGia({
      ...dataSP,
      MaBG: filterCondition.MaBG,
    });
    if (_res.status === 2000) {
      filter(filterCondition);
      openNotification("success", "topRight", "Thêm sản phẩm thành công");
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const renderModal = () => {
    const applyChange = (v) => {
      let _datasp = _.cloneDeep(dataSP);
      _datasp.GiaBan = v;
      setDataSP(_datasp);
    };
    return (
      <Modal
        // title="Sản phẩm bảng giá"
        open={isModalOpen}
        width={300}
        okText="Lưu"
        cancelText="Huỷ"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p style={{ marginTop: 20 }}>Giá bán</p>
        <InputNumber
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          style={{ width: "100%" }}
          value={dataSP?.GiaBan}
          onChange={(v) => applyChange(v)}
        />
      </Modal>
    );
  };
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Flex gap={10}>
              <DebounceSelect
                value={null}
                placeholder="Thêm sản phẩm vào bảng giá"
                fetchOptions={fetchSanPham}
                va
                onChange={(newValue) => onAdd(newValue)}
                style={{
                  width: 300,
                }}
              />
              <Button
                type="primary"
                onClick={() =>
                  refAddProduct.current.add(data, filterCondition.MaBG)
                }
              >
                Thêm nhiều
              </Button>
            </Flex>
          )}
          // right={() => (
          //   <Button type="primary">
          //     <CSVLink filename={"BaoCaoTonKho.csv"} data={data}>
          //       Export
          //     </CSVLink>
          //   </Button>
          // )}
        />
        <Table
          style={{
            height: 100,
            // boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            // borderRadius:0
          }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            // x: 2500
          }}
          size="small"
        />
      </Col>
      <FormAddSanPham ref={refAddProduct} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
      {renderModal()}
    </Row>
  );
}
