import React, {
  useState,
  useEffect,
} from "react";
import {  Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { KhoHangService } from "services/KhoHangService";
import ActiveRoute from "components/ActiveRoute";
import ChiNhanhSelect from "components/ChiNhanhSelect";

const FilterModal = ({ ...props }) => {
  const { companies, filterCondition, onChangeFilter } = props;
  const [kho,setKho] = useState([]);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "ListMaCT":
      case "KhoHang":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      case "MaCT":
        _filter[p] = v ? v : 0;
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(()=>{
    KhoHangService.NhapKho.getTenKho().then((res) => setKho(res.data ?? []));
  },[])
  return (
    <div>
      <ActiveRoute />
      <ChiNhanhSelect
        FormID={262}
        onChange={(v) => applyChangeFilter("MaCT", v)}
      />

      <div className="box-search">
        <p className="label">Kho hàng</p>
        <Select
          mode="multiple"
          placeholder="Chọn kho hàng"
          onChange={(v) => applyChangeFilter("KhoHang", v)}
          style={{
            width: "100%",
          }}
          options={kho}
          fieldNames={{ label: "TenKho", value: "ID" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
