import { Box} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Popconfirm,
  Table,
  Tabs,
  Tooltip,
  notification,
  Flex,
} from "antd";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import TabPane from "antd/es/tabs/TabPane";
import { SanPhamService } from "services/SanPhamService";
import { RiImageAddLine } from "react-icons/ri";
import FormAddImage from "./FormAddImage";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const refDetail = useRef(null);
  const refDetailImage = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const tabs = [
    {
      title: "Khu vui chơi",
      key: "0",
    },
    {
      title: "Gói dịch vụ",
      key: "1",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");

  useEffect(() => {
    filter(activeTab);
  }, []);
  const filter = async (tab) => {
    setLoading(true);
    if (tab === "0") {
      let _res = await SanPhamService.filterKhuVuiChoi();
      if (_res.data) setData(_res.data);
    } else if (tab === "1") {
      let _res = await SanPhamService.filterGoiDV();
      if (_res.data) setData(_res.data);
    } 
    setLoading(false);
  };
  const onAfterSubmit = () => {
    filter(activeTab);
  };
  const columns = [
    {
      title: "Tên khu vui chơi",
      dataIndex: "TenKhuVuiChoi",
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
    },
    // {
    //   title: "Giới thiệu chung",
    //   dataIndex: "GioiThieuChung",
    //   render: (v) =><p>{v}</p>
    // },
    {
      title: ()=>{
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "160px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Thêm hình ảnh">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<RiImageAddLine />}
                onClick={() => refDetailImage.current.show(item)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "Tên gói dịch vụ",
      dataIndex: "TenGoi",
    },
    {
      title: "Tên khu vui chơi",
      dataIndex: "TenKhuVuiChoi",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await SanPhamService.deleteKhuVuiChoi({ ID: Number(value) });
        break;
      case "1":
        _res = await SanPhamService.deleteGoiDV({ ID: Number(value) });
        break;
    }
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá danh mục thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
    filter(newActiveKey);
  };
  return (
    <Box >
      {/* <Toolbar
        left={() => (
          
        )}
      /> */}
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              {item.key === "0" ? (
                <Table
                  loading={loading}
                  dataSource={data}
                  columns={columns}
                  scroll={{
                    y: window.innerHeight - 350,
                    // x: 2500
                  }}
                  size="small"
                />
              ) : (
                <Table
                  loading={loading}
                  dataSource={data}
                  columns={columns2}
                  scroll={{
                    y: window.innerHeight - 350,
                    // x: 2500
                  }}
                  size="small"
                />
              )}
              {/* <Table
                loading={loading}
                dataSource={data}
                columns={columns}
                scroll={{
                  y: window.innerHeight - 350,
                  // x: 2500
                }}
                size="small"
              /> */}
            </TabPane>
          );
        })}
      </Tabs>

      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormAddImage ref={refDetailImage}  />
      {contextHolder}
    </Box>
  );
}
