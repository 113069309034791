import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Popconfirm, notification, Tooltip, Badge, Row, Col, Input, Flex } from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import { MdEdit } from "react-icons/md";
import FormDetail from "./FormDetail";
import { TaiLieuService } from "services/TaiLieuService";
import { Format_Datetime } from "layouts/ultis/common";
import FormTaiLieu from "./FormTaiLieu";
import { IoDocumentAttachOutline } from "react-icons/io5";

export default function Settings() {
  const [dataAll, setDataAll] = useState([]);
  const [data, setData] = useState([]);
  const [stateActive, setStateActive] = useState({
    ID: 0,
    Name: "Tất cả",
    Color: "gray",
  });
  const [api, contextHolder] = notification.useNotification();
  const refDetail = useRef(null);
  const refAdd = useRef(null);
  const refTaiLieu = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loai,setLoai] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    InputSearch: "",
    MaLTL: 0,
  });
  useEffect(() => {
    loadCategories();
  }, []);

  const loadBuyin = async (_filter) => {
    setLoading(true);
    let _res = await TaiLieuService.getList(_filter);
    let _tt = await TaiLieuService.Loai.getList();
    let _loai = [{ ID: 0, Name: "Tất cả", Color: "#cccccc" }, ..._tt.data] ?? [];
    _loai.map((item) => {
      if (item.ID === 0) {
        item.Total = _res.data?.length;
      } else {
        item.Total = _res?.data?.filter((v) => v.MaLTL === item.ID)?.length;
      }
    });
    setLoai(_loai);
    setDataAll(_res.data);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    // let _resCT = await CongTyService.getCongTy();
    // setCompanies(_resCT.data);
  };
  const columns = [
    {
      title: "Tên tài liệu",
      dataIndex: "Name",
      // render: (item) => Format_Date(item),
    },
    {
      title: "Loại tài liệu",
      dataIndex: "MaLTL",
      render: (item) => loai.find((v) => v.ID === item)?.Name,
    },
    {
      title: "Ghi chú",
      dataIndex: "Note",
    },
    {
      title: "Ngày thêm",
      dataIndex: "NgayTao",
      render: (item) => Format_Datetime(item),
    },
    {
      title: "",
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex gap={5}>
            <Tooltip placement="topRight" title="Tài liệu">
              <Button
                type="primary"
                ghost
                icon={<IoDocumentAttachOutline />}
                onClick={() => refTaiLieu.current.show(item)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refAdd.current.update(item)}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá"
              description="Bạn có chắc chắn muốn xoá hay không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item)}
            >
              <Button danger ghost icon={<CiTrash />} />
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await TaiLieuService.add({
      ...item,
      Status: false,
    });
    if (_res.status === 2000) {
      // let _data = _.cloneDeep(data);
      // let fil = _data.filter((v) => v.ID !== Number(item.ID));
      // setData(fil);
    loadBuyin(filterCondition);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onAfterSubmit = () => {
    loadBuyin(filterCondition);
  };
  const changeState = (state) => {
    if (state === 0) {
      setData(dataAll);
    } else {
      let _data = dataAll.filter((v) => v.MaLTL === state);
      setData(_data);
    }
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        loadBuyin(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  return (
    <Row gutter={[15]}>
      {/* <Col span={4}>
        <FilterModal
          companies={companies}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col> */}
      <Col span={24}>
        <Toolbar
          left={() => (
            <Input
              placeholder="Tìm kiếm"
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          right={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
              }}
            >
              {loai?.map((item) => {
                return (
                  <Badge
                    key={item.ID}
                    count={item.Total}
                    onClick={() => {
                      setStateActive(item);
                      changeState(item.ID);
                    }}
                  >
                    <div
                      style={{
                        border: `1px solid ${ item.Color}`,
                        padding: "6px 15px",
                        cursor: "pointer",
                        borderRadius: 10,
                        background:
                          stateActive.ID === item.ID ? item.Color : null,
                        color: stateActive.ID === item.ID ? "white" : null,
                      }}
                    >
                      <p>{item.Name}</p>
                    </div>
                  </Badge>
                );
              })}
              <Button type="primary" onClick={() => refAdd.current.show()}>
                Thêm
              </Button>
            </div>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            // x: 1500,
          }}
          size="small"
        />
      </Col>

      <FormAdd ref={refAdd} onAfterSubmit={onAfterSubmit} />
      <FormDetail ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormTaiLieu ref={refTaiLieu} />
      {contextHolder}
    </Row>
  );
}
