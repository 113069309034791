import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  InputNumber,
  Row,
  Col,
  Drawer,
  notification,
  Select,
  Flex,
  Modal,
  Popconfirm,
  Badge,
  message,
  Tooltip,
  Input,
  Popover,
  Space,
} from "antd";
import { DateTime } from "luxon";
import _ from "lodash";
import { FaTrashAlt } from "react-icons/fa";
import { SanPhamService } from "services/SanPhamService";
import { Format_Currency } from "layouts/ultis/common";
import { CustomerService } from "services/CustomerService";
import { BanHangService } from "services/BanHangService";
import { CardService } from "services/CardService";
import { MdOutlineCancel } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { KhoHangService } from "services/KhoHangService";
import { CiStickyNote, CiTimer } from "react-icons/ci";
import { Format_Datetime, Format_Time } from "layouts/ultis/common";
import FormChangeTable from "./FormChangeTable";
import { FcOvertime } from "react-icons/fc";
import { RiMoneyDollarCircleLine, RiTableAltLine } from "react-icons/ri";
import { LuPercent } from "react-icons/lu";
const FormCartMobile = (props, ref) => {
  const history = useHistory();
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [diemTien, setDiemTien] = useState({});
  const [khachhang, setKhachhang] = useState([]);
  const refPay = useRef(null);
  const [cardType, setCardType] = useState({});
  const componentRef = useRef();
  const ketcaRef = useRef();
  const refChangeTable = useRef(null);
  const [tinhGio, setTinhGio] = useState({});
  const defaulData = {
    IsKhachHang: 1,
    SanPham: [],
    MaKH: null,
  };
  const [data, setData] = useState(defaulData);
  const [open, setOpen] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sanpham, setSanpham] = useState([]);
  const [sanphamall, setSanphamall] = useState([]);
  const [khohang, setKhohang] = useState([]);
  const [quayve, setQuayve] = useState([]);
  const [dataquay, setDataquay] = useState({});
  const [calamviec, setCalamviec] = useState({});
  const [openCaLV, setOpenCaLV] = useState(false);
  const [openTinhGio, setOpenTinhGio] = useState(false);
  const [openKTCa, setOpenKTCa] = useState(false);
  const [dhtheoca, setDhtheoca] = useState([]);
  const [loaisp, setLoaisp] = useState([]);
  const [uudai, setUudai] = useState({});
  const [lspActive, setLspActive] = useState({ MaLSP: 0, TenLSP: "Tất cả" });
  const refActive = useRef(null);

  const loadcategories = async () => {
    let _qv = await BanHangService.getQuayBan();
    setQuayve(_qv.data ?? []);
    let _dataquay = _.cloneDeep(dataquay);
    _dataquay.MaQuay = _qv.isDefault;
    setDataquay(_dataquay);
    let _kh = await CustomerService.getListAll();
    _kh.data?.map((item) => (item.Name = item.TenCongTy + " - " + item.DiDong));
    setKhachhang(_kh?.data);
  };
  const loadSanPham = async (maquay) => {
    let _sp = await SanPhamService.getList({ InputSearch: "" });
    let _sptheoquay = await SanPhamService.getSanPhamTheoQuay({
      MaQuay: maquay,
    });
    let spquay = _sp.data.filter((v) =>
      _.map(_sptheoquay.data, "MaSP").includes(v.ID)
    );
    let _lsp = await SanPhamService.getLSP({ ApDung: true });
    let lsp = [{ MaLSP: 0, TenLSP: "Tất cả" }];
    let _sptheoloai = spquay.filter((v) =>
      _.map(_lsp.data, "MaLSP").includes(v.MaLSP)
    );
    setSanpham(_sptheoloai ?? []);
    setSanphamall(_sptheoloai ?? []);
    _lsp.data?.map((item) => {
      if (_sptheoloai?.some((v) => v.MaLSP === item.MaLSP)) lsp.push(item);
    });
    setLoaisp(lsp);
  };
  useImperativeHandle(ref, () => ({
    show: async (ban, isTinhGio) => {
      setOpen(true);
      setLoading(true);
      loadcategories();
      let _data = _.cloneDeep(data);
      _data.MaBan = ban.ID;
      _data.TenBan = ban.TenBan;
      _data.MaKV = ban.MaKV;
      _data.TinhGio = isTinhGio;
      _data.GioVao = ban?.GioVao ?? new Date();
      let _checkBanHang = await BanHangService.BanTheoBan.check({
        MaBan: ban.ID,
      });
      if (_checkBanHang?.data) {
        _data.MaBH = _checkBanHang.data?.id;
        _data.ThanhTien = _checkBanHang.data?.thanhTien;
        _data.SanPham = _checkBanHang.sanpham;
      }
      let _kho = await KhoHangService.NhapKho.getTenKho();
      if (_kho.data?.length > 0) {
        _data.MaKho = _kho.data[0].ID;
        setKhohang(_kho.data ?? []);
      }
      setData(_data);
      let _check = await BanHangService.checkCaLamViec();
      if (!_check.data) {
        setOpenCaLV(true);
      } else {
        loadSanPham(_check.data.MaQuay);
        setCalamviec(_check.data);
      }
      setLoading(false);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    setDiemTien({});
  };
  const SumTotal = (_data) => {
    _data.ThanhTien = _data?.SanPham?.reduce((pre, current) => {
      return (pre += current.ThanhTien);
    }, 0);
    _data.TienCK = (_data.ThanhTien * (_data?.TyLeCK ?? 0)) / 100;
  };
  const applyChange = async (p, v, MaSP) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    switch (p) {
      case "ChooseSP":
        _data[p] = [];
        let sp = JSON.parse(v[0].title);
        let spds = _data.SanPham?.find((v) => v.MaSP === sp.ID);
        if (spds) {
          spds.SoLuong++;
        } else {
          _data.SanPham.push({
            MaSP: sp.ID,
            TenSP: sp.TenSP,
            DonGia: sp.GiaBan,
            SoLuong: 1,
            TyLeCK: 0,
            TienCK: 0,
            ThanhTien: sp.GiaBan,
            MaNhom: sp.MaNhom,
          });
        }
        SumTotal(_data);
        break;
      case "ChooseClickSP":
        let spdsnew = _data.SanPham?.find((f) => f.MaSP === v.ID);
        if (spdsnew) {
          spdsnew.SoLuong++;
          spdsnew.TienCK =
            (spdsnew?.SoLuong * spdsnew?.DonGia * spdsnew?.TyLeCK) / 100;
          spdsnew.ThanhTien =
            spdsnew.SoLuong * spdsnew.DonGia -
            (spdsnew?.SoLuong * spdsnew?.DonGia * spdsnew.TyLeCK) / 100;
        } else {
          let tyleck = uudai?.TyLeCK ? uudai?.TyLeCK : 0;
          let TienCK = (v?.GiaBan * tyleck) / 100;
          let ThanhTien = v.GiaBan - (v?.GiaBan * tyleck) / 100;
          _data.SanPham.push({
            MaSP: v.ID,
            TenSP: v.TenSP,
            DonGia: v.GiaBan,
            SoLuong: 1,
            TyLeCK: tyleck,
            TienCK: TienCK,
            ThanhTien: ThanhTien,
            MaNhom: v.MaNhom,
          });
        }
        SumTotal(_data);
        if (_data.MaBH) {
          let _payload = {
            ThanhTien: _data.ThanhTien,
            ID: _data.MaBH,
            SanPham: _data.SanPham,
          };
          await BanHangService.BanTheoBan.update(_payload);
          let _sp = await BanHangService.BanTheoBan.check({
            MaBan: _data.MaBan,
          });
          _data.SanPham = _sp.sanpham;
        } else {
          let _payload = {
            MaCaLamViec: calamviec?.ID,
            MaQuayBan: calamviec?.MaQuay,
            MaNhom: 2,
            ThanhTien: _data.ThanhTien,
            SanPham: _data.SanPham,
            MaBan: _data.MaBan,
          };
          let _res = await BanHangService.BanTheoBan.add(_payload);
          _data.MaBH = _res?.data;
          onAfterSubmit(_data.MaBan, "ACTIVE");
        }

        break;
      case "MaKH":
        if (v) {
          _data[p] = v;
          // _data.MaKH = v;
          let _the = await CardService.getByMaKH({ MaKH: v });
          // _data.MaVach = _the?.data?.MaVach;
          let _res = await CardService.getDiem({
            MaThe: _the?.data?.ID,
          });
          let _resTypeCardDetail = await CardService.getLoaiTheDetail({
            ID: _the?.data?.MaLT,
          });
          setDiemTien(_res.data ?? {});
          setCardType(_resTypeCardDetail.data ?? {});
          _data.MaThe = _the?.data?.ID;
          let _uu = await CardService.findUuDai({
            LoaiUuDai: "CK",
            MaLoaiThe: _the?.data?.MaLT,
          });
          if (_uu?.data) {
            setUudai(_uu.data);
            _data.SanPham?.map((item) => {
              item.TyLeCK = _uu?.data?.TyLeCK;
              item.TienCK = (item?.SoLuong * item?.DonGia * item.TyLeCK) / 100;
              item.ThanhTien =
                item.SoLuong * item.DonGia -
                (item?.SoLuong * item?.DonGia * item.TyLeCK) / 100;
            });
            SumTotal(_data);
          } else {
            _data.SanPham?.map((item) => {
              item.TyLeCK = 0;
              item.TienCK = 0;
              item.ThanhTien = item.SoLuong * item.DonGia - 0;
            });
            SumTotal(_data);
          }
        } else {
          _data[p] = [];
          setUudai({});
        }
        break;
      case "SoLuong":
        let sl = _data.SanPham?.find((v) => v.MaSP === MaSP);
        sl[p] = v;
        sl.TienCK = (sl?.SoLuong * sl?.DonGia * sl?.TyLeCK) / 100;
        sl.ThanhTien =
          sl.SoLuong * sl.DonGia - (sl?.SoLuong * sl?.DonGia * sl.TyLeCK) / 100;
        SumTotal(_data);
        break;
      case "TyLeCK":
        let item = _data.SanPham?.find((v) => v.MaSP === MaSP);
        item[p] = v;
        item.TienCK = (item?.SoLuong * item?.DonGia * v) / 100;
        item.ThanhTien =
          item.SoLuong * item.DonGia -
          (item?.SoLuong * item?.DonGia * item.TyLeCK) / 100;
        SumTotal(_data);
        break;
      case "Remove":
        _data.SanPham?.splice(v, 1);
        SumTotal(_data);
        if (MaSP) {
          BanHangService.BanVeKinhDoanh.deleteSPBanHang({ ID: MaSP });
        }
        break;
      case "AddDvGio":
        let _sp = _data.SanPham.find((v) => v.MaNhom === 7);
        _data.SanPham.unshift({
          ..._sp,
          ID: null,
          GioRa: null,
          GioVao: DateTime.now().setZone("Asia/Ho_Chi_Minh"),
          SoLuong: 0,
          ThanhTien: 0,
        });
        let _payload = {
          ThanhTien: _data.ThanhTien,
          ID: _data.MaBH,
          SanPham: _data.SanPham,
        };
        await BanHangService.BanTheoBan.update(_payload);
        let _check = await BanHangService.BanTheoBan.check({
          MaBan: _data.MaBan,
        });
        _data.SanPham = _check.sanpham;
        break;
      case "TinhGio":
        let _sptg = _data.SanPham?.find((v) => v.ID === tinhGio.ID);
        _sptg.GioRa = new Date(tinhGio.GioRa);
        _sptg.GioVao = new Date(tinhGio.GioVao);
        const ngay1 = DateTime.fromISO(_sptg.GioRa?.toISOString(), {
          zone: "Asia/Ho_Chi_Minh",
        }).toString();
        const ngay2 = DateTime.fromISO(_sptg.GioVao?.toISOString(), {
          zone: "Asia/Ho_Chi_Minh",
        }).toString();
        var chenhLechGio =
          (new Date(ngay1) - new Date(ngay2)) / (1000 * 60 * 60);
        console.log(ngay2);
        _sptg.SoLuong = chenhLechGio.toFixed(2);
        _sptg.TienCK = (_sptg?.SoLuong * _sptg?.DonGia * _sptg?.TyLeCK) / 100;
        _sptg.ThanhTien =
          _sptg.SoLuong * _sptg.DonGia -
          (_sptg?.SoLuong * _sptg?.DonGia * _sptg.TyLeCK) / 100;
        SumTotal(_data);
        let _payload2 = {
          ThanhTien: _data.ThanhTien,
          ID: _data.MaBH,
          SanPham: [
            {
              ..._sptg,
              GioRa: ngay1,
              GioVao: ngay2,
            },
          ],
        };
        await BanHangService.BanTheoBan.update(_payload2);
        let _checktg = await BanHangService.BanTheoBan.check({
          MaBan: _data.MaBan,
        });
        _data.SanPham = _checktg.sanpham;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
    setLoading(false);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const handleCancel = async () => {
    let _data = _.cloneDeep(data);
    let _res = await BanHangService.BanTheoBan.cancel({
      ID: _data.MaBH,
      MaBan: _data.MaBan,
    });
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Huỷ bàn thành công!`);
      onClose();
      onAfterSubmit(_data.MaBan, "EMPTY");
    }
  };
  const changeTable = () => {
    refChangeTable.current.show({
      MaKV: data?.MaKV,
      MaBan: data?.MaBan,
      GioVao: data?.GioVao,
      MaBH: data?.MaBH,
    });
  };
  useEffect(() => {
    let time = null;
    if (data.MaVach?.length > 0) {
      time = setTimeout(async () => {
        let _res = await CardService.getCardByMaVach({
          MaVach: data.MaVach,
        });
        if (_res.status === 2000) {
          messageApi.open({
            type: "success",
            content: "Thẻ đang hoạt động",
          });
          let _kh = await CardService.getKhachHangByID({ ID: _res.data.MaKH });
          let _data = _.cloneDeep(data);
          _data.MaKH = _kh.data.MaKH;
          // _data.KhachHang = _kh.data.MaKH;
          let _resDiem = await CardService.getDiem({
            MaThe: _res?.data?.ID,
          });
          let _resTypeCardDetail = await CardService.getLoaiTheDetail({
            ID: _res?.data?.MaLT,
          });
          setDiemTien(_resDiem.data ?? {});
          setCardType(_resTypeCardDetail.data ?? {});
          _data.MaThe = _res?.data?.ID;
          let _uu = await CardService.findUuDai({
            LoaiUuDai: "CK",
            MaLoaiThe: _res?.data?.MaLT,
          });
          if (_uu?.data) {
            setUudai(_uu.data);
            _data.SanPham?.map((item) => {
              item.TyLeCK = _uu?.data?.TyLeCK;
              item.TienCK = (item?.SoLuong * item?.DonGia * item.TyLeCK) / 100;
              item.ThanhTien =
                item.SoLuong * item.DonGia -
                (item?.SoLuong * item?.DonGia * item.TyLeCK) / 100;
            });
            SumTotal(_data);
          } else {
            _data.SanPham?.map((item) => {
              item.TyLeCK = 0;
              item.TienCK = 0;
              item.ThanhTien = item.SoLuong * item.DonGia - 0;
            });
            SumTotal(_data);
          }
          setData(_data);
        } else {
          let _data = _.cloneDeep(data);
          _data.MaKH = null;
          _data.MaThe = null;
          setData(_data);
          setDiemTien({});
          setCardType({});
          setUudai({});
          messageApi.open({
            type: "error",
            content: _res.message,
          });
        }
      }, 1000);
    }
    return () => clearTimeout(time);
  }, [data.MaVach]);
  const [note, setNote] = useState(null);
  const [ck, setCk] = useState({ Type: "VND", Value: 0 });
  const renderRight = () => {
    const appllyCK = (p, v) => {
      let _res = _.cloneDeep(ck);
      switch (p) {
        case "Value":
          _res[p] = v;
          if (_res.Type === "VND") {
            _res.TyLeCK = Number(
              (
                (refActive.current?.SoLuong * refActive.current?.DonGia) /
                v
              ).toFixed(2)
            );
            _res.TienCK = v;
          } else {
            _res.TienCK = Number(
              (
                (refActive.current?.SoLuong * refActive.current?.DonGia * v) /
                100
              ).toFixed(2)
            );
            _res.TyLeCK = v;
          }
          break;
        default:
          _res[p] = v;
          break;
      }
      console.log(_res);
      setCk(_res);
    };
    const handleOpenChange = async (item, newOpen) => {
      if (newOpen === true) {
        refActive.current = item;
      }
      if (newOpen === false) {
        setLoading(true);
        let _data = _.cloneDeep(data);
        let sp = _data.SanPham?.find((v) => v.ID === refActive.current?.ID);
        sp.TyLeCK = ck?.TyLeCK;
        sp.TienCK = ck?.TienCK;
        sp.ThanhTien =
          refActive.current?.SoLuong * refActive.current?.DonGia -
          (ck?.TienCK ?? 0);
        SumTotal(_data);
        let _res = await BanHangService.BanTheoBan.updateSanPham({
          ID: refActive.current?.ID,
          GhiChu: refActive.current?.GhiChu,
          SoLuong: refActive.current?.SoLuong,
          ThanhTien:
            refActive.current?.SoLuong * refActive.current?.DonGia -
            (ck?.TienCK ?? 0),
          TyLeCK: ck?.TyLeCK,
          TienCK: ck?.TienCK,
          ThanhTienBH: _data.ThanhTien,
          MaBH: data.MaBH,
        });
        setLoading(false);
        if (_res.status === 2000) {
          setData(_data);
          setCk({ Type: "PT", Value: 0 });
        }
      }
    };
    const content = () => {
      return (
        <Flex
          align="center"
          style={{ width: 300 }}
          gap={5}
          justify="space-between"
        >
          <p>Giảm giá</p>
          <InputNumber
            style={{ width: "40%" }}
            value={ck.Value}
            onChange={(v) => appllyCK("Value", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          <Button
            type={ck.Type === "VND" ? "primary" : "dashed"}
            onClick={() => appllyCK("Type", "VND")}
          >
            VND
          </Button>
          <Button
            type={ck.Type === "PT" ? "primary" : "dashed"}
            onClick={() => appllyCK("Type", "PT")}
          >
            %
          </Button>
        </Flex>
      );
    };
    const renderTop = () => {
      return (
        <Flex
          justify="space-between"
          align="center"
          style={{ padding: "13px 10px" }}
          gap={10}
        >
          {data?.TinhGio && (
            <>
              <p style={{ fontWeight: "bold", color: "black", fontSize: 12 }}>
                {Format_Datetime(data?.GioVao)}
              </p>
              <Button
                type="primary"
                icon={<CiTimer />}
                onClick={() => applyChange("AddDvGio")}
              >
                Thêm dịch vụ giờ
              </Button>
            </>
          )}
          {/* <Button danger onClick={() => handleKetThucCa()}>
            Kết thúc ca
          </Button> */}
          {/* <Button onClick={() => onClose()}>Thoát</Button> */}
        </Flex>
      );
    };
    const renderContent = () => {
      return (
        <div
          style={{
            overflow: "scroll",
          }}
          className="left-box-table"
        >
          {/* <Flex style={{ padding: "10px" }} gap={10}>
            <Select
              value={data?.IsKhachHang}
              style={{
                width: "25%",
              }}
              fieldNames={{ label: "Name", value: "ID" }}
              onChange={(newValue) => applyChange("IsKhachHang", newValue)}
              options={[
                { ID: 1, Name: "Theo khách hàng" },
                { ID: 2, Name: "Theo mã thẻ" },
              ]}
            />
            {data?.IsKhachHang === 1 ? (
              <Select
                showSearch
                placeholder="Tìm kiểm khách hàng"
                value={data?.MaKH}
                optionFilterProp="children"
                filterOption={filterOption}
                style={{
                  width: "50%",
                }}
                fieldNames={{ label: "Name", value: "MaKH" }}
                onChange={(newValue) => applyChange("MaKH", newValue)}
                options={khachhang}
              />
            ) : (
              <Input
                style={{
                  width: "50%",
                }}
                value={data?.MaVach}
                placeholder="Nhập mã thẻ"
                onChange={(v) => applyChange("MaVach", v.target.value)}
              />
            )}

            <Select
              style={{
                width: "120px",
              }}
              value={data?.MaKho}
              onChange={(v) => applyChange("MaKho", v)}
              options={khohang}
              fieldNames={{ label: "TenKho", value: "ID" }}
            />
          </Flex> */}
          {data?.SanPham?.map((item, i) => {
            return (
              <Row
                gutter={[3]}
                style={{
                  borderBottom: "1px solid #F5F6F8",
                  padding: 5,
                }}
                align="middle"
                key={item.ID}
              >
                <Col span={24}>
                  <Flex align="center">
                    {(item.MaNhom !== 7 ||
                      (item.MaNhom === 7 &&
                        data?.SanPham?.filter((v) => v.MaNhom === 7).length >
                          1)) && (
                      <Button
                        onClick={() => applyChange("Remove", i, item.ID)}
                        icon={<FaTrashAlt color="#4D5258" />}
                        type="text"
                      />
                    )}
                    <p
                      className="text-overflow-ellipsis"
                      style={{
                        fontWeight: "bold",
                        textOverflow: "ellipsis",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        color: "#000810",
                      }}
                    >
                      {i + 1}. {item.TenSP} ({Format_Currency(item.TienCK)})
                    </p>
                  </Flex>
                  {item.MaNhom === 7 && (
                    <Flex align="center" gap={10}>
                      {/* <Tooltip placement="topRight" title="Tính tiền giờ">
                        <FcOvertime
                          color="green"
                          size={25}
                          style={{ cursor: "pointer", color: "green" }}
                          onClick={() => handleTinhGio(item)}
                        />
                      </Tooltip> */}
                      <p>
                        Giờ: {Format_Datetime(item.GioVao)} -{" "}
                        {item.GioRa
                          ? new Date(item.GioVao).getDate() ===
                            new Date(item.GioRa).getDate()
                            ? Format_Time(item.GioRa)
                            : Format_Datetime(item.GioRa)
                          : "HT"}
                      </p>
                    </Flex>
                  )}
                </Col>
                <Col span={4}>
                  <Popover
                    content={content()}
                    trigger="click"
                    onOpenChange={(v) => handleOpenChange(item, v)}
                  >
                    <Flex align="center">
                      <p style={{ fontSize: 12, color: "#85888C" }}>
                        {item.TyLeCK > 0 ? item.TyLeCK : "CK"}
                      </p>
                      <LuPercent />
                    </Flex>
                  </Popover>
                </Col>
                <Col span={4}>
                  <InputNumber
                    style={{ width: "100%" }}
                    value={item.SoLuong}
                    disabled
                    onChange={(v) => applyChange("SoLuong", v, item.MaSP)}
                  />
                </Col>
                <Col span={8}>{Format_Currency(item.DonGia)}</Col>
                <Col span={8}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(item.ThanhTien)}
                  </p>
                </Col>
                <Col span={24}>
                  <Flex
                    align="center"
                    gap={5}
                    style={{ cursor: "pointer",marginTop:10 }}
                    onClick={() => {
                      refActive.current = item;
                      setOpenNote(true);
                    }}
                  >
                    <CiStickyNote color="#85888C" size={15} />
                    <p style={{ fontSize: 12, color: "#85888C" }}>
                      {item?.GhiChu ? item.GhiChu : "Nhập ghi chú"}
                    </p>
                  </Flex>
                </Col>
              </Row>
            );
          })}
        </div>
      );
    };
    const renderBottom = () => {
      const handleThanhToan = () => {
        let _payload = {
          ...data,
          KhachHang: khachhang?.find((v) => v.MaKH === data.MaKH),
          cardType: cardType,
          diemTien: diemTien,
          HinhThucTT: 3,
          KhachTra: data.ThanhTien,
          ThanhToan: data.ThanhTien,
          TienThua: 0,
        };
        refPay.current.show(_payload);
      };
      return (
        <>
          <Flex
            align="center"
            justify="space-between"
            style={{
              padding: "10px 20px",
              background: "#e6f0fa",
            }}
          >
            <Flex
              align="center"
              gap={5}
              style={{
                background: "rgba(0, 8, 16, 0.04)",
                borderRadius: 20,
                padding: "5px 10px",
                cursor: "pointer",
              }}
              onClick={() => changeTable()}
            >
              <RiTableAltLine size={20} />
              <p>Ghép/Chuyền bàn</p>
            </Flex>
            <p>
              Tổng tiền:{" "}
              <span style={{ fontWeight: "bold", fontSize: 18 }}>
                {Format_Currency(_.sum(_.map(data.SanPham,"ThanhTien")))}
              </span>
            </p>
          </Flex>
          {/* <Flex
            align="center"
            gap={10}
            justify="center"
            style={{
              background: "#e6f0fa",
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              paddingBottom: 10,
            }}
          >
            <Button
              loading={loading}
              type="primary"
              icon={<RiMoneyDollarCircleLine size={25} />}
              onClick={() => handleThanhToan()}
              className="btn-thanhtoan"
              style={{
                width: "45%",
              }}
            >
              Thanh toán
            </Button>
            <Popconfirm
              title="Huỷ hoá đơn"
              description="Bạn có chắc chắn muốn huỷ hoá đơn?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => handleCancel()}
            >
              <Button
                loading={loading}
                icon={<MdOutlineCancel size={25} />}
                className="btn-cancel"
                style={{
                  width: "45%",
                }}
              >
                Huỷ hoá đơn
              </Button>
            </Popconfirm>
          </Flex> */}
        </>
      );
    };
    const renderNote = () => {
      const saveKetThucCa = async () => {
        setLoading(true);
        let _res = await BanHangService.BanTheoBan.updateSanPham({
          ID: refActive.current?.ID,
          GhiChu: note,
          SoLuong: refActive.current?.SoLuong,
          ThanhTien: refActive.current?.ThanhTien,
          TyLeCK: refActive.current?.TyLeCK,
          TienCK: refActive.current?.TienCK,
        });
        if (_res.status === 2000) {
          let _data = _.cloneDeep(data);
          let sp = _data.SanPham?.find((v) => v.ID === refActive.current?.ID);
          sp.GhiChu = note;
          setData(_data);
          setOpenNote(false);
          setNote(null);
        }
        setLoading(false);
      };
      return (
        <Modal
          closeIcon={false}
          width={500}
          title="Ghi chú / Món thêm"
          open={openNote}
          onOk={() => saveKetThucCa()}
          onCancel={() => {
            setOpenNote(false);
          }}
          cancelText="Bỏ qua"
          okText="Lưu lại"
        >
          <Input
            style={{ width: "100%" }}
            value={note || note === "" ? note : refActive.current?.GhiChu}
            onChange={(e) => setNote(e.target.value)}
          />
        </Modal>
      );
    };
    return (
      <div className="left-box">
        {renderTop()}
        {renderContent()}
        {renderBottom()}
        {renderNote()}
      </div>
    );
  };
  const filterOption = (input, option) => {
    return (option?.Name ?? "").toLowerCase().includes(input.toLowerCase());
  };
  const renderBatDauCa = () => {
    const saveCaLamViec = async () => {
      setLoading(true);
      let _res = await BanHangService.addCaLamViec(dataquay);
      if (_res.status === 2000) {
        setOpenCaLV(false);
        setCalamviec({
          ID: _res.data,
          TienDauCa: dataquay.TienDauCa,
          GioVaoCa: new Date(),
          MaQuay: dataquay.MaQuay,
        });
        loadSanPham(dataquay.MaQuay);
      }
      setLoading(false);
    };
    const appllyChange = (p, v) => {
      let _data = _.cloneDeep(dataquay);
      _data[p] = v;
      setDataquay(_data);
    };
    return (
      <Modal
        closeIcon={false}
        title="KHAI BÁO TIỀN ĐẦU CA"
        open={openCaLV}
        onOk={() => saveCaLamViec()}
        onCancel={() => {
          setOpenCaLV(false);
          setOpen(false);
        }}
        cancelText="Huỷ"
        okText="Lưu"
      >
        <div>
          <p style={{ marginTop: 10 }}>Quầy bán</p>
          <Select
            style={{
              width: "100%",
            }}
            value={dataquay?.MaQuay}
            placeholder="Chọn quầy bán"
            onChange={(v) => appllyChange("MaQuay", v)}
            options={quayve}
            fieldNames={{ label: "TenQuay", value: "ID" }}
          />
          <p style={{ marginTop: 10 }}>Tiền đầu ca</p>
          <InputNumber
            suffix="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            placeholder="Nhập tiền đầu ca"
            style={{ width: "100%" }}
            onChange={(v) => appllyChange("TienDauCa", v)}
          />
        </div>
      </Modal>
    );
  };
  const handleTinhGio = (item) => {
    setOpenTinhGio(true);
    setTinhGio({
      ...item,
      GioRa: DateTime.now().setZone("Asia/Ho_Chi_Minh"),
    });
  };
  const onAfterSubmitChangeTable = async (v) => {
    let _data = _.cloneDeep(data);
    let _checkBanHang = await BanHangService.BanTheoBan.check({
      MaBan: v.ID,
    });
    if (_checkBanHang?.data) {
      _data.MaBH = _checkBanHang.data?.id;
      _data.ThanhTien = _checkBanHang.data?.thanhTien;
      _data.SanPham = _checkBanHang.sanpham;
    }
    _data.MaBan = v.ID;
    setData(_data);
    onAfterSubmit();
  };
  return (
    <Drawer
      title="Đơn hàng"
      width="100%"
      onClose={() => onClose()}
      open={open}
      className="bantheoban-mobile-detail"
      // extra={
      //   <Space>
      //     <GiShoppingCart
      //       size={30}
      //       style={{
      //         background: "#f0f0f0",
      //         padding: "5px",
      //         borderRadius: "50%",
      //       }}
      //     />
      //     {inputVisible === false && (
      //       <IoSearch
      //         onClick={handleButtonClick}
      //         size={30}
      //         style={{
      //           background: "#f0f0f0",
      //           padding: "5px",
      //           borderRadius: "50%",
      //         }}
      //       />
      //     )}
      //     <Select
      //       showSearch
      //       placeholder="Tìm kiếm sản phẩm"
      //       optionFilterProp="children"
      //       filterOption={filterOptionSP}
      //       fieldNames={{ label: "TenSP", value: "ID" }}
      //       onChange={(v) => {
      //         handleChooseSP(v);
      //         handleButtonClick();
      //       }}
      //       options={sanphamall}
      //       className={`search-input ${inputVisible ? "show" : ""}`}
      //     />
      //   </Space>
      // }
    >
      {renderRight()}
      {/* <div style={{ display: "none" }}>
        <HoaDonToPrint ref={componentRef} data={data} />
      </div>
      <div style={{ display: "none" }}>
        <KetCaPrint ref={ketcaRef} data={dhtheoca} calamviec={calamviec} />
      </div> */}
      {renderBatDauCa()}
      {/* {renderKetThucCa()} */}
      {/* {renderTinhGio()} */}
      <FormChangeTable
        ref={refChangeTable}
        onAfterSubmit={onAfterSubmitChangeTable}
      />
      {/* <FormPay ref={refPay} onAfterSubmit={onSubmitPay} /> */}
      {contextHolder}
      {contextHolderMS}
    </Drawer>
  );
};
export default forwardRef(FormCartMobile);
