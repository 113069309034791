import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from "react";
import { Input, Row, Col, Drawer, notification } from "antd";
import _ from "lodash";
import { CustomerService } from "services/CustomerService";
import UploadComponent from "components/UploadComponent";
import { VisaService } from "services/VisaService";
import { API_UPLOAD_URL } from "services/UploadService";

const { TextArea } = Input;
const FormTaiLieu = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const refImages = React.useRef(null);
  const [files,setFiles] = useState([]);

  const loadCategories = async (mahoso) => {
    let _res = await VisaService.HoSo.getListTaiLieu({MaHoSo:mahoso});
    // refImages.current = _.map(_res.data, "Link");
    setFiles(_res.data);
  };
  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      setData(data);
      await loadCategories(data.ID);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = await CustomerService.addChamSoc(_data);
    setLoading(false);
    if (_res.status === 2000) {
      onAfterSubmit();
      openNotification("success", "topRight", `Thêm lịch sử thành công!`);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const affterUpload = async (file) => {
    let result = _.map(file, (v) => (v = API_UPLOAD_URL+v));
    console.log(result);
    await VisaService.HoSo.addTaiLieu({
      MaHoSo: data?.ID,
      MaViSa: data?.MaVisa,
      Files: result,
    });
  };
  const onRemove = async (file) => {
    let _file = files.find(v=>v.Link === file.url);
    await VisaService.HoSo.deleteTaiLieu({
      ID: _file?.ID,
    });
  };
  return (
    <Drawer
      title="Tài liệu"
      width={500}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={24}>
          <p style={{ textAlign: "center", fontWeight: "bold", marginTop: 10 }}>
            {data?.TenHoSo}
          </p>
        </Col>
        <Col span={24}>
          <UploadComponent
            files={_.map(files, "Link")}
            multiple={true}
            affterUpload={affterUpload}
            onRemove={onRemove}
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormTaiLieu);
