import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  InputNumber,
  Row,
  Col,
  Drawer,
  notification,
  Flex,
  Modal,
  Image,
  Checkbox,
  Popover,
} from "antd";
import _ from "lodash";
import { SanPhamService } from "services/SanPhamService";
import { Format_Currency } from "layouts/ultis/common";
import { BanHangService } from "services/BanHangService";
import { OtherService } from "services/OtherService";
import HoaDonToPrint from "components/HoaDonToPrint";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { KhoHangService } from "services/KhoHangService";
import { CRMService } from "services/CRMService";
import { Format_Datetime } from "layouts/ultis/common";
import { Format_Time } from "layouts/ultis/common";
import { FaMinus, FaPlus, FaRegCircleUser } from "react-icons/fa6";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import InHoaDon from "../BanSanPham/InHoaDon";

const FormPay = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [hinhthuc, setHinhThuc] = useState([]);
  const componentRef = useRef();
  const [isMotPhan, setIsMotPhan] = useState(false);
  const defaulData = {
    SanPham: [],
    ThanhTien: 0,
    ThanhToan: 0,
    KhachTra: 0,
    TyLeCK: 0,
    TienCK: 0,
  };
  const [data, setData] = useState(defaulData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ck, setCk] = useState({ Type: "PT", Value: 0 });

  const loadcategories = async () => {
    let _ht = await OtherService.getHinhThucTT();
    setHinhThuc(_ht.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      loadcategories();
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setIsMotPhan(false);
    setData(defaulData);
  };
  const SumTotal = (_data) => {
    _data.KhachTra =
      _data.ThanhTien - (_data.TienCK ?? 0) - (_data.TienTru ?? 0);
    _data.ThanhToan =
      _data.ThanhTien - (_data.TienCK ?? 0) - (_data.TienTru ?? 0);
    _data.TienThua = 0;
  };
  const applyChangeTotal = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "HinhThucTT":
        _data[p] = v;
        if (v === 2) {
          _data.TienChuyenKhoan = _data.KhachTra;
          showQRCode();
        }
        break;
      case "ThanhToan":
        _data[p] = v;
        _data.TienThua = v - _data.KhachTra;
        break;
      default:
        _data[p] = v;
        SumTotal(_data);
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async () => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    if (data?.cardType?.TyGiaDiem) {
      _data.DiemTich = _data.KhachTra / data?.cardType?.TyGiaDiem;
    }
    let slmt = _.sum(_.map(_data.SanPham, "SoLuongMT")) ?? 0;
    let sl = _.sum(_.map(_data.SanPham, "SoLuong")).toFixed(2);
    if (slmt > 0 && slmt !== sl) {
      _data.SanPham.map((item) => (item.SoLuong = item.SoLuongMT));
      _data.IsMotPhan = true;
    }
    _data.SanPham = _data.SanPham.filter((v) => v.SoLuong);
    _data.ThanhToan =
    _data.ThanhToan > _data.KhachTra ? _data.KhachTra : _data.ThanhToan;
    let _res = await BanHangService.addBanHang(_data);
    // let _res = await BanHangService.BanTheoBan.pay({
    //   ..._data,
    //   ID: _data.MaBH,
    // });
    if (_res.status === 2000) {
      if (_data?.TienTru > 0) {
        CRMService.Zalo.Send({
          Phone: data?.KhachHang?.DiDong,
          TenKH: data?.KhachHang?.TenCongTy,
          KyHieuKH: data?.KhachHang?.DiDong,
          SoTien1: Format_Currency(_data.TienTru),
          SoTien2: Format_Currency(data?.diemTien?.tienThe - _data.TienTru),
          TempID: "5919",
        });
      }
      //tao phieu xua kho
      let sp = [];
      await Promise.all(
        _data.SanPham?.map(async (item) => {
          if (item.MaNhom === 3) {
            let _res = await SanPhamService.getDinhLuong({ MaSP: item.MaSP });
            _res.data?.map((dl) => {
              sp.push({
                ...item,
                MaKho: _data.MaKho,
                MaDVT: dl.MaDVT,
                MaSP: dl.MaSPNVL,
                SoLuong: item.SoLuong * dl.SoLuong,
                DonGia: dl.DonGia,
                ThanhTien: item.SoLuong * dl.SoLuong * dl.DonGia,
              });
            });
          } else {
            sp.push(item);
          }
        })
      );
      let _payload = {
        NgayXK: new Date(),
        MaLoai: 1,
        DienGiai: "Bán hàng",
        MaKho: _data.MaKho,
        SanPham: sp,
      };
      await KhoHangService.XuatKho.add(_payload);
      //end
      //add dữ liệu form in
      _data.TenCongTy = data?.KhachHang?.Name;
      _data.TenBan = data?.TenBan;
      _data.HinhThuc = hinhthuc?.find(
        (v) => v.ID === _data.HinhThucTT
      )?.TenHinhThuc;
      setData(_data);
      handlePrint();
      openNotification("success", "topRight", `Thanh toán thành công!`);
      onClose();
      onAfterSubmit(_data.MaBan, "EMPTY");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [QRUrl, setQRUrl] = useState(null);
  const showQRCode = async () => {
    let _res = await OtherService.getTaiKhoan({ IsDefault: true });
    if (_res?.data?.length > 0) {
      let _qr = await axios.post(
        "https://api.vietqr.io/v2/generate",
        {
          accountNo: _res?.data[0].SoTK,
          accountName: _res?.data[0].ChuTaiKhoan,
          acqId: _res?.data[0].Bin,
          addInfo: "Chuyển tiền bán sản phẩm",
          amount:
            data?.SanPham?.reduce((pre, current) => {
              return (pre += current.ThanhTien);
            }, 0) -
            (data?.DiemTru ?? 0) * (data?.cardType?.DiemTien ?? 0) -
            (data?.TienTru ?? 0) -
            (data?.SanPham?.reduce((pre, current) => {
              return (pre += current.ThanhTien);
            }, 0) *
              (data?.TyLeCK ?? 0)) /
              100,
          template: "compact",
        },
        {
          headers: {
            "x-client-id": "0d80cef2-f7bd-4db7-a6a1-4e4db05aaaca",
            "x-api-key": "231a2376-bdf7-4287-b5c0-dc44465e71c1",
            "Content-Type": "application/json",
          },
        }
      );
      setQRUrl(_qr?.data.data?.qrDataURL);
      setIsModalOpen(true);
    }
  };
  const renderModalQR = () => {
    const handleOk = () => {
      setIsModalOpen(false);
    };
    return (
      <Modal
        title="Chuyển khoản ngân hàng"
        open={isModalOpen}
        onOk={handleOk}
        width={350}
        onCancel={() => setIsModalOpen(false)}
        footer={() => ""}
      >
        <Image width={300} src={QRUrl} />
      </Modal>
    );
  };
  const SumTotalMT = (_data) => {
    _data.ThanhTien = _.reduce(
      _data.SanPham,
      (sum, n) => {
        return (
          sum +
          ((n.SoLuongMT ?? 0) * n.DonGia -
            ((n.SoLuongMT ?? 0) * n.DonGia * n.TyLeCK) / 100)
        );
      },
      0
    );
    _data.TienCK = (_data.ThanhTien * (_data.TyLeCK ?? 0)) / 100;
    _data.KhachTra =
      _data.ThanhTien - (_data.TienCK ?? 0) - (_data.TienTru ?? 0);
    _data.ThanhToan =
      _data.ThanhTien - (_data.TienCK ?? 0) - (_data.TienTru ?? 0);
  };
  const applyChange = (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "minus":
        let _sp = _data.SanPham?.find((a) => a.ID === v.ID);
        if (_sp && (_sp?.SoLuongMT ?? 0) > 0) {
          if (_sp.MaNhom === 7) {
            _sp.SoLuongMT = 0;
          } else {
            _sp.SoLuongMT = (_sp.SoLuongMT ?? 0) - 1;
          }
        }
        break;
      case "plus":
        let _spp = _data.SanPham?.find((a) => a.ID === v.ID);
        if (_spp && (_spp?.SoLuongMT ?? 0) < _spp?.SoLuong) {
          if (_spp.MaNhom === 7) {
            _spp.SoLuongMT = _spp.SoLuong;
          } else {
            _spp.SoLuongMT = (_spp.SoLuongMT ?? 0) + 1;
          }
        }
        break;
    }
    SumTotalMT(_data);
    setData(_data);
  };
  const renderLeft = () => {
    return (
      <div>
        <Flex justify="space-between" style={{ padding: "10px 0px" }}>
          <div>
            <Flex align="center" gap={8}>
              <FaRegCircleUser size={22} />
              <p style={{ fontWeight: "bold" }}>
                {data?.MaKH ? data?.KhachHang?.Name : "Khách lẻ"}
              </p>
            </Flex>
            <p
              style={{
                color: "#8c8c8c",
                fontSize: 13,
              }}
            >
              Điểm thẻ: {Format_Currency(data?.diemTien?.diemThe)} - Tiền thẻ:{" "}
              {Format_Currency(data?.diemTien?.tienThe)}
            </p>
          </div>
          {!isMotPhan && (
            <Button
              loading={loading}
              type="primary"
              onClick={() => setIsMotPhan(true)}
            >
              Thanh toán một phần
            </Button>
          )}
        </Flex>
        <p
          style={{
            backgroundColor: "#f6f6f6",
            padding: 8,
            fontWeight: "bold",
          }}
        >
          Các món
        </p>
        {data?.SanPham?.map((item, i) => {
          return (
            <Row
              gutter={[3]}
              style={{
                borderBottom: "1px solid #F5F6F8",
                padding: 5,
              }}
              align="middle"
              key={item.ID}
            >
              <Col span={13}>
                <Flex align="center" style={{ paddingRight: 20 }}>
                  <p
                    className="text-overflow-ellipsis"
                    style={{
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      color: "#000810",
                    }}
                  >
                    {i + 1}. {item.TenSP} {item.TyLeCK && `(${item.TyLeCK}%)`}
                  </p>
                </Flex>
                {item.MaNhom === 7 && (
                  <Flex align="center" gap={10}>
                    <p>
                      Giờ: {Format_Datetime(item.GioVao)} -{" "}
                      {item.GioRa
                        ? new Date(item.GioVao).getDate() ===
                          new Date(item.GioRa).getDate()
                          ? Format_Time(item.GioRa)
                          : Format_Datetime(item.GioRa)
                        : "HT"}
                    </p>
                  </Flex>
                )}

                <p style={{ fontSize: 12, color: "#85888C" }}>{item.GhiChu}</p>
              </Col>
              <Col span={5}>
                {isMotPhan ? (
                  <Flex align="center" justify="center" gap={10}>
                    <FaMinus
                      size={18}
                      style={{
                        background: "#0066CC",
                        color: "white",
                        padding: 2,
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={() => applyChange("minus", item)}
                    />
                    <p style={{ fontWeight: "600" }}>
                      {item.SoLuongMT ?? 0} / {item.SoLuong}
                    </p>
                    <FaPlus
                      size={18}
                      onClick={() => applyChange("plus", item)}
                      style={{
                        background: "#0066CC",
                        color: "white",
                        padding: 2,
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    />
                  </Flex>
                ) : (
                  <p>{item.SoLuong}</p>
                )}
              </Col>
              <Col span={3}>{Format_Currency(item.DonGia)}</Col>
              {/* <Col span={3}>
                  <InputNumber
                    style={{ width: "100%" }}
                    // suffix="%"
                    value={item.TyLeCK}
                    onChange={(v) => applyChange("TyLeCK", v, item.MaSP)}
                  />
                </Col>
                <Col span={3}> {Format_Currency(item.TienCK)}</Col> */}
              <Col span={3}>
                <p style={{ fontWeight: "bold" }}>
                  {Format_Currency(item.ThanhTien)}
                </p>
              </Col>
            </Row>
          );
        })}
      </div>
    );
  };
  const renderPay = () => {
    const appllyCK = (p, v) => {
      let _res = _.cloneDeep(ck);
      switch (p) {
        case "Value":
          _res[p] = v;
          if (_res.Type === "VND") {
            _res.TyLeCK = Number((data?.ThanhTien / v)?.toFixed(2));
            _res.TienCK = v;
          } else {
            _res.TienCK = Number(((data?.ThanhTien * v) / 100)?.toFixed(2));
            _res.TyLeCK = v;
          }
          break;
        default:
          _res[p] = v;
          break;
      }
      setCk(_res);
    };
    const handleOpenChange = async (newOpen) => {
      if (newOpen === false) {
        if (ck.TienCK >= 0) {
          let _data = _.cloneDeep(data);
          _data.TienCK = ck?.TienCK;
          _data.TyLeCK = ck?.TyLeCK;
          SumTotal(_data);
          setCk({ Type: "PT", Value: 0 });
          setData(_data);
        }
      }
    };
    const content = () => {
      return (
        <Flex
          align="center"
          style={{ width: 300 }}
          gap={5}
          justify="space-between"
        >
          <p>Giảm giá</p>
          <InputNumber
            style={{ width: "40%" }}
            value={ck.Value}
            onChange={(v) => appllyCK("Value", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          <Button
            type={ck.Type === "VND" ? "primary" : "dashed"}
            onClick={() => appllyCK("Type", "VND")}
          >
            VND
          </Button>
          <Button
            type={ck.Type === "PT" ? "primary" : "dashed"}
            onClick={() => appllyCK("Type", "PT")}
          >
            %
          </Button>
        </Flex>
      );
    };
    return (
      <div>
        <Flex justify="flex-end" align="center" style={{ paddingBottom: 10 }}>
          <p
            style={{
              color: "#8c8c8c",
              fontSize: 13,
            }}
          >
            {Format_Datetime(new Date())}
          </p>
        </Flex>
        <Flex
          align="center"
          justify="space-between"
          style={{ margin: "10px 0px" }}
        >
          <p style={{ width: "120px", fontSize: 14, fontWeight: "500" }}>
            Tổng tiền hàng
          </p>
          <p style={{ fontSize: 16, fontWeight: "500" }}>
            {Format_Currency(data?.ThanhTien)}
          </p>
        </Flex>
        <Flex
          align="center"
          justify="space-between"
          style={{ margin: "10px 0px" }}
        >
          <p style={{ width: "120px", fontWeight: "500" }}>
            Giảm giá {data?.TyLeCK && `(${data?.TyLeCK}%)`}
          </p>
          <Popover
            content={content()}
            trigger="click"
            onOpenChange={(v) => handleOpenChange(v)}
          >
            <InputNumber
              value={data?.TienCK}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Popover>
        </Flex>
        {data?.diemTien?.diemThe?.length > 0 && (
          <Flex align="center">
            <p style={{ width: "120px", fontWeight: "500" }}>Trừ điểm</p>
            <InputNumber
              max={data?.diemTien?.diemThe}
              value={data?.DiemTru}
              onChange={(v) => applyChangeTotal("DiemTru", v)}
            />
          </Flex>
        )}
        {data?.diemTien?.tienThe > 0 && (
          <Flex
            align="center"
            justify="space-between"
            style={{ margin: "10px 0px" }}
          >
            <p style={{ width: "120px" }}>Trừ tiền thẻ</p>
            <InputNumber
              max={data?.diemTien?.tienThe}
              value={data?.TienTru}
              onChange={(v) => applyChangeTotal("TienTru", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Flex>
        )}
        <Flex
          align="center"
          justify="space-between"
          style={{ margin: "10px 0px" }}
        >
          <p style={{ fontWeight: "bold" }}>Khách cần trả</p>
          <p style={{ fontWeight: "bold", fontSize: 16 }}>
            {Format_Currency(data?.KhachTra)}
          </p>
        </Flex>
        <Flex
          align="center"
          justify="space-between"
          style={{ margin: "10px 0px" }}
        >
          <p style={{ fontWeight: "500" }}>Khách thanh toán</p>
          <InputNumber
            disabled={!data?.KhachHang}
            value={data?.ThanhToan}
            onChange={(v) => applyChangeTotal("ThanhToan", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Flex>
        {data?.HinhThucTT === 4 && (
          <>
            <Flex
              align="center"
              justify="space-between"
              style={{ margin: "10px 0px" }}
            >
              <p style={{ fontWeight: "500" }}>Tiền chuyển khoản</p>
              <InputNumber
                value={data?.TienChuyenKhoan}
                onChange={(v) => applyChangeTotal("TienChuyenKhoan", v)}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Flex>
            <Flex
              align="center"
              justify="space-between"
              style={{ margin: "10px 0px" }}
            >
              <p style={{ fontWeight: "500" }}>Tiền mặt</p>
              <p style={{ fontSize: 16 }}>
                {Format_Currency(data?.KhachTra - (data?.TienChuyenKhoan ?? 0))}
              </p>
            </Flex>
          </>
        )}
        <Flex
          align="center"
          justify="space-between"
          style={{ margin: "10px 0px" }}
        >
          <p style={{ fontWeight: "500" }}>Tiền thừa trả khách</p>
          <p>{Format_Currency(data?.TienThua)}</p>
        </Flex>
        {hinhthuc?.map((item) => {
          return (
            <Checkbox
              key={item.ID}
              value={item.ID}
              checked={data?.HinhThucTT === item.ID}
              onChange={(v) => applyChangeTotal("HinhThucTT", v.target.value)}
            >
              {item.TenHinhThuc}
            </Checkbox>
          );
        })}
        <Button
          loading={loading}
          type="primary"
          icon={<RiMoneyDollarCircleLine size={25} />}
          className="btn-thanhtoan"
          style={{
            width: "100%",
            marginTop: 30,
          }}
          onClick={() => onSubmit()}
        >
          Thanh toán
        </Button>
      </div>
    );
  };
  return (
    <Drawer
      title="Thanh toán"
      width={950}
      onClose={() => onClose()}
      open={open}
      className="banhangpay"
    >
      {contextHolder}
      <Row gutter={[20, 0]}>
        <Col span={16}>{renderLeft()}</Col>
        <Col span={8}>{renderPay()}</Col>
      </Row>
      <div style={{ display: "none" }}>
        <InHoaDon ref={componentRef} data={data} />
      </div>
      {renderModalQR()}
    </Drawer>
  );
};
export default forwardRef(FormPay);
