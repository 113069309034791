import React, { useState,useEffect,createContext } from 'react';
import {  HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

const SignalrContext = createContext({name: "signalr"});
export const SignalrContextProvider = ({children,user}) => {
    const [hubConnection, setHubConnection] = useState(null);
    useEffect(() => {
      try {
        let nhanvien = JSON.parse(localStorage.getItem("user"));
        let ctdk = localStorage.getItem("MaCTDK");
        (async () => {
          let connection = new HubConnectionBuilder()
            .withUrl("https://api-crm.beesky.vn/thongbao")
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();
          connection.onreconnected(async (connectionId) => {
            connection.invoke(ctdk.toLowerCase()+String(nhanvien.MaNV));
          });
          await connection.start();
          await connection.invoke("JoinGroup", ctdk.toLowerCase() +String(nhanvien.MaNV));
          setHubConnection(connection);
        })();
      } catch {}
      return () => {
        if (hubConnection) {
          hubConnection.stop();
        }
      };
    }, []);
    return <SignalrContext.Provider value={{hubConnection}}>
        {children}
    </SignalrContext.Provider>
  };
export default SignalrContext;