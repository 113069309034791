import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  Drawer,
  Flex,
  Tag,
  notification,
  Popconfirm,
  Row,
  Col,
  Input,
  Checkbox,
} from "antd";
import _ from "lodash";
import { useReactToPrint } from "react-to-print";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { BanHangService } from "services/BanHangService";
import HoaDonToPrint from "./HoaDonToPrint";
import { OtherService } from "services/OtherService";
import { FaTrashAlt } from "react-icons/fa";
import { SanPhamService } from "services/SanPhamService";
import { CRMService } from "services/CRMService";
let exits = {};
function taoSoDuyNhatBangDate() {
  try {
    const timestamp = Date.now().toString();
    const value = timestamp.slice(-9);
    if (exits[value]) {
      return taoSoDuyNhatBangDate();
    } else {
      exits[value] = true;
      return value;
    }
  } catch {
    return taoSoDuyNhatBangDate();
  }
}
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const componentRef = useRef();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sanpham, setSanpham] = useState([]);
  const [isQR, setIsQR] = useState(false);
  const [the, setThe] = useState([]);
  const [goidichvu, setGoidichvu] = useState([]);

  const state = {
    NEW: "Mới",
    PAYMENT: "Đã thanh toán",
    CANCEL: "Đã huỷ",
    REJECT: "Từ chối",
    ACCEPT: "Đã duyệt",
    DONE: "Đã xuất vé",
  };
  const stateColor = {
    NEW: "blue",
    PAYMENT: "cyan",
    CANCEL: "orange",
    REJECT: "red",
    ACCEPT: "green",
    DONE: "#1677ff",
  };
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      let _the = await BanHangService.getBHThe({ MaBH: item.ID });
      let _res = await BanHangService.getBHSanPHam({ MaBH: item.ID });
      let _gdv = await SanPhamService.filterGoiDV();
      setGoidichvu(_gdv.data ?? []);
      let sp = [];
      _res.data?.map((item) => {
        let qr = _the.data?.filter(
          (v) => v.MaSP === item.MaSP && v.MaGDV === item.MaGDV
        );
        sp.push({
          ...item,
          QRCodes: _.map(qr, "MaVach"),
        });
      });
      setThe(_the?.data);
      setSanpham(sp);
      setOpen(true);
      setData(item);
    },
  }));
  const onClose = () => {
    setOpen(false);
    onAfterSubmit();
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const onAccept = async (state) => {
    setLoading(true);
    let _the = [];
    if (isQR) {
      sanpham?.map((item) => {
        for (let index = 0; index < item.SoLuong; index++) {
          let qr = taoSoDuyNhatBangDate();
          _the.push({
            MaVach: qr,
            MaGoiDV: item.MaGDV,
            MaSP: item.MaSP,
            SoLanQuet: item.SoLanQuet ?? 1,
            NgayTao: item.NgayBH,
            Type: "QR",
          });
        }
      });
    } else {
      _the = the;
    }
    let _res = await BanHangService.changeState({
      ID: data.ID,
      State: state,
      MaLPC: 3,
      TongTien: data.ThanhTien,
      MaKH: data.MaKH,
      The: _the,
      LyDoPT: "Duyệt vé online",
    });
    setLoading(false);
    if (_res.status === 2000) {
      if (state === "ACCEPT") {
        OtherService.addLichSuThaoTac({
          Modules: "BanVe",
          SoGiaoDich: data?.SoBH,
          NoiDung: "Duyệt vé online",
          LinkID: data?.ID,
        });
        CRMService.Zalo.Send({
          Phone: data?.DiDong,
          TenKH: data?.TenCongTy,
          KyHieuKH: data?.DiDong,
          MaVe: data?.SoBH,
          TempID: "5920",
        });
      } else {
        OtherService.addLichSuThaoTac({
          Modules: "BanVe",
          SoGiaoDich: data?.SoBH,
          NoiDung: "Từ chối vé online",
          LinkID: data?.ID,
        });
      }
      onClose();
    }
  };
  const confirm = (e) => {
    onAccept("ACCEPT");
  };

  const confirm2 = (e) => {
    onAccept("REJECT");
  };
  const print = () => {
    OtherService.addLichSuThaoTac({
      Modules: "BanVe",
      SoGiaoDich: data?.SoBH,
      NoiDung: "In vé online",
      LinkID: data?.ID,
    });
    handlePrint();
  };
  const [mavach, setMavach] = useState("");
  const addThe = (v) => {
    setMavach(v);
  };
  useEffect(() => {
    let time = null;
    if (mavach !== "") {
      if (_.sumBy(sanpham, "SoLuong") === the?.length) {
        return;
      }
      time = setTimeout(() => {
        let _lstsp = sanpham;
        let magdv = 0;
        let solanquet = 0;
        let masp = 0;
        let dem = 0;
        let ngaybh;
        // let lstthe = theInput;
        for (let index = 0; index < _lstsp?.length; index++) {
          dem = dem + _lstsp[index].SoLuong;
          if (the.length < dem) {
            magdv = _lstsp[index].MaGDV;
            masp = _lstsp[index].MaSP;
            ngaybh = _lstsp[index].NgayBH;
            solanquet = _lstsp[index].SoLanQuet ?? 1;
            break;
          }
        }
        let _the = the?.find((f) => f.MaVach === mavach);
        if (!_the) {
          let _t = _.cloneDeep(the);
          _t.unshift({
            MaVach: mavach,
            Type: "CARD",
            MaGoiDV: magdv,
            SoLanQuet: solanquet,
            MaSP: masp,
            NgayTao: ngaybh,
          });
          setThe(_t);
        }
        setMavach("");
      }, 500);
    }
    return () => clearTimeout(time);
  }, [mavach]);
  const removeThe = (i) => {
    let _data = _.cloneDeep(the);
    _data.splice(i, 1);
    setThe(_data);
  };
  const handleXacNhanXuatVe = async () => {
    setLoading(true);
    let _the = the;
    let _res = await BanHangService.xacNhanXuatVe({
      ID: data.ID,
      MaKH: data.MaKH,
      The: _the,
    });
    setLoading(false);
    if (_res.status === 2000) {
      OtherService.addLichSuThaoTac({
        Modules: "BanVe",
        SoGiaoDich: data?.SoBH,
        NoiDung: "Xuất vé online",
        LinkID: data?.ID,
      });
      openNotification("success", "topRight", "Xuất vé thành công");
      onClose();
    }
  };
  return (
    <Drawer
      title="Thông tin chi tiết vé"
      width="500px"
      onClose={onClose}
      open={open}
    >
      {contextHolder}
      <div style={{ margin: "10px 0px" }}>
        <p>
          Tên khách hàng:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {data.TenCongTy}
          </span>
        </p>
        <p>
          Di động:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {data.DiDong}
          </span>
        </p>
        <p>
          Email:
          <span style={{ fontWeight: "bold", fontSize: 14 }}>{data.Email}</span>
        </p>
      </div>
      <hr />
      <div style={{ margin: "10px 0px" }}>
        <p>
          Ngày đặt vé:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {/* {Format_Datetime(data.NgayBH)} */}
            {new Date(data.NgayBH).getDate()}/{" "}
            {new Date(data.NgayBH).getMonth() + 1}/{" "}
            {new Date(data.NgayBH).getFullYear()}
          </span>
        </p>
        <p>
          Tổng tiền:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {Format_Currency(data.ThanhTien)}
          </span>
        </p>
        <p>
          Số lượng vé:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {_.sum(_.map(sanpham, "SoLuong"))}
          </span>
        </p>
        <Tag
          style={{ fontSize: 16, padding: 10 }}
          color={stateColor[data.State]}
        >
          {state[data.State]}
        </Tag>
      </div>
      <hr />
      <div style={{ margin: "10px 0px" }}>
        <Flex
          justify="space-between"
          style={{ borderBottom: "1px dotted #dee2e6" }}
        >
          <p style={{ fontWeight: "600" }}>Gói bán x Số lượng</p>
          <p style={{ fontWeight: "600" }}>Đơn giá(đ)</p>
        </Flex>
        {sanpham?.map((item) => {
          return (
            <Flex justify="space-between">
              <p style={{ marginTop: 0 }}>
                {item.TenSP} x{item.SoLuong}
              </p>
              <p style={{ marginTop: 0 }}>
                {Format_Currency(item.DonGia * item.SoLuong)}
              </p>
            </Flex>
          );
        })}
      </div>
      <hr />
      {/* {data.State === "ACCEPT" && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Button
            type="primary"
            style={{ marginRight: 5 }}
            loading={loading}
            onClick={() => print()}
          >
            In vé
          </Button>
        </div>
      )} */}
      <div style={{ margin: "10px 0px" }}>
        {/* {data?.State !== "ACCEPT" && (
          <>
            <Checkbox checked={isQR === true} onChange={() => setIsQR(true)}>
              QR
            </Checkbox>
            <Checkbox checked={isQR === false} onChange={() => setIsQR(false)}>
              Thẻ
            </Checkbox>
          </>
        )} */}

        {data?.State === "ACCEPT" && (
          <Input
            placeholder="Quẹt thẻ"
            style={{ width: "100%" }}
            value={mavach}
            onChange={(v) => addThe(v.target.value)}
          />
        )}
        <div
          style={{
            width: "100%",
            overflow: "scroll",
            padding: "0px 10px",
            paddingTop: 10,
          }}
          className="list-the"
        >
          {the?.map((item, i) => {
            return (
              <Row align="middle" gutter={[0, 50]}>
                <Col span={2}>
                  <p>{i + 1}. </p>
                </Col>
                <Col span={3}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => removeThe(i)}
                  >
                    <FaTrashAlt />
                  </div>
                </Col>
                <Col span={9}>
                  <p style={{ fontWeight: "bold" }}>{item.MaVach}</p>
                </Col>
                <Col span={10}>
                  <p>
                    {goidichvu?.find((v) => v.ID === item.MaGoiDV)?.TenGoi} (
                    Quẹt: {item.SoLanQuet} )
                  </p>
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
      {data.State === "ACCEPT" && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Popconfirm
            title="Duyệt vé"
            description="Bạn có chắc muốn duyệt vé này không?"
            onConfirm={handleXacNhanXuatVe}
            // onCancel={cancel}
            okText="Đồng ý"
            cancelText="Huỷ"
          >
            <Button style={{ marginRight: 5 }} type="primary" loading={loading}>
              Xác nhận xuất vé
            </Button>
          </Popconfirm>
          {/* <Popconfirm
            title="Từ chối vé"
            description="Bạn có chắc muốn từ chối vé này không?"
            onConfirm={confirm2}
            okText="Đồng ý"
            cancelText="Huỷ"
          >
            <Button
              loading={loading}
              style={{ marginLeft: 5 }}
              type="primary"
              danger
              ghost
            >
              Từ chối
            </Button>
          </Popconfirm> */}
        </div>
      )}
      <div style={{ display: "none" }}>
        <HoaDonToPrint ref={componentRef} data={{ SanPham: sanpham }} />
      </div>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
