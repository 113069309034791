import axios from "axios";

export const API_URL = () => {
  let url = "";
  switch (window.location?.host) {
    case "crm.beesky.vn":
      url = "https://api-crm.beesky.vn";
      break;
    case "117.4.1.95:8001":
      url = "http://117.4.1.95:8002";
      break;
    case "10.8.0.26:8001":
      url = "http://10.8.0.26:8002";
      break;
    default:
      url = "https://api-crm.beesky.vn";
      break;
  }
  return url;
};
const axiosApi = (headers) => {
  return axios.create({
    baseURL: API_URL(),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...headers,
    },
  });
};
export default axiosApi;

export async function SendSMS(payload) {
  return await axios
    .post("https://api-fns.fpt.work/api/send-message", payload, {
      headers: {
        "app-id": 1700118304,
        "secret-key": "7bb346a73a6d599ebae3015c68bde3cd",
      },
    })
    .then((response) => response.data);
}