import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  DatePicker,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  InputNumber,
  Checkbox,
  Select,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import vi from "dayjs/locale/vi";
import { CardService } from "services/CardService";
import { SanPhamService } from "services/SanPhamService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("0");

  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    LoaiUuDai: "CK",
    TuNgay: new Date(),
    DenNgay: new Date(),
    NgayNhacGiaHan: 7,
  };
  const [data, setData] = useState(defaulData);
  const [open, setOpen] = useState(false);
  const [loaithe, setLoaithe] = useState([]);
  const [loaisp, setLoaisp] = useState([]);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const loadCateTab3 = async () => {
    let _res = await CardService.getType();
    if (_res.data) setLoaithe(_res.data);
    let _lsp = await SanPhamService.getLSP({ ApDung: true });
    setLoaisp(_lsp?.data ?? []);
  };

  useImperativeHandle(ref, () => ({
    show: async (tab) => {
      setOpen(true);
      setActiveTab(tab);
      if (tab === "2") loadCateTab3();
    },
    update: async (data, tab) => {
      setOpen(true);
      setData(data);
      setActiveTab(tab);
      if (tab === "2") loadCateTab3();
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);

    let _res = null;
    if (activeTab === "0") {
      _res = await CardService.addCardType(_data);
    } else if (activeTab === "1") {
      _res = await CardService.addChinhSach(_data);
    } else if (activeTab === "2") {
      _res = await CardService.addUuDai(_data);
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} loại thẻ thành công!`
      );
      onAfterSubmit();
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderTab1 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={8}>
          <p>Tên loại thẻ</p>
          <Input
            value={data?.TenLT}
            placeholder="Nhập tên loại thẻ"
            onChange={(e) => applyChange("TenLT", e.target.value)}
          />
        </Col>

        <Col span={8}>
          <p>Điểm đạt</p>
          <InputNumber
            suffix="vnđ"
            placeholder="Mức điểm đạt"
            style={{ width: "100%" }}
            value={data?.MucDat}
            onChange={(v) => applyChange("MucDat", v)}
          />
        </Col>
        <Col span={8}>
          <p>Điểm duy trì</p>
          <InputNumber
            suffix="vnđ"
            placeholder="Nhập điểm duy trì"
            style={{ width: "100%" }}
            value={data?.DuyTri}
            onChange={(v) => applyChange("DuyTri", v)}
          />
        </Col>
        <Col span={8}>
          <p>1 Điểm / tiền</p>
          <InputNumber
            suffix="vnđ"
            placeholder="1 điểm / tiền"
            style={{ width: "100%" }}
            value={data?.DiemTien}
            onChange={(v) => applyChange("DiemTien", v)}
          />
        </Col>
        <Col span={8}>
          <p>Số tiền / 1 điểm</p>
          <InputNumber
            suffix="vnđ"
            placeholder="Số tiền / 1 điểm"
            style={{ width: "100%" }}
            value={data?.TyGiaDiem}
            onChange={(v) => applyChange("TyGiaDiem", v)}
          />
        </Col>
        <Col span={8}>
          <p>Số tiền giới thiệu / điểm</p>
          <InputNumber
            suffix="vnđ"
            placeholder="Số tiền giới thiệu / điểm"
            style={{ width: "100%" }}
            value={data?.TyGiaDiemGioiThieu}
            onChange={(v) => applyChange("TyGiaDiemGioiThieu", v)}
          />
        </Col>
        <Col span={8}>
          <p>Số điểm / 1 lần quẹt</p>
          <InputNumber
            suffix="vnđ"
            placeholder="Số điểm / 1 lần quẹt"
            style={{ width: "100%" }}
            value={data?.DiemCheckIn}
            onChange={(v) => applyChange("DiemCheckIn", v)}
          />
        </Col>
        <Col span={8}>
          <p>Số ngày nhắc gia hạn</p>
          <InputNumber
            // suffix="vnđ"
            placeholder="Số ngày nhắc gia hạn"
            style={{ width: "100%" }}
            value={data?.NgayNhacGiaHan}
            onChange={(v) => applyChange("NgayNhacGiaHan", v)}
          />
        </Col>
        <Col span={24}>
          <Checkbox
            checked={data?.isCheckSP}
            onChange={(v) => applyChange("isCheckSP", !data?.isCheckSP)}
          >
            Tích điểm sản phẩm
          </Checkbox>
        </Col>
      </Row>
    );
  };
  const renderTab2 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={12}>
          <p>Từ ngày</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.TuNgay)}
            placeholder="Từ ngày"
            locale={vi}
            onChange={(v) => applyChange("TuNgay", v)}
          />
        </Col>
        <Col span={12}>
          <p>Đến ngày</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.DenNgay)}
            placeholder="Đến ngày"
            locale={vi}
            onChange={(v) => applyChange("DenNgay", v)}
          />
        </Col>
        <Col span={12}>
          <p>Nạp tiền từ</p>
          <InputNumber
            suffix="vnđ"
            style={{ width: "100%" }}
            value={data?.NapTienTu}
            onChange={(v) => applyChange("NapTienTu", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={12}>
          <p>Nạp tiền đến</p>
          <InputNumber
            suffix="vnđ"
            style={{ width: "100%" }}
            value={data?.NapTienDen}
            onChange={(v) => applyChange("NapTienDen", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={12}>
          <p>Tỷ lệ CK</p>
          <InputNumber
            suffix="%"
            style={{ width: "100%" }}
            value={data?.TyLeCK}
            onChange={(v) => applyChange("TyLeCK", v)}
          />
        </Col>
        <Col span={12}>
          <p>Tỷ lệ cộng thêm</p>
          <InputNumber
            suffix="%"
            style={{ width: "100%" }}
            value={data?.TyLeCongThem}
            onChange={(v) => applyChange("TyLeCongThem", v)}
          />
        </Col>
        <Col span={24}>
          <Checkbox
            checked={data?.ApDung}
            onChange={(v) => applyChange("ApDung", !data?.ApDung)}
          >
            Áp dụng
          </Checkbox>
        </Col>
      </Row>
    );
  };
  const renderTab3 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={12}>
          <p>Tên chương trình</p>
          <Input
            value={data?.TenCT}
            placeholder="Nhập tên chương trình"
            onChange={(e) => applyChange("TenCT", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Loại ưu đã</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.LoaiUuDai}
            placeholder="Chọn loại ưu đãi"
            onChange={(v) => applyChange("LoaiUuDai", v)}
            options={[
              { ID: "CK", Name: "Chiết khấu" },
              { ID: "VOUCHER", Name: "Voucher" },
            ]}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={12}>
          <p>Từ ngày</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.TuNgay)}
            placeholder="Từ ngày"
            locale={vi}
            onChange={(v) => applyChange("TuNgay", v)}
          />
        </Col>
        <Col span={12}>
          <p>Đến ngày</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.DenNgay)}
            placeholder="Đến ngày"
            locale={vi}
            onChange={(v) => applyChange("DenNgay", v)}
          />
        </Col>
        <Col span={12}>
          <p>Loại thẻ</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaLoaiThe}
            placeholder="Chọn loại thẻ"
            onChange={(v) => applyChange("MaLoaiThe", v)}
            options={loaithe}
            fieldNames={{ label: "TenLT", value: "ID" }}
          />
        </Col>
        {data?.LoaiUuDai === "CK" && (
          <>
            <Col span={12}>
              <p>Loại sản phẩm</p>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaLoaiSP}
                placeholder="Chọn loại sản phẩm"
                onChange={(v) => applyChange("MaLoaiSP", v)}
                options={loaisp}
                fieldNames={{ label: "TenLSP", value: "MaLSP" }}
              />
            </Col>
            <Col span={12}>
              <p>Tỷ lệ CK</p>
              <InputNumber
                suffix="%"
                style={{ width: "100%" }}
                value={data?.TyLeCK}
                onChange={(v) => applyChange("TyLeCK", v)}
              />
            </Col>
          </>
        )}
        {data?.LoaiUuDai === "VOUCHER" && (
          <Col span={12}>
            <p>Tiền Voucher</p>
            <InputNumber
              suffix="vnđ"
              style={{ width: "100%" }}
              value={data?.TienVoucher}
              onChange={(v) => applyChange("TienVoucher", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
        )}

        <Col span={24}>
          <Checkbox
            checked={data?.ApDung}
            onChange={(v) => applyChange("ApDung", !data?.ApDung)}
          >
            Áp dụng
          </Checkbox>
        </Col>
      </Row>
    );
  };
  return (
    <Drawer
      title="DANH MỤC THẺ"
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          {/* <Button onClick={() => onSubmit()}>Lưu</Button> */}
          <Button onClick={() => onSubmit(true)} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {activeTab === "0"
        ? renderTab1()
        : activeTab === "1"
        ? renderTab2()
        : renderTab3()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
