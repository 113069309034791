import React, {
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Drawer,
  notification,
  Table,
} from "antd";
import _ from "lodash";
import { Format_Currency } from "layouts/ultis/common";
import { BanHangService } from "services/BanHangService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total,setTotal] = useState({});

  useImperativeHandle(ref, () => ({
    show: async (item) => {
      let _res = await BanHangService.Viettel.getHoaDonDetail({
        MaHD: item.ID,
      });
      setTotal(item);
      setData(_res.data)
      setOpen(true);
    },
  }));
  const onClose = () => {
    setOpen(false);
    onAfterSubmit();
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "lineNumber",
      width: 150,
    },

    {
      title: "Tên sản phẩm",
      dataIndex: "itemName",
      width: 150,
    },
    {
      title: "Unit",
      dataIndex: "unitName",
      width: 100,
    },
    {
      title: "Đơn giá",
      dataIndex: "unitPrice",
      render: (item) => Format_Currency(item),
      width: 100,
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      width: 100,
    },
    {
      title: "Tổng tiền",
      dataIndex: "itemTotalAmountWithoutTax",
      render: (item) => Format_Currency(item),
      width: 100,
    },
  ];
  return (
    <Drawer
      title="Thông tin chi tiết"
      width={900}
      onClose={onClose}
      open={open}
    >
      {contextHolder}
      <Table
        style={{
          height: 100,
        }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 150,
          // x: 1500,
        }}
        size="small"
        summary={(pageData) => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={5}>
                  Tổng chưa VAT
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(total.TongChuaVAT)}
                  </p>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={5}>
                  Tiền VAT
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(total.TongGomVAT - total.TongChuaVAT)}
                  </p>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={5}>
                  Tổng gồm VAT
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(total.TongGomVAT)}
                  </p>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
