import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
} from "antd";
import _ from "lodash";
import DebounceSelect from "components/DebounceSelect";
import { useReactToPrint } from "react-to-print";
import { CardService } from "services/CardService";
import { OtherService } from "services/OtherService";
import { CRMService } from "services/CRMService";
import { Format_Currency } from "layouts/ultis/common";

const { TextArea } = Input;
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [hinhthuc, setHinhThuc] = useState([]);
  const defaultData = {
    TyLeCK: 0,
    PTCongTienNap: 0,
  };
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [lydo, setLydo] = useState([]);
  const [loathe, setLoaithe] = useState({});
  const [chinhsach, setChinhsach] = useState([]);
  const refPrint = useRef(null);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const loadCategories = async () => {
    let _ld = await CardService.getLyDoCongDiem();
    setLydo(_ld?.data ?? []);
    let _cs = await CardService.findChinhSach();
    setChinhsach(_cs?.data ?? []);
    let _ht = await OtherService.getHinhThucTT();
    setHinhThuc(_ht.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaKH":
        if (v?.length > 0) {
          _data[p] = v;
          if (v?.length > 1) {
            _data[p] = v.splice(1);
          }
          _data.MaVach = JSON.parse(_data?.MaKH[0].title)?.MaVach;
          _data.SoThe = JSON.parse(_data?.MaKH[0].title)?.SoThe;
          _data.MaThe = JSON.parse(_data?.MaKH[0].title)?.MaThe;
        } else {
          _data[p] = [];
        }
        break;
      case "TyLeCK":
        _data[p] = v;
        _data.TienCK = (v * _data.TienNap) / 100;
        _data.TongSauCK = _data.TienNap - _data.TienCK;
        break;
      case "TienNap":
        _data[p] = v;
        let cs = chinhsach.find((c) => c.NapTienTu <= v && c.NapTienDen >= v);
        if (cs) {
          _data.TyLeCK = cs.TyLeCK;
          _data.PTCongTienNap = cs.TyLeCongThem;
        } else {
          _data.TyLeCK = 0;
          _data.PTCongTienNap = 0;
        }
        _data.TienCK = (v * _data.TyLeCK) / 100;
        _data.TongSauCK = v - _data.TienCK;
        _data.TienCongThem = (v * _data.PTCongTienNap) / 100;
        break;
      case "PTCongTienNap":
        _data[p] = v;
        _data.TienCongThem = (v * _data.TienNap) / 100;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  async function fetchUserList(username) {
    return CardService.filterCustomer({ InputSearch: username }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenCongTy} - ${item.DiDong}`,
        value: item.MaKH,
        title: JSON.stringify(item),
      }));
    });
  }
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    // console.log(_data);
    // return;
    if (_data.MaLyDo === 1) {
      if (!loathe?.TyGiaDiem) {
        messageApi.open({
          type: "error",
          content: "Bạn chưa cài tỷ giá điểm, vui lòng kiểm tra lại!",
        });
        return;
      }
      _data.Diem = _data.Tien / (loathe?.TyGiaDiem ?? 0);
    }
    _data.MaKHMT = _data?.MaKH[0].value;
    let _res = await CardService.addCardHistory(_data);
    if (_res.status === 2000) {
      let _sodu = await CardService.getSoDu({ ID: data.MaThe });
      CRMService.Zalo.Send({
        Phone: JSON.parse(_data?.MaKH[0].title)?.DiDong,
        TenKH: JSON.parse(_data?.MaKH[0].title)?.TenCongTy,
        KyHieuKH: JSON.parse(_data?.MaKH[0].title)?.DiDong,
        SoTien1: Format_Currency(data.TienNap),
        SoTien2: Format_Currency(_sodu?.data),
        PhanTram: data.PTCongTienNap + " %",
        SoDiem: Format_Currency(data.TienCongThem) + " VND",
        TempID: "5918",
      });
      openNotification("success", "topRight", "Lưu thành công!");
      onClose();
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => refPrint.current,
  });
  useEffect(() => {
    let time = null;
    if (data.MaVach) {
      time = setTimeout(async () => {
        let _res = await CardService.getCardByMaVach({ MaVach: data.MaVach });
        if (_res.status === 2000) {
          messageApi.open({
            type: "success",
            content: "Thẻ đang hoạt động",
          });
          let _resTypeCardDetail = await CardService.getLoaiTheDetail({
            ID: _res?.data?.MaLT,
          });
          setLoaithe(_resTypeCardDetail?.data ?? {});
          let _kh = await CardService.getKhachHangByID({ ID: _res.data.MaKH });
          setData((pre) => {
            return {
              ...pre,
              SoThe: _res.data?.SoThe,
              MaThe: _res.data?.ID,
              MaKH: [
                {
                  label: `${_kh.data.TenCongTy} - ${_kh.data.DiDong}`,
                  value: _kh.data.MaKH,
                  title: JSON.stringify(_kh.data),
                },
              ],
            };
          });
        } else {
          messageApi.open({
            type: "error",
            content: _res.message,
          });
          setData((pre) => {
            return {
              ...pre,
              SoThe: "",
              MaKH: [],
            };
          });
        }
      }, 1000);
    }
    return () => clearTimeout(time);
  }, [data.MaVach]);
  return (
    <Drawer
      title="Thông tin hoá đơn"
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          {/* <Button onClick={() => onSubmit()}>Lưu</Button> */}
          <Button onClick={() => onSubmit()} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolderMS}
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={12} className="gutter-row">
          <p>Mã vạch</p>
          <Input
            value={data?.MaVach}
            placeholder="Mã vạch"
            onChange={(e) => applyChange("MaVach", e.target.value)}
          />
        </Col>
        <Col span={12} className="gutter-row">
          <p>Số thẻ</p>
          <Input value={data?.SoThe} placeholder="Số thẻ" disabled />
        </Col>
        <Col span={24} className="gutter-row">
          <p>
            Khách hàng <span style={{ color: "red" }}>*</span>
          </p>
          <DebounceSelect
            mode="multiple"
            value={data?.MaKH}
            // status={data?.MaKH ? "" : "error"}
            placeholder="Tìm kiểm khách hàng"
            fetchOptions={fetchUserList}
            onChange={(newValue) => applyChange("MaKH", newValue)}
            style={{
              width: "100%",
            }}
          />
          <p style={{ color: "red" }}>{errors?.MaKH}</p>
        </Col>

        <Col span={12} className="gutter-row">
          <p>
            Nội dung tích thẻ <span style={{ color: "red" }}>*</span>
          </p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaLyDo}
            placeholder="Chọn nội dung tích thẻ"
            onChange={(v) => applyChange("MaLyDo", v)}
            options={lydo}
            fieldNames={{ label: "LyDo", value: "ID" }}
          />
        </Col>
        {(data?.MaLyDo === 2 || data?.MaLyDo === 4) && (
          <Col span={12}>
            <p>Điểm</p>
            <InputNumber
              addonAfter="điểm"
              style={{ width: "100%" }}
              // max={data?.Diem}
              onChange={(newValue) => applyChange("Diem", newValue)}
            />
          </Col>
        )}

        {data?.MaLyDo === 1 && (
          <Col span={12}>
            <p>Tiền hoá đơn</p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="vnđ"
              value={data?.Tien}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              onChange={(v) => applyChange("Tien", v)}
            />
          </Col>
        )}
        {data?.MaLyDo === 3 && (
          <>
            <Col span={12}>
              <p>Tiền nạp</p>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.TienNap}
                // disabled
                addonAfter="vnđ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={(v) => applyChange("TienNap", v)}
              />
            </Col>
            <Col span={12}>
              <p>Hình thức TT</p>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaHT}
                placeholder="Chọn hình thức"
                onChange={(v) => applyChange("MaHT", v)}
                options={hinhthuc}
                fieldNames={{ label: "TenHinhThuc", value: "ID" }}
              />
            </Col>
            <Col span={12}>
              <p>Tỷ lệ CK</p>
              <InputNumber
                max={100}
                style={{ width: "100%" }}
                value={data?.TyLeCK}
                onChange={(v) => applyChange("TyLeCK", v)}
              />
            </Col>
            <Col span={12}>
              <p>Tiền CK</p>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.TienCK}
                disabled
                addonAfter="vnđ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Col>
            <Col span={12}>
              <p>Tổng sau ck</p>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.TongSauCK}
                disabled
                addonAfter="vnđ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Col>
            <Col span={12}>
              <p>% Cộng tiền nạp</p>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.PTCongTienNap}
                onChange={(v) => applyChange("PTCongTienNap", v)}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Col>
            <Col span={12}>
              <p>Tiền cộng thêm</p>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.TienCongThem}
                disabled
                addonAfter="vnđ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Col>
          </>
        )}

        <Col span={24}>
          <p>Ghi chú</p>
          <TextArea
            onChange={(e) => applyChange("GhiChu", e.target.value)}
            placeholder="Ghi chú"
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
