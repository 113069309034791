import React, { useEffect,useRef,useState } from "react";
import {
  Button,
  Popconfirm,
  Table,
  Tooltip,
  notification,
  Row,
  Col,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from 'lodash'
import './scss/index.scss'
import Toolbar from "components/Toolbar";
import { KhoHangService } from "services/KhoHangService";
export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    InputSearch: "",
    MaCT: 0,
    LoaiNhapKho:",,"
  });
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(()=>{
    filter(filterCondition);
  },[])
  const filter =async (_filter) =>{
    setLoading(true);
    let _res = await KhoHangService.NhapKho.filter(_filter);
    if(_res.data) setData(_res.data);
    setLoading(false);
  }
  const onChangeFilter = (_filter) =>{
    filter(_filter);
    setFilterCondition(_filter);
  }
  const onAfterSubmit = ()=>{
    filter(filterCondition);
  }
  const columns = [
    {
      title: "Ngày nhập kho",
      dataIndex: "NgayNK",
      render: (item) => Format_Date(item),
    },
    {
      title: "Kho hàng",
      dataIndex: "TenKho",
    },
    {
      title: "Loại",
      dataIndex: "TenLoai",
    },
    {
      title: "Số lượng",
      dataIndex: "SoLuong",
    },
    {
      title: "Số tiền",
      dataIndex: "SoTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Người tạo",
      dataIndex: "HoTen",
    },
    {
      title: "",
      fixed: "right",
      width: "100px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginRight: 5 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá nhập kho"
              description="Bạn có chắc chắn muốn xoá phiếu nhập?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button danger ghost icon={<CiTrash />} />
              </Tooltip>
            </Popconfirm>

            {/* <Button
              style={{ marginLeft: 5 }}
              type="primary"
              ghost
              onClick={(e) => {
                refMoreButton.current.toggle(e);
                refMoreButtonItem.current = item;
              }}
            >
              Khác
            </Button>
            <OverlayPanel ref={refMoreButton} style={{ width: "180px" }}>
              <Row gutter={[10, 5]}>
                <Col span={24}>
                  <Popconfirm
                    title="Xoá giải đấu"
                    description="Bạn có chắc chắn muốn xoá giải đấu?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(refMoreButtonItem.current?.id)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ width: "100%" }}
                        danger
                        ghost
                        icon={<CiTrash />}
                      >
                        Xoá giải
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Col>
                <Col span={24}>
                  <Popconfirm
                    title="Bắt đầu giải đấu"
                    description="Bạn có chắc chắn muốn bắt đầu giải đấu?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => startTour(refMoreButtonItem.current?.id)}
                  >
                    <Tooltip placement="topRight" title="Bắt đầu">
                      <Button
                        style={{ width: "100%" }}
                        type="primary"
                        ghost
                        icon={<VscDebugStart />}
                      >
                        Bắt đầu giải
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Col>
              </Row>
            </OverlayPanel> */}
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await KhoHangService.NhapKho.delete({ ID: Number(value) });
    if(_res.status === 2000){
      let _data = _.cloneDeep(data);
      let fil = _data.filter(v=>v.ID !==Number(value) );
      setData(fil);
      openNotification(
        "success",
        "topRight",
        "Xoá thành công"
      );
    }else{
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          // left={() => (
          //   <Search
          //     style={{width:"300px"}}
          //     placeholder="Tìm kiếm"
          //     // loading
          //     enterButton
          //   />
          // )}
          right={() => (
            <Button type="primary" onClick={() => refDetail.current.show()}>
              Thêm
            </Button>
          )}
        />
        <Table
          // style={{
          //   height: 100,
          // }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            // x: 2500
          }}
          size="small"
        />
      </Col>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Row>
  );
}
