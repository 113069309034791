import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Divider,
  Checkbox,
  Image,
  Modal,
} from "antd";
import _ from "lodash";
import DebounceSelect from "components/DebounceSelect";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { CardService } from "services/CardService";
import { CustomerService } from "services/CustomerService";
import { SanPhamService } from "services/SanPhamService";
import { BanHangService } from "services/BanHangService";
import { OtherService } from "services/OtherService";
import { CategoryService } from "services/CategoryService";
import { ViettelService } from "services/ViettelService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaultData = {
    NgayHH: new Date(),
    NgayPH: new Date(),
    NgayHL: new Date(),
    NgaySinh: new Date(),
  };
  const [data, setData] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  const [banggia, setBanggia] = useState([]);
  const [sanphamBG, setSanphamBG] = useState([]);

  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [cardType, setCardType] = useState([]);
  const [customerGroup, setCustomerGroup] = useState([]);
  const [openCaLV, setOpenCaLV] = useState(false);
  const [calamviec, setCalamviec] = useState({});
  const [dataquay, setDataquay] = useState({});
  const [quayve, setQuayve] = useState([]);
  const [hinhthuc, setHinhThuc] = useState([]);

  const defaultErrors = {
    TenCongTy: null,
    MaLT: null,
    DiDong: null,
    MaVach: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const [sanPham, setSanPham] = useState([]);
  const loadCategories = async () => {
    let _qv = await BanHangService.getQuayBan();
    setQuayve(_qv.data ?? []);
    let _dataquay = _.cloneDeep(dataquay);
    _dataquay.MaQuay = _qv.isDefault;
    setDataquay(_dataquay);

    let _ht = await OtherService.getHinhThucTT();
    setHinhThuc(_ht.data ?? []);
    let _restype = await CardService.getType();
    let _resgr = await CardService.getCustomerGroup();
    let _sp = await SanPhamService.getListByMaNhom({
      InputSearch: "",
      MaNhom: 6,
    });

    setCardType(_restype?.data ?? []);
    setCustomerGroup(_resgr?.data ?? []);
    setSanPham(_sp?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      let _check = await BanHangService.checkCaLamViec();
      if (!_check.data) {
        setOpenCaLV(true);
      } else {
        setCalamviec(_check.data);
      }
      loadCategories();
      let _bg = await SanPhamService.Categories.getBangGia({
        ApDung: true,
      });
      if (_bg.data?.length > 0) {
        setBanggia(_bg.data ?? []);
        let _day = new Date().getDay();
        let id = 0;
        if (_day === 0 || _day === 6) {
          id = _bg.data?.find((v) => v.isCuoiTuan === true)?.ID;
        } else {
          id = _bg.data?.find((v) => v.MacDinh === true)?.ID;
        }
        let _res = await SanPhamService.Categories.getSanPhamByMaBG({
          MaBG: id,
          InputSearch: "",
        });
        setSanphamBG(_res.data ?? []);
        let _data = _.cloneDeep(data);
        _data.MaBG = id;
        setData(_data);
        // setSanpham(_res.data ?? []);
      }
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
  };
  const SUMTOTAL = (_data) => {
    _data.TienCK = ((_data?.TienThe ?? 0) * (_data?.TyLeCK ?? 0)) / 100;
    _data.KhachTra = (_data?.TienThe ?? 0) - _data.TienCK;
    _data.ConNo = _data.KhachTra - (_data.ThanhToan ?? 0);
  };
  const SUMTOTALTienCK = (_data) => {
    _data.TyLeCK = ((_data?.TienCK ?? 0) / (_data?.TienThe ?? 0)) * 100;
    _data.KhachTra = (_data?.TienThe ?? 0) - _data.TienCK;
    _data.ConNo = _data.KhachTra - (_data.ThanhToan ?? 0);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaKH":
        if (v?.length > 1) {
          _data[p] = v.splice(1);
        } else {
          _data[p] = v;
        }
        if (v?.length > 0) {
          let _res = await CustomerService.getByID({
            ID: _data[p][0].value,
          });
          _data.TenCongTy = _res?.data?.TenCongTy;
          _data.NgaySinh = new Date(_res?.data?.NgaySinh);
          _data.GioiTinh = _res?.data?.GioiTinh;
          _data.Email = _res?.data?.Email;
          _data.DiDong = _res?.data?.DiDong;
          _data.MaNKH = _res?.data?.MaNKH;
          _data.DiaChiCT = _res?.data?.DiaChiCT;
        } else {
          _data.TenCongTy = "";
          _data.NgaySinh = new Date();
          _data.GioiTinh = null;
          _data.Email = "";
          _data.DiDong = null;
          _data.MaNKH = null;
          _data.DiaChiCT = null;
        }
        break;
      case "MaSP":
        _data[p] = v;
        let sp = sanPham?.find((a) => a.ID === v);
        let spbg = sanphamBG.find((a) => a.ID === v);
        _data.MaGDV = sp?.MaGDV;
        _data.TienThe = spbg?.GiaBan ?? sp?.GiaBan;
        _data.SoLanQuet = sp?.SoLanQuet;
        _data.TenSP = sp?.TenSP;
        _data.VAT = sp?.ThueGTGT;
        _data.NgayHH = new Date(
          new Date(_data.NgayHL).setDate(
            new Date(_data.NgayHL).getDate() + (sp?.SoNgay ?? 0)
          )
        );
        SUMTOTAL(_data);
        //  _data.TienCK = (_data?.TienThe * _data?.TyLeCK) / 100;
        //  _data.KhachTra = _data?.TienThe - _data.TienCK;
        //  _data.ConNo = _data.KhachTra - (_data.ThanhToan ?? 0);
        break;
      case "NgayHL":
        _data[p] = v;
        let sp2 = sanPham?.find((a) => a.ID === _data.MaSP);
        _data.NgayHH = new Date(
          new Date(v).setDate(new Date(v).getDate() + (sp2?.SoNgay ?? 0))
        );
        break;
      case "TyLeCK":
        _data[p] = v;
        SUMTOTAL(_data);
        // _data.TienCK = (_data?.TienThe * v) / 100;
        // _data.KhachTra = _data?.TienThe - _data.TienCK;
        // _data.ConNo = _data.KhachTra - (_data.ThanhToan ?? 0);
        break;
      case "TienCK":
        _data[p] = v;
        SUMTOTALTienCK(_data);
        break;
      case "ThanhToan":
        _data[p] = v;
        SUMTOTAL(_data);
        break;
      case "MaBG":
        _data[p] = v;
        let _res = await SanPhamService.Categories.getSanPhamByMaBG({
          MaBG: v,
          InputSearch: "",
        });
        setSanphamBG(_res.data ?? []);
        _data.MaSP = null;
        _data.SoLanQuet = 0;

        _data.TienThe = 0;
        SUMTOTAL(_data);

        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  async function fetchUserList(username) {
    return CardService.filterCustomer({ InputSearch: username }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenCongTy} - ${item.DiDong} - ${new Date(
          item.NgaySinh
        ).getDate()}/${new Date(item.NgaySinh).getMonth() + 1}/${new Date(
          item.NgaySinh
        ).getFullYear()}`,
        value: item.MaKH,
        title: item.TenCongTy,
        DiDong: item.DiDong,
      }));
    });
  }
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      if (item === "TenCongTy" || item === "DiDong") {
        if (data?.MaKH?.length === 0 || data?.MaKH === undefined) {
          _errors[item] = data[item] ? null : "Không để trống";
        }
        //
      } else {
        _errors[item] = data[item] ? null : "Không để trống";
      }
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      return;
    }
    if (isupdate) {
      // const _payloadEdit = {
      //   MaKH: _data?.MaKH,
      //   TenCongTy: _data?.TenCongTy,
      //   NgaySinh: _data?.,
      //   DiDong: _data?.DiDong,
      //   Email: _data?.,
      //   DiaChiCT: _data?.,
      //   HinhAnh2: _data?.,
      //   MaNKH: _data?.,
      //   GioiTinh: _data?.,
      //   ID: _data?.,
      //   SoThe: _data?.,
      //   MaVach: _data?.,
      //   NgayPH: _data?.,
      //   NgayHL: _data?.,
      //   NgayHH: _data?.,

      //   MaLT: _data,
      //   DienGIai: _data,
      // };
      const _payloadEdit = {};
      let _resEdit = await BanHangService.BanVeThang.editVe(_payloadEdit);

      if (_resEdit.status === 2000) {
        openNotification("success", "topRight", `Sữa thẻ thành công!`);
        onClose();
        setIsUpdate(false);
        onAfterSubmit();
      } else {
        openNotification("error", "topRight", _resEdit?.message);
      }
    } else {
      if (_data.MaKH?.length > 0) {
        _data.MaKH = _data?.MaKH[0].value;
      }
      let _res = await CardService.addCard(_data);
      if (_res.status === 2000) {
        _data.MaKH = _res.makh;
        let _payload = {
          MaKH: _data.MaKH,
          ThanhTien: _data?.TienThe,
          TyeLeCK: _data?.TyLeCK,
          TienCK: _data?.TienCK,
          KhachTra: _data.KhachTra,
          ThanhToan: _data.ThanhToan,
          TienTru: 0,
          DiemTich: 0,
          HinhThucTT: _data.HinhThucTT,
          MaThe: _res.data,
          MaCaLamViec: calamviec.ID,
          MaQuay: calamviec?.MaQuay,
          MaNhom: 1,
          LoaiVeXuat: "CARD",
          // MaNguonKH: 0,
          SanPham: {
            MaSP: _data.MaSP,
            SoLuong: 1,
            DonGia: _data.TienThe,
            ThanhTien: _data.KhachTra,
            TyLeCK: _data?.TyLeCK,
            TienCK: _data?.TienCK,
            TenSP: _data.TenSP,
          },
          The: {
            MaVach: _data.MaVach,
            Type: "CARD",
            MaGoiDV: _data.MaGDV,
            MaSP: _data.MaSP,
            SoLanQuet: _data.SoLanQuet,
          },
        };
        let _resadd = await BanHangService.BanVeThang.add(_payload);
        if (_resadd.status === 2000) {
          taoHoaDonViettel(_data, _resadd?.maBH, _resadd.data);
        }
        openNotification("success", "topRight", `Thêm thẻ thành công!`);
        onClose();
        setIsUpdate(true);
        onAfterSubmit();
      } else {
        openNotification("error", "topRight", _res?.message);
      }
    }
  };

  console.log(data);

  const taoHoaDonViettel = async (_data, maBH, soHD) => {
    const GetHinhThuc = (ID) => {
      switch (ID) {
        case 2:
          return "CK";
        case 3:
          return "TM";
        case 4:
          return "TM/CK";
        default:
          return "TTV";
      }
    };
    let _tk = await CategoryService.Viettel.getTaiKhoan();
    let _mau = await CategoryService.Viettel.getMau();
    let _mauDefault = _mau.data?.find((v) => v.IsDefault);
    if (_tk?.data?.length === 0) return;
    if (!_mauDefault) return;
    let _login = await ViettelService.Login(_tk?.data[0]);
    let _generalInvoiceInfo = {
      invoiceType: _mauDefault.invoiceType, //"5",
      templateCode: _mauDefault.templateCode, //"5/0042",
      invoiceSeries: _mauDefault.invoiceSeries, //"C24MQT",
      currencyCode: _mauDefault.currencyCode, //"VND",
      adjustmentType: "1",
      paymentStatus: true,
      cusGetInvoiceRight: true,
      validation: 0,
    };
    let _buyerInfo = {
      buyerName: _data.TenCongTy,
      // buyerLegalName: "",
      // buyerTaxCode: "",
      buyerAddressLine: _data.DiaChiCT,
      buyerPhoneNumber: _data.DiDong,
    };

    let _payments = [
      {
        paymentMethodName: GetHinhThuc(_data.HinhThucTT),
      },
    ];
    let _itemInfo = [];
    let donGia = (_data.TienThe / ((_data.VAT + 100) / 100)).toFixed(0);
    let thanhTien = Number(donGia);
    let tongGomVAT = _data.TienThe;
    _itemInfo.push({
      lineNumber: 1,
      itemCode: _data.MaSP.toString(),
      itemName: _data.TenSP,
      unitName: "Vé tháng",
      unitPrice: Number(donGia),
      quantity: 1,
      selection: 1,
      itemTotalAmountWithoutTax: thanhTien, //item.ThanhTien,
      taxAmount: tongGomVAT - thanhTien,
      itemTotalAmountWithTax: thanhTien,
      itemTotalAmountAfterDiscount: thanhTien,
      taxPercentage: _data.VAT,
      discount: 0,
      itemDiscount: 0,
      TongGomVAT: tongGomVAT,
    });
    // let vat = _data.VAT;
    // let tienChuaThue = _.sumBy(_itemInfo, (v) => v.unitPrice * v.quantity);
    // let tienGomThue = _.sumBy(_itemInfo, "TongGomVAT");

    let _taxBreakdowns = [
      {
        taxPercentage: _data.VAT,
        taxableAmount: thanhTien,
        taxAmount: tongGomVAT - thanhTien,
      },
    ];

    let _summarizeInfo = {
      totalAmountAfterDiscount: thanhTien,
      totalAmountWithoutTax: thanhTien,
      totalTaxAmount: tongGomVAT - thanhTien,
      totalAmountWithTax: tongGomVAT,
      discountAmount: 0,
    };
    let _res = await ViettelService.addHoaDon(
      {
        generalInvoiceInfo: _generalInvoiceInfo,
        buyerInfo: _buyerInfo,
        payments: _payments,
        itemInfo: _itemInfo,
        taxBreakdowns: _taxBreakdowns,
        summarizeInfo: _summarizeInfo,
        token: _login.access_token,
      },
      _tk?.data[0]
    );
    await BanHangService.Viettel.addHoaDon({
      errorCode: _res?.errorCode,
      description: _res?.description,
      ..._res.result,
      itemInfo: _itemInfo,
      ..._buyerInfo,
      MaMau: _mauDefault?.ID,
      MaBH: maBH,
      paymentMethodName: GetHinhThuc(_data.HinhThucTT),
      vat: _data.VAT,
      State: _tk?.data[0].isKyHoaDon ? "SIGNED" : "DRAFT",
      SoHoaDonBH: soHD,
      QuayBan: _data.TenQuay,
      isVeThang: true,
    });
  };
  const renderBatDauCa = () => {
    const saveCaLamViec = async () => {
      if (!dataquay.MaQuay) {
        let _data = _.cloneDeep(dataquay);
        _data.Loi = "Vui lòng chọn quầy bán!";
        setDataquay(_data);
        return;
      }
      setLoading(true);
      let _res = await BanHangService.addCaLamViec(dataquay);
      if (_res.status === 2000) {
        setOpenCaLV(false);
        setCalamviec({
          ID: _res.data,
          TienDauCa: dataquay.TienDauCa,
          GioVaoCa: new Date(),
          MaQuay: dataquay.MaQuay,
        });
      }
      setLoading(false);
    };
    const appllyChange = (p, v) => {
      let _data = _.cloneDeep(dataquay);
      _data[p] = v;
      setDataquay(_data);
    };
    return (
      <Modal
        closeIcon={false}
        title="KHAI BÁO TIỀN ĐẦU CA"
        open={openCaLV}
        onOk={() => saveCaLamViec()}
        onCancel={() => {
          setOpenCaLV(false);
          setOpen(false);
        }}
        cancelText="Huỷ"
        okText="Lưu"
      >
        <div>
          <p style={{ marginTop: 10 }}>Quầy bán</p>
          <Select
            style={{
              width: "100%",
            }}
            // status="error"
            value={dataquay?.MaQuay}
            placeholder="Chọn quầy bán"
            onChange={(v) => appllyChange("MaQuay", v)}
            options={quayve}
            fieldNames={{ label: "TenQuay", value: "ID" }}
          />
          <p style={{ color: "red" }}>{dataquay?.Loi}</p>
          <p style={{ marginTop: 10 }}>Tiền đầu ca</p>
          <InputNumber
            suffix="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            placeholder="Nhập tiền đầu ca"
            style={{ width: "100%" }}
            onChange={(v) => appllyChange("TienDauCa", v)}
          />
        </div>
      </Modal>
    );
  };
  return (
    <Drawer
      title="THÊM THẺ"
      width={1120}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button onClick={() => onSubmit(true)} type="primary">
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
        1. Thông tin khách hàng
      </p>
      <Row gutter={[24, 8]}>
        {isupdate === false && (
          <Col span={24} className="gutter-row">
            <p>Khách hàng</p>
            <DebounceSelect
              mode="multiple"
              value={data?.MaKH}
              placeholder="Tìm kiểm khách hàng"
              fetchOptions={fetchUserList}
              onChange={(newValue) => applyChange("MaKH", newValue)}
              style={{
                width: "100%",
              }}
            />
          </Col>
        )}
        <Col span={8}>
          <p>
            Họ tên <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            value={data?.TenCongTy}
            status={data?.TenCongTy ? "" : "error"}
            placeholder="Nhập tên khách hàng"
            onChange={(e) => applyChange("TenCongTy", e.target.value)}
            disabled={data?.MaKH?.length > 0}
          />
          <p style={{ color: "red" }}>{errors?.TenCongTy}</p>
        </Col>
        <Col span={8}>
          <p>Ngày sinh</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            // defaultValue={dayjs(filterCondition.TuNgay)}
            value={data?.NgaySinh ? dayjs(data?.NgaySinh) : null}
            placeholder="Ngày sinh"
            // locale={vi}
            onChange={(v) => applyChange("NgaySinh", v)}
            disabled={data?.MaKH?.length > 0}
          />
        </Col>

        <Col span={8}>
          <p>Giới tính</p>
          <Checkbox
            checked={data?.GioiTinh === true}
            onChange={() => applyChange("GioiTinh", true)}
            disabled={data?.MaKH?.length > 0}
          >
            Nam
          </Checkbox>
          <Checkbox
            checked={data?.GioiTinh === false}
            onChange={() => applyChange("GioiTinh", false)}
            disabled={data?.MaKH?.length > 0}
          >
            Nữ
          </Checkbox>
        </Col>
        <Col span={8}>
          <p>Email</p>
          <Input
            value={data?.Email}
            placeholder="Email"
            onChange={(e) => applyChange("Email", e.target.value)}
            disabled={data?.MaKH?.length > 0}
          />
        </Col>
        <Col span={8}>
          <p>
            Di động <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            value={data?.DiDong}
            placeholder="Di động"
            status={data?.DiDong ? "" : "error"}
            onChange={(e) => applyChange("DiDong", e.target.value)}
            disabled={data?.MaKH?.length > 0}
          />
          <p style={{ color: "red" }}>{errors?.DiDong}</p>
        </Col>

        <Col span={8}>
          <p>Nhóm khách hàng</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaNKH}
            placeholder="Chọn nhóm khách hàng"
            onChange={(v) => applyChange("MaNKH", v)}
            options={customerGroup}
            fieldNames={{ label: "Name", value: "ID" }}
            disabled={data?.MaKH?.length > 0}
          />
        </Col>
        <Col span={24}>
          <p>Địa chỉ</p>
          <Input
            value={data?.DiaChiCT}
            placeholder="Địa chỉ"
            onChange={(e) => applyChange("DiaChiCT", e.target.value)}
            disabled={data?.MaKH?.length > 0}
          />
        </Col>
        <Col span={6}>
          {data.HinhAnh2 && (
            <Image src={data.HinhAnh2} />
            // <img alt="example" style={{ width: "100%" }} src={data.HinhAnh2} />
          )}
          {/* <FileUpload
            mode="basic"
            accept="image/*"
            maxFileSize={1000000}
            onSelect={(e) => onBeforeUpload(e)}
            chooseLabel="Tải ảnh"
          /> */}
        </Col>
      </Row>
      <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
        2. Thông tin thẻ
      </p>
      <Row gutter={[24, 8]}>
        {/* <Divider orientation="left" style={{ margin: 0, fontSize: 14 }}>
          Thông tin thẻ
        </Divider> */}
        <Col span={8}>
          <p>
            Mã vạch <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            status={data?.MaVach ? "" : "error"}
            value={data?.MaVach}
            placeholder="Mã vạch"
            onChange={(e) => applyChange("MaVach", e.target.value)}
          />
          <p style={{ color: "red" }}>{errors?.MaVach}</p>
        </Col>
        <Col span={8}>
          <p>Số thẻ</p>
          <Input
            value={data?.SoThe}
            placeholder="Số thẻ"
            onChange={(e) => applyChange("SoThe", e.target.value)}
          />
        </Col>
        <Col span={8}>
          <p>
            Loại thẻ <span style={{ color: "red" }}>*</span>
          </p>
          <Select
            style={{
              width: "100%",
            }}
            allowClear
            status={data?.MaLT ? "" : "error"}
            value={data?.MaLT}
            placeholder="Chọn loại thẻ"
            onChange={(v) => applyChange("MaLT", v)}
            options={cardType}
            fieldNames={{ label: "TenLT", value: "ID" }}
          />
          <p style={{ color: "red" }}>{errors?.MaLT}</p>
        </Col>

        <Col span={8}>
          <p>Ngày cấp</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            // value={data?.NgayPH}
            value={dayjs(data?.NgayPH)}
            placeholder="Ngày cấp"
            // locale={vi}
            onChange={(v) => applyChange("NgayPH", v)}
          />
        </Col>
        <Col span={8}>
          <p>Ngày hiệu lực</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            // defaultValue={dayjs(filterCondition.TuNgay)}
            value={dayjs(data?.NgayHL)}
            placeholder="Ngày hiệu lực"
            locale={vi}
            onChange={(v) => applyChange("NgayHL", v)}
          />
        </Col>
        <Col span={8}>
          <p>Ngày hết hạn</p>
          <DatePicker
            disabled={data?.MaSP ? true : false}
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.NgayHH)}
            placeholder="Ngày hết hạn"
            locale={vi}
            onChange={(v) => applyChange("NgayHH", v)}
          />
        </Col>
        <Col span={4}>
          <p>Mã bảng giá</p>
          <Select
            style={{ width: "100%" }}
            value={data?.MaBG}
            onChange={(v) => applyChange("MaBG", v)}
            options={banggia}
            fieldNames={{ label: "TenBangGia", value: "ID" }}
          />
        </Col>
        <Col span={4}>
          <p>Dịch vụ</p>
          <Select
            style={{
              width: "100%",
            }}
            allowClear
            // status={data?.MaLT ? "" : "error"}
            value={data?.MaSP}
            placeholder="Chọn sản phẩm"
            onChange={(v) => applyChange("MaSP", v)}
            options={sanPham}
            fieldNames={{ label: "TenSP", value: "ID" }}
          />
          <p style={{ color: "red" }}>{errors?.MaLT}</p>
        </Col>

        <Col span={4}>
          <p>Tổng tiền</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.TienThe}
            disabled
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={4}>
          <p>Số lượt</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.SoLanQuet}
            disabled
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={8}>
          <p>Ghi chú</p>
          <Input
            value={data?.DienGiai}
            placeholder="Ghi chú"
            onChange={(e) => applyChange("DienGiai", e.target.value)}
          />
        </Col>
      </Row>
      <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
        3. Thông thanh toán
      </p>
      <Row gutter={[20, 10]}>
        <Col span={4}>
          <p>Hình thức thanh toán</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.HinhThucTT}
            placeholder="Chọn hình thức thanh toán"
            onChange={(v) => applyChange("HinhThucTT", v)}
            options={hinhthuc}
            fieldNames={{ label: "TenHinhThuc", value: "ID" }}
          />
        </Col>
        <Col span={4}>
          <p>Chiết khấu(%)</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.TyLeCK}
            placeholder="Nhập tổng tiền"
            onChange={(v) => applyChange("TyLeCK", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={4}>
          <p>Tiền chiết khấu</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.TienCK}
            onChange={(v) => applyChange("TienCK", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={4}>
          <p>Tổng sau CK</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.KhachTra}
            disabled
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>

        <Col span={4}>
          <p>Khách trả</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.ThanhToan}
            placeholder="Nhập tiền khách trả"
            onChange={(v) => applyChange("ThanhToan", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={4}>
          <p>Còn nợ</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.ConNo}
            disabled
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
      </Row>
      {renderBatDauCa()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
