import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Table,
  Tooltip,
  notification,
  Row,
  Col,
  Tag,
  Input,
  Popconfirm,
  Flex,
  Checkbox,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CSVLink } from "react-csv";
import { IoEyeSharp } from "react-icons/io5";
import _ from "lodash";
// import "./scss/index.scss";
import Toolbar from "components/Toolbar";
import { BanHangService } from "services/BanHangService";
import Search from "antd/es/input/Search";
import { GiConfirmed } from "react-icons/gi";
import { CRMService } from "services/CRMService";
import { ViettelService } from "services/ViettelService";
import { CategoryService } from "services/CategoryService";
export default function Settings() {
  const state = {
    NEW: "Mới",
    PAYMENT: "Đã thanh toán",
    CANCEL: "Đã huỷ",
    REJECT: "Từ chối",
    ACCEPT: "Đã duyệt",
    DONE: "Đã xuất vé",
  };
  const stateColor = {
    NEW: "blue",
    PAYMENT: "cyan",
    CANCEL: "orange",
    REJECT: "red",
    ACCEPT: "green",
    DONE: "#1677ff",
  };
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    InputSearch: "",
    State: ",,",
  });
  const refDetail = useRef(null);
  const refSettingBlind = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await BanHangService.Viettel.getHoaDon(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const states = {
    DRAFT: "Chờ phát hành",
    SIGNED: "Đã phát hành",
    ERROR: "Phát hành lỗi",
  };
  const statesColor = {
    DRAFT: "cyan",
    SIGNED: "green",
    ERROR: "red",
  };
  const columns = [
    // {
    //   title: "Ngày tạo",
    //   dataIndex: "DateCreate",
    //   render: (item) =>
    //     new Date(item).getDate() +
    //     "-" +
    //     (new Date(item).getMonth() + 1) +
    //     "-" +
    //     new Date(item).getFullYear(),
    // },
    {
      title: "Trạng thái",
      dataIndex: "State",
      width: 150,
      render: (item) => <Tag color={statesColor[item]}>{states[item]}</Tag>,
    },
    {
      title: "Số hoá đơn",
      dataIndex: "SoHoaDonBH",
      width: 150,
    },
    {
      title: "Quầy bán",
      dataIndex: "QuayBan",
      width: 150,
    },
    {
      title: "Tên khách hàng",
      dataIndex: "buyerName",
      width: 150,
    },
    {
      title: "Di động",
      dataIndex: "buyerPhoneNumber",
      width: 150,
    },
    {
      title: "Thanh toán",
      dataIndex: "paymentMethodName",
      width: 100,
    },
    {
      title: "Tiền chưa VAT",
      dataIndex: "TongChuaVAT",
      render: (item) => (item ? Format_Currency(item) : ""),
      // render: (item) => Format_Currency(item.ThanhTien - item.TienVAT),
      width: 100,
    },
    {
      title: "Tiền VAT",
      // dataIndex: "TienVAT",
      render: (item) => Format_Currency(item.TongGomVAT - item.TongChuaVAT),
      width: 100,
    },
    {
      title: "Tổng gồm VAT",
      dataIndex: "TongGomVAT",
      render: (item) => Format_Currency(item),
      width: 100,
    },
    {
      title: "Vé tháng",
      dataIndex: "isVeThang",
      render: (item) => <Checkbox checked={item} />,
      width: 100,
    },
    {
      title: "InvoiceNo",
      dataIndex: "invoiceNo",
      width: 100,
    },
    {
      title: "CodeOfTax",
      dataIndex: "codeOfTax",
      width: 250,
    },
    {
      title: "TransactionID",
      dataIndex: "transactionID",
      width: 200,
    },

    {
      title: "Ngày nhập",
      dataIndex: "DateCreate",
      render: (item) => Format_Date(item),
      width: 100,
    },
    {
      title: "ErrorCode",
      dataIndex: "errorCode",
      width: 100,
    },

    {
      title: " ",
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex gap={5} justify="center">
            {item.State !== "SIGNED" && (
              <Tooltip placement="topRight" title="Phát hành hoá đơn">
                <Popconfirm
                  title="Phát hành"
                  description="Bạn có chắc chắn muốn phát hành hoá đơn hay không?"
                  okText="Đồng ý"
                  cancelText="Không"
                  onConfirm={() => onAccept(item)}
                >
                  <Button icon={<GiConfirmed color="green" />} />
                </Popconfirm>
              </Tooltip>
            )}

            <Tooltip placement="topRight" title="Chi tiết">
              <Button
                type="primary"
                ghost
                icon={<IoEyeSharp />}
                onClick={() => refDetail.current.show(item)}
              />
            </Tooltip>
          </Flex>
        );
      },
    },
  ];
  const taoHoaDonViettel = async (_data, _sanPham) => {
    let _tk = await CategoryService.Viettel.getTaiKhoan();
    let _mau = await CategoryService.Viettel.getMau();
    let _mauDefault = _mau.data?.find((v) => v.ID === _data.MaMau);
    if (_tk?.data?.length === 0) return;
    if (!_mauDefault) return;
    let _login = await ViettelService.Login(_tk?.data[0]);
    let _generalInvoiceInfo = {
      invoiceType: _mauDefault.invoiceType, //"5",
      templateCode: _mauDefault.templateCode, //"5/0042",
      invoiceSeries: _mauDefault.invoiceSeries, //"C24MQT",
      currencyCode: _mauDefault.currencyCode, //"VND",
      adjustmentType: "1",
      paymentStatus: true,
      cusGetInvoiceRight: true,
      validation: 0,
    };
    let _buyerInfo = {
      buyerName: _data.buyerName ?? "Khách lẻ",
      buyerAddressLine: _data.buyerAddressLine,
      buyerPhoneNumber: _data.buyerPhoneNumber,
    };
    let _payments = [
      {
        paymentMethodName: _data.paymentMethodName,
      },
    ];
    let _itemInfo = [];
    _sanPham.map((item, index) => {
      _itemInfo.push({
        lineNumber: index + 1,
        itemCode: item.itemCode,
        itemName: item.itemName,
        unitName: item.unitName,
        unitPrice: item.unitPrice,
        quantity: item.quantity,
        selection: 1,
        itemTotalAmountWithoutTax: item.itemTotalAmountWithoutTax, //thanhTien, //item.ThanhTien,
        taxAmount: item.taxAmount,
        itemTotalAmountWithTax: item.itemTotalAmountWithoutTax,
        itemTotalAmountAfterDiscount: item.itemTotalAmountWithoutTax,
        taxPercentage: item.taxPercentage,
        discount: 0,
        itemDiscount: 0,
        TongGomVAT: item.TongGomVAT,
      });
    });
    let tienChuaThue = _.sumBy(_itemInfo, (v) => v.unitPrice * v.quantity);
    let tienGomThue = _.sumBy(_itemInfo, "TongGomVAT");
    let tienThue = _.sumBy(_itemInfo, "taxAmount");
    let _taxBreakdowns = [
      {
        taxPercentage: _data.vat,
        taxableAmount: tienChuaThue,
        taxAmount: tienThue,
      },
    ];
    let _summarizeInfo = {
      totalAmountAfterDiscount: tienChuaThue,
      totalAmountWithoutTax: tienChuaThue,
      totalTaxAmount: tienThue,
      totalAmountWithTax: tienGomThue,
      discountAmount: 0,
    };
    let _res = await ViettelService.addHoaDon(
      {
        generalInvoiceInfo: _generalInvoiceInfo,
        buyerInfo: _buyerInfo,
        payments: _payments,
        itemInfo: _itemInfo,
        taxBreakdowns: _taxBreakdowns,
        summarizeInfo: _summarizeInfo,
        token: _login.access_token,
      },
      {
        Username: _tk?.data[0].Username,
        isKyHoaDon: true,
      }
    );
    if (_res?.errorCode) {
      openNotification("error", "topRight", _res?.description);
    } else {
      let res = await BanHangService.Viettel.updateHoaDon({
        ID: _data.ID,
        errorCode: _res?.errorCode,
        description: _res?.description,
        ..._res.result,
        State: _res?.errorCode ? "ERROR" : "SIGNED",
      });
      if (res.status === 2000) {
        filter(filterCondition);
        openNotification("success", "topRight", "Ký thành công");
      } else {
        openNotification(
          "error",
          "topRight",
          "Đẩy dữ liệu ký thành công. Lưu lịch sử lỗi ở BEEHOME!"
        );
      }
    }
  };
  const onAccept = async (value) => {
    let _res = await BanHangService.Viettel.getHoaDonDetail({
      MaHD: value.ID,
    });
    if (_res.data.length > 0) {
      taoHoaDonViettel(value, _res.data);
    }
    // let _res = await BanHangService.duyetVeBan({
    //   ID: value.ID,
    //   TongTien: value.ThanhTien,
    //   MaKH: value.MaKH,
    //   LyDoPT: "Duyệt vé online",
    // });
    // if (_res.status === 2000) {
    //   filter(filterCondition);
    //   CRMService.Zalo.Send({
    //     Phone: value?.DiDong,
    //     TenKH: value?.TenCongTy,
    //     KyHieuKH: value?.DiDong,
    //     MaVe: value?.SoBH,
    //     TempID: "5920",
    //   });
    //   openNotification("success", "topRight", "Duyệt thành công");
    // } else {
    //   openNotification("error", "topRight", _res?.message);
    // }
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        filter(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              placeholder="Tìm kiếm tên, điện thoại, InvoiceNo"
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          // right={() => (
          //   <Button type="primary">
          //     <CSVLink filename={"ticket-online.csv"} data={data}>
          //       Export
          //     </CSVLink>
          //   </Button>
          // )}
        />
        <Table
          style={{
            height: 100,
          }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            x: 1500,
          }}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={4}>
                    Tổng: {pageData.length}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TongChuaVAT")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        _.sum(
                          _.map(pageData, (v) => v.TongGomVAT - v.TongChuaVAT)
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TongGomVAT")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Row>
  );
}
