import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  Select,
  Checkbox,
  Table,
} from "antd";
import _ from "lodash";
import { SanPhamService } from "services/SanPhamService";
import { OtherService } from "services/OtherService";
import DebounceSelect from "components/DebounceSelect";
import { VisaService } from "services/VisaService";
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const FormAddHoSo = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [maSP, setMaSP] = useState(0);
  useImperativeHandle(ref, () => ({
    add: async (masp) => {
      setOpen(true);
      let _res = await VisaService.Categories.getTenLoaiHS();
      let _hstheosp = await VisaService.SanPham.getHoSoByMSP({
        MaSP: masp,
      });
      let selects = [];
      _hstheosp?.data?.map((item) => {
        selects.push(item.MaLHS);
      });
      setSelectedRowKeys(selects);
      setData(_res.data);
      setMaSP(masp);
    },
  }));
  const onClose = () => {
    setOpen(false);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const columns = [
    {
      title: "Tên loại hồ sơ",
      dataIndex: "TenLoaiHS",
    },
    {
      title: "Ghi chú",
      dataIndex: "GhiChu",
    },
  ];
  const onSelectChange = async (newSelectedRowKeys) => {
    // setLoading(true);
    if (selectedRowKeys.length > newSelectedRowKeys.length) {
      let id = _.difference(selectedRowKeys, newSelectedRowKeys);
      if (id?.length > 0) {
        await VisaService.SanPham.deleteHoSoByMSP({
          MaSP: maSP,
          Ids: id,
        });
      }
    } else {
      let id = _.difference(newSelectedRowKeys, selectedRowKeys);
      if (id?.length > 0) {
        await VisaService.SanPham.addHoSoByMSP({
          MaSP: maSP,
          Ids: id,
        });
      }
    }
    setSelectedRowKeys(newSelectedRowKeys);
    setLoading(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(data, result.source.index, result.destination.index);
    setData(items);
  };
  return (
    <Drawer
      title="Thêm hồ sơ cần cho sản phẩm"
      width={950}
      onClose={onClose}
      open={open}
    >
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 170,
          // x: 2500
        }}
        rowSelection={rowSelection}
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
        size="small"
        pagination={{
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: ["100", "500", "5000"],
        }}
      />
   
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAddHoSo);
