import React, {  useEffect } from "react";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import ChiNhanhSelect from "components/ChiNhanhSelect";

const FilterModal = ({ ...props }) => {
  const { lydo, filterCondition, onChangeFilter } = props;
  // const [quay, setQuay] = useState([]);
  // const [nhanvien, setNhanvien] = useState([]);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaLyDo":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      case "MaCT":
        _filter[p] = v ? v : 0;
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      <ChiNhanhSelect
        FormID={285}
        onChange={(v) => applyChangeFilter("MaCT", v)}
      />
      <div className="box-search">
        <p className="label">Nội dung tích thẻ</p>
        <Select
          mode="multiple"
          placeholder="Chọn nội dung tích thẻ"
          onChange={(v) => applyChangeFilter("MaLyDo", v)}
          style={{
            width: "100%",
          }}
          options={lydo}
          fieldNames={{ label: "LyDo", value: "ID" }}
        />
      </div>
      {/* <div className="box-search">
        <p className="label">Nhân viên</p>
        <Select
          mode="multiple"
          placeholder="Chọn nhân viên"
          onChange={(v) => applyChangeFilter("NhanVien", v)}
          style={{
            width: "100%",
          }}
          options={nhanvien}
          fieldNames={{ label: "HoTen", value: "MaNV" }}
        />
      </div> */}
    </div>
  );
};
export default FilterModal;
