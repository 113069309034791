import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Col, Drawer, Row, Table, Tabs, Tag } from "antd";
import _ from "lodash";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CardService } from "services/CardService";
import { OtherService } from "services/OtherService";
const { TabPane } = Tabs;
const FormDetail = (props, ref) => {
  const defaultData = {
    LichSuHoaDon: [],
    LichSuNap: [],
    HoaDonVeThang: [],
    ThongTinChung: [],
  };
  const [hinhthuc, setHinhthuc] = useState([]);
  const [dataTTC, seDataTTC] = useState([]);
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const loadCategories = async (item) => {
    let _ht = await OtherService.getHinhThucTT();
    setHinhthuc(_ht.data ?? []);
    let _res = await CardService.getCardHistoryByMaThe({
      ID: item?.ID,
    });
    let _res2 = await CardService.getBanHangByMaThe({
      ID: item?.ID,
    });
    let _resHD = await CardService.getHoaDonVeThang({
      MaThe: item?.ID,
    });
    // api/card/get-hoadonvethang-bymathe
    let _data = _.cloneDeep(data);
    _data.LichSuHoaDon = _res2.data ?? [];
    _data.LichSuNap = _res.data ?? [];
    _data.ThongTinChung = item ?? {};
    _data.HoaDonVeThang = _resHD?.data ?? [];
    setData(_data);
  };
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      loadCategories(item);
      seDataTTC(item);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
  };
  const state = {
    NEW: "Mới",
    PAYMENT: "Đã thanh toán",
    CANCEL: "Đã huỷ",
    REJECT: "Từ chối",
    ACCEPT: "Đã duyệt",
    RETURN: "Hoàn trả",
  };
  const stateColor = {
    NEW: "blue",
    PAYMENT: "cyan",
    CANCEL: "orange",
    REJECT: "red",
    ACCEPT: "green",
    RETURN: "#87d068",
  };
  const columns = [
    {
      title: "Ngày hoá đơn",
      dataIndex: "NgayBH",
      render: (item) => Format_Date(item),
    },
    {
      title: "Số hoá đơn",
      dataIndex: "SoBH",
    },
    {
      title: "Tổng tiền",
      dataIndex: "KhachTra",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Hình thức TT",
      dataIndex: "HinhThucTT",
      render: (item) => hinhthuc?.find((v) => v.ID === item)?.TenHinhThuc,
    },
    {
      title: "Trừ tiền thẻ",
      dataIndex: "TienTru",
      render: (item) => Format_Currency(item),
    },
  ];
  const columns2 = [
    {
      title: "Ngày nộp",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
    },
    {
      title: "Tiền nộp",
      dataIndex: "TienNap",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền cộng thêm",
      render: (item) =>
        Format_Currency((item.TienNap * (item.PTCongTienNap ?? 0)) / 100),
    },

    {
      title: "Tổng tiền nộp",
      render: (item) =>
        Format_Currency(
          item.TienNap + (item.TienNap * (item.PTCongTienNap ?? 0)) / 100
        ),
    },
  ];
  const columnsTCC = [
    {
      title: "Số thẻ",
      dataIndex: "SoThe",
    },
    {
      title: "Mã vạch",
      dataIndex: "MaVach",
    },
    {
      title: "Loại thẻ",
      dataIndex: "TenLT",
    },
    {
      title: "Trạng thái",
      dataIndex: "TenTT",
    },
    {
      title: "Ngày phát hàng",
      dataIndex: "NgayPH",
      render: (item) => Format_Date(item),
    },
    {
      title: "Ngày hiệu lực",
      dataIndex: "NgayHL",
      render: (item) => Format_Date(item),
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "NgayHH",
      render: (item) => Format_Date(item),
    },
    {
      title: "Điểm tích luỹ",
      dataIndex: "DiemThe",
    },
    {
      title: "Tổng tiền",
      dataIndex: "TienThe",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Số dư thẻ",
      dataIndex: "SoDuThe",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenCongTy",
      width: "200px",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      render: (item) => Format_Date(item),
    },
    {
      title: "CMND",
      dataIndex: "SoCMND",
    },
    {
      title: "Di động",
      dataIndex: "DiDong",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
    },
  ];
  const columnsHDVT = [
    {
      title: "Loại",
      render: (item) =>
        item ? (
          <Tag color={item?.IsGiaHan ? "red" : "blue"}>{item?.Loai}</Tag>
        ) : (
          ""
        ),
    },
    {
      title: "Ngày bán hàng",
      dataIndex: "NgayBH",
      render: (item) => Format_Date(item),
    },
    {
      title: "Ngày sử dụng",
      dataIndex: "NgayPH",
      render: (item) => Format_Date(item),
    },
    {
      title: "Số hoá đơn",
      dataIndex: "SoBH",
    },
    {
      title: "Số lượng",
      dataIndex: "SoLuongThe",
      render: (item) => item ?? 0,
    },
    {
      title: "Vé đã SD",

      render: (item) => (
        <p
        // onClick={() => refHistory.current.show(item)}
        // style={{ fontWeight: "bold", fontSize: 16, cursor: "pointer" }}
        >
          {item?.DaSuDung ?? 0}
        </p>
      ),
    },
    {
      title: "Vé còn lại",
      // dataIndex: "ConLai",
      render: (item) => item.SoLanQuet - (item.SoLanDaQuet ?? 0),
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
    },
    {
      title: "Quầy vé",
      dataIndex: "TenQuay",
    },
    {
      title: "Khách hàng",
      dataIndex: "TenCongTy",
    },
    {
      title: "Hình thức TT",
      dataIndex: "TenHinhThuc",
      // render: (item) => hinhthuc?.find((v) => v.ID === item)?.TenHinhThuc,
    },
    {
      title: "Tổng tiền",
      dataIndex: "TongTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền CK",
      dataIndex: "TienCK",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tổng sau CK",
      dataIndex: "KhachTra",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Đã thanh toán",
      dataIndex: "ThanhToan",
      render: (item) => Format_Currency(item ?? 0),
    },

    {
      title: "Còn nợ",
      fixed: "right",
      width: "80px",
      render: (item) => Format_Currency(item.KhachTra - (item.ThanhToan ?? 0)),
    },
    {
      title: "Trạng thái",
      fixed: "right",
      dataIndex: "State",
      width: "100px",
      render: (item) => <Tag color={stateColor[item]}>{state[item]}</Tag>,
    },
  ];
  const handleTabChange = async (key) => {
    let _data = _.cloneDeep(data);
    switch (key) {
      case "1":
        _data.ThongTinChung = dataTTC ?? {};

        break;
      case "2":
        let _res2 = await CardService.getBanHangByMaThe({
          ID: dataTTC?.ID,
        });
        _data.LichSuHoaDon = _res2.data ?? [];
        break;
      case "3":
        let _res = await CardService.getCardHistoryByMaThe({
          ID: dataTTC?.ID,
        });
        _data.LichSuNap = _res.data ?? [];
        break;
      case "4":
        let _resHD = await CardService.getHoaDonVeThang({
          MaThe: dataTTC?.ID,
        });
        _data.HoaDonVeThang = _resHD?.data ?? [];
        break;

      default:
        break;
    }

    setData(_data);
  };

  const ThongTinChung = () => {
    return (
      <>
        <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
          Thông tin chung
        </p>
        {/* <Table
          // loading={loading}
          dataSource={data.ThongTinChung}
          columns={columnsTCC}
          scroll={{
            y: window.innerHeight / 2,
            x: 2350,
          }}
          size="small"
         
        /> */}

        <Row gutter={[24, 5]}>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Mã thẻ</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.ThongTinChung?.MaVach}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Tên khách hàng</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.ThongTinChung?.TenCongTy}
            </p>
          </Col>

          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Loại thẻ</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.ThongTinChung?.TenLT}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Di động</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.ThongTinChung?.DiDong}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Điển thẻ</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.ThongTinChung?.DiemThe}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Số dư thẻ</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.ThongTinChung?.SoDuThe}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Ngày hiệu lực</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {Format_Date(data?.ThongTinChung?.NgayHL)}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Ngày hết hạn</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {Format_Date(data?.ThongTinChung?.NgayHH)}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Số ngày còn lại</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {(() => {
                const now = new Date();
                const ngayHH = new Date(data?.ThongTinChung?.NgayHH);
                const diffTime = Math.ceil(
                  (ngayHH - now) / (1000 * 60 * 60 * 24)
                );
                return diffTime < 0 ? "0 ngày" : `${diffTime} ngày `;
              })()}
            </p>
          </Col>
          <Col
            span={4}
            className="gutter-row"
            style={{ background: "#dadee3" }}
          >
            <p style={{ padding: "8px 0px" }}>Số lượt còn lại</p>
          </Col>
          <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {data?.ThongTinChung?.SoLuotConLai}
            </p>
          </Col>
        </Row>
      </>
    );
  };
  const LichSuHoaDon = () => {
    return (
      <>
        <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
          Lịch sử hoá đơn
        </p>
        <Table
          // loading={loading}
          dataSource={data.LichSuHoaDon}
          columns={columns}
          scroll={{
            y: window.innerHeight / 2,
          }}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData.length}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "KhachTra")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TienTru")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </>
    );
  };
  const LichSuNapSoDu = () => {
    return (
      <>
        <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
          Lịch sử nạp số dư
        </p>
        <Table
          // loading={loading}
          dataSource={data.LichSuNap}
          columns={columns2}
          scroll={{
            y: window.innerHeight / 2,
          }}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <p style={{ fontWeight: "bold" }}>
                      Tổng: {pageData.length}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TienNap")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        _.reduce(
                          pageData,
                          (sum, n) =>
                            sum + (n.TienNap * (n.PTCongTienNap ?? 0)) / 100,
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        _.reduce(
                          pageData,
                          (sum, n) =>
                            sum +
                            (n.TienNap +
                              (n.TienNap * (n.PTCongTienNap ?? 0)) / 100),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </>
    );
  };
  const HoaDonVeThang = () => {
    return (
      <>
        <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
          Hóa đơn vé tháng
        </p>
        <Table
          // loading={loading}
          dataSource={data.HoaDonVeThang}
          columns={columnsHDVT}
          scroll={{
            y: window.innerHeight / 2,
            x: 1900,
          }}
          size="small"
        />
      </>
    );
  };
  return (
    <Drawer
      title="Chi tiết thẻ"
      width={1120}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab="Thông tin chung" key="1">
          {ThongTinChung()}
        </TabPane>
        <TabPane tab="Lịch sử hóa đơn" key="2">
          {LichSuHoaDon()}
        </TabPane>
        <TabPane tab="Lịch sử nạp số dư" key="3">
          {LichSuNapSoDu()}
        </TabPane>
        <TabPane tab="Hóa đơn vé tháng" key="4">
          {HoaDonVeThang()}
        </TabPane>
      </Tabs>
    </Drawer>
  );
};
export default forwardRef(FormDetail);
