import React, { useState, useEffect, useRef } from "react";
import { Flex, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { KhoHangService } from "services/KhoHangService";
import ActiveRoute from "components/ActiveRoute";
import { CiCirclePlus, CiEdit } from "react-icons/ci";
import FormAdd from "./FormAdd";
import { SanPhamService } from "services/SanPhamService";

const FilterModal = ({ ...props }) => {
  const { companies, filterCondition, onChangeFilter } = props;
  const [bangGia, setBangGia] = useState([]);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "ListMaCT":
      case "KhoHang":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(() => {
    SanPhamService.Categories.getBangGia({}).then((res) =>
      setBangGia(res.data ?? [])
    );
  }, []);
  const refDetail = useRef(null);
  const onAfterSubmit = (remove) => {
    SanPhamService.Categories.getBangGia({}).then((res) =>
      setBangGia(res.data ?? [])
    );
    if(remove){
       let _filter = _.cloneDeep(filterCondition);
       _filter.MaBG = null;
       onChangeFilter(_filter);
    }
  };
  return (
    <div>
      <ActiveRoute />
      <div className="box-search">
        <Flex justify="space-between" style={{ marginBottom: 5 }}>
          <p className="label">Bảng giá</p>
          <CiCirclePlus
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() => refDetail.current.show()}
          />
        </Flex>
        <Flex align="center" gap={5}>
          <Select
            // mode="multiple"
            allowClear
            placeholder="Chọn bảng giá"
            onChange={(v) => applyChangeFilter("MaBG", v)}
            style={{
              width: "100%",
            }}
            value={filterCondition.MaBG}
            options={bangGia}
            fieldNames={{ label: "TenBangGia", value: "ID" }}
          />
          {filterCondition.MaBG && (
            <CiEdit
              size={25}
              style={{ cursor: "pointer" }}
              onClick={() =>
                refDetail.current.update(
                  bangGia?.find((v) => v.ID === filterCondition.MaBG)
                )
              }
            />
          )}
        </Flex>
      </div>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={(remove) => onAfterSubmit(remove)}
      />
    </div>
  );
};
export default FilterModal;
