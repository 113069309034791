import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  Input,
  InputNumber,
  Row,
  Col,
  Drawer,
  notification,
  Tabs,
  Select,
  Flex,
  Modal,
  Popconfirm,
  Tag,
  DatePicker,
} from "antd";
import _ from "lodash";
import DebounceSelect from "components/DebounceSelect";
import { FaTrashAlt } from "react-icons/fa";
import { SanPhamService } from "services/SanPhamService";
import TabPane from "antd/es/tabs/TabPane";
import { Format_Currency } from "layouts/ultis/common";
import { CustomerService } from "services/CustomerService";
import { BanHangService } from "services/BanHangService";
import { CardService } from "services/CardService";
import { OtherService } from "services/OtherService";
import HoaDonToPrint from "./HoaDonToPrint";
import KetCaPrint from "./KetCaPrint";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/vi_VN";
import FormAddKH from "../../CRM/KhachHang/FormAdd";
import { ViettelService } from "services/ViettelService";
import { CategoryService } from "services/CategoryService";
const { TextArea } = Input;

let exits = {};
function taoSoDuyNhatBangDate() {
  try {
    const timestamp = Date.now().toString();
    const value = timestamp.slice(-9);
    if (exits[value]) {
      return taoSoDuyNhatBangDate();
    } else {
      exits[value] = true;
      return value;
    }
  } catch {
    return taoSoDuyNhatBangDate();
  }
}
const FormAdd = (props, ref) => {
  const history = useHistory();
  const refSoLuong = useRef();
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [diemTien, setDiemTien] = useState({});
  const [hinhthuc, setHinhThuc] = useState([]);
  const [banggia, setBanggia] = useState([]);
  const [nguonKH, setNguonKH] = useState([]);
  const [khachhang, setKhachhang] = useState([]);
  const [cardType, setCardType] = useState({});
  const componentRef = useRef();
  const refAddKH = useRef(null);
  const ketcaRef = useRef();
  const defaultDataKH = {
    TenCongTy: "",
    DiDong: "",
    Email: "",
  };
  const [dataKH, setDataKH] = useState(defaultDataKH);
  const defaultTab = {
    SanPham: [],
    KhachHang: null,
    HinhThucTT: 3,
    The: [],
    LoaiVeXuat: "CARD",
    NgayBH: new Date(),
    TyLeCK: 0,
  };
  const defaulData = {
    ["0"]: defaultTab,
  };
  const [data, setData] = useState(defaulData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [goidichvu, setGoidichvu] = useState([]);
  const initialItems = [
    {
      title: "Hoá đơn",
      key: "0",
      closable: false,
    },
  ];
  const [loaisp, setLoaisp] = useState([]);
  const [tabs, setTabs] = useState(initialItems);
  const [activeTab, setActiveTab] = useState(initialItems[0].key);
  const newTabIndex = useRef(0);
  const [sanpham, setSanpham] = useState([]);
  const [quayve, setQuayve] = useState([]);
  const [dataquay, setDataquay] = useState({});
  const [calamviec, setCalamviec] = useState({});
  const [openCaLV, setOpenCaLV] = useState(false);
  const [openKTCa, setOpenKTCa] = useState(false);
  const [dhtheoca, setDhtheoca] = useState([]);
  const [selectsSP, setSelectsSP] = useState([]);
  const [mabanggia, setMabanggia] = useState(0);
  const loadcategories = async () => {
    let _ht = await OtherService.getHinhThucTT();
    setHinhThuc(_ht.data ?? []);
    
    let _gdv = await SanPhamService.filterGoiDV();
    _gdv.data?.unshift({ ID: 0, TenGoi: "Tất cả" });
    setGoidichvu(_gdv.data ?? []);
    let _kh = await CustomerService.getListAll();
    _kh.data?.map((item) => (item.Name = item.TenCongTy + " - " + item.DiDong));
    setKhachhang(_kh?.data);
  };

  const [dataPrint, setDataPrint] = useState({});
  useImperativeHandle(ref, () => ({
    show: async () => {
      let _data = _.cloneDeep(data);
      setOpen(true);
      let _qv = await BanHangService.getQuayBan();
    setQuayve(_qv.data ?? []);
    let _dataquay = _.cloneDeep(dataquay);
    _dataquay.MaQuay = _qv.isDefault;
    setDataquay(_dataquay);
      await loadcategories();
      let _kh = await CustomerService.getNguon();
      setNguonKH(_kh?.data ?? []);
      if (_kh.data?.length > 0) _data[activeTab].MaNguonKH = _kh.data[0].ID;
      let _check = await BanHangService.checkCaLamViec();
      if (!_check.data) {
        setOpenCaLV(true);
      } else {
        setCalamviec(_check.data);
      }
      let _bg = await SanPhamService.Categories.getBangGia({ ApDung: true });
      if (_bg.data?.length > 0) {
        setBanggia(_bg.data ?? []);
        let _day = new Date().getDay();
        let id = 0;
        if (_day === 0 || _day === 6) {
          id = _bg.data?.find((v) => v.isCuoiTuan === true)?.ID;
        } else {
          id = _bg.data?.find((v) => v.MacDinh === true)?.ID;
        }
        _data["0"].MaBG = id;
        setMabanggia(id);
        let _sptheoquay = await SanPhamService.getSanPhamTheoQuay({
          MaQuay: _check.data?.MaQuay,
        });
        let selects = _sptheoquay?.data?.map((item) => item.MaSP) ?? [];
        _sptheoquay?.data?.map((item) => {
          setSelectsSP((pr) => [...pr, item.MaSP]);
        });
        let _res = await SanPhamService.Categories.getSanPhamByMaBG({
          MaBG: id,
          InputSearch: "",
        });
        let sptheoquay =
          _res.data?.filter((item) => selects?.includes(item?.ID)) ?? [];
        setSanpham(sptheoquay);
        let _lsp = await SanPhamService.getLSP({ ApDung: true });
        let lsp = [{ MaLSP: 0, TenLSP: "Tất cả" }];
        _lsp.data?.map((item) => {
          if (sptheoquay?.some((v) => v.MaLSP === item.MaLSP)) lsp.push(item);
        });
        setLoaisp(lsp);
      }

      const veQR = _qv.data?.find((item) => item?.ID === _check.data?.MaQuay)?.LoaiVeXuat;
      _data[activeTab].LoaiVeXuat = veQR === "QR" ? "QR" : "CARD";
      setData(_data);
    },
  }));

  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    onAfterSubmit();
    setSelectsSP([]);
  };
  const SumTotal = (_data) => {
    _data[activeTab].ThanhTien = _data[activeTab]?.SanPham?.reduce(
      (pre, current) => {
        return (pre += current.ThanhTien);
      },
      0
    );
    _data[activeTab].TienCK =
      (_data[activeTab].ThanhTien * (_data[activeTab]?.TyLeCK ?? 0)) / 100;
    _data[activeTab].KhachTra =
      _data[activeTab].ThanhTien - _data[activeTab].TienCK;
    _data[activeTab].KhachDua =
      _data[activeTab].ThanhTien - _data[activeTab].TienCK;
    _data[activeTab].TienThua =
      (_data[activeTab].KhachDua ?? 0) - _data[activeTab].KhachTra;
    _data[activeTab].ThanhToan =
      (_data[activeTab].KhachDua ?? 0) > _data[activeTab].KhachTra
        ? _data[activeTab].KhachTra
        : _data[activeTab].KhachDua ?? 0;
  };
  const applyChange = async (p, v, MaSP) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "ChooseSP":
        _data[p] = [];
        if (!_data[activeTab]) _data[activeTab] = defaultTab;
        let sp = JSON.parse(v[0].title);
        let spds = _data[activeTab].SanPham?.find((v) => v.MaSP === sp.ID);
        if (spds) {
          spds.SoLuong++;
        } else {
          _data[activeTab].SanPham.push({
            MaSP: sp.ID,
            TenSP: sp.TenSP,
            DonGia: sp.GiaBan,
            SoLuong: 1,
            TyLeCK: 0,
            TienCK: 0,
            ThanhTien: sp.GiaBan,
            MaGDV: sp.MaGDV,
            SoLanQuet: sp.SoLanQuet,
            VAT: sp.ThueGTGT,
          });
        }
        SumTotal(_data);
        refSoLuong?.current?.focus();
        break;
      case "ChooseClickSP":
        _data[p] = [];
        if (!_data[activeTab]) _data[activeTab] = defaultTab;
        let spdsnew = _data[activeTab].SanPham?.find((f) => f.MaSP === v.ID);
        if (spdsnew) {
          spdsnew.SoLuong++;
          spdsnew.TienCK =
            (spdsnew?.SoLuong * spdsnew?.DonGia * spdsnew?.TyLeCK) / 100;

          spdsnew.ThanhTien =
            spdsnew.SoLuong * spdsnew.DonGia -
            (spdsnew?.SoLuong * spdsnew?.DonGia * spdsnew.TyLeCK) / 100;
        } else {
          _data[activeTab].SanPham.push({
            MaSP: v.ID,
            TenSP: v.TenSP,
            DonGia: v.GiaBan,
            SoLuong: 1,
            TyLeCK: 0,
            TienCK: 0,
            ThanhTien: v.GiaBan,
            MaGDV: v.MaGDV,
            SoLanQuet: v.SoLanQuet,
            VAT: v.ThueGTGT,
          });
        }
        SumTotal(_data);
        break;
      case "KhachHang":
        if (v) {
          _data[activeTab][p] = v;
          _data[activeTab].MaKH = v;
          let kh = khachhang?.find((a) => a.MaKH === v);
          _data[activeTab].TenKH = kh.TenCongTy;
          _data[activeTab].DiDong = kh.DiDong;
          _data[activeTab].DiaChi = kh.DiaChiCT;
          let _the = await CardService.getByMaKH({ MaKH: v });
          let _res = await CardService.getDiem({
            MaThe: _the?.data?.ID,
          });
          let _resTypeCardDetail = await CardService.getLoaiTheDetail({
            ID: _the?.data?.MaLT,
          });
          setDiemTien(_res.data ?? {});
          setCardType(_resTypeCardDetail.data ?? {});
          _data[activeTab].MaThe = _the?.data?.ID;
        } else {
          _data[activeTab][p] = null;
          setCardType({});
          setDiemTien({});
        }
        break;
      case "SoLuong":
        let sl = _data[activeTab].SanPham?.find((v) => v.MaSP === MaSP);
        sl[p] = v;
        sl.TienCK = (sl?.SoLuong * sl?.DonGia * sl?.TyLeCK) / 100;
        sl.ThanhTien =
          sl.SoLuong * sl.DonGia - (sl?.SoLuong * sl?.DonGia * sl.TyLeCK) / 100;
        SumTotal(_data);
        break;
      case "TyLeCK":
        let item = _data[activeTab].SanPham?.find((v) => v.MaSP === MaSP);
        item[p] = v;
        item.TienCK = (item?.SoLuong * item?.DonGia * v) / 100;
        item.ThanhTien =
          item.SoLuong * item.DonGia -
          (item?.SoLuong * item?.DonGia * item.TyLeCK) / 100;
        SumTotal(_data);
        break;
      case "Remove":
        _data[activeTab].SanPham?.splice(v, 1);
        SumTotal(_data);
        break;
      default:
        _data[activeTab][p] = v;
        break;
    }
    setData(_data);
  };
  const applyChangeTotal = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaBG":
        _data[activeTab][p] = v;
        let _res = await SanPhamService.Categories.getSanPhamByMaBG({
          MaBG: v,
          InputSearch: "",
        });
        setSanpham(
          _res.data?.filter((item) => selectsSP?.includes(item?.ID)) ?? []
        );
        setMabanggia(v);
        break;
      case "NgayBH":
        _data[activeTab][p] = v.format("YYYY-MM-DD hh:mm");
        break;
      default:
        _data[activeTab][p] = v;
        SumTotal(_data);
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const taoHoaDonViettel = async (_data, maBH, soHD) => {
    const GetHinhThuc = (ID) => {
      switch (ID) {
        case 2:
          return "CK";
        case 3:
          return "TM";
        case 4:
          return "TM/CK";
        default:
          return "TTV";
      }
    };
    let _tk = await CategoryService.Viettel.getTaiKhoan();
    let _mau = await CategoryService.Viettel.getMau();
    let _mauDefault = _mau.data?.find((v) => v.IsDefault);
    if (_tk?.data?.length === 0) return;
    if (!_mauDefault) return;
    let _login = await ViettelService.Login(_tk?.data[0]);
    let _generalInvoiceInfo = {
      invoiceType: _mauDefault.invoiceType, //"5",
      templateCode: _mauDefault.templateCode, //"5/0042",
      invoiceSeries: _mauDefault.invoiceSeries, //"C24MQT",
      currencyCode: _mauDefault.currencyCode, //"VND",
      adjustmentType: "1",
      paymentStatus: true,
      cusGetInvoiceRight: true,
      validation: 0,
    };
    let _buyerInfo = {
      buyerName: _data.TenKH ?? "Khách lẻ",
      // buyerLegalName: "",
      // buyerTaxCode: "",
      buyerAddressLine: _data.DiaChi,
      buyerPhoneNumber: _data.DiDong,
    };

    let _payments = [
      {
        paymentMethodName: GetHinhThuc(_data.HinhThucTT),
      },
    ];
    let _itemInfo = [];
    _data.SanPham.map((item, index) => {
      let donGia = (item.DonGia / ((item.VAT + 100) / 100)).toFixed(0);
      let thanhTien = Number(donGia) * item.SoLuong;
      let tongGomVAT = item.SoLuong * item.DonGia;
      _itemInfo.push({
        lineNumber: index + 1,
        itemCode: item.MaSP.toString(),
        itemName: item.TenSP,
        unitName: "Vé",
        unitPrice: Number(donGia),
        quantity: item.SoLuong,
        selection: 1,
        itemTotalAmountWithoutTax: thanhTien, //item.ThanhTien,
        taxAmount: tongGomVAT - thanhTien,
        itemTotalAmountWithTax: thanhTien,
        itemTotalAmountAfterDiscount: thanhTien,
        taxPercentage: item.VAT,
        discount: 0,
        itemDiscount: 0,
        TongGomVAT: tongGomVAT,
      });
    });
    let vat = _data.SanPham[0].VAT;
    let tienChuaThue = _.sumBy(_itemInfo, (v) => v.unitPrice * v.quantity);
    let tienGomThue = _.sumBy(_itemInfo, "TongGomVAT");

    let _taxBreakdowns = [
      {
        taxPercentage: vat,
        taxableAmount: tienChuaThue,
        taxAmount: tienGomThue - tienChuaThue,
      },
    ];

    let _summarizeInfo = {
      totalAmountAfterDiscount: tienChuaThue,
      totalAmountWithoutTax: tienChuaThue,
      totalTaxAmount: tienGomThue - tienChuaThue,
      totalAmountWithTax: tienGomThue,
      discountAmount: 0,
    };
    let _res = await ViettelService.addHoaDon(
      {
        generalInvoiceInfo: _generalInvoiceInfo,
        buyerInfo: _buyerInfo,
        payments: _payments,
        itemInfo: _itemInfo,
        taxBreakdowns: _taxBreakdowns,
        summarizeInfo: _summarizeInfo,
        token: _login.access_token,
      },
      _tk?.data[0]
    );
    await BanHangService.Viettel.addHoaDon({
      errorCode: _res?.errorCode,
      description: _res?.description,
      ..._res.result,
      itemInfo: _itemInfo,
      ..._buyerInfo,
      MaMau: 1,
      MaBH: maBH,
      paymentMethodName: GetHinhThuc(_data.HinhThucTT),
      vat: vat,
      State: _tk?.data[0].isKyHoaDon ? "SIGNED" : "DRAFT",
      SoHoaDonBH: soHD,
      QuayBan: _data.TenQuay,
      isVeThang: false,
    });
  };
  const onSubmit = async () => {
    // console.log(data);
    // return

    setLoading(true);
    let _dataAll = _.cloneDeep(data);
    let _data = _dataAll[activeTab];
    let totalVe = _.sumBy(_data?.SanPham, "SoLuong");
    if (totalVe !== _data.The?.length && _data.LoaiVeXuat === "CARD") {
      openNotification(
        "error",
        "topRight",
        `Nhập đủ số lượng thẻ. Tổng: ${totalVe}`
      );
      setLoading(false);
      return;
    }
    if (_data.TienThua < 0 && !_data.MaKH) {
      openNotification("error", "topRight", `Vui lòng chọn khách hàng`);
      setLoading(false);
      return;
    }
    if (cardType?.TyGiaDiem) {
      _data.DiemTich = _data.KhachTra / cardType?.TyGiaDiem;
    }
    _data.MaCaLamViec = calamviec?.ID;
    _data.MaQuayBan = calamviec?.MaQuay;
    _data.TenQuayVT = quayve.find((v) => v.ID === calamviec?.MaQuay)?.MaQuay;
    _data.TenQuay = quayve.find((v) => v.ID === calamviec?.MaQuay)?.TenQuay;
    _data.MaNhom = 1;
    let _res = await BanHangService.addBanHangThe(_data);
    setLoading(false);
    if (_res.status === 2000) {
      taoHoaDonViettel(_data, _res?.maBH, _res.data);
      setDataPrint({
        SoHD: _res.data,
        TenQuay: _data.TenQuay,
        HinhThucTT: hinhthuc.find((v) => v.ID === _data.HinhThucTT)
          ?.TenHinhThuc,
      });
      handlePrint();
      openNotification("success", "topRight", `Thanh toán thành công!`);
      _dataAll[activeTab] = {
        ...defaultTab,
        MaBG: mabanggia,
        LoaiVeXuat: _data.LoaiVeXuat,
        MaNguonKH: _data.MaNguonKH,
      };
      setData(_dataAll);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  async function fetchSanPham(username) {
    return SanPhamService.Categories.getSanPhamByMaBG({
      InputSearch: username,
      MaBG: data[activeTab]?.MaBG,
    }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenSP}`,
        value: item.ID,
        title: JSON.stringify(item),
      }));
    });
  }
  const [mavach, setMavach] = useState("");
  const addThe = (v) => {
    setMavach(v);
  };
  useEffect(() => {
    let time = null;
    if (mavach !== "") {
      let _data = _.cloneDeep(data);
      if (
        _.sumBy(_data[activeTab]?.SanPham, "SoLuong") ===
        _data[activeTab]?.The?.length
      ) {
        return;
      }
      time = setTimeout(() => {
        let _lstsp = _data[activeTab]?.SanPham;
        let magdv = 0;
        let solanquet = 0;
        let masp = 0;
        let dem = 0;
        if (!_data[activeTab]) _data[activeTab] = defaultTab;
        let lstthe = _data[activeTab].The;
        for (let index = 0; index < _lstsp?.length; index++) {
          dem = dem + _lstsp[index].SoLuong;
          if (lstthe.length < dem) {
            magdv = _lstsp[index].MaGDV;
            masp = _lstsp[index].MaSP;
            solanquet = _lstsp[index].SoLanQuet ?? 1;
            break;
          }
        }
        let the = _data[activeTab].The?.find((f) => f.MaVach === mavach);
        if (!the) {
          _data[activeTab].The.unshift({
            MaVach: mavach,
            Type: "CARD",
            MaGoiDV: magdv,
            SoLanQuet: solanquet,
            MaSP: masp,
          });
        }
        setData(_data);
        setMavach("");
      }, 500);
    }
    return () => clearTimeout(time);
  }, [mavach]);
  const removeThe = (i) => {
    let _data = _.cloneDeep(data);
    _data[activeTab].The?.splice(i, 1);
    setData(_data);
  };

  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
  };
  const add = async () => {
    const newActiveKey = `${(newTabIndex.current += 1)}`;
    const newPanes = [...tabs];
    newPanes.push({
      title: "Hoá đơn",
      key: newActiveKey,
    });
    let _data = _.cloneDeep(data);
    _data[newActiveKey] = {
      ...defaultTab,
      MaNguonKH: nguonKH[0].ID,
      MaBG: mabanggia,
    };
    setData(_data);
    setTabs(newPanes);
    setActiveTab(newActiveKey);
  };
  const remove = (targetKey) => {
    let newActiveKey = activeTab;
    let lastIndex = -1;
    tabs.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = tabs.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    let _data = _.cloneDeep(data);
    delete _data[targetKey];
    setData(_data);
    setTabs(newPanes);
    setActiveTab(newActiveKey);
  };
  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePrintKetCa = useReactToPrint({
    content: () => ketcaRef.current,
  });
  const renderBatDauCa = () => {
    const saveCaLamViec = async () => {
      if (!dataquay.MaQuay) {
        let _data = _.cloneDeep(dataquay);
        _data.Loi = "Vui lòng chọn quầy bán!";
        setDataquay(_data);
        return;
      }
      setLoading(true);
      let _res = await BanHangService.addCaLamViec(dataquay);
      if (_res.status === 2000) {
        setOpenCaLV(false);
        setCalamviec({
          ID: _res.data,
          TienDauCa: dataquay.TienDauCa,
          GioVaoCa: new Date(),
          MaQuay: dataquay.MaQuay,
        });
      }
      setLoading(false);
    };
    const appllyChange = (p, v) => {
      let _data = _.cloneDeep(dataquay);
      _data[p] = v;
      setDataquay(_data);
    };
    return (
      <Modal
        closeIcon={false}
        title="KHAI BÁO TIỀN ĐẦU CA"
        open={openCaLV}
        onOk={() => saveCaLamViec()}
        onCancel={() => {
          setOpenCaLV(false);
          setOpen(false);
        }}
        cancelText="Huỷ"
        okText="Lưu"
      >
        <div>
          <p style={{ marginTop: 10 }}>Quầy bán</p>
          <Select
            style={{
              width: "100%",
            }}
            // status="error"
            value={dataquay?.MaQuay}
            placeholder="Chọn quầy bán"
            onChange={(v) => appllyChange("MaQuay", v)}
            options={quayve}
            fieldNames={{ label: "TenQuay", value: "ID" }}
          />
          <p style={{ color: "red" }}>{dataquay?.Loi}</p>
          <p style={{ marginTop: 10 }}>Tiền đầu ca</p>
          <InputNumber
            suffix="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            placeholder="Nhập tiền đầu ca"
            style={{ width: "100%" }}
            onChange={(v) => appllyChange("TienDauCa", v)}
          />
        </div>
      </Modal>
    );
  };

  const renderKetThucCa = () => {
    const logout = async () => {
      localStorage.removeItem("token");
      localStorage.removeItem("url");
      history.push("/auth/sign-in");
    };
    const saveKetThucCa = async () => {
      setLoading(true);
      let _res = await BanHangService.addKetThucCa({
        ID: calamviec?.ID,
      });
      if (_res.status === 2000) {
        OtherService.addLichSuThaoTac({
          Modules: "BanVe",
          SoGiaoDich: "",
          NoiDung: "Kết thúc ca",
          LinkID: 0,
        });
        handlePrintKetCa();
        setOpenKTCa(false);
        setOpen(false);
        logout();
      }
      setLoading(false);
    };
    return (
      <Modal
        closeIcon={false}
        width={900}
        title="KHAI BÁO TIỀN KẾT THÚC CA"
        open={openKTCa}
        onOk={() => saveKetThucCa()}
        onCancel={() => {
          setOpenKTCa(false);
        }}
        cancelText="Huỷ"
        okText="Kết ca"
      >
        <Row gutter={[20]}>
          <Col span={12}>
            <p style={{ marginTop: 10, color: "red" }}>Tiền đầu ca (1)</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={calamviec?.TienDauCa}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Tổng hoá đơn</p>
            <InputNumber
              disabled
              value={dhtheoca?.length}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Tiền hàng</p>
            <InputNumber
              suffix="vnđ"
              disabled
              value={dhtheoca?.reduce((pre, cur) => (pre += cur.ThanhTien), 0)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Chiết khấu</p>
            <InputNumber
              suffix="vnđ"
              disabled
              value={dhtheoca?.reduce((pre, cur) => (pre += cur.TienCK), 0)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Doanh thu</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={dhtheoca?.reduce((pre, cur) => (pre += cur.KhachTra), 0)}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10, color: "red" }}>Tiền mặt (2)</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={dhtheoca
                ?.filter((v) => v.HinhThucTT === 3)
                ?.reduce((pre, cur) => (pre += cur.KhachTra), 0)}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Ngân hàng</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={dhtheoca
                ?.filter((v) => v.HinhThucTT === 2)
                ?.reduce((pre, cur) => (pre += cur.KhachTra), 0)}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10, color: "red" }}>
              Tổng tiền mặt cuối ca = (1)+(2)
            </p>
            <InputNumber
              disabled
              suffix="vnđ"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={
                (dhtheoca
                  ?.filter((v) => v.HinhThucTT === 3)
                  ?.reduce((pre, cur) => (pre += cur.KhachTra), 0) ?? 0) +
                calamviec?.TienDauCa
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  };
  const handleKetThucCa = async () => {
    let _res = await BanHangService.getDonHangTheoCa({ MaCa: calamviec?.ID });
    setDhtheoca(_res.data ?? []);
    setOpenKTCa(true);
  };
  const [openPay, setOpenPay] = useState(false);
  const renderModalConfirmPay = () => {
    const hideModal = () => {
      setOpenPay(false);
      let _dataAll = _.cloneDeep(data);
      let _data = _dataAll[activeTab];
      if (_data.LoaiVeXuat === "QR") {
        _data.The = [];
        setData(_dataAll);
      }
    };
    return (
      <Modal
        title="Xác nhận"
        open={openPay}
        onOk={() => {
          onSubmit();
          setOpenPay(false);
        }}
        onCancel={hideModal}
        okText="Đồng ý"
        cancelText="Huỷ"
      >
        <p>Bạn có chắc chắn thanh toán hoá đơn</p>
      </Modal>
    );
  };

  const onPayConfirm = () => {
    let _dataAll = _.cloneDeep(data);
    let _data = _dataAll[activeTab];
    if (_data.LoaiVeXuat === "QR") {
      let the = [];
      _data?.SanPham?.map((item) => {
        item.QRCodes = [];
        for (let index = 0; index < item.SoLuong; index++) {
          let qr = taoSoDuyNhatBangDate();
          item.QRCodes.push(qr);
          the.push({
            Type: "QR",
            MaVach: qr,
            MaGoiDV: item.MaGDV,
            MaSP: item.MaSP,
            SoLanQuet: item.SoLanQuet ?? 1,
          });
        }
      });
      _data.The = the;
    }
    setData(_dataAll);
    setOpenPay(true);
  };
  const onAfterSubmitKH = async (value) => {
    let _data = _.cloneDeep(data);
    _data[activeTab].KhachHang = value?.MaKH;
    _data[activeTab].MaKH = value.MaKH;
    _data[activeTab].TenKH = value.TenCongTy;
    console.log(_data);
    console.log(value);
    setData(_data);
    let _kh = await CustomerService.getListAll();
    _kh.data?.map((item) => (item.Name = item.TenCongTy + " - " + item.DiDong));
    setKhachhang(_kh?.data);
  };
  const [activeKeyGoi, setActiveKeyGoi] = useState("0");
  const filterOption = (input, option) => {
    // console.log(option);
    return (option?.Name ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const renderHoaDon = (tab) => {
    return (
      <Row gutter={[10, 20]} style={{ height: "100%" }}>
        <Col span={18} style={{ height: "100%" }}>
          <Flex
            gap="middle"
            vertical
            justify="space-between"
            style={{ height: "100%" }}
          >
            <div style={{ height: "400px", overflow: "scroll" }}>
              {(!data[tab] || data[tab]?.SanPham?.length === 0) && (
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "10%",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Vui lòng chọn sản phẩm!
                </p>
              )}
              {data[tab]?.SanPham?.map((item, i) => {
                return (
                  <Row
                    style={{
                      border: "1px solid #d9d9d9",
                      padding: 5,
                      borderRadius: 5,
                      marginBottom: 5,
                    }}
                    align="middle"
                    key={item.ID}
                  >
                    <Col span={1}>{i + 1}</Col>
                    <Col span={1}>
                      <div
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => applyChange("Remove", i)}
                      >
                        <FaTrashAlt />
                      </div>
                    </Col>
                    <Col span={10}>
                      {item.TenSP} - {Format_Currency(item.DonGia)}đ
                    </Col>
                    <Col span={3}>
                      <InputNumber
                        value={item.SoLuong}
                        suffix="SL"
                        onChange={(v) => applyChange("SoLuong", v, item.MaSP)}
                      />
                    </Col>
                    <Col span={3}>
                      <InputNumber
                        suffix="% CK"
                        value={item.TyLeCK}
                        onChange={(v) => applyChange("TyLeCK", v, item.MaSP)}
                      />
                    </Col>
                    <Col span={3}> {Format_Currency(item.TienCK)}</Col>
                    <Col span={3}>{Format_Currency(item.ThanhTien)}</Col>
                  </Row>
                );
              })}
            </div>
            <Tabs
              style={{
                height: "400px",
                paddingTop: "5px",
                overflow: "scroll",
              }}
              // onEdit={onEdit}
              // type="editable-card"
              onChange={setActiveKeyGoi}
              activeKey={activeKeyGoi}
            >
              {loaisp?.map((item) => {
                return (
                  <TabPane tab={item.TenLSP} key={item.MaLSP}>
                    <Flex
                      gap="middle"
                      justify="flex-start"
                      style={{
                        height: "400px",
                        borderTop: "1px solid rgb(217, 217, 217)",
                        paddingTop: "5px",
                        overflow: "scroll",
                      }}
                      wrap="wrap"
                    >
                      {sanpham
                        .filter(
                          (v) => v.MaLSP === item.MaLSP || item.MaLSP === 0
                        )
                        .map((item) => {
                          return (
                            <div
                              style={{
                                width: "150px",
                                height: "140px",
                                border: "1px solid rgb(217, 217, 217)",
                                cursor: "pointer",
                              }}
                              onClick={() => applyChange("ChooseClickSP", item)}
                            >
                              {item?.LinkImage ? (
                                <img
                                  style={{ height: "70px", width: "100%" }}
                                  src={item.LinkImage}
                                />
                              ) : (
                                <img
                                  style={{ height: "70px", width: "100%" }}
                                  src={require("../../../../assets/img/ticket.jpeg")}
                                />
                              )}

                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                  padding: "5px",
                                }}
                              >
                                {item.TenSP}
                              </p>
                              <Tag bordered={false} color="green">
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  {Format_Currency(item.GiaBan)} đ
                                </p>
                              </Tag>
                            </div>
                          );
                        })}
                    </Flex>
                  </TabPane>
                );
              })}
            </Tabs>
          </Flex>
        </Col>
        <Col
          span={6}
          style={{
            borderLeft: "1px solid rgb(217, 217, 217)",
            // height: "100%",
          }}
        >
          <Flex vertical justify="space-between" style={{ height: "100%" }}>
            <div style={{ width: "100%" }} className="top-right">
              <Flex gap={5}>
                <Select
                  showSearch
                  placeholder="Tìm kiếm khách hàng"
                  value={data[activeTab]?.KhachHang}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  style={{
                    width: "100%",
                  }}
                  fieldNames={{ label: "Name", value: "MaKH" }}
                  onChange={(newValue) => applyChange("KhachHang", newValue)}
                  options={khachhang}
                />
                {/* <DebounceSelect
                  mode="multiple"
                  value={data[activeTab]?.KhachHang}
                  placeholder="Tìm kiểm khách hàng"
                  fetchOptions={fetchUserList}
                  onChange={(newValue) => applyChange("KhachHang", newValue)}
                  style={{
                    width: "100%",
                  }}
                /> */}
                <Button
                  loading={loading}
                  // type="primary"
                  // disabled={data[tab]?.SanPham?.length > 0 ? false : true}
                  onClick={() => refAddKH.current.show()}
                >
                  +
                </Button>
              </Flex>
              <Flex gap={20} style={{ margin: "6px 0px" }}>
                <DatePicker
                  defaultValue={dayjs(new Date())}
                  // minDate={"10-04-2024"}
                  disabledDate={(d) =>
                    !d ||
                    d.isBefore(new Date().setDate(new Date().getDate() - 1))
                  }
                  style={{ width: "50%" }}
                  allowClear={false}
                  format="DD-MM-YYYY"
                  locale={locale}
                  value={data[tab]?.NgayBH ? dayjs(data[tab]?.NgayBH) : null}
                  placeholder="Chọn ngày SD"
                  onChange={(v) => applyChangeTotal("NgayBH", v)}
                />
                <Select
                  style={{
                    width: "50%",
                  }}
                  placeholder="Chọn nguồn khách"
                  value={data[tab]?.MaNguonKH}
                  onChange={(v) => applyChangeTotal("MaNguonKH", v)}
                  options={nguonKH}
                  fieldNames={{ label: "Name", value: "ID" }}
                />
              </Flex>
              <p
                style={{ fontWeight: "bold", fontSize: 16, padding: "5px 0px" }}
              >
                Điểm thẻ: {Format_Currency(diemTien?.diemThe)} - Tiền thẻ:{" "}
                {Format_Currency(diemTien?.tienThe)}
              </p>
              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 8 }}
              >
                <p style={{ fontSize: 16 }}>Ghi chú</p>
                <TextArea
                  style={{ width: "50%" }}
                  value={data[tab]?.GhiChu}
                  onChange={(v) => applyChangeTotal("GhiChu", v.target.value)}
                />
              </Flex>
              <Flex justify="space-between" style={{ marginBottom: 8 }}>
                <p style={{ fontSize: 16 }}>Tổng chưa CK</p>
                <p style={{ fontSize: 16 }}>
                  {Format_Currency(data[tab]?.ThanhTien)}
                </p>
              </Flex>
              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 8 }}
              >
                <p style={{ fontSize: 16 }}>Chiết khấu (%)</p>
                <Flex
                  style={{ width: "50%" }}
                  justify="space-between"
                  align="center"
                >
                  <InputNumber
                    max={100}
                    style={{ width: "35%" }}
                    value={data[tab]?.TyLeCK}
                    onChange={(v) => applyChangeTotal("TyLeCK", v)}
                  />
                  <p style={{ fontSize: 16 }}>
                    {Format_Currency(data[tab]?.TienCK)}
                  </p>
                </Flex>
              </Flex>
              {cardType.DiemTien && (
                <>
                  <Col span={12}>
                    <InputNumber
                      suffix="điểm"
                      max={diemTien?.diemThe}
                      placeholder="Trừ điểm"
                      style={{ width: "80%" }}
                      value={data[tab]?.DiemTru}
                      onChange={(v) => applyChangeTotal("DiemTru", v)}
                    />
                  </Col>
                  <Col span={12}>
                    {Format_Currency(data[tab]?.DiemTru * cardType.DiemTien)}
                  </Col>
                </>
              )}
              {diemTien.tienThe > 0 && (
                <>
                  <Col span={12}>
                    <InputNumber
                      suffix="vnđ"
                      max={diemTien?.tienThe}
                      placeholder="Trừ tiền thẻ"
                      style={{ width: "80%" }}
                      value={data[tab]?.TienTru}
                      onChange={(v) => applyChangeTotal("TienTru", v)}
                    />
                  </Col>
                  <Col span={12}>{Format_Currency(data[tab]?.TienTru)}</Col>
                </>
              )}

              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 8 }}
              >
                <p style={{ fontWeight: "bold", fontSize: 16 }}>
                  Tổng phải trả
                </p>
                <p style={{ fontWeight: "bold", fontSize: 16 }}>
                  {Format_Currency(data[tab]?.KhachTra)}
                  {/* {Format_Currency(
                    data[tab]?.SanPham?.reduce((pre, current) => {
                      return (pre += current.ThanhTien);
                    }, 0) -
                      (data[tab]?.DiemTru ?? 0) * (cardType.DiemTien ?? 0) -
                      (data[tab]?.TienTru ?? 0) -
                      (data[tab]?.SanPham?.reduce((pre, current) => {
                        return (pre += current.ThanhTien);
                      }, 0) *
                        (data[tab]?.TyLeCK ?? 0)) /
                        100
                  )} */}
                </p>
              </Flex>
              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 8 }}
              >
                <p style={{ fontWeight: "bold", fontSize: 16 }}>Khách trả</p>
                <InputNumber
                  style={{ width: "50%" }}
                  value={data[tab]?.KhachDua}
                  onChange={(v) => applyChangeTotal("KhachDua", v)}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              </Flex>
              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 8 }}
              >
                <p style={{ fontWeight: "bold", fontSize: 16 }}>Tiền thừa</p>
                <p style={{ fontWeight: "bold", fontSize: 16 }}>
                  {Format_Currency(data[tab]?.TienThua)}
                </p>
              </Flex>
              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 8 }}
              >
                <p style={{ fontSize: 16 }}> Hình thức thanh toán</p>
                <Select
                  style={{
                    width: "50%",
                  }}
                  value={data[tab]?.HinhThucTT}
                  placeholder="Chọn hình thức"
                  onChange={(v) => applyChangeTotal("HinhThucTT", v)}
                  options={hinhthuc}
                  fieldNames={{ label: "TenHinhThuc", value: "ID" }}
                />
              </Flex>
              <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 8 }}
              >
                <p style={{ fontSize: 16 }}>Vé QR hoặc thẻ</p>
                <Select
                  style={{
                    width: "50%",
                  }}
                  value={data[tab]?.LoaiVeXuat}
                  onChange={(v) => applyChangeTotal("LoaiVeXuat", v)}
                  options={[
                    { ID: "QR", Name: "QR" },
                    { ID: "CARD", Name: "Thẻ cứng" },
                  ]}
                  fieldNames={{ label: "Name", value: "ID" }}
                />
              </Flex>
              {data[tab]?.LoaiVeXuat === "CARD" && (
                <>
                  <Input
                    placeholder="Quẹt thẻ"
                    style={{ width: "100%" }}
                    value={mavach}
                    onChange={(v) => addThe(v.target.value)}
                  />
                  <div
                    style={{
                      width: "100%",
                      overflow: "scroll",
                      padding: "0px 10px",
                      paddingTop: 10,
                    }}
                    className="list-the"
                  >
                    {data[activeTab]?.The?.map((item, i) => {
                      return (
                        <Row align="middle" gutter={[0, 50]}>
                          <Col span={2}>
                            <p>{i + 1}. </p>
                          </Col>
                          <Col span={3}>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => removeThe(i)}
                            >
                              <FaTrashAlt />
                            </div>
                          </Col>
                          <Col span={9}>
                            <p style={{ fontWeight: "bold" }}>{item.MaVach}</p>
                          </Col>
                          <Col span={10}>
                            <p>
                              {
                                goidichvu?.find((v) => v.ID === item.MaGoiDV)
                                  ?.TenGoi
                              }{" "}
                              ( Quẹt: {item.SoLanQuet} )
                            </p>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
            <Flex
              align="flex-end"
              gap={10}
              justify="center"
              style={{ height: "100px" }}
            >
              <Button
                loading={loading}
                type="primary"
                disabled={data[tab]?.SanPham?.length > 0 ? false : true}
                // style={{ width: "100%" }}
                onClick={() => onPayConfirm()}
              >
                Thanh toán
              </Button>
              <Button danger onClick={() => handleKetThucCa()}>
                Kết thúc ca
              </Button>
            </Flex>
          </Flex>
        </Col>
      </Row>
    );
  };
  return (
    <Drawer
      title="Bán vé"
      width="100%"
      onClose={onClose}
      open={open}
      className="banve-detail"
    >
      {contextHolder}
      <Tabs
        onEdit={onEdit}
        type="editable-card"
        onChange={onChange}
        activeKey={activeTab}
        tabBarExtraContent={{
          left: (
            <Flex gap={10}>
              <Select
                style={{
                  width: "200px",
                }}
                value={data[activeTab]?.MaBG}
                // placeholder="Chọn hình thức"
                onChange={(v) => applyChangeTotal("MaBG", v)}
                options={banggia}
                fieldNames={{ label: "TenBangGia", value: "ID" }}
              />
              <DebounceSelect
                mode="multiple"
                value={data?.ChooseSP}
                placeholder="Tìm kiếm sản phẩm"
                fetchOptions={fetchSanPham}
                onChange={(newValue) => applyChange("ChooseSP", newValue)}
                style={{
                  width: 300,
                }}
              />
            </Flex>
          ),
          right: (
            <Button danger onClick={() => onClose()}>
              Thoát
            </Button>
          ),
        }}
      >
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key} closable={item.closable}>
              {renderHoaDon(item.key)}
            </TabPane>
          );
        })}
      </Tabs>
      <div style={{ display: "none" }}>
        <HoaDonToPrint
          ref={componentRef}
          data={data[activeTab]}
          dataPrint={dataPrint}
        />
      </div>
      <div style={{ display: "none" }}>
        <KetCaPrint ref={ketcaRef} data={dhtheoca} calamviec={calamviec} />
      </div>
      {renderModalConfirmPay()}
      {/* {renderModalAddKH()} */}
      {renderBatDauCa()}
      {renderKetThucCa()}
      <FormAddKH ref={refAddKH} onAfterSubmit={onAfterSubmitKH} />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
