import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Select,
  Checkbox,
  InputNumber,
} from "antd";
import _ from "lodash";
import { BanHangService } from "services/BanHangService";
import { SanPhamService } from "services/SanPhamService";
import { CongTyService } from "services/CongTyService";
const FormAdd = (props, ref) => {
  const [congty, setCongty] = useState([]);

  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [maKV, setMaKV] = useState(0);
  const [khuvuc, setKhuvuc] = useState({});
  const [sanpham, setSanpham] = useState([]);
  const loadTab2 = async () => {
    let _res = await SanPhamService.getListByMaNhom({
      MaNhom: 7,
      InputSearch: "",
    });
    setSanpham(_res.data);
  };
  const loadCategory = () => {
    CongTyService.getCongTy().then((_ct) => {
      setCongty(_ct.data);
    });
  };
  useImperativeHandle(ref, () => ({
    show: async (tab, makv) => {
      setOpen(true);
      setActiveTab(tab);
      setMaKV(makv);
      loadCategory();
    },
    update: async (data, tab, kv) => {
      setOpen(true);
      let _sptheoban = await SanPhamService.TheoBan.Get({
        MaBan: data.ID,
      });
      setData({
        ...data,
        MaSP: _sptheoban?.data?.MaSP,
        IDSP: _sptheoban?.data?.ID,
        DonGia: _sptheoban?.data?.DonGia,
      });
      setActiveTab(tab);
      setKhuvuc(kv);
      loadCategory();

      if (tab === "1") loadTab2(data.ID);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async () => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await BanHangService.Categories.addKhuVuc(_data);
        break;
      case "1":
        _data.MaKV = maKV;
        _res = await BanHangService.Categories.addBan(_data);
        if (_data.MaSP) {
          await SanPhamService.TheoBan.Add({
            MaBan: _data?.ID,
            MaSP: _data.MaSP,
            ID: _data?.IDSP,
            DonGia: _data?.DonGia,
          });
        }

        break;
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} danh mục thành công!`
      );
      onAfterSubmit(activeTab);
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderTab1 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên khu</p>
          <Input
            value={data?.TenKhu}
            placeholder="Nhập tên khu"
            onChange={(e) => applyChange("TenKhu", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Checkbox
            checked={data?.IsTinhGio}
            onChange={(e) => applyChange("IsTinhGio", e.target.checked)}
          >
            Tính tiền giờ
          </Checkbox>
        </Col>
        <Col span={24}>
          <Select
            // mode="multiple"
            allowClear
            placeholder="Chọn chi nhánh"
            onChange={(v) => applyChange("MaCT",v)}
            style={{
              width: 200,
            }}
            options={congty}
            fieldNames={{ label: "TenCTVT", value: "ID" }}
          />
        </Col>
      </Row>
    );
  };
  const renderTab2 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên bàn</p>
          <Input
            value={data?.TenBan}
            placeholder="Nhập tên bàn"
            onChange={(e) => applyChange("TenBan", e.target.value)}
          />
        </Col>

        {data?.ID && khuvuc?.IsTinhGio && (
          <>
            <Col span={12}>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaSP}
                placeholder="Chọn sản phẩm"
                onChange={(v) => applyChange("MaSP", v)}
                options={sanpham}
                fieldNames={{ label: "TenSP", value: "ID" }}
              />
            </Col>
            <Col span={12}>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.DonGia}
                placeholder="Nhập đơn giá"
                onChange={(v) => applyChange("DonGia", v)}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Col>
          </>
        )}
      </Row>
    );
  };
  return (
    <Drawer
      title="Danh mục"
      width={activeTab === "0" ? 450 : 450}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button loading={loading} onClick={() => onSubmit()} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {activeTab === "0" ? renderTab1() : renderTab2()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
