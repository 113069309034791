import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Flex, Table, Tooltip } from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { BanHangService } from "services/BanHangService";
import { Format_Datetime } from "layouts/ultis/common";
import { IoMdPrint } from "react-icons/io";
import { useReactToPrint } from "react-to-print";
import KetCaPrint from "components/KetCaPrint";
export default function Settings() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
  });
  const refDetail = useRef(null);
  const refPrint = useRef(null);
  const [calamviec, setCalamviec] = useState({});

  // const loadCalamviec = async () => {
  //   let _check = await BanHangService.checkCaLamViec();
  //   if (_check.data) {
  //     setCalamviec(_check.data);
  //   }
  // };
  useEffect(() => {
    // loadCalamviec();
    filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await BanHangService.getListCaLamViec(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const [dhtheoca, setDhtheoca] = useState([]);
  const handlePrintLai = useReactToPrint({
    content: () => refPrint.current,
  });

  const print = async (item) => {
    let _res = await BanHangService.getDonHangTheoCa({ MaCa: item?.ID });
    setDhtheoca(_res.data ?? []);
    setCalamviec(item);
    handlePrintLai();
  };
  const columns = [
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
    },
    {
      title: "Giờ vào ca",
      dataIndex: "GioVaoCa",
      render: (item) => Format_Datetime(item),
    },
    {
      title: "Giờ kết thúc ca",
      dataIndex: "GioRaCa",
      render: (item) => (item ? Format_Datetime(item) : "Đang trong ca"),
    },
    {
      title: "Tiền đầu ca (1)",
      dataIndex: "TienDauCa",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tổng số hoá đơn",
      dataIndex: "TongSoHD",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền hàng",
      dataIndex: "TienHang",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Chiết khấu",
      dataIndex: "ChietKhau",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Doanh thu",
      // dataIndex: "DoanhThu",
      render: (item) => Format_Currency(item?.TienHang - item.ChietKhau),
    },
    {
      title: "Tiền mặt (2)",
      dataIndex: "TienMat",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Ngân hàng",
      dataIndex: "NganHang",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền mặt cuối ca = (1+2)",
      // dataIndex: "TongTien",
      render: (item) => Format_Currency(item?.TienDauCa + item.TienMat),
    },
    {
      title: "",
      fixed: "right",
      width: "80px",
      render: (item) => {
        return (
          <Flex justify="center" gap={10}>
            <Tooltip placement="topRight" title="In hoá đơn">
              <Button
                ghost
                type="primary"
                icon={<IoMdPrint />}
                onClick={() => print(item)}
              />
            </Tooltip>
          </Flex>
        );
      },
    },
  ];

  return (
    <Box>
      <Toolbar
        right={() => (
          <FilterModal
            filterCondition={filterCondition}
            onChangeFilter={onChangeFilter}
          />
        )}
      />
      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          x: 1500,
        }}
        size="small"
      />
      <div style={{ display: "none" }}>
        <KetCaPrint ref={refPrint} data={dhtheoca} calamviec={calamviec} />
      </div>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
    </Box>
  );
}
