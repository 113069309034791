import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Col, DatePicker, Popover, Row, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { BanHangService } from "services/BanHangService";
import { NhanVienService } from "services/NhanVienService";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";

const FilterModal = ({ ...props }) => {
  const { companies, filterCondition, onChangeFilter } = props;
  // const [quay,setQuay] = useState([]);
  // const [nhanvien,setNhanvien] = useState([]);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "State":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(()=>{
    // BanHangService.getQuayBan().then(res=> setQuay(res.data ?? []));
    // NhanVienService.getNhanVien().then(res=>setNhanvien(res.data ?? []));
  },[])
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      <div className="box-search">
        <p className="label">Trạng thái</p>
        <Select
          mode="multiple"
          placeholder="Chọn quầy vé"
          onChange={(v) => applyChangeFilter("State", v)}
          style={{
            width: "100%",
          }}
          options={[
            {ID:"DRAFT",Name:"Chờ phát hành"},
            {ID:"SIGNED",Name:"Đã phát hành"},
            {ID:"ERROR",Name:"Phát hành lỗi"},
          ]}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
