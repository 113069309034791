import axiosApi from "./api_helper";

export const SanPhamService = {
  getListByMaNhom: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/getby-manhom", payload)
      .then((res) => res.data);
  },
  getList: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/get-list", payload)
      .then((res) => res.data);
  },
  getListBanVe: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/get-list-banve", payload)
      .then((res) => res.data);
  },

  getDinhLuong: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/get-dinhluong", payload)
      .then((res) => res.data);
  },
  deleteDinhLuong: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/delete-dinhluong", payload)
      .then((res) => res.data);
  },
  byMaVach: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/by-mavach", payload)
      .then((res) => res.data);
  },
  updateSanPham: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/update", payload)
      .then((res) => res.data);
  },
  addSanPham: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/add", payload)
      .then((res) => res.data);
  },
  deleteSanPham: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/delete", payload)
      .then((res) => res.data);
  },

  getLSP: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/get-loaisp", payload ? payload : { ApDung: null })
      .then((res) => res.data);
  },
  addLSP: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/loaisp/add", payload)
      .then((res) => res.data);
  },
  deleteLSP: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/loaisp/delete", payload)
      .then((res) => res.data);
  },
  getDVT: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/get-dvt", payload)
      .then((res) => res.data);
  },
  addDVT: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/donvitinh/add", payload)
      .then((res) => res.data);
  },
  deleteDVT: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/donvitinh/delete", payload)
      .then((res) => res.data);
  },
  getNhom: async () => {
    return await axiosApi()
      .post("api/sanpham/nhom-sp")
      .then((res) => res.data);
  },
  filterKhuVuiChoi: async (payload) => {
    return await axiosApi()
      .post("api/khuvuichoi/filter", payload)
      .then((res) => res.data);
  },
  addKhuVuiChoi: async (payload) => {
    return await axiosApi()
      .post("api/khuvuichoi/add", payload)
      .then((res) => res.data);
  },
  deleteKhuVuiChoi: async (payload) => {
    return await axiosApi()
      .post("api/khuvuichoi/delete", payload)
      .then((res) => res.data);
  },
  filterGoiDV: async (payload) => {
    return await axiosApi()
      .post("api/khuvuichoi/filter-gdv", payload)
      .then((res) => res.data);
  },
  getGoiDVByKVC: async (payload) => {
    return await axiosApi()
      .post("api/goidichvu/get-by-kvc", payload)
      .then((res) => res.data);
  },

  addGoiDV: async (payload) => {
    return await axiosApi()
      .post("api/khuvuichoi/add-gdv", payload)
      .then((res) => res.data);
  },
  deleteGoiDV: async (payload) => {
    return await axiosApi()
      .post("api/khuvuichoi/delete-gdv", payload)
      .then((res) => res.data);
  },

  getImageKVC: async (payload) => {
    return await axiosApi()
      .post("api/khuvuichoi/get-images", payload)
      .then((res) => res.data);
  },
  addImageKVC: async (payload) => {
    return await axiosApi()
      .post("api/khuvuichoi/add-images", payload)
      .then((res) => res.data);
  },
  deleteImageKVC: async (payload) => {
    return await axiosApi()
      .post("api/khuvuichoi/delete-image", payload)
      .then((res) => res.data);
  },
  getSanPhamTheoQuay: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/quay/list", payload)
      .then((res) => res.data);
  },

  addSanPhamTheoQuay: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/quay/add", payload)
      .then((res) => res.data);
  },
  removeSanPhamTheoQuay: async (payload) => {
    return await axiosApi()
      .post("api/sanpham/quay/remove", payload)
      .then((res) => res.data);
  },
  TheoBan: {
    Get: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/ban/get", payload)
        .then((res) => res.data);
    },
    Add: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/ban/add", payload)
        .then((res) => res.data);
    },
    Remove: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/ban/remove", payload)
        .then((res) => res.data);
    },
  },
  Categories: {
    getSanPhamMayIn: async (payload) => {
      return await axiosApi()
        .post("api/caidatmayin/get-sanpham", payload)
        .then((res) => res.data);
    },
    addSanPhamMayIn: async (payload) => {
      return await axiosApi()
        .post("api/caidatmayin/add-sanpham", payload)
        .then((res) => res.data);
    },
    deleteSanPhamMayIn: async (payload) => {
      return await axiosApi()
        .post("api/caidatmayin/delete-sanpham", payload)
        .then((res) => res.data);
    },
    getBangGiaTheoQuay: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/get-banggia-byquay", payload)
        .then((res) => res.data);
    },
    getBangGia: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/get-banggia", payload)
        .then((res) => res.data);
    },
    addBangGia: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/add-banggia", payload)
        .then((res) => res.data);
    },
    deleteBangGia: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/delete-banggia", payload)
        .then((res) => res.data);
    },
    addSPBangGia: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/add-sanpham-banggia", payload)
        .then((res) => res.data);
    },
    addNhieuSPBangGia: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/add-nhieusanpham-banggia", payload)
        .then((res) => res.data);
    },
    deleteSPBangGia: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/delete-sanpham-banggia", payload)
        .then((res) => res.data);
    },
    getSanPhamByMaBG: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/get-sanpham-by-banggia", payload)
        .then((res) => res.data);
    },
    getCatDatMayIn: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/get-caidatmayin", payload)
        .then((res) => res.data);
    },
    addCaiDatMayIn: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/coru-caidatmayin", payload)
        .then((res) => res.data);
    },
    deleteCaiDatMayIn: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/delete-caidatmayin", payload)
        .then((res) => res.data);
    },
    addKhoHang: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/coru-khohang", payload)
        .then((res) => res.data);
    },
    deleteKhoHang: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/delete-khohang", payload)
        .then((res) => res.data);
    },
  },
};
