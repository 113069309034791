import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  DatePicker,
  InputNumber,
  Row,
  Col,
  Drawer,
  notification,
  Select,
  Flex,
  Modal,
  Popconfirm,
  Badge,
  message,
  Tooltip,
  Input,
  Popover,
  Space,
} from "antd";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import { FiPlusCircle } from "react-icons/fi";

import _ from "lodash";
import { FaCheckCircle, FaTrashAlt } from "react-icons/fa";
import { SanPhamService } from "services/SanPhamService";
import { Format_Currency } from "layouts/ultis/common";
import { CustomerService } from "services/CustomerService";
import { BanHangService } from "services/BanHangService";
import { CardService } from "services/CardService";
import HoaDonToPrint from "components/HoaDonToPrint";
import KetCaPrint from "./KetCaPrint";
import { useReactToPrint } from "react-to-print";
import { MdOutlineCancel, MdOutlineFastfood } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { KhoHangService } from "services/KhoHangService";
import { IoFastFoodOutline, IoSearch } from "react-icons/io5";
import { CiShoppingCart, CiStickyNote, CiTimer } from "react-icons/ci";
import { Format_Datetime, Format_Time } from "layouts/ultis/common";
import FormChangeTable from "./FormChangeTable";
import { FcOvertime } from "react-icons/fc";
import FormPay from "./FormPay";
import { RiMoneyDollarCircleLine, RiTableAltLine } from "react-icons/ri";
import { LuPercent } from "react-icons/lu";
import { GiShoppingCart } from "react-icons/gi";
import FormCartMobile from "./FormCartMobile";
import { FaMinus, FaPlus } from "react-icons/fa6";
// import "./scss/mobile.scss";
const FormAddMobile = (props, ref) => {
  const history = useHistory();
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [diemTien, setDiemTien] = useState({});
  const [khachhang, setKhachhang] = useState([]);
  const refPay = useRef(null);
  const refCart = useRef(null);
  const [cardType, setCardType] = useState({});
  const componentRef = useRef();
  const ketcaRef = useRef();
  const refChangeTable = useRef(null);
  const [tinhGio, setTinhGio] = useState({});
  const defaulData = {
    IsKhachHang: 1,
    SanPham: [],
    MaKH: null,
  };
  const [data, setData] = useState(defaulData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sanpham, setSanpham] = useState([]);
  const [sanphamall, setSanphamall] = useState([]);
  const [khohang, setKhohang] = useState([]);
  const [quayve, setQuayve] = useState([]);
  const [dataquay, setDataquay] = useState({});
  const [calamviec, setCalamviec] = useState({});
  const [openCaLV, setOpenCaLV] = useState(false);
  const [openKTCa, setOpenKTCa] = useState(false);
  const [dhtheoca, setDhtheoca] = useState([]);
  const [loaisp, setLoaisp] = useState([]);
  const [uudai, setUudai] = useState({});
  const [lspActive, setLspActive] = useState({ MaLSP: 0, TenLSP: "Tất cả" });
  const loadcategories = async () => {
    let _qv = await BanHangService.getQuayBan();
    setQuayve(_qv.data ?? []);
    let _dataquay = _.cloneDeep(dataquay);
    _dataquay.MaQuay = _qv.isDefault;
    setDataquay(_dataquay);
    let _kh = await CustomerService.getListAll();
    _kh.data?.map((item) => (item.Name = item.TenCongTy + " - " + item.DiDong));
    setKhachhang(_kh?.data);
  };
  const loadSanPham = async (maquay) => {
    let _sp = await SanPhamService.getList({ InputSearch: "" });
    let _sptheoquay = await SanPhamService.getSanPhamTheoQuay({
      MaQuay: maquay,
    });
    let spquay = _sp.data.filter((v) =>
      _.map(_sptheoquay.data, "MaSP").includes(v.ID)
    );
    let _lsp = await SanPhamService.getLSP({ ApDung: true });
    let lsp = [{ MaLSP: 0, TenLSP: "Tất cả" }];
    let _sptheoloai = spquay.filter((v) =>
      _.map(_lsp.data, "MaLSP").includes(v.MaLSP)
    );
    setSanpham(_sptheoloai ?? []);
    setSanphamall(_sptheoloai ?? []);
    _lsp.data?.map((item) => {
      if (_sptheoloai?.some((v) => v.MaLSP === item.MaLSP)) lsp.push(item);
    });
    setLoaisp(lsp);
  };
  useImperativeHandle(ref, () => ({
    show: async (ban, isTinhGio) => {
      setOpen(true);
      setLoading(true);
      loadcategories();
      let _data = _.cloneDeep(data);
      _data.MaBan = ban.ID;
      _data.TenBan = ban.TenBan;
      _data.MaKV = ban.MaKV;
      _data.TinhGio = isTinhGio;
      _data.GioVao = ban?.GioVao ?? new Date();
      let _checkBanHang = await BanHangService.BanTheoBan.check({
        MaBan: ban.ID,
      });
      if (_checkBanHang?.data) {
        _data.MaBH = _checkBanHang.data?.id;
        _data.ThanhTien = _checkBanHang.data?.thanhTien;
        _data.SanPham = _checkBanHang.sanpham;
      }
      let _kho = await KhoHangService.NhapKho.getTenKho();
      if (_kho.data?.length > 0) {
        _data.MaKho = _kho.data[0].ID;
        setKhohang(_kho.data ?? []);
      }
      setData(_data);
      let _check = await BanHangService.checkCaLamViec();
      if (!_check.data) {
        setOpenCaLV(true);
      } else {
        loadSanPham(_check.data.MaQuay);
        setCalamviec(_check.data);
      }
      setLoading(false);
    },
  }));
  useEffect(() => {
    (async () => {
      let _data = _.cloneDeep(data);
      if (_data?.TinhGio) {
        if (!_data.MaBH) {
          let _sp = await SanPhamService.getListByMaNhom({
            MaNhom: 7,
            InputSearch: "",
          });
          let _sptheoban = await SanPhamService.TheoBan.Get({
            MaBan: _data.MaBan,
          });
          let lstsp = [];
          if (_sp.data?.length > 0) {
            let _findsp = _sp?.data?.find(
              (v) => v.ID === _sptheoban?.data?.MaSP
            );
            lstsp.push({
              MaSP: _findsp ? _findsp.ID : _sp.data[0].ID,
              TenSP: _findsp ? _findsp.TenSP : _sp.data[0].TenSP,
              DonGia: _findsp ? _sptheoban?.data?.DonGia : _sp.data[0].GiaBan,
              SoLuong: 0,
              TyLeCK: 0,
              TienCK: 0,
              ThanhTien: 0,
              MaNhom: _findsp ? _findsp.MaNhom : _sp.data[0].MaNhom,
            });
          }
          let _payload = {
            MaCaLamViec: calamviec?.ID,
            MaQuayBan: calamviec?.MaQuay,
            MaNhom: 2,
            ThanhTien: 0,
            SanPham: lstsp,
            MaBan: _data.MaBan,
          };
          let _res = await BanHangService.BanTheoBan.add(_payload);
          if (_res.status === 2000) {
            let _checkBanHang = await BanHangService.BanTheoBan.check({
              MaBan: _data.MaBan,
            });
            if (_checkBanHang?.data) {
              _data.MaBH = _res.data;
              _data.ThanhTien = _checkBanHang.data?.thanhTien;
              _data.SanPham = _checkBanHang.sanpham;
            }
          }
          setData(_data);
          onAfterSubmit(_data.MaBan, "ACTIVE");
        }
      }
    })();
  }, [calamviec]);

  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    setDiemTien({});
  };
  const SumTotal = (_data) => {
    _data.ThanhTien = _data?.SanPham?.reduce((pre, current) => {
      return (pre += current.ThanhTien);
    }, 0);
    _data.TienCK = (_data.ThanhTien * (_data?.TyLeCK ?? 0)) / 100;
  };
  const applyChange = async (p, v, MaSP) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    switch (p) {
      case "ChooseClickSP":
        let spdsnew = _data.SanPham?.find((f) => f.MaSP === v.ID);
        if (spdsnew) {
          spdsnew.SoLuong++;
          spdsnew.TienCK =
            (spdsnew?.SoLuong * spdsnew?.DonGia * spdsnew?.TyLeCK) / 100;
          spdsnew.ThanhTien =
            spdsnew.SoLuong * spdsnew.DonGia -
            (spdsnew?.SoLuong * spdsnew?.DonGia * spdsnew.TyLeCK) / 100;
        } else {
          let tyleck = uudai?.TyLeCK ? uudai?.TyLeCK : 0;
          let TienCK = (v?.GiaBan * tyleck) / 100;
          let ThanhTien = v.GiaBan - (v?.GiaBan * tyleck) / 100;
          _data.SanPham.push({
            MaSP: v.ID,
            TenSP: v.TenSP,
            DonGia: v.GiaBan,
            SoLuong: 1,
            TyLeCK: tyleck,
            TienCK: TienCK,
            ThanhTien: ThanhTien,
            MaNhom: v.MaNhom,
          });
        }
        SumTotal(_data);
        if (_data.MaBH) {
          let _payload = {
            ThanhTien: _data.ThanhTien,
            ID: _data.MaBH,
            SanPham: _data.SanPham,
          };
          await BanHangService.BanTheoBan.update(_payload);
          let _sp = await BanHangService.BanTheoBan.check({
            MaBan: _data.MaBan,
          });
          _data.SanPham = _sp.sanpham;
        } else {
          let _payload = {
            MaCaLamViec: calamviec?.ID,
            MaQuayBan: calamviec?.MaQuay,
            MaNhom: 2,
            ThanhTien: _data.ThanhTien,
            SanPham: _data.SanPham,
            MaBan: _data.MaBan,
          };
          let _res = await BanHangService.BanTheoBan.add(_payload);
          _data.MaBH = _res?.data;
          onAfterSubmit(_data.MaBan, "ACTIVE");
        }

        break;
      case "SoLuong":
        let sl = _data.SanPham?.find((v) => v.MaSP === MaSP);
        sl[p] = v;
        sl.TienCK = (sl?.SoLuong * sl?.DonGia * sl?.TyLeCK) / 100;
        sl.ThanhTien =
          sl.SoLuong * sl.DonGia - (sl?.SoLuong * sl?.DonGia * sl.TyLeCK) / 100;
        SumTotal(_data);
        break;
      case "AddDvGio":
        let _sp = _data.SanPham.find((v) => v.MaNhom === 7);
        _data.SanPham.unshift({
          ..._sp,
          ID: null,
          GioRa: null,
          GioVao: DateTime.now().setZone("Asia/Ho_Chi_Minh"),
          SoLuong: 0,
          ThanhTien: 0,
        });
        let _payload = {
          ThanhTien: _data.ThanhTien,
          ID: _data.MaBH,
          SanPham: _data.SanPham,
        };
        await BanHangService.BanTheoBan.update(_payload);
        let _check = await BanHangService.BanTheoBan.check({
          MaBan: _data.MaBan,
        });
        _data.SanPham = _check.sanpham;
        break;
      case "TinhGio":
        let _sptg = _data.SanPham?.find((v) => v.ID === tinhGio.ID);
        _sptg.GioRa = new Date(tinhGio.GioRa);
        _sptg.GioVao = new Date(tinhGio.GioVao);
        const ngay1 = DateTime.fromISO(_sptg.GioRa?.toISOString(), {
          zone: "Asia/Ho_Chi_Minh",
        }).toString();
        const ngay2 = DateTime.fromISO(_sptg.GioVao?.toISOString(), {
          zone: "Asia/Ho_Chi_Minh",
        }).toString();
        var chenhLechGio =
          (new Date(ngay1) - new Date(ngay2)) / (1000 * 60 * 60);
        console.log(ngay2);
        _sptg.SoLuong = chenhLechGio.toFixed(2);
        _sptg.TienCK = (_sptg?.SoLuong * _sptg?.DonGia * _sptg?.TyLeCK) / 100;
        _sptg.ThanhTien =
          _sptg.SoLuong * _sptg.DonGia -
          (_sptg?.SoLuong * _sptg?.DonGia * _sptg.TyLeCK) / 100;
        SumTotal(_data);
        let _payload2 = {
          ThanhTien: _data.ThanhTien,
          ID: _data.MaBH,
          SanPham: [
            {
              ..._sptg,
              GioRa: ngay1,
              GioVao: ngay2,
            },
          ],
        };
        await BanHangService.BanTheoBan.update(_payload2);
        let _checktg = await BanHangService.BanTheoBan.check({
          MaBan: _data.MaBan,
        });
        _data.SanPham = _checktg.sanpham;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
    setLoading(false);
  };
  const [sanphamchoose, setSanphamchooe] = useState({});
  const handleSoLuong = (p, v) => {
    let _sp = _.cloneDeep(sanphamchoose);
    switch (p) {
      case "minus":
        if (_sp[v.ID] > 0) {
          _sp[v.ID] = _sp[v.ID] - 1;
        }
        break;
      case "plus":
        if (_sp[v.ID]) {
          _sp[v.ID] = _sp[v.ID] + 1;
        } else {
          _sp[v.ID] = 1;
        }
        break;
    }
    setSanphamchooe(_sp);
  };
  const handleCancel = () => {
    setSanphamchooe({});
  };
  const handleAddCart = async () => {
    let _lstsp = [];
    Object.keys(sanphamchoose)?.map((item) => {
      let sp = sanphamall.find((v) => v.ID === Number(item));
      console.log(sp);
      if (sp) {
        _lstsp.push({
          MaSP: sp.ID,
          TenSP: sp.TenSP,
          DonGia: sp.GiaBan,
          SoLuong: sanphamchoose[item],
          TyLeCK: 0,
          TienCK: 0,
          ThanhTien: sp.GiaBan * sanphamchoose[item],
          MaNhom: sp.MaNhom,
        });
      }
    });
    let _payload = {
      ThanhTien: 0,
      ID: data.MaBH,
      SanPham: _lstsp,
    };
    let _res  = await BanHangService.BanTheoBan.update(_payload);
    if(_res.status === 2000){
      messageApi.open({
        type: "success",
        content: "Thêm vào giỏ thành công",
      });
      setSanphamchooe({})
    }else{
      messageApi.open({
        type: "error",
        content: _res.message,
      });
    }
  };
  const renderHoaDon = () => {
    const onChangeLSP = (value) => {
      if (value.MaLSP === 0) {
        setSanpham(sanphamall);
      } else {
        let _sp = sanphamall?.filter((v) => v.MaLSP === value.MaLSP);
        setSanpham(_sp);
      }
      setLspActive(value);
    };
    return (
      <div className="boxhoadon">
        <div className="right-box">
          <Flex
            align="center"
            gap={5}
            style={{
              marginBottom: 10,
              overflowX: "scroll",
              width: "100%",
              borderRadius: 10,
              boxShadow:
                "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
            }}
          >
            {loaisp?.map((item) => {
              return (
                <Badge
                  key={item.MaLSP}
                  count={item.Total}
                  onClick={() => onChangeLSP(item)}
                >
                  <div
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
                      border: `1px solid #adc6ff`,
                      padding: "6px 15px",
                      cursor: "pointer",
                      borderRadius: 10,
                      background:
                        lspActive.MaLSP === item.MaLSP ? "#adc6ff" : null,
                      color: lspActive.MaLSP === item.MaLSP ? "black" : null,
                    }}
                  >
                    <p style={{ whiteSpace: "nowrap" }}>{item.TenLSP}</p>
                  </div>
                </Badge>
              );
            })}
          </Flex>
          <div className="right-box-detail">
            {sanpham.map((item) => {
              return (
                <div
                  style={{
                    borderBottom: "1px solid rgb(217, 217, 217)",
                    cursor: "pointer",
                    borderRadius: 10,
                    marginBottom: 5,
                  }}
                  // onClick={() => applyChange("ChooseClickSP", item)}
                >
                  <Flex
                    style={{
                      height: 80,
                      width: "100%",
                      borderRadius: 10,
                    }}
                    align="center"
                  >
                    <Flex
                      align="center"
                      justify="center"
                      style={{
                        height: 80,
                        width: 80,
                        background: !item?.LinkImage && "#e6f0fa",
                        borderRadius: 10,
                        position: "relative",
                      }}
                    >
                      {item?.LinkImage ? (
                        <>
                          <img
                            style={{ height: 80, padding: "10px" }}
                            src={item.LinkImage}
                          />
                          {sanphamchoose[item.ID] > 0 && (
                            <Flex
                              style={{
                                height: 80,
                                padding: "10px",
                                position: "absolute",
                                height: "100%",
                                top: 0,
                                left: 0,
                                right: 0,
                                background: "rgba(0,0,0,0.5)",
                                borderRadius: 10,
                              }}
                              align="center"
                              justify="center"
                            >
                              <FaCheckCircle color="white" size={25} />
                            </Flex>
                          )}
                        </>
                      ) : (
                        <>
                          <MdOutlineFastfood
                            style={{
                              height: "30px",
                              width: "60%",
                              color: "rgba(0, 102, 204, 0.5)",
                            }}
                          />
                          {sanphamchoose[item.ID] > 0 && (
                            <Flex
                              style={{
                                height: 80,
                                padding: "10px",
                                position: "absolute",
                                height: "100%",
                                top: 0,
                                left: 0,
                                right: 0,
                                background: "rgba(0,0,0,0.5)",
                                borderRadius: 10,
                              }}
                              align="center"
                              justify="center"
                            >
                              <FaCheckCircle color="white" size={25} />
                            </Flex>
                          )}
                        </>
                      )}
                    </Flex>
                    <div style={{ width: "65%" }}>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "11px",
                        }}
                      >
                        {item.TenSP}
                      </p>
                      <Flex justify="space-between" align="center">
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                            color: "#0066CC",
                          }}
                        >
                          {Format_Currency(item.GiaBan)}
                        </p>
                        <Flex align="center" justify="center" gap={10}>
                          <FaMinus
                            size={18}
                            style={{
                              background: "#0066CC",
                              color: "white",
                              padding: 2,
                              borderRadius: "50%",
                            }}
                            onClick={() => handleSoLuong("minus", item)}
                          />
                          <p style={{ fontWeight: "600" }}>
                            {sanphamchoose[item.ID] ?? 0}
                          </p>
                          <FaPlus
                            size={18}
                            onClick={() => handleSoLuong("plus", item)}
                            style={{
                              background: "#0066CC",
                              color: "white",
                              padding: 2,
                              borderRadius: "50%",
                            }}
                          />
                        </Flex>
                      </Flex>
                    </div>
                  </Flex>
                </div>
              );
            })}
          </div>
        </div>
        {_.sum(Object.values(sanphamchoose)) > 0 && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              bottom: 0,
              left: 0,
              background: "white",

              boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
            }}
          >
            <Flex
              align="center"
              gap={10}
              justify="center"
              style={{
                background: "#e6f0fa",
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                padding: 10,
              }}
            >
              <Popconfirm
                title="Thêm vào đơn"
                description="Bạn có chắc chắn muốn thêm vào đơn?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => handleAddCart()}
              >
                <Button
                  loading={loading}
                  type="primary"
                  icon={<CiShoppingCart size={25} />}
                  className="btn-thanhtoan"
                  style={{
                    width: "45%",
                  }}
                >
                  Thêm vào đơn ({_.sum(Object.values(sanphamchoose))})
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Chọn lại"
                description="Bạn có chắc chắn muốn chọn lại?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => handleCancel()}
              >
                <Button
                  loading={loading}
                  icon={<MdOutlineCancel size={25} />}
                  className="btn-cancel"
                  style={{
                    width: "45%",
                  }}
                >
                  Chọn lại
                </Button>
              </Popconfirm>
            </Flex>
          </div>
        )}
      </div>
    );
  };
  const handlePrintKetCa = useReactToPrint({
    content: () => ketcaRef.current,
  });
  const renderBatDauCa = () => {
    const saveCaLamViec = async () => {
      setLoading(true);
      let _res = await BanHangService.addCaLamViec(dataquay);
      if (_res.status === 2000) {
        setOpenCaLV(false);
        setCalamviec({
          ID: _res.data,
          TienDauCa: dataquay.TienDauCa,
          GioVaoCa: new Date(),
          MaQuay: dataquay.MaQuay,
        });
        loadSanPham(dataquay.MaQuay);
      }
      setLoading(false);
    };
    const appllyChange = (p, v) => {
      let _data = _.cloneDeep(dataquay);
      _data[p] = v;
      setDataquay(_data);
    };
    return (
      <Modal
        closeIcon={false}
        title="KHAI BÁO TIỀN ĐẦU CA"
        open={openCaLV}
        onOk={() => saveCaLamViec()}
        onCancel={() => {
          setOpenCaLV(false);
          setOpen(false);
        }}
        cancelText="Huỷ"
        okText="Lưu"
      >
        <div>
          <p style={{ marginTop: 10 }}>Quầy bán</p>
          <Select
            style={{
              width: "100%",
            }}
            value={dataquay?.MaQuay}
            placeholder="Chọn quầy bán"
            onChange={(v) => appllyChange("MaQuay", v)}
            options={quayve}
            fieldNames={{ label: "TenQuay", value: "ID" }}
          />
          <p style={{ marginTop: 10 }}>Tiền đầu ca</p>
          <InputNumber
            suffix="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            placeholder="Nhập tiền đầu ca"
            style={{ width: "100%" }}
            onChange={(v) => appllyChange("TienDauCa", v)}
          />
        </div>
      </Modal>
    );
  };
  const renderKetThucCa = () => {
    const logout = async () => {
      localStorage.removeItem("token");
      localStorage.removeItem("url");
      history.push("/auth/sign-in");
    };
    const saveKetThucCa = async () => {
      setLoading(true);
      let _res = await BanHangService.addKetThucCa({
        ID: calamviec?.ID,
      });
      if (_res.status === 2000) {
        handlePrintKetCa();
        setOpenKTCa(false);
        setOpen(false);
        logout();
      }
      setLoading(false);
    };
    return (
      <Modal
        closeIcon={false}
        width={900}
        title="KHAI BÁO TIỀN KẾT THÚC CA"
        open={openKTCa}
        onOk={() => saveKetThucCa()}
        onCancel={() => {
          setOpenKTCa(false);
        }}
        cancelText="Huỷ"
        okText="Kết ca"
      >
        <Row gutter={[20]}>
          <Col span={12}>
            <p style={{ marginTop: 10, color: "red" }}>Tiền đầu ca (1)</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={calamviec?.TienDauCa}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Tổng hoá đơn</p>
            <InputNumber
              disabled
              value={dhtheoca?.length}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Tiền hàng</p>
            <InputNumber
              suffix="vnđ"
              disabled
              value={dhtheoca?.reduce((pre, cur) => (pre += cur.ThanhTien), 0)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Chiết khấu</p>
            <InputNumber
              suffix="vnđ"
              disabled
              value={dhtheoca?.reduce((pre, cur) => (pre += cur.TienCK), 0)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Doanh thu</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={dhtheoca?.reduce((pre, cur) => (pre += cur.KhachTra), 0)}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10, color: "red" }}>Tiền mặt (2)</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={dhtheoca
                ?.filter((v) => v.HinhThucTT === 3)
                ?.reduce((pre, cur) => (pre += cur.KhachTra), 0)}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10 }}>Ngân hàng</p>
            <InputNumber
              suffix="vnđ"
              disabled
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={dhtheoca
                ?.filter((v) => v.HinhThucTT === 2)
                ?.reduce((pre, cur) => (pre += cur.KhachTra), 0)}
            />
          </Col>
          <Col span={12}>
            <p style={{ marginTop: 10, color: "red" }}>
              Tổng tiền mặt cuối ca = (1)+(2)
            </p>
            <InputNumber
              disabled
              suffix="vnđ"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              value={
                (dhtheoca
                  ?.filter((v) => v.HinhThucTT === 3)
                  ?.reduce((pre, cur) => (pre += cur.KhachTra), 0) ?? 0) +
                calamviec?.TienDauCa
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  };
  const onAfterSubmitChangeTable = async (v) => {
    let _data = _.cloneDeep(data);
    let _checkBanHang = await BanHangService.BanTheoBan.check({
      MaBan: v.ID,
    });
    if (_checkBanHang?.data) {
      _data.MaBH = _checkBanHang.data?.id;
      _data.ThanhTien = _checkBanHang.data?.thanhTien;
      _data.SanPham = _checkBanHang.sanpham;
    }
    _data.MaBan = v.ID;
    setData(_data);
    onAfterSubmit();
  };
  const onSubmitPay = (maban, state) => {
    onClose();
    onAfterSubmit(maban, state);
  };
  const [inputVisible, setInputVisible] = useState(false);

  const handleButtonClick = () => {
    setInputVisible(!inputVisible);
  };
  const searchSanPham = (v) => {
    if (v.length === 0) {
      setSanpham(sanphamall);
    } else {
      let _sp = sanphamall?.filter((a) =>
        (a?.TenSP).toLowerCase().includes(v.toLowerCase())
      );
      setSanpham(_sp);
    }
  };
  return (
    <Drawer
      title={data?.TenBan}
      width="100%"
      onClose={() => onClose()}
      open={open}
      className="bantheoban-mobile-detail"
      extra={
        <Space>
          <GiShoppingCart
            onClick={() =>
              refCart.current.show(
                {
                  ID: data.MaBan,
                  TenBan: data.TenBan,
                  MaKV: data.MaKV,
                  GioVao: data?.GioVao,
                },
                data.TinhGio
              )
            }
            size={30}
            style={{
              background: "#f0f0f0",
              padding: "5px",
              borderRadius: "50%",
            }}
          />
          {inputVisible === false && (
            <IoSearch
              onClick={handleButtonClick}
              size={30}
              style={{
                background: "#f0f0f0",
                padding: "5px",
                borderRadius: "50%",
              }}
            />
          )}
          <Input
            onChange={(e) => searchSanPham(e.target.value)}
            // showSearch
            placeholder="Tìm kiếm sản phẩm"
            // optionFilterProp="children"
            // filterOption={filterOptionSP}
            // fieldNames={{ label: "TenSP", value: "ID" }}
            // onChange={(v) => {
            //   handleChooseSP(v);
            //   handleButtonClick();
            // }}
            // options={sanphamall}
            className={`search-input ${inputVisible ? "show" : ""}`}
          />
        </Space>
      }
    >
      {renderHoaDon()}
      <div style={{ display: "none" }}>
        <HoaDonToPrint ref={componentRef} data={data} />
      </div>
      <div style={{ display: "none" }}>
        <KetCaPrint ref={ketcaRef} data={dhtheoca} calamviec={calamviec} />
      </div>
      {renderBatDauCa()}
      {renderKetThucCa()}
      <FormChangeTable
        ref={refChangeTable}
        onAfterSubmit={onAfterSubmitChangeTable}
      />
      <FormPay ref={refPay} onAfterSubmit={onSubmitPay} />
      <FormCartMobile ref={refCart} />
      {contextHolder}
      {contextHolderMS}
    </Drawer>
  );
};
export default forwardRef(FormAddMobile);
