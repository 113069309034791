import dayjs from "dayjs";

export const Format_Currency = (number) => {
  let num;
  const lengthArr = number?.toString().split(".").length;
  if (lengthArr > 1) {
    const parts = Number.parseFloat(number).toFixed(0).split(".");
    num =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      (parts[1] ? "," + parts[1] : "");
  } else {
    num = number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return num;
};
export const Format_Datetime = (date) => {
  var m = new Date(date);
  var dateString =
    m.getUTCFullYear() +
    "/" +
    ("0" + (m.getUTCMonth() + 1)).slice(-2) +
    "/" +
    ("0" + m.getUTCDate()).slice(-2) +
    " | ";
  var time = m.getHours() + ":" + m.getMinutes();

  // ("0" + m.getUTCHours()).slice(-2) + ":" +
  // ("0" + m.getUTCMinutes()).slice(-2) + ":" +
  // ("0" + m.getUTCSeconds()).slice(-2);
  return dateString + time;
};
export const Format_Date = (date) => {
  var m = new Date(date);
  // var dateString =
  // ("0" + m.getUTCDate()).slice(-2) + "/" +
  // ("0" + (m.getUTCMonth()+1)).slice(-2) + "/" +
  //     m.getUTCFullYear();
  var day = m.getDate() < 10 ? "0" + m.getDate() : m.getDate();
  var month =
    m.getMonth() + 1 < 10 ? "0" + (m.getMonth() + 1) : m.getMonth() + 1;
  return day + "/" + month + "/" + m.getFullYear();
};
export const Format_Time = (date) => {
  var m = new Date(date);
  var hour = m.getHours() < 10 ? "0" + m.getHours() : m.getHours();
  var min = m.getMinutes() < 10 ? "0" + m.getMinutes() : m.getMinutes();
  return hour + ":" + min;
};

export function DocSoTienBangTiengViet(soTien) {
  const donVi = ["", "nghìn", "triệu", "tỷ", "nghìn tỷ", "triệu tỷ", "tỷ tỷ"];

  const soChu = [
    "không",
    "một",
    "hai",
    "ba",
    "bốn",
    "năm",
    "sáu",
    "bảy",
    "tám",
    "chín",
  ];

  function docBaChuSo(so) {
    let tram = Math.floor(so / 100);
    let chuc = Math.floor((so % 100) / 10);
    let donVi = so % 10;
    let ketQua = "";

    if (tram !== 0) {
      ketQua += `${soChu[tram]} trăm `;
      if (chuc === 0 && donVi !== 0) {
        ketQua += "linh ";
      }
    }

    if (chuc !== 0 && chuc !== 1) {
      ketQua += `${soChu[chuc]} mươi `;
      if (donVi === 1) {
        ketQua += "mốt ";
      }
    } else if (chuc === 1) {
      ketQua += "mười ";
      if (donVi === 1) {
        ketQua += "một ";
      }
    }

    if (chuc !== 1 && donVi !== 0 && donVi !== 1) {
      ketQua += `${soChu[donVi]} `;
    }

    return ketQua.trim();
  }

  function docSoThanhChu(so) {
    if (so === 0) {
      return "không đồng";
    }

    let soTien = Math.abs(so);
    let ketQua = "";
    let i = 0;

    while (soTien > 0) {
      let baChuSo = soTien % 1000;
      if (baChuSo !== 0) {
        let chu = docBaChuSo(baChuSo);
        ketQua = `${chu} ${donVi[i]} ${ketQua}`.trim();
      }
      soTien = Math.floor(soTien / 1000);
      i++;
    }

    return `${ketQua} đồng`.replace(/ {2,}/g, " ").trim();
  }
  function vietHoaChuDauTien(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return vietHoaChuDauTien(docSoThanhChu(soTien));
}

export function ConvertExcelDateToJSDate(excelDate) {
  const excelStartDate = new Date(1900, 0, 1); // 1/1/1900
  const jsDate = new Date(
    excelStartDate.getTime() + (excelDate - 1) * 24 * 60 * 60 * 1000
  );
  return dayjs(jsDate).format("DD-MM-YYYY");
}
