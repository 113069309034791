import axiosApi from "./api_helper";
export const CategoryService = {
  Viettel: {
    getTaiKhoan: async () => {
      return await axiosApi()
        .post("api/category/viettel/get-taikhoan")
        .then((res) => res.data);
    },
    addTaiKhoan: async (payload) => {
      return await axiosApi()
        .post("api/category/viettel/add-taikhoan", payload)
        .then((res) => res.data);
    },
    deleteTaiKhoan: async (payload) => {
      return await axiosApi()
        .post("api/category/viettel/delete-taikhoan", payload)
        .then((res) => res.data);
    },
    getMau: async () => {
      return await axiosApi()
        .post("api/category/viettel/get-mau")
        .then((res) => res.data);
    },
    addMau: async (payload) => {
      return await axiosApi()
        .post("api/category/viettel/add-mau", payload)
        .then((res) => res.data);
    },
    deleteMau: async (payload) => {
      return await axiosApi()
        .post("api/category/viettel/delete-mau", payload)
        .then((res) => res.data);
    },
  },
};
