import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Row,
  Col,
  Flex,
  Input,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { UploadOutlined } from "@ant-design/icons";
import { IoMdPrint } from "react-icons/io";
import BuyinToPrint from "components/BuyinToPrint";
import { useReactToPrint } from "react-to-print";
import { CardService } from "services/CardService";
import { Format_Date } from "layouts/ultis/common";
import { CongTyService } from "services/CongTyService";
import Search from "antd/es/input/Search";

export default function Settings() {
  const [data, setData] = useState([]);
  const [dataPrint, setDataPrint] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const refPrint = useRef(null);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [lydo, setLydo] = useState([]);

  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    MaLyDo: ",,",
    InputSearch: "",
    MaCT: 0,
    FormID: 285,
  });
  useEffect(() => {
    loadCategories();
    loadBuyin(filterCondition);
  }, []);

  const loadBuyin = async (_filter) => {
    setLoading(true);
    let _res = await CardService.getCardHistory(_filter);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    // let _resCT = await CongTyService.getCongTy();
    // setCompanies(_resCT.data);
    let _ld = await CardService.getLyDoCongDiem();
    setLydo(_ld?.data ?? []);
  };
  const columns = [
    {
      title: "Ngày hoá đơn",
      dataIndex: "NgayHoaDon",
      render: (item) => item && Format_Date(item),
    },
    {
      title: "Số hoá đơn",
      dataIndex: "SoHoaDon",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenKH",
    },
    {
      title: "Tiền nạp",
      dataIndex: "TienNap",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền cộng thêm",
      // dataIndex: "TienNap",
      render: (item) =>
        Format_Currency((item.TienNap * (item.PTCongTienNap ?? 0)) / 100),
    },

    {
      title: "Lý do",
      dataIndex: "MaLyDo",
      render: (item) => lydo?.find((v) => v.ID === item)?.LyDo,
    },
    {
      title: "Mã thẻ",
      dataIndex: "SoThe",
    },
    {
      title: "Loại thẻ",
      dataIndex: "TenLT",
    },
    {
      title: "Số tiền",
      dataIndex: "Tien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Điểm",
      dataIndex: "Diem",
    },
    {
      title: "Ghi chú",
      dataIndex: "GhiChu",
    },
    {
      title: "",
      fixed: "right",
      width: "50px",
      render: (item) => {
        return (
          <div>
            {/* <Button
              type="primary"
              style={{ marginRight: 5 }}
              ghost
              icon={<IoMdPrint />}
              onClick={() => {
                setDataPrint(item);
              }}
            /> */}
            <Popconfirm
              title="Xoá bàn"
              description="Bạn có chắc chắn muốn xoá buyin hay không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item)}
            >
              <Button danger ghost icon={<CiTrash />} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if (dataPrint) handlePrint();
  }, [dataPrint]);
  const handlePrint = useReactToPrint({
    content: () => refPrint.current,
  });
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    // let _res = await BuyinService.deleteBuyin({
    //   MaGD: Number(item.MaGD),
    //   ID: item.ID,
    // });
    // if (_res.status === 2000) {
    //   let _data = _.cloneDeep(data);
    //   let fil = _data.filter((v) => v.ID !== Number(item.ID));
    //   setData(fil);
    //   openNotification("success", "topRight", "Xoá buyin thành công");
    // } else {
    //   openNotification("error", "topRight", _res?.message);
    // }
  };
  const refDetail = useRef(null);
  const onAfterSubmit = () => {
    loadBuyin(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    loadBuyin(_filter);
    setFilterCondition(_filter);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách buyin" + fileExtension);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
    loadBuyin(_ft);
  };
  return (
    <Row gutter={15}>
      <Col span={5}>
        <FilterModal
          lydo={lydo}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={19}>
        <Toolbar
          left={() => (
            <Input
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              placeholder="Tìm kiếm"
              loading={loading}
              onChange={(e) => onSearch(e.target.value)}
              // enterButton
            />
          )}
          right={() => (
            <Flex justify="flex-end">
              <Button type="primary" onClick={() => refDetail.current.show()}>
                Thêm
              </Button>
              <Button
                icon={<UploadOutlined />}
                onClick={() => exportToCSV()}
                style={{ marginLeft: 10 }}
              >
                Export
              </Button>
            </Flex>
          )}
        />

        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 1500,
          }}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <p style={{ fontWeight: "bold" }}>Tổng</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "TienNap")))}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}> </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}> </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}> </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "Tien")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100", "500", "1000"],
          }}
        />
        <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
        <div style={{ display: "none" }}>
          <BuyinToPrint
            ref={refPrint}
            data={dataPrint}
            company={companies?.length > 0 && companies[0]}
            soTT={dataPrint?.SoTT}
          />
        </div>
        {contextHolder}
      </Col>
    </Row>
  );
}
