import React, { useEffect, useRef, useState } from "react";
import { Table, notification, Row, Col, Checkbox, Button } from "antd";
import FilterModal from "./FilterModal";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import "./scss/index.scss";
import Toolbar from "components/Toolbar";
import { ReportService } from "services/ReportService";
import ExcelJS from "exceljs";
import { CRMService } from "services/CRMService";
import { Format_Datetime } from "layouts/ultis/common";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    Type: ",,",
  });

  useEffect(() => {
    filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await CRMService.GetLichSu(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const columns = [
    {
      title: "Ngày gửi",
      dataIndex: "DateCreate",
      render: (item) => Format_Datetime(item),
    },
    {
      title: "Loại",
      dataIndex: "Type",
    },
    {
      title: "Trạng thái",
      dataIndex: "Code",
    },
    {
      title: "Log",
      dataIndex: "Log",
    },
    {
      title: "Tên ",
      dataIndex: "Name",
    },
    {
      title: "Tiêu đề",
      dataIndex: "NameTemp",
    },

    {
      title: "Số tiền",
      dataIndex: "SoTien",
      render: (item) => Format_Currency(item),
    },
  ];
const exportExcel = () => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("BaoCao");
  sheet.properties.defaultRowHeight = 20;
  sheet.columns = [
    {
      header: "Ngày bán hàng",
      key: "ngaybh",
      width: 15,
      font: {
        bold: true,
        size: 24,
      },
    },
    {
      header: "Số hoá đơn",
      key: "sohd",
      width: 20,
    },
    {
      header: "Khách hàng",
      key: "khachhang",
      width: 15,
    },
    {
      header: "Nhân viên",
      key: "nhanvien",
      width: 10,
    },
    {
      header: "Quầy bán",
      key: "quayban",
      width: 10,
    },
    {
      header: "Loại HĐ",
      key: "loaihd",
      width: 8,
    },
    {
      header: "Tổng tiền",
      key: "tongtien",
      width: 20,
      numFmt : '#.##0'
    },
    {
      header: "Tiền CK",
      key: "tienck",
      width: 20,
    },
    {
      header: "Tổng sau CK",
      key: "tongsauck",
      width: 20,
    },
  ];
  data?.map((item) => {
    sheet.addRow({
      ngaybh: Format_Date(item.NgayBH),
      nhanvien: item.HoTen,
      sohd: item.SoBH,
      khachhang: item.TenCongTy,
      quayban: item.TenQuay,
      loaihd: item.LoaiHD,
      tongtien: item.ThanhTien,
      tienck: item.TienCK,
      tongsauck: item.KhachTra,
    });
  });
  sheet.getCell("A1").numFmt = "# ?/?";

  sheet.addRow({
    ngaybh: "Tổng: " + data.length,
    nhanvien: "",
    quayban: "",
    quayonlineban: "",
    tongtien: _.sumBy(data, (o) => o.ThanhTien),
    tienck: _.sumBy(data, (o) => o.TienCK),
    tongsauck: _.sumBy(data, (o) => o.KhachTra),
  });
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "baocaobanhangtheongay.xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          right={() => (
            <Button type="primary" onClick={() => exportExcel()}>
              Export
            </Button>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            // x: 2500
          }}
          size="small"
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100", "500", "5000"],
          }}
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={6}>
                    <p style={{ fontWeight: "bold" }}>Tổng: {pageData.length}</p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(_.sum(_.map(pageData, "SoTien")))}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
      {contextHolder}
    </Row>
  );
}
