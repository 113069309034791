import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Row,
  Col,
  DatePicker,
  Flex,
  Select,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import { Format_Currency } from "layouts/ultis/common";
import Chart from "react-apexcharts";
import { BanHangService } from "services/BanHangService";
import "./scss/index.scss";
import VeOnOff from "./Home/VeOnOff";
import locale from "antd/es/date-picker/locale/vi_VN";
import VeBan from "./Home/VeBan";
import VeBanTheoNgay from "./Home/VeBanTheoNgay";
import VeBanTBTheoGio from "./Home/VeBanTBTheoGio";

export default function Settings() {
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    Date1: new Date(),
    Date2: new Date(),
    Date3: new Date(),
  });
  const optionsDefault = {
    chart: {
      id: "apexchart-example",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
        type: "number",
        labels: {
          formatter: function (value) {
            return Format_Currency(value);
          },
        },
    },
    yaxis: {
      type: "number",
      labels: {
        formatter: function (value) {
          return Format_Currency(value / 1000000) + " m";
        },
      },
    },
  };
  const seriesDefault = [
    {
      name: "Số tiền",
      data: [
        30, 40, 35, 50, 49, 60, 70, 91, 125, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3,
        4, 5, 6, 7, 8, 2, 2, 3, 4,
      ],
    },
  ];
  const [options, setOptions] = useState(optionsDefault);
  const [series, setSeries] = useState(seriesDefault);
  useEffect(() => {
    loadReport1(filterCondition);
    loadReport2(filterCondition);
    loadReport3(filterCondition);
  }, []);
  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }
  const loadReport1 = async (_filter) => {
    let _res = await BanHangService.reportDoanhThuTheoNgay({
      Date: _filter.Date1,
    });
    let days = getDaysInMonth(
      new Date(_filter.Date1).getFullYear(),
      new Date(_filter.Date1).getMonth()
    );
    let ngay = [];
    let tien = [];
    for (let i = 1; i <= days; i++) {
      ngay.push(i);
      let v = _res?.data?.filter((v) => v.Ngay === i);
      tien.push(_.sumBy(v, (o) => o.ThanhTien));
    }
    let _option = _.cloneDeep(options);
    _option.xaxis.categories = ngay;
    setOptions(_option);
    setSeries([
      {
        name: "Số tiền",
        data: tien,
      },
    ]);
  };
  const options2Default = {
    chart: {
      id: "apexchart-example2",
    },
    colors: ["#FF7F00"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    xaxis: {
      categories: [1, 2, 3, 4, 5],
      type: "number",
      labels: {
        formatter: function (value) {
          return Format_Currency(value/1000000)+" m";
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Format_Currency(value);
        },
      },
    },
  };
  const series2Default = [
    {
      name: "Số tiền",
      data: [0, 0, 0, 0, 0],
    },
  ];
  const [options2, setOptions2] = useState(options2Default);
  const [series2, setSeries2] = useState(series2Default);
  const loadReport2 = async (_filter) => {
    let _res = await BanHangService.reportTopSPbyDoanhThu({
      Date: _filter.Date2,
    });
    let tien = [];
    let ten = [];
    let _option = _.cloneDeep(options2);
    _.orderBy(_res?.data, ["ThanhTien"], ["desc"])?.map((item, i) => {
      if (i > 4) return;
      tien.push(item.ThanhTien);
      ten.push(item.TenSP);
    });
    _option.xaxis.categories = ten;
    setOptions2(_option);
    setSeries2([
      {
        name: "Số tiền",
        data: tien,
      },
    ]);
  };
  const options3Default = {
    chart: {
      id: "apexchart-example2",
    },
    colors: ["#FF7F00"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    xaxis: {
      categories: [1, 2, 3, 4, 5],
      type: "number",
      labels: {
        formatter: function (value) {
          return Format_Currency(value);
        },
      },
    },
  };
  const series3Default = [
    {
      name: "Số tiền",
      data: [0, 0, 0, 0, 0],
    },
  ];
  const [options3, setOptions3] = useState(options3Default);
  const [series3, setSeries3] = useState(series3Default);
  const loadReport3 = async (_filter) => {
    let _res = await BanHangService.reportTopSPbySoLuong({
      Date: _filter.Date3,
    });
    let tien = [];
    let ten = [];
    let _option = _.cloneDeep(options3);
    _.orderBy(_res?.data, ["SoLuong"], ["desc"])?.map((item, i) => {
      if (i > 4) return;
      tien.push(item.SoLuong);
      ten.push(item.TenSP);
    });
    _option.xaxis.categories = ten;
    setOptions3(_option);
    setSeries3([
      {
        name: "Số lượng",
        data: tien,
      },
    ]);
  };

  const applyChange = (p, v) => {
    let _fil = _.cloneDeep(filterCondition);
    console.log(v);
    switch (p) {
      case "Date1":
        _fil[p] = v;
        loadReport1(_fil);
        break;
      case "Date2":
        _fil[p] = v;
        loadReport2(_fil);
        break;
      case "Date3":
        _fil[p] = v;
        loadReport3(_fil);
        break;
    }
    setFilterCondition(_fil);
  };
  return (
    <Box className="index-report">
      <Row gutter={[10]} style={{ marginBottom: 10 }}>
        <Col span={4}  >
          <VeBan />
        </Col>
        <Col span={8}>
          <VeBanTheoNgay />
        </Col>
        <Col span={8}>
          <div className="box">
            <Flex gap="middle" justify="space-between">
              <p style={{ fontWeight: "bold" }}>Doanh thu theo ngày</p>
              {/* <Select
                style={{
                  width: "30%",
                }}
                // value={data?.MaQD}
                // placeholder="Chọn danh xưng"
                // onChange={(v) => applyChange("MaQD", v)}
                // options={quyDanh}
                fieldNames={{ label: "TenQD", value: "MaQD" }}
              /> */}
              <DatePicker
                allowClear={false}
                format="MM-YYYY"
                style={{ width: "120px" }}
                defaultValue={dayjs(filterCondition.Date1)}
                picker="month"
                locale={locale}
                // value={data?.NgaySinh ? dayjs(data?.NgaySinh) : null}
                // placeholder="Ngày sinh"
                // locale={vi}
                onChange={(v) => applyChange("Date1", v)}
              />
            </Flex>
            <Chart
              options={options}
              series={series}
              type="bar"
              width="700px"
              className="line-chart"
              height={300}
            />
          </div>
        </Col>

        <Col span={4}>
          <VeOnOff />
        </Col>
      </Row>
      <Row gutter={[10]}>
        <Col span={8}>
          <div className="box">
            <Flex
              gap="middle"
              justify="space-between"
              style={{ marginRight: "15px" }}
            >
              <p style={{ fontWeight: "bold" }}>
                Top sản phẩm bán chạy theo doanh thu
              </p>
              <DatePicker
                allowClear={false}
                format="MM-YYYY"
                style={{ width: "120px" }}
                defaultValue={dayjs(filterCondition.Date2)}
                picker="month"
                locale={locale}
                onChange={(v) => applyChange("Date2", v)}
              />
            </Flex>

            <Chart
              options={options2}
              series={series2}
              type="bar"
              width="100%"
              height={300}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="box">
            <Flex
              gap="middle"
              justify="space-between"
              style={{ marginRight: "15px" }}
            >
              <p style={{ fontWeight: "bold" }}>
                Top sản phẩm bán chạy theo số lượng
              </p>
              <DatePicker
                allowClear={false}
                format="MM-YYYY"
                style={{ width: "120px" }}
                defaultValue={dayjs(filterCondition.Date3)}
                picker="month"
                locale={locale}
                onChange={(v) => applyChange("Date3", v)}
              />
            </Flex>
            <Chart
              options={options3}
              series={series3}
              type="bar"
              width="100%"
              height={300}
            />
          </div>
        </Col>
        <Col span={8}>
         <VeBanTBTheoGio />
        </Col>
      </Row>
    </Box>
  );
}
