import axiosApi from "./api_helper";
export const CardService = {
  getHoaDonVeThang: async (payload) => {
    return await axiosApi()
      .post("api/card/get-hoadonvethang-bymathe", payload)
      .then((res) => res.data);
  },

  getDetailGiaHan: async (payload) => {
    return await axiosApi()
      .post("api/card/get-byid", payload)
      .then((res) => res.data);
  },
  giaHanThe: async (payload) => {
    return await axiosApi()
      .post("api/card/gia-han", payload)
      .then((res) => res.data);
  },
  import: async (payload) => {
    return await axiosApi()
      .post("api/card/import", payload)
      .then((res) => res.data);
  },
  getKhoTheCSD: async (payload) => {
    return await axiosApi()
      .post("api/card/get-khothe-csd", payload)
      .then((res) => res.data);
  },
  getKhoThe: async (payload) => {
    return await axiosApi()
      .post("api/card/get-khothe", payload)
      .then((res) => res.data);
  },
  getByMaKH: async (payload) => {
    return await axiosApi()
      .post("api/card/get-bymakh", payload)
      .then((res) => res.data);
  },
  getSoDu: async (payload) => {
    return await axiosApi()
      .post("api/card/get-sodu", payload)
      .then((res) => res.data);
  },
  getCardHistoryByMaThe: async (payload) => {
    return await axiosApi()
      .post("api/card/history-bymathe", payload)
      .then((res) => res.data);
  },
  getBanHangByMaThe: async (payload) => {
    return await axiosApi()
      .post("api/card/banhang-bymathe", payload)
      .then((res) => res.data);
  },
  getBanHangLSTruThe: async (payload) => {
    return await axiosApi()
      .post("api/card/banhang-lstruthe", payload)
      .then((res) => res.data);
  },
  getCardHistory: async (payload) => {
    return await axiosApi()
      .post("api/card/history", payload)
      .then((res) => res.data);
  },
  addCardHistory: async (payload) => {
    return await axiosApi()
      .post("api/card/add-history", payload)
      .then((res) => res.data);
  },
  getLyDoCongDiem: async (payload) => {
    return await axiosApi()
      .post("api/card/lydo-congdiem", payload)
      .then((res) => res.data);
  },
  getDiem: async (payload) => {
    return await axiosApi()
      .post("api/card/get-diemtien-the", payload)
      .then((res) => res.data);
  },
  getLoaiTheDetail: async (payload) => {
    return await axiosApi()
      .post("api/card/get-cardtype-detail", payload)
      .then((res) => res.data);
  },

  getCard: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/card/get-list", payload)
      .then((res) => res.data);
  },

  getCardByMaVach: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/card/get-by-mavach", payload)
      .then((res) => res.data);
  },
  getKhachHangByID: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/customer/get-byid", payload)
      .then((res) => res.data);
  },
  filterCustomer: async (payload) => {
    return await axiosApi()
      .post("api/customer/filter", payload)
      .then((res) => res.data);
  },
  addCard: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/card/add", payload)
      .then((res) => res.data);
  },

  addLichSuRaVao: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/card/add-lichsu-ravao", payload)
      .then((res) => res.data);
  },
  getLichSuRaVao: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/card/get-lichsu-ravao", payload)
      .then((res) => res.data);
  },
  updateCard: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/card/update", payload)
      .then((res) => res.data);
  },
  deleteCard: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/card/delete", payload)
      .then((res) => res.data);
  },

  getCustomerGroup: async () => {
    //MaDA
    return await axiosApi()
      .post("api/customer/get-group")
      .then((res) => res.data);
  },

  // categories
  getType: async () => {
    //MaDA
    return await axiosApi()
      .post("api/card/get-type")
      .then((res) => res.data);
  },
  addCardType: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/card/categories/coru-card-type", payload)
      .then((res) => res.data);
  },
  deleteCardType: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/card/categories/delete-card-type", payload)
      .then((res) => res.data);
  },
  findChinhSach: async () => {
    return await axiosApi()
      .post("api/card/categories/find-chinhsach")
      .then((res) => res.data);
  },
  getChinhSach: async () => {
    return await axiosApi()
      .post("api/card/categories/get-chinhsach")
      .then((res) => res.data);
  },
  addChinhSach: async (payload) => {
    return await axiosApi()
      .post("api/card/categories/coru-chinhsach", payload)
      .then((res) => res.data);
  },
  deleteChinhSach: async (payload) => {
    return await axiosApi()
      .post("api/card/categories/delete-chinhsach", payload)
      .then((res) => res.data);
  },
  findUuDai: async (payload) => {
    return await axiosApi()
      .post("api/card/categories/find-uudai", payload)
      .then((res) => res.data);
  },
  getUuDai: async () => {
    return await axiosApi()
      .post("api/card/categories/get-uudai")
      .then((res) => res.data);
  },
  addUuDai: async (payload) => {
    return await axiosApi()
      .post("api/card/categories/coru-uudai", payload)
      .then((res) => res.data);
  },
  deleteUuDai: async (payload) => {
    return await axiosApi()
      .post("api/card/categories/delete-uudai", payload)
      .then((res) => res.data);
  },
};
