import axiosApi from "./api_helper";
export const NhanVienService = {
  getNhanVien: async () => {
    return await axiosApi()
      .post("api/nhanvien/get-list")
      .then((res) => res.data);
  },
  getDetail: async () => {
    return await axiosApi()
      .post("api/nhanvien/detail")
      .then((res) => res.data);
  },
  addNhanVien: async (payload) => {
    return await axiosApi()
      .post("api/nhanvien/add", payload)
      .then((res) => res.data);
  },
  updateNhanVien: async (payload) => {
    return await axiosApi()
      .post("api/nhanvien/update", payload)
      .then((res) => res.data);
  },
  deleteNhanVien: async (payload) => {
    return await axiosApi()
      .post("api/nhanvien/delete", payload)
      .then((res) => res.data);
  },

  getQuyDanh: async () => {
    return await axiosApi()
      .post("api/get-quy-danh")
      .then((res) => res.data);
  },
  getNhomQuyen: async () => {
    return await axiosApi()
      .post("api/nhanvien/get-nhom-quyen")
      .then((res) => res.data);
  },
  getAction: async (payload) => {
    return await axiosApi()
      .post("api/get-action", payload)
      .then((res) => res.data);
  },
  getLSChamCong: async (payload) => {
    return await axiosApi()
      .post("api/nhanvien/get-list-chamcong", payload)
      .then((res) => res.data);
  },
  getLSChamCongTongHop: async (payload) => {
    return await axiosApi()
      .post("api/nhanvien/get-list-chamcong-tonghop", payload)
      .then((res) => res.data);
  },

  ///categories
  // chức vụ
  getChucVu: async () => {
    return await axiosApi()
      .post("api/categories/get-chucvu")
      .then((res) => res.data);
  },
  addChucVu: async (payload) => {
    return await axiosApi()
      .post("api/categories/coru-chucvu", payload)
      .then((res) => res.data);
  },
  deleteChucVu: async (payload) => {
    return await axiosApi()
      .post("api/categories/delete-chucvu", payload)
      .then((res) => res.data);
  },
  // phòng ban
  getPhongBan: async () => {
    return await axiosApi()
      .post("api/categories/get-phongban")
      .then((res) => res.data);
  },
  addPhongBan: async (payload) => {
    return await axiosApi()
      .post("api/categories/coru-phongban", payload)
      .then((res) => res.data);
  },
  deletePhongBan: async (payload) => {
    return await axiosApi()
      .post("api/categories/delete-phongban", payload)
      .then((res) => res.data);
  },
  // nhóm kinh doanh
  getNhomKinhDoanh: async () => {
    return await axiosApi()
      .post("api/categories/get-nhomkinhdoanh")
      .then((res) => res.data);
  },
  addNhomKD: async (payload) => {
    return await axiosApi()
      .post("api/categories/coru-nhomkinhdoanh", payload)
      .then((res) => res.data);
  },
  deleteNKD: async (payload) => {
    return await axiosApi()
      .post("api/categories/delete-nhomkinhdoanh", payload)
      .then((res) => res.data);
  },
  changePass: async (payload) => {
    return await axiosApi()
      .post("api/ChangePassword", payload)
      .then((res) => res.data);
  },
  resetPass: async (payload) => {
    return await axiosApi()
      .post("api/ResetPassword", payload)
      .then((res) => res.data);
  },
};
