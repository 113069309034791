import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import { Button, Drawer, Flex, Tag, notification, Popconfirm } from "antd";
import _ from "lodash";
import { useReactToPrint } from "react-to-print";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { BanHangService } from "services/BanHangService";
import InLaiVe from "./InLaiVe";
import { OtherService } from "services/OtherService";
const FormDetail = (props, ref) => {
  const [api, contextHolder] = notification.useNotification();
  // const componentRef = useRef();
  const refInLaiVe = useRef();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sanpham, setSanpham] = useState([]);
  const [the, setThe] = useState([]);
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      let _the = await BanHangService.getBHThe({ MaBH: item.ID });
      let _res = await BanHangService.getBHSanPHam({ MaBH: item.ID });
      let sp = [];
      _res.data?.map((item) => {
        let qr = _the.data?.filter(
          (v) => v.MaSP === item.MaSP && v.MaGDV === item.MaGDV
        );
        sp.push({
          ...item,
          QRCodes: _.map(qr, "MaVach"),
        });
      });
      setSanpham(sp);
      setThe(_the?.data);
      setOpen(true);
      setData(item);
    },
  }));
  const onClose = () => {
    setOpen(false);
  };
  const handlePrintLai = useReactToPrint({
    content: () => refInLaiVe.current,
  });
  const print = () => {
     OtherService.addLichSuThaoTac({
       Modules: "BanVe",
       SoGiaoDich: data?.SoBH,
       NoiDung: "In lại vé lẻ",
       LinkID: data?.ID,
     });
    handlePrintLai();
  };
  return (
    <Drawer
      title="Thông tin chi tiết vé"
      width="500px"
      onClose={onClose}
      open={open}
    >
      {contextHolder}
      <div style={{ margin: "10px 0px" }}>
        <p>
          Tên khách hàng:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {data.TenCongTy}
          </span>
        </p>
        <p>
          Di động:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {data.DiDong}
          </span>
        </p>
        <p>
          Email:
          <span style={{ fontWeight: "bold", fontSize: 14 }}>{data.Email}</span>
        </p>
      </div>
      <hr />
      <div style={{ margin: "10px 0px" }}>
        <p>
          Ngày bán vé:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {/* {Format_Datetime(data.NgayBH)} */}
            {new Date(data.NgayBH).getDate()}/{" "}
            {new Date(data.NgayBH).getMonth() + 1}/{" "}
            {new Date(data.NgayBH).getFullYear()}
          </span>
        </p>
        <p>
          Tổng tiền:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {Format_Currency(data.ThanhTien)}
          </span>
        </p>
        <p>
          Số lượng vé:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {data.SoLuongThe}
          </span>
        </p>
        <p>
          Vé đã sử dụng:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {data.DaSuDung ?? 0}
          </span>
        </p>
        <p>
          Vé còn lại:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {data.SoLuongThe - data.DaSuDung}
          </span>
        </p>
        {/* <Tag
          style={{ fontSize: 16, padding: 10 }}
          color={stateColor[data.State]}
        >
          {state[data.State]}
        </Tag> */}
      </div>
      <hr />
      <div style={{ margin: "10px 0px" }}>
        <Flex
          justify="space-between"
          style={{ borderBottom: "1px dotted #dee2e6" }}
        >
          <p style={{ fontWeight: "600", width: "50%" }}>Gói bán(SL)</p>
          <p style={{ fontWeight: "600" }}>Mã thẻ</p>
          <p style={{ fontWeight: "600" }}>Đơn giá(đ)</p>
        </Flex>
        {the?.map((item) => {
          return (
            <Flex justify="space-between" style={{ margin: "5px 0px" }}>
              <div style={{ width: "50%" }}>
                <p style={{ marginTop: 0 }}>
                  {sanpham?.find((v) => v.MaSP === item.MaSP)?.TenSP}
                  {/* {sanpham?.find((v) => v.MaSP === item.MaSP)?.SoLuong} */}
                  <Tag
                    style={{ fontSize: 12, marginLeft: 10 }}
                    color={
                      item.DaQuet > 0 && !item.NgaySuDung ? "red" : "green"
                    }
                  >
                    {item.DaQuet ?? 0} / {item.SoLanQuet}
                  </Tag>
                </p>
                {item.DaQuet > 0 && !item.NgaySuDung && (
                  <p style={{ color: "red" }}>Vé huỷ</p>
                )}
              </div>
              <p>{item.MaVach}</p>
              <p style={{ marginTop: 0, fontWeight: "bold" }}>
                {Format_Currency(
                  sanpham?.find((v) => v.MaSP === item.MaSP)?.DonGia *
                    sanpham?.find((v) => v.MaSP === item.MaSP)?.SoLuong
                )}
              </p>
            </Flex>
          );
        })}
      </div>
      <hr />
      {data?.IsPrint && data?.State !== "RETURN" && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Button
            type="primary"
            style={{ marginRight: 5 }}
            loading={loading}
            onClick={() => print()}
          >
            In vé
          </Button>
        </div>
      )}

      {/* {(data.State === "PAYMENT" || data.State === "NEW") && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Popconfirm
            title="Duyệt vé"
            description="Bạn có chắc muốn duyệt vé này không?"
            onConfirm={confirm}
            okText="Đồng ý"
            cancelText="Huỷ"
          >
            <Button style={{ marginRight: 5 }} type="primary" loading={loading}>
              Duyệt
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Từ chối vé"
            description="Bạn có chắc muốn từ chối vé này không?"
            onConfirm={confirm2}
            okText="Đồng ý"
            cancelText="Huỷ"
          >
            <Button
              loading={loading}
              style={{ marginLeft: 5 }}
              type="primary"
              danger
              ghost
            >
              Từ chối
            </Button>
          </Popconfirm>
        </div>
      )} */}
      {/* <div style={{ display: "none" }}>
        <HoaDonToPrint ref={componentRef} data={{ SanPham: sanpham }} />
      </div> */}
      <div style={{ display: "none" }}>
        <InLaiVe
          ref={refInLaiVe}
          data={{ SanPham: sanpham, The: the }}
          Info={data}
        />
      </div>
    </Drawer>
  );
};
export default forwardRef(FormDetail);
