import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Drawer, Spin, notification } from "antd";
import _ from "lodash";
import { VisaService } from "services/VisaService";
import FormHoSoXuLy from "./FormHoSoXuLy.jsx";
const FormXuLy = (props, ref) => {
  const { onAfterSubmit } = props;
  const [open, setOpen] = useState(false);
  const [hoSo, setHoSo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState([]);
  const defaultData = {
    NgayNhanHS: new Date(),
  };
  const [data, setData] = useState(defaultData);
  const loadHoSoVisa = async (mavisa) => {
    setLoading(true);
    let _res = await VisaService.HoSo.getList({ MaVisa: mavisa });
    setHoSo(_res?.data ?? []);
    setLoading(false);
  };
  useImperativeHandle(ref, () => ({
    show: async (data,action) => {
      setOpen(true);
      loadHoSoVisa(data.ID);
      setData(data);
      setAction(action);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
    setHoSo([]);
  };
  return (
    <Drawer
      title={`Hồ sơ - ${data?.TenCongTy} - ${data?.DiDong}`}
      width={1420}
      onClose={onClose}
      open={open}
    >
      {loading && <Spin size="large" />}
      <FormHoSoXuLy
        hoSo={hoSo}
        setHoSo={setHoSo}
        maVisa={data?.ID}
        action={action}
      />
    </Drawer>
  );
};
export default forwardRef(FormXuLy);
