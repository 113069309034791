import React, { useEffect, useRef, useState } from "react";
import {
  DatePicker,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import "../scss/index.scss";
import locale from "antd/es/date-picker/locale/vi_VN";
import { ReportService } from "services/ReportService";

export default function VeBan() {
  const [filterCondition, setFilterCondition] = useState(new Date());
  const [data,setData] = useState({})
  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }
  const filter = async (filter) =>{
    let _res = await ReportService.getVeBanTheoThang({
      Date: filter,
    });
    let soluong = _.sum(_.map(_res.data,"SoLuong"));
    // let ngay = new Date(filter).getDate();
    let ngay = getDaysInMonth(
      new Date(filter).getFullYear(),
      new Date(filter).getMonth()
    );
    setData({
      SoLuong: soluong,
      TBNgay: Math.floor(soluong / ngay),
      TBGio: (soluong / (ngay*24)).toFixed(1),
    });
  }
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const applyChange = (v) => {
    let _fil = _.cloneDeep(filterCondition);
    _fil = v;
    filter(v);
    setFilterCondition(_fil);
  };
  return (
    <div className="box">
      <Flex gap="middle" justify="space-between">
        <p style={{ fontWeight: "bold" }}>Hoá đơn bán</p>
        <DatePicker
          allowClear={false}
          format="MM-YYYY"
          style={{ width: "80px" }}
          defaultValue={dayjs(filterCondition.Date1)}
          picker="month"
          locale={locale}
          onChange={(v) => applyChange(v)}
        />
      </Flex>
      <p style={{ textAlign: "center", fontWeight: "600", paddingTop: 10 }}>
        Số lượng hoá đơn
      </p>
      <p
        style={{
          fontWeight: "bold",
          fontSize: 25,
          textAlign: "center",
        }}
      >
        {data?.SoLuong}
      </p>
      <p style={{ textAlign: "center", fontWeight: "600", paddingTop: 20 }}>
        Hoá đơn bán trung bình / ngày
      </p>
      <p style={{ fontWeight: "bold", fontSize: 25, textAlign: "center" }}>
        {data?.TBNgay}
      </p>
      <p style={{ textAlign: "center", fontWeight: "600", paddingTop: 20 }}>
        Hoá đơn bán trung bình / giờ
      </p>
      <p style={{ fontWeight: "bold", fontSize: 25, textAlign: "center" }}>
        {data?.TBGio}
      </p>
    </div>
  );
}
