import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Image,
  Button,
  Flex,
} from "antd";
import _ from "lodash";
import { FileUpload } from "primereact/fileupload";
import { HomeService } from "services/HomeService";
import { SanPhamService } from "services/SanPhamService";
import { API_URL } from "services/api_helper";
import { API_UPLOAD_URL } from "services/UploadService";
import { UploadService } from "services/UploadService";

const FormAddImage = (props, ref) => {
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [makvc, setMakvc] = useState(0);
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      setMakvc(item.ID);
      let _res = await SanPhamService.getImageKVC({ MaKVC: item.ID });
      setData(_res.data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
  };
  const onBeforeUploadGD = async (v) => {
    const formData = new FormData();
    for (var i = 0; i < v.files.length; i++) {
      formData.append("Image", v.files[i]);
    }
    formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
    let _resupload = await UploadService.upload(formData);
    if (_resupload.status === 2000) {
      let imgs = [];
      _resupload?.data?.map((item) => {
        imgs.push({ Link: API_UPLOAD_URL + item, MaKVC: makvc });
      });
      await SanPhamService.addImageKVC({
        Images: imgs,
      });
    }
    let _res = await SanPhamService.getImageKVC({ MaKVC: makvc });
    setData(_res.data);
  };
  const handleDeleteImage = async (id) => {
    let _res = await SanPhamService.deleteImageKVC({ ID: id });
    if(_res.status === 2000){
      let _data = _.cloneDeep(data);
      _data = _data.filter(v=>v.ID !== id);
      setData(_data);
    }
  };
  return (
    <Drawer
      title="Hình ảnh"
      width={1020}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <FileUpload
            // style={{ maxWidth: "100px", width: 100 }}
            mode="basic"
            multiple
            // name="demo[]"
            // url="/api/upload"
            accept="image/*"
            maxFileSize={1000000}
            onSelect={(e) => onBeforeUploadGD(e)}
            chooseLabel="Tải ảnh"
          />
          {/* <Button loading={loading} onClick={() => onSubmit()}>
            Lưu
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button> */}
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 20]}>
        {/* <Col span={24}> */}
        {/* <Image.PreviewGroup
            preview={{
              onChange: (current, prev) =>
                console.log(`current index: ${current}, prev index: ${prev}`),
            }}
          > */}
        {data?.map((item) => {
          return (
            <Col span={6} vertical>
              <Image width={200} style={{ height: 150 }} src={item.Link} />
              <Button
                danger
                loading={loading}
                onClick={() => handleDeleteImage(item.ID)}
              >
                Xoá
              </Button>
            </Col>
          );
        })}
        {/* </Image.PreviewGroup> */}
        {/* </Col> */}
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAddImage);
