import React, { useState, useImperativeHandle, useEffect } from "react";
import { Row, Col, notification, Select, Flex, Badge, message } from "antd";
import _ from "lodash";
import { SanPhamService } from "services/SanPhamService";
import { Format_Currency } from "layouts/ultis/common";
import { MdOutlineFastfood } from "react-icons/md";
import "../scss/index.scss";

const FormLeft = (props) => {
  const { applyChange, MaQuay } = props;
  const [loading, setLoading] = useState(false);
  const [sanpham, setSanpham] = useState([]);
  const [sanphamall, setSanphamall] = useState([]);
  const [loaisp, setLoaisp] = useState([]);
  const [banggia, setBanggia] = useState([]);
  const [bgActive, setBgActive] = useState(0);
  const [lspActive, setLspActive] = useState({ MaLSP: 0, TenLSP: "Tất cả" });
  const loadSanPham = async (maquay) => {
    let _sp = await SanPhamService.getList({ InputSearch: "" });
    //get bang gia
    let _bgtheoquay = await SanPhamService.Categories.getBangGiaTheoQuay({
      MaQuay: maquay,
    });
    setBanggia(_bgtheoquay.data);
    let mabgDefault = _bgtheoquay.data?.find((v) => v.MacDinh)?.ID;
    setBgActive(mabgDefault);
    let _sptheobg = await SanPhamService.Categories.getSanPhamByMaBG({
      InputSearch: "",
      MaBG: mabgDefault,
    });
    //end
    let _sptheoquay = await SanPhamService.getSanPhamTheoQuay({
      MaQuay: maquay,
    });
    let spquay = _sp.data.filter((v) =>
      _.map(_sptheoquay.data, "MaSP").includes(v.ID)
    );
    let _lsp = await SanPhamService.getLSP({ ApDung: true });
    let lsp = [{ MaLSP: 0, TenLSP: "Tất cả" }];
    let _sptheoloai = spquay.filter((v) =>
      _.map(_lsp.data, "MaLSP").includes(v.MaLSP)
    );
    let updateGia = _sptheoloai?.map((v) => {
      let sp = _sptheobg?.data?.find((a) => a.ID === v.ID);
      if (sp) {
        return { ...v, GiaBan: sp.GiaBan };
      } else return v;
    });
    setSanpham(updateGia);
    setSanphamall(updateGia);
    _lsp.data?.map((item) => {
      if (_sptheoloai?.some((v) => v.MaLSP === item.MaLSP)) lsp.push(item);
    });
    setLoaisp(lsp);
  };
  const applyChangeBangGia = async (v) => {
    setBgActive(v);
    let _sptheobg = await SanPhamService.Categories.getSanPhamByMaBG({
      InputSearch: "",
      MaBG: v,
    });
    let sanpham = _.cloneDeep(sanphamall);
    let updateGia = sanpham?.map((v) => {
      let sp = _sptheobg?.data?.find((a) => a.ID === v.ID);
      if (sp) {
        return { ...v, GiaBan: sp.GiaBan };
      } else return v;
    });
    setSanpham(updateGia);
    setSanphamall(updateGia);
  };
  useEffect(() => {
    if (MaQuay) {
      loadSanPham(MaQuay);
    }
  }, [MaQuay]);

  const onChangeLSP = (value) => {
    if (value.MaLSP === 0) {
      setSanpham(sanphamall);
    } else {
      let _sp = sanphamall?.filter((v) => v.MaLSP === value.MaLSP);
      setSanpham(_sp);
    }
    setLspActive(value);
  };
  const handleChooseSP = (v) => {
    let item = sanphamall.find((a) => a.ID === v);
    applyChange("ChooseClickSP", item);
  };
  const filterOptionSP = (input, option) => {
    return (option?.TenSP ?? "").toLowerCase().includes(input.toLowerCase());
  };
  return (
    <Col md={16} xs={24}>
      <Flex style={{ padding: "13px 0px" }} gap={10}>
        <Select
          value={bgActive}
          style={{
            width: "120px",
          }}
          fieldNames={{ label: "TenBangGia", value: "ID" }}
          onChange={(v) => applyChangeBangGia(v)}
          options={banggia}
        />
        <Select
          showSearch
          placeholder="Tìm kiếm sản phẩm"
          optionFilterProp="children"
          filterOption={filterOptionSP}
          style={{
            width: "30%",
          }}
          fieldNames={{ label: "TenSP", value: "ID" }}
          onChange={(v) => handleChooseSP(v)}
          options={sanphamall}
        />
      </Flex>
      <div className="right-box">
        <Flex
          align="center"
          gap={5}
          style={{
            marginBottom: 10,
            overflowX: "scroll",
            width: "100%",
          }}
        >
          {loaisp?.map((item) => {
            return (
              <Badge
                key={item.MaLSP}
                count={item.Total}
                onClick={() => onChangeLSP(item)}
              >
                <div
                  style={{
                    border: `1px solid #adc6ff`,
                    padding: "6px 15px",
                    cursor: "pointer",
                    borderRadius: 10,
                    background:
                      lspActive.MaLSP === item.MaLSP ? "#adc6ff" : null,
                    color: lspActive.MaLSP === item.MaLSP ? "black" : null,
                  }}
                >
                  <p style={{ whiteSpace: "nowrap" }}>{item.TenLSP}</p>
                </div>
              </Badge>
            );
          })}
        </Flex>
        <Row gutter={[10, 10]} align="top" className="right-box-detail">
          {sanpham.map((item) => {
            return (
              <Col xs={12} md={6} lg={4} xl={4}>
                <Row
                  style={{
                    border: "1px solid rgb(217, 217, 217)",
                    cursor: "pointer",
                    // padding: 5,
                    height: "200px",
                    borderRadius: 10,
                  }}
                  // gutter={[0, 10]}
                  onClick={() => applyChange("ChooseClickSP", item)}
                >
                  <Flex
                    style={{
                      height: 140,
                      width: "100%",
                      background: !item?.LinkImage && "#e6f0fa",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                    justify="center"
                    align="center"
                  >
                    {item?.LinkImage ? (
                      <img
                        style={{ height: "140px", padding: "15px" }}
                        src={item.LinkImage}
                      />
                    ) : (
                      <MdOutlineFastfood
                        style={{
                          height: "50px",
                          width: "22%",
                          color: "rgba(0, 102, 204, 0.5)",
                        }}
                      />
                    )}
                  </Flex>
                  {/* </Col> */}
                  <Flex
                    justify="center"
                    style={{
                      background: "rgba(0, 8, 16, 0.04)",
                      height: 60,
                      width: "100%",
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "11px",
                        paddingTop: 5,
                      }}
                    >
                      {item.TenSP}
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "#0066CC",
                        position: "absolute",
                        top: 120,
                        right: "33%",
                        background: "rgba(255, 255, 255, 0.7)",
                        padding: 4,
                        borderRadius: 5,
                      }}
                    >
                      {Format_Currency(item.GiaBan)}
                    </p>
                  </Flex>
                </Row>
              </Col>
            );
          })}
        </Row>
      </div>
    </Col>
  );
};
export default React.memo(FormLeft);
