import axiosApi from "./api_helper";
export const BanHangService = {
  Categories: {
    getLoaiHD: async () => {
      return await axiosApi()
        .post("api/banhang/get-loaihd")
        .then((res) => res.data);
    },
    getKhuVuc: async (payload) => {
      return await axiosApi()
        .post("api/banhang/khu-vuc", payload)
        .then((res) => res.data);
    },
    addKhuVuc: async (payload) => {
      return await axiosApi()
        .post("api/banhang/add-khu-vuc", payload)
        .then((res) => res.data);
    },
    deleteKhuVuc: async (payload) => {
      return await axiosApi()
        .post("api/banhang/delete-khu-vuc", payload)
        .then((res) => res.data);
    },
    getBan: async (payload) => {
      return await axiosApi()
        .post("api/banhang/ban", payload)
        .then((res) => res.data);
    },
    addBan: async (payload) => {
      return await axiosApi()
        .post("api/banhang/add-ban", payload)
        .then((res) => res.data);
    },
    deleteBan: async (payload) => {
      return await axiosApi()
        .post("api/banhang/delete-ban", payload)
        .then((res) => res.data);
    },
  },
  getList: async (payload) => {
    return await axiosApi()
      .post("api/banhang/get-list", payload)
      .then((res) => res.data);
  },
  getListVeOnline: async (payload) => {
    return await axiosApi()
      .post("api/banhang/get-list-online", payload)
      .then((res) => res.data);
  },
  getDonHangTheoCa: async (payload) => {
    return await axiosApi()
      .post("api/banhang/get-donhang-theoca", payload)
      .then((res) => res.data);
  },

  addBanHang: async (payload) => {
    return await axiosApi()
      .post("api/banhang/add", payload)
      .then((res) => res.data);
  },
  addBanHangThe: async (payload) => {
    return await axiosApi()
      .post("api/banhang/add-the", payload)
      .then((res) => res.data);
  },
  reportDoanhThuTheoNgay: async (payload) => {
    return await axiosApi()
      .post("api/report/doanhthu-theongay", payload)
      .then((res) => res.data);
  },
  reportTopSPbyDoanhThu: async (payload) => {
    return await axiosApi()
      .post("api/report/topsp-bydoanhthu", payload)
      .then((res) => res.data);
  },
  reportTopSPbySoLuong: async (payload) => {
    return await axiosApi()
      .post("api/report/topsp-bysoluong", payload)
      .then((res) => res.data);
  },
  changeState: async (payload) => {
    return await axiosApi()
      .post("api/banhang/change-state", payload)
      .then((res) => res.data);
  },
  duyetVeBan: async (payload) => {
    return await axiosApi()
      .post("api/banhang/duyet-veban", payload)
      .then((res) => res.data);
  },
  xacNhanXuatVe: async (payload) => {
    return await axiosApi()
      .post("api/banhang/xacnhan-xuatve", payload)
      .then((res) => res.data);
  },
  getBHSanPHam: async (payload) => {
    return await axiosApi()
      .post("api/banhang/detail-sanpham", payload)
      .then((res) => res.data);
  },
  getBHThe: async (payload) => {
    return await axiosApi()
      .post("api/banhang/list-the", payload)
      .then((res) => res.data);
  },

  checkCaLamViec: async () => {
    return await axiosApi()
      .post("api/banhang/check-calamviec")
      .then((res) => res.data);
  },
  addCaLamViec: async (payload) => {
    return await axiosApi()
      .post("api/banhang/add-vaoca", payload)
      .then((res) => res.data);
  },
  addKetThucCa: async (payload) => {
    return await axiosApi()
      .post("api/banhang/add-ketthucca", payload)
      .then((res) => res.data);
  },
  addKetThucCa: async (payload) => {
    return await axiosApi()
      .post("api/banhang/add-ketthucca", payload)
      .then((res) => res.data);
  },
  getListCaLamViec: async (payload) => {
    return await axiosApi()
      .post("api/banhang/filter-calamviec", payload)
      .then((res) => res.data);
  },

  //danh muc
  getQuayBan: async () => {
    return await axiosApi()
      .post("api/banhang/quay-ban")
      .then((res) => res.data);
  },
  addQuayBan: async (payload) => {
    return await axiosApi()
      .post("api/banhang/add-quay-ban", payload)
      .then((res) => res.data);
  },
  deleteQuayBan: async (payload) => {
    return await axiosApi()
      .post("api/banhang/delete-quay-ban", payload)
      .then((res) => res.data);
  },
  BanTheoBan: {
    getList: async (payload) => {
      return await axiosApi()
        .post("api/banhang/get-list-theoban", payload)
        .then((res) => res.data);
    },
    check: async (payload) => {
      return await axiosApi()
        .post("api/banhang/check-theoban", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/banhang/add-theoban", payload)
        .then((res) => res.data);
    },
    updateDienGiai: async (payload) => {
      return await axiosApi()
        .post("api/banhang/update-diengiai", payload)
        .then((res) => res.data);
    },
    addSanPham: async (payload) => {
      return await axiosApi()
        .post("api/banhang/add-sanpham", payload)
        .then((res) => res.data);
    },
    updateSanPham: async (payload) => {
      return await axiosApi()
        .post("api/banhang/sanpham-update", payload)
        .then((res) => res.data);
    },
    update: async (payload) => {
      return await axiosApi()
        .post("api/banhang/update-theoban", payload)
        .then((res) => res.data);
    },
    pay: async (payload) => {
      return await axiosApi()
        .post("api/banhang/pay-theoban", payload)
        .then((res) => res.data);
    },
    cancel: async (payload) => {
      return await axiosApi()
        .post("api/banhang/cancel", payload)
        .then((res) => res.data);
    },
    changeTable: async (payload) => {
      return await axiosApi()
        .post("api/banhang/change-table", payload)
        .then((res) => res.data);
    },
    ghepTable: async (payload) => {
      return await axiosApi()
        .post("api/banhang/merge-table", payload)
        .then((res) => res.data);
    },
    addLichSuTinhGio: async (payload) => {
      return await axiosApi()
        .post("api/banhang/add-lichsu-tinhgio", payload)
        .then((res) => res.data);
    },
    getLichSuTinhGio: async (payload) => {
      return await axiosApi()
        .post("api/banhang/get-lichsu-tinhgio", payload)
        .then((res) => res.data);
    },
  },
  BanVeKinhDoanh: {
    getList: async (payload) => {
      return await axiosApi()
        .post("api/banhang/get-list-kinhdoanh", payload)
        .then((res) => res.data);
    },
    addBanHang: async (payload) => {
      return await axiosApi()
        .post("api/banhang/add-ve-kinhdoanh", payload)
        .then((res) => res.data);
    },
    updateBanHang: async (payload) => {
      return await axiosApi()
        .post("api/banhang/update-bhkinhdoanh", payload)
        .then((res) => res.data);
    },
    deleteSPBanHang: async (payload) => {
      return await axiosApi()
        .post("api/banhang/delete-sanpham", payload)
        .then((res) => res.data);
    },
  },
  BanVeThang: {
    duyet: async (payload) => {
      return await axiosApi()
        .post("api/card/duyet-giahan", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/banhang/add-ve-thang", payload)
        .then((res) => res.data);
    },
    editVe: async (payload) => {
      return await axiosApi()
        .post("api/card/update", payload)
        .then((res) => res.data);
    },
    addLuotVe: async (payload) => {
      return await axiosApi()
        .post("api/banhang/themluot-the-thang", payload)
        .then((res) => res.data);
    },
    getLichSuThemLuotVe: async (payload) => {
      return await axiosApi()
        .post("api/banhang/get-lichsu-themluot", payload)
        .then((res) => res.data);
    },
    getList: async (payload) => {
      return await axiosApi()
        .post("api/banhang/get-list-vethang", payload)
        .then((res) => res.data);
    },
    getListGiaHan: async (payload) => {
      return await axiosApi()
        .post("api/card/get-giahan", payload)
        .then((res) => res.data);
    },
    getListHisQuetVeDetail: async (payload) => {
      return await axiosApi()
        .post("api/banhang/lich-su-quet-the-thang-detail", payload)
        .then((res) => res.data);
    },
    getListHisQuetVe: async (payload) => {
      return await axiosApi()
        .post("api/banhang/lich-su-quet-the-thang", payload)
        .then((res) => res.data);
    },
    import: async (payload) => {
      return await axiosApi()
        .post("api/banhang/import-ve-thang", payload)
        .then((res) => res.data);
    },
  },
  Viettel: {
    addHoaDon: async (payload) => {
      return await axiosApi()
        .post("api/banhang/add-hoadon-viettel", payload)
        .then((res) => res.data);
    },
    updateHoaDon: async (payload) => {
      return await axiosApi()
        .post("api/banhang/update-hoadon-viettel", payload)
        .then((res) => res.data);
    },
    getHoaDon: async (payload) => {
      return await axiosApi()
        .post("api/banhang/get-hoadon-viettel", payload)
        .then((res) => res.data);
    },
    getHoaDonDetail: async (payload) => {
      return await axiosApi()
        .post("api/banhang/get-hoadon-viettel-detail", payload)
        .then((res) => res.data);
    },
  },
};
