/* eslint-disable */
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import { Menu } from "antd";
import { MailOutlined } from "@ant-design/icons";
import _ from "lodash";
export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let history = useHistory();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const onOpenChange = (keys) => {
    // const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    // if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    //   setOpenKeys(keys);
    // } else {
    //   setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    // }
  };
  const onClickMenu = (v) => {
    if (v.key === "report-other") {
      window.open(window.location.origin + "#/report");
    } else {
      history.push("/admin/" + v.key);
    }
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    let _menu = JSON.parse(localStorage.getItem("menu"));
    return routes.map((route, index) => {
      if (route.category) {
        return (
          <>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight="bold"
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt="18px"
              pb="12px"
              key={index}
            >
              {route.name}
            </Text>
            {createLinks(route.items)}
          </>
        );
      } else if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        return (
          _menu?.some((v) => v.FormID === route.id) && (
            <NavLink key={index} to={route.layout + route.path}>
              {route.icon ? (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                  >
                    <Flex w="100%" alignItems="center" justifyContent="center">
                      <Box
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeIcon
                            : textColor
                        }
                        me="18px"
                      >
                        {route.icon}
                      </Box>
                      <Text
                        me="auto"
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeColor
                            : textColor
                        }
                        fontWeight={
                          activeRoute(route.path.toLowerCase())
                            ? "bold"
                            : "normal"
                        }
                      >
                        {route.name}
                      </Text>
                    </Flex>
                    <Box
                      h="36px"
                      w="4px"
                      bg={
                        activeRoute(route.path.toLowerCase())
                          ? brandColor
                          : "transparent"
                      }
                      borderRadius="5px"
                    />
                  </HStack>
                </Box>
              ) : (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                  >
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                    >
                      {route.name}
                    </Text>
                    <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                  </HStack>
                </Box>
              )}
            </NavLink>
          )
        );
      }
    });
  };
  //  BRAND
  const [menu, setMenu] = useState([]);
  // useEffect(() => {
  //   var menuCT = JSON.parse(localStorage.getItem("menu_congty"));
  //   let accessMenuCongTy = _.map(menuCT, "FormID");
  //   let congtylv1 = _.map(menuCT, "ModulIDLevel1");
  //   let accessMenu = _.map(JSON.parse(localStorage.getItem("menu")), "FormID");
  //   let menulv1 = _.map(
  //     JSON.parse(localStorage.getItem("menu")),
  //     "ModulIDLevel1"
  //   );
  //   let menu = [];
  //   routes?.map((item) => {
  //     if (!congtylv1.includes(item.id)) return;
  //     if (item.children) {
  //       if (menulv1.includes(item.id)) menu.push(item);
  //       // if (item.children) {
  //       item.children = item.children.filter(
  //         (v) => accessMenu?.includes(v.id) && accessMenuCongTy?.includes(v.id)
  //       );
  //       // }
  //     } else {
  //       if (accessMenu.includes(item.id) || item.id === 0) {
  //         menu.push(item);
  //       }
  //     }
  //   });
  //   setMenu(menu);
  // }, []);
  useEffect(() => {
    const menuCT = JSON.parse(localStorage.getItem("menu_congty"));
    const menuData = JSON.parse(localStorage.getItem("menu"));
    const accessMenuCongTy = menuCT?.map((item) => item.FormID);
    const congtylv1 = menuCT?.map((item) => item.ModulIDLevel1);
    const accessMenu = menuData?.map((item) => item.FormID);
    const menulv1 = menuData?.map((item) => item.ModulIDLevel1);
    const filteredRoutes = routes?.filter((item) => {
      if(item.id === 0) return true; 
      if (congtylv1?.includes(item.id)) {
        if (item.children) {
          item.children = item.children.filter(
            (v) =>
              accessMenu?.includes(v.id) && accessMenuCongTy?.includes(v.id)
          );
          return menulv1?.includes(item.id);
        } else {
          return accessMenu?.includes(item.id) || item.id === 0;
        }
      }
      return false;
    });

    setMenu(filteredRoutes);
  }, []);
  return (
    <Menu
      mode="horizontal"
      // openKeys={openKeys}
      // activeKey="danh-sach-giai"
      onOpenChange={onOpenChange}
      onSelect={(v) => onClickMenu(v)}
      style={{
        width: "100%",
        border: "none",
        background: "#bae0ff",
        color: "#000000",
      }}
      items={menu}
    />
  );
  //  return createLinks(routes);
}

export default SidebarLinks;
