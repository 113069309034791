import React, { useState, useEffect } from "react";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { BanHangService } from "services/BanHangService";
import { NhanVienService } from "services/NhanVienService";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";

const FilterModal = ({ ...props }) => {
  const { companies, filterCondition, onChangeFilter } = props;
  const [quay, setQuay] = useState([]);
  const [nhanvien, setNhanvien] = useState([]);
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "ListMaCT":
      case "QuayBan":
      case "NhanVien":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(() => {
    BanHangService.getQuayBan().then((res) => setQuay(res.data ?? []));
    NhanVienService.getNhanVien().then((res) => setNhanvien(res.data ?? []));
  }, []);
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
{/* 
      <div className="box-search">
        <p className="label">Từ ngày</p>
        <DatePicker
          format="DD-MM-YYYY"
          style={{ width: "100%" }}
          defaultValue={dayjs(filterCondition.TuNgay)}
          placeholder="Từ ngày"
          onChange={(v) => applyChangeFilter("TuNgay", v)}
          // locale={locale}
        />
        <p className="label" style={{ marginTop: 10 }}>
          Đến ngày
        </p>
        <DatePicker
          format="DD-MM-YYYY"
          style={{ width: "100%" }}
          defaultValue={dayjs(filterCondition.DenNgay)}
          placeholder="Đến ngày "
          // locale={locale}
          onChange={(v) => applyChangeFilter("DenNgay", v)}
        />
      </div> */}
      <div className="box-search">
        <p className="label">Quầy vé</p>
        <Select
          mode="multiple"
          placeholder="Chọn quầy vé"
          onChange={(v) => applyChangeFilter("QuayBan", v)}
          style={{
            width: "100%",
          }}
          options={quay}
          fieldNames={{ label: "TenQuay", value: "ID" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Nhân viên</p>
        <Select
          mode="multiple"
          placeholder="Chọn nhân viên"
          onChange={(v) => applyChangeFilter("NhanVien", v)}
          style={{
            width: "100%",
          }}
          options={nhanvien}
          fieldNames={{ label: "HoTen", value: "MaNV" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
