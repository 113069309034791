import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Flex } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import "../scss/index.scss";
import locale from "antd/es/date-picker/locale/vi_VN";
import { ReportService } from "services/ReportService";
import { Format_Currency } from "layouts/ultis/common";
import Chart from "react-apexcharts";

export default function VeBanTBTheoGio() {
  const [filterCondition, setFilterCondition] = useState(new Date());
  const optionsDefault = {
    chart: {
      id: "apexchart-example",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return  value.toFixed(1) //Format_Currency(value);
        },
      },
    },
  };
  const seriesDefault = [
    {
      name: "Hoá đơn TB:",
      data: [
        30, 40, 35, 50, 49, 60, 70, 91, 125, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3,
        4, 5
      ],
    },
  ];
  const [options, setOptions] = useState(optionsDefault);
  const [series, setSeries] = useState(seriesDefault);
  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }
  const filter = async (_filter) => {
    let _res = await ReportService.getVeBanTheoNgay({
      Date: _filter,
    });

    let days = getDaysInMonth(
      new Date(_filter).getFullYear(),
      new Date(_filter).getMonth()
    );
    let ngay = [];
    let soluong = [];
    for (let i = 1; i <= 24; i++) {
      ngay.push(i);
      let v = _res?.data?.filter((v) => v.Gio === i);
      soluong.push((_.sumBy(v, (o) => o.SoLuong) / 24).toFixed(1) );
    }
    let _option = _.cloneDeep(options);
    _option.xaxis.categories = ngay;
    setOptions(_option);
    setSeries([
      {
        name: "Số vé tb",
        data: soluong,
      },
    ]);
  };
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const applyChange = (v) => {
    let _fil = _.cloneDeep(filterCondition);
    _fil = v;
    filter(v);
    setFilterCondition(_fil);
  };
  return (
    <div className="box">
      <Flex gap="middle" justify="space-between">
        <p style={{ fontWeight: "bold" }}>Hoá đơn bán trung bình / giờ</p>
        <DatePicker
          allowClear={false}
          format="DD-MM-YYYY"
          style={{ width: "140px" }}
          defaultValue={dayjs(filterCondition)}
          locale={locale}
          onChange={(v) => applyChange( v)}
        />
      </Flex>
      <Chart
        options={options}
        series={series}
        type="line"
        width="600px"
        height={300}
        className="line-chart"
      />
    </div>
  );
}
