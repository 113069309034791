import { Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CongTyService } from "services/CongTyService";
import { PermissionService } from "services/PermissionService";
const ChiNhanhSelect = (props) => {
  const { onChange, FormID } = props;
  const [congty, setCongty] = useState([]);
  const refPer = useRef(null);
  useEffect(() => {
    PermissionService.checkQuyen({ FormID: FormID }).then((res) => {
      refPer.current = res.data;
      if (res.data == 1) {
        CongTyService.getCongTy().then((_ct) => {
          setCongty(_ct.data);
        });
      }
    });
  }, []);
  return (
    refPer.current === 1 && (
      <div className="box-search">
        <p className="label">Chi nhánh</p>
        <Select
          // mode="multiple"
          allowClear
          placeholder="Chọn chi nhánh"
          onChange={(v) => onChange(v)}
          style={{
            width: "100%",
          }}
          options={congty}
          fieldNames={{ label: "TenCTVT", value: "ID" }}
        />
      </div>
    )
  );
};
export default React.memo(ChiNhanhSelect);
