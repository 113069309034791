import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  InputNumber,
  Row,
  Col,
  Drawer,
  notification,
  Tabs,
  Select,
  DatePicker,
  Space,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import vi from "dayjs/locale/vi";
import _ from "lodash";
import DebounceSelect from "components/DebounceSelect";
import { FaTrashAlt } from "react-icons/fa";
import { SanPhamService } from "services/SanPhamService";
import TabPane from "antd/es/tabs/TabPane";
import { Format_Currency } from "layouts/ultis/common";
import { CustomerService } from "services/CustomerService";
import { BanHangService } from "services/BanHangService";
import { CardService } from "services/CardService";
import { OtherService } from "services/OtherService";
import HoaDonToPrint from "components/HoaDonToPrint";
import { useReactToPrint } from "react-to-print";
import { ContractService } from "services/ContractService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CiTrash } from "react-icons/ci";

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [loaihd, setLoaihd] = useState([]);
  const [trangthai, setTrangthai] = useState([]);
  const [loaitien, setLoaiTien] = useState([]);
  const [dvt, setDvt] = useState([]);
  const [sanpham, setSanpham] = useState([]);
  const componentRef = useRef();
  const defaulData = {
    SanPham: [],
  };
  const [data, setData] = useState(defaulData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState("0");
  const loadcategories = async () => {
    let _lhd = await ContractService.getLoaiHD();
    let _tt = await ContractService.getTrangThaiHD();
    let _lt = await ContractService.getLoaiTien();
    let _dvt = await SanPhamService.getDVT();
    let _sp = await SanPhamService.getList({ InputSearch: "" });
    setLoaihd(_lhd.data ?? []);
    setTrangthai(_tt.data ?? []);
    setLoaiTien(_lt.data ?? []);
    setDvt(_dvt.data ?? []);
    setSanpham(_sp.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadcategories();
    },
    update: async (data) => {
      setOpen(true);
      let _kh = await CustomerService.getByID({ ID: data.CusID });
      data.KhachHang = [
        {
          label: `${_kh?.data?.TenCongTy} - ${_kh?.data?.DiDong} - ${new Date(
            _kh?.data?.NgaySinh
          ).getDate()}/${
            new Date(_kh?.data?.NgaySinh).getMonth() + 1
          }/${new Date(_kh?.data?.NgaySinh).getFullYear()}`,
          value: _kh?.data?.MaKH,
        },
      ];
      let _sp = await ContractService.getListSP({ ID: data.ContractID });
      data.SanPham = _sp?.data ?? [];
      setData(data);
      loadcategories();
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "KhachHang":
        if (v?.length > 0) {
          _data[p] = v;
          _data.CusID = v[0].value;
        } else {
          _data[p] = [];
        }
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const applyChangeSP = async (p, v, index) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data.SanPham[index][p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = null;
    let id = _data.ContractID;
    if (_data.ContractID) {
      _res = await ContractService.update(_data);
    } else {
      _res = await ContractService.add(_data);
      id = _res.data;
      setData((pre) => {
        return { ...pre, ContractID: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ContractID ? "Sửa" : "Thêm"} hợp đồng thành công!`
      );
      if (close) {
        onClose();
      } else {
        let _sp = await ContractService.getListSP({ ID: id });
        setData((pre) => {
          return { ...pre, SanPham: _sp.data };
        });
      }
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchUserList(username) {
    return CustomerService.filter({ InputSearch: username }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenCongTy} - ${item.DiDong} - ${new Date(
          item.NgaySinh
        ).getDate()}/${new Date(item.NgaySinh).getMonth() + 1}/${new Date(
          item.NgaySinh
        ).getFullYear()}`,
        value: item.MaKH,
      }));
    });
  }
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
  };
  const renderHoaDon = () => {
    return (
      <Row gutter={[10, 10]}>
        <Col span={8} className="gutter-row">
          <p>Khách hàng</p>
          <DebounceSelect
            mode="multiple"
            value={data?.KhachHang}
            placeholder="Tìm kiểm khách hàng"
            fetchOptions={fetchUserList}
            onChange={(newValue) => applyChange("KhachHang", newValue)}
            style={{
              width: "100%",
            }}
          />
        </Col>
        <Col span={16}>
          <p>Tên hợp đồng</p>
          <Input
            placeholder="Tên hợp đồng"
            style={{ width: "100%" }}
            value={data?.ContractName}
            onChange={(v) => applyChange("ContractName", v.target.value)}
          />
        </Col>
        <Col span={8}>
          <p>Giá trị</p>
          <InputNumber
            suffix="vnđ"
            placeholder="Giá trị hợp đồng"
            style={{ width: "100%" }}
            value={data?.GiaTri}
            onChange={(v) => applyChange("GiaTri", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>

        <Col span={8}>
          <p>Ngày bắt đầu</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.NgayBD ? dayjs(data?.NgayBD) : null}
            locale={vi}
            onChange={(v) => applyChange("NgayBD", v)}
          />
        </Col>
        <Col span={8}>
          <p>Ngày ký</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.SigningDate ? dayjs(data?.SigningDate) : null}
            locale={vi}
            onChange={(v) => applyChange("SigningDate", v)}
          />
        </Col>
        <Col span={8}>
          <p>Ngày hiệu lực</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.EffectiveDate ? dayjs(data?.EffectiveDate) : null}
            locale={vi}
            onChange={(v) => applyChange("EffectiveDate", v)}
          />
        </Col>
        <Col span={8}>
          <p>Thời hạn</p>
          <InputNumber
            suffix="tháng"
            placeholder="Thời hạn"
            style={{ width: "100%" }}
            value={data?.TimeOut}
            onChange={(v) => applyChange("TimeOut", v)}
          />
        </Col>
        <Col span={8}>
          <p>Ngày hết hạn</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={data?.NgayHH ? dayjs(data?.NgayHH) : null}
            locale={vi}
            onChange={(v) => applyChange("NgayHH", v)}
          />
        </Col>
        <Col span={8}>
          <p>Loại hợp đồng</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.CateID}
            placeholder="Chọn loại hợp đồng"
            onChange={(v) => applyChange("CateID", v)}
            options={loaihd}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={8}>
          <p>Trạng thái</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.StatusID}
            placeholder="Chọn trạng thái"
            onChange={(v) => applyChange("StatusID", v)}
            options={trangthai}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={8}>
          <p>Loại tiền</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaLT}
            placeholder="Chọn loại tiền"
            onChange={(v) => applyChange("MaLT", v)}
            options={loaitien}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={24}>
          <p>Diễn giải</p>
          <Input
            placeholder="Nhập diễn giải"
            style={{ width: "100%" }}
            value={data?.Description}
            onChange={(v) => applyChange("Description", v.target.value)}
          />
        </Col>
      </Row>
    );
  };

  const renderCoCauGiai = () => {
    const addCoCau = () => {
      let _data = _.cloneDeep(data);
      _data.SanPham.push({
        SoLuong: 0,
      });
      setData(_data);
    };
    const onChangeCoCau = (p, v, index) => {
      let _data = _.cloneDeep(data);
      let item = _data.SanPham.slice(index, index + 1);
      if (item?.length > 0) {
        _data.SanPham.splice(index, 1, {
          ...item[0],
          [p]: v,
        });
      }
      setData(_data);
    };
    const deleteCoCau = (v, index) => {
      let _data = _.cloneDeep(data);
      _data.SanPham.splice(index, 1);
      setData(_data);
      if (v.ID) {
        ContractService.deleteSanPham({ ID: v.ID });
      }
    };
    const filterOption = (input, option) => {
      return (option?.TenSP ?? "").toLowerCase().includes(input.toLowerCase());
    };
    return (
      <>
        <Button
          loading={loading}
          onClick={() => addCoCau()}
          type="primary"
          ghost
          style={{ marginBottom: 10 }}
        >
          Thêm
        </Button>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={data.SanPham}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            header="Sản phẩm"
            style={{ width: "200px" }}
            body={(item, row) => {
              return (
                <Select
                  showSearch
                  filterOption={filterOption}
                  style={{
                    width: "100%",
                  }}
                  value={item?.MaSP}
                  placeholder="Chọn sản phẩm"
                  onChange={(v) => applyChangeSP("MaSP", v, row.rowIndex)}
                  options={sanpham}
                  fieldNames={{ label: "TenSP", value: "ID" }}
                />
              );
            }}
          />
          <Column
            header="ĐVT"
            body={(item, row) => {
              return (
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={item?.MaDVT}
                  placeholder="Chọn ĐVT"
                  onChange={(v) => applyChangeSP("MaDVT", v, row.rowIndex)}
                  options={dvt}
                  fieldNames={{ label: "TenDVT", value: "MaDVT" }}
                />
              );
            }}
          />
          <Column
            header="Số lượng"
            body={(item, row) => {
              return (
                // <Input
                //   value={item?.TenGiai}
                //   placeholder="Tìm sản phẩm"
                //   onChange={(e) =>
                //     onChangeCoCau("TenGiai", e.target.value, row.rowIndex)
                //   }
                // />
                <InputNumber
                  placeholder="Số lượng"
                  style={{ width: "100%" }}
                  value={item?.SoLuong}
                  onChange={(v) => applyChangeSP("SoLuong", v, row.rowIndex)}
                />
              );
            }}
          />
          <Column
            header="Đơn giá"
            body={(item, row) => {
              return (
                <InputNumber
                  placeholder="Đơn giá"
                  style={{ width: "100%" }}
                  value={item?.DonGia}
                  onChange={(v) => applyChangeSP("DonGia", v, row.rowIndex)}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              );
            }}
          />
          <Column
            header="Thành tiền"
            body={(item, row) => {
              return (
                <InputNumber
                  placeholder="Thành tiền"
                  style={{ width: "100%" }}
                  value={item?.ThanhTien}
                  onChange={(v) => applyChangeSP("ThanhTien", v, row.rowIndex)}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              );
            }}
          />

          <Column
            body={(item, row) => {
              return (
                <Button
                  onClick={() => deleteCoCau(item, row.rowIndex)}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              );
            }}
          />
        </DataTable>
      </>
    );
  };
  return (
    <Drawer
      title="Bán hàng"
      width="1020px"
      onClose={onClose}
      open={open}
      className="giai-dau-detail"
      styles={{
        body: {
          // paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(false)}
            type="primary"
            ghost
          >
            Lưu
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Tabs onChange={onChange} activeKey={activeTab}>
        <TabPane tab="Thông tin chung" key="0">
          {renderHoaDon()}
        </TabPane>
        <TabPane tab="Sản phẩm" key="1">
          {renderCoCauGiai()}
        </TabPane>
      </Tabs>
      <div style={{ display: "none" }}>
        <HoaDonToPrint ref={componentRef} data={data[activeTab]} />
      </div>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
