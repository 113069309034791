import React, { useEffect, useContext, useState } from "react";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import PortalLayout from "layouts/portal";
import OrderLayout from "layouts/orders";
import ReportLayout from "layouts/reports";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import './app.scss'
const App = () => {
  return (
    <ChakraProvider theme={theme}>
        <React.StrictMode>
          <ThemeEditorProvider>
            <HashRouter>
              <Switch>
                <Route path={`/auth`} component={AuthLayout} />
                <Route path={`/admin`} component={AdminLayout} />
                <Route path={`/portal`} component={PortalLayout} />
                <Route path={`/orders`} component={OrderLayout} />
                <Route path={`/report`} component={ReportLayout} />
                <Redirect from="/" to="/admin/danh-sach-giai" />
              </Switch>
            </HashRouter>
          </ThemeEditorProvider>
        </React.StrictMode>
    </ChakraProvider>
  );
};
export default App;
