import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
} from "antd";
import { CustomerService } from "services/CustomerService";
import { ColorPicker } from "primereact/colorpicker";
import _ from "lodash"
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async (tab) => {
      setOpen(true);
      setActiveTab(tab);
    },
    update: async (data,tab) => {
      setOpen(true);
      setData(data);
      setActiveTab(tab);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await  CustomerService.addStatus(_data);
        break;
      case "1":
        _res = await CustomerService.addGroup(_data);
        break;
      case "2":
        _res = await CustomerService.addNguon(_data);
        break;
    }

    setLoading(false);
    if (_res.status === 2000) {
      if (!_data.ID) {
        setData((pre) => {
          return { ...pre, ID: _res.data };
        });
      }
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} danh mục thành công!`
      );
      onAfterSubmit();
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Drawer
      title="Danh mục"
      width={520}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          {/* <Button loading={loading} onClick={() => onSubmit()}>
            Lưu
          </Button> */}
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên</p>
          <Input
            value={data?.Name}
            placeholder="Nhập tên danh mục"
            onChange={(e) => applyChange("Name", e.target.value)}
          />
        </Col>
        {activeTab !== "2" && (
          <Col span={12}>
            <p>Màu sắc</p>
            <ColorPicker
              format="hex"
              value={data?.Color}
              onChange={(e) => applyChange("Color", e.value)}
            />
          </Col>
        )}
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
