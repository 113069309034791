import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import * as React from "react";
import { NhanVienService } from "services/NhanVienService";
import "./scss/index.scss";
import _ from "lodash";
import { Flex } from "antd";
const InLaiVe = (props, ref) => {
  const { data, Info } = props;
  const [nhanvien, setNhanVien] = React.useState({});
  console.log(Info);
  React.useEffect(() => {
    NhanVienService.getDetail().then((res) => {
      setNhanVien(res.data ?? []);
    });
  }, []);

  return (
    <div
      ref={ref}
      className="print-container"
      style={{ margin: "0", padding: "0" }}
    >
      <div className="page-break" />
      <div style={{ height: "100vh", margin: "0 10px", padding: "0" }}>
        <div style={{ textAlign: "center", padding: "15px 0px" }}>
          <p style={{ fontSize: 13, fontWeight: "bold" }}>{nhanvien?.TenCT}</p>
          <p>{nhanvien?.DiaChi}</p>
          <p>{nhanvien?.DienThoai}</p>
        </div>
        <div style={{ padding: "10px 0px" }}>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            HOÁ ĐƠN BÁN HÀNG
          </p>
          <Flex justify="space-between">
            <p>Ngày: {Format_Date(new Date())}</p>
            <p>{Info?.SoBH}</p>
          </Flex>
          {/* <p>Ngày: {Format_Date(new Date())}</p> */}
          <Flex justify="space-between">
            <p>Thu ngân: {nhanvien?.HoTen}</p>
            <p>Khách hàng: {Info.TenCongTy}</p>
          </Flex>
          <Flex justify="space-between">
            <p>
              In lúc:{" "}
              {new Date().getHours() < 10
                ? "0" + new Date().getHours()
                : new Date().getHours()}
              :
              {new Date().getMinutes() < 10
                ? "0" + new Date().getMinutes()
                : new Date().getMinutes()}
            </p>
            <p>Quầy: {Info?.TenQuay}</p>
          </Flex>
        </div>
        <table style={{ width: "100%" }}>
          <tr
            style={{
              textAlign: "left",
              borderBottom: "2px solid black",
            }}
          >
            <th style={{ fontSize: 12 }}>Mặt hàng</th>
            <th style={{ fontSize: 12 }}>SL</th>
            <th style={{ fontSize: 12 }}>Đ.Giá</th>
            <th style={{ fontSize: 12 }}>T.Tiền</th>
          </tr>
          {data?.SanPham?.map((item) => {
            return (
              <tr>
                <td style={{ width: "40%", fontSize: 12 }}>{item.TenSP}</td>
                <td style={{ width: "10%", fontSize: 12 }}>{item.SoLuong}</td>
                <td style={{ width: "20%", fontSize: 12 }}>
                  {Format_Currency(item.DonGia)}
                </td>
                <td style={{ width: "20%", fontSize: 12 }}>
                  {Format_Currency(item.DonGia * item.SoLuong - item.TienCK)}
                </td>
              </tr>
            );
          })}
          <tfoot
            style={{
              padding: "10px 0px",
              borderTop: "1px solid gray",
            }}
          >
            <tr>
              <td style={{ fontSize: 12 }}>Tổng cộng</td>
              <td></td>
              <td></td>
              <td style={{ fontSize: 12 }}>
                {Format_Currency(
                  _.sumBy(data?.SanPham, (v) => v.SoLuong * v.DonGia)
                )}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: 12 }}>Chiết khấu</td>
              <td></td>
              <td></td>
              <td style={{ fontSize: 12 }}>
                {Format_Currency(_.sumBy(data?.SanPham, (v) => v.TienCK))}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold", fontSize: 13 }}>Tổng sau CK</td>
              <td></td>
              <td></td>
              <td style={{ fontWeight: "bold", fontSize: 13 }}>
                {Format_Currency(Info?.KhachTra)}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold", fontSize: 13 }}>Khách trả</td>
              <td></td>
              <td></td>
              <td style={{ fontWeight: "bold", fontSize: 13 }}>
                {Format_Currency(Info?.ThanhToan)}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold", fontSize: 13 }}>Tiền thừa</td>
              <td></td>
              <td></td>
              <td style={{ fontWeight: "bold", fontSize: 13 }}>
                {Format_Currency(Info?.ThanhToan - Info?.KhachTra)}
              </td>
            </tr>
          </tfoot>
        </table>
        <p
          style={{
            marginTop: 20,
            fontStyle: "italic",
            fontSize: 12,
            textAlign: "center",
          }}
        >
          Phiếu này có giá trị xuất hoá đơn đỏ trong ngày
        </p>
        <p
          style={{
            fontStyle: "italic",
            fontSize: 12,
            textAlign: "center",
          }}
        >
          Cảm ơn quý khách. Hẹn gặp lại!
        </p>
      </div>
      {data?.The?.filter((v) => v.IsUse === false)?.map((item) => {
        return (
          <>
            <div className="page-break" />
            <div style={{ height: "100vh" }}>
              <div style={{ textAlign: "center", padding: "0px 0px" }}>
                <p style={{ fontSize: 15, fontWeight: "bold" }}>
                  {nhanvien?.TenCT}
                </p>
                <p style={{ fontSize: 10 }}>{nhanvien?.DiaChi}</p>
                <p style={{ fontSize: 10 }}>{nhanvien?.DienThoai}</p>
              </div>
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  VÉ / TICKET
                </p>
              </div>
              <div style={{ textAlign: "center", padding: "0px 5px" }}>
                <p style={{ fontWeight: "bold" }}>
                  {" "}
                  {data?.SanPham?.find((v) => v.MaSP === item.MaSP)?.TenSP}
                </p>
                <Flex justify="space-between">
                  <p>
                    Giá:{" "}
                    {Format_Currency(
                      data?.SanPham?.find((v) => v.MaSP === item.MaSP)?.DonGia
                    )}
                  </p>
                  <p>Ngày SD: {Format_Date(Info?.NgayBH)}</p>
                </Flex>
                <Flex justify="space-between">
                  <p>Mã: {item.MaVach}</p>
                  <p>Ngày HH: {Format_Date(Info?.NgayBH)}</p>
                </Flex>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <img
                  src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${item.MaVach}`}
                />
              </div>
              <p
                style={{
                  fontStyle: "italic",
                  fontSize: 12,
                  textAlign: "center",
                  paddingTop: 10,
                }}
              >
                Cảm ơn quý khách. Hẹn gặp lại!
              </p>
            </div>
          </>
        );
      })}
    </div>
  );
};
export default React.forwardRef(InLaiVe);
