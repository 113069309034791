import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import * as React from "react";
import { NhanVienService } from "services/NhanVienService";
import "./index.scss";
import { Flex } from "antd";
import _ from "lodash";
import { Format_Datetime } from "layouts/ultis/common";
const LichSuCheckIn = (props, ref) => {
  const { data, filter } = props;
  const [nhanvien, setNhanVien] = React.useState({});
  React.useEffect(() => {
    NhanVienService.getDetail().then((res) => {
      setNhanVien(res.data ?? []);
    });
  }, []);

  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <div
        ref={ref}
        className="print-report"
        style={{
          width: filter?.Horizontal ? "1123px" : "794px",
          margin: "0",
          padding: "0 25px",
        }}
      >
        <div className="page-break" />
        <div style={{ margin: "0 10px", padding: "0" }}>
          <Flex justify="space-around" style={{ padding: "20px" }}>
            <img width={100} src={nhanvien.Logo} />
            <div style={{ textAlign: "center", padding: "15px 0px" }}>
              <p style={{ fontSize: 15, fontWeight: "bold" }}>
                {nhanvien?.TenCT}
              </p>
              <p>{nhanvien?.DiaChi}</p>
              <p>{nhanvien?.DienThoai}</p>
            </div>
          </Flex>
          <Flex justify="space-between">
            <div>
              <p>
                Từ ngày {Format_Date(filter.TuNgay)} Đến ngày{" "}
                {Format_Date(filter.DenNgay)}
              </p>
              <p>
                In lúc:{" "}
                {new Date().getHours() < 10
                  ? "0" + new Date().getHours()
                  : new Date().getHours()}
                :
                {new Date().getMinutes() < 10
                  ? "0" + new Date().getMinutes()
                  : new Date().getMinutes()}
              </p>
            </div>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              BÁO CÁO TỔNG HỢP CHECKIN, CHECKOUT
            </p>
          </Flex>
          {/* <p style={{ fontWeight: "bold" }}>LỊCH SỬ NẠP TIỀN</p> */}
          <table style={{ width: "100%" }}>
            <tr
              style={{
                textAlign: "left",
                borderBottom: "2px solid black",
              }}
            >
              <th style={{ fontSize: 12, width: "30px", padding: "6px 0px" }}>
                STT
              </th>
              <th style={{ fontSize: 12 }}>Mã nhân viên</th>
              <th style={{ fontSize: 12 }}>Nhân viên</th>
              <th style={{ fontSize: 12 }}>Số di động</th>
              <th style={{ fontSize: 12 }}>Số CMND</th>
              <th style={{ fontSize: 12 }}>Số giờ</th>
              <th style={{ fontSize: 12 }}>Tiền dự kiến</th>
            </tr>
            {data?.map((item, index) => {
              return (
                <tr style={{ borderBottom: "1px dotted gray" }}>
                  <td
                    style={{ width: "20px", fontSize: 12, padding: "6px 0px" }}
                  >
                    {index + 1}
                  </td>
                  <td style={{ fontSize: 12 }}>{item.MaSo}</td>
                  <td style={{ fontSize: 12 }}>{item.HoTen}</td>
                  <td style={{ fontSize: 12 }}>{item.DienThoai}</td>
                  <td style={{ fontSize: 12 }}>{item.SoCMND}</td>
                  <td style={{ fontSize: 12 }}>
                    {(item.SoPhut / 60).toFixed(2)}
                  </td>
                  <td style={{ fontSize: 12 }}>
                    {Format_Currency(
                      (item.SoPhut / 60).toFixed(2) * item.TienTrenGio
                    )}
                  </td>
                </tr>
              );
            })}
            <tfoot
              style={{
                padding: "10px 0px",
                borderTop: "1px solid gray",
              }}
            >
              <tr>
                <td colSpan={5} style={{ fontSize: 12, fontWeight: "bold" }}>
                  Tổng cộng
                </td>

                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {_.sumBy(data, (v) => Number((v.SoPhut / 60).toFixed(2)))}
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  {Format_Currency(
                    _.sumBy(data, (v) => Number((v.SoPhut / 60).toFixed(2))* v.TienTrenGio)
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
          <Flex justify="flex-end" style={{ padding: "30px 10%" }}>
            <div>
              <p style={{ textAlign: "center" }}>
                Ngày {new Date().getDate()} tháng {new Date().getMonth() + 1}{" "}
                năm {new Date().getFullYear()}
              </p>
              <p style={{ fontWeight: "bold", textAlign: "center" }}>
                Người lập
              </p>
              <p style={{ textAlign: "center", fontStyle: "italic" }}>
                (Ký , họ tên)
              </p>
            </div>
          </Flex>
        </div>
      </div>
    </div>
  );
};
export default React.forwardRef(LichSuCheckIn);
