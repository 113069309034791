import axiosApi from "./api_helper";

export const OrderService = {
  getProducts: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-products", payload)
      .then((res) => res.data);
  },
  checkout: async (payload) => {
    return await axiosApi()
      .post("api/poker/checkout", payload)
      .then((res) => res.data);
  },
  getTable: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/order-table", payload)
      .then((res) => res.data);
  },
  getOrders: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/orders", payload)
      .then((res) => res.data);
  },
  getOrderDetail: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/order-detail", payload)
      .then((res) => res.data);
  },
  payOrders: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/order-pay", payload)
      .then((res) => res.data);
  },
  changeStateOrders: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/order-change-state", payload)
      .then((res) => res.data);
  },
  getTableDetail: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/order-table-detail", payload)
      .then((res) => res.data);
  },
  addTable: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/add-order-table", payload)
      .then((res) => res.data);
  },
  removeTable: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/remove-order-table", payload)
      .then((res) => res.data);
  },
};
