import axiosApi from "./api_helper";
export const TaiLieuService = {
  getList: async (payload) => {
    return await axiosApi()
      .post("api/tai-lieu/get", payload)
      .then((res) => res.data);
  },
  add: async (payload) => {
    return await axiosApi()
      .post("api/tai-lieu/add", payload)
      .then((res) => res.data);
  },
  Loai: {
    getList: async () => {
      return await axiosApi()
        .post("api/tai-lieu/get-loai")
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/tai-lieu/add-loai", payload)
        .then((res) => res.data);
    },
  },
  File: {
    getList: async (payload) => {
      return await axiosApi()
        .post("api/tai-lieu/get-images", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/tai-lieu/add-images", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/tai-lieu/delete-images", payload)
        .then((res) => res.data);
    },
  },
};
