import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Badge,
  Row,
  Col,
  Input,
  Flex,
  Tag,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { Format_Date } from "layouts/ultis/common";
import { MdDocumentScanner, MdEdit } from "react-icons/md";
import { CongTyService } from "services/CongTyService";
import { VisaService } from "services/VisaService";
import { AiOutlineMenuFold } from "react-icons/ai";
import FormXuLy from "./FormXuLy";
import { DateTime } from "luxon";
import FormDetail from "./FormDetail";
import { NhanVienService } from "services/NhanVienService";

export default function Settings() {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [dataAll, setDataAll] = useState([]);
  const [data, setData] = useState([]);
  const [stateActive, setStateActive] = useState({
    ID: 0,
    TenTT: "Tất cả",
    Color: "gray",
  });
  const [status, setStatus] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const refDetail = useRef(null);
  const refAdd = useRef(null);
  const refXuLy = useRef(null);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [action, setAction] = useState([]);

  var date = new Date();
  let y = date.getFullYear();
  let m = date.getMonth();
  var firstDay = new Date(y, m, 1);
  var lastDay = new Date(y, m + 1, 0);
  const [filterCondition, setFilterCondition] = useState({
    InputSearch: "",
    TuNgay: DateTime.fromJSDate(firstDay, { zone: "utc" })
      .setZone("Asia/Ho_Chi_Minh")
      .toISO(),
    DenNgay: DateTime.fromJSDate(lastDay, { zone: "utc" })
      .setZone("Asia/Ho_Chi_Minh")
      .toISO(),
    LoaiSP: ",,",
  });
  useEffect(() => {
    loadCategories();
  }, []);

  const loadBuyin = async (_filter) => {
    setLoading(true);
    let _res = await VisaService.getList(_filter);
    let _tt = await VisaService.Categories.getTinhTrang();
    let status =
      [{ ID: 0, TenTT: "Tất cả", Color: "#cccccc" }, ..._tt.data] ?? [];
    status.map((item) => {
      if (item.ID === 0) {
        item.Total = _res.data?.length;
      } else {
        item.Total = _res?.data?.filter((v) => v.MaTT === item.ID)?.length;
      }
    });
    setStatus(status);
    setDataAll(_res.data);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    NhanVienService.getAction({ FormID: 425 }).then((res) =>
      setAction(res?.data)
    );
    // let _resCT = await CongTyService.getCongTy();
    // setCompanies(_resCT.data);
  };
  const checkAction = (id) => {
    return action?.some((v) => v.FeatureID === id);
  };
  const columns = [
    {
      title: "Tên khách hàng",
      width: 200,
      render: (item) => (
        <p
          style={{ fontWeight: "bold", color: "cadetblue", cursor: "pointer" }}
          onClick={() =>
            refDetail.current.update(
              item,
              status.filter((v) => v.ID !== 0)
            )
          }
        >
          {item.TenCongTy}
        </p>
      ),
    },
    {
      title: "Tình trạng",
      dataIndex: "MaTT",
      render: (item) => {
        let _item = status.find((v) => v.ID === item);
        console.log(_item);
        return <Tag color={_item.Color}>{_item?.TenTT}</Tag>;
      },
    },
    {
      title: "Ghi chú",
      dataIndex: "GhiChu",
    },
    {
      title: "Di động",
      dataIndex: "DiDong",
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      render: (item) => item && Format_Date(item),
    },

    {
      title: "Hộ chiếu",
      dataIndex: "SoHoChieu",
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChiCT",
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "TenSP",
    },
    {
      title: "Ngày nhận hồ sơ",
      dataIndex: "NgayNhanHS",
      render: (item) => Format_Date(item),
    },
    {
      title: "Ngày nộp ĐSQ",
      dataIndex: "NgayNopHS",
      render: (item) => item && Format_Date(item),
    },
    {
      title: "Ngày trả kết quả",
      dataIndex: "NgayTraKQ",
      render: (item) => item && Format_Date(item),
    },
    {
      title: "",
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex justify="center" gap={5}>
            <Tooltip placement="topRight" title="Hồ sơ">
              <Button
                type="primary"
                ghost
                icon={<MdDocumentScanner />}
                onClick={() => refXuLy.current.show(item,action)}
              />
            </Tooltip>
            {checkAction(2) && (
              <Tooltip placement="topRight" title="Sửa">
                <Button
                  type="primary"
                  ghost
                  icon={<MdEdit />}
                  onClick={() => refAdd.current.update(item,action)}
                />
              </Tooltip>
            )}
            {checkAction(3) && (
              <Popconfirm
                title="Xoá"
                description="Bạn có chắc chắn muốn xoá hay không?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => remove(item)}
              >
                <Button danger ghost icon={<CiTrash />} />
              </Popconfirm>
            )}
          </Flex>
        );
      },
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await VisaService.delete({
      ID: item.ID,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(item.ID));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onAfterSubmit = () => {
    loadBuyin(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    loadBuyin(_filter);
    setFilterCondition(_filter);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách buyin" + fileExtension);
  };
  const changeState = (state) => {
    if (state === 0) {
      setData(dataAll);
    } else {
      let _data = dataAll.filter((v) => v.MaTT === state);
      setData(_data);
    }
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        loadBuyin(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  return (
    <Row gutter={[15]}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          // companies={companies}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                color="primary"
                className="icon-filter"
                onClick={() => onHandleHide()}
              />
              <Input
                placeholder="Tìm kiếm tên, di động, hộ chiếu, sản phẩm"
                style={{
                  width: "310px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
              }}
            >
              {status?.map((item) => {
                return (
                  <Badge
                    key={item.ID}
                    count={item.Total}
                    onClick={() => {
                      setStateActive(item);
                      changeState(item.ID);
                    }}
                  >
                    <div
                      style={{
                        border: `1px solid ${item.Color}`,
                        padding: "6px 15px",
                        cursor: "pointer",
                        borderRadius: 10,
                        background:
                          stateActive.ID === item.ID ? item.Color : null,
                        color: stateActive.ID === item.ID ? "white" : null,
                      }}
                    >
                      <p>{item.TenTT}</p>
                    </div>
                  </Badge>
                );
              })}
              {checkAction(1) && (
                <Button type="primary" onClick={() => refAdd.current.show()}>
                  Thêm
                </Button>
              )}
            </div>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            // x: 1500,
          }}
          size="small"
        />
      </Col>

      <FormAdd ref={refAdd} onAfterSubmit={onAfterSubmit} />
      <FormXuLy ref={refXuLy} onAfterSubmit={onAfterSubmit}  />
      <FormDetail ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Row>
  );
}
