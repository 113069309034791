import { Box } from "@chakra-ui/react";
import React, { useEffect,useRef,useState } from "react";
import {
  Button,
  Col,
  Input,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  notification,
} from "antd";
import FilterModal from "./FilterModal";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from 'lodash'
import Toolbar from "components/Toolbar";
import { KhoHangService } from "services/KhoHangService";
import { CSVLink, CSVDownload } from "react-csv";
import Search from "antd/es/input/Search";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    InputSearch: "",
    KhoHang: ",,",
    MaCT: 0,
  });
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(()=>{
    filter(filterCondition);
  },[])
  const filter =async (_filter) =>{
    setLoading(true);
    let _res = await KhoHangService.BaoCao.getTonKho(_filter);
    if(_res.data) setData(_res.data);
    setLoading(false);
  }
  const onChangeFilter = (_filter) =>{
    filter(_filter);
    setFilterCondition(_filter);
  }
  const onSearch = (v) =>{
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
    filter(_ft);
  }
  const columns = [
    {
      title: "Mã vạch",
      dataIndex: "MaVach",
    },
    {
      title: "Mã sản phẩm",
      dataIndex: "MaSP",
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "TenSP",
    },
    {
      title: "Đơn vị tính",
      dataIndex: "TenDVT",
    },
    {
      title: "Kho hàng",
      dataIndex: "TenKho",
    },

    {
      title: "Tồn đầu",
      dataIndex: "TonDau",
      render: (item) => item,
    },
    {
      title: "Nhập",
      dataIndex: "Nhap",
      render: (item) => item,
    },
    {
      title: "Xuất",
      dataIndex: "Xuat",
      render: (item) => item,
    },
    {
      title: "Tồn cuối",
      dataIndex: "TonCuoi",
      render: (item) => item,
    },
    {
      title: "Giá tồn cuối",
      dataIndex: "TongGia",
      render: (item) => Format_Currency(item),
    },
    // {
    //   title: "",
    //   fixed: "right",
    //   width: "100px",
    //   render: (item) => {
    //     return (
    //       <div>
    //         <Tooltip placement="topRight" title="Sửa">
    //           <Button
    //             style={{ marginRight: 5 }}
    //             type="primary"
    //             ghost
    //             icon={<MdEdit />}
    //             onClick={() => refDetail.current.update(item)}
    //           />
    //         </Tooltip>
    //         <Popconfirm
    //           title="Xoá nhập kho"
    //           description="Bạn có chắc chắn muốn xoá phiếu nhập?"
    //           okText="Đồng ý"
    //           cancelText="Không"
    //           onConfirm={() => remove(item.ID)}
    //         >
    //           <Tooltip placement="topRight" title="Xoá">
    //             <Button danger ghost icon={<CiTrash />} />
    //           </Tooltip>
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
  ];
  const remove = async (value) => {
    let _res = await KhoHangService.XuatKho.delete({ ID: Number(value) });
    if(_res.status === 2000){
      let _data = _.cloneDeep(data);
      let fil = _data.filter(v=>v.ID !==Number(value) );
      setData(fil);
      openNotification(
        "success",
        "topRight",
        "Xoá thành công"
      );
    }else{
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              placeholder="Tìm kiếm mã, tên sản phẩm"
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          right={() => (
            <Button type="primary">
              <CSVLink filename={"BaoCaoTonKho.csv"} data={data}>
                Export
              </CSVLink>
            </Button>
          )}
        />
        <Table
          style={{
            height: 100,
            // boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            // borderRadius:0
          }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            // x: 2500
          }}
          size="small"
        />
      </Col>
      {contextHolder}
    </Row>
  );
}
