import React, {
  useState,
} from "react";
import {  DatePicker, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";

const FilterModal = ({ ...props }) => {
  const {  filterCondition, onChangeFilter } = props;
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "LoaiVe":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
const onChangeDate = (v) => {
  let _filter = _.cloneDeep(filterCondition);
  _filter.TuNgay = v.TuNgay;
  _filter.DenNgay = v.DenNgay;
  onChangeFilter(_filter);
};
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      <div className="box-search">
        <p className="label">Loại vé</p>
        <Select
          mode="multiple"
          placeholder="Chọn loại vé"
          onChange={(v) => applyChangeFilter("LoaiVe", v)}
          style={{
            width: "100%",
          }}
          options={[
            { ID: "QR", name: "QR" },
            { ID: "CARD", name: "CARD" },
          ]}
          fieldNames={{ label: "name", value: "ID" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
