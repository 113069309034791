import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  Drawer,
  Flex,
  Tag,
  notification,
  Popconfirm,
  InputNumber,
  Select,
} from "antd";
import _ from "lodash";
import { useReactToPrint } from "react-to-print";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { BanHangService } from "services/BanHangService";
import InLaiVe from "./InLaiVe";
import { SanPhamService } from "services/SanPhamService";
import { CiCircleRemove } from "react-icons/ci";
import { OtherService } from "services/OtherService";

let exits = {};
function taoSoDuyNhatBangDate() {
  try {
    const timestamp = Date.now().toString();
    const value = timestamp.slice(-9);
    if (exits[value]) {
      return taoSoDuyNhatBangDate();
    } else {
      exits[value] = true;
      return value;
    }
  } catch {
    return taoSoDuyNhatBangDate();
  }
}
const FormDetailEdit = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const refInLaiVe = useRef();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sanpham, setSanpham] = useState([]);
  const [the, setThe] = useState([]);
  const [ve, setVe] = useState([]);
  const [hinhthuc, setHinhThuc] = useState([]);
  const [banggia, setBanggia] = useState([]);
  const [mabg, setMabg] = useState(0);
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      let _ht = await OtherService.getHinhThucTT();
      setHinhThuc(_ht.data ?? []);
      let _bg = await SanPhamService.Categories.getBangGia({ ApDung: true });
      if (_bg.data?.length > 0) {
        setBanggia(_bg.data ?? []);
        let _day = new Date().getDay();
        let id = 0;
        if (_day === 0 || _day === 6) {
          id = _bg.data?.find((v) => v.isCuoiTuan === true)?.ID;
        } else {
          id = _bg.data?.find((v) => v.MacDinh === true)?.ID;
        }
        setMabg(id);
        let _res = await SanPhamService.Categories.getSanPhamByMaBG({
          MaBG: id,
          InputSearch: "",
        });
        setVe(_res.data ?? []);
        // setSanpham(_res.data ?? []);
      }
      let _the = await BanHangService.getBHThe({ MaBH: item.ID });
      let _res = await BanHangService.getBHSanPHam({ MaBH: item.ID });
      let sp = [];
      _res.data?.map((item) => {
        let qr = _the.data?.filter(
          (v) => v.MaSP === item.MaSP && v.MaGDV === item.MaGDV
        );
        sp.push({
          ...item,
          QRCodes: _.map(qr, "MaVach"),
        });
      });
      setSanpham(sp);
      setThe(_the?.data);
      setOpen(true);
      setData(item);
    },
  }));
  const onClose = () => {
    setOpen(false);
    onAfterSubmit();
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const confirm = async () => {
    setLoading(true);
    let _the = [];
    // sanpham?.map((item) => {
    //   for (let index = 0; index < item.SoLuong; index++) {
    //     let qr = taoSoDuyNhatBangDate();
    //     _the.push({
    //       MaVach: qr,
    //       MaGoiDV: item.MaGDV,
    //       MaSP: item.MaSP,
    //       SoLanQuet: item.SoLanQuet ?? 1,
    //       NgayTao: item.NgayBH,
    //     });
    //   }
    // });
    let thanhtien = _.sumBy(sanpham, (v) => v.SoLuong * v.DonGia);
    let tienphaithu =
      thanhtien - (data.TienCK ?? 0) - data.ThanhToan > 0
        ? thanhtien - (data.TienCK ?? 0) - data.ThanhToan
        : 0;
    let tienphaichi =
      thanhtien - (data.TienCK ?? 0) - data.ThanhToan < 0
        ? data.ThanhToan - thanhtien - (data.TienCK ?? 0)
        : 0;

    let _payload = {
      ID: data.ID,
      MaKH: data.MaKH,
      HinhThucTT: data.HinhThucTT,
      TyLeCK: data.TyLeCK,
      TienCK: data.TienCK,
      KhachTra: thanhtien - (data.TienCK ?? 0),
      ThanhTien: thanhtien,
      TienPhaiThu: tienphaithu,
      TienPhaiChi: tienphaichi,
      SanPham: sanpham,
    };
    let _res = await BanHangService.BanVeKinhDoanh.updateBanHang(_payload);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Cập nhật thành công!`);
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const applyChange = async (p, v, index) => {
    let _data = _.cloneDeep(sanpham);
    switch (p) {
      case "MaBG":
        let _res = await SanPhamService.Categories.getSanPhamByMaBG({
          MaBG: v,
          InputSearch: "",
        });
        setVe(_res.data ?? []);
        break;
      case "SoLuong":
        _data[index][p] = v;
        _data[index].ThanhTien = v * _data[index].DonGia;
        break;
      case "Remove":
        _data.splice(index, 1);
        if (v.ID) {
          await BanHangService.BanVeKinhDoanh.deleteSPBanHang({ ID: v.ID });
        }
        break;
      case "MaVe":
        let check = _data.find((o) => o.MaSP === v);
        if (check) {
          check.SoLuong += 1;
        } else {
          let sp = ve?.find((o) => o.ID === v);
          _data.push({
            DonGia: sp.GiaBan,
            MaGDV: sp.MaGDV,
            MaSP: sp.ID,
            NgayBH: data?.NgayBH,
            SoLanQuet: sp?.SoLanQuet ?? 1,
            SoLuong: 1,
            TenSP: sp.TenSP,
            ThanhTien: sp.GiaBan,
            TienCK: 0,
          });
        }
        break;
    }
    setSanpham(_data);
  };
  const applyChangeData = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "TyLeCK":
        _data[p] = v;
        _data.TienCK = _.sumBy(sanpham, (v) => v.SoLuong * v.DonGia) * v / 100;
        break;
      case "HinhThucTT":
        _data[p] = v;
        break;
        let check = _data.find((o) => o.MaSP === v);
        if (check) {
          check.SoLuong += 1;
        } else {
          let sp = ve?.find((o) => o.ID === v);
          _data.push({
            DonGia: sp.GiaBan,
            MaGDV: sp.MaGDV,
            MaSP: sp.ID,
            NgayBH: data?.NgayBH,
            SoLanQuet: sp?.SoLanQuet ?? 1,
            SoLuong: 1,
            TenSP: sp.TenSP,
            ThanhTien: sp.GiaBan,
            TienCK: 0,
          });
        }
        break;
    }
    setData(_data);
  };
  return (
    <Drawer
      title="Thông tin chi tiết vé"
      width="750px"
      onClose={onClose}
      open={open}
      extra={
        
          <Button
            onClick={() => confirm()}
            style={{ marginRight: 5 }}
            type="primary"
            loading={loading}
          >
            Lưu
          </Button>
      }
    >
      {contextHolder}
      <div style={{ margin: "10px 0px" }}>
        <p>
          Tên khách hàng:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {data.TenCongTy}
          </span>
        </p>
        <p>
          Di động:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {" "}
            {data.DiDong}
          </span>
        </p>
        <p>
          Email:
          <span style={{ fontWeight: "bold", fontSize: 14 }}>{data.Email}</span>
        </p>
      </div>
      <hr />
      <Flex
        justify="space-between"
        align="center"
        style={{ padding: "10px 0px" }}
      >
        <p>
          Ngày bán vé:{" "}
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            {new Date(data.NgayBH).getDate()}/{" "}
            {new Date(data.NgayBH).getMonth() + 1}/{" "}
            {new Date(data.NgayBH).getFullYear()}
          </span>
        </p>
        <Select
          style={{
            width: "20%",
          }}
          // value={data[activeTab]?.MaBG}
          placeholder="Bảng giá"
          onChange={(v) => applyChange("MaBG", v)}
          options={banggia}
          fieldNames={{ label: "TenBangGia", value: "ID" }}
        />
        <Select
          showSearch
          style={{
            width: "40%",
          }}
          value={null}
          placeholder="Chọn vé cần thêm"
          onChange={(v) => applyChange("MaVe", v)}
          options={ve}
          fieldNames={{ label: "TenSP", value: "ID" }}
        />
      </Flex>

      <div>
        <Flex
          justify="space-between"
          style={{ borderBottom: "1px dotted #dee2e6" }}
        >
          <p style={{ fontWeight: "600", width: 280 }}>Gói bán</p>
          <p>Số lượng</p>
          <p style={{ fontWeight: "600", width: 80 }}>Đơn giá(đ)</p>
        </Flex>
        {sanpham?.map((item, index) => {
          return (
            <Flex
              justify="space-between"
              style={{ margin: "5px 0px" }}
              align="center"
              key={item.ID}
            >
              <Flex align="center" gap={10}>
                <p>
                  <CiCircleRemove
                    size={25}
                    color="red"
                    style={{ cursor: "pointer" }}
                    onClick={() => applyChange("Remove", item, index)}
                  />
                </p>
                <p style={{ marginTop: 0, width: 250 }}>
                  {item.TenSP}
                  {/* {sanpham?.find((v) => v.MaSP === item.MaSP)?.TenSP} ({item.SoLuong}) */}
                  {/* {sanpham?.find((v) => v.MaSP === item.MaSP)?.SoLuong} */}
                  {/* <Tag
                  style={{ fontSize: 12,marginLeft:10 }}
                  color="green"
                >
                  {item.DaQuet ?? 0} / {item.SoLanQuet}
                </Tag> */}
                </p>
              </Flex>
              <InputNumber
                style={{ width: 50 }}
                value={item.SoLuong}
                onChange={(v) => applyChange("SoLuong", v, index)}
              />
              <p style={{ marginTop: 0, fontWeight: "bold", width: 80 }}>
                {/* {Format_Currency(
                  sanpham?.find((v) => v.MaSP === item.MaSP)?.DonGia *
                    sanpham?.find((v) => v.MaSP === item.MaSP)?.SoLuong
                )} */}
                {Format_Currency(item.DonGia)}
              </p>
            </Flex>
          );
        })}
        <hr />
        <Flex
          style={{ paddingTop: 10, paddingBottom: 4 }}
          align="center"
          justify="space-between"
        >
          <p style={{ width: 200 }}>Tổng tiền:</p>
          <p style={{ fontSize: 14, width: 80 }}>
            {/* {Format_Currency(data.ThanhTien)} */}
            {Format_Currency(_.sumBy(sanpham, (v) => v.SoLuong * v.DonGia))}
          </p>
        </Flex>
        <Flex justify="space-between" align="center">
          <p style={{ width: 280 }}>Chiết khấu (%)</p>
          {/* <Flex style={{ width: "50%" }} justify="space-between" align="center"> */}
          <InputNumber
            max={100}
            placeholder="%"
            style={{ width: 50 }}
            value={data.TyLeCK}
            onChange={(v) => applyChangeData("TyLeCK", v)}
          />
          <p style={{ width: 80 }}>{Format_Currency(data.TienCK)}</p>
          {/* </Flex> */}
        </Flex>
        <Flex
          style={{ paddingBottom: 7 }}
          align="center"
          justify="space-between"
        >
          <p style={{ width: 200 }}>Tổng tiền sau CK</p>
          <p style={{ fontWeight: "bold", fontSize: 14, width: 80 }}>
            {Format_Currency(data.ThanhToan + data.TienCK)}
            {/* {Format_Currency(_.sumBy(sanpham, (v) => v.SoLuong * v.DonGia))} */}
          </p>
        </Flex>
        <Flex
          style={{ paddingBottom: 7 }}
          align="center"
          justify="space-between"
        >
          <p style={{ width: 200 }}>Tiền đã thành toán trước</p>
          <p style={{ fontWeight: "bold", fontSize: 14, width: 80 }}>
            {Format_Currency(data.ThanhToan)}
            {/* {Format_Currency(_.sumBy(sanpham, (v) => v.SoLuong * v.DonGia))} */}
          </p>
        </Flex>

        <Flex
          style={{ paddingBottom: 7 }}
          align="center"
          justify="space-between"
        >
          <p style={{ width: 200 }}>
            {_.sumBy(sanpham, (v) => v.SoLuong * v.DonGia) -
              data?.TienCK -
              data.ThanhToan >
            0
              ? "Số tiền phải thu:"
              : "Số tiền phải chi:"}
          </p>
          <p style={{ fontWeight: "bold", fontSize: 14, width: 80 }}>
            {Format_Currency(
              _.sumBy(sanpham, (v) => v.SoLuong * v.DonGia) -
                data?.TienCK -
                data.ThanhToan
            )}
            {/* {Format_Currency(_.sumBy(sanpham, (v) => v.SoLuong * v.DonGia))} */}
          </p>
        </Flex>
        <Flex justify="space-between" align="center">
          <p>Hình thức thanh toán</p>
          <Select
            style={{
              width: "30%",
            }}
            value={data.HinhThucTT}
            placeholder="Chọn hình thức"
            onChange={(v) => applyChangeData("HinhThucTT", v)}
            options={hinhthuc}
            fieldNames={{ label: "TenHinhThuc", value: "ID" }}
          />
        </Flex>
      </div>

      <div style={{ display: "none" }}>
        <InLaiVe ref={refInLaiVe} data={{ SanPham: sanpham, The: the }} />
      </div>
    </Drawer>
  );
};
export default forwardRef(FormDetailEdit);
