import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Col, DatePicker, Input, Popover, Row, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { FaFilter } from "react-icons/fa";
import ChiNhanhSelect from "components/ChiNhanhSelect";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import { SanPhamService } from "services/SanPhamService";
const FilterModal = ({ ...props }) => {
  const { companies, filterCondition, onChangeFilter } = props;
  const [loaiSP, setLoaiSP] = useState([]);
  useEffect(() => {
    SanPhamService.getLSP({ ApDung: true }).then((res) =>
      setLoaiSP(res.data ?? [])
    );
  }, []);

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "ListMaCT":
      case "LoaiSP":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      case "MaCT":
        _filter[p] = v ? v : 0;
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate
        onChangeDate={onChangeDate}
        dateDefault={{
          Name: "Tháng này",
          TuNgay: new Date(),
          DenNgay: new Date(),
        }}
      />
      {/* <ChiNhanhSelect
        FormID={9}
        onChange={(v) => applyChangeFilter("MaCT", v)}
      /> */}
      <div className="box-search">
        <p className="label">Loại sản phẩm</p>
        <Select
          mode="multiple"
          placeholder="Chọn loại sản phẩm"
          onChange={(v) => applyChangeFilter("LoaiSP", v)}
          style={{
            width: "100%",
          }}
          options={loaiSP}
          fieldNames={{ label: "TenLSP", value: "MaLSP" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
