import { Flex } from "antd";
import { Format_Date } from "layouts/ultis/common";
import { DocSoTienBangTiengViet } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import * as React from "react";
import { NhanVienService } from "services/NhanVienService";

const InPhieuChi = (props, ref) => {
  const { data } = props;
  const [nhanvien, setNhanVien] = React.useState({});

  React.useEffect(() => {
    NhanVienService.getDetail().then((res) => {
      setNhanVien(res.data ?? []);
    });
  }, []);
  return (
    <div ref={ref} id="in-phieuchi" style={{ margin: "0px 5px 5px" }}>
      <div style={{ textAlign: "center", padding: "15px 0px" }}>
        <p style={{ fontSize: 15, fontWeight: "bold" }}>{nhanvien?.TenCT}</p>
        <p style={{ fontSize: 13 }}>{nhanvien?.DiaChi}</p>
        <p style={{ fontSize: 13 }}>{nhanvien?.DienThoai}</p>
      </div>
      <p
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        PHIẾU CHI
      </p>
      <p style={{ fontSize: 11, textAlign: "center" }}>{data?.SoBH}</p>
      <p style={{ fontSize: 11, textAlign: "center" }}>
        Ngày: {Format_Date(new Date())}
      </p>
      {/* <Flex justify="flex-start" align="center"> */}
      <p style={{ fontSize: 13 }}>Người nhận: {data?.NguoiNhan}</p>
      <p style={{ fontSize: 13 }}>Số điện thoại: {data?.DiDong}</p>
      <p style={{ fontSize: 13 }}>Địa chỉ: {data?.DiaChiNN}</p>
      <p style={{ fontSize: 13 }}>Lý do nhận: {data?.LyDo}</p>
      {/* </Flex> */}
      <Flex justify="flex-start" align="center">
        <p style={{ fontSize: 13 }}>Số tiền: </p>
        <p style={{ fontSize: 13, fontWeight: "bold" }}>
          {Format_Currency(data?.TongTien)}
        </p>
      </Flex>
      <p style={{ fontSize: 13 }}>
        Bằng chữ: {DocSoTienBangTiengViet(data?.TongTien)}
      </p>
      <p style={{ fontSize: 11, textAlign: "right" }}>
        Ngày {new Date().getDate()} tháng {new Date().getMonth() + 1} năm{" "}
        {new Date().getFullYear()}
      </p>
      <Flex justify="space-around" align="center">
        <p style={{ fontSize: 12, fontWeight: "bold" }}>Người lập phiếu</p>
        <p style={{ fontSize: 12, fontWeight: "bold" }}>Người nhận</p>
        <p style={{ fontSize: 12, fontWeight: "bold" }}>Thủ quỹ</p>
      </Flex>
    </div>
  );
};
export default React.forwardRef(InPhieuChi);
