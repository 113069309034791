import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Row,
  Col,
  Flex,
  Checkbox,
  Input,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import Toolbar from "components/Toolbar";
import { Format_Date } from "layouts/ultis/common";
import { AiOutlineMenuFold } from "react-icons/ai";
import { DeleteOutlined, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import { HeThongService } from "services/HeThongService";
import FilterModal from "./FilterModal";
import { InfoService } from "services/InfoService";
import dayjs from "dayjs";
export default function HistoryNotification() {
  const [data, setData] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [toanha, setToanha] = useState([]);
  // const [loaiBM, setLoaiBM] = useState([]);
  const [loaiTB, setLoaiTB] = useState([]);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const today = new Date();
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: today,
    DenNgay: today,
    MaChucNang: 0,
    PageIndex: 1,
    PageSize: 20,
    MaTN: null,
    InputSearch: "",
  });
  const refDetail = useRef(null);
  useEffect(() => {
    loadCategories(filterCondition);
  }, []);

  const loadCategories = async () => {
    let _filter = _.cloneDeep(filterCondition);
    let _resCN = await InfoService.getChucNang();
    if (_resCN.data) setLoaiTB(_resCN.data);

    setFilterCondition(_filter);
    filter(_filter);
  };
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await HeThongService.ThongBao.getHistoryNotification(_filter);
    setData(_res.data);
    setLoading(false);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        if (filterCondition.MaTN) filter(filterCondition);
      }, 500);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const columns = [
    {
      title: "Tiêu đề",
      dataIndex: "Title",
      width: 250,
    },
    {
      title: "Nội dung",
      dataIndex: "Contents",
    },
    {
      title: "Chức năng",
      dataIndex: "MaChucNang",
      width: 150,
      render: (item) => loaiTB?.find((cn) => cn.ID === item)?.Name,
    },
    {
      title: "Ngày tạo",
      width: 250,
      dataIndex: "DateCreate",
      render: (item) => (item ? dayjs(item).format("HH:mm DD-MM-YYYY") : ""),
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await HeThongService.BieuMau.delete({
      MaBM: item,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaBM !== Number(item));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };

  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  return (
    <Row gutter={[10, 10]}>
      <Col span={collapsed ? 0 : 4} style={{ transition: "all 0.3s ease" }}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{
            LoaiTB: loaiTB,
          }}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                className="icon-filter"
                color="primary"
                onClick={() => onHandleHide()}
              />
              <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm mã số mặt bằng, điện thoại, tên khách hàng"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              {/* <Button
                loading={loading}
                type="primary"
                onClick={() => refDetail.current.show()}
              >
                <PlusOutlined style={{ fontSize: "13px", margin: "0 -4px" }} />{" "}
                Thêm
              </Button> */}
              {/* <Button onClick={() => refAddExcel.current.show()}>
                <ImportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />
                Import
              </Button>
              <Button onClick={() => exportToCSV()}>
                <ExportOutlined
                  style={{ fontSize: "13px", margin: "0 -2px" }}
                />{" "}
                Export
              </Button> */}
            </Flex>
          )}
        />

        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            // x: 2000,
          }}
          size="small"
          bordered
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
          }}
        />
      </Col>
      {contextHolder}
    </Row>
  );
}
