import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  notification,
  Row,
  Col,
  Checkbox,
  Button,
  Tag,
  Flex,
  Tooltip,
  Modal,
  Input,
} from "antd";
import FilterModal from "./FilterModal";
import { Format_Date } from "layouts/ultis/common";
import _ from "lodash";
import "./scss/index.scss";
import Toolbar from "components/Toolbar";
import { ReportService } from "services/ReportService";
import ExcelJS from "exceljs";
import Search from "antd/es/input/Search";
import { IoEyeSharp } from "react-icons/io5";
import { Format_Datetime } from "layouts/ultis/common";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({});
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    LoaiVe: ",,",
    InputSearch:"",
  });
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const [detail,setDetail] = useState([]);
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await ReportService.getLichSuQuetTheError(_filter);
    if (_res.data) setData(_res.data);
    let _total = _.cloneDeep(total);
    let tongve = _.sum(_.map(_res.data,"SoLanQuet"));
    let daquet = _.sum(_.map(_res.data,"DaQuet"));
    _total.TongVe = tongve;
    _total.DaQuet = daquet;
    _total.ConLai = tongve - daquet;
    setTotal(_total);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const columns = [
    {
      title: "Mã vé",
      dataIndex: "MaVach",
    },
    {
      title: "Cổng",
      dataIndex: "MaCong",
    },
    {
      title: "Loại vé",
      dataIndex: "LoaiVe",
    },
    {
      title: "Lỗi",
      dataIndex: "GhiChu",
    },
    {
      title: "Ngày quẹt",
      dataIndex: "NgayQuet",
      render: (item) => Format_Datetime(item),
    },
  ];

  const exportExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("BaoCao");
    sheet.properties.defaultRowHeight = 80;
    sheet.columns = [
      {
        header: "Ngày bán hàng",
        key: "ngaybh",
        width: 15,
      },
      {
        header: "Nhân viên",
        key: "nhanvien",
        width: 10,
      },
      {
        header: "Quầy bán",
        key: "quayban",
        width: 10,
      },
      {
        header: "Online",
        key: "online",
        width: 8,
      },
      {
        header: "Tổng tiền",
        key: "tongtien",
        width: 20,
      },
      {
        header: "Tiền CK",
        key: "tienck",
        width: 20,
      },
      {
        header: "Tổng sau CK",
        key: "tongsauck",
        width: 20,
      },
    ];
    data?.map((item) => {
      sheet.addRow({
        ngaybh: Format_Date(item.NgayBH),
        nhanvien: item.HoTen,
        quayban: item.TenQuay,
        online: item.IsOnline,
        tongtien: item.ThanhTien,
        tienck: item.TienCK,
        tongsauck: item.KhachTra,
      });
    });
    sheet.addRow({
      ngaybh: "Tổng: " + data.length,
      nhanvien: "",
      quayban: "",
      quayonlineban: "",
      tongtien: _.sumBy(data, (o) => o.ThanhTien),
      tienck: _.sumBy(data, (o) => o.TienCK),
      tongsauck: _.sumBy(data, (o) => o.KhachTra),
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "baocaobanhangtheongay.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const onSearch = (v)=>{
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
    filter(_ft);
  }
  const [open,setOpen] = useState(false);
   const renderModal = () => {
     const hide = () => {
       setOpen(false);
     };
     const columnsdt = [
       {
         title: "Mã vé",
         dataIndex: "MaVach",
       },
       {
         title: "Cổng",
         dataIndex: "MaCong",
       },
       {
         title: "Ngày quét",
         dataIndex: "NgayQuet",
         render: (item) => Format_Datetime(item),
       },
     ];
     return (
       <Modal
         title="Chi tiết vé quẹt"
         open={open}
         width={600}
         confirmLoading={loading}
         onCancel={hide}
         footer={null}
       >
         <Table
           loading={loading}
           dataSource={detail}
           columns={columnsdt}
           scroll={{
             y: window.innerHeight - 500,
           }}
           size="small"
           pagination={{
             defaultPageSize: 20,
             showSizeChanger: true,
             pageSizeOptions: ["10", "50", "100", "500"],
           }}
         />
       </Modal>
     );
   };
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
              placeholder="Tìm kiếm mã vé"
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          right={() => (
            <Tag
              color="#f50"
              style={{ fontSize: 14, padding: 5, fontWeight: "bold" }}
            >
              Tổng số lượt : {data?.length}
            </Tag>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 200,
            // x: 2500
          }}
          size="small"
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100", "500", "5000"],
          }}
        />
      </Col>
      {contextHolder}
      {/* {renderModal()} */}
    </Row>
  );
}
