import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  InputNumber,
  Row,
  Col,
  notification,
  Flex,
  Table,
  Form,
  Tooltip,
  Popconfirm,
  Tag,
} from "antd";
import _ from "lodash";
import { CustomerService } from "services/CustomerService";
import { MdAdd, MdCancel, MdEdit } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import { VisaService } from "services/VisaService";
import { IoMdTrash } from "react-icons/io";
import { render } from "react-dom";
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? <InputNumber /> : <Input.TextArea autoSize />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Vui lòng nhập [${title}]!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const FormHoSo = (props) => {
  const { hoSo, setHoSo, maVisa, action } = props;
  console.log(maVisa);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const isEditing = (record) => record.ID === editingKey;
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const cancel = (record) => {
    setEditingKey("");
    if (record.IsAdd) {
      const index = hoSo.findIndex((item) => record.ID === item.ID);
      if (index > -1) {
        form.setFieldsValue({});
        let newData = _.cloneDeep(hoSo);
        newData.splice(index, 1);
        setHoSo(newData);
      }
    }
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = _.cloneDeep(hoSo);
      const index = newData.findIndex((item) => key === item.ID);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        if (maVisa) {
          if (newData[index].IsAdd) {
            let _res = await VisaService.HoSo.add({
              ...newData[index],
              ID: null,
            });
            if (_res.status === 2000) {
              let _list = await VisaService.HoSo.getList({ MaVisa: maVisa });
              setHoSo(_list.data);
            } else {
              openNotification("error", "topRight", _res?.message);
            }
          } else {
            let _res = await VisaService.HoSo.add(newData[index]);
            if (_res.status === 2000) {
              setHoSo(newData);
            } else {
              openNotification("error", "topRight", _res?.message);
            }
          }
        } else {
          setHoSo(newData);
          setEditingKey("");
        }
        setEditingKey("");

        // if (newData[index].IsAdd) {
        //   let isBreak =
        //     newData[index].TenLevel.toLowerCase().includes("break");
        //   newData[index].isBreak = isBreak;
        //   // await BuyinService.addOneBlind(newData[index]);
        // } else {
        // await BuyinService.editBlind(newData[index]);
        //   setHoSo(newData);
        // }
        // let _res = await TourService.Table.edit(newData[index]);
        // if (_res.status === 2000) {
        //   openNotification("success", "topRight", "Thao tác thành công");
        // } else {
        //   openNotification("error", "topRight", _res?.message);
        // }
      } else {
        newData.push(row);
        setHoSo(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.ID);
  };
  const remove = async (record) => {
    if (maVisa) {
      let _res = await VisaService.HoSo.delete({
        ID: record.ID,
      });
      if (_res.status === 2000) {
        let _data = _.cloneDeep(hoSo);
        let _index = _data.findIndex((item) => item.ID === record.ID);
        _data.splice(_index, 1);
        setHoSo(_data);
      } else {
        openNotification("error", "topRight", _res?.message);
      }
    } else {
      const index = hoSo.findIndex((item) => record.ID === item.ID);
      if (index > -1) {
        form.setFieldsValue({});
        let newData = _.cloneDeep(hoSo);
        newData.splice(index, 1);
        setHoSo(newData);
      }
    }
  };
  const addHoSo = async (value) => {
    const newData = {
      ID: Date.now(), // Tạo một key tạm thời
      IsAdd: true,
      Index: hoSo?.length,
      STT: hoSo?.length + 1,
      MaVisa: maVisa,
    };
    let _data = _.cloneDeep(hoSo);
    _data.splice(hoSo?.length, 0, newData);
    setHoSo(_data);
    setEditingKey(newData.ID);
  };
  const checkAction = (id) => {
    return action?.some((v) => v.FeatureID === id);
  };
  const columns = [
    {
      title: "STT",
      width: "50px",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Tên hồ sơ",
      dataIndex: "TenHoSo",
      editable: true,
      render: (item) => (
        <p
          style={{
            margin: 0,
            padding: "8px",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {item}
        </p>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "State",
      width: "100px",
      render: (item) => (
        <Tag color={item === "DONE" ? "#87d068" : "#108ee9"}>
          {item === "DONE" ? "Hoàn thành" : "Mới"}
        </Tag>
      ),
    },
    {
      title: (
        <Flex justify="center">
          <Button
            type="primary"
            ghost
            disabled={editingKey !== ""}
            icon={<MdAdd />}
            onClick={() => addHoSo()}
          />
        </Flex>
      ),
      dataIndex: "operation",
      width: "100px",
      render: (_, record, index) => {
        const editable = isEditing(record);
        return editable ? (
          <Flex gap={5}>
            <Button
              type="primary"
              ghost
              icon={<FaRegSave />}
              onClick={() => save(record.ID)}
            />
            <Button
              type="primary"
              danger
              ghost
              icon={<MdCancel />}
              onClick={() => cancel(record)}
            />
          </Flex>
        ) : (
          <Flex gap={5} justify="center">
            {checkAction(59) && (
              <Tooltip placement="topRight" title="Sửa">
                <Button
                  type="primary"
                  ghost
                  disabled={editingKey !== ""}
                  icon={<MdEdit />}
                  onClick={() => edit(record)}
                />
              </Tooltip>
            )}

            {(record.State !== "DONE" && checkAction(60)) && (
              <Popconfirm
                title="Xoá "
                description="Bạn có chắc chắn muốn xoá hay không?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => remove(record)}
              >
                <Tooltip placement="topRight" title="Xoá">
                  <Button
                    type="primary"
                    danger
                    ghost
                    icon={<IoMdTrash />}
                    disabled={editingKey !== ""}
                    // onClick={() => remove(record)}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </Flex>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text", //col.dataIndex === "Ten" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Row gutter={[24, 8]}>
      {contextHolder}
      <Col span={24}>
        <p style={{ fontWeight: "bold", fontSize: 14, margin: "20px 0px 6px" }}>
          Danh sách hồ sơ cần
        </p>
        <Form form={form} component={false}>
          <Table
            loading={loading}
            dataSource={hoSo}
            columns={mergedColumns}
            pagination={false}
            rowClassName="editable-row"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            scroll={{
              y: window.innerHeight - 150,
            }}
            size="small"
          />
        </Form>
      </Col>
    </Row>
  );
};
export default React.memo(FormHoSo);
