import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Modal,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { SoQuyService } from "services/SoQuyService";
import { BanHangService } from "services/BanHangService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit, hinhThuc, loai } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    NgayThu: new Date(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    ThanhTien: null,
  };
  const [calamviec, setCalamviec] = useState({});
  const [openCaLV, setOpenCaLV] = useState(false);
  const [dataquay, setDataquay] = useState({});
  const [quayve, setQuayve] = useState([]);
  const [errors, setErrors] = useState(defaultErrors);

  const loadCategories = async () => { 
     let _qv = await BanHangService.getQuayBan();
     setQuayve(_qv.data ?? []);
     let _dataquay = _.cloneDeep(dataquay);
     _dataquay.MaQuay = _qv.isDefault;
     setDataquay(_dataquay);
     let _check = await BanHangService.checkCaLamViec();
     if (!_check.data) {
       setOpenCaLV(true);
     } else {
       setCalamviec(_check.data);
     }
  } 
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadCategories();
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    _data.MaCaLamViec = calamviec.ID;
    let _res = null;
    if (isupdate) {
      _res = await SoQuyService.PhieuThu.updatePhieuThu(_data);
    } else {
      _res = await SoQuyService.PhieuThu.addPhieuThu(_data);
      setData((pre) => {
        return { ...pre, ID: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} phiếu thu thành công!`
      );
      setIsUpdate(true);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
 const renderBatDauCa = () => {
   const saveCaLamViec = async () => {
     setLoading(true);
     let _res = await BanHangService.addCaLamViec(dataquay);
     if (_res.status === 2000) {
       setOpenCaLV(false);
       setCalamviec({
         ID: _res.data,
         TienDauCa: dataquay.TienDauCa,
         GioVaoCa: new Date(),
         MaQuay: dataquay.MaQuay,
       });
     }
     setLoading(false);
   };
   const appllyChange = (p, v) => {
     let _data = _.cloneDeep(dataquay);
     _data[p] = v;
     setDataquay(_data);
   };
   return (
     <Modal
       closeIcon={false}
       title="KHAI BÁO TIỀN ĐẦU CA"
       open={openCaLV}
       onOk={() => saveCaLamViec()}
       onCancel={() => {
         setOpenCaLV(false);
       }}
       cancelText="Huỷ"
       okText="Lưu"
     >
       <div>
         <p style={{ marginTop: 10 }}>Quầy bán</p>
         <Select
           style={{
             width: "100%",
           }}
           value={dataquay?.MaQuay}
           placeholder="Chọn quầy bán"
           onChange={(v) => appllyChange("MaQuay", v)}
           options={quayve}
           fieldNames={{ label: "TenQuay", value: "ID" }}
         />
         <p style={{ marginTop: 10 }}>Tiền đầu ca</p>
         <InputNumber
           suffix="vnđ"
           formatter={(value) =>
             `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
           }
           placeholder="Nhập tiền đầu ca"
           style={{ width: "100%" }}
           onChange={(v) => appllyChange("TienDauCa", v)}
         />
       </div>
     </Modal>
   );
 };
  return (
    <Drawer
      title="PHIẾU THU"
      width={620}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(false)}
            type="primary"
            ghost
          >
            Lưu
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 12]}>
        <Col span={24}>
          <p>Ngày thu</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.NgayThu)}
            locale={vi}
            onChange={(v) => applyChange("NgayThu", v)}
          />
        </Col>
        <Col span={24}>
          <p style={{ color: "red" }}>Số tiền</p>
          <InputNumber
            style={{ width: "100%" }}
            addonAfter="vnđ"
            value={data?.ThanhTien}
            status={data?.ThanhTien ? "" : "error"}
            onChange={(v) => applyChange("ThanhTien", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          <p style={{ color: "red" }}>{errors?.ThanhTien}</p>
        </Col>
        <Col span={24}>
          <p className="label">Hình thức thanh toán</p>
          <Select
            value={data?.MaHinhThucTT}
            placeholder="Chọn hình thức"
            onChange={(v) => applyChange("MaHinhThucTT", v)}
            style={{
              width: "100%",
            }}
            options={hinhThuc}
            fieldNames={{ label: "TenHinhThuc", value: "ID" }}
          />
        </Col>
        <Col span={24}>
          <p className="label">Loại thu</p>
          <Select
            value={data?.MaLPT}
            placeholder="Chọn loại thu"
            onChange={(v) => applyChange("MaLPT", v)}
            style={{
              width: "100%",
            }}
            options={loai}
            fieldNames={{ label: "TenLPT", value: "ID" }}
          />
        </Col>
        <Col span={24}>
          <p>Lý do</p>
          <TextArea
            value={data?.LyDo}
            onChange={(e) => applyChange("LyDo", e.target.value)}
            placeholder="Nhập lý do"
          />
        </Col>
      </Row>
      {renderBatDauCa()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
