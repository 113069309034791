import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Flex,
  Popconfirm,
  Table,
  Tabs,
  Tooltip,
  notification,
} from "antd";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import { MdAdd, MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import TabPane from "antd/es/tabs/TabPane";
import { CustomerService } from "services/CustomerService";
import { ColorPicker } from "primereact/colorpicker";
import { CategoryService } from "services/CategoryService";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const tabs = [
    {
      title: "Cấu hình tài khoản",
      key: "0",
    },
    {
      title: "Cấu hình mẫu",
      key: "1",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");

  useEffect(() => {
    filter(activeTab);
  }, []);
  const filter = async (tab) => {
    setLoading(true);
    if (tab === "0") {
      let _res = await CategoryService.Viettel.getTaiKhoan();
      if (_res.data) setData(_res.data);
    } else if (tab === "1") {
      let _res = await CategoryService.Viettel.getMau();
      if (_res.data) setData(_res.data);
    }
    setLoading(false);
  };
  const onAfterSubmit = () => {
    filter(activeTab);
  };
  const columns = [
    {
      title: "Username",
      dataIndex: "Username",
    },
    {
      title: "Password",
      dataIndex: "Password",
    },
    {
      title: "Ký hoá đơn",
      dataIndex: "isKyHoaDon",
      render: (item) => (
        <Checkbox
          checked={item === true}
        />
      ),
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              ghost
              icon={<MdAdd />}
              onClick={() => refDetail.current.show(activeTab)}
            />
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "invoiceType",
      dataIndex: "invoiceType",
    },
    {
      title: "templateCode",
      dataIndex: "templateCode",
    },
    {
      title: "invoiceSeries",
      dataIndex: "invoiceSeries",
    },
    {
      title: "currencyCode",
      dataIndex: "currencyCode",
    },
    {
      title: "Mặc định",
      dataIndex: "IsDefault",
      render: (item) => <Checkbox value={item} checked={item} />,
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              ghost
              icon={<MdAdd />}
              onClick={() => refDetail.current.show(activeTab)}
            />
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await CategoryService.Viettel.deleteTaiKhoan({
          ID: Number(value),
        });
        break;
      case "1":
        _res = await CategoryService.Viettel.deleteMau({
          ID: Number(value),
        });
        break;
    }
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá danh mục thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
    filter(newActiveKey);
  };

  return (
    <Box>
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              <Table
                loading={loading}
                dataSource={data}
                columns={activeTab === "0" ? columns : columns2}
                scroll={{
                  y: window.innerHeight - 350,
                }}
                size="small"
              />
            </TabPane>
          );
        })}
      </Tabs>

      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Box>
  );
}
